<div class="row" *ngIf="loaded | async">
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Sconto {{ discount.code }}</h5>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Codice: </span>
          <input type="text" class="form-control" [(ngModel)]="discount.code"/>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Tipo: </span>
          <select class="form-control" [(ngModel)]="discount.type">
            <option value="PERCENTAGE">Percentuale</option>
            <option value="FIXED">Fisso</option>
          </select>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Sconto: </span>
          <input type="number" class="form-control" [(ngModel)]="discount.amount"/>
        </p>
        <p class="card-text mb-05 mt-1">
          <input type="checkbox" class="form-check-input mr-05" [(ngModel)]="discount.restricted"/>
          <span class="card-text-title">Limitato </span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title"> Periodo </h5>
        <div class="from-date-picker time-selector-container full-width" #fromDatePicker>
          <p class="card-text mb-05 mt-2"><span class="card-text-title">Da: </span>
          <div class="btn btn-outline-primary btn-sm full-width" (click)="openFromDateSelector($event)">
            <div class="font-small-2">
              <span class="align-left"> {{ fromDateString }} </span>
              <app-svg-icon name="calendar" class="align-middle ml-05"></app-svg-icon>
            </div>
          </div>
          <div class="time-selector" [class.active]="selectFromDate">
            <ngb-datepicker #fromDatepicker (dateSelect)="onFromDateSelection($event)"
                            [displayMonths]="1"
                            [dayTemplate]="t"
                            outsideDays="hidden"
                            class="full-width"
            />
            <ng-template #t let-date let-focused="focused">
      <span
        class="custom-day full-width"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </span>
            </ng-template>
          </div>
        </div>

        <div class="to-date-picker time-selector-container" #toDatePicker>
          <p class="card-text mb-05 mt-2"><span class="card-text-title">A: </span>
          <div class="btn btn-outline-primary btn-sm full-width" (click)="openToDateSelector($event)">
            <div class="font-small-2">
              <span class="align-middle"> {{ toDateString }} </span>
              <app-svg-icon name="calendar" class="align-middle ml-05"></app-svg-icon>
            </div>
          </div>
          <div class="time-selector " [class.active]="selectToDate">
            <ngb-datepicker #toDatepicker (dateSelect)="onToDateSelection($event)"
                            [displayMonths]="1"
                            [dayTemplate]="t"
                            [minDate]="discount.from"
                            outsideDays="hidden"
                            class="full-width"
            />
            <ng-template #t let-date let-focused="focused">
      <span
        class="custom-day full-width"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Azioni</h5>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-primary btn-block" (click)="saveDiscount()">
            <app-svg-icon name="save"></app-svg-icon>
            Salva
          </button>
        </div>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-danger btn-block" (click)="deleteDiscount()">
            <app-svg-icon name="delete"></app-svg-icon>
            Elimina
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Aziende</h5>
        <div class="table-container">
          <dynamic-table
            [dataRetriever]="getBusinesses.bind(this)"
            [columns]="tableConfig1.columns"
            [filterGroups]="tableConfig1.filterGroups"
            [page]="tableConfig1.pagination.page"
            [pageSize]="tableConfig1.pagination.pageSize"
            [sort]="tableConfig1.pagination.sort"
            [search]="tableConfig1.pagination.search"
            [strictSearch]="tableConfig1.pagination.strictSearch"
            [messages]="tableConfig1.messages"
            [autoReloadEnable]="true"
          >
          </dynamic-table>
        </div>
      </div>
    </div>
  </div>


  <div class="col-12 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Ordini</h5>
        <div class="table-container">
          <dynamic-table
            [dataRetriever]="getOrders.bind(this)"
            [columns]="tableConfig2.columns"
            [filterGroups]="tableConfig2.filterGroups"
            [page]="tableConfig2.pagination.page"
            [pageSize]="tableConfig2.pagination.pageSize"
            [sort]="tableConfig2.pagination.sort"
            [search]="tableConfig2.pagination.search"
            [strictSearch]="tableConfig2.pagination.strictSearch"
            [messages]="tableConfig2.messages"
            [autoReloadEnable]="true"
          >
          </dynamic-table>
        </div>
      </div>
    </div>
  </div>


</div>


<ng-template
  #paymentsCell
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-rowIndex="rowIndex"
  let-payments="value"
>
  <ng-container *ngIf="payments.length > 0">
    <div class="flex flex-row items-center">
      <ng-container *ngFor="let payment of payments">
        <a *ngIf="column.extra?.status?.[payment.status]"
           class="badge badge-pill {{column.extra.status[payment.status]?.class}}"
           ngbTooltip="{{payment.code}} | {{payment.amount | price}} | {{column.extra.status[payment.status]?.label}}"
           routerLink="/payments/{{payment.code}}"
        >
          {{ column.extra?.method?.[payment.method?.driver] ? column.extra?.method?.[payment.method?.driver].label : payment.method?.driver }}
        </a>
        <a *ngIf="!column.extra?.status?.[payment.status]" class="badge badge-pill badge-light-danger"
           ngbTooltip="{{payment.code}} | {{payment.amount | price}} | {{payment.status}}">
          {{ column.extra?.method?.[payment.method?.driver] ? column.extra?.method?.[payment.method?.driver].label : payment.method?.driver }}
        </a>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
