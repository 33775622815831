import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, timeout, switchMap, retryWhen, delay, takeWhile } from 'rxjs/operators';
import { throwError, interval } from 'rxjs';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class PingService {
  private readonly backendUrl = 'https://api-dev.tulero.it/api/app';
  private readonly pingEndpoint = '/ping';
  private approved: boolean = false;
  private alarmPlaying: boolean = false;
  private audio = new Audio('assets/audios/mixkit-alert-alarm-1005.wav');

  constructor(private http: HttpClient) {
    console.log('PingService');
    this.monitorServer().subscribe();
  }



  monitorServer() {
    return interval(3000).pipe(
      switchMap(() => this.checkServerAvailability()),
      retryWhen(errors => errors.pipe(delay(3000)))
    );
  }

  checkServerAvailability() {
    const url = `${this.backendUrl}${this.pingEndpoint}`;

    return this.http.get(url).pipe(
      timeout(2000),
      map(() => {
        if (this.alarmPlaying) {
          this.stopAlarm();
        }
        this.approved = true;
      }),
      catchError(() => {
        console.log('Server is down');
        // Handle error when server is down
        if (!this.approved) {
          this.approved = false;
          if (!this.alarmPlaying) {
            console.log('Play alarm');
            this.playAlarm();
          }
          Swal.fire({
            title: 'Errore',
            text: 'Il server non risponde',
            icon: 'error',
            confirmButtonText: 'Riprova',
            confirmButtonColor: '#dc3545',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then(() => {
            this.approved = true;
            this.stopAlarm();
          });
        }
        return throwError(false);
      })
    );
  }

  private playAlarm() {
    this.alarmPlaying = true;
    this.audio.loop = true;
    this.audio.play();
  }

  private stopAlarm() {
    this.alarmPlaying = false;
    this.audio.pause();
    this.audio.currentTime = 0;
  }
}
