
<div class="full-width d-flex justify-content-between">
  <div class="name">
    <h1 class="font-medium-5 px-025 my-1">Marchi</h1>
  </div>
  <div class="actions">
    <button *ngIf="_authService.checkPermission('admin.page.brandsList.add')" class="btn btn-primary px-1 mb-1" (click)="addBrand(addNewBrandModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getBrands.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>


<ng-template #addNewBrandModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="newOrder-modal">Aggiungi Marchio</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Nome</h5>
        <input type="text" class="form-control mb-1" [(ngModel)]="newBrand.name">
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Codice</h5>
        <input type="text" class="form-control mb-1" [(ngModel)]="newBrand.code">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addNewBrand()">Aggiungi</button>
  </div>
</ng-template>
