import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@core/components/core.module";
import {NgbInputDatepicker, NgbProgressbar, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import { ClipboardModule } from 'ngx-clipboard';
import { PrimesListComponent } from "./prime-list/prime-list.component";
import { PrimesViewComponent } from "./prime-view/primes-view.component";
import {SubscriptionListComponent} from "./subscriptions-list/subscriptions-list.component";

const routes: Routes = [
  {
    path: 'primes',
    component: PrimesListComponent,
    data: { animation: 'PaymentsListComponent' }
  },
  {
    path: 'primes/:id',
    component: PrimesViewComponent,
    data: { animation: 'PaymentsViewComponent' }
  },
  {
    path: 'subscriptions',
    component: SubscriptionListComponent,
    data: { animation: 'SubscriptionListComponent' }
  },
];

@NgModule({
  declarations: [
    PrimesListComponent,
    PrimesViewComponent,
    SubscriptionListComponent
  ],
  imports: [
    ClipboardModule,
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    NgbInputDatepicker,
    ReactiveFormsModule,
    NgSelectModule,
    NgbTooltip,
    FormsModule,
    NgbProgressbar
  ],
  providers: []
})
export class PrimesModule {
}
