<div class="full-width d-flex justify-content-between">
  <div class="name">
    <h1 class="font-medium-5 px-025 my-1">Utenti</h1>
  </div>
  <div class="actions">
    <button *ngIf="_authService.checkPermission('admin.page.usersList.add')" class="btn btn-primary ml-1 mb-1 px-1" (click)="addUser(addNewUserModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getUsers.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
    [autoReloadEnable]="true"
  >
  </dynamic-table>
</div>

<ng-template #addNewUserModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="changePassword-modal">Aggiungi Utenti</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- form to add a user -->
    <form class="auth-register-form mt-2" [formGroup]="modifyForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="register-username" class="form-label"
               [ngClass]="{ 'is-invalid-label':  userExist}">Username</label>
        <input
          formControlName="username"
          class="form-control"
          placeholder="johndoe"
          aria-describedby="register-username"
          tabindex="1"
          id="register-username"
          [ngClass]="{ 'is-invalid': userExist}"

        />

        <div *ngIf="userExist" class="invalid-feedback">
          <div>Username già in uso</div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <label for="register-name" class="form-label">Nome</label>
          <input
            type="text"
            formControlName="name"
            class="form-control"
            placeholder=""
            aria-describedby="register-name"
            tabindex="1"
            autofocus
            id="register-name"
          />
        </div>
        <div class="form-group col-6">
          <label for="register-surname" class="form-label">Cognome</label>
          <input
            type="text"
            formControlName="surname"
            class="form-control"
            placeholder=""
            aria-describedby="register-surname"
            tabindex="1"
            id="register-surname"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="register-email" class="form-label">Email</label>
        <input
          type="text"
          formControlName="email"
          class="form-control"
          placeholder="john@example.com"
          aria-describedby="register-email"
          tabindex="1"
          id="register-email"
        />
      </div>
      <div class="form-group">
        <label for="register-phone" class="form-label">Numero di telefono</label>
        <input
          type="text"
          formControlName="phone"
          class="form-control"
          placeholder="Numero di telefono"
          aria-describedby="register-phone"
          tabindex="1"
          id="register-phone"
        />
      </div>
      <div class="form-group mb-2">
        <label for="register-password" class="form-label">Password</label>
        <input
          type="password"
          formControlName="password"
          class="form-control"
          placeholder="Paassword"
          aria-describedby="register-phone"
          tabindex="1"
          id="register-password"
        />
      </div>

      <button type="submit" class="btn btn-primary btn-block" tabindex="5">Aggiungi</button>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>



