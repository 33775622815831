import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CustomersService} from "@core/services/customers.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableConfig} from "../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../@core/services/dynamicTable.service";

@Component({
  selector: 'app-mail-view',
  templateUrl: './mails-view.component.html',
  styleUrls: ['./mails-view.component.scss']
})

export class MailsViewComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  public emailDocument: {
    data: {
      name: string,
      email: string,
    },
    subject: string,
    template: string,
    recipients : [
      {
        name: string,
        email: string,
      }
    ];
    status: string,
    createdAt: string,
  } = {
    data: {
      name: '',
      email: '',
    },
    subject: '',
    template: '',
    recipients : [
      {
        name: '',
        email: '',
      }
    ],
    status: '',
    createdAt: '',
  }

  public currentId: string = '';

  public statuses: any = {
    CREATED: {
      class: 'badge-light-warning',
      label: 'Creato'
    },
    SENT: {
      class: 'badge-light-info',
      label: 'Inviato'
    },
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private modalService: NgbModal,
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _dynamicTableService: DynamicTableService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('mail');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.currentId = this._activatedRoute.snapshot.paramMap.get('id') || '';
    this._customerService.getMailById(this.currentId)
      .then((mail) => {
        this.emailDocument = mail;
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  sendAgain() {

  }

  sendTo() {

  }
}
