<div class="row" *ngIf="loaded | async">
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Fattura {{ currentCode }}</h5>
        <p class="card-text mb-05"><b class="card-text-title">Azienda: </b><a
          class="business-name">{{ currentInvoice.businessData.name }}</a>
        </p>
        <p class="card-text mb-05"><b class="card-text-title">P.IVA: </b> {{ currentInvoice.business.vat }}</p>
        <p class="card-text mb-05">
          <b class="card-text-title">Data: </b>
          {{ currentInvoice.createdAt | date: 'dd/MM/yyyy HH:mm' }}
        </p>
        <p class="card-text mb-05">
          <b class="card-text-title">Magazzino: </b>
          {{ currentInvoice.warehouse.name }} ({{ currentInvoice.warehouse.code }})
        </p>
        <p class="card-text mb-05"><b class="card-text-title">Stato: </b>
          <span
            [ngClass]="{
                        'badge-light-primary': currentInvoice.status == 'COMPLETED' || currentInvoice.status == 'CONFIRMED' || currentInvoice.status == 'DELIVERED',
                        'badge-light-success': currentInvoice.status == 'ACTIVATED' || currentInvoice.status == 'SHIPPED',
                        'badge-light-danger': currentInvoice.status == 'REJECTED' || currentInvoice.status == 'FAILED' || currentInvoice.status == 'CANCELED',
                        'badge-light-warning': currentInvoice.status == 'PAIDBACK' || currentInvoice.status == 'RETURNED' || currentInvoice.status == 'REFUNDED',
                        'badge-light-info': currentInvoice.status == 'CREATED' || currentInvoice.status == 'PENDING' || currentInvoice.status == 'APPROVED'
                      }"
            class="badge badge-pill"
          >
            {{ currentInvoice.status }}
          </span>
        </p>
        <div class="card-body" *ngIf="currentInvoice.invoice">
          <div>
            <p class="card-text mb-05">
              <b>Numero: </b>
              <a class="badge badge-pill badge-light-primary"
                 [routerLink]="'/tulero-b2b/invoice/detail/' + currentInvoice.invoice._id">{{ currentInvoice.invoice.code }}</a>
            </p>
            <p class="card-text mb-05">
              <b>Data: </b> {{ currentInvoice.invoice.invoiceDate | date: 'dd/MM/yyyy' }}
            </p>
            <p class="card-text mb-05" *ngIf="currentInvoice.invoice.sent">
              <b>Inviata </b> {{ currentInvoice.invoice.sent | date: 'dd/MM/yyyy' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
