// Importing necessary modules and components from Angular and other libraries
import {Component, OnDestroy, OnInit, Inject, PLATFORM_ID, NgZone, Input, TemplateRef} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {CustomersService} from "@core/services/customers.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Group, GroupPriceRule, GroupPromotion, GroupSupplier} from "@core/types/data/group";
import {Chart, registerables} from 'chart.js'; // Importing Chart.js for chart creation
Chart.register(...registerables); // Registering Chart.js components

import * as am5 from '@amcharts/amcharts5'; // Importing amCharts library
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {FormGroup, FormBuilder, FormArray} from "@angular/forms";
import Swal from "sweetalert2";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap"; // Importing amCharts Animated theme

@Component({
  selector: 'app-groups-view',
  templateUrl: './groups-view.component.html',
  styleUrls: ['./groups-view.component.scss']
})
export class GroupsViewComponent implements OnInit, OnDestroy {
  private root!: am5.Root; // Declaration of a private variable root of type am5.Root
  // Add necessary properties to your component
  public loaded: Promise<boolean> = Promise.resolve(false);
  public isModalOpen: boolean = false;
  public modalTitle: string = '';
  public editData: GroupPriceRule[] = []; // Replace 'any' with appropriate type
  public newRule: any = {}; // Replace 'any' with appropriate type
  public isAddingNewRule: boolean = false;
  // Define a FormGroup and FormArray
  editFormGroup: FormGroup;
  newRuleFormGroup: FormGroup;
  ViewFormGroup: FormGroup;
  isEditModalOpen = false; // or whatever your initial value is
  isAddModalOpen = false; // or whatever your initial value is
  isViewModalOpen = false;
  editModalTitle = 'Edit pricing rules'; // Replace with your actual title
  addModalTitle = 'Add a pricing rule'; // Replace with your actual title
  viewModalTitle = 'Pricing rules';
  editType: 'default' | 'supplier' | 'promo' = 'default';
  editingIndex: number = 0;
  SelectedItem: any = 'Select a item';

// Methods to handle opening/closing modals

  closeEditModal() {
    this.isEditModalOpen = false;
  }

  closeViewModal() {
    this.isViewModalOpen = false;
  }

  closeAddModal() {
    this.isAddModalOpen = false;
    // Other necessary logic
  }

  submitEditChanges(index: number) {
    if (this.editType === 'default') {
      this.group.default.priceRules = this.editData; // Replace with the correct property path
      console.log(this.group.default.priceRules);
    } else if (this.editType === 'supplier') {

    } else if (this.editType === 'promo') {

    }
    // Implement logic to update the data (edit or add rule)
    // Refresh charts or data display after updating the rules
    // Call appropriate functions to update the charts or data display
    // Close the modal after changes are made
    this.closeEditModal();
  }

  submitAddRule() {
    this.closeAddModal();
  }


  openViewModal(data: GroupPriceRule[]) {
    console.log(data);
    console.log(this.editData);
    this.editData = data;
    this.isViewModalOpen = true;
    this.isAddingNewRule = false;
    console.log(this.isModalOpen);
  }

// Function to open modal for adding new rule
  openAddModal(group: GroupPriceRule[]) {
    this.isAddModalOpen = true;
    this.modalTitle = 'Add Pricing Rule';
    this.newRule = {}; // Clear previous new rule data
    this.isAddingNewRule = true;

  }

// Function to close the modal
  closeModal() {
    this.isModalOpen = false;
  }

// Function to handle submitting changes
  submitChanges() {
    // Implement logic to update the data (edit or add rule)
    // Refresh charts or data display after updating the rules
    // Call appropriate functions to update the charts or data display
    // Close the modal after changes are submitted
    this.closeModal();
  }


  // Private variables
  private _unsubscribeAll: Subject<any>; // Subject to unsubscribe from subscriptions

  // Public variables
  public code: string = ''; // Public variable to store a string code
  public group: Group = {} as Group; // Public variable to store a Group object

  constructor(
    private formBuilder: FormBuilder,
    // Injecting services and dependencies required for this component
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
  ) {
    // Initializing private variables and setting defaults in the constructor
    this._unsubscribeAll = new Subject(); // Initializing _unsubscribeAll as a Subject
    this._applicationService.layoutContentWidth(); // Setting layout content width
    this._applicationService.selectMenu('customers-groups-list'); // Selecting a menu option
    this.editFormGroup = this.formBuilder.group({
      rules: this.formBuilder.array([])
    });
    this.ViewFormGroup = this.formBuilder.group({
      rules: this.formBuilder.array([])
    });

    this.newRuleFormGroup = this.formBuilder.group({
      from: '',
      markUp: ''
      // Add additional fields for newRule as needed
    });
  }


  // Lifecycle hook - On initialization
  ngOnInit() {
    // Retrieving 'code' from the route or setting an empty string
    this.code = this._route.snapshot.paramMap.get('code') || '';
    console.log({code: this.code});

    // Fetching a single group from the customer service
    this._customerService.getSingleGroup(this.code)
      .then((response: Group) => {
        console.log({response});
        this.loaded = Promise.resolve(true); // Setting loaded to true
        this.group = response; // Setting the fetched response to the 'group' variable
      });
  }

  // Lifecycle hook - On component destruction
  ngOnDestroy(): void {
    // Unsubscribing from all subscriptions to prevent memory leaks
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  setupEditFormControls() {
    const editRulesArray = this.editFormGroup.get('rules') as FormArray;
    editRulesArray.clear();

    for (const rule of this.editData) {
      const ruleGroup = this.formBuilder.group({
        from: rule.from,
        markup: rule.markup
      });
      editRulesArray.push(ruleGroup);
    }
  }

  // Lifecycle hook - After the view initialization
  SelectedPromo: any = 'Select a promotion';



  editRules() {
    this.modalService.dismissAll();
    this._customerService.editGroup(this.group.id, this.group)
      .then(() => {
        Swal.fire("Modificato!", "Il tuo gruppo è stato modificato.", "success");
      })
      .catch((error: any) => {
        Swal.fire("Errore", "Si è verificato un errore durante la modifica del gruppo", "error");
      });
  }


  AddRulesSupplier(supplier: GroupSupplier) {

  }

  editRulesDefault(group: Group) {

  }

  AddRulesDefault(group: Group) {

  }

  editRulesPromo(promo: GroupPromotion) {

  }

  AddRulesPromo(promo: GroupPromotion) {

  }

  deleteRule(index: number) {
    this.group.default.priceRules.splice(index, 1);
  }

  deleteRuleFromArray(rulesArray: GroupPriceRule[], ruleToDelete: GroupPriceRule): GroupPriceRule[] {
    const indexToDelete = rulesArray.findIndex(rule => rule === ruleToDelete);
    if (indexToDelete !== -1) {
      rulesArray.splice(indexToDelete, 1);
    }
    return rulesArray;
  }

  deleteGroup() {
    Swal.fire({
      title: "Sei sicuro?",
      text: "Non sarai in grado di annullare questa operazione!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--primary)",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì"
    }).then((result) => {
      if (result.isConfirmed) {
        this._customerService.deleteGroup(this.group.id)
          .then(() => {
            Swal.fire("Eliminato!", "Il tuo gruppo è stato eliminato.", "success");
            // navigate to the groups list page
            this.router.navigate(['/groups']);
          })
          .catch((error: any) => {
            Swal.fire("Errore", "Si è verificato un errore durante l'eliminazione del gruppo", "error");
          });
      }
    });
  }

  openEditModal(editPricingRules: TemplateRef<any>) {
    this.modalService.open(editPricingRules, {size: 'md'});
  }

  addRule() {
    const newRule = {
      from: 0,
      markup: 0
    };
    this.group.default.priceRules.push(newRule);
  }
}
