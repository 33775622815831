import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials2caps'
})
export class Initials2CapsPipe implements PipeTransform {
  transform(fullName: string): any {
    return fullName
      ?.split(/[^a-zA-Z0-9]/)
      .map(n => n[0])
      .filter(n => n && isNaN(Number(n)) && n.toUpperCase() === n)
      .join('')
      .substring(0, 2);
  }
}
