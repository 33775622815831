<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Metodi di pagamento</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.paymentMethodsList.add')" class="btn btn-primary px-1" (click)="addPaymentMethod(addNewPaymentMethodModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getPaymentMethods.bind(this)"
    [columns]="tableConfig.columns"
    [filterGroups]="tableConfig.filterGroups"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>

<ng-template #addNewPaymentMethodModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="newOrder-modal">Aggiungi metodo di pagamento</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Codice: </span>
      <input type="text" class="form-control" [(ngModel)]="newPaymentMethod.code"/>
    </p>
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Nome: </span>
      <input type="text" class="form-control" [(ngModel)]="newPaymentMethod.name"/>
    </p>
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Etichetta: </span>
      <input type="text" class="form-control" [(ngModel)]="newPaymentMethod.label"/>
    </p>
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Descrizione: </span>
      <input type="text" class="form-control" [(ngModel)]="newPaymentMethod.description"/>
    </p>
    <div class="row">
      <div class="col-6">
        <p class="card-text mb-05 mt-1">
          <input type="checkbox" class="form-check-input mr-05" [(ngModel)]="newPaymentMethod.default"/>
          <span class="card-text-title">Default </span>
        </p>
      </div>
      <div class="col-6">
        <p class="card-text mb-05 mt-1">
          <input type="checkbox" class="form-check-input mr-05" [(ngModel)]="newPaymentMethod.active"/>
          <span class="card-text-title">Attivo </span>
        </p>
      </div>
    </div>
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Fatture: </span>
      <ng-select id="invoiceCodes" [items]="invoiceCodes" bindLabel="label" bindValue="value"
                 placeholder="Seleziona un codice fattura"
                 [(ngModel)]="newPaymentMethod.invoiceCode"
                 addTagText="Usa "
                 [addTag]="true"
      >
      </ng-select>
    </p>
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Driver: </span>
      <ng-select id="drivers" [items]="drivers" bindLabel="value"
                 placeholder="Seleziona un driver"
                 [(ngModel)]="newPaymentMethod.driver"
                 addTagText="Usa "
                 [addTag]="true"
      >
      </ng-select>
    </p>
    <div class="row mb-3">
      <div class="col-6 mt-1">
            <span>
              <input class="form-check-input" type="checkbox" [(ngModel)]="endOfMonthCheckbox"
                     (change)="toggleEndOfMonth()">
              Fine mese
            </span>
      </div>
      <div class="col-6 mt-1">
            <span>
              <input class="form-check-input custom-check-box" type="checkbox" [(ngModel)]="cumulativeDaysCheckbox"
                     (change)="toggleCumulativeDays()">
              Giorni cumulativi
            </span>
      </div>
      <div class="col-6 mt-1">
        <input type="number" class="form-control" [(ngModel)]="newPaymentMethod.endOfMonth"
               [disabled]="!endOfMonthCheckbox">
      </div>
      <div class="col-6 mt-1">
        <input type="number" class="form-control " [(ngModel)]="newPaymentMethod.cumulativeDays"
               [disabled]="!cumulativeDaysCheckbox">
      </div>
      <div class="col-6 mt-1">
        <p class="card-text mb-05"><span class="card-text-title">Min: </span>
          <input type="text" class="form-control" [(ngModel)]="newPaymentMethod.min"/>
        </p>
      </div>
      <div class="col-6 mt-1">
        <p class="card-text mb-05"><span class="card-text-title">Max: </span>
          <input type="text" class="form-control" [(ngModel)]="newPaymentMethod.max"/>
        </p>
      </div>
    </div>
    <h5 class="card-title">Rate</h5>
    <div class="alert alert-danger" *ngIf="feeSum !== 100">
      La somma delle rate non è 100%
    </div>
    <table class="table no-border">
      <thead>
      <tr class="no-border">
        <th class="custom-card-text-title no-border">Scadenza (Giorni)</th>
        <th class="custom-card-text-title no-border">Rata</th>
      </tr>
      </thead>
      <tbody class="no-border">
      <tr class="no-border" *ngFor="let installment of newPaymentMethod.installments; let i = index">
        <td class="no-border">
          <input type="number" class="form-control " [(ngModel)]="installment.expiration"/>
        </td>
        <td class="no-border">
          <input type="number" class="form-control" [(ngModel)]="installment.fee" (input)="recalculateFee()"/>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-10"></div>
      <div class="col-2 d-flex justify-content-end">
        <button class="px-1 col-12 btn btn-primary btn-block" (click)="addInstallment()">
          <app-svg-icon name="add"></app-svg-icon>
        </button>
      </div>
    </div>
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Immagine: </span>
      <input type="text" class="form-control" [(ngModel)]="newPaymentMethod.image"/>
    </p>
    <div class="logo-container">
      <img class="logo-gallery" *ngIf="!newPaymentMethod.image" src="assets/images/catalog/default-product-picture.png">
      <img class="logo-gallery" *ngIf="newPaymentMethod.image" [src]="newPaymentMethod.image">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addNewPaymentMethod()">Aggiungi</button>
  </div>
</ng-template>
