import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SessionsListComponent } from "./sessions-list/sessions-list.component";
// import { SessionsViewComponent } from "./sessions-view/sessions-view.component";
import { CoreModule } from "@core/components/core.module";

const routes: Routes = [
  {
    path: 'sessions',
    component: SessionsListComponent,
    data: { animation: 'SessionsListComponent' }
  },
  // {
  //   path: 'sessions/:code',
  //   component: SessionsViewComponent,
  //   data: { animation: 'SessionsViewComponent' }
  // },
];

@NgModule({
  declarations: [
    SessionsListComponent,
    // SessionsViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule
  ],
  providers: []
})
export class SessionsModule {
}
