<div class="row" *ngIf="loaded | async">
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Carello {{ currentId }}</h5>
        <a class="business-name card-text mb-05 "
           routerLink="/businesses/{{cart.business.code}}">
          <app-svg-icon name="link"></app-svg-icon>
          <b>{{ cart.business.code }}</b> {{ cart.business.name }}</a>
        <p class="card-text mb-05"><b class="card-text-title">P.IVA: </b> {{ cart.business.vat }}</p>
        <p class="card-text mb-05">
          <b class="card-text-title">Data: </b>
          {{ cart.createdAt | date: 'dd/MM/yyyy HH:mm' }}
        </p>
      </div>
    </div>
  </div>
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Magazzini</h5>
        <p class="card-text mb-05"><b class="card-text-title">Quantità di magazzini: </b> {{ cart.suppliers.length }}
        </p>
        <p class="card-text mb-05">
          <b class="card-text-title">Lista magazzini: </b>
          <span *ngFor="let supplier of cart.suppliers; let last = last">
            {{ supplier.supplier.name | titlecase }} ({{ supplier.supplier.label }}) {{ last ? '' : ', ' }}
          </span>
        </p>
        <div class="d-flex justify-content-center mt-1">
          <button class="btn btn-primary" (click)="addSupplierModal(addWarehouseModal)">
            <app-svg-icon name="plus"></app-svg-icon>
            Magazzino
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Prodotti</h5>
        <p class="card-text mb-05"><b class="card-text-title">Quantità di prodotti: </b> {{ productsQuantity }}</p>
        <p class="card-text mb-05">
          <b class="card-text-title">Prezzo totale: </b>
          {{ totalPrice | price }}
        </p>
      </div>
    </div>
  </div>
  <div class="col-2 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Sconti</h5>
        <p *ngIf="cart.discount" class="card-text mb-05"><b class="card-text-title">Codice sconto
          applicato: </b> {{ cart.discount }}</p>
        <p class="card-text mb-05">
          <b class="card-text-title">codici sconto disponibili: </b>

        </p>
      </div>
    </div>
  </div>
  <div class="col-2 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Azioni</h5>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-primary btn-block" (click)="saveCart()">
            <app-svg-icon name="save"></app-svg-icon>
            Salva
          </button>
        </div>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-danger btn-block" (click)="deleteCart()">
            <app-svg-icon name="delete"></app-svg-icon>
            Elimina
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-container>
    <div class="row">
      <div class="single-order col-6" *ngFor="let supplier of cart.suppliers"
           [ngClass]="{ 'notSelected-supplier': supplier.active }">
        <div class="d-flex">
          <div class="card full-width">
            <div class="card-body d-flex flex-column">
              <div class="card-title">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <h5 class="card-title supplier-name">
                      {{ supplier.supplier.name | titlecase }} ({{ supplier.supplier.label }})
                      <app-svg-icon class="delete-icon cursor-pointer " name="delete"
                                    (click)="removeSupplierFromCart(supplier)"></app-svg-icon>
                    </h5>
                  </div>
                  <div class="supplier-details d-none d-md-flex">
                    <div class="d-inline-block mr-2">
                      <div>
                        <div class="d-inline-block mr-2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th colspan="2"><p class="card-text mb-05"><b class="card-text-title custom-card-text-title">Prodotto </b></p></th>
                      <th class="text-center"><p class="card-text mb-05"><b class="card-text-title custom-card-text-title">Quantità</b></p>
                      </th>
                      <th class=" text-center"><p class=" text-center card-text mb-05"><b class="card-text-title custom-card-text-title">Prezzo </b></p></th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let product of supplier.products">
                      <td class="align-middle">
                        <img *ngIf="product.product.pictures.length===1" class="img-thumbnail"
                             src="{{product.product.pictures}}">
                        <img *ngIf="product.product.pictures.length>1" class="img-thumbnail"
                             src="{{product.product.pictures[0]}}">
                        <img *ngIf="product.product.pictures.length<1" class="img-thumbnail"
                             src="assets/images/catalog/default-product-picture.png"
                             class="img-thumbnail">
                      </td>
                      <td class="align-middle"><p class="card-text"> <b>{{ product.product.code }}</b> {{ product.product.name }} </p></td>
                      <td class="text-center align-middle text-nowrap">
                        <div class="form-check-inline quantity-buttons-section">
                          <button class="btn btn-primary quantity-buttons" (click)="decreaseQuantity(product)">
                            -
                          </button>
                        </div>
                        <div class="form-check-inline quantity-buttons-section">
                          <input type="text" class="form-control text-center quantity-number"
                                 [(ngModel)]="product.quantity">
                        </div>
                        <div class="form-check-inline quantity-buttons-section">
                          <button class="btn btn-primary quantity-buttons" (click)="increaseQuantity(product)">
                            +
                          </button>
                        </div>
                      </td>
                      <td class="align-middle text-nowrap">
                        <div class="input-group pl-3 pr-3">
                          <div class="input-group-append">
                            <span class="customized-input-group-text input-group-text">€</span>
                          </div>
                          <input type="text" class="form-control text-center price-input-box" [(ngModel)]="product.price" (input)= updatePrices()>
                        </div>
                      </td>
                      <td class="align-middle">
                        <app-svg-icon class="delete-icon cursor-pointer" name="delete"
                                      (click)="removeProductFromCart(product)"></app-svg-icon>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="d-flex justify-content-end mb-1">
                  <button class="btn btn-primary" (click)="openAddProductModal(addProductModal, supplier)">
                    <app-svg-icon name="plus"></app-svg-icon>
                    Prodotto
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>


<ng-template #addWarehouseModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="warehouse-modal">Aggiungi magazzino</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="warehouse">Magazzino</label>
          <ng-select id="warehouse" [items]="warehouses" bindLabel="name"
                     placeholder="Seleziona un magazzino"
                     [(ngModel)]="selectedWarehouse">
          </ng-select>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" (click)="addSupplierToCart()" class="btn btn-primary"> Aggiungi</button>
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>

<ng-template #addProductModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="product-modal">Aggiungi prodotto</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0">
    <div class="row">
      <div class="form-group col-12">
        <label for="product">Prodotto</label>
        <input placeholder="CERCA..." type="text" class="form-control mb-2" id="product"
               [(ngModel)]="newProduct.product.name"
               (input)="searchProductsByName(newProduct.product.name)">
      </div>
      <div *ngIf="products.length === 0">
        <div class="col-12 d-flex justify-content-center">
          <p class="text-center">Nessun prodotto trovato in questo magazzino</p>
        </div>
      </div>
      <div class="product-card col-2 cursor-pointer" *ngFor="let product of products"
           (click)="addProductToCart(product)">
        <div class="d-flex flex-column">
          <div class="d-inline-block center text-center">
            <img *ngIf="product.pictures.length>1" class="product-image" src="{{product.pictures[0]}}">
            <img *ngIf="product.pictures.length===1" class="product-image" src="{{product.pictures}}">
            <img *ngIf="product.pictures.length<1" src="assets/images/catalog/default-product-picture.png"
                 class="product-image">
          </div>
          <div class="card-title mt-1">
            <div class=" d-flex justify-content-between">
              <div class="supplier-name">
                <label for="{{product}}">
                  <span class="mb-0">{{ product.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class=" modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>
