import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { DynamicData } from "../types/dynamic-table/dynamic-data";
import { ListParams } from "../types/api/list-params";
import { Group } from "../types/data/group";
import { array } from "@amcharts/amcharts5";

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  // -----------------------------------------------------------------------------------------------------
  // @ public
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ private
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  getUsers(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('users', 'list', {}, options)
        .then((response) => {
          if (!response.data) {
            return
          }
          for (let user of response.data.rows) {
            user['links'] = {
              "product-detail": "https://www.google.com"
            }
          }
          resolve(response.data as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getUserList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('users', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSessionList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('sessions', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getNetworkList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('networks', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getBusinessList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('businesses', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }
  getBusinessListComplex(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['body'] = queryParams
      }
      this._apiService.make('businesses', 'getComplex', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getBusinessesList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'get', {})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getBusinessStatusList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('businesses', 'statusList', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleBusiness(code: string, options = {}) {
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'viewFromCode', { code }, options)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }
  getSingleBusinessForView(code: string, options = {}) {
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'viewFromCodeForView', { code }, options)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  setBusinessWallet(code: string, wallet: string, amount: number, reason: string, options: any = {}) {
    options['body'] = { amount, reason }
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'setWallet', { code, wallet }, options)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getBusinessWalletHistory(code: string, wallet: string, options: any = {}) {
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'getWalletHistory', { code, wallet }, options)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleBusinessOrders(code: string, queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('businesses', 'getOrders', { code }, options)
        .then((response) => {
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleBusinessOrderStats(code: string, options = {}) {
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'getOrderStats', { code }, options)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getGroupList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('groups', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleGroup(code: string): Promise<Group> {
    return new Promise((resolve, reject) => {
      this._apiService.make('groups', 'viewFromCode', { code })
        .then((response) => {
          resolve(response as Group)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  editGroup(id: string, group: Group): Promise<Group> {
    return new Promise((resolve, reject) => {
      this._apiService.make('groups', 'edit', { id }, { body: { group } })
        .then((response) => {
          resolve(response as Group)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }


  editBusiness(id: string, business: any): Promise<any> {
    console.log('business in request', business)
    const section = business;
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'editSections', { id }, { body: {section} })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleUser(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('users', 'viewFromCode', { code })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getBusinesses(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('users', 'getBusinesses', { id })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getIdentities(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('users', 'getIdentities', { id })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleBusinessById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'view', { id })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  updateUserPassword(id: string, password: string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('users', 'changePassword', { id }, { body: { password } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });

  }

  userEdit(id: string, user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('users', 'edit', { id }, { body: { user } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  setBusinessToUser(user_id: string, business_id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('users', 'setBusinessToUser', { user_id }, { body: { user_id, business_id } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  revokeBusiness(identity_id: string, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('identity_id', identity_id)
      console.log('id', id)
      this._apiService.make('users', 'revokeBusiness', { id }, { body: { identity_id, id } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  switchIdentity(identity_id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('users', 'switchIdentity', { identity_id }, {})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });


  }

  newUser(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('users', 'newUser', {}, { body: { user } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  newBusiness(business: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'newBusiness', {}, { body: { business } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getWallets(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'getWallets', { code })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('groups', 'get', {})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getNetworks(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('networks', 'get', {})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getItemById(item: string, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'getItemById', { item, id })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getUsersByBusiness(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('businesses', 'getUsers', { id })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getIdentity(user_id: string, business_id: string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('users', 'getIdentity', { user_id, business_id })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });

  }

  getToken(identity: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('identities', 'getToken', { identity })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });

  }

  loginByToken(identity: string): Promise<any> {
    return new Promise((resolve, reject) => {
      //get our browser ip and store it in a variable
      const ip = window.location.hostname;
      this._apiService.make('identities', 'loginIdentity', {}, { body: { identity, ip } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getNetworkByCode(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('networks', 'view', { code })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  saveNetworkbyCode(code: string, network: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('networks', 'edit', { code }, { body: { network } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  newGroup(group: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('groups', 'newGroup', {}, { body: { group } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  newNetwork(network:any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('networks', 'newNetwork', {}, { body: {network} })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deleteGroup(id: string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('groups', 'delete', { id })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deleteNetworkById(id: string, code:string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('networks', 'delete', { id, code })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  async getMailList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('mail', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getMailById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('mail', 'view', { id })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  sendMail(newEmail: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('mail', 'newMail', {}, { body: newEmail })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getIdentityList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('identities', 'getIdentities', {}, options)
        .then((response) => {
          console.log('response', response)
          if (!response) {
            console.log('no response')
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getIdentityById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('identities', 'getIdentity', { id })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  updateIdentity(identity: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('identities', 'updateIdentity', { id: identity.id }, { body: { identity } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getApplications(queryParams?: ListParams): Promise<DynamicData> {
    console.log('here');
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('applications', 'get', {}, options)
        .then((response) => {
          console.log('response', response)
          if (!response.rows) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  createIdentity(identity: { application: any; business: any; permissions: any[]; user: any }) {
    return new Promise((resolve, reject) => {
      this._apiService.make('identities', 'newIdentity', {}, { body: { user:identity.user, business:identity.business, application:identity.application } })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getPermissions(queryParams?: ListParams): Promise<DynamicData> {
    console.log('here');
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      console.log('options', options)
      this._apiService.make('permissions', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          else {
            resolve(response)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }
}
