<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="robots" content="noindex">
  <title>Coming Soon</title>
  <style>

  </style>
</head>
<body>
<div class="container">
  <div class="logo">
    <img class="logo" [src]="logo">
    <span>Pagina non trovata</span>
  </div>
  <h1 class="title">La pagina che stai cercando non è stata trovata...</h1>

  <a routerLink="/" class="btn btn-primary">Torna alla home</a>

</div>

