<div class="row" *ngIf="loaded | async">
  <div class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Fornitore {{ supplier.name }}</h5>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Nome: </span>
          <input type="text" class="form-control" [(ngModel)]="supplier.name"/>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Codice: </span>
          <input type="text" class="form-control" [(ngModel)]="supplier.code"/>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Descrizione: </span>
          <textarea class="form-control mb-1" [(ngModel)]="supplier.description"></textarea>
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title ">Commenti</h5>
        <div class="comment-container">
          <ng-container *ngIf="comments.length > 0">
            <ul class="list-unstyled">
              <li *ngFor="let comment of comments" class="mb-1">
                <div class="">
                  <div class="comment-text">
                    <p class="card-text mb-0">
                      {{ comment.text }}
                    </p>
                  </div>
                  <div class="comment-user mt-05">
                    <span class="avatar mr-1">
                      <img
                        [src]="comment.user.avatar || 'assets/images/avatars/default-avatar.png'"
                        (error)="comment.user.avatar = 'assets/images/avatars/default-avatar.png'"
                        alt="avatar"
                        class="rounded-circle"
                        width="18"
                        height="18"
                      >
                    </span>
                    <span class="font-small-2 font-weight-bold mr-1">{{ comment.user.name }}</span>
                    <span class="font-small-2">{{ comment.createdAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="comments?.length == 0">
            <p>Nessun commento trovato</p>
          </ng-container>
        </div>
      </div>
      <div class="card-footer">
        <div class="form-group">
          <div class="input-group">
            <textarea class="form-control" id="comment" rows="2"
                      [(ngModel)]="commentText" name="comment"
                      (keydown.control.enter)="addComment()"></textarea>
            <button class="btn btn-outline-primary px-1" (click)="addComment()">
              <app-svg-icon class="d-inline-block align-middle" name="send"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4 col-lg-2 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Azioni</h5>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-primary btn-block" (click)="saveSupplier()">
            <app-svg-icon name="save"></app-svg-icon>
            Salva
          </button>
        </div>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-danger btn-block" (click)="deleteSupplier()">
            <app-svg-icon name="delete"></app-svg-icon>
            Elimina
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end align-items-center mb-05">
    <button class="btn btn-primary px-1 btn-normal" (click)="addWarehouse(addNewWarehouseModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
  <div class="col-12 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Magazzini</h5>
        <dynamic-table
          [dataRetriever]="getWarehouses.bind(this)"
          [columns]="tableConfig.columns"
          [page]="tableConfig.pagination.page"
          [pageSize]="tableConfig.pagination.pageSize"
          [sort]="tableConfig.pagination.sort"
          [search]="tableConfig.pagination.search"
          [messages]="tableConfig.messages"
        >
        </dynamic-table>
      </div>
    </div>
  </div>
</div>

<ng-template
  #activeCell
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-rowIndex="rowIndex"
  let-active="value"
  let-row="row"
>
  <div class="col-12">
    <input type="checkbox" class="btn-check" id="btn-check-outlined-{{row.code}}" autocomplete="off"
           (change)="changeWarehouse($event, row)" [checked]="row.active">
    <label class="btn btn-outline-primary btn-checkbox full-width" for="btn-check-outlined-{{row.code}}">
      <span *ngIf="row.active">Abilitato</span>
      <span *ngIf="!row.active">Disabilitato</span>
    </label>
  </div>


</ng-template>



<ng-template #addNewWarehouseModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="newWarehouse-modal">Aggiungi un nuovo magazzino</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Codice: </span>
      <input type="text" class="form-control" [(ngModel)]="newWarehouse.code"/>
    </p>
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Nome: </span>
      <input type="text" class="form-control" [(ngModel)]="newWarehouse.name"/>
    </p>
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Etichetta: </span>
      <input type="text" class="form-control" [(ngModel)]="newWarehouse.label"/>
    </p>
    <div class="form-check form-check-primary  mt-1">
      <input type="checkbox" class="form-check-input mr-05" id="active" [(ngModel)]="newWarehouse.active">
      <span class="card-text-title mt-1" for="active">Attivo</span>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-normal" (click)="addnewWarehouse()">Aggiungi</button>
  </div>
</ng-template>
