<div class="row">
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Pagamento {{ payment.code }}</h5>
        <p class="card-text mb-05"><b class="card-text-title">Stato: </b>
          <span
            [ngClass]="{
                        'badge-light-success': payment.status == 'PAID' ,
                        'badge-light-danger': payment.status == 'REJECTED' || payment.status == 'FAILED' ,
                        'badge-light-warning': payment.status == 'PENDING' ,
                      }"
            class="badge badge-pill"
          >
            <ng-container *ngIf="paymentStatus?.[payment.status]">
              {{ paymentStatus[payment.status].label }}
            </ng-container>
            <ng-container *ngIf="!paymentStatus?.[payment.status]">
              {{ payment.status }}
            </ng-container>
          </span>
        </p>
        <p class="card-text mb-05"><b class="card-text-title">Metodo di
          pagamento: </b> {{ payment.method.label || '-' }}
        </p>
        <p class="card-text mb-05">
          <b class="card-text-title">Creato il: </b>
          {{ payment.createdAt | date: 'dd/MM/yyyy HH:mm' }}
        </p>
        <p class="card-text mb-05">
          <b class="card-text-title">Modificato il: </b>
          {{ payment.updatedAt | date: 'dd/MM/yyyy HH:mm' }}
        </p>
        <p class="card-text mb-05">
          <b class="card-text-title">Riferimento: </b>
          {{ payment.reference || '-' }}
        </p>
        <div class="d-flex flex-row" *ngIf="payment.url">
          <a class="card-text mb-05 mr-1"
             routerLink="{{payment.url}}">
            <app-svg-icon name="link"></app-svg-icon>
            <b>Link al pagamento</b>
          </a>
          <app-svg-icon (click)="copyToClipboard()"  ngbTooltip="Copia negli appunti" placement="bottom"  class="cursor-pointer" ngxClipboard [cbContent]="payment.url" name="copy"></app-svg-icon>
          <span *ngIf="linkCopied">Copiato!</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Riposta</h5>
        <div class="response-details  d-flex flex-column">
          <ng-container *ngIf="payment.response">
            <pre class="response-details-value">{{ payment.response | json }}</pre>
          </ng-container>
          <ng-container *ngIf="!payment.response">
            <p class="response-details-value">-</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Dettagli</h5>
        <div class="payment-details  d-flex flex-column">
          <table class="full-width">
            <tbody>
            <tr>
              <td class="payment-details-label font-weight-bold font-small-2 text-uppercase">Quantita:</td>
              <td
                class="payment-details-value text-right h6 pt-50">{{ payment.amount | price }}
              </td>
            </tr>
            <tr>
              <td class="payment-details-label font-weight-bold font-small-2 text-uppercase">Cashback:</td>
              <td
                class="payment-details-value text-right h6 pt-50">{{ payment.cashback | price }}
              </td>
            </tr>
            <tr>
              <td class="payment-details-label font-weight-bold font-small-2 text-uppercase">Valuta:</td>
              <td
                class="payment-details-value text-right h6 pt-50">{{ payment.currency | price }}
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title mb-1">Azioni</h5>
        <button class="btn btn-success btn-block mb-1" (click)="approvePayment()">
          <app-svg-icon class="d-inline-block align-middle" name="info"></app-svg-icon>
          <span class="align-middle">Approva</span>
        </button>
        <button class="btn btn-danger btn-block mb-1" (click)="rejectPayment()">
          <app-svg-icon class="d-inline-block align-middle" name="forbidden"></app-svg-icon>
          <span class="align-middle">Rifiuta</span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Ordini</h5>
        <dynamic-table
          [dataRetriever]="getOrders.bind(this)"
          [columns]="tableConfig.columns"
          [filterGroups]="tableConfig.filterGroups"
          [page]="tableConfig.pagination.page"
          [pageSize]="tableConfig.pagination.pageSize"
          [sort]="tableConfig.pagination.sort"
          [search]="tableConfig.pagination.search"
          [strictSearch]="tableConfig.pagination.strictSearch"
          [messages]="tableConfig.messages"
          [autoReloadEnable]="true"
        >
        </dynamic-table>
      </div>
    </div>
  </div>
</div>
