<div class="header-container glassy" *ngIf="currentUser?.user && currentUser?.business">
  <div class="row">
    <div class="col-1 menu-icon" *ngIf="sidebarHidden">
      <app-svg-icon name="menuItems" class="ml-1" (click)="toggleSideNav()"></app-svg-icon>
    </div>
    <div class="col-1 mt-1" *ngIf="!sidebarHidden">
      <app-svg-icon name="MenuClose" class="close-menu ml-1" (click)="toggleSideNav()"></app-svg-icon>
    </div>
    <div class="col-9">

    </div>
    <div class="col-2 text-right justify-content-end">
      <div class="d-flex flex-row justify-content-end mr-05">
        <ng-container class="user-nav d-flex flex-column">
          <div class="user-nav d-sm-flex d-none flex-column mr-05">
            <span class="text-primary user-name font-weight-bolder">{{ currentUser.user.username }}</span>
            <span class="user-status">{{ currentUser.business.name }}</span>
          </div>
          <span class="avatar"
          ><img
            class="round mt-025"
            src="https://cdn0.iconfinder.com/data/icons/shopping-197/48/bl_872_profile_avatar_anonymous_customer_user_head_human-512.png"
            alt="avatar"
            height="40"
            width="40"/><span class="avatar-status-online"></span
          ></span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
