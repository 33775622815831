<div class="row" *ngIf="loaded | async">
  <h3 class="card-title mb-1 col-12"> Gruppo {{ group.name }}</h3>
  <div class="row">
    <div class="col-4">
      <div class="card glassy card-full-height">
        <div class="card-body p-1">
          <h5 class="card-title">Nome</h5>
          <p class="card-text">{{ group.name }}</p>
          <h5 class="card-title">Codice</h5>
          <p class="card-text">{{ group.code }}</p>
          <h5 class="card-title">Descrizione</h5>
          <p class="card-text">{{ group.description || '-' }}</p>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="card glassy card-full-height">
        <div class="card-header pb-0">
          <h4 class="card-title">Azioni</h4>
        </div>
        <div class="card-body p-1">
          <button class=" col-12 btn btn-danger btn-block mt-1" (click)="deleteGroup()">
            <app-svg-icon name="delete"></app-svg-icon>
            Elimina
          </button>
        </div>
      </div>
    </div>
  </div>
  <h3 class="card-title mb-1 col-12"> Predifinito</h3>
  <div class="row">
    <div class="col-6">
      <div class="card glassy card-full-height">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-11">
              <h4 class="card-title">Regole di prezzo</h4>
            </div>
            <div class="col-1">
              <app-svg-icon class="text-primary cursor-pointer" name="edit"
                            (click)="openEditModal(editPricingRules)"></app-svg-icon>
            </div>

          </div>
          <div class="card-body p-1">
            <p class="text-center">Il grafico mostra il markup applicato in base al prezzo di vendita.
              verticalmente il markup, orizzontalmente il prezzo di vendita.</p>
            <app-pricing-chart class="charts" [pricingData]="group.default.priceRules"></app-pricing-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card glassy card-full-height">
        <div class="card-header pb-0">
          <h4 class="card-title">Promozioni</h4>
        </div>
        <div class="card-body p-1">
          <div class="mt-2" *ngFor="let promo of group.default.promoRules; let i = index">
            <h5> Promozione {{ i + 1 }}:</h5>
            <div class="row">
              <div class="col-3">
                <div>
                  <label for="brandPromo">Brand:</label>
                  <select id="brandPromo" class="form-control" [(ngModel)]="promo.filters.brand"
                          [disabled]="!promo.filters.brand || promo.filters.brand.length === 0">
                    <option class="option" [value]="promo.filters.brand">{{ promo.filters.brand }}</option>
                  </select>
                </div>
              </div>
              <div class="col-3">
                <label for="categoryPromo">Categoria:</label>
                <select id="categoryPromo" class="form-control" [(ngModel)]="promo.filters.category"
                        [disabled]="!promo.filters.category || promo.filters.category.length === 0">
                  <option class="option" [value]="promo.filters.category">{{ promo.filters.category }}</option>
                </select>
              </div>
              <div class="col-3">
                <label for="productPromo">Prodotto:</label>
                <select id="productPromo" class="form-control" [(ngModel)]="promo.filters.product"
                        [disabled]="!promo.filters.product || promo.filters.product.length === 0">
                  <option class="option" [value]="promo.filters.product">{{ promo.filters.product }}</option>
                </select>
              </div>
              <div class="col-3">
                <label for="fromPromo">N° regole:</label>
                <input id="fromPromo" class="form-control" type="number" value="{{promo.priceRules.length}}"
                       [disabled]="true">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editPricingRules let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="usersList-modal">Modifica Regole di prezzo</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pl-2 pr-2">
    <ng-container *ngFor="let rule of group.default.priceRules; let i = index">
      <div class="row mb-1">
        <div class="col-4">
          <label for="fromPromo{{i}}">Da:</label>
          <input id="fromPromo{{i}}" class="form-control" type="number" value="{{rule.from}}">
        </div>
        <div *ngIf="i !== group.default.priceRules.length - 1" class="col-4">
          <label for="toPromo{{i}}">A:</label>
          <input id="toPromo{{i}}" class="form-control" type="number"
                 value="{{this.group.default.priceRules[i+1].from-1}}" [disabled]="true">
        </div>
        <div *ngIf="i == group.default.priceRules.length - 1" class="col-4">
          <label for="toPromo{{i}}">A:</label>
          <input id="toPromo{{i}}" class="form-control" type="number" value="" [disabled]="true">
        </div>
        <div class="col-3">
          <label for="markUpPromo{{i}}">MarkUp:</label>
          <input id="markUpPromo{{i}}" class="form-control" type="number" value="{{rule.markup}}">
        </div>
        <div class="col-1 pt-2">
          <app-svg-icon class="text-danger cursor-pointer" name="delete"
                        (click)="deleteRule(i)"></app-svg-icon>
        </div>
      </div>
      <div class="col-12">
        <hr>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-12">
        <button class="btn btn-primary btn-block" (click)="addRule()">Aggiungi regola</button>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="editRules()">Salva</button>
  </div>
</ng-template>
