import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApplicationService } from "@core/services/application.service";
import { MenuGroup } from "@core/types/app/menu";
import { DeepMerge } from "@core/helpers/Object";
import {ApiService} from "../../../services/api.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SidebarComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public logo: string = '';

  public menu: MenuGroup[] = [];


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _apiService: ApiService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.logo.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((logo: string) => {
        this.logo = logo;
      });

    this._applicationService.menu.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((menu: MenuGroup[]) => {
        this.menu = menu;
      })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {}

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

}
