import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { fakeBackendProvider } from "@dev/fake-backend";
import { environment } from "environments/environment";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CoreModule } from "@core/components/core.module";
import { LastOrdersComponent } from "./dashboard/widgets/last-orders/last-orders.component";
import { KromedaStatsComponent } from "./dashboard/widgets/kromeda-stats/kromeda-stats.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import localeItExtra from "@angular/common/locales/extra/it";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";

registerLocaleData(localeIt, "it", localeItExtra);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LastOrdersComponent,
    KromedaStatsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
  ],
  providers: [
    ...(environment.production ? [] : [fakeBackendProvider]),
    { provide: LOCALE_ID, useValue: "it-IT"}
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
