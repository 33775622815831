import {Component, OnDestroy, OnInit, TemplateRef} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApplicationService } from "@core/services/application.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CustomersService } from "@core/services/customers.service";
import { DynamicData } from "@core/types/dynamic-table/dynamic-data";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-group-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss']
})

export class GroupsListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public newGroup : any = {
    code: "",
    name: "",
    description: "",
    default: {
      priceRules: [],
      promoRules: [],
      promotions: []
    },
    suppliers: []
  }



  public tableConfig : TableConfig ={} as TableConfig;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _customersService: CustomersService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('customers-groups-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('groups')
      .then((table) => {
        this.tableConfig = table;
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getGroups(query?: any) {
    query = query || {};
    const queryParams: Params = { ...query };
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['category', 'brand', 'supplier'];
    const groupsData: DynamicData = await this._customersService.getGroupList(query)
    groupsData.rows.forEach(group => {
      group.actions = group.actions || [];
      group.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/groups/' + group.code
      });
    })
    return groupsData;
  }

  addGroup(addNewGroupModal: TemplateRef<any>) {
    return this.modalService.open(addNewGroupModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  addNewGroup() {
    this._customersService.newGroup(this.newGroup).then(() => {
      this.modalService.dismissAll();
      this.getGroups();
    });
  }
}
