import { Component, OnInit } from '@angular/core';
import { ApplicationService } from "@core/services/application.service";
import { AuthenticationService } from "../../@core/services/authentication.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public pagePermissions: {
    last_orders: boolean,
    kromeda: boolean,
  } = {
    last_orders: false,
    kromeda: false,
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _authService: AuthenticationService,
  ) {
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('dashboard');
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.pagePermissions = {
      last_orders: this._authService.checkPermission('admin.page.dashboard.last_orders'),
      kromeda: this._authService.checkPermission('admin.page.dashboard.kromeda'),
    }
  }

}
