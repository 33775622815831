<div class="row" xmlns="http://www.w3.org/1999/html" *ngIf="loaded | async">
  <h4 class="card-title">Tabella {{ table.name }}</h4>
  <div class="col-4 mt-1">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-body">
        <h4 class="card-title">Dettagli</h4>
        <label class="text-primary" for="table-name">Nome tabella</label>
        <input type="text" class="form-control" id="table-name" [(ngModel)]="table.name">
        <h4 class="card-title mt-2">Impaginazione</h4>
        <div class="row">
          <div class="col-6">
            <label class="text-primary">Prima pagina:</label>
            <input type="number" class="form-control" [(ngModel)]="table.pagination.page">
          </div>
          <div class="col-6">
            <label class="text-primary">Righe per pagina:</label>
            <input type="number" class="form-control" [(ngModel)]="table.pagination.pageSize">
          </div>
          <div class="col-6 mt-1">
            <label class="text-primary">Testo di ricerca predefinito:</label>
            <input type="text" class="form-control" [(ngModel)]="table.pagination.search">
          </div>
          <div class=" col-6 form-check form-check-primary  mt-3 pl-3">
            <input type="checkbox" class="form-check-input" [(ngModel)]="table.pagination.strictSearch">
            <span class="text-primary">Ricerca esatta</span>
          </div>
          <div class="col-6 mt-1">
            <label class="text-primary">Ordinare la proprietà:</label>
            <ng-select
              [items]="sortProperties"
              [(ngModel)]="table.pagination.sort.prop"
              placeholder="Seleziona una colonna"
              appendTo="body">
            </ng-select>
          </div>
          <div class="col-6 mt-1">
            <label class="text-primary">Ordinare per:</label>
            <ng-select
              [items]="sortDirections"
              [(ngModel)]="table.pagination.sort.dir"
              placeholder="Seleziona una direzione"
              appendTo="body">
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6 mt-1">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-body">
        <h4 class="card-title">Messaggi</h4>
        <div class="row">
          <div class="col-6">
            <label class="text-primary">Messaggio di vouta:</label>
            <input type="text" class="form-control" [(ngModel)]="table.messages.emptyMessage">
          </div>
          <div class="col-6">
            <label class="text-primary">Messaggio di totale:</label>
            <input type="text" class="form-control" [(ngModel)]="table.messages.totalMessage">
          </div>
          <div class="col-6 mt-1">
            <label class="text-primary">Messaggio di selezione:</label>
            <input type="text" class="form-control" [(ngModel)]="table.messages.selectedMessage">
          </div>
          <div class="col-6 mt-1">
            <label class="text-primary">Messaggio di prima pagina:</label>
            <input type="text" class="form-control" [(ngModel)]="table.messages.beforePageSizeMessage">
          </div>
          <div class="col-6 mt-1">
            <label class="text-primary">Messaggio di dopo pagina:</label>
            <input type="text" class="form-control" [(ngModel)]="table.messages.afterPageSizeMessage">
          </div>
          <div class="col-6 mt-1">
            <label class="text-primary">Messaggio di ricerca:</label>
            <input type="text" class="form-control" [(ngModel)]="table.messages.textSearchMessage">
          </div>
          <div class="col-6 mt-1">
            <label class="text-primary">Messaggio di ricerca specifica:</label>
            <input type="text" class="form-control" [(ngModel)]="table.messages.specificSearchMessage">
          </div>
          <div class="col-6 mt-1">
            <label class="text-primary">Messaggio di prima ricaricare:</label>
            <input type="text" class="form-control" [(ngModel)]="table.messages.beforeAutoReloadMessage">
          </div>
          <div class="col-6 mt-1">
            <label class="text-primary">Messaggio di dopo ricaricare:</label>
            <input type="text" class="form-control" [(ngModel)]="table.messages.afterAutoReloadMessage">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 mt-1">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-body">
        <h4 class="card-title">Azioni</h4>
        <div class="row">
          <div class="mt-1 col-12 d-flex justify-content-center">
            <button class=" col-12 btn btn-primary btn-block" (click)="showPreview(tableModal)">
              <app-svg-icon name="show"></app-svg-icon>
              Anteprima
            </button>
          </div>
          <div class="mt-1 col-12 d-flex justify-content-center">
            <button class=" col-12 btn btn-primary btn-block" (click)="saveTable()">
              <app-svg-icon name="save"></app-svg-icon>
              Salva
            </button>
          </div>
          <div class="mt-1 col-12 d-flex justify-content-center">
            <button class=" col-12 btn btn-danger btn-block" (click)="deleteTable()">
              <app-svg-icon name="delete"></app-svg-icon>
              Elimina
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-12 d-flex mt-2 mb-05 justify-content-between">
            <h5 class="card-title mb-1">Colonne</h5>
            <button class="btn btn-primary" (click)="addColumns(addColumnModal)">
              <app-svg-icon name="add"></app-svg-icon>
              Aggiungi colonna
            </button>
          </div>
          <ng-container *ngFor="let columns of table.columns; let i = index">
            <div class="col-4">
              <div class="card glassy card-full-height card-short-height">
                <div class="card-body">
                  <h4 *ngIf="columns.name" class="card-title">Colonna {{ columns?.name }}</h4>
                  <div class="row">
                    <div class="col-12">
                      <label class="text-primary">Tipo:</label>
                      <ng-select
                        [items]="columnTypes"
                        [(ngModel)]="columns.type"
                        placeholder="Seleziona una tipologia"
                        appendTo="body">
                      </ng-select>
                    </div>
                    <div *ngIf="columns.prop" class="col-6 mt-1">
                      <label class="text-primary">Proprietà:</label>
                      <input type="text" class="form-control" [(ngModel)]="columns.prop">
                    </div>
                    <div *ngIf="columns.name" class="col-6 mt-1">
                      <label class="text-primary">Nome:</label>
                      <input type="text" class="form-control" [(ngModel)]="columns.name">
                    </div>
                    <div *ngIf="columns.cellTemplate" class="col-6 mt-1">
                      <label class="text-primary">Modella:</label>
                      <input type="text" class="form-control" [(ngModel)]="columns.cellTemplate">
                    </div>
                    <div *ngIf="columns.width" class="col-6 mt-1">
                      <label class="text-primary">Larghezza:</label>
                      <input type="text" class="form-control" [(ngModel)]="columns.width">
                    </div>
                    <div *ngIf="columns.maxWidth" class="col-6 mt-1">
                      <label class="text-primary">Larghezza massima:</label>
                      <input type="text" class="form-control" [(ngModel)]="columns.maxWidth">
                    </div>
                    <div *ngIf="columns.minWidth" class="col-6 mt-1">
                      <label class="text-primary">Larghezza minima:</label>
                      <input type="text" class="form-control" [(ngModel)]="columns.minWidth">
                    </div>
                    <div *ngIf="columns.flexGrow" class="col-6 mt-1">
                      <label class="text-primary">Flessibilità:</label>
                      <input type="text" class="form-control" [(ngModel)]="columns.flexGrow">
                    </div>
                    <div *ngIf="columns.draggable" class="col-12 form-check form-check-primary  mt-1 pl-3">
                      <input type="checkbox" class="form-check-input" [(ngModel)]="columns.draggable">
                      <span class="text-primary">Trascinabile</span>
                    </div>
                    <div *ngIf="columns.resizable" class="col-12 form-check form-check-primary  mt-1 pl-3">
                      <input type="checkbox" class="form-check-input" [(ngModel)]="columns.resizable">
                      <span class="text-primary">Ridimensionabile</span>
                    </div>
                    <div *ngIf="columns.sortable" class="col-12 form-check form-check-primary  mt-1 pl-3">
                      <input type="checkbox" class="form-check-input" [(ngModel)]="columns.sortable">
                      <span class="text-primary">Ordinabile</span>
                    </div>
                    <div *ngIf="columns.header" class="col-12 form-check form-check-primary  mt-1 pl-3">
                      <input type="checkbox" class="form-check-input" [(ngModel)]="columns.header">
                      <span class="text-primary">Intestazione</span>
                    </div>
                    <div *ngIf="columns.extra" class="col-12 mt-1">
                      <span class="text-primary">Extra</span>
                      <textarea class="form-control"
                                [(ngModel)]="columns.extra">{{ getExtraAsJSON(columns) }}</textarea>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-12 align-items-center d-flex justify-content-around">
                      <button class="btn btn-primary btn-block" (click)="addFieldToColumns(addFieldModal, i)">
                        <app-svg-icon name="add"></app-svg-icon>
                      </button>
                      <button class="btn btn-danger btn-block" (click)="removeColumn(i)">
                        <app-svg-icon name="delete"></app-svg-icon>
                      </button>
                      <button class="btn btn-primary btn-block" (click)="moveColumn(i, 'up')">
                        <app-svg-icon name="arrow-left"></app-svg-icon>
                      </button>
                      <button class="btn btn-primary btn-block" (click)="moveColumn(i, 'down')">
                        <app-svg-icon name="arrow-right"></app-svg-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="col-12 d-flex mt-2 mb-05 justify-content-between">
            <h5 class="card-title mb-1">Filtri</h5>
            <button class="btn btn-primary" (click)="addFilter()">
              <app-svg-icon name="add"></app-svg-icon>
              Aggiungi filtro
            </button>
          </div>
          <div *ngIf="table.filterGroups">
            <div *ngIf="table.filterGroups[0]">
              <div *ngIf="table.filterGroups[0].filters">
                <div class="row">
                  <ng-container *ngFor="let filter of table.filterGroups[0].filters; let i = index">
                    <div class="col-3">
                      <div class="card glassy column-card">
                        <div class="card-body">
                          <h4 *ngIf="filter.label" class="card-title">Filtro {{ filter?.label }}</h4>
                          <div class="row">
                            <div class="col-12">
                              <label class="text-primary">Tipo:</label>
                              <ng-select
                                [items]="filterTypes"
                                [(ngModel)]="filter.type"
                                placeholder="Seleziona una tipologia"
                                appendTo="body">
                              </ng-select>
                            </div>
                            <div *ngIf="filter.label" class="col-6 mt-1">
                              <label class="text-primary">Etichetta:</label>
                              <input type="text" class="form-control" [(ngModel)]="filter.label">
                            </div>
                            <div *ngIf="filter.field" class="col-6 mt-1">
                              <label class="text-primary">Proprietà:</label>
                              <input type="text" class="form-control" [(ngModel)]="filter.field">
                            </div>
                            <div *ngIf="filter.fieldType" class="col-6 mt-1">
                              <label class="text-primary">Tipo proprietà:</label>
                              <input type="text" class="form-control" [(ngModel)]="filter.fieldType">
                            </div>
                            <div *ngIf="filter.class" class="col-12 mt-1">
                              <label class="text-primary">Classe:</label>
                              <input type="text" class="form-control" [(ngModel)]="filter.class">
                            </div>
                            <div *ngIf="filter.retriever" class="col-12 mt-1">
                              <label class="text-primary">Dati recuperati:</label>
                              <input type="text" class="form-control" [(ngModel)]="filter.retriever">
                            </div>
                            <div *ngIf="filter.placeholder" class="col-12 mt-1">
                              <label class="text-primary">Segnaposto</label>
                              <input type="text" class="form-control" [(ngModel)]="filter.placeholder">
                            </div>
                            <div *ngIf="filter.multiple" class="col-12 form-check form-check-primary  mt-1 pl-3">
                              <input type="checkbox" class="form-check-input" [(ngModel)]="filter.multiple">
                              <span class="text-primary">Multiselezione</span>
                            </div>
                            <div *ngIf="filter.closeOnSelect" class="col-12 form-check form-check-primary  mt-1 pl-3">
                              <input type="checkbox" class="form-check-input" [(ngModel)]="filter.closeOnSelect">
                              <span class="text-primary">Chiudi alla selezione</span>
                            </div>
                            <div *ngIf="filter.searchable" class="col-12 form-check form-check-primary  mt-1 pl-3">
                              <input type="checkbox" class="form-check-input" [(ngModel)]="filter.searchable">
                              <span class="text-primary">Ricerca</span>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer">
                          <div class="row">
                            <div class="col-12 mt-2 align-items-center d-flex justify-content-around">
                              <button class="btn btn-danger btn-block" (click)="removeFilter(i)">
                                <app-svg-icon name="delete"></app-svg-icon>
                              </button>
                              <button class="btn btn-primary btn-block" (click)="moveFilter(i, 'up')">
                                <app-svg-icon name="arrow-left"></app-svg-icon>
                              </button>
                              <button class="btn btn-primary btn-block" (click)="moveFilter(i, 'down')">
                                <app-svg-icon name="arrow-right"></app-svg-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-12 d-flex mt-2 mb-05 justify-content-between flex-column">
            <h5 class="card-title mb-1">Autorizzazioni</h5>
              <div class="card glassy overflow-hidden">
                <div class="card-body overflow-hidden">
                  <div class="row">
                    <ng-container *ngFor="let permission of table.permissions">
                      <div class="col-12">
                        <div class="row d-flex justify-content-between">
                          <div class="col-11">
                            <label class="text-primary font-small-3">{{ permission }}</label>
                          </div>
                        </div>
                        <div class="separator"></div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #tableModal let-modal>
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Anteprima tabella</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <dynamic-table
      [dataRetriever]="getValues.bind(this)"
      [columns]="table2.columns"
      [page]="table2.pagination.page"
      [pageSize]="table2.pagination.pageSize"
      [sort]="table2.pagination.sort"
      [search]="table2.pagination.search"
      [filterGroups]="table2.filterGroups"
      [strictSearch]="table2.pagination.strictSearch"
      [messages]="table2.messages"
    >
    </dynamic-table>
  </div>
</ng-template>

<ng-template #addFieldModal let-modal>
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Aggiungi proprietà</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-select
      [items]="fieldTypes"
      [(ngModel)]="newFieldType"
      placeholder="Seleziona una tipologia"
      appendTo="body">
    </ng-select>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addField()">Aggiungi</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>

<ng-template #addColumnModal let-modal>
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Aggiungi colonna</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-select
      [items]="columnTypes"
      [(ngModel)]="newColumnType"
      placeholder="Seleziona una tipologia"
      appendTo="body">
    </ng-select>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addColumn()">Aggiungi</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>




<ng-template #addPermissionModal let-modal>
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Aggiungi autorizzazione</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <input type="text" class="form-control" [(ngModel)]="newPermission" placeholder="Inserisci il autorizzazione">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addPermission()">Aggiungi</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>
