// Importing necessary modules and components from Angular and other libraries
import { Component, OnDestroy, OnInit, Inject, PLATFORM_ID, NgZone, Input } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApplicationService } from "@core/services/application.service";
import { CustomersService } from "@core/services/customers.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import { DynamicData } from "@core/types/dynamic-table/dynamic-data";
import { ShopService } from "@core/services/shop.service";
import { CatalogService } from "../../../../@core/services/catalog.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-category-view',
  templateUrl: './category-view.component.html',
  styleUrls: ['./category-view.component.scss']
})
export class CategoryViewComponent implements OnInit, OnDestroy {

  // Private variables
  private _unsubscribeAll: Subject<any>; // Subject to unsubscribe from subscriptions

  // Public variables
  public code: string = ''; // Public variable to store a string code

  public loading: boolean = true; // Public variable to store loading status

  public category: any;
  public categories: any[] = [];
  public parent: any;
  public groups: any[] = [];

  constructor(
    // Injecting services and dependencies required for this component
    private _applicationService: ApplicationService,
    private _catalogService: CatalogService,
    private _customersService: CustomersService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
    // Initializing private variables and setting defaults in the constructor
    this._unsubscribeAll = new Subject(); // Initializing _unsubscribeAll as a Subject
    this._applicationService.layoutContentWidth(); // Setting layout content width
    this._applicationService.selectMenu('catalog-categories-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.refreshCategory()
  }

  ngOnDestroy(): void {
    // Unsubscribing from all subscriptions to prevent memory leaks
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  selectParent($event: any) {
    this.parent = $event || undefined;
  }

  searchCategory($event: any) {
    this._catalogService.getCategoryList({
      search: $event.target.value,
      pageSize: 25,
    }).then((response: DynamicData) => {
      this.categories = response.rows.map((category: any) => {
        return {
          label: category.name,
          value: category.id
        }
      })
    })
  }

  cancelModifications() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Vuoi davvero annullare le modifiche?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Annulla',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result) {
        this.refreshCategory();
      }
    });
  }

  refreshCategory() {
    this.code = this._route.snapshot.paramMap.get('code') || '';
    this._catalogService.getCategoryByCode(this.code)
      .then((response: any) => {
        this.category = response;
        this.loading = false;

        this._catalogService.getCategoryList({
          pageSize: 100,
        })
          .then((response: any) => {
            this.categories = response.rows.map((category: any) => {
              return {
                label: category.name,
                value: category.id
              }
            })
            this.categories.unshift({
              label: "Nessuno",
              value: ""
            })
          })
        this._customersService.getGroupList({})
          .then((response: any) => {
            this.groups = response.rows
              // .filter((e: any) => ['CN', 'SN'].includes(e.code))
              .map((group: any) => {
                const priceRule = this.category?.priceRules?.find((e: any) => e.group.id === group.id);
                return {
                  code: group.code,
                  name: group.name,
                  value: group.id,
                  markup: priceRule?.markup || 0,
                  enabled: !!priceRule,
                }
              })
          })
      })

  }

  toggleGroupMarkup(group: any, $event: any) {
    group.enabled = $event.target.checked;
  }

  saveCategory() {
    const data: any = {
      code: this.category.code,
      name: this.category.name,
    };
    if (this.parent) {
      data.parent = this.parent.value;
    }
    let flagPriceRules = false;
    this.groups.forEach((group: any) => {
      const priceRule: any = this.category.priceRules?.find((e: any) => e.group.id === group.value);
      if (group.enabled && !priceRule) {
        flagPriceRules = true;
      } else if (!group.enabled && priceRule) {
        flagPriceRules = true;
      } else if (group.enabled && priceRule && priceRule.markup !== group.markup) {
        flagPriceRules = true;
      }
    })
    if (flagPriceRules) {
      data.priceRules = this.groups
        .filter((group: any) => group.enabled)
        .map((group: any) => {
          return {
            group: group.value,
            markup: parseFloat(group.markup),
          }
        })
    }
    // ask for confirmation
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Vuoi davvero salvare le modifiche?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Salva',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result?.isConfirmed) {
        this._catalogService.updateCategory(this.category.id, data)
          .then((response: any) => {
            this.refreshCategory();
            Swal.fire({
              title: 'Salvato!',
              text: 'Le modifiche sono state salvate con successo',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'OK'
            })
          })
      }
    });
  }

  propagatePriceRules() {
    // ask for confirmation
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Vuoi davvero propagare le regole di prezzo?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Propaga',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result?.isConfirmed) {
        this._catalogService.propagatePriceRules(this.category.id)
          .then((response: any) => {
            this.refreshCategory();
            Swal.fire({
              title: 'Propagato!',
              text: 'Le regole di prezzo sono state propagate con successo',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'OK'
            })
          })
      }
    });
  }

  deleteCategory() {
    //swal confirm
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Vuoi davvero eliminare questa categoria?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result?.isConfirmed) {
        this._catalogService.deleteCategory(this.category.id)
          .then((response: any) => {
            Swal.fire({
              title: 'Eliminato!',
              text: 'La categoria è stata eliminata con successo',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'OK'
            }).then(() => {
             this._router.navigate(['/categories']);
            })
          })
      }
    });
  }
}
