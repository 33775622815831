<div class="row" *ngIf="loaded | async">
  <div class="col-3 d-flex" *ngIf="pagePermissions.details">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Ordine {{ currentCode }}/{{ currentYear }}</h5>
        <a class="business-name card-text mb-05 "
           routerLink="/businesses/{{currentOrder.business.code}}">
          <app-svg-icon name="link"></app-svg-icon>
          <b>{{ currentOrder.business.code }}</b> {{ currentOrder.business.name }}</a>
        <p class="card-text mb-05"><b class="card-text-title">P.IVA: </b> {{ currentOrder.business.vat }}</p>
        <p class="card-text mb-05">
          <b class="card-text-title">Data: </b>
          {{ currentOrder.createdAt | date: 'dd/MM/yyyy HH:mm' }}
        </p>
        <p class="card-text mb-05">
          <b class="card-text-title">Magazzino: </b>
          {{ currentOrder.warehouse.name }} ({{ currentOrder.warehouse.code }})
        </p>
        <p class="card-text mb-05"><b class="card-text-title">Stato: </b>
          <span
            [ngClass]="{
                        'badge-light-primary': currentOrder.status == 'COMPLETED' || currentOrder.status == 'CONFIRMED' || currentOrder.status == 'DELIVERED',
                        'badge-light-success': currentOrder.status == 'ACTIVATED' || currentOrder.status == 'SHIPPED',
                        'badge-light-danger': currentOrder.status == 'REJECTED' || currentOrder.status == 'FAILED' || currentOrder.status == 'CANCELED',
                        'badge-light-warning': currentOrder.status == 'PAIDBACK' || currentOrder.status == 'RETURNED' || currentOrder.status == 'REFUNDED',
                        'badge-light-info': currentOrder.status == 'CREATED' || currentOrder.status == 'PENDING' || currentOrder.status == 'APPROVED'
                      }"
            class="badge badge-pill"
          >
            <ng-container *ngIf="orderStatus?.[currentOrder.status]">
              {{ orderStatus[currentOrder.status].label }}
            </ng-container>
            <ng-container *ngIf="!orderStatus?.[currentOrder.status]">
              {{ currentOrder.status }}
            </ng-container>
          </span>
        </p>
      </div>
      <div class="card-body">
        <p class="card-text mb-05">
          <b class="card-text-title">Indirizzo: </b> {{ currentOrder.address.street }} {{ currentOrder.address.number }}
          , {{ currentOrder.address.zip }}</p>
        <p class="card-text mb-05"><b class="card-text-title">Comune: </b> {{ currentOrder.address.city }}
          ({{ currentOrder.address.province }})</p>
        <ng-container *ngIf="currentOrder.address?.extra?.[currentOrder.warehouse.supplier.name]">
          <p *ngFor="let entry of Object.entries(currentOrder.address?.extra?.[currentOrder.warehouse.supplier.name])">
            <b class="card-text-title">{{ entry[0] }}: </b> {{ entry[1] | json }}
          </p>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-2 d-flex" *ngIf="pagePermissions.billings">
    <div class="card full-width">
      <div class="card-body d-flex flex-column" *ngIf="pagePermissions.ddt">
        <h5 class="card-title mt-1">DDT</h5>
        <ng-container *ngIf="currentOrder.shippings?.length > 0">
          <table class="table font-small-4">
            <tbody>
            <tr *ngFor="let shipping of currentOrder.shippings">
              <td class="cursor-pointer text-primary"
                  (click)="editShippingModal(shipping,editDeliveryModal)">{{ shipping.reference }}
              </td>
              <td>{{ shipping.date | date: 'dd/MM/yyyy' }}</td>
              <td>
                <a target="_blank" href="https://api.tulero.it/api/admin/orders/deliveries/{{shipping.id}}/delivery.pdf"
                   class="">
                  <app-svg-icon class="d-inline-block align-middle" name="download2"></app-svg-icon>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-container *ngIf="!(currentOrder.shippings?.length > 0)">
          <p>Nessuna spedizione trovata</p>
        </ng-container>
      </div>
      <div class="card-body d-flex flex-column" *ngIf="pagePermissions.invoice">
        <h5 class="card-title mt-1">Fattura</h5>
        <p>Non ancora fatturato</p>
      </div>
    </div>
  </div>
  <div class="col-2 d-flex" *ngIf="pagePermissions.total">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title ">Totale</h5>
        <div class="order-details  d-flex flex-column">
          <table class="full-width">
            <tbody>
            <tr>
              <td class="order-details-label font-weight-bold font-small-2 text-uppercase">Prodotti:</td>
              <td
                class="order-details-value text-right h6 pt-50">{{ calculateSumOfProducts() | price }}
              </td>
            </tr>
            <tr>
              <td class="order-details-label font-weight-bold font-small-2 text-uppercase">Spedizione:</td>
              <td
                class="order-details-value text-right h6 pt-50">{{ currentOrder.delivery.price | price }}
              </td>
            </tr>
            <tr>
              <td class="order-details-label font-weight-bold font-small-2 text-uppercase">IVA:</td>
              <td
                class="order-details-value text-right h6 pt-50">{{ calculateTaxOfProducts() | price }}
              </td>
            </tr>
            <tr *ngIf="currentOrder.discount">
              <td class="order-details-label font-weight-bold font-small-2 text-uppercase">Totale parziale:</td>
              <td
                class="order-details-value text-right h6 pt-50">{{ calculateTotalOfProducts() | price }}
              </td>
            </tr>
            <tr *ngIf="currentOrder.discount">
              <td class="order-details-label font-weight-bold font-small-2 text-uppercase">Sconto:</td>
              <td
                class="order-details-value text-right h6 pt-50">{{ discount | price }}
              </td>
            </tr>
            <tr>
              <td class="order-details-label font-weight-bold font-small-3 text-uppercase">Totale</td>
              <td
                class="order-details-value text-right text-primary font-weight-bold h5 pt-50">{{ calculateTotalWithDiscount() | price }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-body d-flex flex-column">
        <h5 class="card-title ">Pagamenti</h5>
        <ng-container *ngIf="currentOrder.payments?.length > 0">
          <div *ngFor="let payment of currentOrder.payments" class="mb-05">
            <p class="card-text mb-0">
              {{ payment.method.label }}
            </p>
            <span class="font-weight-bold mr-1">{{ payment.amount | price }}</span>
            <a *ngIf="paymentExtra?.status?.[payment.status]"
               class="badge badge-pill {{paymentExtra.status[payment.status]?.class}}"
               ngbTooltip="{{payment.code}} | {{payment.amount | price}} | {{paymentExtra.status[payment.status]?.label}}"
               routerLink="/payments/{{payment.id}}"
            >
              {{ paymentExtra?.method?.[payment.method?.driver] ? paymentExtra?.method?.[payment.method?.driver].label : payment.method?.driver }}
            </a>
            <a *ngIf="!paymentExtra?.status?.[payment.status]" class="badge badge-pill badge-light-danger"
               ngbTooltip="{{payment.code}} | {{payment.amount | price}} | {{payment.status}}">
              {{ paymentExtra?.method?.[payment.method?.driver] ? paymentExtra?.method?.[payment.method?.driver].label : payment.method?.driver }}
            </a>
          </div>
        </ng-container>
        <ng-container *ngIf="currentOrder.payments?.length == 0">
          <p>Nessun pagamento trovato</p>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-3 d-flex" *ngIf="pagePermissions.comments">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title ">Commenti</h5>
        <div class="comment-container">
          <ng-container *ngIf="comments.length > 0">
            <ul class="list-unstyled">
              <li *ngFor="let comment of comments" class="mb-1">
                <div class="">
                  <div class="comment-text">
                    <p class="card-text mb-0">
                      {{ comment.text }}
                    </p>
                  </div>
                  <div class="comment-user mt-05">
                    <span class="avatar mr-1">
                      <img
                        [src]="comment.user.avatar || 'assets/images/avatars/default-avatar.png'"
                        (error)="comment.user.avatar = 'assets/images/avatars/default-avatar.png'"
                        alt="avatar"
                        class="rounded-circle"
                        width="18"
                        height="18"
                      >
                    </span>
                    <span class="font-small-2 font-weight-bold mr-1">{{ comment.user.name }}</span>
                    <span class="font-small-2">{{ comment.createdAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="comments?.length == 0">
            <p>Nessun commento trovato</p>
          </ng-container>
        </div>
      </div>
      <div class="card-footer">
        <div class="form-group">
          <div class="input-group">
            <textarea class="form-control" id="comment" rows="2"
                      [(ngModel)]="commentText" name="comment"
                      (keydown.control.enter)="addComment()"></textarea>
            <button class="btn btn-outline-primary px-1" (click)="addComment()">
              <app-svg-icon class="d-inline-block align-middle" name="send"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 d-flex" *ngIf="pagePermissions.actions">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Azione</h5>
        <div class="col-12">
          <a class="action btn btn-success mr-1 col-12"
             *ngIf="(currentOrder.status == 'FAILED' || currentOrder.status == 'CANCELED' || currentOrder.status == 'REJECTED' || currentOrder.status == 'CREATED') && pagePermissions.approve"
             (click)="approveCurrentOrder()">APPROVA</a>
          <a class="action btn btn-success mr-1 col-12"
             *ngIf="currentOrder.status == 'APPROVED' && pagePermissions.confirm"
             (click)="confirmCurrentOrder()">CONFERMA</a>
          <a class="action btn btn-danger mr-1 col-12"
             *ngIf="(currentOrder.status == 'APPROVED' || currentOrder.status == 'CONFIRMED' || currentOrder.status == 'CREATED') && pagePermissions.cancel"
             (click)="cancelCurrentOrder()">ANNULLA</a>
          <a class="action btn btn-primary mr-1 col-12"
             *ngIf="currentOrder.status == 'CONFIRMED' && pagePermissions.delivery"
             (click)="modalOpenModal(deliveryModal)">SPEDIZIONE</a>
          <a class="action btn btn-info mr-1 col-12"
             (click)="downloadCSV()">
            <app-svg-icon class="d-inline-block align-left" name="download"></app-svg-icon>
            <span class="align-left">PRODOTTI</span></a>
          <button class="action btn btn-primary btn-block mb-1 text-white col-12 mr-1"
                  *ngIf="pagePermissions.pdf"
                  (click)="openPdfModal(invoicePdfModal)">
            <app-svg-icon class="d-inline-block align-left" name="download"></app-svg-icon>
            <span class="align-left">PDF</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="pagePermissions.products">
    <div class="card">
      <div class="card-body">
        <h5 class="text-primary">PRODOTTI</h5>
      </div>
      <div class="table-responsive" >
        <dynamic-table
          [dataRetriever]="getProducts.bind(this)"
          [columns]="tableConfig.columns"
          [filterGroups]="tableConfig.filterGroups"
          [page]="tableConfig.pagination.page"
          [pageSize]="tableConfig.pagination.pageSize"
          [sort]="tableConfig.pagination.sort"
          [search]="tableConfig.pagination.search"
          [strictSearch]="tableConfig.pagination.strictSearch"
          [messages]="tableConfig.messages"
          [autoReloadEnable]="true"
        >
        </dynamic-table>
      </div>
    </div>
  </div>
</div>

<ng-template #deliveryModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="delivery-modal">Spedizione</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0">
    <form [formGroup]="deliveryForm" class="mb-0">
      <div class="row">
        <div class="col-sm-2">
          <div class="form-group">
            <label for="delivery-code">Codice Spedizione</label>
            <input id="delivery-code" class="form-control" type="text" placeholder="" formControlName="deliveryCode"/>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="delivery-track">Codice di tracciamento</label>
            <input id="delivery-track" class="form-control" type="text" placeholder="" formControlName="deliveryTrack"/>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="delivery-date">Data spedizione</label>
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" formControlName="deliveryDate"
                     id="delivery-date" ngbDatepicker #basicDP="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <div class="table-responsive">
            <table class="table" #products>
              <thead class="thead-light font-small-4">
              <tr>
                <th colspan="2">Prodotto</th>
                <th>Codice</th>
                <th>Marca</th>
                <th>Categoria</th>
                <th>Kromeda</th>
                <th>Codice Fornitore</th>
                <th class="text-center">Qnt (spd)</th>
                <th class="text-center">Cfz</th>
                <th>Acquisto</th>
                <th>Vendita</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let product of currentOrder.products">
                <td class="pr-0">
                  <img
                    class="table-image"
                    [src]="product.picture || 'assets/images/catalog/default-product-picture.png'"
                    (error)="product.picture = 'assets/images/catalog/default-product-picture.png'"
                    alt="{{product.name}}"
                  >
                </td>
                <td class="">
                  <span class="">{{ product.name }}</span>
                </td>
                <td>{{ product.code }}</td>
                <td class="">{{ product.brand.name }}</td>
                <td>{{ product.category.name }}</td>
                <td>{{ product.info.kromeda.id_product }}</td>
                <td></td>
                <td class="text-center">
                  {{ product.quantity }} ({{ product.shipped || 0 }})
                </td>
                <td class="text-center">{{ product.multiplier }}</td>
                <td class="product-row-price">{{ product.cost | price }}</td>
                <td class="product-row-price">{{ product.price | price }}</td>
                <td class="overflow-hidden actions">
                  <!-- quantity of product -->
                  <input type="number"
                         class="form-control"
                         min="0"
                         formControlName="{{ product._id }}"
                         placeholder="{{ product.quantity }}"
                         (change)="updateQuantity(product, $event.target)"
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger"
            (click)="modal.close('Accept click')">Annulla
    </button>
    <button type="button" class="btn btn-primary"
            (click)="sendDelivery()">Invia Spedizione
    </button>
  </div>
</ng-template>


<ng-template #editDeliveryModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="edit-delivery-modal">Modifica</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0">
    <form [formGroup]="deliveryForm2" class="mb-0">
      <div class="row">
        <div class="col-sm-2">
          <div class="form-group">
            <label for="delivery-code2">Codice Spedizione</label>
            <input id="delivery-code2" class="form-control" type="text" placeholder="{{selectedDelivery.reference}}"
                   formControlName="deliveryCode"/>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="delivery-track2">Codice di tracciamento</label>
            <input id="delivery-track2" class="form-control" type="text" placeholder="{{selectedDelivery.tracking}}"
                   formControlName="deliveryTrack"/>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="delivery-date2">Data spedizione</label>
            <div class="input-group">
              <input class="form-control" placeholder="{{selectedDelivery?.date | date}}" formControlName="deliveryDate"
                     id="delivery-date2" ngbDatepicker #basicDP="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <div class="table-responsive">
            <table class="table" #products>
              <thead class="thead-light font-small-4">
              <tr>
                <th colspan="2">Prodotto</th>
                <th>Codice</th>
                <th>Marca</th>
                <th>Categoria</th>
                <th>Kromeda</th>
                <th>Codice Fornitore</th>
                <th class="text-center">Qnt (spd)</th>
                <th class="text-center">Cfz</th>
                <th>Acquisto</th>
                <th>Vendita</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let product of currentOrder.products">
                <td class="pr-0">
                  <img
                    class="table-image"
                    [src]="product.picture || 'assets/images/catalog/default-product-picture.png'"
                    (error)="product.picture = 'assets/images/catalog/default-product-picture.png'"
                    alt="{{product.name}}"
                  >
                </td>
                <td class="">
                  <span class="">{{ product.name }}</span>
                </td>
                <td>{{ product.code }}</td>
                <td class="">{{ product.brand.name }}</td>
                <td>{{ product.category.name }}</td>
                <td>{{ product.info.kromeda.id_product }}</td>
                <td></td>
                <td class="text-center">
                  {{ product.quantity }} ({{ product.shipped || 0 }})
                </td>
                <td class="text-center">{{ product.multiplier }}</td>
                <td class="product-row-price">{{ product.cost | price }}</td>
                <td class="product-row-price">{{ product.price | price }}</td>
                <ng-container *ngFor="let product2 of selectedDelivery.products">
                  <td class="overflow-hidden actions" *ngIf="product2.product == product._id">
                    <input
                      type="number"
                      class="form-control"
                      min="0"
                      formControlName="{{ product._id }}"
                      placeholder="{{ product2.quantity }}"
                      (change)="updateQuantity2(product2, $event.target)"
                    />
                  </td>
                </ng-container>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger"
            (click)="modal.close('Accept click')">Annulla
    </button>
    <button type="button" class="btn btn-primary"
            (click)="editDelivery()">Modifica Spedizione
    </button>
  </div>
</ng-template>


<ng-template #invoicePdfModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="pdf-modal">Modifica PDF</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="downloadPDF()">
    <div class="modal-body" tabindex="0">
      <div class="row">
        <h5 class="text-primary mt-1">Dettagli del documento</h5>
        <div class="form-group col-4 mb-1">
          <label for="invoiceType">Nome del documento</label>
          <ng-select [items]="invoice.documentNames"
                     [(ngModel)]="invoice.documentName"
                     name="invoiceType"
                     id="invoiceType">
          </ng-select>
        </div>
        <div class="col-8 mb-1"></div>
        <div class="form-group col-3">
          <label for="returnedNumber">Numero</label>
          <input type="text" class="form-control" id="returnedNumber" [(ngModel)]="invoice.returned.number"
                 name="returnedNumber">
        </div>
        <div class="form-group col-3">
          <label for="returnedDate">Data</label>
          <input type="text" class="form-control" id="returnedDate" ngbDatepicker #basicDP="ngbDatepicker"
                 [(ngModel)]="invoice.returned.date"
                 name="returnedDate">
          <div class="input-group-append">
            <button class="feather icon-calendar cursor-pointer pt-05 mt-1 mr-05" (click)="basicDP.toggle()"
                    type="button" rippleEffect>
              <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
            </button>
          </div>
        </div>
        <div class="form-group col-3">
          <label for="returnedReason">Causale</label>
          <input type="text" class="form-control" id="returnedReason" [(ngModel)]="invoice.returned.casual"
                 name="returnedReason">
        </div>
        <div class="form-group col-3">
          <label for="returnedReference">Riferimento</label>
          <input type="text" class="form-control" id="returnedReference" [(ngModel)]="invoice.returned.reference"
                 name="returnedReference">
        </div>
        <h6 class="text-primary mt-1">Dettagli aziendali</h6>
        <div class="form-group col-3">
          <label for="businessName">Nome azienda</label>
          <ng-select
            #clientSelect
            [items]="invoice.clients"
            bindLabel="name"
            [(ngModel)]="selectedClient"
            (ngModelChange)="updateBusinessData()"
            name="businessName"
            id="businessName"
            addTagText="Usa "
            [addTag]="true"
          >
            <ng-template #optionTemplate let-item="item">
              <div *ngIf="!item || item === selectedClient">
                {{ item }}
              </div>
              <div *ngIf="item && item !== selectedClient">
                {{ item.name }}
              </div>
            </ng-template>
          </ng-select>

        </div>

        <div class="form-group col-3">
          <label for="businessVat">P.IVA</label>
          <input type="text" class="form-control" id="businessVat" [(ngModel)]="invoice.businessData.vat"
                 name="businessVat">
        </div>
        <div class="form-group col-3">
          <label for="businessAddress1">Indirizzo (via e numero)</label>
          <input type="text" class="form-control" id="businessAddress1" [(ngModel)]="invoice.businessData.address1"
                 name="businessAddress1">
        </div>
        <div class="form-group col-3">
          <label for="businessAddress2">Indirizzo (cap, città, provincia, regione e paese)</label>
          <input type="text" class="form-control" id="businessAddress2" [(ngModel)]="invoice.businessData.address2"
                 name="businessAddress2">
        </div>

        <h6 class="text-primary mt-1">Destinazione</h6>
        <div class="form-group col-3">
          <label for="destinationName">Nome azienda</label>
          <ng-select
            #destinationSelect
            [items]="invoice.clients"
            bindLabel="name"
            [(ngModel)]="selectedDestination"
            (ngModelChange)="updateDestination()"
            name="destinationName"
            id="destinationName"
            addTagText="Usa "
            [addTag]="true">
          </ng-select>
        </div>
        <div class="form-group col-3">
          <label for="destinationVat">P.IVA</label>
          <input type="text" class="form-control" id="destinationVat" [(ngModel)]="invoice.destination.vat"
                 name="destinationVat">
        </div>
        <div class="form-group col-3">
          <label for="destinationAddress1">Indirizzo (via e numero)</label>
          <input type="text" class="form-control" id="destinationAddress1" [(ngModel)]="invoice.destination.address1"
                 name="destinationAddress1">
        </div>
        <div class="form-group col-3">
          <label for="destinationAddress2">Indirizzo (cap, città, provincia, regione e paese)</label>
          <input type="text" class="form-control" id="destinationAddress2" [(ngModel)]="invoice.destination.address2"
                 name="destinationAddress2">
        </div>

        <h6 class="text-primary mt-1">Dettagli</h6>
        <div class="form-group col-4">
          <label for="number">Numero</label>
          <input type="text" class="form-control" id="number" [(ngModel)]="invoice.number" name="number">
        </div>
        <div class="form-group col-4">
          <label for="invoiceDate">Data</label>
          <input type="text" class="form-control" id="invoiceDate" ngbDatepicker #basicDP2="ngbDatepicker"
                 [(ngModel)]="invoice.invoiceDate" name="invoiceDate">
          <div class="input-group-append">
            <button class="feather icon-calendar cursor-pointer pt-05 mt-1 mr-05" (click)="basicDP2.toggle()"
                    type="button" rippleEffect>
              <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
            </button>
          </div>
        </div>
        <div class="form-group col-4">
          <label for="productsQuantity">Quantità prodotto</label>
          <input type="text" class="form-control" id="productsQuantity" [(ngModel)]="invoice.productQuantity"
                 name="productsQuantity">
        </div>


        <h6 class="text-primary mt-1">Spedizione</h6>
        <div class="form-group col-3">
          <label for="transportMethod">Trasporto a cura di</label>
          <input type="text" class="form-control" id="transportMethod" [(ngModel)]="invoice.transport.method"
                 name="transportMethod">
        </div>
        <div class="form-group col-3">
          <label for="shippingMethod">Spedizione a</label>
          <input type="text" class="form-control" id="shippingMethod" [(ngModel)]="invoice.shipping.method"
                 name="shippingMethod">
        </div>
        <div class="form-group col-3">
          <label for="shippingPacks">Numero colli</label>
          <input type="text" class="form-control" id="shippingPacks" [(ngModel)]="invoice.shipping.packages"
                 name="shippingPacks">
        </div>
        <div class="form-group col-3">
          <label for="vectorName">Nome Vettore</label>
          <input type="text" class="form-control" id="vectorName" [(ngModel)]="invoice.vectorName"
                 name="vectorName">
        </div>
        <h5 class="text-primary mt-1">Prodotti</h5>
        <ng-container *ngFor="let line of invoice.lines; let i = index">
          <h6 class="text-primary mt-1">Prodotto {{ i + 1 }}</h6>
          <div class="form-group col-3">
            <label for="productCode{{i}}">Codice</label>
            <input type="text" class="form-control" id="productCode{{i}}" [(ngModel)]="line.code"
                   name="productCode{{i}}">
          </div>
          <div class="form-group col-5">
            <label for="productDescription{{i}}">Descrizione</label>
            <input type="text" class="form-control" id="productDescription{{i}}" [(ngModel)]="line.description"
                   name="productDescription{{i}}">
          </div>
          <div class="form-group col-2">
            <label for="productUnit{{i}}">U.m.</label>
            <input type="text" class="form-control" id="productUnit{{i}}" [(ngModel)]="line.unit"
                   name="productUnit{{i}}">
          </div>
          <div class="form-group col-1">
            <label for="productQuantity{{i}}">Q.tà</label>
            <input type="text" class="form-control" id="productQuantity{{i}}" [(ngModel)]="line.quantity"
                   name="productQuantity{{i}}">
          </div>
          <div class="form-group col-1">
            <div class="column d-flex flex-column">
              <label>Rimuovere</label>
              <app-svg-icon (click)="removeProduct(i)" class="d-inline-block align-middle cursor-pointer mt-05 ml-2"
                            name="close"></app-svg-icon>
            </div>
          </div>

        </ng-container>

      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary">Scarica</button>
      <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
    </div>
  </form>
</ng-template>
