import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ShippingRulesListComponent } from "./shipping-rules-list/shipping-rules-list.component";
import { CoreModule } from "@core/components/core.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";

const routes: Routes = [
  {
    path: 'shipping-rules',
    component: ShippingRulesListComponent,
    data: { animation: 'ShippingRulesListComponent' }
  },
];

@NgModule({
  declarations: [
    ShippingRulesListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    NgSelectModule,
    FormsModule
  ],
  providers: []
})
export class ShippingRulesModule {
}
