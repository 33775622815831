import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Observable, Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CustomersService} from "@core/services/customers.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {Menu, MenuGroup} from "../../../../@core/types/app/menu";
import Swal2 from "sweetalert2";
import {TableColumn, TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";


@Component({
  selector: 'app-menuItems-view',
  templateUrl: './menuItems-view.component.html',
  styleUrls: ['./menuItems-view.component.scss']
})

export class MenuItemsViewComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public currentId: string = '';
  public loaded: Promise<boolean> = Promise.resolve(false);
  public menuItem: any = {};

  filterTypes: string[] = ['select'];

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------


  constructor(
    private modalService: NgbModal,
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private _dynamicTableService: DynamicTableService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('super-menu-items-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.currentId = this._activatedRoute.snapshot.paramMap.get('code') || '';
    this._applicationService.getItem(this.currentId).then((data: any) => {
      this.menuItem = data;
      this.menuItem.name = this.menuItem.name || '';
      console.log("this.menuItem",this.menuItem);
      this.loaded = Promise.resolve(true);
    });
  }


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  saveMenu() {

  }

  deleteMenu() {

  }
}

