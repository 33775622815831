<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Carrelli</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.cartsList.add')" class="btn btn-primary px-1" (click)="addCart(addNewCartModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getCarts.bind(this)"
    [columns]="tableConfig.columns"
    [filterGroups]="tableConfig.filterGroups"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>

<ng-template #addNewCartModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="newOrder-modal">Aggiungi Carrello</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Azienda</h5>
        <ng-select
          id="cart-business"
          bindLabel="name"
          [items]="businesses"
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="true"
          [placeholder]="newCart?.business || 'Seleziona una categoria'"
          (keyup)="searchBusiness($event)"
          (change)="selectBusiness($event)"
          class="custom-ng-select"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addNewCart()">Aggiungi</button>
  </div>
</ng-template>
