import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@core/components/core.module";
import { ProductsListComponent } from "./products-list/products-list.component";
import { ProductsViewComponent } from "./products-view/products-view.component";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

const routes: Routes = [
  {
    path: 'products',
    component: ProductsListComponent,
    data: {
      animation: 'ProductsListComponent',
      permission: 'admin.page.products.list'
    }
  },
  {
    path: 'products/:brand/:code',
    component: ProductsViewComponent,
    data: {
      animation: 'ProductsViewComponent',
      permission: 'admin.page.products.view'
    }
  },
  {
    path: 'products/:id',
    component: ProductsViewComponent,
    data: {
      animation: 'ProductsViewComponent',
      permission: 'admin.page.products.view'
    }
  },
];

@NgModule({
  declarations: [
    ProductsListComponent,
    ProductsViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    FormsModule,
    NgSelectModule
  ],
  providers: []
})
export class ProductsModule {
}
