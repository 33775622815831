import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;
  public currentUser: any = {};


  public logo: string = '';
  public loaded: Promise<boolean> = Promise.resolve(false);
  sidebarHidden: boolean = false;



  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _authenticationService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    // this._authenticationService.currentUser$.subscribe(user => {
    //   this.currentUser = user;
    // });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loaded = Promise.resolve(false);
    this._applicationService.logo.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((logo: string) => {
        this.logo = logo;
      });
    this._authenticationService.user.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => {
        this.currentUser.user = user;
        this.loaded = Promise.resolve(true);
      });
    this._authenticationService.business.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((business:any) => {
        this.currentUser.business = business;
        this.loaded = Promise.resolve(true);
      });
    this._applicationService.sidebarHidden.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((sidebarHidden: boolean) => {
        this.sidebarHidden = sidebarHidden;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  toggleSideNav() {
    this._applicationService.toggleSidebar();
  }
}
