import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {CatalogService} from "@core/services/catalog.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {CommentsService} from "../../../../@core/services/comments.service";
import Swal2 from "sweetalert2";

@Component({
  selector: 'app-warehouses-view',
  templateUrl: './warehouses-view.component.html',
  styleUrls: ['./warehouses-view.component.scss']
})

export class WarehousesViewComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public loaded: Promise<boolean> = Promise.resolve(false);

  public warehouse: any;
  public currentId: string = '';

  public comments: any[] = [];
  public commentText: string = '';

  warningTypes = [
    {type: 'warning', label: 'Avvertimento'},
    {type: 'danger', label: 'Pericolo'},
    {type: 'info', label: 'Informazioni'},
    {type: 'success', label: 'Successo'},
    {type: 'primary', label: 'Primario'},
    {type: 'secondary', label: 'Secondario'},
    {type: 'dark', label: 'Buio'},
    {type: 'light', label: 'Leggero'}
  ];
  public tableConfig: TableConfig = {} as TableConfig;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  private warehouseList: { label: any; value: any }[] = [];

  constructor(
    private _applicationService: ApplicationService,
    private _catalogService: CatalogService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _commentService: CommentsService,
    private modalService: NgbModal,
    private _dynamicTableService: DynamicTableService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('catalog-suppliers-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loaded = Promise.resolve(false);
    this.currentId = this._route.snapshot.paramMap.get('id') || '';
    this._catalogService.getWarehouse(this.currentId)
      .then((response: any) => {
        this.warehouse = response;
        this._dynamicTableService.getDynamicTable('shipping-rules')
          .then((table) => {
            this.tableConfig = table;
            this.generateFilterOptions();
            const queryParams: any = this._route.snapshot.queryParams;
            if (queryParams.page) {
              this.tableConfig.pagination.page = queryParams.page;
            }
            if (queryParams.pageSize) {
              this.tableConfig.pagination.pageSize = queryParams.pageSize;
            }
            if (queryParams.search) {
              this.tableConfig.pagination.search = queryParams.search;
            }
            if (queryParams.sort) {
              const [sortProp, sortDir] = queryParams.sort.split(':');
              this.tableConfig.pagination.sort = {
                prop: sortProp,
                dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
              };
            }
          })
        this.loaded = Promise.resolve(true);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  changeWarehouse(input: any) {
    Swal2.fire(
      {
        title: 'Attenzione',
        text: `Sei sicuro di voler ${input.checked ? 'attivare' : 'disattivare'} il magazzino ${this.warehouse.code}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }
    ).then((result) => {
        if (result.isConfirmed) {
          console.log(`Making the request to ${input.checked ? 'enable' : 'disable'} the warehouse ${this.warehouse.code} (${this.warehouse.id})`);
          this._catalogService.toggleSupplierWarehouse(this.currentId, this.warehouse.id, input.checked)
            .then((response: any) => {
              this.warehouse.active = response.active;
              Swal.fire({
                title: 'Conferma',
                text: `Magazzino ${input.checked ? 'attivato' : 'disattivato'} correttamente`,
                icon: 'success',
                confirmButtonText: 'Ok'
              });
            })
            .catch((error: any) => {
              console.error('Error fetching comments:', error);
              Swal.fire({
                title: 'Errore',
                icon: 'error',
                confirmButtonText: 'Ok'
              });
            })
        }
      }
    )
  }

  addComment() {
    let user = this.getLocalUser();
    if (this.commentText.trim()) {
      this._commentService.setComment(this.currentId, this.commentText, user)
        .then((response: any) => {
          this.commentText = '';
          this.loadComments();
          Swal.fire({
            title: 'Conferma',
            text: 'Commento aggiunto con successo',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
        })
        .catch((error: any) => {
          console.error('Error fetching comments:', error);
          Swal.fire({
            title: 'Errore',
            text: 'Errore durante l\'invio del commento',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
    }
  }

  getLocalUser(): {
    name: string,
    avatar: string
  } {
    const userName = localStorage.getItem('comment-user-name') || "Cap Nemo";
    const userAvatar = localStorage.getItem('comment-user-avatar') || "assets/images/avatars/default-avatar.png";
    return {
      name: userName,
      avatar: userAvatar
    }
  }

  setLocalUser(name: string, avatar: string) {
    localStorage.setItem('comment-user-name', name);
    localStorage.setItem('comment-user-avatar', `assets/images/avatars/${avatar}.jpg`);
  }

  loadComments() {
    this._commentService.getCommentList(this.currentId)
      .then((response: any) => {
        this.comments = response.rows;
      })
      .catch((error: any) => {
        console.error('Error fetching comments:', error);
      });
  }

  saveWarehouse() {
    this._catalogService.saveWarehouse(this.warehouse)
      .then((response: any) => {
        Swal.fire({
          title: 'Conferma',
          text: 'Magazzino salvato con successo',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      })
      .catch((error: any) => {
        console.error('Error fetching comments:', error);
        Swal.fire({
          title: 'Errore',
          text: 'Errore durante il salvataggio del magazzino',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      });
  }

  deleteWarehouse() {
    this._catalogService.deleteWarehouse(this.currentId)
      .then((response: any) => {
        Swal.fire({
          title: 'Conferma',
          text: 'Magazzino eliminato con successo',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
        this._router.navigate(['/catalog/warehouses']);
      })
      .catch((error: any) => {
        console.error('Error fetching comments:', error);
        Swal.fire({
          title: 'Errore',
          text: 'Errore durante l\'eliminazione del magazzino',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      });
  }

  addWarning() {
    if (!this.warehouse.warnings) {
      this.warehouse.warnings = [];
    }
    this.warehouse.warnings.push({
      type: '',
      description: '',
      active: false
    });
  }

  removeWarning(i: number) {
    this.warehouse.warnings.splice(i, 1);
  }

  generateFilterOptions() {
    this.tableConfig.filterGroups.forEach(filterGroup => {
      filterGroup.filters.forEach((filter: any) => {
        switch (filter.retriever) {
          case 'warehouses.list':
            filter.options = this.warehouse.map((warehouse: { name: any; id: any; }) => {
              return {
                label: warehouse.name,
                value: warehouse.id
              }
            });
            break;
        }
      });
    });
  }


  async getShippingRules(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._route,
        queryParams,
      }
    );
    query.filters = query.filters || {};
    query.filters.warehouse = {$oid: this.currentId};
    query['populateFields'] = ['warehouse'];
    const shippingRules: DynamicData = await this._catalogService.getShippingRuleList(query)
    shippingRules.rows.forEach(shippingRule => {
      const cutoffs = new Set();
      shippingRule.cutoffs.forEach((cutoff: any) => {
        cutoffs.add(`${cutoff.hh}:${cutoff.mm}`);
      })
      shippingRule.cutoffs = [...cutoffs].join(' | ');
      shippingRule.actions = shippingRule.actions || [];
      shippingRule.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/shipping-rules/' + shippingRule.code
      });
    })
    return shippingRules
  }
}
