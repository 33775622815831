import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReturnsListComponent } from "./returns-list/returns-list.component";
// import { OrdersViewComponent } from "./orders-view/orders-view.component";
import { CoreModule } from "@core/components/core.module";

const routes: Routes = [
  {
    path: 'returns',
    component: ReturnsListComponent,
    data: { animation: 'ReturnsListComponent' }
  },
  // {
  //   path: 'orders/:code',
  //   component: OrdersViewComponent,
  //   data: { animation: 'OrdersViewComponent' }
  // },
];

@NgModule({
  declarations: [
    ReturnsListComponent,
    // OrdersViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule
  ],
  providers: []
})
export class ReturnsModule {
}
