import {Component, OnDestroy, OnInit, TemplateRef} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApplicationService } from "@core/services/application.service";
import { CatalogService } from "@core/services/catalog.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DynamicData } from "../../../../@core/types/dynamic-table/dynamic-data";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})

export class CategoriesListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public newCategory: any = {
    name: '',
    code: '',
    parent: {},
    priceRules: [],
    description: '',
  };
  public categories: any[] = [];
  public parent: any;
  public groups: any[] = [];

  public tableConfig : TableConfig = {} as TableConfig;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _catalogService: CatalogService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('catalog-categories-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('categories')
      .then((table) => {
        this.tableConfig = table;
        this.refreshCategories();
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getCategories(query?: any) {
    query = query || {};
    const queryParams: Params = { ...query };
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
      }
    );
    query['populateFields'] = ['parent'];
    const categories: DynamicData = await this._catalogService.getCategoryList(query);
    categories.rows.forEach(category => {
      category.links = category.links || {};
      category.links.code = '/categories/' + category.code;
      if (category.parent) {
        category.links.parent = '/categories/' + category.parent.code;
      }
      category.actions = category.actions || [];
      category.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/categories/' + category.code
      });
    });
    return categories;
  }

  donwloadCSV() {
    this._catalogService.downloadCSVCategoryList();
  }

  addCategory(addNewCategoryModal: TemplateRef<any>) {
    return this.modalService.open(addNewCategoryModal, {
      size: 'lg',
      windowClass: 'modal modal-primary'
    });
  }

  saveNewCategory() {
    this.newCategory.parent = this.parent?._id;
    this._catalogService.newCategory(this.newCategory)
      .then((response: any) => {
        this.modalService.dismissAll();
        this.refreshCategories();
        this.getCategories();
      })
  }

  selectParent($event: any) {
    this.newCategory.parent = $event || undefined;
  }

  searchCategory($event: any) {
    this._catalogService.getCategoryList({
      search: $event.target.value,
      pageSize: 25,
    }).then((response: DynamicData) => {
      this.categories = response.rows.map((category: any) => {
        return {
          label: category.name,
          value: category.id
        }
      })
    })
  }

  refreshCategories() {
    this._catalogService.getCategoryList({
      pageSize: 100,
    })
      .then((response: any) => {
        this.categories = response.rows.map((category: any) => {
          return {
            label: category.name,
            value: category.id
          }
        })
        this.categories.unshift({
          label: "Nessuno",
          value: ""
        })
      })
  }
}
