<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Aziende</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.businessList.add')" class="btn btn-primary px-1" (click)="addBusiness(addNewBusinessModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>

<div class="glassy">
  <dynamic-table
    [dataRetriever]="getBusinesses.bind(this)"
    [columns]="tableConfig.columns"
    [filterGroups]="tableConfig.filterGroups"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
    [autoReloadEnable]="true"
  >
  </dynamic-table>
</div>

<ng-template #addNewBusinessModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="changePassword-modal">Aggiungi azienda</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- form to add a user -->
    <form [formGroup]="businessRegistration" (ngSubmit)="onSubmit()" class="d-flex flex-column col-12">
      <div class="container d-flex flex-column col-12">
        <div class=" container col-12 d-flex flex-column">
          <h3>Dettagli Aziendali</h3>
          <div class="form-section d-flex flex-row">
            <div class="col-12 form-group">
              <label class="label" for="name">Ragione Sociale:</label>
              <input id="name" class="form-control" formControlName="name" placeholder="Ragione Sociale">
            </div>
          </div>
          <div class="form-section d-flex flex-row">
            <div class="col-6 form-group">
              <label class="label" for="vatNumber">Partita IVA:</label>
              <input id="vatNumber" class="form-control" formControlName="vat" placeholder="Partita IVA">

            </div>
            <div class="col-6 form-group pl-1">
              <label class="label" for="fiscalCode">Codice Fiscale:</label>
              <input id="fiscalCode" class="form-control" formControlName="fiscalCode" placeholder="Codice Fiscale">

            </div>
          </div>
          <div class="form-section d-flex flex-row">
            <div class="col-6 form-group">
              <label class="label" for="uniqueCode">Codice Unico:</label>
              <input id="uniqueCode" class="form-control" formControlName="uniqueCode" placeholder="Codice Unico">

            </div>


            <div class="col-6 form-group pl-1">
              <label class="label" for="pec">PEC (Posta Elettronica Certificata):</label>
              <input id="pec" class="form-control" formControlName="pec" placeholder="PEC">

            </div>
          </div>
        </div>
        <div class="container col-12 d-flex flex-column mt-1 mb-0">
          <div class="row d-flex flex-row">
            <div class="col-6">
              <fieldset>
                <!-- Basic Multi select -->
                <span>Gruppo</span>
                <ng-select
                  [items]="groups"
                  bindLabel="name"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  [searchable]="true"
                  [(ngModel)]="newBusiness.group.name"
                  (change)="changeGroup($event)"
                >
                </ng-select>
                <!--/ Basic Multi select -->
              </fieldset>
            </div>
            <div class="col-6">
              <fieldset >
                <!-- Basic Multi select -->
                <span>Network</span>
                <ng-select
                  [items]="networks"
                  bindLabel="name"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  [searchable]="true"
                  [(ngModel)]="newBusiness.network.name"
                  (change)="changeNetwork($event)"
                >
                </ng-select>
                <!--/ Basic Multi select -->
              </fieldset>
            </div>
          </div>
        </div>
        <div class="container col-12 d-flex flex-column mt-2 mb-2">
          <h3>Sede legale</h3>
          <div class="form-section d-flex flex-row">
            <div class="col-6 form-group">
              <label class="label" for="location.legalStreet">Indirizzo:</label>
              <input id="location.legalStreet" class="form-control" formControlName="legalStreet"
                     placeholder="Indirizzo">

            </div>
            <div class="col-3 form-group pl-1">
              <label class="label" for="legalNumber">Numero Civico:</label>
              <input id="legalNumber" class="form-control" formControlName="legalNumber"
                     placeholder="Numero Civico">
            </div>

            <div class="col-3 form-group  pl-1">
              <label class="label" for="legalZipCode">CAP:</label>
              <input id="legalZipCode" class="form-control" formControlName="legalZipCode"
                     placeholder="CAP">

            </div>
          </div>
          <div class="form-section d-flex flex-row">
            <div class="col-5 form-group">
              <label class="label" for="legalCity">Città:</label>
              <input id="legalCity" class="form-control" formControlName="legalCity"
                     placeholder="Città">

            </div>
            <div class="col-2 form-group  pl-1">
              <label class="label" for="legalProvince">Provincia:</label>
              <input id="legalProvince" class="form-control" formControlName="legalProvince"
                     placeholder="Provincia">

            </div>
            <div class="col-5 form-group  pl-1">
              <label class="label" for="legalProvince">Regione:</label>
              <input id="legalRegion" class="form-control" formControlName="legalRegion"
                     placeholder="Regione">
            </div>
          </div>
        </div>
      </div>
      <div class="container d-flex flex-column col-12">
        <div *ngIf="!sameAddress" class="container col-12 d-flex flex-column">
          <h3>Indirizzo di consegna</h3>
          <div class="form-section d-flex flex-row">
            <div class="col-6 form-group">
              <label class="label" for="Street">Indirizzo:</label>
              <input id="Street" class="form-control" formControlName="Street"
                     placeholder="Indirizzo">

            </div>
            <div class="col-3 form-group">
              <label class="label" for="Number">Numero Civico:</label>
              <input id="Number" class="form-control" formControlName="Number"
                     placeholder="Numero Civico:">
            </div>

            <div class="col-3 form-group">
              <label class="label" for="ZipCode">CAP:</label>
              <input id="ZipCode" class="form-control" formControlName="ZipCode"
                     placeholder="CAP">

            </div>
          </div>
          <div class="form-section d-flex flex-row">
            <div class="col-6 form-group">
              <label class="label" for="City">Città :</label>
              <input id="City" class="form-control" formControlName="City"
                     placeholder="Città">

            </div>
            <div class="col-3 form-group">
              <label class="label" for="Province">Provincia:</label>
              <input id="Province" class="form-control" formControlName="Province"
                     placeholder="Provincia">
            </div>
            <div class="same-address col-3 form-group">
            </div>
          </div>
        </div>
      </div>
      <button class="submit btn btn-primary" type="submit">Aggiungi</button>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>
