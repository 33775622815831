<div class="auth-container">
  <div class="row">
    <div class="card col-12 auth-box">
      <div class="card-header justify-content-center">
        <a href="#" target="_self" class="brand-logo mb-0 mt-0">
          <img src="/assets/images/logo/logo-light.png" alt="brand-logo" height="50"/>
        </a>
      </div>
      <div class="card-body pt-2">
        <h2>Siamo felici di rivederti!</h2>
        <h5>Scegli l'azienda con cui fare accesso</h5>

        <!-- Search Box -->
        <div class="search-bar">
          <div class="search-icon">
            <i class="fa fa-search"></i>
          </div>
          <input type="text" [placeholder]="placeholderText" (input)="onSearch($event)" [(ngModel)]="searchQuery"/>
          <div class="clear-icon" (click)="clearSearch()">
            <i class="fa fa-times"></i>
          </div>
        </div>

        <!-- List of Items -->
        <div *ngIf="identities.length > 0" class="business-list col-12">
          <div *ngFor="let item of identities; index as i" class="business-item col-12"
               (click)="identifyUserWithBusinessCode(item.id)">
            <div class="row-number">{{ i + 1 }}</div>
            <div class="business-info">
              <div class="business-name">{{ item.name }}</div>
              <div class="partita-iva">IVA {{ item.vat }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="identities.length === 0" class="business-list col-12">
          <div class="business-item col-12">
            <div class="business-info">
              <div class="business-name">Nessuna azienda trovata</div>
            </div>
          </div>
        </div>
        <button class="submit" type="submit" (click)="signOut()">Logout</button>
      </div>
    </div>
  </div>
</div>
