import {Component, OnDestroy, OnInit, TemplateRef} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApplicationService } from "@core/services/application.service";
import { CatalogService } from "@core/services/catalog.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DynamicData } from "@core/types/dynamic-table/dynamic-data";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-brands-list',
  templateUrl: './brands-list.component.html',
  styleUrls: ['./brands-list.component.scss']
})

export class BrandsListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  public tableConfig : TableConfig = {} as TableConfig;


  public newBrand = {
    name: "",
    code: "",
    info: {
      kromeda: {
        code: "",
        type: ""
      }
    },
    logo: "",
    description: "",
  }





  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _catalogService: CatalogService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('catalog-brands-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('brands')
      .then((table) => {
        this.tableConfig = table;
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getBrands(query?: any) {
    query = query || {};
    const queryParams: Params = { ...query };
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
      }
    );
    query['populateFields'] = ['category', 'brand'];
    const brands:DynamicData = await this._catalogService.getBrandList(query)
    brands.rows.forEach(brand => {
      brand.actions = brand.actions || [];
      brand.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/brands/' + brand.id
      });
    })
    return brands
  }

  addBrand(addNewBrandModal: TemplateRef<any>) {
    return this.modalService.open(addNewBrandModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  addNewBrand( ) {
    this._catalogService.newBrand(this.newBrand).then(() => {
      this.modalService.dismissAll();
      this.getBrands();
    });
  }
}
