<div class="row">
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Piani {{ prime.code }}</h5>
        <div class="row">
          <div class="col-12 d-flex justify-content-center d-flex flex-column">
            <label class="text-primary">Codice:</label>
            <input type="text" class="form-control" [(ngModel)]="prime.code">
          </div>
          <div class="col-6 mt-1 d-flex justify-content-center d-flex flex-column">
            <label class="text-primary">Punto Minimo:</label>
            <input type="text" class="form-control" [(ngModel)]="prime.minPoint">
          </div>
          <div class="col-6 mt-1 d-flex justify-content-center d-flex flex-column">
            <label class="text-primary">Punto Massimo:</label>
            <input type="text" class="form-control" [(ngModel)]="prime.maxPoint">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-7 d-flex">
    <div class="card full-width">
      <div class="card-body p-3 d-flex flex-column">
        <h5 class="card-title pb-3">Grand Prix</h5>
        <div class="slider-container pt-1 full-width">
          <ngb-progressbar
            class="prime-progress"
            type="primary"
            [value]="(rangeValue*100)/ (prime.minPoint - prime.maxPoint)"
            [striped]="true"
            [animated]="true"
            [height]="'10px'"
          ></ngb-progressbar>
          <ng-container *ngFor="let step of prime.steps; let i = index">
            <div *ngIf="i > 0" class="step-banner" [style.left]="calculatePositionRange(i) + '%'"
                 [class.top]="step.top">
              <span class="text-primary">{{ Number(step.label) | price }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-gift text-primary gift-icon">
                <polyline points="20 12 20 22 4 22 4 12"></polyline>
                <rect x="2" y="7" width="20" height="5"></rect>
                <line x1="12" y1="22" x2="12" y2="7"></line>
                <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
              </svg>
            </div>
            <div *ngIf="i === 0" class="start-flag" [style.left]="calculatePositionRange(i) + '%'"
                 [class.top]="step.top">
              <img src="assets/images/icons/start.png" class="start-flag">
            </div>
            <div *ngIf="i > 0" class="step-banner" [style.left]="calculatePositionRange(i) + '%'"
                 [class.bottom]="step.bottom">
              <span class="text-success">{{ step.target | price }}</span>
            </div>
            <div *ngIf="i > 0" class="line-connector" [style.left]="calculatePositionRange(i) + '%'"></div>
          </ng-container>
          <img src="assets/images/icons/elan-gialla.svg" class="bip-icon"
               [style.left]="((rangeValue*100)/ (prime.minPoint - prime.maxPoint)) + '%'">
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Azioni</h5>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-primary btn-block" (click)="savePrime()">
            <app-svg-icon name="save"></app-svg-icon>
            Salva
          </button>
        </div>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-danger btn-block" (click)="deletePrime()">
            <app-svg-icon name="delete"></app-svg-icon>
            Elimina
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Passi</h5>
        <div class="row">
          <ng-container *ngFor="let step of prime.steps; let i = index">
            <div class="col-2 pb-1">
              <label class="mt-2 text-primary font-weight-bold">Passo {{ i + 1 }}:</label>
              <div class="row">
                <div class="col-6">
                  <label>Etichetta:</label>
                  <input type="text" class="form-control" [(ngModel)]="step.label">
                </div>
                <div class="col-6">
                  <label>La Targa:</label>
                  <input type="number" class="form-control" [(ngModel)]="step.target">
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">la aziende ha un abbonamento a piano </h5>
        <dynamic-table
          [dataRetriever]="getSubscription.bind(this)"
          [filterGroups]="tableConfig.filterGroups"
          [columns]="tableConfig.columns"
          [page]="tableConfig.pagination.page"
          [pageSize]="tableConfig.pagination.pageSize"
          [sort]="tableConfig.pagination.sort"
          [search]="tableConfig.pagination.search"
          [strictSearch]="tableConfig.pagination.strictSearch"
          [messages]="tableConfig.messages"
          [autoReloadEnable]="true"
        >
        </dynamic-table>
      </div>
    </div>
  </div>
</div>


<ng-template
  #rewardsCell
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-rowIndex="rowIndex"
  let-rewardBadges="value"
>
  <ng-container *ngIf="rewardBadges.length > 0">
    <div class="flex flex-row items-center">
      <ng-container *ngFor="let badge of rewardBadges">
        <a
          class="badge badge-pill mr-05 p-05 {{badge.class}}"
          ngbTooltip="{{badge.amount}}"
        >
          {{ badge.label }}
        </a>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
