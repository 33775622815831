import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SuppliersListComponent } from "./suppliers-list/suppliers-list.component";
import { CoreModule } from "@core/components/core.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import { SuppliersViewComponent } from "./suppliers-view/suppliers-view.component";

const routes: Routes = [
  {
    path: 'suppliers',
    component: SuppliersListComponent,
    data: { animation: 'SuppliersListComponent' }
  },
  {
    path: 'suppliers/:id',
    component: SuppliersViewComponent,
    data: { animation: 'SuppliersViewComponent' }
  }
];

@NgModule({
  declarations: [
    SuppliersListComponent,
    SuppliersViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    NgSelectModule,
    FormsModule
  ],
  providers: []
})
export class SuppliersModule {
}
