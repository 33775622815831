<div class="layout"
     [class.active-header]="!headerHidden"
     [class.active-sidebar]="!sidebarHidden"
     [class.active-footer]="!footerHidden"
>
  <app-sidebar class="sidebar"></app-sidebar>

  <app-header class="header"></app-header>

  <div class="wrapper">
    <app-content class="content"></app-content>
    <app-footer class="footer"></app-footer>
  </div>
</div>
