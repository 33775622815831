import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrandsListComponent } from "./brands-list/brands-list.component";
import { CoreModule } from "@core/components/core.module";
import { BrandsViewComponent } from "./brands-view/brands-view.component";
import {FormsModule} from "@angular/forms";
const routes: Routes = [
  {
    path: 'brands',
    component: BrandsListComponent,
    data: { animation: 'BrandsListComponent' }
  },
    {
        path: 'brands/:id',
        component: BrandsViewComponent,
        data: { animation: 'BrandsViewComponent' }
    }
];

@NgModule({
  declarations: [
    BrandsListComponent,
    BrandsViewComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        CoreModule,
        FormsModule
    ],
  providers: []
})
export class BrandsModule {
}
