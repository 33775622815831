import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {CustomersService} from "../../../../@core/services/customers.service";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal2 from "sweetalert2";

@Component({
  selector: 'app-banner-view',
  templateUrl: './banners-view.component.html',
  styleUrls: ['./banners-view.component.scss']
})

export class BannersViewComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;
  public loaded: Promise<boolean> = Promise.resolve(false);
  public currentId: any;
  public banner: any ;
  protected networks: any[] = [];


  public tableConfig: TableConfig = {} as TableConfig;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------


  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _customerService: CustomersService,
    private _dynamicTableService: DynamicTableService,
    public _authService: AuthenticationService,
    private modalService: NgbModal
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('cms-banners-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loaded = Promise.resolve(false);
    this.currentId = this._activatedRoute.snapshot.paramMap.get('id') || '';
    this._shopService.getBanner(this.currentId).then((data: any) => {
      this.banner = data;
      this.loaded = Promise.resolve(true);
      this._customerService.getNetworks()
        .then((response: any) => {
          this.networks = response.rows;
        });
    } );

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  saveBanner() {
    this._shopService.updateBanner(this.banner).then((data: any) => {
      Swal2.fire('Success', 'Banner updated successfully', 'success');
    });
  }

  deleteBanner() {
    Swal2.fire({
      title: 'Sei sicuro?',
      text: 'Non sarai in grado di recuperare questo banner!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, elimina',
      cancelButtonText: 'No, annulla',
    }).then((result) => {
      if (result.isConfirmed) {
        this._shopService.deleteBanner(this.banner.id).then((data: any) => {
          Swal2.fire('Eliminato!', 'Il banner è stato eliminato.', 'success');
          this._router.navigate(['/banners']);
        });
      }
    });
  }

  uploadImage(i: number) {
    //open a file selector
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e: any) => {
      const file = e.target.files[0];
      this._shopService.uploadBannerImage(file).then((data: any) => {
        this.banner.pictures[i].src = data.url;
      });
    };
    input.click();
  }

  addPicture() {
    this.banner.pictures.push({src: '', link: ''});
  }

  deletePicture(i: number) {
    this.banner.pictures.splice(i, 1);
  }
}
