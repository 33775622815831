<div class="full-width d-flex justify-content-between">
  <div class="name">
    <h1 class="font-medium-5 px-025 my-1">Prodotti</h1>
  </div>
  <div class="actions">
    <button *ngIf="_authService.checkPermission('admin.page.productsList.add')" class="btn btn-primary px-1 mb-1" (click)="addProduct(addNewProductModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getProducts.bind(this)"
    [columns]="tableConfig.columns"
    [filterGroups]="tableConfig.filterGroups"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>

<ng-template #addNewProductModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="newOrder-modal">Aggiungi prodotto</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Codice</h5>
        <input type="text" class="form-control font-small-3 mb-1" [(ngModel)]="newProduct.code">
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Nome</h5>
        <input type="text" class="form-control font-small-3 mb-1" [(ngModel)]="newProduct.name">
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Brand</h5>
        <ng-select id="warehouse" [items]="brands" bindLabel="name"
                   bindValue="id"
                   placeholder="Seleziona un marchio"
                   [(ngModel)]="newProduct.brand"
                   (input)="onBrandInput($event)"
                   class="mb-1">
        </ng-select>
      </div>
      <div class="col-12" *ngIf="newProduct.listPrice">
        <h5 class="font-small-3 text-primary">Prezzo di listino</h5>
        <input type="text" class="form-control font-small-3 mb-1" [(ngModel)]="newProduct.listPrice">
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Categoria</h5>
        <ng-select id="category" [items]="categories" bindLabel="name"
                   bindValue="id"
                   placeholder="Seleziona un categoria"
                   [(ngModel)]="newProduct.category"
                   (input)="onCategoryInput($event)"
                   class="mb-1">
        </ng-select>
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Familia (descrittivo interno)</h5>
        <input type="text" class="form-control font-small-3 mb-1" placeholder="-" [(ngModel)]="newProduct.family">

      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Descrizione</h5>
        <input type="text" class="form-control font-small-3 mb-1" placeholder="Nessuna descrizione"
               [(ngModel)]="newProduct.description">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addNewProduct()">Aggiungi</button>
  </div>
</ng-template>
