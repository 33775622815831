import {Component, Input, ElementRef, ViewChild, AfterViewInit, SimpleChanges} from '@angular/core';
import {Chart, registerables} from 'chart.js';

@Component({
  selector: 'app-pricing-chart',
  template: '<div class="chart-container">\n' +
    '  <canvas #chartCanvas></canvas>\n' +
    '</div>\n',
  styles: ['.chart-container {\n' +
  '  display: flex;\n' +
  '  justify-content: center;\n' +
  '  align-items: center;\n' +
  '  height: 400px; /* Adjust the height as needed */\n' +
  '  /* Optionally, add more styles for padding, margins, etc. */\n' +
  '}\n' +
  '\n' +
  'canvas {\n' +
  '  /* Ensure the canvas takes up the available space within the container */\n' +
  '  width: 100%;\n' +
  '  height: 100%;\n' +
  '  padding: 2rem;\n' +
  '}']
})
export class PricingChartComponent implements AfterViewInit {
  @ViewChild('chartCanvas') chartCanvas!: ElementRef<HTMLCanvasElement>;
  @Input() pricingData: any[] = [];
  private chartInstance: Chart | null = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pricingData'] && !changes['pricingData'].firstChange) {
      this.updateChart(); // Update the chart when pricingData changes
    }
  }
  ngAfterViewInit() {
    if (this.chartCanvas) {
      const ctx = this.chartCanvas.nativeElement.getContext('2d');
      if (ctx) {
        Chart.register(...registerables);
        this.chartInstance = new Chart(ctx, {
          type: 'bar', // Change chart type to 'bar'
          data: {
            labels: this.pricingData.map(item => item.from),
            datasets: [
              {
                label: 'Mark-up',
                data: this.pricingData.map(item => item.markup),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
              }
            ]
          },
          options: {
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Da (prezzo in euro)'
                },
                type: 'linear',
                position: 'bottom'
              },
              y: {
                title: {
                  display: true,
                  text: 'Maggiorazione (percentuale)'
                },
                beginAtZero: true // Start y-axis from 0
              }
            }
          }
        });
      }
    }
  }

  private updateChart() {
    console.log("update chart",this.pricingData);
    console.log("update chart 2",this.chartInstance , this.pricingData , this.pricingData.length);
    if (this.chartInstance && this.pricingData && this.pricingData.length > 0) {
      console.log("update chart entered",this.pricingData);
      this.chartInstance.data.labels = this.pricingData.map(item => item.from);
      this.chartInstance.data.datasets[0].data = this.pricingData.map(item => item.markUp);
      this.chartInstance.update();
    }
  }
}
