import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {NgbDateStruct, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CustomersService} from "@core/services/customers.service";
import {FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {CatalogService} from "../../../../@core/services/catalog.service";
import {PdfService} from "../../../../@core/services/pdf.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import Swal from 'sweetalert2';
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-order-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})

export class OrdersListComponent implements OnInit, OnDestroy {

  // Private

  @ViewChild("paymentsCell", {static: true})
  private paymentsCellTemplate?: TemplateRef<any>;

  private _unsubscribeAll: Subject<any>;
  public newOrder: Order = {} as Order;
  public businesses: any[] = [];
  public paymentMethods: any[] = [];
  public brands: any[] = [];
  public suppliers: any[] = [];
  public suppliers2: any[] = [];
  public shippingMethods: Delivery[] = [];
  public deliveries: any[] = [
    {
      name: 'Corriere',
      price: 0,
      date: '',
      cutoff: '',
      free: 0
    },
    {
      name: 'Ritiro in sede',
      price: 0,
      date: '',
      cutoff: '',
      free: 0
    },
    {
      name: 'Ritiro in negozio',
      price: 0,
      date: '',
      cutoff: '',
      free: 0
    },
    {
      name: 'Ritiro in magazzino',
      price: 0,
      date: '',
      cutoff: '',
      free: 0
    },
    {
      name: 'Ritiro in deposito',
      price: 0,
      date: '',
      cutoff: '',
      free: 0
    },
  ];

  // Public


  public tableConfig: TableConfig = {} as TableConfig;


  public productsForm!: FormGroup;
  public paymentsForm!: FormGroup;

  public invoice: any = {
    documentNames: [
      "Reso Fornitore",
      "Ordine Fornitore",
    ],
    documentName: '',
    number: '',
    invoiceDate: '',
    productQuantity: 0,
    vectorName: '',
    clients: [
      {
        name: "SIDAT SPA",
        address1: "CORSO VINZAGLIO 2",
        address2: "10121 TORINO (TO)",
        vat: "05585370017",
      },
      {
        name: "I.D.I.R - IMPORTAZIONE DISTRIBUZIONE RICAMBI S.P.A",
        address1: "VIA MEDINA 40",
        address2: "80133 NAPOLI (NA)",
        vat: "01283111217",
      },
      {
        name: "EUROGLASS SRL",
        address1: "VIA L. PALMA DI CESNOLA 109",
        address2: "10121 TORINO (TO)",
        vat: "12342520017",
      },
      {
        name: "CIDA AUTO COMPONENTS SPA AUTODIS",
        address1: "VIA STRADA SANTA CATERINA, 4",
        address2: "70124 BARI (BA)",
        vat: "04769580723",
      },
      {
        name: "CATI SPA",
        address1: "VIA PRETEGIANI 27",
        address2: "10078 VENARIA REALE (TO)",
        vat: "01237760051",
      },
      {
        name: "BORI SRL",
        address1: "VIA DELLA FISICA 5",
        address2: "40068 SAN LAZZARO DI SAVENNA (BO)",
        vat: "02514970371",
      },
      {
        name: "AG CORES S.R.L.",
        address1: "VIA GIAIME PINTOR 101-121",
        address2: "47521 - CESENA (FO)",
        vat: "04422170409",
      },
      {
        name: "OVAM SPA",
        address1: "VIA NEWTON 12",
        address2: "20016 PERO (MI)",
        vat: "00745100156",
      },
      {
        name: "DI.PA SPORT SRL",
        address1: "VIA DELLA CHIUSA, 34",
        address2: "29010 ROVELETO DI CADEO",
        vat: "01508220330",
      },
      {
        name: "MAX s.r.l.",
        address1: "Via Verga 42",
        address2: "10036 SETTIMO T.SE (TO)",
        vat: "07085410012",
      },
    ],
    clients2: [
      {
        name: "SIDAT SPA",
        address1: "VIA ENZO FERRARI 8",
        address2: "10028 TROFARELLO (TO)",
        vat: "05585370017",
      },
      {
        name: "I.D.I.R - IMPORTAZIONE DISTRIBUZIONE RICAMBI S.P.A",
        address1: "Via Ficucella Piano T 1-2, Interporto Sud Europa",
        address2: "81024 Maddaloni CE",
        vat: "01283111217",
      },
      {
        name: "EUROGLASS SRL",
        address1: "VIA L. PALMA DI CESNOLA 109",
        address2: "10121 TORINO (TO)",
        vat: "12342520017",
      },
      {
        name: "CIDA AUTO COMPONENTS SPA AUTODIS",
        address1: "VIA STRADA SANTA CATERINA, 4",
        address2: "70124 BARI (BA)",
        vat: "04769580723",
      },
      {
        name: "CATI SPA",
        address1: "VIA PRETEGIANI 27",
        address2: "10078 VENARIA REALE (TO)",
        vat: "01237760051",
      },
      {
        name: "BORI SRL",
        address1: "VIALE ARTIGIANATO 30/C",
        address2: "12051 ALBA (CN)",
        vat: "02514970371",
      },
      {
        name: "AG CORES S.R.L.",
        address1: "VIA GIAIME PINTOR 101-121",
        address2: "47521 - CESENA (FO)",
        vat: "04422170409",
      },
      {
        name: "OVAM SPA",
        address1: "VIA NEWTON 12",
        address2: "20016 PERO (MI)",
        vat: "00745100156",
      },
      {
        name: "DI.PA SPORT SRL",
        address1: "VIA DELLA CHIUSA, 2/A",
        address2: "29016 CHIAVENNA LANDI",
        vat: "01508220330",
      },
      {
        name: "MAX s.r.l.",
        address1: "Via Verga 42",
        address2: "10036 SETTIMO T.SE (TO)",
        vat: "07085410012",
      },
    ],
    businessData: {
      name: '',
      address1: '',
      address2: '',
      vat: '',
      references: {},
    },
    destination: {
      name: '',
      address1: '',
      address2: '',
      vat: '',
      references: {},
    },
    returned: {
      number: '',
      date: '',
      casual: '',
      reference: '',
    },
    lines: [],
    transport: {
      method: ''
    },
    shipping: {
      method: '',
      packages: '',
    }
  };


  selectedClient: any;
  selectedDestination: any;
  selectedAddress: Address = { number: '', city: '', country: '', province: '', region: '', street: '', zip: '' };
  selectedNetwork: any;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  private clientFilter!: any[];
  private groups: any[] = [];
  public networks: any[] = []
  public prices: any;

  @ViewChild("clientStep", {static: false})
  private clientStep?: ElementRef;
  @ViewChild("addressStep", {static: false})
  private addressStep?: ElementRef;
  @ViewChild("deliveryStep", {static: false})
  private deliveryStep?: ElementRef;
  @ViewChild("productStep", {static: false})
  private productStep?: ElementRef;
  @ViewChild("paymentStep", {static: false})
  private paymentStep?: ElementRef;

  public currentStep = 0;


  constructor(
    private _pdfService: PdfService,
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _customerService: CustomersService,
    private _formBuilder: FormBuilder,
    private _catalogService: CatalogService,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-orders-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('orders')
      .then((table) => {
        this.tableConfig = table;
        this.generateFilterOptions();
        this.tableConfig.filterGroups[0].filters[0].options = this.clientFilter;
        this.productsForm = this._formBuilder.group({
          productsArray: this._formBuilder.array([])
        });
        this.paymentsForm = this._formBuilder.group({
          paymentsArray: this._formBuilder.array([])
        });

        this.addProductRow();
        this.addPaymentRow();
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
          this.tableConfig.pagination.strictSearch = queryParams.strictSearch !== 'false';
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }
        const paymentsColumn = this.tableConfig.columns.find(column => column.prop === 'payments');
        if (paymentsColumn) {
          paymentsColumn.cellTemplate = this.paymentsCellTemplate;
        }
        this._customerService.getBusinessesList()
          .then((businesses) => {
            this.businesses = businesses.rows;
            this.businesses.forEach(business => {
              this.tableConfig.filterGroups[0].filters[0].options.push({
                label: business.name,
                value: business._id
              })
            })
          })
          .catch((error: any) => {
          });

        this._catalogService.getSupplierList()
          .then((suppliers) => {
            this.suppliers = suppliers.rows;
            this.suppliers.forEach(supplier => {
              this.tableConfig.filterGroups[0].filters[1].options.push({
                label: supplier.name,
                value: supplier._id
              })
            })
          })
          .catch((error: any) => {
          });
        this._shopService.getPaymentMethodList()
          .then((paymentMethods) => {
            this.paymentMethods = paymentMethods.rows;
          })
          .catch((error: any) => {
          });
        this._catalogService.getBrandList()
          .then((brands) => {
            this.brands = brands.rows;
          })
          .catch((error: any) => {
          });
      })
  }

  addProductRow() {
    const productFormGroup = this._formBuilder.group({
      code: ['', Validators.required],
      description: ['', Validators.required],
      brand: ['', Validators.required],
      price: ['', Validators.required],
      quantity: ['', Validators.required],
      cost: ['', Validators.required],
      total: [{value:'', disabled:true}, Validators.required]
    });

    this.newOrderProducts.push(productFormGroup);
    (this.productsForm.get('productsArray') as FormArray).push(productFormGroup);
  }

  // Function to remove a product form group from the array
  removeProductRow(index: number) {
    this.newOrderProducts.splice(index, 1);
    (this.productsForm.get('productsArray') as FormArray).removeAt(index);
  }

  onSubmit() {
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  selectedBusiness: any;
  selectedSupplier: any;
  selectedDelivery: Delivery = { price: 0, date: '', cutoff: '', free: 0 };
  search: any;
  search2: any;
  search3: any;
  showDropdown: boolean = false;
  showDropdown2: boolean = false;
  showDropdown3: boolean = false;
  newOrderProducts: FormGroup[] = [];
  newOrderPayments: FormGroup[] = [];

  selectedMethod: any;
  selectedBrand: any;

  public loaded: Promise<boolean> = Promise.resolve(false);



  async getOrders(query?: any) {
    this.loaded = Promise.resolve(false);
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['business', 'network', 'warehouse', 'payments.payment'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    console.log('getting the data', query)
    const orders: DynamicData = await this._shopService.getOrderList(query)
    console.log(orders)
    orders.rows.forEach(order => {
      order.links = order.links || {};
      order.links.code = '/orders/' + order.code;
      order.products = order.products.reduce((acc: number, product: any) => {
        acc += product.total
        return acc
      }, 0);
      order.actions = order.actions || [];
      order.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/orders/' + order.code
      });
    })
    this.loaded = Promise.resolve(true);
    return orders
  }




  searchBusiness(event: any) {
    const search = event.target.value;
    this.showDropdown = true;
    this.selectedBusiness = null;
    if (search.length == 0) {
      this.showDropdown = false;
      this.businesses = [];
      return;
    } else {
      this._customerService.getBusinessList({search: search})
        .then((response) => {
          console.log('Businesses', response);
          this.businesses = response.rows;
        })
        .catch((error) => {
          console.log('Businesses error', error);
        })
    }
  }

  onBlur() {
    setTimeout(() => {
      this.showDropdown = false;
    }, 200);
  }

  selectBusiness(business: any) {
    this.selectedBusiness = business;
    this.paymentMethods = business.payments;
    this.search = business.name;
    this.showDropdown = false;

  }

  openPdfModal(modalPdf: TemplateRef<any>) {
    this.modalService.open(modalPdf, {
      size: 'xl',
      windowClass: 'modal modal-primary'
    });
  }

  downloadPDF() {
    console.log(this.invoice);
    this._pdfService.setdInvoices("", this.invoice)
      .then((response: any) => {
        console.log(response);
        this._pdfService.getPdf(response.id).then((response2: any) => {
          console.log(response2);
          const blob = new Blob([response2], {type: 'application/pdf'});
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
      })
      .catch((error: any) => {
        console.error('Error downloading PDF:', error);
      });
  }


  updateDestination() {
    if (this.selectedDestination) {
      const existingClient = this.invoice.clients.find((client: Client) => client.name === this.selectedDestination.name);
      if (existingClient) {
        console.log(existingClient);
        this.invoice.destination = {
          name: existingClient.name,
          vat: existingClient.vat,
          address1: existingClient.address1,
          address2: existingClient.address2
        };
      } else {
        console.log("not found");
        this.createNew2(this.selectedDestination.name);
        this.invoice.destination = {
          name: this.selectedDestination.name,
          vat: '',
          address1: '',
          address2: ''
        };
      }
    } else {
      console.log("not found 2");
      this.createNew2(this.selectedDestination.name);
      this.invoice.destination = {
        name: this.selectedDestination.name,
        vat: '',
        address1: '',
        address2: ''
      };
    }
  }

  removeProduct(i: number) {
    this.invoice.lines.splice(i, 1);
  }

  createNew2(text: string) {
    this.selectedDestination = {
      name: text,
      vat: '',
      address1: '',
      address2: ''
    };
    this.invoice.clients.push({
      name: text,
      vat: '',
      address1: '',
      address2: ''
    })
    this.invoice.destination = {
      name: text,
      vat: '',
      address1: '',
      address2: ''
    };
    console.log('something');
    console.log(this.invoice);
  }

  updateBusinessData() {
    if (this.selectedClient) {
      const existingClient = this.invoice.clients.find((client: Client) => client.name === this.selectedClient.name);
      if (existingClient) {
        this.invoice.businessData = {
          name: existingClient.name,
          vat: existingClient.vat,
          address1: existingClient.address1,
          address2: existingClient.address2
        };
      } else {
        console.log("not found");
        this.createNew1(this.selectedClient.name);
        this.invoice.businessData = {
          name: this.selectedClient.name,
          vat: '',
          address1: '',
          address2: ''
        };
      }
    } else {
      console.log("not found 2");
      this.createNew1(this.selectedClient.name);
      this.invoice.businessData = {
        name: this.selectedClient.name,
        vat: '',
        address1: '',
        address2: ''
      };
    }
  }

  createNew1(text: string) {
    this.selectedClient = {
      name: text,
      vat: '',
      address1: '',
      address2: ''
    };
    this.invoice.clients.push({
      name: text,
      vat: '',
      address1: '',
      address2: ''
    })
    this.invoice.businessData = {
      name: text,
      vat: '',
      address1: '',
      address2: ''
    };
  }

  updateBusinessFilter($event: any, filter: any) {
    console.log('here');
    this._customerService.getBusinessList({
      search: $event.target.value,
      pageSize: 25,
    }).then((response: DynamicData) => {
      filter.options = response.rows.map(business => {
        return {
          label: business.name,
          value: business.id
        }
      })
    })
  }

  generateFilterOptions() {
    this.tableConfig.filterGroups.forEach(filterGroup => {
      filterGroup.filters.forEach((filter: any) => {
        switch (filter.retriever) {
          case 'networks.list':
            this._customerService.getNetworkList({
              pageSize: 50,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(network => {
                return {
                  label: network.name,
                  value: network.id
                }
              })
            })
            break;
          case 'warehouses.list':
            this._catalogService.getWarehouseList({
              pageSize: 100,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(group => {
                return {
                  label: group.name,
                  value: group.id
                }
              })
            })
            break;
          case 'businesses.list':
            this._customerService.getBusinessList({
              pageSize: 50,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(business => {
                return {
                  label: business.name,
                  value: business.id
                }
              })
            })
            break;
        }
      })
    })
    this._customerService.getGroups()
      .then((response: any) => {
        this.groups = response.rows;
        console.log('groups', this.groups);
      });
    this._customerService.getNetworks()
      .then((response: any) => {
        this.networks = response.rows;
        console.log('networks', this.networks);
      });

  }

  addProduct() {
    this.newOrder.products.push({
      _id: '',
      code: '',
      name: '',
      brand: '',
      category: '',
      vat: 0,
      price: 0,
      quantity: 0,
      stock: 0,
      multiplier: 0,
      min: 0,
      max: 0,
      unit: '',
      cost: 0,
      total: 0,
    })
  }

  addProductLine() {
    this.invoice.lines.push({
      code: '',
      description: '',
      brand: '',
      quantity: 0,
      price: 0,
      total: 0
    });
  }

  searchSuppliers(event: any) {
    const value = event.target.value;
    this.showDropdown2 = true;
    console.log(value);
    this.selectedSupplier = null;
    console.log(this.suppliers);
    this.suppliers2 = this.suppliers.filter(supplier => supplier.name.toLowerCase().includes(value.toLowerCase()));
    console.log(this.suppliers2);
  }

  selectSupplier(supplier: any) {
    this.selectedSupplier = supplier;
    console.log(supplier);
    this.search2 = supplier.name;
    this.showDropdown2 = false;
    this._catalogService.getDeliveryPrices({
      supplier: supplier.id
    }).then((response: any) => {
      this.prices = response.rows;
      console.log(this.prices);
    })
  }

  searchDelivery(event: any) {
    const value = event.target.value;
    this.showDropdown3 = true;
    this.deliveries = this.prices.filter((delivery: { name: string; }) => delivery.name.toLowerCase().includes(value.toLowerCase()));
  }

  selectDelivery(delivery: any) {
    this.selectedDelivery = delivery;
    this.search3 = delivery.name;
    this.showDropdown3 = false;
    this.selectedDelivery.price = delivery.price;
  }

  showProgressStepsWithHtmlFromTemplate() {
    const steps = [
      {title: 'Cliente', content: this.clientStep, number: '1'},
      {title: 'Indirizzo', content: this.addressStep, number: '2'},
      {title: 'Spedizione', content: this.deliveryStep, number: '3'},
      {title: 'Prodotti', content: this.productStep, number: '4'},
      {title: 'Pagamento', content: this.paymentStep, number: '5'}
    ];
    this.showProgressSteps(steps);
  }

  private showProgressSteps(steps: { title: string; content: any; number: any}[]) {
    console.log(steps);
    Swal.fire({
      title: steps[this.currentStep].title,
      html: steps[this.currentStep].content.nativeElement,
      currentProgressStep: this.currentStep,
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: '&larr; Indietro',
      cancelButtonText: 'Annulla',
      denyButtonText: 'Avanti &rarr;',
      denyButtonColor: 'success',
      cancelButtonColor: 'danger',
      confirmButtonColor: 'warning',
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: 'swal-wide',
      position: 'center',
      showCloseButton: true,
      width: '90%',
      heightAuto: true,
      showLoaderOnConfirm: true,
      progressSteps: steps.map(step => step.number),
      preConfirm: () => {
        return new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 200);
        });
      },
      preDeny: () => {
        return new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 200);
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if(this.currentStep > 0) {
          this.currentStep--;
          this.showProgressSteps(steps);
        }
      }
      else if (result.dismiss) {
        this.currentStep = 0;
        this.newOrderPayments = [
          this._formBuilder.group({
            method: ['', Validators.required],
            amount: ['', Validators.required],
            date: ['', Validators.required],
            notes: ['']
          })
        ];
        this.newOrderProducts = [
          this._formBuilder.group({
            code: ['', Validators.required],
            description: ['', Validators.required],
            brand: ['', Validators.required],
            price: ['', Validators.required],
            quantity: ['', Validators.required],
            cost: ['', Validators.required],
            total: [{value:'', disabled:true}, Validators.required]
          })
        ];
        this.selectedAddress = {
          number: '',
          city: '',
          country: '',
          province: '',
          region: '',
          street: '',
          zip: ''
        };
        this.selectedNetwork = null;
        this.selectedClient = null;
        this.selectedBrand = null;
        this.selectedMethod = null;

        Swal.fire({
          title: 'Annullato',
          html: 'Ordine annullato e progressi persi',
          confirmButtonText: 'Chiudi',
        });
      }
      else if (result.isDenied) {
        this.currentStep++;
        if(this.currentStep === 4) {
          console.log("payments", this.newOrderPayments);
          const total = this.newOrderProducts.reduce((acc, product) => {
            return acc + product.value.price;
          }, 0);
          console.log(total);
          this.newOrderPayments[0].value.amount = total;
        }
        console.log(this.currentStep);
        if (this.currentStep < steps.length) {
          this.showProgressSteps(steps);
        } else {
          console.log(this.selectedDelivery)
          console.log(this.selectedClient);
          console.log(this.newOrderProducts);
          const order: order = {
            business: this.selectedClient.id,
            address: this.selectedAddress,
            network: this.selectedNetwork,
            products: this.newOrderProducts.map(product => {
              console.log(product.value.brand);
              return {
                _id: '',
                code: product.value.code,
                name: product.value.description,
                brand: product.value.brand,
                category: '',
                vat: 22,
                price: product.value.price,
                quantity: product.value.quantity,
                stock: 0,
                multiplier: 1,
                min: 0,
                max: 999999,
                unit: '',
                cost: product.value.cost,
                total: product.value.total,
              }
            }),
            delivery: {
              price: this.selectedDelivery.price,
              date: '',
              cutoff: '',
              free: 0
            },
            warehouse: this.selectedSupplier,
            payments: this.newOrderPayments.map(payment => {
              return {
                method: this.selectedMethod.id,
                amount: payment.value.amount,
                date: payment.value.date,
                notes: payment.value.notes
              }
            }),
            createdAt: '',
            updatedAt: '',
            code: '',
            status: 'CREATED'
          }
          console.log(order);
          this.currentStep = 0;
          Swal.fire({
            title: 'Completato',
            html: 'Ordine creato con successo',
            confirmButtonText: 'Chiudi',
          });
          this.newOrderPayments = [
            this._formBuilder.group({
              method: ['', Validators.required],
              amount: ['', Validators.required],
              date: ['', Validators.required],
              notes: ['']
            })
          ];
          this.newOrderProducts = [
            this._formBuilder.group({
              code: ['', Validators.required],
              description: ['', Validators.required],
              brand: ['', Validators.required],
              price: ['', Validators.required],
              quantity: ['', Validators.required],
              cost: ['', Validators.required],
              total: ['', Validators.required]
            })
          ];
          this.selectedAddress = {
            number: '',
            city: '',
            country: '',
            province: '',
            region: '',
            street: '',
            zip: ''
          };
          this.selectedNetwork = null;
          this.selectedClient = null;
          this.selectedBrand = null;
          this.selectedMethod = null;
        }
      }
    });
  }

  selectClient($event: any) {
    this.selectedClient = $event;
    console.log($event);
    console.log(this.selectedClient);
    this.selectedAddress = this.selectedClient.location;
    this.selectedNetwork = this.selectedClient.network;
  }

  removePaymentRow(i: number) {
    this.newOrderPayments.splice(i, 1);
    (this.paymentsForm.get('paymentsArray') as FormArray).removeAt(i);
  }

  addPaymentRow() {
    const paymentFormGroup = this._formBuilder.group({
      method: ['', Validators.required],
      amount: ['', Validators.required],
      date: ['', Validators.required],
      notes: ['']
    });

    this.newOrderPayments.push(paymentFormGroup);
    (this.paymentsForm.get('paymentsArray') as FormArray).push(paymentFormGroup);
  }

  selectBrand($event: any, i: number) {
    this.selectedBrand = $event.id;
    console.log(this.selectedBrand);
    this.newOrderProducts[i].value.brand = $event.id;
    console.log(this.newOrderProducts[i]);
    console.log($event);
  }

  searchBrand($event: KeyboardEvent) {
    const value = ($event.target as HTMLInputElement).value;
    this.brands = this.brands.filter(brand => brand.name.toLowerCase().includes(value.toLowerCase()));
  }

  selectMethod($event: any) {
    this.selectedMethod = $event;
    console.log($event);
  }

  searchMethod($event: KeyboardEvent) {
    const value = ($event.target as HTMLInputElement).value;
    this.paymentMethods = this.paymentMethods.filter(method => method.name.toLowerCase().includes(value.toLowerCase()));
  }

  selectNetwork($event: any) {
    this.selectedNetwork = $event;
    console.log($event);
  }

  searchNetwork($event: KeyboardEvent) {
    const value = ($event.target as HTMLInputElement).value;
    this.networks = this.networks.filter(network => network.name.toLowerCase().includes(value.toLowerCase()));
  }

  recalculateTotal(i: number) {
    const product = this.newOrderProducts[i].value;
    console.log(product);
    this.newOrderProducts[i].value.total = product.price * product.quantity;
    console.log(this.newOrderProducts[i]);
  }

  inputDate($event: Event) {
    console.log($event);
  }

  formattedReturnedDate!: string;
  formattedInvoiceDate!: string;

  // Handle date change event
  onDateChange1(date: NgbDateStruct) {
    if (date) {
      // Convert NgbDateStruct to string format
      this.invoice.returned.date = `${date.year}/${date.month}/${date.day}`;
    } else {
      this.invoice.returned.date = ''; // Reset if date is null or undefined
    }
  }
  onDateChange2(date: NgbDateStruct) {
    if (date) {
      // Convert NgbDateStruct to string format
      this.invoice.invoiceDate = `${date.year}/${date.month}/${date.day}`;
    } else {
      this.invoice.invoiceDates = ''; // Reset if date is null or undefined
    }
  }
}


interface Address {
  number: string;
  city: string;
  country: string;
  province: string;
  region: string;
  street: string;
  zip: string;
}

interface Product {
  _id: string;
  code: string;
  name: string;
  brand: string;
  category: string;
  vat: number;
  price: number;
  quantity: number;
  stock: number;
  multiplier: number;
  min: number;
  max: number;
  unit: string;
  cost: number;
  total: number;
}

interface Delivery {
  price: number;
  date: string;
  cutoff: string;
  free: number;
}

interface Order {
  _id: string;
  business: string;
  address: Address;
  products: Product[];
  delivery: Delivery;
  network: string;
  warehouse: string;
  payments: string[];
  createdAt: string;
  updatedAt: string;
  code: string;
  status: string;
}

interface Client {
  name: string;
  vat: string;
  address1: string;
  address2: string;
}
interface payment {
  method: string;
  amount: number;
  date: string;
  notes: string;
}

interface order {
  business: string;
  address: Address;
  products: Product[];
  delivery: Delivery;
  network: string;
  warehouse: string;
  payments: payment[];
  createdAt: string;
  updatedAt: string;
  code: string;
  status: string;
}
