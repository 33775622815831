import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";

@Component({
  selector: 'app-returns-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss']
})

export class InvoicesListComponent implements OnInit, OnDestroy {

  // Private
  @ViewChild("sentDateCell", {static: true})
  private sentDateTemplate?: TemplateRef<any>;

  private _unsubscribeAll: Subject<any>;


  public tableConfig: TableConfig = {} as TableConfig;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dynamicTableService: DynamicTableService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-invoices-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('invoices')
      .then((table) => {
        this.tableConfig = table;
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
          this.tableConfig.pagination.strictSearch = queryParams.strictSearch !== 'false';
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }
        const sentDateColumn = this.tableConfig.columns.find(column => column.prop === 'sentDate');
        if (sentDateColumn) {
          sentDateColumn.cellTemplate = this.sentDateTemplate;
        }
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getInvoices(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['network', 'group'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const invoices: DynamicData = await this._shopService.getInvoiceList(query)
    invoices.rows.forEach(invoice => {
      invoice.links = invoice.links || {};
      invoice.links.code = '/invoices/' + encodeURIComponent(invoice.code);
      invoice.total = invoice.lines.reduce((total: number, line: any) => {
        if (!line.price) return total;
        if (!line.quantity) return total;
        return total + (line.price * line.quantity);
      }, 0);
      invoice.actions = invoice.actions || [];
      invoice.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/invoices/' + invoice.code
      });
    })
    return invoices
  }
}
