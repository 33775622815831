import {Component, OnDestroy, OnInit, TemplateRef} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {CustomersService} from "../../../../@core/services/customers.service";
import {CatalogService} from "../../../../@core/services/catalog.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {group} from "@angular/animations";
import Swal from "sweetalert2";

@Component({
  selector: 'app-cart-view',
  templateUrl: './carts-view.component.html',
  styleUrls: ['./carts-view.component.scss']
})

export class CartsViewComponent implements OnInit, OnDestroy {

  //public
  public loaded: Promise<boolean> = Promise.resolve(false);
  public cart: any;
  public currentId: string = '';
  public productsQuantity: number = 0;
  public totalPrice: number = 0;
  public warehouses: any[] = [];
  public selectedWarehouse: any = null;
  public newProduct: {} | any;
  public products: any[] = [];
  public showProductsDropdown: boolean = false;

  // Private
  private _unsubscribeAll: Subject<void>;
  private currentRequestID: number = 0;
  private selectedSupplier: any;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _customerService: CustomersService,
    private _catalogService: CatalogService,
    private modalService: NgbModal,
    private _route: ActivatedRoute,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-carts-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.newProduct = {
      price: null,
      priceRule: null,
      product: {
        brand: "",
        category: "",
        code: "",
        description: "",
        id: "",
        info: {},
        meta: [],
        name: "",
        pictures: [],
        suppliers: []
      },
      quantity: null,
      stock: 10000,
      vehicle: null
    };
    this.generateWarehouseList();
    this.loaded = Promise.resolve(false);
    this.currentId = this._route.snapshot.paramMap.get('id') || '';
    this._shopService.getSingleCartFromId(this.currentId).then((response) => {
      this.cart = response;
      if (this.cart.suppliers) {
        this.cart.suppliers.forEach((supplier: { products: any[]; }) => {
          supplier.products.forEach((product) => {
            this.productsQuantity += product.quantity;
            this.totalPrice += product.price * product.quantity;
          });
        });
      }

      this.loaded = Promise.resolve(true);
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  product: any;


  decreaseQuantity(product: any) {
    if (product.quantity > 1) {
      product.quantity -= 1;
      this.productsQuantity -= 1;
      this.totalPrice -= product.price;
    }
  }

  increaseQuantity(product: any) {
    if (product.quantity < product.stock) {
      product.quantity += 1;
      this.productsQuantity += 1;
      this.totalPrice += product.price;
    }
  }

  addSupplierModal(modalPdf: TemplateRef<any>) {
    this.modalService.open(modalPdf, {
      size: 'sm',
      windowClass: 'modal modal-primary'
    });
  }

  addProductModal() {

  }

  saveCart() {
    this._shopService.saveCart(this.currentId, this.cart).then((response) => {
      console.log(response);
      Swal.fire({
        title: 'Success!',
        text: 'Carrello salvato con successo',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
    })
    this._router.navigate(['/carts']);
  }

  deleteCart() {
    //ask with Swal if the user is sure to delete the cart
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Non potrai tornare indietro!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, elimina!'
    }).then((result) => {
      if (result.isConfirmed) {
        this._shopService.deleteCart(this.currentId).then((response) => {
          console.log(response);
          Swal.fire(
            'Eliminato!',
            'Il carrello è stato eliminato.',
            'success'
          )
          this._router.navigate(['/carts']);
        })
      }
    })
  }

  generateWarehouseList() {
    this._catalogService.getWarehouseList({
      pageSize: 100,
    }).then((response: DynamicData) => {
      this.warehouses = response.rows.map(group => {
        return {
          label: group.label,
          id: group.id,
          name: group.name,
          supplier: group.supplier
        }
      })
    })

  }

  addSupplierToCart() {
    console.log(this.selectedWarehouse);
    this.modalService.dismissAll();
    const newWarehouse = {
      products: [],
      supplier: {
        id: this.selectedWarehouse.id,
        active: true,
        label: this.selectedWarehouse.label,
        name: this.selectedWarehouse.name,
        supplier: this.selectedWarehouse.supplier
      }

    }
    let flag = false;
    this.cart.suppliers.forEach((supplier: any) => {
      if (supplier.supplier.id === newWarehouse.supplier.id || supplier.supplier.supplier === newWarehouse.supplier.supplier) {
        flag = true;
        console.log("supplier found", supplier, newWarehouse);
        Swal.fire({
          title: 'Attenzione!',
          text: 'Il magazzino è già presente nel carrello',
          icon: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }
    })
    if(!flag) {
      this.cart.suppliers.push(newWarehouse);
      Swal.fire({
        title: 'Magazzino aggiunto!',
        text: 'Ricordati di aggiungere almeno un prodotto',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
    }
  }

  openAddProductModal(modalPdf: TemplateRef<any>, supplier: any) {
    this.selectedSupplier = supplier;
    this.modalService.open(modalPdf, {
      size: 'lg',
      windowClass: 'modal modal-primary'
    });
  }

  addProductToCart(product: any) {
    this.newProduct.product = product;
    this.newProduct.quantity = 1;
    this.newProduct.price = product.suppliers[0].price || 0;
    this.productsQuantity += this.newProduct.quantity;
    this.totalPrice += this.newProduct.price * this.newProduct.quantity;
    if(this.newProduct.price === 0) {
      console.log(this.newProduct)
      this.newProduct.product.suppliers.forEach((supplier: any) => {
        if (
          supplier.supplier === this.selectedSupplier.supplier.id ||
          supplier.supplier === this.selectedSupplier.supplier.supplier
        ) {
          this.newProduct.price = supplier.price;
        }
      });
    }
    this.modalService.dismissAll();
    this.selectedSupplier.products.push(this.newProduct);
    this.newProduct = {
      price: null,
      priceRule: null,
      product: {
        brand: "",
        category: "",
        code: "",
        description: "",
        id: "",
        info: {},
        meta: [],
        name: "",
        pictures: [],
        suppliers: []
      },
      quantity: null,
      stock: 10000,
      vehicle: null
    };
    Swal.fire({
      title: 'Prodotto aggiunto!',
      text: 'Ricordati di impostare la quantità e il prezzo correttamente',
      icon: 'success',
      confirmButtonText: 'Ok'
    })
  }

  searchProductsByName(name: string) {
    if (name.length === 0) {
      this.showProductsDropdown = false;
      this.products = [];
    } else {
      setTimeout(() => {
        this.currentRequestID++;
        const reqID = this.currentRequestID;
        this.showProductsDropdown = true;
        this._catalogService.searchProducts(name).then((response) => {
          if (this.currentRequestID === reqID) {
            this.products = response.rows;

            // Remove products that don't have the selected supplier
            this.products = this.products.filter((product: any) => {
              let flag = false;
              product.suppliers.forEach((supplier: any) => {
                if (
                  supplier.supplier === this.selectedSupplier.supplier.id ||
                  supplier.supplier === this.selectedSupplier.supplier.supplier
                ) {
                  console.log("supplier found", supplier, this.selectedSupplier);
                  flag = true;
                }
              });
              return flag;
            });
          }
        });
      }, 300);
    }
  }


  removeProductFromCart(product: any) {
    this.productsQuantity -= product.quantity;
    this.totalPrice -= product.price * product.quantity;
    this.cart.suppliers.forEach((supplier: any) => {
      supplier.products = supplier.products.filter((p: any) => p.product.id !== product.product.id);
    })
  }

  removeSupplierFromCart(supplier: any) {
    this.cart.suppliers = this.cart.suppliers.filter((s: any) => s.supplier.id !== supplier.supplier.id);
  }

  updatePrices() {
    this.productsQuantity = 0;
    this.totalPrice = 0;
    if (this.cart.suppliers) {
      this.cart.suppliers.forEach((supplier: { products: any[]; }) => {
        supplier.products.forEach((product) => {
          this.productsQuantity += product.quantity;
          this.totalPrice += product.price * product.quantity;
        });
      });
    }
  }
}
