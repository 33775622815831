import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { ApplicationService } from "../../../../@core/services/application.service";
import { ShopService } from "../../../../@core/services/shop.service";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { CustomersService } from "../../../../@core/services/customers.service";
import { NgbDate, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { Invoice } from "./invoice.interface";
import Swal2 from "sweetalert2";
import { CommentsService } from "../../../../@core/services/comments.service";

@Component({
  selector: 'app-invoice-new',
  templateUrl: './invoice-new.component.html',
  styleUrls: ['./invoice-new.component.scss']
})

export class InvoiceNewComponent implements OnInit, OnDestroy {

  public file: File | null = null;

  public businesses: any[] = [];

  public tmpFiscalRepresentative: {
    active: boolean;
    fiscalRepresentativeId: {
      country: string;
      fiscal: string;
    };
    personalData: {
      denominazione: {
        value: string;
        active: boolean;
      };
      name: {
        value: string;
        active: boolean;
      };
      surname: {
        value: string;
        active: boolean;
      };
    };
  } = {
    active: true,
    fiscalRepresentativeId: {
      country: '',
      fiscal: ''
    },
    personalData: {
      denominazione: {
        value: '',
        active: true
      },
      name: {
        value: '',
        active: true
      },
      surname: {
        value: '',
        active: true
      },
    }
  }

  public newPayment: any = {
    beneficiary: {
      value: '',
      active: false,
    },
    paymentMethod: '',
    paymentTermsReferenceDate: {
      value: '',
      active: false,
    },
    paymentTermsDays: {
      value: '',
      active: false,
    },
    paymentDueDate: {
      value: '',
      active: false,
    },
    paymentAmount: '',
    postOfficeCode: {
      value: '',
      active: false,
    },
    payerSurname: {
      value: '',
      active: false,
    },
    payerName: {
      value: '',
      active: false,
    },
    payerCF: {
      value: '',
      active: false,
    },
    payerTitle: {
      value: '',
      active: false,
    },
    financialInstitution: {
      value: '',
      active: false,
    },
    IBAN: {
      value: '',
      active: false,
    },
    ABI: {
      value: '',
      active: false,
    },
    CAB: {
      value: '',
      active: false,
    },
    BIC: {
      value: '',
      active: false,
    },
    earlyPaymentDiscount: {
      value: '',
      active: false,
    },
    earlyPaymentDeadline: {
      value: '',
      active: false,
    },
    latePaymentPenalty: {
      value: '',
      active: false,
    },
    penaltyStartDate: {
      value: '',
      active: false,
    },
    paymentCode: {
      value: '',
      active: false,
    }
  }

  public newInvoice: Invoice = {
    header: {
      transmitter: {
        idTransmitter: {
          country: 'IT',
          fiscal: ''
        },
        sendingProgress: 'Automatico',
        transmissionFormat: 'FPR12',
        recipientCode: '',
        transmitterContacts: {
          active: false,
          phone: '',
          email: ''
        },
        recipientPEC: {
          active: false,
          value: ''
        }
      },
      sellerProvider: {
        providerId: {
          country: 'IT',
          fiscal: ''
        },
        taxCode: {
          value: '',
          active: false
        },
        personalData: {
          denominazione: {
            value: '',
            active: false
          },
          name: {
            value: '',
            active: false
          },
          surname: {
            value: '',
            active: false
          },
          title: {
            value: '',
            active: false
          },
          codEORI: {
            value: '',
            active: false
          },
          professionalRegistry: {
            value: '',
            active: false
          },
          registryProvince: {
            value: '',
            active: false
          },
          registryNumber: {
            value: '',
            active: false
          },
          registryDate: {
            value: new Date(),
            active: false
          },
          fiscalRegime: ''
        },
        address: {
          street: '',
          streetNumber: '',
          postalCode: '',
          city: '',
          province: {
            value: '',
            active: false
          },
          country: 'IT'
        },
        permanentEstablishment: {
          active: false,
          street: '',
          streetNumber: {
            value: '',
            active: false
          },
          postalCode: '',
          city: '',
          province: {
            value: '',
            active: false
          },
          country: 'IT'
        },
        REARegistration: {
          active: false,
          office: '',
          REANumber: '',
          shareCapital: {
            value: '',
            active: false
          },
          singlePartner: {
            value: '',
            active: false
          },
          liquidationStatus: ''
        },
        providerContacts: {
          active: false,
          phone: {
            value: '',
            active: false
          },
          fax: {
            value: '',
            active: false
          },
          email: {
            value: '',
            active: false
          },
        },
        administrationReference: {
          active: false,
          value: ''
        }
      },
      fiscalRepresentative: {
        active: false,
        fiscalRepresentativeId: {
          country: 'IT',
          fiscal: ''
        },
        taxCode: {
          value: '',
          active: false
        },
        personalData: {
          denominazione: {
            value: '',
            active: false
          },
          name: {
            value: '',
            active: false
          },
          surname: {
            value: '',
            active: false
          },
          title: {
            value: '',
            active: false
          },
          codEORI: {
            value: '',
            active: false
          },
        }
      },
      assigneeClient: {
        assigneeId: {
          active: false,
          country: 'IT',
          fiscal: ''
        },
        taxCode: {
          value: '',
          active: false
        },
        personalData: {
          denominazione: {
            value: '',
            active: false
          },
          name: {
            value: '',
            active: false
          },
          surname: {
            value: '',
            active: false
          },
          title: {
            value: '',
            active: false
          },
          codEORI: {
            value: '',
            active: false
          },
        },
        address: {
          street: '',
          streetNumber: {
            value: '',
            active: false
          },
          postalCode: '',
          city: '',
          province: {
            value: '',
            active: false
          },
          country: 'IT'
        },
        permanentEstablishment: {
          active: false,
          street: '',
          streetNumber: {
            value: '',
            active: false
          },
          postalCode: '',
          city: '',
          province: {
            value: '',
            active: false
          },
          country: 'IT'
        },
        fiscalRepresentative: {
          active: false,
          fiscalRepresentativeId: {
            country: 'IT',
            fiscal: ''
          },
          personalData: {
            denominazione: {
              value: '',
              active: false
            },
            name: {
              value: '',
              active: false
            },
            surname: {
              value: '',
              active: false
            },
          }
        }
      },
      thirdIntermediary: {
        active: false,
        intermediaryId: {
          active: false,
          country: 'IT',
          fiscal: ''
        },
        taxCode: {
          value: '',
          active: false
        },
        personalData: {
          denominazione: {
            value: '',
            active: false
          },
          name: {
            value: '',
            active: false
          },
          surname: {
            value: '',
            active: false
          },
          title: {
            value: '',
            active: false
          },
          codEORI: {
            value: '',
            active: false
          },
        }
      },
      issuingSubject: {
        active: false,
        subjectType: 'CC'
      }
    },
    body: {
      invoiceData: {
        documentType: '',
        number: '',
        date: new Date(),
        currency: '',
        withholdingData: {
          active: false,
          withholdingType: '',
          withholdingAmount: '',
          withholdingRate: '',
          paymentReason: ''
        },
        stampData: {
          active: false,
          virtualStamp: '',
          stampAmount: {
            value: 0,
            active: false
          },
        },
        pensionData: {
          active: false,
          pensionType: '',
          pensionAmount: '',
          pensionContributionAmount: '',
          pensionTaxableAmount: {
            value: '',
            active: false
          },
          pensionVATRate: '',
          pensionWithholding: {
            value: '',
            active: false
          },
          pensionNature: {
            value: '',
            active: false
          },
          administrativeReference: {
            value: '',
            active: false
          },
        },
        discountIncreaseData: {
          active: false,
          discountType: 'SC',
          discountPercentage: {
            value: 0,
            active: false
          },
          discountAmount: {
            value: 0,
            active: false
          },
        },
        totalDocumentAmount: {
          value: 0,
          active: false
        },
        rounding: {
          value: '',
          active: false
        },
        reason: {
          value: '',
          active: false
        },
        art73: {
          value: '',
          active: false
        },
      },
      purchaseOrderData: {
        active: false,
        lineNumber: {
          value: '',
          active: false
        },
        documentId: '',
        date: {
          value: new Date(),
          active: false
        },
        itemNumber: {
          value: '',
          active: false
        },
        conventionCode: {
          value: '',
          active: false
        },
        CUPCode: {
          value: '',
          active: false
        },
        CIGCode: {
          value: '',
          active: false
        },
      },
      contractData: {
        active: false,
        lineNumber: {
          value: '',
          active: false
        },
        documentId: '',
        date: {
          value: new Date(),
          active: false
        },
        itemNumber: {
          value: '',
          active: false
        },
        conventionCode: {
          value: '',
          active: false
        },
        CUPCode: {
          value: '',
          active: false
        },
        CIGCode: {
          value: '',
          active: false
        },
      },
      conventionData: {
        active: false,
        lineNumber: {
          value: '',
          active: false
        },
        documentId: '',
        date: {
          value: new Date(),
          active: false
        },
        itemNumber: {
          value: '',
          active: false
        },
        conventionCode: {
          value: '',
          active: false
        },
        CUPCode: {
          value: '',
          active: false
        },
        CIGCode: {
          value: '',
          active: false
        },
      },
      receptionData: {
        active: false,
        lineNumber: {
          value: '',
          active: false
        },
        documentId: '',
        date: {
          value: new Date(),
          active: false
        },
        itemNumber: {
          value: '',
          active: false
        },
        conventionCode: {
          value: '',
          active: false
        },
        CUPCode: {
          value: '',
          active: false
        },
        CIGCode: {
          value: '',
          active: false
        },
      },
      linkedInvoicesData: {
        active: false,
        lineNumber: {
          value: '',
          active: false
        },
        documentId: '',
        date: {
          value: new Date(),
          active: false
        },
        itemNumber: {
          value: '',
          active: false
        },
        conventionCode: {
          value: '',
          active: false
        },
        CUPCode: {
          value: '',
          active: false
        },
        CIGCode: {
          value: '',
          active: false
        },
      },
      SALData: {
        active: false,
        phaseReference: ''
      },
      DDTData: {
        active: false,
        DDTNumber: '',
        DDTDate: '',
        lineNumber: {
          value: '',
          active: false
        }
      },
      transportData: {
        active: false,
        carrier: {
          active: false,
          id: {
            country: 'IT',
            fiscal: ''
          },
          taxCode: {
            value: '',
            active: false
          },
          personalData: {
            denominazione: {
              value: '',
              active: false
            },
            name: {
              value: '',
              active: false
            },
            surname: {
              value: '',
              active: false
            },
            title: {
              value: '',
              active: false
            },
            codEORI: {
              value: '',
              active: false
            }
          },
          driverLicenseNumber: {
            value: '',
            active: false
          }
        },
        transportVehicle: {
          value: '',
          active: false
        },
        transportReason: {
          value: '',
          active: false
        },
        numberOfPackages: {
          value: '',
          active: false
        },
        description: {
          value: '',
          active: false
        },
        weightUnitMeasure: {
          value: '',
          active: false
        },
        grossWeight: {
          value: '',
          active: false
        },
        netWeight: {
          value: '',
          active: false
        },
        pickupDateTime: {
          value: '',
          active: false
        },
        transportStartDate: {
          value: '',
          active: false
        },
        deliveryType: {
          value: '',
          active: false
        },
        deliveryAddress: {
          active: false,
          street: '',
          streetNumber: {
            value: '',
            active: false
          },
          postalCode: '',
          city: '',
          province: {
            value: '',
            active: false
          },
          country: 'IT'
        },
        deliveryDateTime: {
          value: '',
          active: false
        },
      },
      mainInvoiceData: {
        active: false,
        number: '',
        date: ''
      },
      goodsServicesData: {
        lineDetails: [],
        summaryDetails: [],
      },
      vehicleData: {
        active: false,
        date: '',
        totalDistance: '',
      },
      paymentData: {
        active: false,
        payment: []

      },
      attachments: {
        name: '',
        active: false,
        compressionAlgorithm: {
          value: '',
          active: false
        },

        attachmentFormat: {
          value: '',
          active: false
        },

        attachmentDescription: {
          value: '',
          active: false
        },

        attachment: this.file
      },
    },
  };
  // Private
  private _unsubscribeAll: Subject<any>;

  public comments: any[] = [];
  public commentText: string = '';


  CountryFiscalOptions = [
    { value: 'IT', label: 'IT - Italia' },
    { value: 'DE', label: 'DE - Germania' },
    { value: 'FR', label: 'FR - Francia' },
    { value: 'ES', label: 'ES - Spagna' },
    { value: 'GB', label: 'GB - Gran Bretagna' },
    { value: 'PT', label: 'PT - Portogallo' },
    { value: 'BE', label: 'BE - Belgio' },
    { value: 'NL', label: 'NL - Olanda' },
    { value: 'LU', label: 'LU - Lussemburgo' },
    { value: 'AT', label: 'AT - Austria' },
    { value: 'DK', label: 'DK - Danimarca' },
    { value: 'FI', label: 'FI - Finlandia' },
    { value: 'SE', label: 'SE - Svezia' },
    { value: 'IE', label: 'IE - Irlanda' },
    { value: 'EL', label: 'EL - Grecia' },
    { value: 'CY', label: 'CY - Cipro' },
    { value: 'MT', label: 'MT - Malta' },
    { value: 'SI', label: 'SI - Slovenia' },
    { value: 'SK', label: 'SK - Slovacchia' },
    { value: 'EE', label: 'EE - Estonia' },
    { value: 'LV', label: 'LV - Lettonia' },
    { value: 'LT', label: 'LT - Lituania' },
    { value: 'PL', label: 'PL - Polonia' },
    { value: 'CZ', label: 'CZ - Repubblica Ceca' },
    { value: 'HU', label: 'HU - Ungheria' },
    { value: 'RO', label: 'RO - Romania' },
    { value: 'BG', label: 'BG - Bulgaria' },
    { value: 'HR', label: 'HR - Croazia' },
  ];

  RegimeFiscaleOptions = [
    { value: 'RF01', label: 'RF01 - Ordinario' },
    { value: 'RF02', label: 'RF02 - Contribuenti minimi (art. 1, c. 96-117, L. 244/2007)' },
    { value: 'RF04', label: 'RF04 - Agricoltura e attività connesse e pesca (artt. 34 e 34-bis, DPR 633/72)' },
    { value: 'RF05', label: 'RF05 - Vendita sali e tabacchi (art. 74, c. 1, DPR 633/72)' },
    { value: 'RF06', label: 'RF06 - Commercio fiammiferi (art. 74, c. 1, DPR 633/72)' },
    { value: 'RF07', label: 'RF07 - Editoria (art. 74, c. 1, DPR 633/72)' },
    { value: 'RF08', label: 'RF08 - Gestione servizi telefonia pubblica (art. 74, c. 1, DPR 633/72)' },
    { value: 'RF09', label: 'RF09 - Rivendita documenti di trasporto pubblico e di sosta (art. 74, c. 1, DPR 633/72)' },
    {
      value: 'RF10',
      label: 'RF10 - Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al DPR 640/72 (art. 74, c. 6, DPR 633/72)'
    },
    { value: 'RF11', label: 'RF11 - Agenzie viaggi e turismo (art. 74-ter, DPR 633/72)' },
    { value: 'RF12', label: 'RF12 - Agriturismo (art. 5, c. 2, L. 413/91)' },
    { value: 'RF13', label: 'RF13 - Vendite a domicilio (art. 25-bis, DPR 600/73)' },
    {
      value: 'RF14',
      label: 'RF14 - Rivendita beni usati, oggetti d’arte, d’antiquariato o da collezione (art. 36, DPR 633/72)'
    },
    {
      value: 'RF15',
      label: 'RF15 - Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art. 40-bis, DPR 633/72)'
    },
    { value: 'RF16', label: 'RF16 - IVA per cassa P.A. (art. 6, c. 5, DPR 633/72)' },
    { value: 'RF17', label: 'RF17 - IVA per cassa (art. 32-bis, DL 83/2012)' },
    { value: 'RF18', label: 'RF18 - Altro' },
    { value: 'RF19', label: 'RF19 - Regime forfettario (art. 1, c. 54-89, L. 190/2014)' },
  ];

  TipoCassaOptions = [
    { value: 'TC01', label: 'TC01 - Cassa nazionale previdenza e assistenza avvocati e procuratori legali' },
    { value: 'TC02', label: 'TC02 - Cassa previdenza dottori commercialisti' },
    { value: 'TC03', label: 'TC03 - Cassa previdenza e assistenza geometri' },
    {
      value: 'TC04',
      label: 'TC04 - Cassa nazionale previdenza e assistenza ingegneri e architetti liberi professionisti'
    },
    { value: 'TC05', label: 'TC05 - Cassa nazionale del notariato' },
    { value: 'TC06', label: 'TC06 - Cassa nazionale previdenza e assistenza ragionieri e periti commerciali' },
    { value: 'TC07', label: 'TC07 - Ente nazionale assistenza agenti e rappresentanti di commercio (ENASARCO)' },
    { value: 'TC08', label: 'TC08 - Ente nazionale previdenza e assistenza consulenti del lavoro (ENPACL)' },
    { value: 'TC09', label: 'TC09 - Ente nazionale previdenza e assistenza medici (ENPAM)' },
    { value: 'TC10', label: 'TC10 - Ente nazionale previdenza e assistenza farmacisti (ENPAF)' },
    { value: 'TC11', label: 'TC11 - Ente nazionale previdenza e assistenza veterinari (ENPAV)' },
    { value: 'TC12', label: 'TC12 - Ente nazionale previdenza e assistenza impiegati dell\'agricoltura (ENPAIA)' },
    { value: 'TC13', label: 'TC13 - Ente nazionale previdenza e assistenza professione infermieristica (ENPAPI)' },
    { value: 'TC14', label: 'TC14 - Istituto nazionale previdenza giornalisti italiani (INPGI)' },
    { value: 'TC15', label: 'TC15 - Opera nazionale assistenza orfani sanitari italiani (ONAOSI)' },
    { value: 'TC16', label: 'TC16 - Cassa autonoma assistenza integrativa giornalisti italiani (CASAGIT)' },
    { value: 'TC17', label: 'TC17 - Ente previdenza periti industriali e periti industriali laureati (EPPI)' },
    { value: 'TC18', label: 'TC18 - Ente previdenza ed assistenza pluricategoriale (EPAP)' },
    { value: 'TC19', label: 'TC19 - Ente nazionale previdenza e assistenza biologi (ENPAB)' },
    { value: 'TC20', label: 'TC20 - Ente nazionale previdenza e assistenza professione infermieristica (ENPAPI)' },
    { value: 'TC21', label: 'TC21 - Ente nazionale previdenza e assistenza psicologi (ENPAP)' },
    { value: 'TC22', label: 'TC22 - INPS' }
  ];

  modalitaPagamentoOptions = [
    { value: 'MP01', label: 'MP01 - Contanti' },
    { value: 'MP02', label: 'MP02 - Assegno' },
    { value: 'MP03', label: 'MP03 - Assegno circolare' },
    { value: 'MP04', label: 'MP04 - Contanti presso Tesoreria' },
    { value: 'MP05', label: 'MP05 - Bonifico' },
    { value: 'MP06', label: 'MP06 - Vaglia cambiario' },
    { value: 'MP07', label: 'MP07 - Bollettino bancario' },
    { value: 'MP08', label: 'MP08 - Carta di pagamento' },
    { value: 'MP09', label: 'MP09 - RID' },
    { value: 'MP10', label: 'MP10 - RID utenze' },
    { value: 'MP11', label: 'MP11 - RID veloce' },
    { value: 'MP12', label: 'MP12 - RIBA' },
    { value: 'MP13', label: 'MP13 - MAV' },
    { value: 'MP14', label: 'MP14 - Quietanza erario' },
    { value: 'MP15', label: 'MP15 - Giroconto su conti di contabilità speciale' },
    { value: 'MP16', label: 'MP16 - Domiciliazione bancaria' },
    { value: 'MP17', label: 'MP17 - Domiciliazione postale' },
    { value: 'MP18', label: 'MP18 - Bollettino di c/c postale' },
    { value: 'MP19', label: 'MP19 - SEPA Direct Debit' },
    { value: 'MP20', label: 'MP20 - SEPA Direct Debit CORE' },
    { value: 'MP21', label: 'MP21 - SEPA Direct Debit B2B' },
    { value: 'MP22', label: 'MP22 - Trattenuta su somme già riscosse' },
    { value: 'MP23', label: 'MP23 - PagoPA' },
  ];
  TipoDocumentoOptions = [
    { value: 'TD01', label: 'TD01 - Fattura' },
    { value: 'TD02', label: 'TD02 - Acconto/anticipo su fattura' },
    { value: 'TD03', label: 'TD03 - Acconto/anticipo su parcella' },
    { value: 'TD04', label: 'TD04 - Nota di credito' },
    { value: 'TD05', label: 'TD05 - Nota di debito' },
    { value: 'TD06', label: 'TD06 - Parcella' },
    { value: 'TD07', label: 'TD07 - Integrazione fattura reverse charge' },
    { value: 'TD08', label: 'TD08 - Autofattura' },
    { value: 'TD09', label: 'TD09 - Autotassazione' },
    { value: 'TD10', label: 'TD10 - Split payment' },
    { value: 'TD11', label: 'TD11 - Fattura differita' },
    { value: 'TD12', label: 'TD12 - Fattura differita reverse charge' },
    { value: 'TD13', label: 'TD13 - Nota di credito differita' },
    { value: 'TD14', label: 'TD14 - Nota di credito differita reverse charge' },
    { value: 'TD15', label: 'TD15 - Nota di debito differita' },
    { value: 'TD16', label: 'TD16 - Integrazione fattura reverse charge interno' },
    { value: 'TD17', label: 'TD17 - Integrazione/autofattura per acquisto servizi dall\'estero' },
    { value: 'TD18', label: 'TD18 - Integrazione per acquisto di beni intracomunitari' },
    { value: 'TD19', label: 'TD19 - Integrazione/autofattura per acquisto di beni ex art. 17 c. 2 DPR 633/72' },
    { value: 'TD20', label: 'TD20 - Autofattura per regolarizzazione e integrazione delle fatture' },
    { value: 'TD21', label: 'TD21 - Autofattura per splafonamento' },
    { value: 'TD22', label: 'TD22 - Estrazione beni da deposito IVA' },
    { value: 'TD23', label: 'TD23 - Estrazione beni da deposito IVA con versamento' },
    { value: 'TD24', label: 'TD24 - Estrazione beni da deposito IVA senza versamento' },
    { value: 'TD25', label: 'TD25 - Estrazione beni da deposito IVA con versamento e scissione pagamento' },
    { value: 'TD26', label: 'TD26 - Estrazione beni da deposito IVA senza versamento e scissione pagamento' },
    { value: 'TD27', label: 'TD27 - Versamento IVA scissione pagamento' },
    { value: 'TD28', label: 'TD28 - Fattura per autoconsumo' },
    { value: 'TD29', label: 'TD29 - Fattura per autoconsumo reverse charge' },
    { value: 'TD30', label: 'TD30 - Documento riepilogativo' },
    { value: 'TD31', label: 'TD31 - Fattura per reverse charge' },
    { value: 'TD32', label: 'TD32 - Fattura per autoconsumo per reverse charge' },
    { value: 'TD33', label: 'TD33 - Nota di credito per reverse charge' },
    { value: 'TD34', label: 'TD34 - Nota di credito per autoconsumo per reverse charge' },
    { value: 'TD35', label: 'TD35 - Nota di debito per reverse charge' },
    { value: 'TD36', label: 'TD36 - Nota di debito per acquisto di beni intracomunitari' },
    { value: 'TD37', label: 'TD37 - Fattura per acquisto di beni intracomunitari' },
    { value: 'TD38', label: 'TD38 - Registrazione per acquisto di servizi intracomunitari' },
    { value: 'TD39', label: 'TD39 - Fattura per acquisto di servizi intracomunitari' },
    { value: 'TD40', label: 'TD40 - Registrazione per acquisto di beni extracomunitari' },
    { value: 'TD41', label: 'TD41 - Fattura per acquisto di beni extracomunitari' },
  ];

  paymentConditionItems = [
    { label: '[TP01] Pagamento a rate', value: 'TP01' },
    { label: '[TP02] Pagamento completo', value: 'TP02' },
    { label: '[TP03] Anticipo', value: 'TP03' }
  ]

  naturaOptions = [
    { value: 'N1', label: 'N1 - Escluse ex art. 15' },
    { value: 'N2', label: 'N2 - Non soggette' },
    { value: 'N2.1', label: 'N2.1 - Non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72' },
    { value: 'N2.2', label: 'N2.2 - Non soggette - altri casi' },
    { value: 'N3', label: 'N3 - Non imponibili' },
    { value: 'N3.1', label: 'N3.1 - Non imponibili - esportazioni' },
    { value: 'N3.2', label: 'N3.2 - Non imponibili - cessioni intracomunitarie' },
    { value: 'N3.3', label: 'N3.3 - Non imponibili - cessioni verso San Marino' },
    { value: 'N3.4', label: 'N3.4 - Non imponibili - operazioni assimilate alle cessioni all\'esportazione' },
    { value: 'N3.5', label: 'N3.5 - Non imponibili - a seguito di dichiarazioni d\'intento' },
    { value: 'N3.6', label: 'N3.6 - Non imponibili - altre operazioni che non concorrono alla formazione del plafond' },
    { value: 'N4', label: 'N4 - Esenti' },
    { value: 'N5', label: 'N5 - Regime del margine / IVA non esposta in fattura' },
    { value: 'N6', label: 'N6 - Inversione contabile' },
    { value: 'N6.1', label: 'N6.1 - Inversione contabile - cessione di rottami e altri materiali di recupero' },
    { value: 'N6.2', label: 'N6.2 - Inversione contabile - cessione di oro e argento puro' },
    { value: 'N6.3', label: 'N6.3 - Inversione contabile - subappalto nel settore edile' },
    { value: 'N6.4', label: 'N6.4 - Inversione contabile - cessione di fabbricati' },
    { value: 'N6.5', label: 'N6.5 - Inversione contabile - cessione di telefoni cellulari' },
    { value: 'N6.6', label: 'N6.6 - Inversione contabile - cessione di prodotti elettronici' },
    { value: 'N6.7', label: 'N6.7 - Inversione contabile - prestazioni comparto edile e settori connessi' },
    { value: 'N6.8', label: 'N6.8 - Inversione contabile - operazioni settore energetico' },
    { value: 'N6.9', label: 'N6.9 - Inversione contabile - altri casi' },
    { value: 'N7', label: 'N7 - IVA assolta dall\'editore' },
    { value: 'N8', label: 'N8 - Operazioni non soggette' },
    { value: 'N9', label: 'N9 - Altro' },
  ];

  tipoRitenutaOptions = [
    { value: 'RT01', label: 'RT01 - Ritenuta persone fisiche' },
    { value: 'RT02', label: 'RT02 - Ritenuta persone giuridiche' },
    { value: 'RT03', label: 'RT03 - Contributo INPS' },
    { value: 'RT04', label: 'RT04 - Contributo ENASARCO' },
    { value: 'RT05', label: 'RT05 - Contributo ENPAM' },
    { value: 'RT06', label: 'RT06 - Altri contributi previdenziali' }
  ];

  divise = [
    {
      "value": "AED",
      "label": "AED"
    },
    {
      "value": "AFN",
      "label": "AFN"
    },
    {
      "value": "ALL",
      "label": "ALL"
    },
    {
      "value": "AMD",
      "label": "AMD"
    },
    {
      "value": "ANG",
      "label": "ANG"
    },
    {
      "value": "AOA",
      "label": "AOA"
    },
    {
      "value": "ARS",
      "label": "ARS"
    },
    {
      "value": "AUD",
      "label": "AUD"
    },
    {
      "value": "AWG",
      "label": "AWG"
    },
    {
      "value": "AZN",
      "label": "AZN"
    },
    {
      "value": "BAM",
      "label": "BAM"
    },
    {
      "value": "BBD",
      "label": "BBD"
    },
    {
      "value": "BDT",
      "label": "BDT"
    },
    {
      "value": "BGN",
      "label": "BGN"
    },
    {
      "value": "BHD",
      "label": "BHD"
    },
    {
      "value": "BIF",
      "label": "BIF"
    },
    {
      "value": "BMD",
      "label": "BMD"
    },
    {
      "value": "BND",
      "label": "BND"
    },
    {
      "value": "BOB",
      "label": "BOB"
    },
    {
      "value": "BOV",
      "label": "BOV"
    },
    {
      "value": "BRL",
      "label": "BRL"
    },
    {
      "value": "BSD",
      "label": "BSD"
    },
    {
      "value": "BTN",
      "label": "BTN"
    },
    {
      "value": "BWP",
      "label": "BWP"
    },
    {
      "value": "BYN",
      "label": "BYN"
    },
    {
      "value": "BZD",
      "label": "BZD"
    },
    {
      "value": "CAD",
      "label": "CAD"
    },
    {
      "value": "CDF",
      "label": "CDF"
    },
    {
      "value": "CHE",
      "label": "CHE"
    },
    {
      "value": "CHF",
      "label": "CHF"
    },
    {
      "value": "CHW",
      "label": "CHW"
    },
    {
      "value": "CLF",
      "label": "CLF"
    },
    {
      "value": "CLP",
      "label": "CLP"
    },
    {
      "value": "CNY",
      "label": "CNY"
    },
    {
      "value": "COP",
      "label": "COP"
    },
    {
      "value": "COU",
      "label": "COU"
    },
    {
      "value": "CRC",
      "label": "CRC"
    },
    {
      "value": "CUP",
      "label": "CUP"
    },
    {
      "value": "CVE",
      "label": "CVE"
    },
    {
      "value": "CZK",
      "label": "CZK"
    },
    {
      "value": "DJF",
      "label": "DJF"
    },
    {
      "value": "DKK",
      "label": "DKK"
    },
    {
      "value": "DOP",
      "label": "DOP"
    },
    {
      "value": "DZD",
      "label": "DZD"
    },
    {
      "value": "EGP",
      "label": "EGP"
    },
    {
      "value": "ERN",
      "label": "ERN"
    },
    {
      "value": "ETB",
      "label": "ETB"
    },
    {
      "value": "EUR",
      "label": "EUR"
    },
    {
      "value": "FJD",
      "label": "FJD"
    },
    {
      "value": "FKP",
      "label": "FKP"
    },
    {
      "value": "GBP",
      "label": "GBP"
    },
    {
      "value": "GEL",
      "label": "GEL"
    },
    {
      "value": "GHS",
      "label": "GHS"
    },
    {
      "value": "GIP",
      "label": "GIP"
    },
    {
      "value": "GMD",
      "label": "GMD"
    },
    {
      "value": "GNF",
      "label": "GNF"
    },
    {
      "value": "GTQ",
      "label": "GTQ"
    },
    {
      "value": "GYD",
      "label": "GYD"
    },
    {
      "value": "HKD",
      "label": "HKD"
    },
    {
      "value": "HNL",
      "label": "HNL"
    },
    {
      "value": "HTG",
      "label": "HTG"
    },
    {
      "value": "HUF",
      "label": "HUF"
    },
    {
      "value": "IDR",
      "label": "IDR"
    },
    {
      "value": "ILS",
      "label": "ILS"
    },
    {
      "value": "INR",
      "label": "INR"
    },
    {
      "value": "IQD",
      "label": "IQD"
    },
    {
      "value": "IRR",
      "label": "IRR"
    },
    {
      "value": "ISK",
      "label": "ISK"
    },
    {
      "value": "JMD",
      "label": "JMD"
    },
    {
      "value": "JOD",
      "label": "JOD"
    },
    {
      "value": "JPY",
      "label": "JPY"
    },
    {
      "value": "KES",
      "label": "KES"
    },
    {
      "value": "KGS",
      "label": "KGS"
    },
    {
      "value": "KHR",
      "label": "KHR"
    },
    {
      "value": "KMF",
      "label": "KMF"
    },
    {
      "value": "KPW",
      "label": "KPW"
    },
    {
      "value": "KRW",
      "label": "KRW"
    },
    {
      "value": "KWD",
      "label": "KWD"
    },
    {
      "value": "KYD",
      "label": "KYD"
    },
    {
      "value": "KZT",
      "label": "KZT"
    },
    {
      "value": "LAK",
      "label": "LAK"
    },
    {
      "value": "LBP",
      "label": "LBP"
    },
    {
      "value": "LKR",
      "label": "LKR"
    },
    {
      "value": "LRD",
      "label": "LRD"
    },
    {
      "value": "LSL",
      "label": "LSL"
    },
    {
      "value": "LYD",
      "label": "LYD"
    },
    {
      "value": "MAD",
      "label": "MAD"
    },
    {
      "value": "MDL",
      "label": "MDL"
    },
    {
      "value": "MGA",
      "label": "MGA"
    },
    {
      "value": "MKD",
      "label": "MKD"
    },
    {
      "value": "MMK",
      "label": "MMK"
    },
    {
      "value": "MNT",
      "label": "MNT"
    },
    {
      "value": "MOP",
      "label": "MOP"
    },
    {
      "value": "MRU",
      "label": "MRU"
    },
    {
      "value": "MUR",
      "label": "MUR"
    },
    {
      "value": "MVR",
      "label": "MVR"
    },
    {
      "value": "MWK",
      "label": "MWK"
    },
    {
      "value": "MXN",
      "label": "MXN"
    },
    {
      "value": "MXV",
      "label": "MXV"
    },
    {
      "value": "MYR",
      "label": "MYR"
    },
    {
      "value": "MZN",
      "label": "MZN"
    },
    {
      "value": "NAD",
      "label": "NAD"
    },
    {
      "value": "NGN",
      "label": "NGN"
    },
    {
      "value": "NIO",
      "label": "NIO"
    },
    {
      "value": "NOK",
      "label": "NOK"
    },
    {
      "value": "NPR",
      "label": "NPR"
    },
    {
      "value": "NZD",
      "label": "NZD"
    },
    {
      "value": "OMR",
      "label": "OMR"
    },
    {
      "value": "PAB",
      "label": "PAB"
    },
    {
      "value": "PEN",
      "label": "PEN"
    },
    {
      "value": "PGK",
      "label": "PGK"
    },
    {
      "value": "PHP",
      "label": "PHP"
    },
    {
      "value": "PKR",
      "label": "PKR"
    },
    {
      "value": "PLN",
      "label": "PLN"
    },
    {
      "value": "PYG",
      "label": "PYG"
    },
    {
      "value": "QAR",
      "label": "QAR"
    },
    {
      "value": "RON",
      "label": "RON"
    },
    {
      "value": "RSD",
      "label": "RSD"
    },
    {
      "value": "RUB",
      "label": "RUB"
    },
    {
      "value": "RWF",
      "label": "RWF"
    },
    {
      "value": "SAR",
      "label": "SAR"
    },
    {
      "value": "SBD",
      "label": "SBD"
    },
    {
      "value": "SCR",
      "label": "SCR"
    },
    {
      "value": "SDG",
      "label": "SDG"
    },
    {
      "value": "SEK",
      "label": "SEK"
    },
    {
      "value": "SGD",
      "label": "SGD"
    },
    {
      "value": "SHP",
      "label": "SHP"
    },
    {
      "value": "SLE",
      "label": "SLE"
    },
    {
      "value": "SLL",
      "label": "SLL"
    },
    {
      "value": "SOS",
      "label": "SOS"
    },
    {
      "value": "SRD",
      "label": "SRD"
    },
    {
      "value": "SSP",
      "label": "SSP"
    },
    {
      "value": "STN",
      "label": "STN"
    },
    {
      "value": "SVC",
      "label": "SVC"
    },
    {
      "value": "SYP",
      "label": "SYP"
    },
    {
      "value": "SZL",
      "label": "SZL"
    },
    {
      "value": "THB",
      "label": "THB"
    },
    {
      "value": "TJS",
      "label": "TJS"
    },
    {
      "value": "TMT",
      "label": "TMT"
    },
    {
      "value": "TND",
      "label": "TND"
    },
    {
      "value": "TOP",
      "label": "TOP"
    },
    {
      "value": "TRY",
      "label": "TRY"
    },
    {
      "value": "TTD",
      "label": "TTD"
    },
    {
      "value": "TWD",
      "label": "TWD"
    },
    {
      "value": "TZS",
      "label": "TZS"
    },
    {
      "value": "UAH",
      "label": "UAH"
    },
    {
      "value": "UGX",
      "label": "UGX"
    },
    {
      "value": "USD",
      "label": "USD"
    },
    {
      "value": "USN",
      "label": "USN"
    },
    {
      "value": "UYI",
      "label": "UYI"
    },
    {
      "value": "UYU",
      "label": "UYU"
    },
    {
      "value": "UYW",
      "label": "UYW"
    },
    {
      "value": "UZS",
      "label": "UZS"
    },
    {
      "value": "VED",
      "label": "VED"
    },
    {
      "value": "VES",
      "label": "VES"
    },
    {
      "value": "VND",
      "label": "VND"
    },
    {
      "value": "VUV",
      "label": "VUV"
    },
    {
      "value": "WST",
      "label": "WST"
    },
    {
      "value": "XAF",
      "label": "XAF"
    },
    {
      "value": "XAG",
      "label": "XAG"
    },
    {
      "value": "XAU",
      "label": "XAU"
    },
    {
      "value": "XBA",
      "label": "XBA"
    },
    {
      "value": "XBB",
      "label": "XBB"
    },
    {
      "value": "XBC",
      "label": "XBC"
    },
    {
      "value": "XBD",
      "label": "XBD"
    },
    {
      "value": "XCD",
      "label": "XCD"
    },
    {
      "value": "XDR",
      "label": "XDR"
    },
    {
      "value": "XOF",
      "label": "XOF"
    },
    {
      "value": "XPD",
      "label": "XPD"
    },
    {
      "value": "XPF",
      "label": "XPF"
    },
    {
      "value": "XPT",
      "label": "XPT"
    },
    {
      "value": "XSU",
      "label": "XSU"
    },
    {
      "value": "XTS",
      "label": "XTS"
    },
    {
      "value": "XUA",
      "label": "XUA"
    },
    {
      "value": "XXX",
      "label": "XXX"
    },
    {
      "value": "YER",
      "label": "YER"
    },
    {
      "value": "ZAR",
      "label": "ZAR"
    },
    {
      "value": "ZMW",
      "label": "ZMW"
    },
    {
      "value": "ZWL",
      "label": "ZWL"
    }
  ]
  public selectedLine: any;
  public selectedLineIndex: any;
  public isNewLine: boolean = false;
  public tmpSelectedLine: any;
  public tmpTransmitter: any
  public tmpInvoiceData: any;
  public tmpSellerProvider: any;
  public tmpAssigneeClient: any;
  public tmpThirdIntermediary: any;
  public tmptmpFiscalRepresentative: any;
  public tmpFR: any;
  public tmpIssuingSubject: any;
  public tmpPaymentConditions: any;
  public tmpPayment: any;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  protected selectedCondition: any;


  constructor(
    private _applicationService: ApplicationService,
    private _route: ActivatedRoute,
    private _shopService: ShopService,
    private _formBuilder: FormBuilder,
    private _customerService: CustomersService,
    private _commentService: CommentsService,
    private modalService: NgbModal,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-invoices-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loadComments();
    this._customerService.getBusinessList({  })
      .then((response) => {
        this.businesses = response.rows;
      })
      .catch((error) => {
        console.log('Businesses error', error);
      })
    console.log(this.newInvoice);
  }

  onChange(event: any) {
    this.file = event.target.files[0];
  }

  upload() {
    if (this.file) {
      // You can perform your upload logic here, for example, using HttpClient to send the file to a server.
      console.log('Uploading file:', this.file);
      // Reset file input after upload
      const fileInput = document.getElementById('fileInput') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
      this.file = null;
    } else {
      console.log('No file selected.');
    }
  }


  checkEmptyFields(data: any, errors: string[] = [], path = ''): string[] {
    if (typeof data === 'object') {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const newPath = path ? `${path}.${key}` : key;
          const fieldValue = data[key];

          if (typeof fieldValue === 'object' && fieldValue !== null && 'active' in fieldValue) {
            if (fieldValue.active && fieldValue.value === '') {
              errors.push(`Field '${newPath}' is active but empty.`);
            }
          } else {
            if (typeof fieldValue === 'object' && fieldValue !== null) {
              this.checkEmptyFields(fieldValue, errors, newPath);
            } else if (typeof fieldValue === 'string' && fieldValue === '') {
              errors.push(`Il campo '${newPath}' è vuoto.`);
            }
          }
        }
      }
    }

    return errors;
  }


  removeActiveFields(data: any): any {
    let tmpData = JSON.parse(JSON.stringify(data));
    if (typeof tmpData === 'object') {
      for (const key in tmpData) {
        if (tmpData.hasOwnProperty(key)) {
          const fieldValue = tmpData[key];
          if (typeof fieldValue === 'object' && fieldValue !== null && 'active' in fieldValue && Object.keys(fieldValue).length > 2) {
            if (!fieldValue.active) {
              delete tmpData[key];
            } else {
              delete fieldValue.active;
              tmpData[key] = this.removeActiveFields(fieldValue);
            }
          } else if (typeof fieldValue === 'object' && fieldValue !== null && 'active' in fieldValue && Object.keys(fieldValue).length === 2) {
            if (!fieldValue.active) {
              delete tmpData[key];
            } else {
              tmpData[key] = fieldValue.value;
            }
          } else if (typeof fieldValue === 'object' && fieldValue !== null) {
            tmpData[key] = this.removeActiveFields(fieldValue);
          }
        }
      }
    }
    console.log('tmpData', tmpData);
    return tmpData;
  }


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  makeTheInvoice() {
    const invoiceWithoutActiveFields = this.removeActiveFields(this.newInvoice);
    const errors = this.checkEmptyFields(invoiceWithoutActiveFields);
    console.log('removed active', invoiceWithoutActiveFields);
    if (errors.length > 0) {
      console.log(errors);
      Swal2.fire({
        title: 'Errore!',
        text: "Questi campi sono vuoti, compilali prima di procedere.",
        html: errors.map(error => `<p>${error}</p>`).join(''),
        icon: 'error',
        confirmButtonColor: '#ff0000',
        confirmButtonText: 'OK'
      });
    } else {
      console.log('no errors');
      Swal2.fire({
        title: 'Fattura creata!',
        text: 'La fattura è stata creata con successo.',
        icon: 'success',
        confirmButtonColor: '#7fad39',
        confirmButtonText: 'OK'
      });
    }
  }

  addComment() {
    let user = this.getLocalUser();
    if (this.commentText.trim()) {
      this._commentService.setComment('', this.commentText, user)
        .then((response: any) => {
          this.commentText = '';
          this.loadComments();
          Swal.fire({
            title: 'Conferma',
            text: 'Commento aggiunto con successo',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
        })
        .catch((error: any) => {
          console.error('Error fetching comments:', error);
          Swal.fire({
            title: 'Errore',
            text: 'Errore durante l\'invio del commento',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
    }
  }

  setLocalUser(name: string, avatar: string) {
    localStorage.setItem('comment-user-name', name);
    localStorage.setItem('comment-user-avatar', `assets/images/avatars/${avatar}.jpg`);
  }

  loadComments() {
    this._commentService.getCommentList('')
      .then((response: any) => {
        this.comments = response.rows;
      })
      .catch((error: any) => {
        console.error('Error fetching comments:', error);
      });
  }

  getLocalUser(): {
    name: string,
    avatar: string
  } {
    const userName = localStorage.getItem('comment-user-name') || "Cap Nemo";
    const userAvatar = localStorage.getItem('comment-user-avatar') || "assets/images/avatars/default-avatar.png";
    return {
      name: userName,
      avatar: userAvatar
    }
  }


  openNewPaymentModal(paymentCondition: any, newPaymentModal: TemplateRef<any>) {
    this.newPayment = {
      beneficiary: {
        value: '',
        active: false,
      },
      paymentMethod: '',
      paymentTermsReferenceDate: {
        value: '',
        active: false,
      },
      paymentTermsDays: {
        value: '',
        active: false,
      },
      paymentDueDate: {
        value: '',
        active: false,
      },
      paymentAmount: '',
      postOfficeCode: {
        value: '',
        active: false,
      },
      payerSurname: {
        value: '',
        active: false,
      },
      payerName: {
        value: '',
        active: false,
      },
      payerCF: {
        value: '',
        active: false,
      },
      payerTitle: {
        value: '',
        active: false,
      },
      financialInstitution: {
        value: '',
        active: false,
      },
      IBAN: {
        value: '',
        active: false,
      },
      ABI: {
        value: '',
        active: false,
      },
      CAB: {
        value: '',
        active: false,
      },
      BIC: {
        value: '',
        active: false,
      },
      earlyPaymentDiscount: {
        value: '',
        active: false,
      },
      earlyPaymentDeadline: {
        value: '',
        active: false,
      },
      latePaymentPenalty: {
        value: '',
        active: false,
      },
      penaltyStartDate: {
        value: '',
        active: false,
      },
      paymentCode: {
        value: '',
        active: false,
      }
    }
    this.selectedCondition = paymentCondition;
    this.modalService.open(newPaymentModal, {
      size: 'xl',
      windowClass: 'modal modal-primary'
    });
  }

  addNewPayment() {
    this.selectedCondition.payments.push(JSON.parse(JSON.stringify(this.newPayment)));
    this.modalService.dismissAll();
  }

  updateFiscalRepresentative() {
    this.newInvoice.header.assigneeClient.fiscalRepresentative = this.tmpFiscalRepresentative;
    this.newInvoice.header.fiscalRepresentative.fiscalRepresentativeId = this.tmpFiscalRepresentative.fiscalRepresentativeId;
    this.newInvoice.header.fiscalRepresentative.personalData.denominazione = this.tmpFiscalRepresentative.personalData.denominazione;
    this.newInvoice.header.fiscalRepresentative.personalData.name = this.tmpFiscalRepresentative.personalData.name;
    this.newInvoice.header.fiscalRepresentative.personalData.surname = this.tmpFiscalRepresentative.personalData.surname;
    console.log('tmp', this.tmpFiscalRepresentative);
    console.log('newInvoice asignee', this.newInvoice.header.assigneeClient.fiscalRepresentative);
    console.log('newInvoice fiscal', this.newInvoice.header.fiscalRepresentative);
  }

  addLineToLineDetails() {
    this.newInvoice.body.goodsServicesData.lineDetails.push({
      lineNumber: '',
      transferType: {
        value: '',
        active: false
      },
      articleCode: {
        active: false,
        type: '',
        value: '',
      },
      description: '',
      quantity: {
        value: 0,
        active: false
      },
      unitMeasure: {
        value: '',
        active: false
      },
      unitPrice: 0,
      periodStartDate: {
        value: '',
        active: false
      },
      periodEndDate: {
        value: '',
        active: false
      },
      discountIncrease: {
        active: false,
        discountType: '',
        discountPercentage: {
          value: 0,
          active: false
        },
        discountAmount: {
          value: 0,
          active: false
        },
      },
      totalPrice: 0,
      VATRate: 0,
      withholding: {
        value: '',
        active: false
      },
      nature: {
        value: '',
        active: false
      },
      administrativeReference: {
        value: '',
        active: false
      },
      managementData: {
        active: false,
        type: '',
        textReference: {
          value: '',
          active: false
        },
        numberReference: {
          value: '',
          active: false
        },
        dateReference: {
          value: '',
          active: false
        },
      }
    });
  }


  updateSummaryDetails() {
    let goodsServicesData = JSON.parse(JSON.stringify(this.newInvoice.body.goodsServicesData));
    const summaryMap = new Map<string, Set<string>>();
    for (const line of goodsServicesData.lineDetails) {
      const key = `${line.VATRate}-${line.nature.value}`;
      if (!summaryMap.has(key)) {
        summaryMap.set(key, new Set<string>());
      }
      summaryMap.get(key)?.add(line.lineNumber);
    }

    const summaryDetails: any[] = [];
    for (const [key, lines] of summaryMap.entries()) {
      const [VATRate, nature] = key.split('-');
      summaryDetails.push({
        VATRate,
        nature: { value: nature, active: true },
        additionalExpenses: {
          value: '',
          active: false
        },
        rounding: {
          value: '',
          active: false
        },
        taxableAmount: '',
        taxAmount: '',
        VATExigibility: {
          value: '',
          active: false
        },
        regulatoryReference: {
          value: '',
          active: false
        }
      });
    }


    console.log('summaryDetails', summaryDetails);
    this.newInvoice.body.goodsServicesData.summaryDetails = summaryDetails;
  }


  newLineModal(modal: TemplateRef<any>) {
    this.isNewLine = true;
    this.addLineToLineDetails();
    this.selectedLine = this.newInvoice.body.goodsServicesData.lineDetails[this.newInvoice.body.goodsServicesData.lineDetails.length - 1];
    this.selectedLine.lineNumber = this.newInvoice.body.goodsServicesData.lineDetails.length;
    this.selectedLineIndex = this.newInvoice.body.goodsServicesData.lineDetails.length;
    return this.modalService.open(modal, {
      size: 'lg',
      windowClass: 'modal modal-primary'
    });
  }


  protected readonly Number = Number;

  openEditLineModal(i: number, modal: TemplateRef<any>) {
    this.isNewLine = false;
    this.selectedLine = this.newInvoice.body.goodsServicesData.lineDetails[i];
    this.tmpSelectedLine = JSON.parse(JSON.stringify(this.selectedLine));
    this.selectedLineIndex = i;
    return this.modalService.open(modal, {
      size: 'lg',
      windowClass: 'modal modal-primary'
    });
  }

  closeLineModal() {
    if (this.isNewLine) {
      this.newInvoice.body.goodsServicesData.lineDetails.pop();
    } else {
      this.newInvoice.body.goodsServicesData.lineDetails[this.selectedLineIndex] = this.tmpSelectedLine;
    }
    this.modalService.dismissAll();
    console.log(this.newInvoice.body.goodsServicesData);
  }

  openTrasnittenteModal(modal: TemplateRef<any>) {
    this.tmpTransmitter = JSON.parse(JSON.stringify(this.newInvoice.header.transmitter));
    this.tmpInvoiceData = JSON.parse(JSON.stringify(this.newInvoice.body.invoiceData));
    return this.modalService.open(modal, {
      size: 'lg',
      windowClass: 'modal modal-primary'
    });
  }

  closeTransmitterModal() {
    this.newInvoice.header.transmitter = this.tmpTransmitter;
    this.newInvoice.body.invoiceData = this.tmpInvoiceData;
    this.modalService.dismissAll();
  }

  openSellerproviderModal(cedentePrestatoreModal: TemplateRef<any>, size: string = 'xl') {
    this.tmpSellerProvider = JSON.parse(JSON.stringify(this.newInvoice.header.sellerProvider));
    console.log(this.newInvoice)
    return this.modalService.open(cedentePrestatoreModal, {
      size: size,
      windowClass: 'modal modal-primary'
    });
  }

  closeSellerProviderModal() {
    this.newInvoice.header.sellerProvider = this.tmpSellerProvider;
    this.modalService.dismissAll();
  }

  openAssigneeClientModal(modal: TemplateRef<any>) {
    this.tmpAssigneeClient = JSON.parse(JSON.stringify(this.newInvoice.header.assigneeClient));
    this.tmptmpFiscalRepresentative = JSON.parse(JSON.stringify(this.tmpFiscalRepresentative));
    this.tmpFR = JSON.parse(JSON.stringify(this.newInvoice.header.fiscalRepresentative));
    return this.modalService.open(modal, {
      size: 'xl',
      windowClass: 'modal modal-primary'
    });
  }

  closeAssigneeClientModal() {
    this.newInvoice.header.assigneeClient = this.tmpAssigneeClient;
    this.newInvoice.header.fiscalRepresentative = this.tmpFR;
    this.tmpFiscalRepresentative = this.tmptmpFiscalRepresentative;
    this.newInvoice.header.fiscalRepresentative.fiscalRepresentativeId = this.newInvoice.header.assigneeClient.fiscalRepresentative.fiscalRepresentativeId;
    this.newInvoice.header.fiscalRepresentative.personalData.denominazione = this.newInvoice.header.assigneeClient.fiscalRepresentative.personalData.denominazione;
    this.newInvoice.header.fiscalRepresentative.personalData.name = this.newInvoice.header.assigneeClient.fiscalRepresentative.personalData.name;
    this.newInvoice.header.fiscalRepresentative.personalData.surname = this.newInvoice.header.assigneeClient.fiscalRepresentative.personalData.surname;
    this.modalService.dismissAll();
  }

  openThirdIntermediaryModal(modal: TemplateRef<any>) {
    this.newInvoice.header.thirdIntermediary.active = true;
    this.tmpThirdIntermediary = JSON.parse(JSON.stringify(this.newInvoice.header.thirdIntermediary));
    this.tmpIssuingSubject = JSON.parse(JSON.stringify(this.newInvoice.header.issuingSubject));
    return this.modalService.open(modal, {
      size: 'lg',
      windowClass: 'modal modal-primary'
    });
  }

  closeThirdIntermediaryModal() {
    this.newInvoice.header.thirdIntermediary = this.tmpThirdIntermediary;
    this.newInvoice.header.issuingSubject = this.tmpIssuingSubject;
    this.modalService.dismissAll();
  }

  openPaymentConditionModal(datiPagamentoModal: TemplateRef<any>) {
    this.tmpPaymentConditions = JSON.parse(JSON.stringify(this.newInvoice.body.paymentData));
    return this.modalService.open(datiPagamentoModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }


  openEditPaymentModal(paymentCondition: any, i: number, editpaymentmodal: TemplateRef<any>) {
    this.tmpPayment = paymentCondition.payments[i];
    this.newPayment = JSON.parse(JSON.stringify(paymentCondition.payments[i]));
    this.modalService.open(editpaymentmodal, {
      size: 'xl',
      windowClass: 'modal modal-primary'
    });
  }

  closeEditPaymentModal() {
    this.modalService.dismissAll();
  }

  saveEditPaymentModal() {
    this.selectedCondition.payments[this.selectedCondition.payments.indexOf(this.tmpPayment)] = this.newPayment;
    this.modalService.dismissAll();
  }

  cancelAddPayment() {
    this.modalService.dismissAll();
  }

  // a function that check all the fields of newInvoice which have the active and value as children, if the value was not empty, make the active true
  checkActiveFields(data: any): any {
    let tmpData = JSON.parse(JSON.stringify(data));
    if (typeof tmpData === 'object') {
      for (const key in tmpData) {
        if (tmpData.hasOwnProperty(key)) {
          const fieldValue = tmpData[key];
          if (typeof fieldValue === 'object' && fieldValue !== null && 'active' in fieldValue && 'value' in fieldValue) {
            if (fieldValue.value !== '') {
              fieldValue.active = true;
            }
          } else if (typeof fieldValue === 'object' && fieldValue !== null) {
            tmpData[key] = this.checkActiveFields(fieldValue);
          }
        }
      }
    }
    return tmpData;
  }

  removeLineFromLines(i: number) {
    Swal2.fire({
      title: 'Sei sicuro?',
      text: "Non potrai tornare indietro!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Si, elimina!',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.isConfirmed) {
        this.newInvoice.body.goodsServicesData.lineDetails.splice(i, 1);
        this.newInvoice.body.goodsServicesData.lineDetails.forEach((line, index) => {
          line.lineNumber = String(index + 1);
        });
        Swal2.fire(
          'Eliminato!',
          'La riga è stata eliminata.',
          'success'
        )
      }
    })

  }

  removePaymentFromPayments(i: number) {
    Swal2.fire({
      title: 'Sei sicuro?',
      text: "Non potrai tornare indietro!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Si, elimina!',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.isConfirmed) {
        this.selectedCondition.payments.splice(i, 1);
        Swal2.fire(
          'Eliminato!',
          'Il pagamento è stato eliminato.',
          'success'
        )
      }
    })
  }

  addNewPaymenCondition(datiPagamentoModal: TemplateRef<any>) {
    this.newInvoice.body.paymentData.payment.push({
      paymentCondition: '',
      payments: []
    })
    this.selectedCondition = this.newInvoice.body.paymentData.payment[this.newInvoice.body.paymentData.payment.length - 1];
    this.modalService.open(datiPagamentoModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  cancelAddpaymentCondition() {
    this.newInvoice.body.paymentData.payment.pop();
    this.modalService.dismissAll()
  }

  validateDates() {
    const startDate: NgbDate = this.selectedLine.periodStartDate.value;
    const endDate: NgbDate = this.selectedLine.periodEndDate.value;

    if (startDate && endDate) {
      const startTimestamp = new Date(startDate.year, startDate.month - 1, startDate.day).getTime();
      const endTimestamp = new Date(endDate.year, endDate.month - 1, endDate.day).getTime();

      if (endTimestamp < startTimestamp) {
        Swal.fire({
          icon: 'error',
          title: 'Intervallo di date non valido',
          text: 'La data di fine non può essere prima della data di inizio',
        });
        this.selectedLine.periodEndDate.value = startDate;
      } else if (startTimestamp < endTimestamp) {
        this.selectedLine.periodStartDate.value = endDate;
      }
    }
  }

  searchBusiness({value: searchInput}: any) {
    this._customerService.getBusinessList({ search: searchInput })
      .then((response) => {
        this.businesses = response.rows;
      })
      .catch((error) => {
        console.log('Businesses error', error);
      })
  }

  saveLineModal() {
    this.modalService.dismissAll();
    this.validateDates();
  }

  clearStartDate() {
    console.log('clearing start date', this.selectedLine.periodStartDate.value);
    this.selectedLine.periodStartDate.value = null;
    console.log(this.selectedLine.periodStartDate.value);
  }

  clearEndDate() {
    this.selectedLine.periodEndDate.value = null;
  }
}
