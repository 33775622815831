import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@core/components/core.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import { WarehousesViewComponent } from "./warehouses-view/warehouses-view.component";
import {NgbAlert} from "@ng-bootstrap/ng-bootstrap";

const routes: Routes = [
  {
    path: 'warehouses/:id',
    component: WarehousesViewComponent,
    data: { animation: 'WarehousesViewComponent' }
  }
];

@NgModule({
  declarations: [
    WarehousesViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    NgSelectModule,
    FormsModule,
    NgbAlert
  ],
  providers: []
})
export class WarehousesModule {
}
