import {Component, OnDestroy, OnInit, TemplateRef} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {CustomersService} from "../../../../@core/services/customers.service";
import {CatalogService} from "../../../../@core/services/catalog.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-cart-list',
  templateUrl: './carts-list.component.html',
  styleUrls: ['./carts-list.component.scss']
})

export class CartsListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;



  public tableConfig : TableConfig ={} as TableConfig;

  public newCart: any = {
    business: "",
    address: "",
    suppliers: [],
  }

  public businesses: any[] = [];

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _customerService: CustomersService,
    private _catalogService: CatalogService,
    private modalService: NgbModal,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-carts-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('carts')
      .then((table) => {
        this.tableConfig = table;
        this.generateBusinessList();
        this.generateFilterOptions();
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
          this.tableConfig.pagination.strictSearch = queryParams.strictSearch !== 'false';
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getCarts(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['business', 'suppliers.supplier'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const carts: DynamicData = await this._shopService.getCartList(query)
    carts.rows.forEach(cart => {
      cart.productsQuantity = 0;
      cart.total = 0;
      cart.suppliersQuantity = cart.suppliers.length;
      cart.suppliers.forEach((supplier: { products: string | any[]; }) => {
        cart.productsQuantity += supplier.products.length;
        if (typeof supplier.products !== "string") {
          supplier.products.forEach((product) => {
            cart.total += product.quantity * product.price;
          })
        }
      })
      cart.actions = cart.actions || [];
      cart.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/carts/' + cart.id
      });
    })
    return carts
  }

  updateBusinessFilter($event: any, filter: any) {
    this._customerService.getBusinessList({
      search: $event.target.value,
      pageSize: 25,
    }).then((response: DynamicData) => {
      filter.options = response.rows.map(business => {
        return {
          label: business.name,
          value: business.id
        }
      })
    })
  }

  generateFilterOptions() {
    this.tableConfig.filterGroups.forEach(filterGroup => {
      filterGroup.filters.forEach((filter: any) => {
        switch (filter.retriever) {
          case 'networks.list':
            this._customerService.getNetworkList({
              pageSize: 50,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(network => {
                return {
                  label: network.name,
                  value: network.id
                }
              })
            })
            break;
          case 'warehouses.list':
            this._catalogService.getWarehouseList({
              pageSize: 100,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(group => {
                return {
                  label: group.name,
                  value: group.id
                }
              })
            })
            break;
          case 'businesses.list':
            this._customerService.getBusinessList({
              pageSize: 50,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(business => {
                return {
                  label: business.name,
                  value: business.id
                }
              })
            })
            break;
        }
      })
    })

  }

  addCart(addNewCartModal: TemplateRef<any>) {
    return this.modalService.open(addNewCartModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  addNewCart() {
    this._shopService.addCart(this.newCart).then(() => {
      this.modalService.dismissAll();

    })
  }

  generateBusinessList() {
    this._customerService.getBusinessList({
      pageSize: 50,
    }).then((response: DynamicData) => {
      this.businesses = response.rows.map(business => {
        return {
          name: business.name,
          id: business.id,
          address: business.addresses[0]
        }
      })
    })
  }

  searchBusiness($event: KeyboardEvent) {
    this._customerService.getBusinessList({
      search: ($event.target as HTMLInputElement).value,
      pageSize: 50,
    }).then((response: DynamicData) => {
      this.businesses = response.rows.map(business => {
        return {
          name: business.name,
          id: business.id,
          address: business.addresses[0]
        }
      })
    })

  }

  selectBusiness($event: any) {
    this.newCart.business = $event.id;
    this.newCart.address = $event.address;
  }
}
