
<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Avvisi</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.alertList.add')" class="btn btn-primary px-1" (click)="addAlert(addNewAlertModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div><div class="glassy">
  <dynamic-table
    [dataRetriever]="getAlerts.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [filterGroups]="tableConfig.filterGroups"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>



<ng-template #addNewAlertModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="newOrder-modal">Aggiungi Avviso</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <label for="title">Titolo</label>
        <input type="text" class="form-control" id="title" [(ngModel)]="newAlertTitle" name="title">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Close click')">Chiudi</button>
    <button type="button" class="btn ml-1 btn-primary" (click)="addNewAlert()">Salva</button>
  </div>
</ng-template>
