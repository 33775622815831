<div class="filters divided" *ngIf="filterGroups.length">
  <!--  <h4>Filtri</h4>-->
  <div class="row px-1 pt-05 pb-1">
    <ng-container *ngFor="let filterGroup of filterGroups">
      <div [className]="filterGroup.class">
        <div class="row">
          <ng-container *ngFor="let filter of filterGroup.filters">
            <div [className]="filter.class">
              <fieldset class="form-group">
                <!-- Basic Multi select -->
                <div class="form-group">
                  <span>{{filter.label}}</span>
                </div>
                <ng-select
                  [items]="filter.options"
                  [multiple]="filter.multiple"
                  [closeOnSelect]="filter.closeOnSelect"
                  [searchable]="filter.searchable"
                  (change)="changeFilter(filter, $event)"
                  (close)="closeFilter()"
                  (clear)="closeFilter()"
                  (keyup)="keyUpFilter(filter, $event)"
                  placeholder="{{filter.placeholder}}"
                  [(ngModel)]="filter.value"
                >
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label"> {{ item.label }}</span>
                    <span class="ng-value-icon right" (click)="clear(item);closeFilter()" aria-hidden="true">×</span>
                  </ng-template>
                </ng-select>
                <!--/ Basic Multi select -->
              </fieldset>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="row">
  <div class="col-md-3 col-12">
    <div class="d-flex justify-content-start align-items-center p-1">
      <label class="d-flex align-items-center mb-0 mr-2">
        {{ messages.beforePageSizeMessage }}
        <select [(ngModel)]="pageSize" class="form-control mx-075" (change)="refresh()">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        {{ messages.afterPageSizeMessage }}
      </label>
    </div>
  </div>
  <div class="col-md-3 col-12">
    <div class="d-flex justify-content-start align-items-center p-1">
      <label class="d-flex align-items-center mb-0" *ngIf="autoReloadEnable">
        {{ messages.beforeAutoReloadMessage }}
        <select [(ngModel)]="autoReload" class="form-control mx-075" (change)="changeAutoreload()">
          <option value="0">0</option>
          <option value="5">3</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="60">60</option>
        </select>
        {{ messages.afterAutoReloadMessage }}
        <a class="ml-1" (click)="refresh()"><app-svg-icon name="refresh"></app-svg-icon></a>
      </label>
    </div>
  </div>
  <div class="col-md-6 col-12 d-flex justify-content-md-end">
    <div class="d-flex align-items-center justify-content-end p-1">
      <label class="d-flex align-items-center">
        <input
          (search)="textSearch()"
          (change)="textSearch()"
          class="form-control ml-025 search-box"
          placeholder="{{ messages.textSearchMessage }}"
          type="search"
          [(ngModel)]="search"
        />
      </label>
      <div class="custom-control custom-checkbox ml-075">
        <input checked class="custom-control-input" id="customCheck1"
               [(ngModel)]="strictSearch"
               (change)="refresh()"
               type="checkbox"/>
        <label class="custom-control-label" for="customCheck1">{{ messages.specificSearchMessage }}</label>
      </div>
    </div>
  </div>
</div>

<ngx-datatable
  (activate)="onActivate($event)"
  (select)="onSelect($event)"
  (page)="pageCallback($event)"
  (sort)="sortCallback($event)"
  [columnMode]=ColumnMode.force
  [count]="total"
  [externalPaging]="true"
  [externalSorting]="true"
  [footerHeight]="43"
  [headerHeight]="30"
  [limit]="pageSize"
  [offset]="page - 1"
  [columns]="columns"
  [loadingIndicator]="loading"
  [sorts]="[sort]"

  [messages]="messages"
  [rowHeight]="50"
  [rows]="rows"
  [selectionType]="SelectionType.checkbox"
  class="bootstrap core-bootstrap"
>
</ngx-datatable>

<ng-template
  #headerSelect
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-selectFn="selectFn"
  let-value="value"
>
  <div class="custom-control custom-checkbox">
    <input
      (change)="selectFn(!allRowsSelected)"
      [checked]="allRowsSelected"
      class="custom-control-input"
      id="headerChkbxRef"
      type="checkbox"
    />
    <label class="custom-control-label" for="headerChkbxRef"></label>
  </div>
</ng-template>

<ng-template
  #cellSelect
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-rowIndex="rowIndex"
  let-value="value"
>
  <div class="custom-control custom-checkbox">
    <input
      (change)="onCheckboxChangeFn($event)"
      [checked]="isSelected"
      class="custom-control-input"
      id="rowChkbxRef{{ rowIndex }}"
      type="checkbox"
    />
    <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
  </div>
</ng-template>


<ng-template
  #cellText
  let-value="value"
  let-row="row"
>
  <span class="fit-cell text-cell">
    {{ value }}
  </span>
</ng-template>

<ng-template
  #cellPrice
  let-value="value"
  let-row="row"
  let-column="column"
>
  <span class="fit-cell text-cell">
    {{ value | price:column.currency }}
  </span>
</ng-template>

<ng-template
  #cellBadge
  let-value="value"
  let-row="row"
  let-column="column"
>
  <span *ngIf="column.extra && column.extra[value]" class="badge badge-pill {{column.extra[value]?.class}}">
    {{ column.extra[value]?.label || '' }}
  </span>
  <span *ngIf="!column.extra || !column.extra[value]" class="badge badge-pill badge-light-danger">
    {{ value }}
  </span>
</ng-template>

<ng-template
  #cellPhoto
  let-value="value"
  let-row="row"
>
  <img
    *ngIf="value"
    alt="datatable-avatar"
    class="rounded-circle mr-1 dynamic-photo"
    height="32"
    [src]="value"
    width="32"
  />
  <img
    *ngIf="!value"
    alt="datatable-avatar"
    class="rounded-circle mr-1"
    height="32"
    width="32"
    src="assets/images/catalog/default-product-picture.png"
  />
</ng-template>

<ng-template
  #cellDate
  let-value="value"
  let-row="row"
>
  <span>{{value | date:"dd/MM/yyyy"}}</span>
</ng-template>

<ng-template
  #cellLink
  let-value="value"
  let-row="row"
  let-column="column"
>
  <a class="fit-cell" routerLink="{{row.links[column.link]}}">
    <app-svg-icon name="link"></app-svg-icon>
    {{value}}</a>
</ng-template>

<ng-template
  #cellLinkOut
  let-value="value"
  let-row="row"
  let-column="column"
>
  <button class=" btn fit-cell" (click)="externalLink(row.links[column.link])">
    <app-svg-icon name="link"></app-svg-icon>
    {{value}}</button>
</ng-template>

<ng-template
  #cellLinkEmail
  let-value="value"
  let-row="row"
>
  <a class="fit-cell" href="mailto:{{value}}" *ngIf="value">
    <app-svg-icon name="mail-card"></app-svg-icon>
    {{value}}</a>
</ng-template>

<ng-template
  #cellLinkPhone
  let-value="value"
  let-row="row"
>
  <a class="fit-cell" href="tel:{{value}}" *ngIf="value">
    <app-svg-icon name="phone"></app-svg-icon>
    {{value}}</a>
</ng-template>

<ng-template
  #cellPercentage
  let-value="value"
  let-row="row"
>
  <span class="fit-cell text-cell">
    {{ value }}%
  </span>
</ng-template>

<ng-template
  #cellDateTime
  let-value="value"
  let-row="row"
>
  <span>{{value | date:"dd/MM/yyyy HH:mm"}}</span>
</ng-template>

<ng-template
  #cellDateHumanized
  let-value="value"
  let-row="row"
>
  <span>{{value | dateHumanized}}</span>
</ng-template>

<ng-template
  #cellActions
  let-value="value"
  let-row="row"
>
  <div class="d-flex justify-content-center actions">
    <ng-container *ngFor="let action of value">
      <a
        *ngIf="action.type === 'link'"
        [routerLink]="action.link"
        class="action"
      >
        <app-svg-icon
          [name]="action.icon"
          class="icon"
        ></app-svg-icon>
      </a>
      <a
        *ngIf="action.type === 'out-link'"
        (click)="externalLink(action.link)"
        class="action"
      >
        <app-svg-icon
          [name]="action.icon"
          class="icon"
        ></app-svg-icon>
      </a>
      <button
        *ngIf="action.type === 'button'"
        (click)="action.action(row)"
        class="action"
      >
        <app-svg-icon
          [name]="action.icon"
          class="icon"
        ></app-svg-icon>
      </button>
    </ng-container>
  </div>
</ng-template>
