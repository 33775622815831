<div class="row" *ngIf="loaded | async">
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Metodo di pagamento {{ paymentMethod.code }}</h5>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Nome: </span>
          <input type="text" class="form-control" [(ngModel)]="paymentMethod.name"/>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Etichetta: </span>
          <input type="text" class="form-control" [(ngModel)]="paymentMethod.label"/>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Descrizione: </span>
          <input type="text" class="form-control" [(ngModel)]="paymentMethod.description"/>
        </p>
        <div class="row">
          <div class="col-6">
            <p class="card-text mb-05 mt-1">
              <input type="checkbox" class="form-check-input mr-05" [(ngModel)]="paymentMethod.default"/>
              <span class="card-text-title">Default </span>
            </p>
          </div>
          <div class="col-6">
            <p class="card-text mb-05 mt-1">
              <input type="checkbox" class="form-check-input mr-05" [(ngModel)]="paymentMethod.active"/>
              <span class="card-text-title">Attivo </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Più dati</h5>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Fatture: </span>
          <ng-select id="invoiceCodes" [items]="invoiceCodes" bindLabel="label" bindValue="value"
                     placeholder="Seleziona un codice fattura"
                     [(ngModel)]="paymentMethod.invoiceCode"
                     addTagText="Usa "
                     [addTag]="true"
          >
          </ng-select>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Driver: </span>
          <ng-select id="drivers" [items]="drivers" bindLabel="value"
                     placeholder="Seleziona un driver"
                     [(ngModel)]="paymentMethod.driver"
                     addTagText="Usa "
                     [addTag]="true"
          >
          </ng-select>
        </p>
        <div class="row mb-3">
          <div class="col-6 mt-1">
            <span>
              <input class="form-check-input" type="checkbox" [(ngModel)]="endOfMonthCheckbox"
                     (change)="toggleEndOfMonth()">
              Fine mese
            </span>
          </div>
          <div class="col-6 mt-1">
            <span>
              <input class="form-check-input custom-check-box" type="checkbox" [(ngModel)]="cumulativeDaysCheckbox"
                     (change)="toggleCumulativeDays()">
              Giorni cumulativi
            </span>
          </div>
          <div class="col-6 mt-1">
            <input type="number" class="form-control" [(ngModel)]="paymentMethod.endOfMonth"
                   [disabled]="!endOfMonthCheckbox">
          </div>
          <div class="col-6 mt-1">
            <input type="number" class="form-control " [(ngModel)]="paymentMethod.cumulativeDays"
                   [disabled]="!cumulativeDaysCheckbox">
          </div>
          <div class="col-6 mt-1">
            <p class="card-text mb-05"><span class="card-text-title">Min: </span>
              <input type="text" class="form-control" [(ngModel)]="paymentMethod.min"/>
            </p>
          </div>
          <div class="col-6 mt-1">
            <p class="card-text mb-05"><span class="card-text-title">Max: </span>
              <input type="text" class="form-control" [(ngModel)]="paymentMethod.max"/>
            </p>
          </div>
          <div class="col-6 mt-1">
            <p class="card-text mb-05"><span class="card-text-title">Spese di riscossione: </span>
              <input type="text" class="form-control" [(ngModel)]="paymentMethod.collectionExpenses"/>
            </p>
          </div>
          <div class="col-6 mt-1">
            <p class="card-text mb-05"><span class="card-text-title">Cashback(%): </span>
              <input type="text" class="form-control" [(ngModel)]="paymentMethod.cashback"/>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-4 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Rate</h5>
        <div class="alert alert-danger" *ngIf="feeSum !== 100">
          La somma delle rate non è 100%
        </div>
        <table class="table no-border">
          <thead>
          <tr class="no-border">
            <th class="custom-card-text-title no-border">Scadenza (Giorni)</th>
            <th class="custom-card-text-title no-border">Rata</th>
          </tr>
          </thead>
          <tbody class="no-border">
          <tr class="no-border" *ngFor="let installment of paymentMethod.installments; let i = index">
            <td class="no-border">
              <input type="text" class="form-control font-small-3 mb-1 cross-input d-inline-block"
                     [(ngModel)]="installment.expiration" (input)="recalculateFee()">
            </td>
            <td class="no-border">
              <input type="text" class="form-control font-small-3 mb-1 cross-input d-inline-block"
                     [(ngModel)]="installment.fee" (keyup)="recalculateFee()">
            </td>
            <td class="no-border">
              <button class="btn btn-danger d-inline-block p-05 pr-025" (click)="removeInstallment(i)">
                <app-svg-icon name="delete"></app-svg-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <div class="row">
          <button class="btn btn-primary btn-block col-12" (click)="addInstallment()">
            <app-svg-icon name="plus"></app-svg-icon>
            Aggiungi rata
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-2 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Azioni</h5>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-primary btn-block" (click)="savePaymentMethod()">
            <app-svg-icon name="save"></app-svg-icon>
            Salva
          </button>
        </div>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-danger btn-block" (click)="deletePaymentMethod()">
            <app-svg-icon name="delete"></app-svg-icon>
            Elimina
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
