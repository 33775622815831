import {Component, OnDestroy, OnInit, TemplateRef} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-payment-methods-list',
  templateUrl: './payment-methods-list.component.html',
  styleUrls: ['./payment-methods-list.component.scss']
})

export class PaymentMethodsListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public newPaymentMethod: any = {
    code: "",
    name: "",
    label: "",
    description: "",
    default: false,
    driver: "",
    invoiceCode: "",
    endOfMonth: 0,
    cumulativeDays: 0,
    collectionExpenses: 0,
    installments: [
      {
        fee: 100,
        expiration: 0
      }
    ],
    active: true,
    min: 0,
    max: 0,
    isStrict: true,
    mandatoryFields: [],
    image: "",
    errorMessages: {},
    cashback: 0
  }
  public feeSum = 0;
  public endOfMonthCheckbox: boolean = false;
  public cumulativeDaysCheckbox: boolean = false;
  public invoiceCodes: any[] = [
    {
      label: 'Contanti (MP01)',
      value: 'MP01'
    },
    {
      label: 'Assegno (MP02)',
      value: 'MP02'
    },
    {
      label: 'Assegno circolare (MP03)',
      value: 'MP03'
    },
    {
      label: 'Contanti presso Tesoreria (MP04)',
      value: 'MP04'
    },
    {
      label: 'Bonifico (MP05)',
      value: 'MP05'
    },
    {
      label: 'Vaglia cambiario (MP06)',
      value: 'MP06'
    },
    {
      label: 'Bollettino bancario (MP07)',
      value: 'MP07'
    },
    {
      label: 'Carta di credito (MP08)',
      value: 'MP08'
    },
    {
      label: 'RID (MP09)',
      value: 'MP09'
    },
    {
      label: 'RID utenze (MP10)',
      value: 'MP10'
    },
    {
      label: 'RID veloce (MP11)',
      value: 'MP11'
    },
    {
      label: 'Bonifico (MP12)',
      value: 'MP12'
    },
    {
      label: 'Riba (MP13)',
      value: 'MP13'
    },
    {
      label: 'Quietanza erario stato (MP14)',
      value: 'MP14'
    },
    {
      label: 'Giroconto su conti di contabilità speciale (MP15)',
      value: 'MP15'
    },
    {
      label: 'Domiciliazione bancaria (MP16)',
      value: 'MP16'
    },
    {
      label: 'Domiciliazione postale (MP17)',
      value: 'MP17'
    },
  ];

  public drivers: any[] = [
    'CASHBACK',
    'DEBT',
    'KLARNA',
    'MYBANK',
    'PAUSEPAY',
    'RIBAAUTO180',
    'STRIPE',
  ];


  public tableConfig: TableConfig = {} as TableConfig;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-payment-methods-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('payment-methods')
      .then((table) => {
        this.tableConfig = table;
        this.recalculateFee();
        this.tableConfig.filterGroups[0].filters[0].options = [
          {
            label: 'CASHBACK',
            value: 'CASHBACK'
          },
          {
            label: 'DEBT',
            value: 'DEBT'
          },
          {
            label: 'KLARNA',
            value: 'KLARNA'
          },
          {
            label: 'MYBANK',
            value: 'MYBANK'
          },
          {
            label: 'PAUSEPAY',
            value: 'PAUSEPAY'
          },
          {
            label: 'RIBAAUTO180',
            value: 'RIBAAUTO180'
          },
          {
            label: 'STRIPE',
            value: 'STRIPE'
          },
        ]
        this.tableConfig.filterGroups[0].filters[1].options = [
          {
            label: 'Contanti (MP01)',
            value: 'MP01'
          },
          {
            label: 'Assegno (MP02)',
            value: 'MP02'
          },
          {
            label: 'Assegno circolare (MP03)',
            value: 'MP03'
          },
          {
            label: 'Contanti presso Tesoreria (MP04)',
            value: 'MP04'
          },
          {
            label: 'Bonifico (MP05)',
            value: 'MP05'
          },
          {
            label: 'Vaglia cambiario (MP06)',
            value: 'MP06'
          },
          {
            label: 'Bollettino bancario (MP07)',
            value: 'MP07'
          },
          {
            label: 'Carta di credito (MP08)',
            value: 'MP08'
          },
          {
            label: 'RID (MP09)',
            value: 'MP09'
          },
          {
            label: 'RID utenze (MP10)',
            value: 'MP10'
          },
          {
            label: 'RID veloce (MP11)',
            value: 'MP11'
          },
          {
            label: 'Bonifico (MP12)',
            value: 'MP12'
          },
          {
            label: 'Riba (MP13)',
            value: 'MP13'
          },
          {
            label: 'Quietanza erario stato (MP14)',
            value: 'MP14'
          },
          {
            label: 'Giroconto su conti di contabilità speciale (MP15)',
            value: 'MP15'
          },
          {
            label: 'Domiciliazione bancaria (MP16)',
            value: 'MP16'
          },
          {
            label: 'Domiciliazione postale (MP17)',
            value: 'MP17'
          },
        ]
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
          this.tableConfig.pagination.strictSearch = queryParams.strictSearch !== 'false';
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }
      })

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getPaymentMethods(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['network', 'group'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const paymentMethods: DynamicData = await this._shopService.getPaymentMethodList(query)
    paymentMethods.rows.forEach(paymentMethod => {
      if (paymentMethod.isVisible) {
        paymentMethod.isVisible = 'Visibile'
      }
      else {
        paymentMethod.isVisible = 'Non visibile'
      }
      if (paymentMethod.active) {
        paymentMethod.active = 'Attivo'
      } else {
        paymentMethod.active = 'Non attivo'
      }
      if (paymentMethod.endOfMonth < 0) {
        paymentMethod.endOfMonth = 'No'
      }
      if (paymentMethod.cumulativeDays < 0) {
        paymentMethod.cumulativeDays = 'No'
      } else {
        paymentMethod.cumulativeDays++;
      }
      paymentMethod.actions = paymentMethod.actions || [];
      paymentMethod.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/payment-methods/' + paymentMethod.id
      });
    })
    return paymentMethods
  }

  addPaymentMethod(addNewPaymentMethodModal: TemplateRef<any>) {
    return this.modalService.open(addNewPaymentMethodModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  addNewPaymentMethod() {
    if (this.feeSum !== 100) {
      Swal.fire({
        title: 'Errore!',
        text: "La somma delle rate deve essere uguale a 100!",
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      return;
    } else {
      this._shopService.addPaymentMethod(this.newPaymentMethod)
        .then((response) => {
          this._router.navigate(['/payment-methods']);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  toggleEndOfMonth() {
    if (this.endOfMonthCheckbox) {
      this.newPaymentMethod.endOfMonth = 0;
    } else {
      this.newPaymentMethod.endOfMonth = -1;
    }
  }

  toggleCumulativeDays() {
    if (this.cumulativeDaysCheckbox) {
      this.newPaymentMethod.cumulativeDays = 0;
    } else {
      this.newPaymentMethod.cumulativeDays = -1;
    }
  }

  addInstallment() {
    this.newPaymentMethod.installments.push({fee: null, days: null});
  }

  recalculateFee() {
    this.feeSum = 0;
    for (var i = 0; i < this.newPaymentMethod.installments.length; i++) {
      this.feeSum += this.newPaymentMethod.installments[i].fee;
    }
  }
}
