
<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Banner</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.bannerList.add')" class="btn btn-primary px-1" (click)="addBanner(addNewBannerModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div><div class="glassy">
  <dynamic-table
    [dataRetriever]="getBanners.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [filterGroups]="tableConfig.filterGroups"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>


<ng-template
  #bannersCell
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-rowIndex="rowIndex"
  let-banners="value"
>
    <ng-container *ngFor="let banner of banners">
      <img
        *ngIf="banner"
        alt="datatable-avatar"
        class="rounded-circle ml-1 dynamic-photo mr-025 object-fit-md-contain"
        height="32"
        [src]="banner.src"
        width="32"
      />
        <a *ngIf="banner.link"
          (click)="externalLink(banner.link)"
          class="action mr-1"
        >
          <app-svg-icon
            name="link"
            class="icon"
          ></app-svg-icon>
          <span class="sr-only">Link</span>
        </a>
    </ng-container>
</ng-template>




<ng-template #addNewBannerModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="newOrder-modal">Aggiungi Banner</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <label>Ordine:</label>
        <input type="number" class="form-control" [(ngModel)]="newBanner.order" />
      </div>
      <div class="col-6">
        <label>Dimensione:</label>
        <input type="number" class="form-control" [(ngModel)]="newBanner.size" />
      </div>
      <div class="col-12 mt-1">
        <label>Network:</label>
       <ng-select
         [items]="networks"
         bindLabel="name"
         bindValue="id"
         [(ngModel)]="newBanner.network"
        ></ng-select>
      </div>
      <ng-container *ngFor="let picture of newBanner.pictures; let i = index">
        <div class="col-12 mt-1">
          <label>Immagine {{ i + 1 }} src:</label>
          <input type="text" class="form-control" [(ngModel)]="newBanner.pictures[i].src" />
        </div>
        <div class="col-12">
          <label>Immagine {{ i + 1 }} link:</label>
          <input type="text" class="form-control" [(ngModel)]="newBanner.pictures[i].link" />
        </div>
        <div class="col-12">
          <img *ngIf="picture.src" [src]="picture.src" class="img-fluid mt-1" />
        </div>
      </ng-container>
      <div class="col-12 mt-1">
        <button class="btn btn-primary" (click)="addPicture()">Aggiungi Immagine</button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Close click')">Chiudi</button>
    <button type="button" class="btn ml-1 btn-primary" (click)="addNewBanner()">Salva</button>
  </div>
</ng-template>
