import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { BehaviorSubject, Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class IconsService {
  private _behaviors: {
    [key: string]: BehaviorSubject<any>
  } = {};

  constructor(
    private _http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {
  }

  getIcon(name: string): Observable<any> {
    if (!this._behaviors[name]) {
      this._behaviors[name] = new BehaviorSubject(null);
      this._http.get(`/assets/images/icons/${name}.svg`, { responseType: 'text' })
        .subscribe(value => {
          this._behaviors[name].next(this.sanitizer.bypassSecurityTrustHtml(value));
        });
    }
    return this._behaviors[name].asObservable();
  }
}
