import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {DynamicData} from "../types/dynamic-table/dynamic-data";
import {ListParams} from "../types/api/list-params";

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  // -----------------------------------------------------------------------------------------------------
  // @ public
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ private
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getOrderList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('orders', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getCartList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('carts', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleCartFromId(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('carts', 'view', {id})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getPaymentList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('payments', 'getPayments', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getReturnList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('returns', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }


  getInvoiceList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('invoices', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getQuoteList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('quotes', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getPaymentMethodList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      console.log('getPaymentMethodList', queryParams);
      this._apiService.make('paymentMethods', 'get', {}, options)
        .then((response) => {
          console.log('getPaymentMethodList response', response);
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  viewPaymentMethod(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('paymentMethods', 'view', {id})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getBannerList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('banners', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getAgreementList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('agreements', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getInvoiceFromCode(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('invoices', 'getInvoiceByCode', {code})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  updateInvoice(code: string, currentInvoice: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('invoices', 'updateInvoice', {code}, {body: {invoice: currentInvoice}})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSinglePaymentFromId(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('payments', 'view', {id})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('getSinglePaymentFromId', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  savePayment(id: string, payment: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('payments', 'save', {id}, {body: {payment}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('savePayment', response, payment);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  saveCart(id: string, cart: any) {
    console.log('saveCart', id, cart);
    return new Promise((resolve, reject) => {
      this._apiService.make('carts', 'save', {id}, {body: {cart}})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deleteCart(currentId: string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('carts', 'delete', {id: currentId})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deletePaymentMethod(currentId: string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('paymentMethods', 'delete', {id: currentId})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  savePaymentMethod(id: string, paymentMethod: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('paymentMethods', 'save', {id}, {body: {paymentMethod}})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getDiscountList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('discount', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleDiscountFromId(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('discount', 'view', {id})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  saveDiscount(id: string, discount: any) {
    console.log('saveDiscount', id, discount);
    return new Promise((resolve, reject) => {
      this._apiService.make('discount', 'save', {id}, {body: {discount}})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deleteDiscount(id: string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('discount', 'delete', {id})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }


  async getDeliveryList(qqueryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (qqueryParams) {
        options['params'] = qqueryParams
      }
      this._apiService.make('deliveries', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleDeliveryFromId(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('deliveries', 'view', {id})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('getSingleDeliveryFromId', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  addCart(cart: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('carts', 'newCart', {}, {body: {cart: cart}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('addCart', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  createDiscount(newDiscount: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('discount', 'newDiscount', {}, {body: {discount: newDiscount}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('createDiscount', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  addPaymentMethod(newPaymentMethod: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('paymentMethods', 'newPaymentMethod', {}, {body: {paymentMethod: newPaymentMethod}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('addPaymentMethod', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getPrimeList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('primes', 'getPrimes', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSubscriptionList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('subscriptions', 'getSubscriptions', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  addPrime(newPrime: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('primes', 'newPrime', {}, {body: {plan: newPrime}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('addPrime', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSinglePrimeFromId(currentId: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('primes', 'view', {id: currentId})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('getSinglePrimeFromId', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  savePrime(prime: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('primes', 'save', {id: prime.id}, {body: {plan: prime}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('savePrime', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deletePrime(prime: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('primes', 'delete', {id: prime.id})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('deletePrime', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  newSubscription(subscription: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('subscriptions', 'newSubscription', {}, {body: {subscription}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('newSubscription', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  newBanner(banner: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('banners', 'newBanner', {}, {body: {banner: banner}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('newBanner', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getBanner(currentId: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('banners', 'view', {id: currentId})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('getBanner', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  updateBanner(banner: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('banners', 'edit', {id: banner.id}, {body: {banner}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('updateBanner', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deleteBanner(id: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('banners', 'delete', {id})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('deleteBanner', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  uploadBannerImage(file: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('banners', 'upload', {}, {body: {file}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('uploadImage', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  async getWalletTypes(queryParams?: ListParams): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('wallets', 'getWalletTypes', {}, {params: queryParams})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('getWalletTypes', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  async getWallets(queryParams?: ListParams): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('wallets', 'getWallets', {}, {params: queryParams})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('getWallets', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  async getAlertsList(query: any): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      this._apiService.make('alerts', 'get', {}, {params: query})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getAlert(currentId: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('alerts', 'view', {id: currentId})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('getAlert', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  updateAlert(alert: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('alerts', 'edit', {id: alert.id}, {body: {alert}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('updateAlert', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deleteAlert(id: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('alerts', 'delete', {id})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('deleteAlert', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  uploadAlertImage(file: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('alerts', 'upload', {}, {body: {file}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('uploadImage', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  async getBusinessPaymentList(businessId: string, query: any): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      console.log('getBusinessPaymentList', businessId, query);
      this._apiService.make('businesses', 'getPayments', {businessId: businessId}, {params: query})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  newAlert(newAlert: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('alerts', 'newAlert', {}, {body: {alert: newAlert}})
        .then((response) => {
          if (!response) {
            return
          }
          console.log('newAlert', response);
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }
}
