import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CategoriesListComponent } from "./categories-list/categories-list.component";
import { CoreModule } from "@core/components/core.module";
import { CategoryViewComponent } from "./category-view/category-view.component";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

const routes: Routes = [
  {
    path: 'categories',
    component: CategoriesListComponent,
    data: { animation: 'CategoriesListComponent' }
  },
  {
    path: 'categories/:code',
    component: CategoryViewComponent,
    data: { animation: 'CategoryViewComponent' }
  },
];

@NgModule({
  declarations: [
    CategoriesListComponent,
    CategoryViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    FormsModule,
    NgSelectModule,
    NgbTooltip
  ],
  providers: []
})
export class CategoriesModule {
}
