import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { DynamicData } from "../types/dynamic-table/dynamic-data";
import { ListParams } from "../types/api/list-params";
import { Group } from "../types/data/group";

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  // -----------------------------------------------------------------------------------------------------
  // @ public
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ private
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getRegionList(queryParams?: ListParams): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('system', 'regionList', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }
}
