import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CustomersService} from "../../../../@core/services/customers.service";

@Component({
  selector: 'app-subscriptions-list',
  templateUrl: './subscriptions-list.component.html',
  styleUrls: ['./subscriptions-list.component.scss']
})

export class SubscriptionListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  public businesses: any[] = [];
  public plans: any[] = [];

  selectedClient: any;
  selectedPlan: any;

  @ViewChild("rewardsCell", {static: true})
  private rewardBadgesTemplate?: TemplateRef<any>;

  public tableConfig: TableConfig = {} as TableConfig;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dynamicTableService: DynamicTableService,
    public _authService: AuthenticationService,
    public modalService: NgbModal,
    public _customerService: CustomersService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('marketing-subscription-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('subscriptions')
      .then((table) => {
        this.tableConfig = table;
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
          this.tableConfig.pagination.strictSearch = queryParams.strictSearch !== 'false';
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }
        const rewardBadgesColumn = this.tableConfig.columns.find(column => column.prop === 'rewardBadges');
        if (rewardBadgesColumn) {
          rewardBadgesColumn.cellTemplate = this.rewardBadgesTemplate;
        }
      })
    this._customerService.getBusinessesList()
      .then((businesses) => {
        this.businesses = businesses.rows;
        this.businesses.forEach(business => {
          this.tableConfig.filterGroups[0].filters[0].options.push({
            label: business.name,
            value: business._id
          })
        })
      })
      .catch((error: any) => {
      });

    this._shopService.getPrimeList()
      .then((plans) => {
        this.plans = plans.rows;
        this.plans.forEach(plan => {
          console.log('Plan', plan);
          this.tableConfig.filterGroups[0].filters[0].options.push({
            label: plan.code,
            value: plan.id
          })
        })
      })
      .catch((error: any) => {
      });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  async getSubscription(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['business', 'plan'];
    console.log("query : ", query)
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const subscriptions: DynamicData = await this._shopService.getSubscriptionList(query)
    subscriptions.rows.forEach(subscription => {
      subscription.actions = subscription.actions || [];
      subscription.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/subscriptions/' + subscription.id
      });
    })
    subscriptions.rows.forEach(subscription => {
      subscription.rewardBadges = subscription.rewardBadges || [];
      subscription.rewards.forEach((reward: { type: string; amount: any; }) => {
        if (reward.type === 'Discount') {
          subscription.rewardBadges.push({
            type: reward.type,
            label: 'Sconto',
            class: 'badge-light-success',
            tooltip: reward.amount
          });
        } else if (reward.type === 'FreeDelivery') {
          subscription.rewardBadges.push({
            type: reward.type,
            label: 'Consegna Gratuita',
            class: 'badge-light-info',
            tooltip: reward.amount
          });
        } else {
          subscription.rewardBadges.push({
            type: reward.type,
            label: reward.type,
            class: 'badge-light-warning',
            tooltip: reward.amount
          });
        }
      })
    });
    console.log("subscriptions : ", subscriptions)
    return subscriptions
  }

  addNewSubscriptionModal(newSubscriptionModal: TemplateRef<any>) {
    this.modalService.open(newSubscriptionModal, {centered: true});
  }


  selectClient($event: any) {
    this.selectedClient = $event;
  }

  selectPlan($event: any) {
    this.selectedPlan = $event;
  }

  searchBusiness(event: any) {
    const search = event.target.value;
    if (search.length == 0) {
      this.businesses = [];
      return;
    } else {
      this._customerService.getBusinessList({search: search})
        .then((response) => {
          console.log('Businesses', response);
          this.businesses = response.rows;
        })
        .catch((error) => {
          console.log('Businesses error', error);
        })
    }
  }

  searchPlans(event: any) {
    const search = event.target.value;
    if (search.length == 0) {
      this.plans = [];
      return;
    } else {
      this._shopService.getPrimeList({search: search})
        .then((response) => {
          console.log('Plans', response);
          this.plans = response.rows;
        })
        .catch((error) => {
          console.log('Plans error', error);
        })
    }
  }


  gift() {
    if (this.selectedClient && this.selectedPlan) {
      console.log('Gift to', this.selectedClient, 'Plan', this.selectedPlan);
      const newSubscription = {
        business: this.selectedClient.id,
        plan: this.selectedPlan.id,
        rewards: []
      }
      this._shopService.newSubscription(newSubscription).then((response) => {
        if (!response) {
          return
        }
      })
      console.log('New Subscription', newSubscription);
    }
  }
}
