<div class="full-width d-flex justify-content-between">
  <div class="name">
    <h1 class="font-medium-5 px-025 my-1">Gruppi</h1>
  </div>
  <div class="actions">
    <button *ngIf="_authService.checkPermission('admin.page.groupsList.add')" class="btn btn-primary px-1 mb-1" (click)="addGroup(addNewGroupModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>

<div class="glassy pt-05">
  <dynamic-table
    [dataRetriever]="getGroups.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>


<ng-template #addNewGroupModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="newOrder-modal">Aggiungi nuovo gruppo</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Nome</h5>
        <input type="text" class="form-control mb-1" [(ngModel)]="newGroup.name">
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Codice</h5>
        <input type="text" class="form-control mb-1" [(ngModel)]="newGroup.code">
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Descrizione</h5>
        <textarea class="form-control mb-1" [(ngModel)]="newGroup.description"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addNewGroup()">Aggiungi</button>
  </div>
</ng-template>
