import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@core/components/core.module";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import { ApplicationsListComponent } from "./applications-list/applications-list.component";
import { ApplicationsViewComponent } from "./applications-view/applications-view.component";

const routes: Routes = [
  {
    path: 'applications',
    component: ApplicationsListComponent,
    data: { animation: 'ApplicationsListComponent' }
  },
  {
    path: 'applications/:id',
    component: ApplicationsViewComponent,
    data: { animation: 'ApplicationsViewComponent' }
  }
];

@NgModule({
  declarations: [
    ApplicationsListComponent,
    ApplicationsViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    FormsModule,
    NgSelectModule
  ],
  providers: []
})
export class ApplicationsModule {
}
