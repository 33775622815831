import { Theme, ThemeAnimation, ThemeSkin } from "@core/types/app/theme";

export const current: Theme = {
  app: {
    name: "logo",
    title: "Tulero",
    logo: {
      light: "assets/images/logo/logo-light.png",
      dark: "assets/images/logo/logo-dark.png",
      lightSquare: "assets/images/logo/logo-light-square.png",
      darkSquare: "assets/images/logo/logo-dark-square.png",
    },
    icon: {
      light: "assets/images/logo/icon-light.svg",
      dark: "assets/images/logo/icon-dark.svg",
    },
    language: "it",
  },
  layout: {
    skin: ThemeSkin.LIGHT,
    animation: ThemeAnimation.ZOOM_IN,
    header: {
      hidden: false,
    },
    sidebar: {
      hidden: false,
    },
    footer: {
      hidden: false,
    },
  },
  colors: {
    primary: {
      '0': '#FF8300',
      '100': '#FF8300',
      '200': '#FF8300',
      '300': '#FF8300',
      '400': '#FF8300',
      '500': '#FF8300',
      '600': '#FF8300',
      '700': '#FF8300',
      '800': '#FF8300',
      '900': '#FF8300',
    },
    secondary: {
      '0': '#490E67',
      '100': '#490E67',
      '200': '#490E67',
      '300': '#490E67',
      '400': '#490E67',
      '500': '#490E67',
      '600': '#490E67',
      '700': '#490E67',
      '800': '#490E67',
      '900': '#490E67',
    },
    success: {
      '0': '#00A300',
      '100': '#00A300',
      '200': '#00A300',
      '300': '#00A300',
      '400': '#00A300',
      '500': '#00A300',
      '600': '#00A300',
      '700': '#00A300',
      '800': '#00A300',
      '900': '#00A300',
    },
    // info is close to blue
    info: {
      '0': '#00A3FF',
      '100': '#00A3FF',
      '200': '#00A3FF',
      '300': '#00A3FF',
      '400': '#00A3FF',
      '500': '#00A3FF',
      '600': '#00A3FF',
      '700': '#00A3FF',
      '800': '#00A3FF',
      '900': '#00A3FF',
    },
    warning: {
      '0': '#FF8300',
      '100': '#FF8300',
      '200': '#FF8300',
      '300': '#FF8300',
      '400': '#FF8300',
      '500': '#FF8300',
      '600': '#FF8300',
      '700': '#FF8300',
      '800': '#FF8300',
      '900': '#FF8300',
    },
    danger: {
      '0': '#ff4400',
      '100': '#ff4400',
      '200': '#ff4400',
      '300': '#ff4400',
      '400': '#ff4400',
      '500': '#ff4400',
      '600': '#ff4400',
      '700': '#ff4400',
      '800': '#ff4400',
      '900': '#ff4400',
    },
  }
}
