import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { QuotesListComponent } from "./quotes-list/quotes-list.component";
import { QuoteViewComponent } from "./quote-view/quote-view.component";
import { CoreModule } from "@core/components/core.module";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

const routes: Routes = [
  {
    path: 'quotes',
    component: QuotesListComponent,
    data: { animation: 'QuotesListComponent' }
  },
  {
    path: 'quotes/:code',
    component: QuoteViewComponent,
    data: { animation: 'OrdersViewComponent' }
  },
];

@NgModule({
  declarations: [
    QuotesListComponent,
    QuoteViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    NgbTooltip
  ],
  providers: []
})
export class QuotesModule {
}
