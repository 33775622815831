import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApplicationService } from "@core/services/application.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CustomersService } from "@core/services/customers.service";
import { DynamicData } from "@core/types/dynamic-table/dynamic-data";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-network-list',
  templateUrl: './networks-list.component.html',
  styleUrls: ['./networks-list.component.scss']
})

export class NetworksListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  @ViewChild("originsCell", {static: true})
  private originsCell?: TemplateRef<any>;



  public tableConfig : TableConfig ={} as TableConfig;

  public newNetwork = {
    code: "",
    name: "",
    description: "",
    logo: "",
    origin: "",
    menu : [],
    suppliers: [],
    __v: 0
  };


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('customers-networks-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('networks')
      .then((table) => {
        this.tableConfig = table;
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
          this.tableConfig.pagination.strictSearch = queryParams.strictSearch !== 'false';
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getNetworks(query?: any) {
    query = query || {};
    const queryParams: Params = { ...query };
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['network', 'group'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const networks:DynamicData = await this._customerService.getNetworkList(query)
    networks.rows.forEach(network => {
      network.actions = network.actions || [];
      network.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/networks/' + network.code
      });
    })
    this.tableConfig.columns.forEach(column => {
      if (column.prop === 'origin') {
        column.cellTemplate = this.originsCell;
      }
    });
    return networks
  }

  addNetwork(addNewNetworkModal: TemplateRef<any>) {
    return this.modalService.open(addNewNetworkModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  addNewNetwork() {
    this._customerService.newNetwork(this.newNetwork).then(() => {
      this.modalService.dismissAll();
      this.getNetworks();
    });
  }
}
