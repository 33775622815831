<div class="card glassy position-relative">
  <div *ngIf="isLoading" class="loading full-width full-height position-absolute py-1 px-2 d-flex align-items-center">
    <div class="progress full-width" role="progressbar" aria-label="Animated striped example" aria-valuenow="100"
         aria-valuemin="0" aria-valuemax="100">
      <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%"></div>
    </div>
  </div>
  <div class="card-header d-flex justify-content-between">
    <h3 class="card-title mb-1">Panoramica</h3>
    <div class="d-flex justify-content-end">
      <div class="group-by mr-1" *ngIf="pagePermissions.group_by">
        <ng-select
          class="group-by-selector d-inline-block"
          [items]="groupByOptions"
          [closeOnSelect]="true"
          [searchable]="false"
          (change)="getChartData()"
          placeholder="Raggruppa"
          [(ngModel)]="selectedGroupBy"
        >
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <div class="">
              <span class="ng-value-label">
                {{ item }}
              </span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="status-selector mr-1" *ngIf="pagePermissions.states">
        <ng-select
          class="status-selector d-inline-block"
          [items]="statuses"
          [multiple]="true"
          [closeOnSelect]="false"
          [searchable]="true"
          (change)="changeStatuses($event)"
          placeholder="Tutti gli stati"
          [(ngModel)]="selectedStatuses"
        >
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label"> {{ item.label }}</span>
            <span class="ng-value-icon right" (click)="clear(item);" aria-hidden="true">×</span>
          </ng-template>
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="text-black" *ngIf="items.length > 1">
              <span class="ng-value-label">{{ items.length }} Stati</span>
            </div>
            <div class="ng-value" *ngIf="items.length == 1">
              <div class="ng-value" *ngFor="let item of items">
              <span class="ng-value-label">
                {{ item.label }}
              </span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="network-selector mr-1" *ngIf="pagePermissions.networks">
        <ng-select
          class="networks-selector d-inline-block"
          [items]="networks"
          [multiple]="true"
          [closeOnSelect]="false"
          [searchable]="true"
          (change)="changeNetworks($event)"
          placeholder="Tutti i network"
          [(ngModel)]="selectedNetworks"
        >
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label"> {{ item.name }}</span>
            <span class="ng-value-icon right" (click)="clear(item);" aria-hidden="true">×</span>
          </ng-template>
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="text-black" *ngIf="items.length > 1">
              <span class="ng-value-label">{{ items.length }} Network</span>
            </div>
            <div *ngIf="items.length == 1">
              <div class="ng-value" *ngFor="let item of items">
              <span class="ng-value-label">
                {{ item.name }}
              </span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="warehouse-selector mr-1" *ngIf="pagePermissions.warehouses">
        <ng-select
          class="warehouses-selector d-inline-block"
          [items]="warehouses"
          [multiple]="true"
          [closeOnSelect]="false"
          [searchable]="true"
          (change)="changeWarehouses($event)"
          placeholder="Tutti i magazzini"
          [(ngModel)]="selectedWarehouses"
        >
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label"> {{ item.name }}</span>
            <span class="ng-value-icon right" (click)="clear(item);" aria-hidden="true">×</span>
          </ng-template>
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="text-black" *ngIf="items.length > 1">
              <span class="ng-value-label">{{ items.length }} Magazzini</span>
            </div>
            <div *ngIf="items.length == 1">
              <div class="ng-value" *ngFor="let item of items">
              <span class="ng-value-label">
                {{ item.name }}
              </span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="time-selector-container" #timeSelector>
        <div class="btn btn-outline-primary btn-sm" (click)="openTimeSelector($event)">
          <ng-container *ngIf="fromDateString === toDateString">
            <div class="font-small-2">
              <span class="align-middle"> {{ fromDateString }} </span>
              <app-svg-icon name="calendar" class="align-middle ml-05"></app-svg-icon>
            </div>
          </ng-container>
          <ng-container *ngIf="fromDateString !== toDateString">
            <div class="font-small-2">
              <span class="align-middle"> {{ fromDateString }} - {{ toDateString }} </span>
              <app-svg-icon name="calendar" class="align-middle ml-05"></app-svg-icon>
            </div>
          </ng-container>
        </div>
        <div class="time-selector" [class.active]="selectTime">
          <ngb-datepicker #d (dateSelect)="onDateSelection($event)"
                          [displayMonths]="2"
                          [dayTemplate]="t"
                          [maxDate]="maxDate"
                          outsideDays="hidden"/>

          <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body pt-0">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <div class="stats-widget">
          <div class="stats-widget-title font-medium-2 font-weight-soft-bold">Totale ordini</div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_orders !== undefined">{{ stats.total_orders }}
          </div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold" *ngIf="stats.total_orders === undefined">
            --
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <div class="stats-widget">
          <div class="stats-widget-title font-medium-2 font-weight-soft-bold">Totale Ordini</div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_products !== undefined && stats.total_delivery_prices !== undefined">{{ stats.total_products + stats.total_delivery_prices | price }}
          </div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_products === undefined || stats.total_delivery_prices === undefined">--
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <div class="stats-widget">
          <div class="stats-widget-title font-medium-2 font-weight-soft-bold">Ordine Medio</div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_products !== undefined && stats.total_delivery_prices !== undefined && (stats.total_orders !== undefined && stats.total_orders !== 0)">{{ (stats.total_products + stats.total_delivery_prices) / stats.total_orders | price }}
          </div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_products === undefined || stats.total_delivery_prices === undefined || (stats.total_orders === undefined && stats.total_orders !== 0)">
            --
          </div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold" *ngIf="stats.total_orders === 0">0</div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <div class="stats-widget">
          <div class="stats-widget-title font-medium-2 font-weight-soft-bold">Clienti</div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_businesses !== undefined">{{ stats.total_businesses }}
          </div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_businesses === undefined">--
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <div class="stats-widget">
          <div class="stats-widget-title font-medium-2 font-weight-soft-bold">Magazzini</div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_warehouses !== undefined">{{ stats.total_warehouses }}
          </div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_warehouses === undefined">--
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <div class="stats-widget">
          <div class="stats-widget-title font-medium-2 font-weight-soft-bold">Network</div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_networks !== undefined">{{ stats.total_networks }}
          </div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold"
               *ngIf="stats.total_networks === undefined">--
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="chart-container">
          <div id="last-orders" style="width: 100%; height: 250px"></div>
        </div>
      </div>
      <!--      <div class="col-2">-->
      <!--        <div class="row">-->
      <!--          <div class="col-12">-->
      <!--            <div class="stats-widget">-->
      <!--              <div class="stats-widget-title">Totale ordini</div>-->
      <!--              <div class="stats-widget-value">35</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <div class="stats-widget">-->
      <!--              <div class="stats-widget-title">Totale incasso</div>-->
      <!--              <div class="stats-widget-value">€ 3.028,20</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <div class="stats-widget">-->
      <!--              <div class="stats-widget-title">Ordine Medio</div>-->
      <!--              <div class="stats-widget-value">€ 86,52</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</div>
