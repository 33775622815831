
<h1 class="font-medium-5 px-025 my-1">Pagamenti</h1>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getPayments.bind(this)"
    [filterGroups]="tableConfig.filterGroups"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
    [autoReloadEnable]="true"
  >
  </dynamic-table>
</div>

