import { Component, ViewEncapsulation } from '@angular/core';
import { fadeInLeft, zoomIn, fadeIn } from '@core/animations/core.animation';
import { RouterOutlet } from "@angular/router";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInLeft, zoomIn, fadeIn]
})
export class ContentComponent {
  public animate = 'zoomIn';


  /**
   * Zoom In Animation
   *
   * @param outlet
   */
  zoomIn(outlet: RouterOutlet) {
    if (this.animate === 'zoomIn') {
      return outlet.activatedRouteData['animation'];
    }
    return null;
  }

  /**
   * Fade In Left Animation
   *
   * @param outlet
   */
  fadeInLeft(outlet: RouterOutlet) {
    if (this.animate === 'fadeInLeft') {
      return outlet.activatedRouteData['animation'];
    }
    return null;
  }

  /**
   * Fade In Animation
   *
   * @param outlet
   */
  fadeIn(outlet: RouterOutlet) {
    if (this.animate === 'fadeIn') {
      return outlet.activatedRouteData['animation'];
    }
    return null;
  }
}
