import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {PingService} from "../../services/ping.service";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  headerHidden: boolean = false;
  sidebarHidden: boolean = false;
  footerHidden: boolean = false;

  public isUserAuthenticated: boolean = false;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private pingService: PingService,
    private _applicationService: ApplicationService,
    private _authenticationService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._applicationService.headerHidden.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((headerHidden: boolean) => {
        this.headerHidden = headerHidden;
      });
    this._applicationService.sidebarHidden.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((sidebarHidden: boolean) => {
        this.sidebarHidden = sidebarHidden;
      });
    this._applicationService.footerHidden.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((footerHidden: boolean) => {
        this.footerHidden = footerHidden;
      });
    // this._authenticationService.isAuthenticated().then((response: any) => {
    //   this.isUserAuthenticated = true;
    // }).catch((error: any) => {
    //   this.isUserAuthenticated = false;
    // });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
