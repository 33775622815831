import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@core/components/core.module";
import { DeliveriesListComponent } from "./deliveries-list/deliveries-list.component";
import {NgbInputDatepicker, NgbProgressbar, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
const routes: Routes = [
  {
    path: 'deliveries',
    component: DeliveriesListComponent,
    data: { animation: 'DeliveriesListComponent' }
  }
];

@NgModule({
  declarations: [
    DeliveriesListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    NgbProgressbar,
    FormsModule,
    NgSelectModule,
    NgbInputDatepicker
  ],
  providers: []
})
export class DeliveriesModule {
}
