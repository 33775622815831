import {Component, OnDestroy, OnInit, TemplateRef} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {CatalogService} from "@core/services/catalog.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DynamicData} from "../../../../@core/types/dynamic-table/dynamic-data";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-product-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})

export class ProductsListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  public brands: any[] = [];
  public categories: any[] = [];
  public newProduct: any = {
    code: "",
    name: "",
    description: "",
    brand: "",
    category: "",
    pictures: [],
    meta: [],
    family: "",
    info: {
      kromeda: {
        id_product: "",
        id_brand: ""
      }
    },
    suppliers: [],
    cross: []
  }

  public tableConfig: TableConfig = {} as TableConfig;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _catalogService: CatalogService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('catalog-products-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('products')
      .then((table) => {
        this.tableConfig = table;
        this.generateBrandList();
        this.generateCategoryList();
        // get current query params
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }

        this.tableConfig.filterGroups.forEach(filterGroup => {
          filterGroup.filters.forEach((filter: any) => {
            switch (filter.retriever) {
              case 'brands.list':
                this._catalogService.getBrandList({
                  pageSize: 1000,
                }).then((brandsResponse: DynamicData) => {
                  filter.options = brandsResponse.rows.map(brand => {
                    return {
                      label: brand.name,
                      value: brand.id
                    }
                  })
                })
                break;
              case 'categories.list':
                this._catalogService.getCategoryList({
                  pageSize: 1000,
                }).then((categoriesResponse: DynamicData) => {
                  filter.options = categoriesResponse.rows.map(category => {
                    return {
                      label: category.name,
                      value: category.id
                    }
                  })
                })
                break;
              case 'suppliers.list':
                this._catalogService.getSupplierList({
                  pageSize: 1000,
                }).then((suppliersResponse: DynamicData) => {
                  filter.options = suppliersResponse.rows.map(supplier => {
                    return {
                      label: supplier.name,
                      value: supplier.id
                    }
                  })
                })
                break;
            }
          })
        })
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getProducts(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['category', 'brand'];
    const products: DynamicData = await this._catalogService.getProductList(query)

    products.rows.forEach(product => {
      product.actions = product.actions || [];
      product.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/products/' + product.id
      });
    })
    return products;
  }

  addProduct(addNewProductModal: TemplateRef<any>) {
    return this.modalService.open(addNewProductModal, {
      size: 'lg',
      windowClass: 'modal modal-primary'
    });
  }

  generateBrandList() {
    this._catalogService.getBrandList({
      pageSize: 10000,
    }).then((response: DynamicData) => {
      this.brands = response.rows.map(brand => {
        return {
          label: brand.label,
          id: brand.id,
          code: brand.code,
          logo: brand.logo,
          name: brand.name,
          info: {
            kromeda: {
              code: brand.info.kromeda.code,
              type: brand.info.kromeda.type,
            }
          }
        }
      })
    });
  }

  generateCategoryList() {
    this._catalogService.getCategoryList({
      pageSize: 10000,
    }).then((response: DynamicData) => {
      this.categories = response.rows.map(category => {
        const newCategory = {
          label: category.name,
          id: category.id,
          code: category.code,
          name: category.name,
          parent: category.parent,
          priceRules: category.priceRules,
          createdAt: category.createdAt,
          updatedAt: category.updatedAt,
        }
        return newCategory;
      });
    });
  }

  onBrandInput($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const value = inputElement.value;
    this.newProduct.brand = value;
  }

  onCategoryInput($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const value = inputElement.value;
    this.newProduct.category = value;
  }

  addNewProduct() {
    this._catalogService.newProduct(this.newProduct)
      .then((response: any) => {
        this.modalService.dismissAll();
      })
  }
}
