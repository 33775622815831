import {ApiConfiguration} from "@core/types/api/api-configuration";
import {EndpointMethods} from "@core/types/api/endpoint-methods";

export const environment: {
    production: boolean;
    useLocalStorage: boolean;
    hmr: boolean;
    apis: {
      [key: string]: ApiConfiguration;
    };
  } = {
    production: false,
    hmr: false,
    useLocalStorage: true,
    apis: {
      application: {
        baseUrl: 'https://api.tulero.it/api/admin/application',
        devUrl: "https://backend.legacy.dipanode.co6.magma.consulting/api/admin/application",
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          theme: {
            method: EndpointMethods.GET,
            url: "/theme",
            fakeSource: "theme.current"
          },
          menu: {
            method: EndpointMethods.GET,
            url: "/menu",
            fakeSource: "menu.current"
          },

        }
      },
      applications: {
        baseUrl: 'https://api.tulero.it/api/admin/applications',
        devUrl: "https://backend.legacy.dipanode.co6.magma.consulting/api/admin/applications",
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "applications.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "applications.view"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "applications.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "applications.viewFromCode.{code}"
          },
          newApplication: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "applications.new"
          },
          delete: {
            method: EndpointMethods.DELETE,
            url: "/{id}",
            fakeSource: "applications.delete.{id}"
          },
        }
      },
      auth: {
        baseUrl: 'https://api.tulero.it/api/admin/account',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/account',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          account: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "account.current"
          },
          signin: {
            method: EndpointMethods.POST,
            url: "/signin",
            fakeSource: "account.signin"
          },
          signout: {
            method: EndpointMethods.POST,
            url: "/signout",
            fakeSource: "account.signout"
          },
          getIdentities: {
            method: EndpointMethods.GET,
            url: "/identities",
            fakeSource: "account.identities.list"
          },
          setIdentity: {
            method: EndpointMethods.POST,
            url: "/identity",
            fakeSource: "account.identities.list"
          },
        }
      },
      dynamicTable: {
        baseUrl: 'https://api.tulero.it/api/admin/dynamicTables',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/dynamicTables',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          getDynamicTable: {
            method: EndpointMethods.GET,
            url: "/{name}",
            fakeSource: "dynamicTable.{name}"
          },
          getDynamicTableList: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "dynamicTable.list"
          },
          getDynamicTablePermissionsList: {
            method: EndpointMethods.GET,
            url: "/permissions",
            fakeSource: "dynamicTable.list"
          },
          getAllDynamicTableList: {
            method: EndpointMethods.GET,
            url: "/dynamics",
            fakeSource: "dynamicTable.list"
          },
          updateDynamicTable: {
            method: EndpointMethods.PUT,
            url: "/{name}",
            fakeSource: "dynamicTable.{name}"
          },
          deleteDynamicTable: {
            method: EndpointMethods.DELETE,
            url: "/{name}",
            fakeSource: "dynamicTable.{name}"
          },
        }
      },
      mail: {
        baseUrl: 'https://api.tulero.it/api/admin/mails',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/mails',
        isDev: true,
        isFake: false,
        useCredentials: true,
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "mails.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "mails.view"
          },
        }
      },
      menu: {
        baseUrl: 'https://api.tulero.it/api/users/menu',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/users/menu',
        isDev: true,
        isFake: true,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          getMenu: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "menu.current"
          }
        }

      },
      menuItems: {
        baseUrl: 'https://api.tulero.it/api/admin/menuItems',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/menuItems',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "menuItems.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "menuItems.view"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "menuItems.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "menuItems.viewFromCode.{code}"
          },
          newMenuItem: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "menuItems.new"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "menuItems.delete.{id}"
          },
        }
      },
      identities: {
        baseUrl: 'https://api.tulero.it/api/app/identities',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/app/identities',
        isDev: true,
        isFake: false,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          newIdentity: {
            method: EndpointMethods.POST,
            url: "/",
            fakeSource: "identities.new"
          },
          getToken: {
            method: EndpointMethods.GET,
            url: "/token/{identity}",
            fakeSource: "identities.getToken"
          },
          loginIdentity: {
            method: EndpointMethods.POST,
            url: "/login/identity",
            fakeSource: "identities.login"
          },
          getIdentities: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "identities.list"
          },
          getIdentity: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "identities.view"
          },
          updateIdentity: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "identities.edit.{id}"
          },
        }
      },
      users: {
        baseUrl: 'https://api.tulero.it/api/admin/users',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/users',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "users.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "users.view"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "users.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "users.viewFromCode.{code}"
          },
          getIdentities: {
            method: EndpointMethods.GET,
            url: "/{id}/identities",
            fakeSource: "users.businesses"
          },
          changePassword: {
            method: EndpointMethods.POST,
            url: "/{id}/password",
            fakeSource: "users.changePassword"
          },
          setBusinessToUser: {
            method: EndpointMethods.POST,
            url: "/{user_id}/businesses/permit",
            fakeSource: "businesses.setBusinessToUser"
          },
          revokeBusiness: {
            method: EndpointMethods.POST,
            url: "/{id}/businesses/revoke",
            fakeSource: "businesses.setBusinessToUser"
          },
          switchIdentity: {
            method: EndpointMethods.POST,
            url: "/{identity_id}/switch",
            fakeSource: "users.switchIdentity"
          },
          newUser: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "users.new"
          },
        }
      },
      permissions: {
        baseUrl: 'https://api.tulero.it/api/admin/permissions2',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/permissions2',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: '',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "/permissions",
            fakeSource: "permissions.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "permissions.view"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "permissions.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "permissions.viewFromCode.{code}"
          },
          newPermission: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "permissions.new"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "permissions.delete.{id}"
          },
        }
      },
      sessions: {
        baseUrl: 'https://api.tulero.it/api/admin/sessions',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/sessions',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "sessions.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "sessions.view"
          },
          edit: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "sessions.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "sessions.viewFromCode.{code}"
          },
        }
      },
      networks: {
        baseUrl: 'https://api.tulero.it/api/admin/networks',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/networks',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "networks.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{code}",
            fakeSource: "networks.view"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{code}",
            fakeSource: "networks.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "networks.viewFromCode.{code}"
          },
          newNetwork: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "networks.new"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}/{code}",
          },
        }
      },
      charts: {
        baseUrl: 'https://api.tulero.it/api/admin/charts',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/charts',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          lastOrders: {
            method: EndpointMethods.POST,
            url: "/orders",
            fakeSource: "lastOrders"
          },
          kromedaProfile: {
            method: EndpointMethods.GET,
            url: "/kromeda-profile",
            fakeSource: "kromedaProfile"
          },
        }
      },
      groups: {
        baseUrl: 'https://api.tulero.it/api/admin/groups',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/groups',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "groups.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "groups.view"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "groups.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "groups.viewFromCode.{code}"
          },
          newGroup: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "groups.new"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "groups.delete.{id}"
          },
        }
      },
      banners: {
        baseUrl: 'https://api.tulero.it/api/admin/banners',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/banners',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "banners.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "banners.view"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "banners.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "banners.viewFromCode.{code}"
          },
          newBanner: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "banners.new"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "banners.delete.{id}"
          },
        }
      },
      agreements: {
        baseUrl: 'https://api.tulero.it/api/admin/agreements',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/agreements',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "agreements.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "agreements.view"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "agreements.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "agreements.viewFromCode.{code}"
          },
          newAgreement: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "agreements.new"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "agreements.delete.{id}"
          },
        }
      },

      alerts: {
        baseUrl: 'https://api.tulero.it/api/admin/alerts',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/alerts',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "alerts.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "alerts.view"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "alerts.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "alerts.viewFromCode.{code}"
          },
          newAlert: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "alerts.new"
          },
          delete: {
            method: EndpointMethods.DELETE,
            url: "/{id}",
            fakeSource: "alerts.delete.{id}"
          },
        }
      },
      businesses: {
        baseUrl: 'https://api.tulero.it/api/admin/businesses',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/businesses',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "businesses.list"
          },
          getComplex: {
            method: EndpointMethods.POST,
            url: "",
            fakeSource: "businesses.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "businesses.view"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "businesses.viewFromCode.{code}"
          },
          viewFromCodeForView: {
            method: EndpointMethods.GET,
            url: "/code/{code}/view",
            fakeSource: "businesses.viewFromCode.{code}"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "businesses.edit.{id}"
          },
          editSections: {
            method: EndpointMethods.PUT,
            url: "/{id}/sections",
            fakeSource: "businesses.edit.{id}"
          },
          getPayments: {
            method: EndpointMethods.GET,
            url: "/{businessId}/payments",
            fakeSource: "businesses.payments"
          },
          statusList: {
            method: EndpointMethods.GET,
            url: "/status",
            fakeSource: "businesses.status.list"
          },
          getOrders: {
            method: EndpointMethods.GET,
            url: "/{code}/orders",
            fakeSource: "businesses.orders"
          },
          getOrderStats: {
            method: EndpointMethods.GET,
            url: "/{code}/orders/stats",
            fakeSource: "businesses.orderStats"
          },
          setWallet: {
            method: EndpointMethods.PUT,
            url: "/{code}/wallets/{wallet}",
            fakeSource: "businesses.orderStats"
          },
          getWalletHistory: {
            method: EndpointMethods.GET,
            url: "/{code}/wallets/{wallet}/history",
            fakeSource: "businesses.orderStats"
          },
          newBusiness: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "businesses.new"
          },
          getWallets: {
            method: EndpointMethods.GET,
            url: "/{code}/wallets",
            fakeSource: "businesses.wallets"
          },
          getItemById: {
            method: EndpointMethods.GET,
            url: "/{item}/{id}",
            fakeSource: "businesses.getItembyId.{id}"
          },
          getUsers: {
            method: EndpointMethods.GET,
            url: "/{id}/users",
            fakeSource: "businesses.users"
          },
        }
      },
      products: {
        baseUrl: 'https://api.tulero.it/api/admin/products',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/products',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "products.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "products.view"
          },
          viewOrders: {
            method: EndpointMethods.GET,
            url: "/{id}/orders",
            fakeSource: "products.orders"
          },
          updateProduct: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "products.edit.{id}"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "products.delete.{id}"
          },
          newProduct: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "products.new"
          },
        }
      },
      brands: {
        baseUrl: 'https://api.tulero.it/api/admin/brands',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/brands',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "brands.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "brands.view"
          },
          save: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "brands.edit.{id}"
          },
          create: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "brands.new"
          },
        }
      },
      wallets: {
        baseUrl: 'https://api.tulero.it/api/admin/wallets',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/wallets',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          getWalletTypes: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "brands.list"
          },
          getWallets: {
            method: EndpointMethods.GET,
            url: "/wallets",
            fakeSource: "wallets.list"
          },
        }
      },
      categories: {
        baseUrl: 'https://api.tulero.it/api/admin/categories',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/categories',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "categories.list"
          },
          downloadCSV: {
            method: EndpointMethods.GET,
            url: "/download-csv",
            fakeSource: "categories.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "categories.view"
          },
          update: {
            method: EndpointMethods.PATCH,
            url: "/{id}",
            fakeSource: "categories.view"
          },
          propagatePriceRules: {
            method: EndpointMethods.GET,
            url: "/{id}/propagate-price-rules",
            fakeSource: "categories.view"
          },
          viewByCode: {
            method: EndpointMethods.GET,
            url: "/{code}",
            fakeSource: "categories.view"
          },
          newCategory: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "categories.new"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "categories.delete.{id}"
          },
        }
      },
      suppliers: {
        baseUrl: 'https://api.tulero.it/api/admin/suppliers',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/suppliers',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "suppliers.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "suppliers.view"
          },
          getWarehouses: {
            method: EndpointMethods.GET,
            url: "/warehouses",
            fakeSource: "suppliers.warehouses"
          },
          create: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "suppliers.new"
          },
          save: {
            method: EndpointMethods.PUT,
            url: "/",
            fakeSource: "suppliers.edit.{id}"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "suppliers.delete.{id}"
          },
          getDeliveryPrices: {
            method: EndpointMethods.GET,
            url: "/{id}/delivery-prices",
            fakeSource: "suppliers.deliveryPrices"
          },
          toggleWarehouse: {
            method: EndpointMethods.POST,
            url: "/{id}/{warehouse}"
          }
        }
      },
      warehouses: {
        baseUrl: 'https://api.tulero.it/api/admin/warehouses',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/warehouses',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "warehouses.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "warehouses.view"
          },
          create: {
            method: EndpointMethods.POST,
            url: "",
            fakeSource: "warehouses.new"
          },
          save: {
            method: EndpointMethods.PUT,
            url: "/",
            fakeSource: "warehouses.edit.{id}"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "warehouses.delete.{id}"
          }
        }
      },
      shippingrules: {
        baseUrl: 'https://api.tulero.it/api/admin/shippingrules',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/shippingrules',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "shippingrules.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "shippingrules.view"
          },
          export: {
            method: EndpointMethods.GET,
            url: "/export/simplified",
            fakeSource: "shippingrules.export"
          },
          import: {
            method: EndpointMethods.POST,
            url: "/import",
            fakeSource: "shippingrules.export"
          },
        }
      },
      invoices: {
        baseUrl: 'https://api.tulero.it/api/admin/invoices',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/invoices',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "invoices.list"
          },
          getInvoiceByCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "invoices.view"
          },
          updateInvoice: {
            method: EndpointMethods.PUT,
            url: "/{code}",
            fakeSource: "invoices.edit.{id}"
          },
        }
      },
      carts: {
        baseUrl: 'https://api.tulero.it/api/admin/carts',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/carts',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "carts.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "carts.view"
          },
          save: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "carts.edit.{id}"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "carts.delete.{id}"
          },
          newCart: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "carts.new"
          },
        }
      },
      pdf: {
        baseUrl: 'https://api.tulero.it/api/admin/pdfs',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/pdfs',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          setInvoice: {
            method: EndpointMethods.POST,
            url: "/invoice",
          },
          getPdf: {
            method: EndpointMethods.GET,
            url: "/{id}",
          }
        }
      },
      orders: {
        baseUrl: 'https://api.tulero.it/api/admin/orders',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/orders',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "orders.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "orders.view"
          },
          getDetails: {
            method: EndpointMethods.GET,
            url: "/code/{code}/{year}",
          },
          getDeliveries: {
            method: EndpointMethods.GET,
            url: "/{id}/deliveries",
            fakeSource: "orders.deliveries"
          },
          newDelivery: {
            method: EndpointMethods.POST,
            url: "/{id}/deliveries/new",
            fakeSource: "orders.newDelivery"
          },
          editDelivery: {
            method: EndpointMethods.PUT,
            url: "/{id}/deliveries/{deliveryId}",
            fakeSource: "orders.editDelivery"
          },
          statusCompleted: {
            method: EndpointMethods.PUT,
            url: "/{id}/status/completed",
          },
          statusCanceled: {
            method: EndpointMethods.PUT,
            url: "/{id}/status/canceled",
          },
          stats: {
            method: EndpointMethods.GET,
            url: "/stats",
          },
          downloadProductsCSV: {
            method: EndpointMethods.GET,
            url: "/{code}/{year}/products.csv",
          },
        }
      },
      deliveries: {
        baseUrl: 'https://api.tulero.it/api/admin/deliveries',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/deliveries',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "deliveries.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "deliveries.view"
          },
          edit: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "deliveries.edit.{id}"
          },
          viewFromCode: {
            method: EndpointMethods.GET,
            url: "/code/{code}",
            fakeSource: "deliveries.viewFromCode.{code}"
          },
        },
      },
      payments: {
        baseUrl: 'https://api.tulero.it/api/admin/payments',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/payments',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          getPayments: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "payments.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "payments.view"
          },
          save: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "payments.edit.{id}"
          },
        }
      },
      primes: {
        baseUrl: 'https://api.tulero.it/api/admin/plan',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/plan',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          getPrimes: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "primes.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "primes.view"
          },
          save: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "primes.edit.{id}"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "primes.delete.{id}"
          },
          newPrime: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "primes.new"
          },
        }
      },
      subscriptions: {
        baseUrl: 'https://api.tulero.it/api/admin/subscriptions',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/subscriptions',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          getSubscriptions: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "subscriptions.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "subscriptions.view"
          },
          save: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "subscriptions.edit.{id}"
          },
          newSubscription: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "subscriptions.new"
          },
        }
      },
      paymentMethods: {
        baseUrl: 'https://api.tulero.it/api/admin/payment-methods',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/payment-methods',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "paymentMethods.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "paymentMethods.view"
          },
          save: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "paymentMethods.edit.{id}"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "paymentMethods.delete.{id}"
          },
          newPaymentMethod: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "paymentMethods.new"
          },
        }
      },
      discount: {
        baseUrl: 'https://api.tulero.it/api/admin/discount',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/discount',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "",
            fakeSource: "discount.list"
          },
          view: {
            method: EndpointMethods.GET,
            url: "/{id}",
            fakeSource: "discount.view"
          },
          save: {
            method: EndpointMethods.PUT,
            url: "/{id}",
            fakeSource: "discount.edit.{id}"
          },
          delete: {
            method: EndpointMethods.POST,
            url: "/{id}",
            fakeSource: "discount.delete.{id}"
          },
          newDiscount: {
            method: EndpointMethods.POST,
            url: "/new",
            fakeSource: "discount.new"
          },
        }
      },
      comments: {
        baseUrl: 'https://api.tulero.it/api/admin/comments',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/comments',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          get: {
            method: EndpointMethods.GET,
            url: "/{target}",
            fakeSource: "comments.list"
          },
          set: {
            method: EndpointMethods.POST,
            url: "/{target}",
            fakeSource: "comments.set"
          },
        }
      },
      system: {
        baseUrl: 'https://api.tulero.it/api/admin/system',
        devUrl: 'https://backend.legacy.dipanode.co6.magma.consulting/api/admin/system',
        isDev: true,
        isFake: false,
        useCredentials: true,
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUFETUlOIn0.LCwCbz2DUST44mytkjmZgcF-i4aBcIIns0UXC_DwuKM',
        endpoints: {
          regionList: {
            method: EndpointMethods.GET,
            url: "/regions",
            fakeSource: "region.list"
          },
        }
      }
    }
  }
;
