import { NgModule } from '@angular/core';
import { RouterLink, RouterOutlet } from "@angular/router";

import { LayoutComponent } from '@core/components/layout/layout.component';
import { ContentComponent } from '@core/components/layout/content/content.component';
import { HeaderComponent } from '@core/components/layout/header/header.component';
import {AsyncPipe, DatePipe, NgClass, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import { SidebarComponent } from "@core/components/layout/sidebar/sidebar.component";
import { FooterComponent } from "@core/components/layout/footer/footer.component";
import { NavigationComponent } from "@core/components/navigation/navigation.component";
import { SvgIconComponent } from "@core/components/svg-icon/svg-icon.component";
import { MenuItemComponent } from "@core/components/layout/sidebar/menu-item/menu-item.component";
import { DynamicTableComponent } from "@core/components/dynamic-table/dynamic-table.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from '@ng-select/ng-select';
import {AuthenticationModule} from "../../app/authentication/authentication.module";

@NgModule({
  declarations: [
    LayoutComponent,
    ContentComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    NavigationComponent,
    SvgIconComponent,
    MenuItemComponent,
    DynamicTableComponent
  ],
    imports: [
        RouterOutlet,
        NgOptimizedImage,
        RouterLink,
        NgIf,
        NgForOf,
        NgxDatatableModule,
        NgClass,
        CorePipesModule,
        FormsModule,
        DatePipe,
        NgSelectModule,
        AuthenticationModule,
        AsyncPipe,
    ],
  providers: [],
  exports: [
    LayoutComponent,
    DynamicTableComponent,
    CorePipesModule,
    SvgIconComponent
  ]
})
export class CoreModule {
}
