<div class="full-width d-flex justify-content-between">
  <div class="name">
    <h1 class="font-medium-5 px-025 my-1">Network</h1>
  </div>
  <div class="actions">
    <button *ngIf="_authService.checkPermission('admin.page.networksList.add')" class="btn btn-primary px-1 mb-1" (click)="addNetwork(addNewNetworkModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getNetworks.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>



<ng-template #addNewNetworkModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="newOrder-modal">Aggiungi Network</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Nome</h5>
        <input type="text" class="form-control mb-1" [(ngModel)]="newNetwork.name">
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Codice</h5>
        <input type="text" class="form-control mb-1" [(ngModel)]="newNetwork.code">
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Origine</h5>
        <input type="text" class="form-control mb-1" [(ngModel)]="newNetwork.origin">
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Descrizione</h5>
        <textarea class="form-control mb-1" [(ngModel)]="newNetwork.description"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addNewNetwork()">Aggiungi</button>
  </div>
</ng-template>

<ng-template
  #originsCell
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-rowIndex="rowIndex"
  let-origin="value"
>
  <a routerLink="{{origin}}">
    <span>{{origin}}</span>
  </a>
</ng-template>
