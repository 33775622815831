import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {CustomersService} from "../../../../@core/services/customers.service";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-permission-list',
  templateUrl: './permissions-list.component.html',
  styleUrls: ['./permissions-list.component.scss']
})

export class PermissionsListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public tableConfig: TableConfig = {} as TableConfig;

  public applications: any[] = [];
  public users: any[] = [];
  public businesses: any[] = [];
  public selectedBusiness: any = null;
  public selectedUser: any = null;
  public selectedApplication: any = null;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  private networks: any[] = [];

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _customerService: CustomersService,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService,
    private modalService: NgbModal,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('super-permissions-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('identities')
      .then((table) => {
        console.log("table",table);
        this.tableConfig = table;
        this.generateFilterOptions()
      } );

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  async getIdentities(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['application', 'user', 'business'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const identities: DynamicData = await this._customerService.getIdentityList(query)
    identities.rows.forEach(identity => {
      identity.actions = identity.actions || [];
      identity.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/permissions/' + identity.id
      });
    })
    return identities
  }

  addIdentity(addNewIdentityModal: TemplateRef<any>) {
    return this.modalService.open(addNewIdentityModal, {
      size: 'lg',
      windowClass: 'modal modal-primary'
    });
  }

  selectApplication($event: any) {
    this.selectedApplication = $event;
  }

  searchApplication(event: any) {
    const search = event.target.value;
    this.selectedApplication = null;
    if (search.length == 0) {
      this.applications = [];
      return;
    } else {
      this._customerService.getApplications({search: search})
        .then((response) => {
          console.log('applications', response);
          this.applications = response.rows;
        })
        .catch((error) => {
          console.log('applications error', error);
        })
    }
  }

  selectBusiness($event: any) {
    this.selectedBusiness = $event;
  }

  searchBusiness(event: any) {
    const search = event.target.value;
    this.selectedBusiness = null;
    if (search.length == 0) {
      this.businesses = [];
      return;
    } else {
      this._customerService.getBusinessList({search: search})
        .then((response) => {
          console.log('Businesses', response);
          this.businesses = response.rows;
        })
        .catch((error) => {
          console.log('Businesses error', error);
        })
    }
  }

  selectUser($event: any) {
    this.selectedUser = $event;
  }

  searchUser(event: any) {
    const search = event.target.value;
    this.selectedUser = null;
    if (search.length == 0) {
      this.users = [];
      return;
    } else {
      this._customerService.getUserList({search: search})
        .then((response) => {
          console.log('users', response);
          this.users = response.rows;
        })
        .catch((error) => {
          console.log('users error', error);
        })
    }
  }

  generateSelectOptions() {
    this._customerService.getApplications()
      .then((applications) => {
        this.applications = applications.rows;
        this._customerService.getBusinessesList()
          .then((businesses) => {
            this.businesses = businesses.rows;
            this._customerService.getUserList()
              .then((users) => {
                this.users = users.rows;
              })
              .catch((error: any) => {
              });
          })
          .catch((error: any) => {
          });
      })
      .catch((error: any) => {
      });
  }

  createIdentity() {
    const identity = {
      application: this.selectedApplication.code,
      user: this.selectedUser.id,
      business: this.selectedBusiness.id,
      permissions: [],
    }
    console.log('identity', identity);
    this._customerService.createIdentity(identity)
      .then((response: any) => {
        console.log('response', response);
        this.modalService.dismissAll();
        this.getIdentities();
      })
      .catch((error: any) => {
        console.log('error', error);
      })
  }

  generateFilterOptions() {
    this.tableConfig.filterGroups.forEach(filterGroup => {
      filterGroup.filters.forEach((filter: any) => {
        switch (filter.retriever) {
          case 'businesses.list':
            this._customerService.getBusinessesList().then((response: DynamicData) => {
              filter.options = response.rows.map(business => {
                return {
                  label: business.name,
                  value: business.id
                }
              })
            })
            break;
          case 'applications.list':
            this._customerService.getApplications()
              .then((applications) => {
                this.applications = applications.rows;
              filter.options = this.applications.map(application => {
                return {
                  label: application.name,
                  value: application.id
                }
              })
            })
            break;
        }
      })
    })
  }
}
