// Importing necessary modules and components from Angular and other libraries
import {Component, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID, TemplateRef} from "@angular/core";
import {Subject} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {ShopService} from "@core/services/shop.service";
import {CatalogService} from "../../../../@core/services/catalog.service";
import Swal from "sweetalert2";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CommentsService} from "../../../../@core/services/comments.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";


@Component({
  selector: 'app-products-view',
  templateUrl: './products-view.component.html',
  styleUrls: ['./products-view.component.scss']
})
export class ProductsViewComponent implements OnInit, OnDestroy {

  // Private variables
  private _unsubscribeAll: Subject<any>; // Subject to unsubscribe from subscriptions

  // Public variables
  public code: string = ''; // Public variable to store a string code
  public product: any = {}; // Public variable to store a Group object
  public pictureIndex = 0; // Public variable to store a number
  public selectedDatabase = 'kromeda'; // Public variable to store a string
  public stats: any = {}; // Public variable to store a Group object
  public loading: boolean = true; // Public variable to store a boolean value to toggle loading
  public brands: any[] = [];
  public categories: any[] = [];
  public selectedSupplier: any = {};
  public selectedSupplierIndex: number = 0;
  public suppliers: any[] = [];
  public warehouses: any[] = [];
  public selectedWarehouse: any = {};

  public permissions: any[] = [];
  public pagePermissions: {
    image: boolean;
    details: boolean;
    info: boolean;
    database: boolean;
    actions: boolean;
    comments: boolean;
    edit: boolean;
    delete: boolean;
    suppliers: boolean;
    orders: boolean;
    research: boolean;
    cross: boolean;
  } = {
    image: false,
    details: false,
    info: false,
    database: false,
    actions: false,
    comments: false,
    edit: false,
    delete: false,
    suppliers: false,
    orders: false,
    research: false,
    cross: false,
  }

  public comments: any[] = [];
  public commentText: string = '';


  public statuses: any = {
    CREATED: {
      class: 'badge-light-warning',
      label: 'Creato'
    },
    VERIFIED: {
      class: 'badge-light-info',
      label: 'Verificato'
    },
    COMPLETED: {
      class: 'badge-light-primary',
      label: 'Completato'
    },
    SUSPENDED: {
      class: 'badge-light-danger',
      label: 'Sospeso'
    },
    CANCELED: {
      class: 'badge-light-danger',
      label: 'Annullato'
    },
  }

  public ordersColumns = [
    // {
    //   autoResize: true,
    //   draggable: false,
    //   resizable: false,
    //   sortable: false,
    //   width: 50,
    //   maxWidth: 50,
    //   minWidth: 50,
    //   type: 'select',
    //   header: true,
    //   cell: true,
    //   flexGrow: 1
    // },
    {
      width: 120,
      name: 'Codice',
      prop: 'code',
      type: 'link',
      link: 'code',
      sortable: false,
      resizable: true,
    },
    {
      width: 120,
      name: 'Stato',
      prop: 'status',
      type: 'badge',
      sortable: false,
      resizable: true,
      extra: {
        CREATED: {
          class: 'badge-light-warning',
          label: 'Creato'
        },
        APPROVED: {
          class: 'badge-light-info',
          label: 'Approvato'
        },
        FAILED: {
          class: 'badge-light-danger',
          label: 'Fallito'
        },
        CONFIRMED: {
          class: 'badge-light-primary',
          label: 'Confermato'
        },
        REJECTED: {
          class: 'badge-light-danger',
          label: 'Rifiutato'
        },
        COMPLETED: {
          class: 'badge-light-primary',
          label: 'Completato'
        },
        CANCELED: {
          class: 'badge-light-danger',
          label: 'Annullato'
        },
        SHIPPED: {
          class: 'badge-light-warning',
          label: 'Spedito'
        },
        DELIVERED: {
          class: 'badge-light-success',
          label: 'Consegnato'
        },
      }
    },
    {
      width: 100,
      name: 'Fornitore',
      prop: 'supplier.name',
      type: 'text',
      sortable: false,
      resizable: true,
    },
    {
      width: 50,
      name: 'Qtà.',
      prop: 'product.quantity',
      type: 'text',
      sortable: false,
      resizable: true,
    },
    {
      width: 100,
      name: 'Prezzo',
      prop: 'product.price',
      type: 'price',
      sortable: false,
      resizable: true,
    },
    {
      width: 150,
      name: 'Data',
      prop: 'createdAt',
      type: 'date-time',
      sortable: true,
      resizable: true,
    },
    {
      width: 100,
      name: 'Azioni',
      prop: 'actions',
      type: 'actions',
      sortable: false,
      resizable: true,
    },
  ];

  public ordersMessages = {
    emptyMessage: 'Nessun ordine trovato',
    totalMessage: 'totali',
    selectedMessage: 'selezionati',
    beforePageSizeMessage: 'Mostra',
    afterPageSizeMessage: 'aziende',
    textSearchMessage: 'Cerca',
    specificSearchMessage: 'Corrispondenza esatta',
    beforeAutoReloadMessage: 'Ricarica ogni',
    afterAutoReloadMessage: 'secondi',
  }

  public newMeta: any = {
    name: '',
    value: ''
  }

  constructor(
    // Injecting services and dependencies required for this component
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _catalogService: CatalogService,
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    private _commentService: CommentsService,
    private _authService: AuthenticationService,
  ) {
    // Initializing private variables and setting defaults in the constructor
    this._unsubscribeAll = new Subject(); // Initializing _unsubscribeAll as a Subject
    this._applicationService.layoutContentWidth(); // Setting layout content width
    this._applicationService.selectMenu('catalog-products-list');

  }


  // Lifecycle hook - On initialization
  ngOnInit() {
    // Retrieving 'code' from the route or setting an empty string
    // this.code = this._route.snapshot.paramMap.get('code') || '';

    this.code = this._route.snapshot.paramMap.get('id') || '';

    this.pagePermissions = {
      image: this._authService.checkPermission('admin.page.productsView.image'),
      details: this._authService.checkPermission('admin.page.productsView.details'),
      info: this._authService.checkPermission('admin.page.productsView.info'),
      database: this._authService.checkPermission('admin.page.productsView.database'),
      actions: this._authService.checkPermission('admin.page.productsView.actions'),
      comments: this._authService.checkPermission('admin.page.productsView.comments'),
      edit: this._authService.checkPermission('admin.page.productsView.edit'),
      delete: this._authService.checkPermission('admin.page.productsView.delete'),
      suppliers: this._authService.checkPermission('admin.page.productsView.suppliers'),
      orders: this._authService.checkPermission('admin.page.productsView.orders'),
      research: this._authService.checkPermission('admin.page.productsView.research'),
      cross: this._authService.checkPermission('admin.page.productsView.cross'),
    }

    // Fetching a single group from the customer service
    this._catalogService.getSingleProduct(this.code, {
      params: {
        populateFields: ['category', 'brand', 'suppliers.supplier', 'suppliers.warehouses.warehouse']
      }
    })
      .then((response: any) => {
        this.loading = false; // Setting loading to false
        this.product = response; // Setting the fetched response to the 'group' variable
        this._catalogService.getSupplierList({ pageSize: 10000 })
          .then((response: any) => {
            this.suppliers = response.rows;
            this.suppliers = this.suppliers.filter((supplier: any) => {
              return !this.product.suppliers.find((productSupplier: any) => productSupplier.supplier.id === supplier.id)
            });
          });
      });
    this.generateBrandList();
    this.generateCategoryList();
  }

  // Lifecycle hook - On component destruction
  ngOnDestroy(): void {
    // Unsubscribing from all subscriptions to prevent memory leaks
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getOrders(query?: any) {
    query = query || {};
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    query.filters = query.filters || {};
    query['populateFields'] = ['supplier'];
    const orders: DynamicData = await this._catalogService.getSingleProductOrders(this.code, query)
    orders.rows.forEach(order => {
      order.links = order.links || {};
      order.links.code = '/orders/' + order.code;
      order.product = order.products.find((product: any) => product._id === this.product.id);
      order.actions = order.actions || [];
      order.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/orders/' + order.code
      });
    })
    return orders
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const selectedFile = inputElement.files[0];
      // Process the selected file here, for example, you can upload it or display it
      // For demonstration, you can access the file details like its name
      // If you want to display the selected image, you may set it as the main picture
      // Assuming product.pictures is an array where you can push the selected image URL
      this.product.pictures.push(URL.createObjectURL(selectedFile));
      this.pictureIndex = this.product.pictures.length - 1; // Set the newly uploaded image as the main picture
    }
  }

  addMeta(addMetaModal: TemplateRef<any>) {
    return this.modalService.open(addMetaModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  addCross() {
    this.product.cross = this.product.cross || [];
    this.product.cross.push("");
  }

  removeCross(index: number) {
    this.product.cross.splice(index, 1);
  }

  trackByCrossIndex(index: number, obj: any): any {
    return index;
  }

  generateBrandList() {
    this._catalogService.getBrandList({
      pageSize: 10000,
    }).then((response: DynamicData) => {
      this.brands = response.rows.map(brand => {
        return {
          label: brand.label,
          id: brand.id,
          code: brand.code,
          logo: brand.logo,
          name: brand.name,
          info: {
            kromeda: {
              code: brand.info.kromeda.code,
              type: brand.info.kromeda.type,
            }
          }
        }
      })
    });
  }

  generateCategoryList() {
    this._catalogService.getCategoryList({
      pageSize: 10000,
    }).then((response: DynamicData) => {
      this.categories = response.rows.map(category => {
        return {
          label: category.label,
          id: category.id,
          code: category.code,
          name: category.name,
          parent: category.parent,
          priceRules: category.priceRules,
          createdAt: category.createdAt,
          updatedAt: category.updatedAt,
        }
      });
    });
  }

  saveWarehouse(supplier: any, warehouse: any) {
    this.save();
  }

  save() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Stai per modificare il prodotto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
      cancelButtonColor: 'var(--danger)',
      confirmButtonText: 'Si, modifica!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.product.brand = this.product.brand.id;
        this.product.category = this.product.category.id;
        this._catalogService.updateProduct(this.product.id, this.product)
          .then((response: any) => {
            // Fetching a single group from the customer service
            this._catalogService.getSingleProduct(this.code, {
              params: {
                populateFields: ['category', 'brand', 'suppliers.supplier', 'suppliers.warehouses.warehouse']
              }
            })
              .then((response: any) => {
                this.loading = false; // Setting loading to false
                this.product = response; // Setting the fetched response to the 'group' variable
              });
            this.generateBrandList();
            this.generateCategoryList();
          });
        Swal.fire(
          'Modificato!',
          'Il prodotto è stato modificato.',
          'success'
        )
      }
    })

  }

  delete() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Stai per eliminare il prodotto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
      cancelButtonColor: 'var(--danger)',
      confirmButtonText: 'Si, elimina!'
    }).then((result) => {
      if (result.isConfirmed) {
        this._catalogService.deleteProduct(this.product.id)
          .then((response: any) => {
            this.product = response;
          });
        Swal.fire(
          'Eliminato!',
          'Il prodotto è stato eliminato.',
          'success'
        )
        this._router.navigate(['/products']);
      }
    })
  }

  onBrandInput($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const value = inputElement.value;
    this.product.brand = this.brands.find(brand => brand.id === value);
  }

  onCategoryInput($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const value = inputElement.value;
    this.product.category = this.categories.find(category => category.id === value);
  }

  removeMeta(meta: any) {
    this.product.meta = this.product.meta.filter((m: any) => m !== meta);
  }

  addInfo() {
    const newItemKey: string = '';
    const newItemValue: string = '';
    this.product.info.kromeda[newItemKey] = newItemValue;
  }

  removeDatabase(item: any) {
    this.product.info.kromeda.remove(item);
  }

  addMetaToProduct() {
    this.product.meta = this.product.meta || [];
    this.product.meta.push(this.newMeta);
    this.modalService.dismissAll();
    this.newMeta = {
      name: '',
      value: ''
    }
  }

  removeInfo(item: any) {
    this.product.info.kromeda.remove(item);
  }

  getLocalUser(): {
    name: string,
    avatar: string
  } {
    const userName = localStorage.getItem('comment-user-name') || "Cap Nemo";
    const userAvatar = localStorage.getItem('comment-user-avatar') || "assets/images/avatars/default-avatar.png";
    return {
      name: userName,
      avatar: userAvatar
    }
  }

  loadComments() {
    this._commentService.getCommentList(this.code)
      .then((response: any) => {
        this.comments = response.rows;
      })
      .catch((error: any) => {
        console.error('Error fetching comments:', error);
      });
  }

  addComment() {
    let user = this.getLocalUser();
    if (this.commentText.trim()) {
      this._commentService.setComment(this.code, this.commentText, user)
        .then((response: any) => {
          this.commentText = '';
          this.loadComments();
          Swal.fire({
            title: 'Conferma',
            text: 'Commento aggiunto con successo',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
        })
        .catch((error: any) => {
          console.error('Error fetching comments:', error);
          Swal.fire({
            title: 'Errore',
            text: 'Errore durante l\'invio del commento',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
    }
  }

  saveSupplier(supplier: any) {
    this.save();
  }

  addSupplierToProduct() {
    console.log(this.selectedSupplier);
    this.modalService.dismissAll();
    const newSupplier = {
      supplier: {
        id: this.selectedSupplier.supplier.id,
        name: this.selectedSupplier.supplier.name,
        description: this.selectedSupplier.supplier.description,
        contacts: this.selectedSupplier.supplier.contacts,
      },
      article_code: "",
      min: 0,
      max: 999999,
      unit: "",
      price: 0,
      multiplier: 1,
      warehouses: []
    }
    this.product.suppliers.push(newSupplier);
    this.selectedSupplier = {};
    console.log(this.product.suppliers);
    console.log(this.selectedSupplier);
    this.save();
    this.modalService.dismissAll();
  }

  addSupplier(addNewSupplierModal: TemplateRef<any>) {
    return this.modalService.open(addNewSupplierModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  onSupplierInput($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const value = inputElement.value;
    this.selectedSupplier = this.suppliers.find(supplier => supplier.id === value);
  }

  deleteSupplier(i: number) {
    this.product.suppliers.splice(i, 1);
    this.save();
    this._catalogService.getSupplierList({ pageSize: 10000 })
      .then((response: any) => {
        this.suppliers = response.rows;
        this.suppliers = this.suppliers.filter((supplier: any) => {
          return !this.product.suppliers.find((productSupplier: any) => productSupplier.supplier.id === supplier.id)
        });
      });
  }

  async getWarehouses(supplierId: string) {
    const query = {
      page: 1,
      pageSize: 10000,
      sort: 'name',
      order: 'asc',
      filters: {
        supplier: {
          $oid: supplierId
        }
      }
    };
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._route,
        queryParams,
      }
    );
    query.filters = query.filters || {};
    query.filters.supplier = {$oid: supplierId};
    const warehouses: DynamicData = await this._catalogService.getWarehouseList(query)
    //filter and remove warehouses already in the product
    warehouses.rows = warehouses.rows.filter((warehouse: any) => {
      return !this.product.suppliers[this.selectedSupplierIndex].warehouses.find((productWarehouse: any) => productWarehouse.warehouse.id === warehouse.id)
    });
    return warehouses;
  }

  onWarehouseInput($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const value = inputElement.value;
    this.selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === value);
  }

  addWarehouse(addNewWarehouseModal: TemplateRef<any>, i: number, supplier: any) {
    this.selectedSupplier = supplier;
    this.getWarehouses(supplier.supplier.id)
      .then((response: any) => {
        this.warehouses = response.rows;
        this.selectedSupplierIndex = i;
        if (this.warehouses.length > 0) {
          this.modalService.open(addNewWarehouseModal, {
            size: 'md',
            windowClass: 'modal modal-primary'
          });
        }
        else {
          Swal.fire({
            title: 'Nessun magazzino disponibile',
            text: "Non ci sono magazzini disponibili per questo fornitore",
            icon: 'warning',
            confirmButtonColor: 'var(--primary)',
            confirmButtonText: 'Ok'
          });
        }
      });
  }

  addWarehouseToSupplier() {
    this.modalService.dismissAll();
    const newWarehouse = {
      warehouse: {
        id: this.selectedWarehouse.id,
        code: this.selectedWarehouse.code,
        name: this.selectedWarehouse.name,
        label: this.selectedWarehouse.label,
        supplier: this.selectedSupplier.supplier.id,
        active: this.selectedWarehouse.active,
      },
      stock: 0,
    }
    this.product.suppliers[this.selectedSupplierIndex].warehouses.push(newWarehouse);
    this.selectedWarehouse = {};
    console.log(this.selectedWarehouse);
    this.save();
  }

  deleteWarehouse(j: number) {
    this.product.suppliers[this.selectedSupplierIndex].warehouses.splice(j, 1);
    this.save();
  }
}
