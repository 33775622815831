<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Sconto</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.discountsList.add')" class="btn btn-primary px-1" (click)="addDiscount(addNewdiscountModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getDiscounts.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>

<ng-template #addNewdiscountModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="newOrder-modal">Aggiungi sconto</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h5 class="card-title">Sconto {{ newDiscount.code }}</h5>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Codice: </span>
          <input type="text" class="form-control" [(ngModel)]="newDiscount.code"/>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Tipo: </span>
          <select class="form-control" [(ngModel)]="newDiscount.type">
            <option value="PERCENTAGE">Percentuale</option>
            <option value="AMOUNT">Fisso</option>
          </select>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Sconto: </span>
          <input type="number" class="form-control" [(ngModel)]="newDiscount.amount"/>
        </p>
        <p class="card-text mb-05 mt-1">
          <input type="checkbox" class="form-check-input mr-05" id="new-discount-restricted" [(ngModel)]="newDiscount.restricted" (click)="updateRestricted(newDiscount)"/>
          <label class="card-text-title" for="new-discount-restricted">Limitato </label>
        </p>
        <div class="from-date-picker time-selector-container full-width" #fromDatePicker>
          <p class="card-text mb-05 mt-2"><span class="card-text-title">Da: </span>
          <div class="btn btn-outline-primary btn-sm full-width" (click)="openFromDateSelector($event)">
            <div class="font-small-2">
              <span class="align-left"> {{ fromDateString }} </span>
              <app-svg-icon name="calendar" class="align-middle ml-05"></app-svg-icon>
            </div>
          </div>
          <div class="time-selector" [class.active]="selectFromDate">
            <ngb-datepicker #fromDatepicker (dateSelect)="onFromDateSelection($event)"
                            [displayMonths]="1"
                            [dayTemplate]="t"
                            outsideDays="hidden"
                            class="full-width"
            />
            <ng-template #t let-date let-focused="focused">
      <span
        class="custom-day full-width"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </span>
            </ng-template>
          </div>
        </div>

        <div class="to-date-picker time-selector-container" #toDatePicker>
          <p class="card-text mb-05 mt-2"><span class="card-text-title">A: </span>
          <div class="btn btn-outline-primary btn-sm full-width" (click)="openToDateSelector($event)">
            <div class="font-small-2">
              <span class="align-middle"> {{ toDateString }} </span>
              <app-svg-icon name="calendar" class="align-middle ml-05"></app-svg-icon>
            </div>
          </div>
          <div class="time-selector " [class.active]="selectToDate">
            <ngb-datepicker #toDatepicker (dateSelect)="onToDateSelection($event)"
                            [displayMonths]="1"
                            [dayTemplate]="t"
                            [minDate]="fromDate"
                            outsideDays="hidden"
                            class="full-width"
            />
            <ng-template #t let-date let-focused="focused">
      <span
        class="custom-day full-width"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </span>
            </ng-template>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addNewDiscount()">Aggiungi</button>
  </div>
</ng-template>
