import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {CatalogService} from "@core/services/catalog.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import Swal from "sweetalert2";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";

@Component({
  selector: 'app-brands-view',
  templateUrl: './brands-view.component.html',
  styleUrls: ['./brands-view.component.scss']
})

export class BrandsViewComponent implements OnInit, OnDestroy {

  public brand: any = {};
  public pictureIndex = 0;

  // Private
  private _unsubscribeAll: Subject<any>;


  public currentId: string = '';

  public tableConfig: TableConfig = {} as TableConfig;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _catalogService: CatalogService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dynamicTableService: DynamicTableService

  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('catalog-brands-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('products')
      .then((table) => {
        this.tableConfig = table;
      })
    this.currentId = this._activatedRoute.snapshot.paramMap.get('id') || '';
    this._catalogService.getSingleBrand(this.currentId).then(brand => {
      this.brand = brand;
    } );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const selectedFile = inputElement.files[0];
      // Process the selected file here, for example, you can upload it or display it
      // For demonstration, you can access the file details like its name
      // If you want to display the selected image, you may set it as the main picture
      // Assuming product.pictures is an array where you can push the selected image URL
      this.brand.pictures.push(URL.createObjectURL(selectedFile));
      this.pictureIndex = this.brand.pictures.length - 1; // Set the newly uploaded image as the main picture
    }
  }

  async getProducts(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query.filters = query.filters || {};
    query.filters.brand = {
      $oid: this.currentId
    }
    query['populateFields'] = ['category', 'brand'];
    const products: DynamicData = await this._catalogService.getProductList(query)

    products.rows.forEach(product => {
      product.actions = product.actions || [];
      product.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/products/' + product.id
      });
    })

    return products;
  }

  saveBrand() {
    //swal to confirm
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Vuoi salvare le modifiche?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, salva!',
      cancelButtonText: 'No, annulla'
    }).then((result) => {
      if (result.isConfirmed) {
        this._catalogService.saveBrand(this.currentId, this.brand)
          .then((response) => {
            if (!response) {
              return
            }
            this.brand = response as DynamicData
            Swal.fire(
              'Salvato!',
              'Il brand è stato salvato con successo.',
              'success'
            )
            this._router.navigate(['/brands'])
          })
          .catch((error) => {
          });
      }
    })

  }
}
