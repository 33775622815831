<div class="full-width d-flex justify-content-between">
  <div class="name">
    <h1 class="font-medium-5 px-025 my-1">Categorie</h1>
  </div>
  <div class="actions">
    <button *ngIf="_authService.checkPermission('admin.page.categoryList.csv')" class="btn btn-primary mb-1" (click)="donwloadCSV()">
      <app-svg-icon name="download"/>
      <span>CSV</span>
    </button>
    <button *ngIf="_authService.checkPermission('admin.page.categoryList.add')" class="btn btn-primary ml-1 mb-1 px-1" (click)="addCategory(addNewCategoryModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getCategories.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [messages]="tableConfig.messages"
    [autoReloadEnable]="true"
  >
  </dynamic-table>
</div>

<ng-template #addNewCategoryModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="newOrder-modal">Aggiungi categoria</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-3">
        <label for="category-name" class="form-label">Nome</label>
        <div class="input-group mb-1">
          <input type="text" class="form-control" id="category-name" aria-describedby="basic-addon3"
                 [(ngModel)]="newCategory.name">
        </div>
      </div>
      <div class="col-3">
        <label for="category-code" class="form-label">Codice</label>
        <div class="input-group mb-1">
          <input type="text" class="form-control" id="category-code" aria-describedby="basic-addon3"
                 [(ngModel)]="newCategory.code">
        </div>
      </div>
      <div class="col-6">
        <label for="category-parent" class="form-label">Categoria padre</label>
        <div  class="mb-1">


        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)=saveNewCategory()>Salva</button>
  </div>
</ng-template>
