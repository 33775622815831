<div class="full-width d-flex justify-content-between">
  <div class="name">
    <h1 class="font-medium-5 px-025 my-1">Email</h1>
  </div>
  <div class="actions">
    <button class="btn btn-primary ml-1 mb-1 px-1" (click)="addMail(addNewMailModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy" *ngIf="loaded | async">
  <dynamic-table
    [dataRetriever]="getMails.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
    [autoReloadEnable]="true"
  >
  </dynamic-table>
</div>


<ng-template #addNewMailModal let-modal>
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Nuova Email</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <ng-container class="col-4" *ngFor="let recipient of newEmail.recipients; let i = index">
            <div class="form-group col-4">
              <label for="recipient{{i+1}}" class="mt-05">Destinatario {{ i + 1 }}</label>
              <input type="email" class="form-control" id="recipient{{i+1}}" [(ngModel)]="recipient.email">
            </div>
          </ng-container>
        </div>
        <button class="btn btn-primary mt-1 col-2" (click)="addRecipient()">
          <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
        </button>
      </div>
      <div class="col-12">
        <label for="subject" class="mt-05">Oggetto</label>
        <input type="text" class="form-control" id="subject" [(ngModel)]="newEmail.subject">
        <label for="Email" class="mt-05">Email</label>
        <textarea class="form-control" id="Email" [(ngModel)]="newEmail.template"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="sendMail()">
      <app-svg-icon [name]="'paperPlane'" class="mr-05"></app-svg-icon>
      Invia
    </button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss()">Annulla</button>
  </div>
</ng-template>
