import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BannersListComponent } from "./banners-list/banners-list.component";
import { CoreModule } from "@core/components/core.module";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {BannersViewComponent} from "./banners-view/banners-view.component";

const routes: Routes = [
  {
    path: 'banners',
    component: BannersListComponent,
    data: { animation: 'BannersListComponent' }
  },
  {
    path: 'banners/:id',
    component: BannersViewComponent,
    data: { animation: 'BannersViewComponent' }
  },
];

@NgModule({
  declarations: [
    BannersListComponent,
    BannersViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    FormsModule,
    NgSelectModule
  ],
  providers: []
})
export class BannersModule {
}
