import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';
import { PricePipe } from '@core/pipes/price.pipe';
import { ToNumberPipe } from '@core/pipes/toNumber.pipe';
import { IsURLPipe } from '@core/pipes/isURL.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { DateHumanizedPipe } from '@core/pipes/dateHumanized.pipe';
import { ReducePipe } from '@core/pipes/reduce.pipe';
import { Initials2CapsPipe } from "@core/pipes/initials2caps.pipe";
import { JsonPipe } from "@core/pipes/json.pipe";
import { LogPipe } from "@core/pipes/log.pipe";

@NgModule({
  declarations: [
    InitialsPipe,
    Initials2CapsPipe,
    PricePipe,
    ToNumberPipe,
    IsURLPipe,
    DateHumanizedPipe,
    ReducePipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    JsonPipe,
    LogPipe
  ],
  imports: [],
  exports: [
    InitialsPipe,
    Initials2CapsPipe,
    PricePipe,
    ToNumberPipe,
    IsURLPipe,
    DateHumanizedPipe,
    ReducePipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    JsonPipe,
    LogPipe
  ]
})
export class CorePipesModule {
}
