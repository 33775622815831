<div class="row" xmlns="http://www.w3.org/1999/html">
  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-header pb-0">
      </div>
      <div class="card-body pt-025 pb-0">
        <h5 class="card-title mb-0 text-primary">
          <span class="align-middle">Da:</span>
        </h5>
        <div class="row mt-075">
          <div class="col-3">
            <h6 class="text-nowrap">Nome:</h6>
            <h6 class="text-nowrap">Email:</h6>
          </div>
          <div class="col-9">
            <h6 class="font-weight-normal ">{{ emailDocument.data.name || '-' }}</h6>
            <h6 class="font-weight-normal">{{ emailDocument.data.email || '-' }}</h6>
          </div>
        </div>
        <h5 class="card-title mb-0 text-primary mt-1">
          <span class="align-middle">Destinario:</span>
        </h5>
        <ng-container *ngFor="let recipient of emailDocument.recipients" class="mt-05">
          <div class="row mt-075">
            <div class="col-3">
              <h6 class="text-nowrap">Nome:</h6>
              <h6 class="text-nowrap">Email:</h6>
            </div>
            <div class="col-9">

              <h6 class="font-weight-normal ">{{ recipient.name || '-' }}</h6>
              <h6 class="font-weight-normal">{{ recipient.email || '-' }}</h6>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-header pb-0">
        <h5 class="card-title mb-0 text-primary">
          <span class="align-middle">Dettagli:</span>
        </h5>
      </div>
      <div class="card-body pt-025 pb-0">
        <div class="row mt-075">
          <div class="col-3">
            <h6 class="text-nowrap">Oggetto:</h6>
            <h6 class="text-nowrap">Data:</h6>
            <h6 class="text-nowrap">Stato:</h6>
          </div>
          <div class="col-9">
            <h6 class="font-weight-normal ">{{ emailDocument.subject || '-' }}</h6>
            <h6 class="font-weight-normal">{{ emailDocument.createdAt || '-' }}</h6>
            <span class="badge badge-pill font-small-3 align-middle {{statuses[emailDocument.status].class}}">
            {{ statuses[emailDocument.status].label }}
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-4 col-lg-2">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-header pb-0">
        <h5 class="card-title mb-0 text-primary">
          <span class="align-middle">Azioni:</span>
        </h5>
      </div>
      <div class="card-body pt-025 pb-0">
        <div class="row">
          <div class="col-12 mt-05">
            <button class="btn btn-primary btn-block col-12" (click)="sendAgain()">
              <app-svg-icon [name]="'paperPlane'" class="mr-05"></app-svg-icon>
              <span class="align-middle">Manda di nuovo</span>
            </button>
          </div>
          <div class="col-12 mt-05">
            <button class="btn btn-primary btn-block col-12" (click)="sendTo()">
              <app-svg-icon [name]="'paperPlane'" class="mr-1"></app-svg-icon>
              <span class="align-middle">Manda a</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-12">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-header pb-0">
        <h5 class="card-title mb-0 text-primary">
          <span class="align-middle">Email</span>
        </h5>
      </div>
      <div class="card-body pt-025 pb-0">
        <div class="row mt-075">
          <div class="col-12">
            <div [innerHTML]="emailDocument.template"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
