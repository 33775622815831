//write the base codes for the networks-view.component.ts file
import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import Swal from "sweetalert2";
import {Subject} from "rxjs";
import {CustomersService} from "../../../../@core/services/customers.service";
import {CatalogService} from "../../../../@core/services/catalog.service";

@Component({
  selector: 'app-networks-view',
  templateUrl: './networks-view.component.html',
  styleUrls: ['./networks-view.component.scss']
})
export class NetworksViewComponent implements OnInit, OnDestroy {

  public warehouses: any[] = [];

  public network: any = null;
  public currentCode: any;
  public loaded: Promise<boolean> = Promise.resolve(false);
  public activeTheme: boolean = false;
  public activeApplication: boolean = false;
  public activeIcons: boolean = false;
  public activeColors: boolean = false;
  public activeMenus: boolean = false;
  public activeLogo: boolean = false;
  public activeLanguage: boolean = false;
  public activeSections: boolean = false;
  public hasTheme: boolean = false;

  public hardcodedSections: any[] = [
    {
      key: 'wallet TULERO',
      title: 'tulero:wallet',
      value: false
    },
    {
      key: 'wallet EK PARTS',
      title: 'custom:wallet:EK PARTS',
      value: false
    },
    {
      key: 'image-wallet',
      title: 'custom:image-wallet:https://tulero.blob.core.windows.net/tulero-images/ekparts-wallet.png',
      value: false
    },
    {
      key: 'access',
      title: 'EK Parts',
      value: false
    },
    {
      key: 'terms',
      title: 'custom:who:url:https://tulero.it/chi-siamo-2/',
      value: false
    },
    {
      key: 'help',
      title: 'custom:help:url:https://tulero.it/guida-acquisto-ekparts/',
      value: false
    },
    {
      key: 'legal',
      title: 'custom:legal:url:https://tulero.it/informazioni-legali-2/',
      value: false
    },
    {
      key: 'privacy',
      title: 'custom:privacy:url:https://tulero.it/privacy-2/',
      value: false
    },
    {
      key: 'who',
      title: 'custom:who:url:https://tulero.it/chi-siamo-2/',
      value: false
    },
  ];


  public menuItems: any[] = [
    {
      title: 'home',
      value: false,
    },
    {
      title: 'spare-parts',
      value: false,
    },
    {
      title: 'promo',
      value: false,
    },
    {
      title: 'lubricants',
      value: false,
    },
    {
      title: 'batteries',
      value: false,
    },
    {
      title: 'tires',
      value: false,
    },
    {
      title: 'gpl',
      value: false,
    },
    {
      title: 'universal',
      value: false,
    },
    {
      title: 'accessories',
      value: false,
    },
    {
      title: 'outlet',
      value: false,
    },
  ]
  public languages: any[] = [
    {
      title: 'Italiano',
      value: 'it'
    },
    {
      title: 'Inglese',
      value: 'en'
    },
    {
      title: 'Francese',
      value: 'fr'
    },
    {
      title: 'Spagnolo',
      value: 'es'
    },
    {
      title: 'Tedesco',
      value: 'de'
    },
    {
      title: 'Olandese',
      value: 'nl'
    },
    {
      title: 'Portoghese',
      value: 'pt'
    },
    {
      title: 'Persiano',
      value: 'fa'
    },
    {
      title: 'Russo',
      value: 'ru'
    },
    {
      title: 'Cinese',
      value: 'zh'
    },
    {
      title: 'Giapponese',
      value: 'ja'
    },
    {
      title: 'Coreano',
      value: 'ko'
    },
    {
      title: 'Arabo',
      value: 'ar'
    },
    {
      title: 'Turco',
      value: 'tr'
    },
    {
      title: 'Svedese',
      value: 'sv'
    },
    {
      title: 'Norvegese',
      value: 'no'
    },
    {
      title: 'Danese',
      value: 'da'
    },
    {
      title: 'Finlandese',
      value: 'fi'
    },
    {
      title: 'Ceco',
      value: 'cs'
    },
    {
      title: 'Slovacco',
      value: 'sk'
    },
    {
      title: 'Ungherese',
      value: 'hu'
    },
    {
      title: 'Polacco',
      value: 'pl'
    },
    {
      title: 'Rumeno',
      value: 'ro'
    },
    {
      title: 'Bulgaro',
      value: 'bg'
    },
    {
      title: 'Greco',
      value: 'el'
    },
    {
      title: 'Lituano',
      value: 'lt'
    },
    {
      title: 'Lettone',
      value: 'lv'
    },
    {
      title: 'Estone',
      value: 'et'
    },
    {
      title: 'Sloveno',
      value: 'sl'
    },
    {
      title: 'Croato',
      value: 'hr'
    },
    {
      title: 'Serbo',
      value: 'sr'
    },
    {
      title: 'Macedone',
      value: 'mk'
    },
    {
      title: 'Albanese',
      value: 'sq'
    },
    {
      title: 'Serbo',
      value: 'sr'
    },
    {
      title: 'Ucraino',
      value: 'uk'
    },
    {
      title: 'Bielorusso',
      value: 'be'
    },
    {
      title: 'Georgiano',
      value: 'ka'
    },
    {
      title: 'Armeno',
      value: 'hy'
    },
    {
      title: 'Azero',
      value: 'az'
    },
    {
      title: 'Turkmeno',
      value: 'tk'
    },
    {
      title: 'Kazako',
      value: 'kk'
    },
    {
      title: 'Uzbeco',
      value: 'uz'
    },
    {
      title: 'Tagiko',
      value: 'tg'
    },
    {
      title: 'Kirghiso',
      value: 'ky'
    },
  ];

  public sections: any[] = [];

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private shopService: ShopService,
    private _customerService: CustomersService,
    private _catalogService: CatalogService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.loaded = Promise.resolve(false);
    this.route.params.subscribe((params: Params) => {
      this.currentCode = params['code'];
      this._customerService.getNetworkByCode(this.currentCode).then((network: any) => {
        this.network = network;
        this.menuItems.forEach((item) => {
          item.value = this.network?.menu?.includes(item.title);
        });
        if (this.network.theme) {
          this.hasTheme = true;
          this.activeTheme = true;
          if (this.network.theme.app) {
            this.activeApplication = true;
            if (this.network.theme.app.logo) {
              this.activeLogo = true;
            } else {
              this.network.theme.app.logo = {
                light: '',
                dark: '',
                lightSquare: '',
                darkSquare: ''
              }
            }
            if (this.network.theme.app.icon) {
              this.activeIcons = true;
            } else {
              this.network.theme.app.icon = {
                light: '',
                dark: '',
              }
            }
            if (this.network.theme.app.language) {
              this.activeLanguage = true;
            } else {
              this.network.theme.app.language = 'IT';
            }
          } else {
            this.network.theme.app = {
              title: '',
              logo: {
                light: '',
                dark: '',
                lightSquare: '',
                darkSquare: ''
              },
              icon: {
                light: '',
                dark: '',
              },
              language: '',
              sections: []
            };
          }
          if (this.network.theme.colors) {
            this.activeColors = true;
          } else {
            this.network.theme.colors = {
              primary: '#FF8300',
              secondary: '#490E67',
              success: '#00A300',
              info: '#00A3FF',
              warning: '#FF8300',
              danger: '#ff4400',

            }
          }
          if (this.network.theme.app.sections) {
            this.activeSections = true;
            this.hardcodedSections.forEach((section) => {
              section.value = this.network.theme.app.sections.includes(section.title);
            });
          } else {
            this.network.theme.app.sections = [];
          }
        } else {
          this.network.theme = {
            app: {
              title: '',
              logo: {
                light: '',
                dark: '',
                lightSquare: '',
                darkSquare: ''
              },
              icon: {
                light: '',
                dark: '',
              },
              language: '',
              sections: []
            },
            colors: {
              primary: '#FF8300',
              secondary: '#490E67',
              success: '#00A300',
              info: '#00A3FF',
              warning: '#FF8300',
              danger: '#ff4400',

            },
          }
        }
        if (network.menu) {
          this.activeMenus = true;
        }
        this.getWarehouses().then((warehouses) => {
          this.warehouses = warehouses.rows;
          this.warehouses.forEach(warehouse => {
            warehouse.active = false;
          });
          this.network.suppliers.forEach((warehouseId: any) => {
            this.warehouses.forEach((warehouse) => {
              console.log(warehouseId, ' & ', warehouse.id, ' = ', warehouse.id === warehouseId);
              if (warehouse.id === warehouseId) {
                warehouse.active = true;
              }
            });

          });
        });
        this.loaded = Promise.resolve(true);
        console.log('network', this.network);
        console.log('color active', this.activeColors);
      });
    });
  }


  async getWarehouses(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
      }
    );
    query['populateFields'] = ['category', 'supplier'];
    query.pageSize = 60;
    const warehouses: DynamicData = await this._catalogService.getWarehouseList(query)
    warehouses.rows.forEach(warehouse => {
      if (warehouse.active) {
        warehouse.active = 'Si'
      } else {
        warehouse.active = 'No'
      }
      warehouse.actions = warehouse.actions || [];
      warehouse.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/suppliers/' + warehouse.id
      });
    })
    return warehouses;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  save() {
    //swal to confirm the save in italian
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Stai per salvare le modifiche apportate al network. Questa operazione non può essere annullata!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, salva!',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(this.network);
        this.makeAppReadyToSave();
        this.makeIconsReadyToSave();
        this.makeColorsReadyToSave();
        this.makeMenusReadyToSave();
        this.makeSectionsReadyToSave();
        this.makeLanguageReadyToSave();
        this.makeWarehouseReadyToSave();
        if (!this.hasTheme && !this.activeTheme && !this.activeApplication && !this.activeIcons && !this.activeColors && !this.activeSections && !this.activeLanguage) {
          delete this.network.theme;
        }
        console.log('network', this.network);
        this._customerService.saveNetworkbyCode(this.currentCode, this.network).then(() => {
          Swal.fire(
            'Salvato!',
            'Le modifiche sono state salvate con successo.',
            'success'
          );
          this._customerService.getNetworkByCode(this.currentCode).then((network: any) => {
            this.network = network;
            this.menuItems.forEach((item) => {
              item.value = this.network?.menu?.includes(item.title);
            });
            if (this.network.theme) {
              this.hasTheme = true;
              this.activeTheme = true;
              if (this.network.theme.app) {
                this.activeApplication = true;
                if (this.network.theme.app.logo) {
                  this.activeLogo = true;
                } else {
                  this.network.theme.app.logo = {
                    light: '',
                    dark: '',
                    lightSquare: '',
                    darkSquare: ''
                  }
                }
                if (this.network.theme.app.icon) {
                  this.activeIcons = true;
                } else {
                  this.network.theme.app.icon = {
                    light: '',
                    dark: '',
                  }
                }
                if (this.network.theme.app.language) {
                  this.activeLanguage = true;
                } else {
                  this.network.theme.app.language = 'IT';
                }
              } else {
                this.network.theme.app = {
                  title: '',
                  logo: {
                    light: '',
                    dark: '',
                    lightSquare: '',
                    darkSquare: ''
                  },
                  icon: {
                    light: '',
                    dark: '',
                  },
                  language: '',
                  sections: []
                };
              }
              if (this.network.theme.colors) {
                this.activeColors = true;
              } else {
                this.network.theme.colors = {
                  primary: '#FF8300',
                  secondary: '#490E67',
                  success: '#00A300',
                  info: '#00A3FF',
                  warning: '#FF8300',
                  danger: '#ff4400',

                }
              }
              if (this.network.theme.app.sections) {
                this.activeSections = true;
                this.hardcodedSections.forEach((section) => {
                  section.value = this.network.theme.app.sections.includes(section.title);
                });
              } else {
                this.network.theme.app.sections = [];
              }
            } else {
              this.network.theme = {
                app: {
                  title: '',
                  logo: {
                    light: '',
                    dark: '',
                    lightSquare: '',
                    darkSquare: ''
                  },
                  icon: {
                    light: '',
                    dark: '',
                  },
                  language: '',
                  sections: []
                },
                colors: {
                  primary: '#FF8300',
                  secondary: '#490E67',
                  success: '#00A300',
                  info: '#00A3FF',
                  warning: '#FF8300',
                  danger: '#ff4400',

                },
              }
            }
            if (network.menu) {
              this.activeMenus = true;
            }
            this.getWarehouses().then((warehouses) => {
              this.warehouses = warehouses.rows;
              this.warehouses.forEach(warehouse => {
                warehouse.active = false;
              });
              this.network.suppliers.forEach((warehouseId: any) => {
                this.warehouses.forEach((warehouse) => {
                  console.log(warehouseId, ' & ', warehouse.id, ' = ', warehouse.id === warehouseId);
                  if (warehouse.id === warehouseId) {
                    warehouse.active = true;
                  }
                });

              });
            });
            this.loaded = Promise.resolve(true);
            console.log('network', this.network);
            console.log('color active', this.activeColors);
          });
        });
      }
    });

  }

  private makeAppReadyToSave() {
    if (!this.activeApplication) {
      console.log('app not active');
      delete this.network.theme.app.title;
      delete this.network.theme.app.logo;
    }
    console.log(this.network);
  }

  private makeIconsReadyToSave() {
    if (!this.activeIcons) {
      console.log('Icon not active');
      delete this.network.theme.app.icon;
    }
  }

  private makeColorsReadyToSave() {
    if (!this.activeColors) {
      console.log('delete colors');
      delete this.network.theme.colors;
      console.log('network', this.network);
      console.log(this.network);
    }
  }

  private makeMenusReadyToSave() {
    if (!this.activeMenus) {
      console.log('delete menu');
      delete this.network.menu;
      console.log('menu', this.network);
    } else {
      this.network.menu = this.menuItems.filter((item) => item.value).map((item) => item.title);
      console.log('menu', this.network);
    }
  }

  private makeSectionsReadyToSave() {
    if (!this.activeSections) {
      console.log('delete sections');
      delete this.network.theme.app.sections;
      console.log('sections', this.network);

    } else {
      this.network.theme.app.sections = this.hardcodedSections.filter((section) => section.value).map((section) => section.title);
      console.log('sections', this.network);
    }
  }

  private makeLanguageReadyToSave() {
    if (!this.activeLanguage) {
      console.log('delete language');
      delete this.network.theme.app.language;
      console.log('language', this.network);

    }
  }

  deleteNetwork() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Stai per eliminare il network. Questa operazione non può essere annullata!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Si, elimina!',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.isConfirmed) {
        this._customerService.deleteNetworkById(this.network.id, this.currentCode).then(() => {
          Swal.fire(
            'Eliminato!',
            'Il network è stato eliminato con successo.',
            'success'
          ).then(() => {
            this.router.navigate(['/networks']);
          });
        });
      }
    });
  }

  private makeWarehouseReadyToSave() {
    this.network.suppliers = this.warehouses.filter((warehouse) => warehouse.active).map((warehouse) => warehouse.id);
  }
}
