import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CustomersService} from "@core/services/customers.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-user-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})

export class UsersListComponent implements OnInit, OnDestroy {
  @ViewChild('addNewUserModal') addNewUserModal!: TemplateRef<any>;

  // Private
  private _unsubscribeAll: Subject<any>;

  public modifyForm!: UntypedFormGroup;
  public status: string = '';
  public userExist: boolean = false;


  public tableConfig : TableConfig ={} as TableConfig;

  public statuses: any = {
    CREATED: {
      class: 'badge-light-warning',
      label: 'Creato'
    },
    VERIFIED: {
      class: 'badge-light-info',
      label: 'Verificato'
    },
    COMPLETED: {
      class: 'badge-light-primary',
      label: 'Completato'
    },
    SUSPENDED: {
      class: 'badge-light-danger',
      label: 'Sospeso'
    },
    CANCELED: {
      class: 'badge-light-danger',
      label: 'Annullato'
    },
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private modalService: NgbModal,
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('customers-users-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('users')
      .then((table) => {
        this.tableConfig = table;
      })
    this.modifyForm = this._formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      password: ['', Validators.required],
    });
    // get current query params
    const queryParams: any = this._activatedRoute.snapshot.queryParams;
    if (queryParams.page) {
      this.tableConfig.pagination.page = queryParams.page;
    }
    if (queryParams.pageSize) {
      this.tableConfig.pagination.pageSize = queryParams.pageSize;
    }
    if (queryParams.search) {
      this.tableConfig.pagination.search = queryParams.search;
      this.tableConfig.pagination.strictSearch = queryParams.strictSearch !== 'false';
    }
    if (queryParams.sort) {
      const [sortProp, sortDir] = queryParams.sort.split(':');
      this.tableConfig.pagination.sort = {
        prop: sortProp,
        dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
      };
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getUsers(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['network', 'group'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const users: DynamicData = await this._customerService.getUserList(query)
    users.rows.forEach(user => {
      user.links = user.links || {};
      user.links.code = '/users/' + user.code;
      user.actions = user.actions || [];
      user.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/users/' + user.code
      });
    })
    return users
  }

  addUser(addNewUserModal: TemplateRef<any>) {
    return this.modalService.open(addNewUserModal, {
      size: 'normal',
      windowClass: 'modal modal-primary'
    });
  }

  onSubmit(){
    const userData = this.modifyForm.value;
    userData.emailVerified = true;
    userData.phoneVerified = true;
    userData.status = 'CREATED';
    this._customerService.newUser(userData)
      .then((data) => {
        this.modalService.dismissAll();
        this._router.navigate(['/users/' + data.code]);
      })
      .catch((error) => {
        console.error('Error creating user:', error);
      });

    this.getUsers();

  }

  changeStatus($event: any) {

  }
}
