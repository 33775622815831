<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Fornitori</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.supplierList.add')" class="btn btn-primary px-1" (click)="addSupplier(addNewSupplierModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getSuppliers.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>

<ng-template #addNewSupplierModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="newOrder-modal">Aggiungi Fornitore</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Codice: </span>
      <input type="text" class="form-control" [(ngModel)]="newSupplier.code"/>
    </p>
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Nome: </span>
      <input type="text" class="form-control" [(ngModel)]="newSupplier.name"/>
    </p>
    <p class="card-text mb-05 mt-1"><span class="card-text-title">Descrizione: </span>
      <textarea class="form-control" [(ngModel)]="newSupplier.description"></textarea>
    </p>
    <div class="form-check form-check-primary  mt-1">
      <input type="checkbox" class="form-check-input" id="active" [(ngModel)]="newSupplier.active">
      <span class="card-text-title" for="active">Attivo</span>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addNewSupplier()">Aggiungi</button>
  </div>
</ng-template>
