<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Identità</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.permissionsList.add')" class="btn btn-primary px-1" (click)="addIdentity(addNewIdentityModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getIdentities.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [filterGroups]="tableConfig.filterGroups"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>


<ng-template #addNewIdentityModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="newOrder-modal">Aggiungi identità</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-4">
        <label for="application">Applicazione</label>
        <ng-select class="form-group col-12 z-index-1061" [items]="applications" bindLabel="name"
                   [(ngModel)]="selectedApplication" name="application" id="application"
                   [multiple]="false"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [placeholder]="'Seleziona una metodo di pagamento'"
                   (change)="selectApplication($event)"
                   (keyup)="searchApplication($event)"
                   appendTo="body"
                   addTagText="Usa "
                   [addTag]="true">
        </ng-select>
      </div>
      <div class="col-4">
        <label for="business">Azienda</label>
        <ng-select class="form-group col-12 z-index-1061" [items]="businesses" bindLabel="name"
                   [(ngModel)]="selectedBusiness" name="business" id="business"
                   [multiple]="false"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [placeholder]="'Seleziona una azienda'"
                   (change)="selectBusiness($event)"
                   (keyup)="searchBusiness($event)"
                   appendTo="body"
                   addTagText="Usa "
                   [addTag]="true">
        </ng-select>
      </div>
      <div class="col-4">
        <label for="user">Utente</label>
        <ng-select class="form-group col-12 z-index-1061" [items]="users" bindLabel="username"
                   [(ngModel)]="selectedUser" name="user" id="user"
                   [multiple]="false"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [placeholder]="'Seleziona una utente'"
                   (change)="selectUser($event)"
                   (keyup)="searchUser($event)"
                   appendTo="body"
                   addTagText="Usa "
                   [addTag]="true">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)=createIdentity()>Aggiungi</button>
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss('Close click')">Chiudi</button>
  </div>
</ng-template>
