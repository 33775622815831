<div class="row scroll-to-top-btn">
  <div class="col-12">
    <div class="card glassy">
      <div class="card-body p1">
        <div class="row">
          <div class="col-12 d-flex justify-content-left">
<!--            <button class="btn btn-danger btn-block" (click)="backToList()">-->
<!--              <app-svg-icon class="d-inline-block align-middle" name="arrow-left"></app-svg-icon>-->
<!--              <span class="align-middle">Lista Aziende</span>-->
<!--            </button>-->
            <button class="btn btn-info btn-block ml-1" (click)="backToViewPage()">
              <app-svg-icon class="d-inline-block align-middle" name="arrow-left"></app-svg-icon>
              <span class="align-middle">{{ business.code }}</span>
            </button>
            <button class="btn btn-primary btn-block ml-1" (click)="scrollToElement('anagrafica')">
              <app-svg-icon class="d-inline-block align-middle" name="business"></app-svg-icon>
              <span class="align-middle">Anagrafica e contatti</span>
            </button>
            <button class="btn btn-primary btn-block ml-1" (click)="scrollToElement('users')">
              <app-svg-icon class="d-inline-block align-middle" name="user"></app-svg-icon>
              <span class="align-middle">Utenti</span>
            </button>
            <button class="btn btn-primary btn-block ml-1" (click)="scrollToElement('addresses')">
              <app-svg-icon class="d-inline-block align-middle" name="location"></app-svg-icon>
              <span class="align-middle">Indirizzi</span>
            </button>
            <button class="btn btn-primary btn-block ml-1" (click)="scrollToElement('wallets')">
              <app-svg-icon class="d-inline-block align-middle" name="bill"></app-svg-icon>
              <span class="align-middle">Wallets</span>
            </button>
            <button class="btn btn-primary btn-block ml-1" (click)="scrollToElement('payments')">
              <app-svg-icon class="d-inline-block align-middle" name="money"></app-svg-icon>
              <span class="align-middle">Pagamenti</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="cards-container">
  <div class="row mt-3 scroll-space" id="anagrafica">
    <div class="col-12">
      <div class="row px-1">
        <div class="col-8" *ngIf="pagePermissions.personal">
          <div class="card-header py-1">
            <h4 class="card-title">Anagrafica</h4>
          </div>
          <div class="card glassy">
            <div class="card-body p1">
              <div class="row">
                <div class="col-md-6">
                  <label for="business-name" class="form-label">Ragione Sociale</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-name" aria-describedby="basic-addon3"
                           [(ngModel)]="business.name">
                  </div>
                </div>
                <div class="col-md-3">
                  <label for="business-vat" class="form-label">P.IVA</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-vat" aria-describedby="basic-addon3"
                           [(ngModel)]="business.vat">
                  </div>
                </div>
                <div class="col-md-3">
                  <label for="business-fiscalcode" class="form-label">Codice Fiscale</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-fiscalcode" aria-describedby="basic-addon3"
                           [(ngModel)]="business.fiscalCode">
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="business-pec" class="form-label">Pec</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-pec" aria-describedby="basic-addon3"
                           [(ngModel)]="business.pec">
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="business-uniquecode" class="form-label">Codice Univoco</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-uniquecode" aria-describedby="basic-addon3"
                           [(ngModel)]="business.uniqueCode">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label for="business-location-street" class="form-label">Indirizzo</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-location-street"
                           aria-describedby="basic-addon3"
                           [(ngModel)]="business.location.street">
                  </div>
                </div>
                <div class="col-md-1">
                  <label for="business-location-number" class="form-label">N.</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-location-number"
                           aria-describedby="basic-addon3"
                           [(ngModel)]="business.location.number">
                  </div>
                </div>
                <div class="col-md-1">
                  <label for="business-location-zip" class="form-label">CAP</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-location-zip" aria-describedby="basic-addon3"
                           [(ngModel)]="business.location.zip">
                  </div>
                </div>
                <div class="col-md-1">
                  <label for="business-location-province" class="form-label">Provincia</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-location-province"
                           aria-describedby="basic-addon3" [(ngModel)]="business.location.province">
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="business-location-city" class="form-label">Citta</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-location-city" aria-describedby="basic-addon3"
                           [(ngModel)]="business.location.city">
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="business-location-region" class="form-label">Regione</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-location-region"
                           aria-describedby="basic-addon3"
                           [(ngModel)]="business.location.region">
                  </div>
                </div>
                <div class="col-md-1">
                  <label for="business-location-country" class="form-label">Paese</label>
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="business-location-country"
                           aria-describedby="basic-addon3"
                           [(ngModel)]="business.location.country">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <fieldset class="form-group">
                    <!-- Basic Multi select -->
                    <div class="form-group">
                      <span>Stato</span>
                    </div>
                    <ng-select
                      [items]="statuses"
                      [multiple]="false"
                      [closeOnSelect]="true"
                      [searchable]="true"
                      [(ngModel)]="status"
                      (change)="changeStatus($event)"
                      appendTo="body"
                      class="z-index-1061"
                      addTagText="Usa "
                      [addTag]="true">
                    </ng-select>
                    <!--/ Basic Multi select -->
                  </fieldset>
                </div>
                <div class="col-3">
                  <fieldset class="form-group">
                    <!-- Basic Multi select -->
                    <div class="form-group">
                      <span>Gruppo</span>
                    </div>
                    <ng-select
                      [items]="groups"
                      bindLabel="name"
                      [multiple]="false"
                      [closeOnSelect]="true"
                      [searchable]="true"
                      [(ngModel)]="business.group.name"
                      (change)="changeGroup($event)"
                      appendTo="body"
                      class="z-index-1061"
                      addTagText="Usa "
                      [addTag]="true">
                    </ng-select>
                    <!--/ Basic Multi select -->
                  </fieldset>
                </div>
                <div class="col-3">
                  <fieldset class="form-group">
                    <!-- Basic Multi select -->
                    <div class="form-group">
                      <span>Network</span>
                    </div>
                    <ng-select
                      [items]="networks"
                      bindLabel="name"
                      [multiple]="false"
                      [closeOnSelect]="true"
                      [searchable]="true"
                      [(ngModel)]="business.network.name"
                      (change)="changeNetwork($event)"
                      appendTo="body"
                      class="z-index-1061"
                      addTagText="Usa "
                      [addTag]="true">
                    </ng-select>
                    <!--/ Basic Multi select -->
                  </fieldset>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-12 text-right">
                  <button class="btn btn-success btn-block ml-1" (click)="saveDetails()">
                    <app-svg-icon class="d-inline-block align-middle" name="edit"></app-svg-icon>
                    <span class="align-middle">Salva</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 first-row">
          <div class="card-header py-1">
            <h4 class="card-title">Contatti</h4>
          </div>
          <ng-container *ngFor="let contact of business.contacts;  let i = index">
            <div class="card glassy">
              <div class="card-body py-1">
                <div class="row px-1">
                  <div class="col-md-6">
                    <label for="business-contact-name-{{i}}" class="form-label">Nome</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-contact-name-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="contact.name">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="business-contact-phone-{{i}}" class="form-label">Telefono</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-contact-phone-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="contact.phone">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label for="business-contact-email-{{i}}" class="form-label">Email</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-contact-email-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="contact.email">
                    </div>
                  </div>
                  <div class="col-md-12 d-flex flex-row-reverse">
                    <button class="btn btn-success btn-sm " (click)="saveContact(i)">
                      <app-svg-icon class="d-inline-block align-middle" name="edit"></app-svg-icon>
                      <span class="align-middle">Salva</span>
                    </button>
                    <button class="btn btn-danger btn-sm mx-1" (click)="deleteContact(i)">
                      <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
                      <span class="align-middle">Elimina</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="card glassy contact-card card-full-height">
            <div class="card-body py-1">
              <li class="picture font-medium-5" (click)="addContact()">
                <label
                  class="no-margin d-flex full-width full-height align-items-center justify-content-center">
                  <app-svg-icon [name]="'plus'"></app-svg-icon>
                </label>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row px-1 scroll-space" id="users">
    <div class="col-12" *ngIf="pagePermissions.users">
      <div class="card-header py-1">
        <h4 class="card-title">Utenti</h4>
      </div>
      <div class="card glassy" *ngIf="business.users.length < 1">
        <div class="card-body p-1">
          <p>Nessun utente trovato</p>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let user of business.users">
      <div class="col-4">
        <div class="card glassy card-short-height user-card">
          <div class="card-header pb-0">
            <a class="business-name card-text mb-05 d-flex flex-row"
               routerLink="/users/{{user.code}}">
              <app-svg-icon name="link"></app-svg-icon>
              <h4 class="card-title mb-0 font-medium-3 text-primary cursor-pointer">
                <span class="align-middle">{{ user.code }}</span>
              </h4>
            </a>
          </div>
          <div class="card-body pt-025 pb-0">
            <h5
              class="font-medium-4 font-weight-soft-bold mb-05 mt-1 text-uppercase">{{ user.name }} {{ user.surname }}</h5>
            <div class="row mt-075">
              <div class="col-5">
                <h6>Username:</h6>
                <h6>Email:</h6>
                <h6>Telefono:</h6>
                <h6>Creato a:</h6>
                <h6>Ultima modifica:</h6>
              </div>
              <div class="col-7">
                <h6 class="font-weight-normal ">{{ user.username || '-' }}</h6>
                <h6 class="font-weight-normal">{{ user.email || '-' }}</h6>
                <h6 class="font-weight-normal">{{ user.phone || '-' }}</h6>
                <h6 class="font-weight-normal">{{ user.createdAt | date: 'dd/MM/yyyy' || '-' }}</h6>
                <h6 class="font-weight-normal">{{ user.updatedAt | date: 'dd/MM/yyyy' || '-' }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="row px-1 scroll-space" id="addresses">
    <div class="col-12" *ngIf="pagePermissions.addresses">
      <div class="card-header py-1">
        <h4 class="card-title">Indirizzi</h4>
      </div>
      <div class="row">
        <ng-container *ngFor="let address of business.addresses;  let i = index">
          <div class="col-6">
            <div class="card glassy address-card card-full-height">
              <div class="card-body py-1">

                <div class="row">
                  <div class="col-md-5">
                    <label for="business-location-street-{{i}}" class="form-label">Via</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-location-street-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="address.street">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label for="business-location-number-{{i}}" class="form-label">N.</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-location-number-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="address.number">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label for="business-location-zip-{{i}}" class="form-label">CAP</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-location-zip-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="address.zip">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label for="business-location-city-{{i}}" class="form-label">Citta</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-location-city-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="address.city">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label for="business-location-province-{{i}}" class="form-label">Provincia</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-location-province-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="address.province">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label for="business-location-region-{{i}}" class="form-label">Regione</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-location-region-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="address.region">
                    </div>
                  </div>
                  <div class="col-md-1">
                    <label for="business-location-country-{{i}}" class="form-label">Paese</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-location-country-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="address.country">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label for="business-cati-username-{{i}}" class="form-label">Cati username</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-cati-username-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="address.extra.cati.username">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label for="business-cati-password-{{i}}" class="form-label">Cati password</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-cati-password-{{i}}"
                             aria-describedby="basic-addon3"
                             [(ngModel)]="address.extra.cati.password">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label for="business-ovam-{{i}}" class="form-label">Ovam</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="business-ovam-{{i}}" aria-describedby="basic-addon3"
                             [(ngModel)]="address.extra.ovam">
                    </div>
                  </div>
                  <div class="col-md-12 d-flex flex-row-reverse">
                    <button class="btn btn-success btn-sm " (click)="saveAddress(i)">
                      <app-svg-icon class="d-inline-block align-middle" name="edit"></app-svg-icon>
                      <span class="align-middle">Salva</span>
                    </button>
                    <button class="btn btn-danger btn-sm mx-1" (click)="deleteAddress(i)">
                      <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
                      <span class="align-middle">Elimina</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="col-6">
          <div class="card glassy address-card card-full-height">
            <div class="card-body py-1">
              <li class="picture font-medium-5" (click)="addAddress()">
                <label
                  class="no-margin d-flex full-width full-height align-items-center justify-content-center">
                  <app-svg-icon [name]="'plus'"></app-svg-icon>
                </label>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row scroll-space px-1" id="wallets">
    <div class="col-12" *ngIf="pagePermissions.wallets">
      <div class="card-header py-1">
        <h4 class="card-title">Wallets</h4>
      </div>
      <div class="row">
        <ng-container *ngFor="let wallet of business.wallets; let i = index">
          <div class="col-2">
            <div class="card glassy card-short-height card-full-height">
              <div class="card-header pb-0">
                <div class="card-text mb-05 d-flex flex-row">
                  <app-svg-icon class="d-inline-block align-middle text-primary" name="bill"></app-svg-icon>
                  <h4 class="card-title mb-0 font-medium-1 text-primary">
                    <span class="align-middle">{{ wallet.type.label }}</span>
                  </h4>
                </div>
              </div>
              <div class="card-body pt-025 pb-0">
                <div class="row mt-075">
                  <div class="col-5">
                    <h6>Saldo:</h6>
                  </div>
                  <div class="col-7">
                    <h6 class="font-weight-normal ">{{ wallet.balance | price:' ' }}</h6>
                  </div>
                  <div class="col-md-12 d-flex mt-1 justify-content-md-between">
                    <button class="btn btn-primary btn-sm d-flex flex-row text-center justify-content-center"
                            (click)="editWallet(editWalletModal, wallet)">
                      <app-svg-icon class="d-inline-block align-middle" name="edit"></app-svg-icon>
                    </button>
                    <button class="btn btn-primary d-flex flex-row text-center justify-content-center"
                            (click)="showWalletHistory(walletHistory, wallet)">
                      <app-svg-icon class="d-inline-block align-middle" name="bill"></app-svg-icon>
                    </button>
                    <button class="btn btn-danger btn-sm d-flex flex-row text-center justify-content-center"
                            (click)="deleteWallet(i)">
                      <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="col-2">
          <div class="card py-1 card-wallet glassy card-full-height card-short-height">
            <div class="card-body py-1">
              <li class="picture font-medium-5" (click)="addWallet(newWalletModal)">
                <label
                  class="no-margin d-flex full-width full-height align-items-center justify-content-center">
                  <app-svg-icon [name]="'plus'"></app-svg-icon>
                </label>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 scroll-space" *ngIf="pagePermissions.payments" id="payments">
      <div class="card-header py-1">
        <h4 class="card-title">Pagamenti</h4>
      </div>
      <div class="row">
        <ng-container *ngFor="let payment of business.payments; let i = index">
          <div class="col-3">
            <div class="card pt-1 glassy card-full-height card-short-height">
              <div class="row px-1">
                <div class="col-12">
                  <label class="form-label">Etichetta:</label>
                  <input type="text" class="form-control" [(ngModel)]="payment.label">
                </div>
                <div class="col-6 mt-1">
                  <div class="d-flex flex-row">
                    <input type="checkbox" class="form-check-input custom-check-box"
                           [(ngModel)]="payment.active">
                    <label class="ml-05 font-medium-1">Attivo</label>
                  </div>
                </div>
                <div class="col-6 mt-1">
                  <div class="d-flex flex-row">
                    <input type="checkbox" class="form-check-input custom-check-box"
                           [(ngModel)]="payment.deleted">
                    <label class="ml-05 font-medium-1">Eliminato</label>
                  </div>
                </div>
                <div class="col-12 mt-1">
                  <label class="form-label">Metodo:</label>
                  <ng-select class="form-group col-12" [items]="paymentMethods" bindLabel="name" bindValue="id"
                             [(ngModel)]="payment.method" name="paymentMethod"
                             [multiple]="false"
                             [closeOnSelect]="true"
                             [searchable]="true"
                             [placeholder]="'Seleziona una metodo di pagamento'"
                             (keyup)="searchMethod($event)"
                             [ngModel]="payment.method"
                             appendTo="body"
                             class="z-index-1061"
                             addTagText="Usa "
                             [addTag]="true">
                  </ng-select>
                </div>
                <div class="col-12 mt-1">
                  <label class="form-label">IBAN: <app-svg-icon name="copy" class="text-primary ml-05 cursor-pointer" (click)="copyToClipboard(payment.iban)"></app-svg-icon></label>
                  <input type="text" class="form-control" [(ngModel)]="payment.iban" #ibanInput
                         [ngClass]="{'valid-iban': isValidIBAN(payment?.iban, payment) && (payment?.iban !== ''),'invalid-iban': !isValidIBAN(payment?.iban, payment) && (payment?.iban !== '')}">
                </div>

                <div class="col-6 mt-1">
                  <label class="form-label">ABI: <app-svg-icon name="copy" class="text-primary ml-05 cursor-pointer" (click)="copyToClipboard(payment.abi)"></app-svg-icon></label>
                  <input type="text" class="form-control" [(ngModel)]="payment.abi">
                </div>
                <div class="col-6 mt-1">
                  <label class="form-label">CAB: <app-svg-icon name="copy" class="text-primary ml-05 cursor-pointer" (click)="copyToClipboard(payment.cab)"></app-svg-icon></label>
                  <input type="text" class="form-control" [(ngModel)]="payment.cab">
                </div>
                <div class="col-12 mt-1">
                  <label class="form-label">BIC: <app-svg-icon name="copy" class="text-primary ml-05 cursor-pointer" (click)="copyToClipboard(payment.iban)"></app-svg-icon></label>
                  <input type="text" class="form-control" [(ngModel)]="payment.bic">
                </div>
                <div class="col-6 mt-1">
                  <label class="form-label">Totale:</label>
                  <input type="text" class="form-control" [(ngModel)]="payment.total">
                </div>
                <div class="col-6 mt-1">
                  <label class="form-label">Cashback:</label>
                  <input type="text" class="form-control" [(ngModel)]="payment.cashback">
                </div>
                <div class="col-md-12 d-flex flex-row-reverse mt-1">
                  <button class="btn btn-success btn-sm " (click)="savePayment(i)">
                    <app-svg-icon class="d-inline-block align-middle" name="edit"></app-svg-icon>
                    <span class="align-middle">Salva</span>
                  </button>
                  <button class="btn btn-danger btn-sm mx-1" (click)="deletePayment(i)">
                    <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
                    <span class="align-middle">Elimina</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="col-3">
          <div class="card py-1 card-payment glassy card-full-height card-short-height">
            <div class="card-body py-1">
              <li class="picture font-medium-5" (click)="addPayment()">
                <label
                  class="no-margin d-flex full-width full-height align-items-center justify-content-center">
                  <app-svg-icon [name]="'plus'"></app-svg-icon>
                </label>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #newWalletModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="new-wallet-modal">Storico</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="text-gray font-weight-bold">Tipo</div>
        <ng-select class="form-group col-12" [items]="walletTypes" bindLabel="label" bindValue="id"
                   [(ngModel)]="newWallet.type.label" name="walletType"
                   [multiple]="false"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [placeholder]="'Seleziona un tipo di wallet'"
                   (keyup)="searchWalletType($event)"
                   [ngModel]="newWallet.type"
                   appendTo="body"
                   class="z-index-1061"
                   addTagText="Usa "
                   [addTag]="true">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onSubmitWallet()">Crea</button>
    <button type="button" class="btn btn-error" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>

<ng-template #walletHistory let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="show-wallet-history-modal">Storico</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-2">
        <div class="text-gray font-weight-bold">Codice</div>
      </div>
      <div class="col-4">
        <div class="text-gray font-weight-bold">Causale</div>
      </div>
      <div class="col-1 d-flex justify-content-end">
        <div class="text-gray font-weight-bold">Quantità</div>
      </div>
      <div class="col-1 d-flex justify-content-end">
        <div class="text-gray font-weight-bold">Da</div>
      </div>
      <div class="col-1 d-flex justify-content-end">
        <div class="text-gray font-weight-bold">A</div>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <div class="text-gray font-weight-bold">Data</div>
      </div>
      <div class="col-1 d-flex justify-content-end">
        <div class="text-gray font-weight-bold">Ora</div>
      </div>
      <ng-container *ngFor="let history of walletHistoryArray">
        <div class="col-2">
          <div class="text-gray">{{ history.code }}</div>
        </div>
        <div class="col-4">
          <div class="text-gray">{{ history.reason }}</div>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <div class="text-gray">{{ history.amount | price:' ' }}</div>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <div class="text-gray">{{ history.from | price:' ' }}</div>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <div class="text-gray">{{ history.to | price:' ' }}</div>
        </div>
        <div class="col-2 d-flex justify-content-end">
          <div class="text-gray">{{ history.createdAt | date:'dd/MM/yyyy' }}</div>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <div class="text-gray">{{ history.createdAt | date:'HH:mm' }}</div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-error" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>

<ng-template #editWalletModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="changePassword-modal">Modifica wallet </h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- form to add a user -->
    <div class="mb-1 row">
      <div class="col-12 form-group">
        <label class="label" for="charge">Causale</label>
        <input id="causale" class="form-control" [(ngModel)]="selectedWallet.reason">
      </div>
      <div class="col-4 form-group">
        <label class="label" for="charge">Attuale</label>
        <div>{{ selectedWallet.balance }}</div>
      </div>
      <div class="col-4 form-group">
        <label class="label" for="charge">Finale</label>
        <input id="total" class="form-control" [(ngModel)]="selectedWallet.total"
               (keyup)="onChangeWallet('total', $event)" (change)="onChangeWallet('total', $event)">
      </div>
      <div class="col-4 form-group">
        <label class="label" for="charge">Carica</label>
        <input id="charge" class="form-control" [(ngModel)]="selectedWallet.charge"
               (keyup)="onChangeWallet('charge', $event)" (change)="onChangeWallet('charge', $event)">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onSubmit()">Carica</button>
    <button type="button" class="btn btn-error" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>
