<div class="row" *ngIf="loaded | async">
  <div class="col-12 col-sm-6 col-md-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Magazine {{ warehouse.name }}</h5>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Nome: </span>
          <input type="text" class="form-control" [(ngModel)]="warehouse.name"/>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Codice: </span>
          <input type="text" class="form-control" [(ngModel)]="warehouse.code"/>
        </p>
        <p class="card-text mb-05 mt-1"><span class="card-text-title">Etichetta: </span>
          <input type="text" class="form-control" [(ngModel)]="warehouse.label"/>
        </p>
        <input type="checkbox" class="btn-check mt-2" id="btn-check-outlined-{{warehouse.code}}" autocomplete="off"
               (change)="changeWarehouse($event)" [checked]="warehouse.active">
        <label class="btn btn-outline-primary mt-2 btn-checkbox full-width" for="btn-check-outlined-{{warehouse.code}}">
          <span *ngIf="warehouse.active">Abilitato</span>
          <span *ngIf="!warehouse.active">Disabilitato</span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-4 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column overflow-y-scroll overflow-x-hidden">
        <h5 class="card-title d-flex flex-row">Avvertimenti
          <button type="button" class="btn btn-primary btn-sm p-025 font-small-2 ml-05"
                  (click)="addWarning()">
            <app-svg-icon class="d-inline-block align-middle no-margin" name="add"></app-svg-icon>
          </button>
        </h5>
        <ng-container *ngFor="let warning of warehouse.warnings; let i = index">
          <span class="text-primary mt-1">Avvertimento {{ i + 1 }}:</span>
          <div class="row">
            <div class="col-12">
              <textarea class="form-control" [(ngModel)]="warning.description"></textarea>
            </div>
            <div class="col-5 mt-1">
              <div class="d-flex flex-column">
                <label>Tipo</label>
                <ng-select
                  [items]="warningTypes"
                  bindLabel="label"
                  bindValue="type"
                  [(ngModel)]="warning.type"
                  placeholder="Tipo">
                </ng-select>
              </div>
            </div>
            <div class="col-3 mt-1">
              <div class="d-flex flex-row">
                <input type="checkbox" class="form-check-input mr-05 mt-2"
                       [(ngModel)]="warning.type"/>
                <label class="form-check-label mt-2">Attivo</label>
              </div>
            </div>
            <div class="col-4 mt-1">
              <button type="button" class="btn btn-danger font-small-2 mt-15"
                      (click)="removeWarning(i)">
                <app-svg-icon class="d-inline-block align-middle no-margin" name="delete"></app-svg-icon>
                Rimuovi
              </button>
            </div>
            <div class="col-12 mt-1">
              <ngb-alert [type]="warning.type.toLowerCase()" [dismissible]="false" *ngIf="warning.active"
                         class="custom-alert">
                <div class="alert-body custom-alert-font">
                  {{ warning.description }}
                </div>
              </ngb-alert>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title ">Commenti</h5>
        <div class="comment-container">
          <ng-container *ngIf="comments.length > 0">
            <ul class="list-unstyled">
              <li *ngFor="let comment of comments" class="mb-1">
                <div class="">
                  <div class="comment-text">
                    <p class="card-text mb-0">
                      {{ comment.text }}
                    </p>
                  </div>
                  <div class="comment-user mt-05">
                    <span class="avatar mr-1">
                      <img
                        [src]="comment.user.avatar || 'assets/images/avatars/default-avatar.png'"
                        (error)="comment.user.avatar = 'assets/images/avatars/default-avatar.png'"
                        alt="avatar"
                        class="rounded-circle"
                        width="18"
                        height="18"
                      >
                    </span>
                    <span class="font-small-2 font-weight-bold mr-1">{{ comment.user.name }}</span>
                    <span class="font-small-2">{{ comment.createdAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="comments?.length == 0">
            <p>Nessun commento trovato</p>
          </ng-container>
        </div>
      </div>
      <div class="card-footer">
        <div class="form-group">
          <div class="input-group">
            <textarea class="form-control" id="comment" rows="2"
                      [(ngModel)]="commentText" name="comment"
                      (keydown.control.enter)="addComment()"></textarea>
            <button class="btn btn-outline-primary px-1" (click)="addComment()">
              <app-svg-icon class="d-inline-block align-middle" name="send"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-2 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Azioni</h5>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-primary btn-block" (click)="saveWarehouse()">
            <app-svg-icon name="save"></app-svg-icon>
            <span class="buttons-text"> Salva </span>
          </button>
        </div>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-danger btn-block" (click)="deleteWarehouse()">
            <app-svg-icon name="delete"></app-svg-icon>
            <span class="buttons-text"> Elimina </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center mt-1">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title d-flex flex-row">Regolo di spedizione</h5>
        <dynamic-table
          [dataRetriever]="getShippingRules.bind(this)"
          [columns]="tableConfig.columns"
          [filterGroups]="tableConfig.filterGroups"
          [page]="tableConfig.pagination.page"
          [pageSize]="tableConfig.pagination.pageSize"
          [sort]="tableConfig.pagination.sort"
          [search]="tableConfig.pagination.search"
          [messages]="tableConfig.messages"
        >
        </dynamic-table>
      </div>
    </div>
  </div>
</div>
