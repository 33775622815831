
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'json'
})
export class JsonPipe implements PipeTransform {
  /**
   * Transform
   *
   *
   * @returns {any}
   * @param value
   */
  transform(value: any): string {
    return JSON.stringify(value, null, 2);
  }
}
