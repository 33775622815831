import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApplicationService } from "@core/services/application.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ShopService } from "@core/services/shop.service";
import { DynamicData } from "@core/types/dynamic-table/dynamic-data";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import {CatalogService} from "../../../../@core/services/catalog.service";
import {CustomersService} from "../../../../@core/services/customers.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";

@Component({
  selector: 'app-payments-view',
  templateUrl: './payments-view.component.html',
  styleUrls: ['./payments-view.component.scss']
})

export class PaymentsViewComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;
  @ViewChild("paymentsCell", {static: true})
  private paymentsCellTemplate?: TemplateRef<any>;

  public linkCopied:boolean = false;
  private currentId: any;
  public payment!: any;
  public paymentMethods!: DynamicData;
  public paymentMethodsArray!: any[];

  public paymentStatus: any = {
    APPROVED: {
      class: 'badge-light-primary',
      label: 'Approvato'
    },
    PENDING: {
      class: 'badge-light-warning',
      label: 'In attesa'
    },
    VERIFIED: {
      class: 'badge-light-info',
      label: 'Verificato'
    },
    PAID: {
      class: 'badge-light-success',
      label: 'Pagato'
    },
    SUSPENDED: {
      class: 'badge-light-danger',
      label: 'Sospeso'
    },
    CANCELED: {
      class: 'badge-light-danger',
      label: 'Annullato'
    },
    FAILED: {
      class: 'badge-light-danger',
      label: 'Fallito'
    },
    REJECTED: {
      class: 'badge-light-danger',
      label: 'Rifiutato'
    }
  };



  public tableConfig: TableConfig = {} as TableConfig;


  private networks: any [] = [];
  private groups: any [] = [];

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _route: ActivatedRoute,
    private modalService: NgbModal,
    private _customerService: CustomersService,
    private _catalogService: CatalogService,
    private _dynamicTableService: DynamicTableService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-payments-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('orders')
      .then((table) => {
        this.tableConfig = table;
        this.generateFilterOptions();
      })
    this._route.params.pipe(takeUntil(this._unsubscribeAll)).subscribe((params: any) => {
      this.currentId = params.id;
    });
    this.payment = this._shopService.getSinglePaymentFromId(this.currentId)
      .then((response) => {
        if (!response) {
          return
        }
        this.payment = response as DynamicData
      })
      .catch((error) => {
      })
     this._shopService.getPaymentMethodList()
      .then((response) => {
        if (!response) {
          return
        }
        this.paymentMethods = response as DynamicData
      })
      .catch((error) => {
      })
    this.paymentMethodsArray = Object.values(this.paymentMethods);
    this.payment.methodName = this.paymentMethodsArray.find((method: any) => method._id === this.payment.method).name;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  changeStatus($event: any) {
    this.payment.status = $event.target.value;
  }

  openModifyModal(modifyModal: TemplateRef<any>) {
    this.modalService.open(modifyModal, {size: 'md', backdrop: 'static', windowClass: 'modal modal-primary'});
  }

  approvePayment() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Questa operazione non potrà essere annullata!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#790af8',
      cancelButtonColor: '#ff740f',
      confirmButtonText: 'Si, confermo!',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.isConfirmed) {
        this.payment.status = 'APPROVED';
        this._shopService.savePayment(this.currentId, this.payment)
          .then((response) => {
            if (!response) {
              return
            }
            this.payment = response as DynamicData
          })
          .catch((error) => {
          })
      }
    })
  }

  rejectPayment() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Questa operazione non potrà essere annullata!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#790af8',
      cancelButtonColor: '#ff740f',
      confirmButtonText: 'Si, confermo!',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.isConfirmed) {
        this.payment.status = 'REJECTED';
        this._shopService.savePayment(this.currentId, this.payment)
          .then((response) => {
            if (!response) {
              return
            }
            this.payment = response as DynamicData
          })
          .catch((error) => {
          })
      }
    })
  }

  deletePayment() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Questa operazione non potrà essere annullata!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#790af8',
      cancelButtonColor: '#ff740f',
      confirmButtonText: 'Si, confermo!',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.isConfirmed) {

      }
    })
  }

  updateBusinessFilter($event: any, filter: any) {
    this._customerService.getBusinessList({
      search: $event.target.value,
      pageSize: 25,
    }).then((response: DynamicData) => {
      filter.options = response.rows.map(business => {
        return {
          label: business.name,
          value: business.id
        }
      })
    })
  }

  generateFilterOptions() {
    this.tableConfig.filterGroups.forEach(filterGroup => {
      filterGroup.filters.forEach((filter: any) => {
        switch (filter.retriever) {
          case 'networks.list':
            this._customerService.getNetworkList({
              pageSize: 50,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(network => {
                return {
                  label: network.name,
                  value: network.id
                }
              })
            })
            break;
          case 'warehouses.list':
            this._catalogService.getWarehouseList({
              pageSize: 100,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(group => {
                return {
                  label: group.name,
                  value: group.id
                }
              })
            })
            break;
          case 'businesses.list':
            this._customerService.getBusinessList({
              pageSize: 50,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(business => {
                return {
                  label: business.name,
                  value: business.id
                }
              })
            })
            break;
        }
      })
    })
    this._customerService.getGroups()
      .then((response: any) => {
        this.groups = response.rows;
      });
    this._customerService.getNetworks()
      .then((response: any) => {
        this.networks = response.rows;
      });

  }

  async getOrders(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['business', 'network', 'warehouse', 'payments.payment'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    query.filters = {
      payments: {
        payment: {
          $oid: this.currentId
        }
      }
    };
    const orders: DynamicData = await this._shopService.getOrderList(query)
    orders.rows.forEach(order => {
      order.links = order.links || {};
      order.links.code = '/orders/' + order.code;
      order.products = order.products.reduce((acc: number, product: any) => {
        acc += product.total
        return acc
      }, 0);
      order.actions = order.actions || [];
      order.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/orders/' + order.code
      });
    })
    return orders
  }


  copyToClipboard() {
    this.linkCopied = true;
    setTimeout(() => this.linkCopied = false, 500);
  }
}
