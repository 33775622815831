<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Tabella dinamica</h1>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getTables.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [filterGroups]="tableConfig.filterGroups"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>

