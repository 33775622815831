<div class="row" *ngIf="loaded | async">
  <h5 class="card-title mb-1 col-12"> Network {{ currentCode }}</h5>
  <div class="col-3">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Logo</h4>
      </div>
      <div class="card-body p-1">
        <div class="row">
          <div class="col-12 px-4 py-2 text-center">
            <img class="img-fluid main-picture" *ngIf="network.logo" src="{{network.logo}}" alt="brand image">
            <img class="img-fluid main-picture" *ngIf="!network.logo"
                 src="assets/images/catalog/default-product-picture.png" alt="Product image">
          </div>
          <div class="col-1 px-1 position-relative">
            <label for="logo-source" class="pt-05">URL</label>
          </div>
          <div class="col-11 px-1">
            <input type="text" id="logo-source" class="form-control" [(ngModel)]="network.logo">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="card glassy card-full-height card-networks">
      <div class="card-header pb-0">
        <h4 class="card-title">Network</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <label for="code">Code</label>
            <input type="text" id="code" class="col-12 form-control mb-1" [(ngModel)]="network.code">
          </div>
          <div class="col-6">
            <label for="name">Name</label>
            <input type="text" id="name" class="col-12 form-control mb-1" [(ngModel)]="network.name">
          </div>
          <div class="col-12">
            <label class="col-12" for="origin">Origin</label>
            <input type="text" id="origin" class="col-12 form-control mb-1" [(ngModel)]="network.origin">
          </div>
          <div class="col-12">
            <label for="description">Description</label>
            <textarea id="description" class="col-12 form-control mb-1" [(ngModel)]="network.description"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="card glassy card-full-height card-networks">
      <div class="card-header pb-0">
        <h4 class="card-title">Magazzini</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <ng-container *ngFor="let warehouse of warehouses">
            <div class="col-4">
              <div class="d-flex flex-row">
                <input type="checkbox" id="supplier-{{warehouse.id}}" class="form-check-input custom-check-box"
                       [(ngModel)]="warehouse.active">
                <label for="supplier-{{warehouse.id}}" class="ml-1 pt-05">{{ warehouse.label }} ({{warehouse.name}})</label>
              </div>
            </div>
          </ng-container>
        </div>
        </div>
      </div>
  </div>
  <div class="col-2">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Azioni</h4>
      </div>
      <div class="card-body p-1">
        <button class="col-12 btn btn-primary" (click)="save()">
          <app-svg-icon name="save"></app-svg-icon>
          salva
        </button>
        <button class=" col-12 btn btn-danger btn-block mt-1" (click)="deleteNetwork()">
          <app-svg-icon name="delete"></app-svg-icon>
          Elimina
        </button>
      </div>
    </div>
  </div>
</div>
<!--<div class="d-flex flex-row">-->
<!--  <input type="checkbox" id="themeActive" class="form-check-input custom-check-box"-->
<!--         [(ngModel)]="activeTheme">-->
<!--  <h5 class="card-title mb-1 col-12 ml-1"> Theme</h5>-->
<!--</div>-->
<div class="row align-items-start" *ngIf="loaded | async">
  <div class="col-3">
    <div class="card glassy card-full-height network-cards">
      <div class="card-header pb-0">
        <div class="d-flex flex-row">
          <input type="checkbox" id="applicationActive" class="form-check-input custom-check-box"
                 [(ngModel)]="activeApplication">
          <h4 class="card-title ml-1">Applicazione</h4>
        </div>
      </div>
      <div class="card-body p-1 network-cards" [ngClass]="{'disabled-row': !activeApplication}">
        <label for="title" class="col-12">Titolo</label>
        <input *ngIf="network.theme" type="text" id="title" class="form-control mb-1 col-12" [(ngModel)]="network.theme.app.title">

        <div class="col-12">
          <hr>
        </div>

        <label for="logo-light" class="col-12">logo chiaro</label>
        <input *ngIf="network.theme" type="text" id="logo-light" class="form-control col-12" [(ngModel)]="network.theme.app.logo.light">
        <div class="logo-container">
          <img *ngIf="network.theme" class="logo-gallery" src="{{network.theme.app.logo.light}}" alt="light logo">
        </div>

        <div class="col-12">
          <hr>
        </div>

        <label for="logo-dark" class="col-12">logo scuro</label>
        <input *ngIf="network.theme" type="text" id="logo-dark" class="form-control col-12" [(ngModel)]="network.theme.app.logo.dark">
        <div class="logo-container">
          <img *ngIf="network.theme" class="logo-gallery" src="{{network.theme.app.logo.dark}}">
        </div>

        <div class="col-12">
          <hr>
        </div>

        <label for="logo-light-square" class="col-12">logo chiaro quadrato</label>
        <input *ngIf="network.theme" type="text" id="logo-light-square" class="form-control col-12"
               [(ngModel)]="network.theme.app.logo.lightSquare">
        <div class="logo-container">
          <img *ngIf="network.theme" class="logo-gallery" src="{{network.theme.app.logo.lightSquare}}">
        </div>


        <div class="col-12">
          <hr>
        </div>

        <label for="logo-dark-square" class="col-12">logo scuro quadrato</label>
        <input *ngIf="network.theme" type="text" id="logo-dark-square" class="form-control col-12"
               [(ngModel)]="network.theme.app.logo.darkSquare">
        <div class="logo-container">
          <img *ngIf="network.theme" class="logo-gallery" src="{{network.theme.app.logo.darkSquare}}">
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="card glassy card-full-height network-cards">
      <div class="card-header pb-0">
        <div class="d-flex flex-row">
          <input type="checkbox" id="iconsActive" class="form-check-input custom-check-box"
                 [(ngModel)]="activeIcons">
          <h4 class="card-title ml-1">Icone</h4>
        </div>
      </div>
      <div class="card-body p-1 network-cards" [ngClass]="{'disabled-row': !activeIcons}">
        <label for="icon-light" class="col-12">Icona chiaro</label>
        <input *ngIf="network.theme" type="text" id="icon-light" class="form-control col-12" [(ngModel)]="network.theme.app.icon.light">
        <div class="logo-container">
          <img *ngIf="network.theme" class="logo-gallery" src="{{network.theme.app.icon.light}}">
        </div>

        <div class="col-12">
          <hr>
        </div>

        <label for="icon-dark" class="col-12">Icona scuro</label>
        <input *ngIf="network.theme" type="text" id="icon-dark" class="form-control col-12" [(ngModel)]="network.theme.app.icon.dark">
        <div class="logo-container">
          <img *ngIf="network.theme" class="logo-gallery" src="{{network.theme.app.icon.dark}}">
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="card glassy card-full-height network-cards">
      <div class="card-body p-1 network-cards">
        <div class="d-flex flex-row mb-1">
          <input  type="checkbox" id="colorsActive" class="form-check-input custom-check-box"
                 [(ngModel)]="activeColors">
          <h4 class="card-title ml-1">Colori</h4>
        </div>
        <div class="row" [ngClass]="{'disabled-row': !activeColors}">
          <input *ngIf="network.theme" type="color" id="primary-color" class="form-control col-1 mb-2 color-form-control ml-1"
                 [(ngModel)]="network.theme.colors.primary">
          <label for="primary-color" class="col-3 color-form-label">Primario</label>

          <input *ngIf="network.theme" type="color" id="secondary-color" class="form-control col-1 mb-2 color-form-control"
                 [(ngModel)]="network.theme.colors.secondary">
          <label for="secondary-color" class="col-3 color-form-label">Secondario</label>

          <input *ngIf="network.theme" type="color" id="success-color" class="form-control col-1 mb-2 color-form-control"
                 [(ngModel)]="network.theme.colors.success">
          <label for="success-color" class="col-3 color-form-label">Successivo</label>

          <input *ngIf="network.theme" type="color" id="info-color" class="form-control col-1 mb-2 color-form-control ml-1"
                 [(ngModel)]="network.theme.colors.info">
          <label for="info-color" class="col-3 color-form-label">Informazione</label>


          <input *ngIf="network.theme" type="color" id="warning-color" class="form-control col-1 mb-2 color-form-control"
                 [(ngModel)]="network.theme.colors.warning">
          <label for="warning-color" class="col-3 color-form-label">Avvertimento</label>

          <input *ngIf="network.theme" type="color" id="danger-color" class="form-control col-1 mb-2 color-form-control"
                 [(ngModel)]="network.theme.colors.danger">
          <label for="danger-color" class="col-3 color-form-label">Pericolo</label>
        </div>
        <div class="col-12">
          <hr>
        </div>

        <div class="d-flex flex-row mb-1">
          <input *ngIf="network.theme" type="checkbox" id="menusActive" class="form-check-input custom-check-box"
                 [(ngModel)]="activeMenus">
          <h4 class="card-title ml-1">Voci del menu</h4>
        </div>
        <div *ngIf="menuItems" class="row" [ngClass]="{'disabled-row': !activeMenus}">
          <ng-container *ngFor="let item of menuItems">
            <div class="col-4">
              <div class="d-flex flex-row mt-05">
                <input type="checkbox" id="menu-item-{{item.name}}" class="form-check-input custom-check-box"
                       [(ngModel)]="item.value">
                <label for="menu-item-{{item.id}}" class="ml-05 menu-items-checkbox">{{ item.title }} </label>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="col-12">
          <hr>
        </div>

        <div class="d-flex flex-row mb-1">
          <input type="checkbox" id="languageActive" class="form-check-input custom-check-box"
                 [(ngModel)]="activeLanguage">
          <h4 class="card-title ml-1">Lingua</h4>
        </div>
        <div class="row" [ngClass]="{'disabled-row': !activeLanguage}">
          <div *ngIf="network.theme" class="col-12">
            <ng-select id="language"
                       [items]="languages"
                       bindLabel="title"
                       bindValue="value"
                       placeholder="Seleziona una lingua"
                       [(ngModel)]="network.theme.app.language"
                       [closeOnSelect]="true"
                       [searchable]="true"
                       [multiple]="false">

            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="card glassy card-full-height network-cards">
      <div class="card-body p-1 network-cards">
        <div class="d-flex flex-row mb-1">
          <input type="checkbox" id="sectionsActive" class="form-check-input custom-check-box"
                 [(ngModel)]="activeSections">
          <h4 class="card-title ml-1">Sezioni</h4>
        </div>
        <div class="row">
          <div class="col-12" [ngClass]="{'disabled-row': !activeSections}">
            <div class="row">
              <ng-container *ngFor="let item of hardcodedSections">
                <div class="d-flex flex-row mt-05 col-6">
                  <input type="checkbox" id="section-{{item.key}}" class="form-check-input custom-check-box"
                         [(ngModel)]="item.value">
                  <label for="section-{{item.key}}" class="ml-05 menu-items-checkbox">{{ item.key }} </label>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
