import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NetworksListComponent } from "./networks-list/networks-list.component";
import { NetworksViewComponent } from "./networks-view/networks-view.component";
import { CoreModule } from "@core/components/core.module";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";

const routes: Routes = [
  {
    path: 'networks',
    component: NetworksListComponent,
    data: { animation: 'NetworksListComponent' }
  },
  {
    path: 'networks/:code',
    component: NetworksViewComponent,
    data: { animation: 'NetworksViewComponent' }
  },
];

@NgModule({
  declarations: [
    NetworksListComponent,
    NetworksViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    FormsModule,
    NgSelectModule
  ],
  providers: []
})
export class NetworksModule {
}
