import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Menu } from "@core/types/app/menu";
import { ApplicationService } from "@core/services/application.service";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  encapsulation: ViewEncapsulation.None
})

export class MenuItemComponent {
  @Input()
  public item: Menu = {} as Menu;
  public showChildren: boolean = false;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _applicationService: ApplicationService
  ) {
    this._unsubscribeAll = new Subject();
    this._applicationService.menu.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (!this.showChildren && this.item.children && this.item.children.length > 0) {
          this.showChildren = this.item.children.some(child => child.selected)
        }
      })
  }
}
