import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./dashboard/dashboard.component";

import {ProductsModule} from "./catalog/products/products.module";
import {BrandsModule} from "./catalog/brands/brands.module";
import {CategoriesModule} from "./catalog/categories/categories.module";
import {BusinessesModule} from "./customers/businesses/businesses.module";
import {GroupsModule} from "./customers/groups/groups.module";
import {ErrorsModule} from "./errors/errors.module";
import {SuppliersModule} from "./catalog/suppliers/suppliers.module";
import {UsersModule} from "./customers/users/users.module";
import {SessionsModule} from "./customers/sessions/sessions.module";
import {NetworksModule} from "./customers/networks/networks.module";
import {OrdersModule} from "./shop/orders/orders.module";
import {CartsModule} from "./shop/carts/carts.module";
import {PaymentsModule} from "./shop/payments/payments.module";
import {ReturnsModule} from "./shop/returns/returns.module";
import {InvoicesModule} from "./shop/invoices/invoices.module";
import {PaymentMethodsModule} from "./shop/payment-methods/payment-methods.module";
import {BannersModule} from "./cms/banners/banners.module";
import {AgreementsModule} from "./cms/agreements/agreements.module";
import {ShippingRulesModule} from "./catalog/shipping-rules/shipping-rules.module";
import {QuotesModule} from "./shop/quotes/quotes.module";
import {DiscountsModule} from "./shop/discounts/discounts.module";
import {DeliveriesModule} from "./shop/deliveries/deliveries.module";
import {AuthenticationModule} from "./authentication/authentication.module";
import {AuthGuard} from "./authentication/AuthGuard";
import {MailsModule} from "./mails/mails.module";
import {PermissionsModule} from "./super-admin/permissions/permissions.module";
import {WarehousesModule} from "./catalog/warehouses/warehouses.module";
import {PrimesModule} from "./shop/primes/primes.module";
import {AlertsModule} from "./cms/alerts/alerts.module";
import { WalletsModule} from "./shop/wallets/wallets.module";
import {TablesModule} from "./super-admin/tables/tables.module";
import{MenuItemsModule} from "./super-admin/menuItems/menuItems.module";
import {ApplicationsModule} from "./super-admin/applicationns/applications.module";

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    path: '',
    component: DashboardComponent,
    data: {
      animation: 'DashboardComponent',
      permission: 'admin.page.dashboard',
      fallback: '/orders'
    }
  },
  {
    canActivate: [],
    path: 'auth',
    loadChildren: () => AuthenticationModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => WarehousesModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => TablesModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => MenuItemsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => ApplicationsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => AlertsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => WalletsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => PrimesModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => ProductsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => BrandsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => CategoriesModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => SuppliersModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => BusinessesModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => UsersModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => MailsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => SessionsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => NetworksModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => GroupsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => OrdersModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => CartsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => PaymentsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => ReturnsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => InvoicesModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => PaymentMethodsModule
  },
  {
    path: '',
    loadChildren: () => DiscountsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => DeliveriesModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => BannersModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => AgreementsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => ShippingRulesModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => PermissionsModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => QuotesModule
  },
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => ErrorsModule
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
