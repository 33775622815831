import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Observable, Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CustomersService} from "@core/services/customers.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {Menu, MenuGroup} from "../../../../@core/types/app/menu";
import Swal2 from "sweetalert2";
import {TableColumn, TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import notify from "devextreme/ui/notify";


@Component({
  selector: 'app-applications-view',
  templateUrl: './applications-view.component.html',
  styleUrls: ['./applications-view.component.scss']
})

export class ApplicationsViewComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public currentId: string = '';
  public tmpPermissions: string[] = [];
  public searchPermission: string = '';
  public loaded: Promise<boolean> = Promise.resolve(false);
  public application: any = {};
  public newPermission: string = '';
  public newUser: string = '';
  public statuses: string[] = ['Active', 'Inactive', 'Pending', 'Rejected', 'Approved'];
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------


  constructor(
    private modalService: NgbModal,
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private _dynamicTableService: DynamicTableService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('super-applications-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.currentId = this._activatedRoute.snapshot.paramMap.get('id') || '';
    this._applicationService.getApplication(this.currentId).then((data: any) => {
      this.application = data;
      this.application.permissions.sort();
      this.tmpPermissions = this.application.permissions;
      this.application.defaultPermissions.sort();
      this.application.anonymousPermissions.sort();
      this.application.menu = this.convertObjectToJSON(this.application.menu);
      this.application.theme = this.convertObjectToJSON(this.application.theme);
      this.loaded = Promise.resolve(true);
    });
  }


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  convertObjectToJSON(object: any): string {
    return JSON.stringify(object, null, 2);
  }

  reverseJSONToObject(jsonString: string): any {
    return JSON.parse(jsonString);
  }


  saveApplication() {
    this.application.menu = this.reverseJSONToObject(this.application.menu);
    this.application.theme = this.reverseJSONToObject(this.application.theme);
    console.log(this.application);
    this._applicationService.updateApplication(this.application).then(() => {
      this.application.menu = this.convertObjectToJSON(this.application.menu);
      this.application.theme = this.convertObjectToJSON(this.application.theme);
      Swal2.fire('Successo', 'Applicazione aggiornata con successo', 'success');
    } );
  }

  deleteApplication() {

  }

  addDefault(permission: any) {
    if (this.application.defaultPermissions.find((p: string) => p === permission)) {
      notify('Autorizzazione già presente', 'error', 1000);
      return;
    }
    this.application.defaultPermissions.push(permission);
    notify('Auturazzione aggiunta con successo', 'success', 1000);
  }

  addAnonymous(permission: any) {
    if (this.application.anonymousPermissions.find((p: string) => p === permission)) {
      notify('Autorizzazione già presente', 'error', 1000);
      return;
    }
    this.application.anonymousPermissions.push(permission);
    notify('Auturazzione aggiunta con successo', 'success', 1000);
  }

  addPermission() {
    this.modalService.dismissAll();
    if (this.newPermission === '') {
      notify('Inserire un nome per l\'autorizzazione', 'error', 1000);
      return;
    }
    if (this.application.permissions.find((p: string) => p === this.newPermission)) {
      notify('Autorizzazione già presente', 'error', 1000);
      return;
    }
    this.application.permissions.push(this.newPermission);
    notify('Autorizzazione aggiunta con successo', 'success', 1000);
  }

  addNewPermission(addPermissionModal: TemplateRef<any>) {
    this.modalService.open(addPermissionModal, {size: 'md', centered: true});
  }

  removeDefault(permission: any) {
    this.application.defaultPermissions = this.application.defaultPermissions.filter((p: string) => p !== permission);
    notify('Autorizzazione rimossa con successo', 'success', 1000);
  }

  removeAnonymous(permission: any) {
    this.application.anonymousPermissions = this.application.anonymousPermissions.filter((p: string) => p !== permission);
    notify('Autorizzazione rimossa con successo', 'success', 1000);
  }

  addAllToDefault() {
    this.application.defaultPermissions = this.application.permissions;
    notify('Autorizzazioni aggiunte con successo', 'success', 1000);
  }

  addAllToAnonymous() {
    this.application.anonymousPermissions = this.application.permissions;
    notify('Autorizzazioni aggiunte con successo', 'success', 1000);
  }

  isPermissionExist(permission: string, type: string): boolean {
    if (type === 'default') {
      return !!this.application.defaultPermissions.find((p: string) => p === permission);
    } else {
      return !!this.application.anonymousPermissions.find((p: string) => p === permission);
    }
    return false;
  }

  removeType(type: any) {
    this.application.userTypes = this.application.userTypes.filter((t: string) => t !== type);
    notify('Tipo utente rimosso con successo', 'success', 1000);
  }

  addUser() {
    this.modalService.dismissAll();
    if (this.newUser === '') {
      notify('Inserire un nome per il tipo utente', 'error', 1000);
      return;
    }
    if (this.application.userTypes.find((t: string) => t === this.newUser)) {
      notify('Tipo utente già presente', 'error', 1000);
      return;
    }
    this.application.userTypes.push(this.newUser);
    notify('Tipo utente aggiunto con successo', 'success', 1000);
  }

  addNewUserType(addUserTypeModal: TemplateRef<any>) {
    this.modalService.open(addUserTypeModal, {size: 'md', centered: true});
  }

  searchPermissionList() {
    this.tmpPermissions = this.application.permissions.filter((p: string) => p.includes(this.searchPermission));
  }
}

