<div class="sidebar-container glassy">
  <img class="logo" [src]="logo">
  <div class="menu">
    <ng-container *ngFor="let group of menu">
      <div class="group-name" *ngIf="group.name">{{ group.name }}</div>
      <ng-container *ngFor="let item of group.children">
        <app-menu-item class="menu-container"
                       [item]="item"></app-menu-item>
      </ng-container>
    </ng-container>
  </div>
</div>
