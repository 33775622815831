import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Observable, Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CustomersService} from "@core/services/customers.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {Menu, MenuGroup} from "../../../../@core/types/app/menu";
import Swal2 from "sweetalert2";
import {TableColumn, TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";


@Component({
  selector: 'app-tables-view',
  templateUrl: './tables-view.component.html',
  styleUrls: ['./tables-view.component.scss']
})

export class TablesViewComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public currentId: string = '';
  public loaded: Promise<boolean> = Promise.resolve(false);
  public table: TableConfig = {} as TableConfig;
  public table2: TableConfig = {} as TableConfig;
  public sortProperties: string[] = [];
  public sortDirections: string[] = ['asc', 'desc'];

  public selectedColumnIndex: number = -1;

  public columnTypes: string[] = ['text', 'select', 'price', 'badge', 'photo', 'date', 'date-time', 'date-humanized', 'link', 'out-link', 'link-email', 'link-phone', 'actions'];
  public newTableType: string = 'text';
  public newFieldType: string = '';
  public newColumnType: string = '';
  public fieldTypes: string[] = [
    'draggable',
    'resizable',
    'sortable',
    'name',
    'prop',
    'width',
    'maxWidth',
    'minWidth',
    'type',
    'header',
    'flexGrow',
    'cellTemplate',
    'extra'
  ];

  filterTypes: string[] = ['select'];
  public newPermission: string = '';

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------


  constructor(
    private modalService: NgbModal,
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private _dynamicTableService: DynamicTableService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('super-dynamic-tables-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.currentId = this._activatedRoute.snapshot.paramMap.get('name') || '';
    this._dynamicTableService.getDynamicTable(this.currentId).then((data: any) => {
      this.table = data;
      this.table.columns.forEach((column: any) => {
        if (column.sortable) {
          this.sortProperties.push(column.prop);
        }
        if (column.extra) {
          column.extra = this.getExtraAsJSON(column);
        }
      });

      this.loaded = Promise.resolve(true);
    });
  }


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  showPreview(tableModal: TemplateRef<any>) {
    this.table2 = JSON.parse(JSON.stringify(this.table));
    this.modalService.open(tableModal, {size: 'xl', centered: true});
  }

  saveTable() {
    this.table.permissions = this.generatePermissions(this.table);
    this.table.columns.forEach((column: any) => {
      if (column.extra) {
        column.extra = JSON.parse(column.extra);
      }
    });
    this._dynamicTableService.updateDynamicTable(this.currentId, this.table).then((data: any) => {
      Swal2.fire('Successo', 'Tabella salvata con successo', 'success');
      this.table.columns.forEach((column: any) => {
        if (column.extra) {
          column.extra = this.getExtraAsJSON(column);
        }
      });
    });
  }

  deleteTable() {
    Swal2.fire({
      title: 'Sei sicuro?',
      text: 'Questa azione non può essere annullata!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, elimina!',
      cancelButtonText: 'No, annulla'
    }).then((result) => {
      if (result.isConfirmed) {
        this._dynamicTableService.deleteDynamicTable(this.currentId).then((data: any) => {
          Swal2.fire('Successo', 'Tabella eliminata con successo', 'success');
          this._router.navigate(['/super-admin/tables']);
        });
      }
    });
  }

  async getValues(query?: any) {
    //return empty array
    return [];
  }

  addFieldToColumns(addFieldModal: TemplateRef<any>, i: number) {
    this.modalService.open(addFieldModal, {size: 'sm', centered: true});
    this.selectedColumnIndex = i;
  }

  getExtraAsJSON(column: any): string {
    return JSON.stringify(column.extra, null, 2);
  }

  protected readonly JSON = JSON;

  addField() {
    console.log(this.newFieldType);
    if (this.selectedColumnIndex > -1) {
      (this.table.columns[this.selectedColumnIndex] as any)[this.newFieldType] = 1;
      console.log(this.table.columns[this.selectedColumnIndex]);
      this.selectedColumnIndex = -1;
    }
    this.modalService.dismissAll();
  }

  addColumns(addColumnModal: TemplateRef<any>) {
    this.modalService.open(addColumnModal, {size: 'sm', centered: true});
  }

  addColumn() {
    const newColumn = {
      type: this.newColumnType
    };
    this.table.columns.push(newColumn as TableColumn);
    console.log(this.table.columns);
    this.modalService.dismissAll();
  }

  addFilter() {
    const newFilter = {
      type: "select",
      class: "col-12 col-md-4 col-lg-3",
      label: "NewFilter",
      field: "field",
      fieldType: "id",
      options: [],
      retriever: "DataRetriever",
      multiple: true,
      closeOnSelect: false,
      searchable: true,
      placeholder: "",
      value: null
    }
    this.table.filterGroups[0].filters.push(newFilter);
  }

  moveColumn(i: number, direction: string) {
    const temp = this.table.columns[i];
    if (direction === 'up') {
      this.table.columns[i] = this.table.columns[i - 1];
      this.table.columns[i - 1] = temp;
    } else {
      this.table.columns[i] = this.table.columns[i + 1];
      this.table.columns[i + 1] = temp;
    }
  }

  removeColumn(i: number) {
    this.table.columns.splice(i, 1);
  }

  removeFilter(i: number) {
    this.table.filterGroups[0].filters.splice(i, 1);
  }

  moveFilter(i: number, up: string) {
    const temp = this.table.filterGroups[0].filters[i];
    if (up === 'up') {
      this.table.filterGroups[0].filters[i] = this.table.filterGroups[0].filters[i - 1];
      this.table.filterGroups[0].filters[i - 1] = temp;
    } else {
      this.table.filterGroups[0].filters[i] = this.table.filterGroups[0].filters[i + 1];
      this.table.filterGroups[0].filters[i + 1] = temp;
    }
  }

  generatePermissions(dynamicTableConfig: TableConfig): string[] {
    const permissions = [];
    permissions.push(`dynamic-table.${dynamicTableConfig.name}`);
    permissions.push(`dynamic-table.${dynamicTableConfig.name}.*`);
    permissions.push(`dynamic-table.${dynamicTableConfig.name}.columns.*`);
    dynamicTableConfig.columns.forEach((column, index) => {
      permissions.push(`dynamic-table.${dynamicTableConfig.name}.columns.${index}`);
    });
    permissions.push(`dynamic-table.${dynamicTableConfig.name}.filterGroups.*`);
    dynamicTableConfig.filterGroups.forEach((filterGroup, groupIndex) => {
      permissions.push(`dynamic-table.${dynamicTableConfig.name}.filterGroups.${groupIndex}`);
      filterGroup.filters.forEach((filter, filterIndex) => {
        permissions.push(`dynamic-table.${dynamicTableConfig.name}.filterGroups.${groupIndex}.filters.${filterIndex}`);
      });
    });

    return permissions;
  }

  addNewPermission(addPermissionModal: TemplateRef<any>) {
    this.modalService.open(addPermissionModal, {size: 'md', centered: true});
  }


  addPermission() {
    this.modalService.dismissAll();
    if (this.newPermission === '') {
      Swal2.fire('Errore', 'Inserire un nome per l\'autorizzazione', 'error');
      return;
    }
    if (this.table.permissions.find((p: string) => p === this.newPermission)) {
      Swal2.fire('Errore', 'Autorizzazione già presente', 'error');
      return;
    }
    this.table.permissions.push(this.newPermission);
    Swal2.fire('Successo', 'Autorizzazione aggiunta con successo', 'success');
  }

  removePermission(permission: string) {
    this.table.permissions = this.table.permissions.filter((p: string) => p !== permission);
    Swal2.fire('Successo', 'Autorizzazione rimossa con successo', 'success');
  }
}

