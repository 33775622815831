import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from "rxjs";
import { ActivatedRoute, Params, Router } from "@angular/router";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationService } from "../../../../@core/services/application.service";
import { ShopService } from "../../../../@core/services/shop.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrderService } from "@core/services/orders.service";
import { PdfService } from "@core/services/pdf.service";
import { CommentsService } from "@core/services/comments.service";
import { AuthenticationService } from "@core/services/authentication.service";
import { DynamicTableService } from "../../../../@core/services/dynamicTable.service";
import { TableConfig } from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import { DynamicData } from "../../../../@core/types/dynamic-table/dynamic-data";

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})

export class OrdersViewComponent implements OnInit, OnDestroy {
  public tableConfig!: TableConfig ;
  public currentId: string | null = '';
  public currentOrder: any = {};
  public comments: any[] = [];
  public commentText: string = '';
  public loaded: Promise<boolean> = Promise.resolve(false);
  public deliveryForm: FormGroup = this._formBuilder.group({
    deliveryCode: null,
    deliveryDate: null,
    deliveryTrack: null
  });
  public deliveryForm2!: FormGroup;
  // Private
  private _unsubscribeAll: Subject<any>;

  public permissions: any[] = [];

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  public currentCode: string = '';
  public currentYear: string = '';
  public currentUrl: string = '';
  public discount: number = 0;

  public orderStatus: any = {
    CREATED: {
      class: 'badge-light-warning',
      label: 'Creato'
    },
    APPROVED: {
      class: 'badge-light-info',
      label: 'Approvato'
    },
    FAILED: {
      class: 'badge-light-danger',
      label: 'Fallito'
    },
    CONFIRMED: {
      class: 'badge-light-primary',
      label: 'Confermato'
    },
    REJECTED: {
      class: 'badge-light-danger',
      label: 'Rifiutato'
    },
    COMPLETED: {
      class: 'badge-light-primary',
      label: 'Completato'
    },
    CANCELED: {
      class: 'badge-light-danger',
      label: 'Annullato'
    },
    SHIPPED: {
      class: 'badge-light-warning',
      label: 'Spedito'
    },
    DELIVERED: {
      class: 'badge-light-success',
      label: 'Consegnato'
    },
  };

  public paymentExtra: any = {
    status: {
      PENDING: {
        class: 'badge-light-warning',
        label: "In attesa"
      },
      PAID: {
        class: 'badge-light-success',
        label: 'Pagato'
      },
      SETTLED: {
        class: 'badge-light-primary',
        label: 'Saldato'
      },
      FAILED: {
        class: 'badge-light-danger',
        label: 'Fallito'
      },
      CANCELED: {
        class: 'badge-light-danger',
        label: 'Annullato'
      },
      REFUNDED: {
        class: 'badge-light-info',
        label: 'Rimborsato'
      },
    },
    method: {
      DEBT: {
        label: "Fido"
      },
      KLARNA: {
        label: "Klarna"
      },
      MYBANK: {
        label: "MyBank"
      },
      PAUSEPAY: {
        label: "PausePay"
      },
      RIBAAUTO180: {
        label: "RibaAuto180"
      },
      STRIPE: {
        label: "Stripe"
      },
      CASHBACK: {
        label: "Cashback"
      },
      WALLET: {
        label: "Disponibilità"
      }
    },
  }
  public deliveries: any[] = [];
  public selectedDelivery: any;
  public invoice: any = {
    documentNames: [
      "Ordine Fornitore",
      "Reso Fornitore",
    ],
    documentName: '',
    number: '',
    invoiceDate: '',
    productQuantity: 0,
    vectorName: '',
    clients: [
      {
        name: "Serbatio GPL",
        address1: "Via dell' Artigiano, 30 Z.I",
        address2: "38068 Rovereto TN",
        vat: "---",
        references: [
          "65798bbd3128245708efad73",
          "65798bbd3128245708efad61",
          "65798bbd3128245708efad58"
        ]
      },
      {
        name: "Commerciale Ricambi s.r.l.",
        address1: "Via della Repubblica, 24",
        address2: "36031 Dueville VI",
        vat: "---",
        references: [
          "65798bbd3128245708efad73",
          "65798bbd3128245708efad61",
          "65798bbd3128245708efad58"
        ]
      },
      {
        name: "Franceschini Srl",
        address1: "Via Nona Strada, 42",
        address2: "35129 Padova PD",
        vat: "---",
        references: [
          "65798bbd3128245708efad73",
          "65798bbd3128245708efad61",
          "65798bbd3128245708efad58"
        ]
      },
      {
        name: "S.i.ra.l. Spa",
        address1: "Via Boscofangone Zona Industriale ASI",
        address2: "80035 Nola NA",
        vat: "---",
        references: [
          "65798bbd3128245708efad73",
          "65798bbd3128245708efad61",
          "65798bbd3128245708efad58"
        ]
      },
      {
        name: "CIDA AUTO COMPONENTS SPA AUTODIS",
        address1: "VIA STRADA SANTA CATERINA, 4",
        address2: "70124 BARI (BA)",
        vat: "04769580723",
        references: [
          "65798bbd3128245708efad73",
          "65798bbd3128245708efad61",
          "65798bbd3128245708efad58"
        ]
      },
      {
        name: "COMMERCIO ARTICOLI TECNICI INDUSTRIALI S.P.A.",
        address1: "Via Pretegiani, 27",
        address2: "10078 Venaria (TO)",
        vat: "03748340019",
        references: [
          "65798bbd3128245708efad76",
          "65798bbd3128245708efad75"
        ]
      }
    ],
    businessData: {
      name: '',
      address1: '',
      address2: '',
      vat: '',
      references: {},
    },
    destination: {
      name: '',
      address1: '',
      address2: '',
      vat: '',
      references: {},
    },
    returned: {
      number: '',
      date: '',
      casual: '',
      reference: '',
    },
    lines: [],
    transport: {
      method: ''
    },
    shipping: {
      method: '',
      packages: '',
    }
  };

  // @ts-ignore
  @ViewChild('destinationSelect') destinationSelect;

  constructor(
    private _pdfService: PdfService,
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _orderService: OrderService,
    private _commentService: CommentsService,
    private _authService: AuthenticationService,
    private _dynamicTableService: DynamicTableService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-orders-list');
  }

  public pagePermissions: {
    details: boolean,
    ddt: boolean,
    invoice: boolean,
    total: boolean,
    comments: boolean,
    actions: boolean,
    delivery: boolean,
    products: boolean,
    approve: boolean,
    confirm: boolean,
    reject: boolean,
    cancel: boolean,
    pdf: boolean,
    billings: boolean,
    products_name: boolean,
    products_code: boolean,
    products_brand: boolean,
    products_category: boolean,
    products_kromeda: boolean,
    products_supplier: boolean,
    products_quantity: boolean,
    products_unit: boolean,
    products_cost: boolean,
    products_price: boolean,
  } = {
    approve: false,
    confirm: false,
    reject: false,
    cancel: false,
    details: false,
    ddt: false,
    invoice: false,
    total: false,
    comments: false,
    actions: false,
    products: false,
    delivery: false,
    pdf: false,
    billings: false,
    products_name: false,
    products_code: false,
    products_brand: false,
    products_category: false,
    products_kromeda: false,
    products_supplier: false,
    products_quantity: false,
    products_unit: false,
    products_cost: false,
    products_price: false,
  };


  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {

    this.pagePermissions = {
      approve: this._authService.checkPermission('admin.page.orderView.approve'),
      confirm: this._authService.checkPermission('admin.page.orderView.confirm'),
      reject: this._authService.checkPermission('admin.page.orderView.reject'),
      cancel: this._authService.checkPermission('admin.page.orderView.cancel'),
      details: this._authService.checkPermission('admin.page.orderView.details'),
      ddt: this._authService.checkPermission('admin.page.orderView.ddt'),
      invoice: this._authService.checkPermission('admin.page.orderView.invoice'),
      total: this._authService.checkPermission('admin.page.orderView.total'),
      comments: this._authService.checkPermission('admin.page.orderView.comments'),
      actions: this._authService.checkPermission('admin.page.orderView.actions'),
      products: this._authService.checkPermission('admin.page.orderView.products'),
      delivery: this._authService.checkPermission('admin.page.orderView.delivery'),
      pdf: this._authService.checkPermission('admin.page.orderView.pdf'),
      billings: this._authService.checkPermission('admin.page.orderView.billings'),
      products_name: this._authService.checkPermission('admin.page.orderView.products.name'),
      products_code: this._authService.checkPermission('admin.page.orderView.products.code'),
      products_brand: this._authService.checkPermission('admin.page.orderView.products.brand'),
      products_category: this._authService.checkPermission('admin.page.orderView.products.category'),
      products_kromeda: this._authService.checkPermission('admin.page.orderView.products.kromeda'),
      products_supplier: this._authService.checkPermission('admin.page.orderView.products.supplier'),
      products_quantity: this._authService.checkPermission('admin.page.orderView.products.quantity'),
      products_unit: this._authService.checkPermission('admin.page.orderView.products.unit'),
      products_cost: this._authService.checkPermission('admin.page.orderView.products.cost'),
      products_price: this._authService.checkPermission('admin.page.orderView.products.price'),
    }

    this.loaded = Promise.resolve(false);
    this.currentCode = this._route.snapshot.paramMap.get('code') || '';
    this.currentYear = this._route.snapshot.paramMap.get('year') || '';
    this.currentUrl = this.currentCode + '/' + this.currentYear;
    this._orderService.getOrderDetails(this.currentCode, this.currentYear).then((response: any) => {
      this.currentOrder = response;
      this.loadComments();
      if (this.currentOrder.discount) {
        if (this.currentOrder.discount.type === 'PERCENTAGE') {
          this.discount = this.calculateTotalOfProducts() / 100 * this.currentOrder.discount.amount;
        } else if (this.currentOrder.discount.type === 'FIXED' || this.currentOrder.discount.type === 'AMOUNT') {
          this.discount = this.currentOrder.discount.amount;
        }
      }
      this._orderService.getDeliveries(this.currentOrder._id).then((response: any) => {
        this.deliveries = response.rows;
        this.currentOrder.shippings = this.deliveries;
        for (let i = 0; i < this.currentOrder.products.length; i++) {
          this.invoice.lines.push({
            code: this.currentOrder.products[i].code,
            description: this.currentOrder.products[i].name,
            unit: this.currentOrder.products[i].unit,
            quantity: this.currentOrder.products[i].quantity,
          });
        }
        this._dynamicTableService.getDynamicTable('orderProducts')
          .then((table) => {
            console.log(this.loaded);
            this.tableConfig = table;
            this.loaded = Promise.resolve(true);
          })
        this.invoice.productQuantity = this.currentOrder.products.length;
      });

      this.invoice.clients.unshift({
        name: this.currentOrder.business.name,
        vat: this.currentOrder.business.vat,
        address1: this.currentOrder.address.street + ' ' + this.currentOrder.address.number,
        address2: this.currentOrder.address.zip + ' ' + this.currentOrder.address.city + ' (' + this.currentOrder.address.province + ')'
      })

      this.invoice.clients.unshift({
        name: this.currentOrder.business.code + ' | ' + this.currentOrder.business.name,
        vat: this.currentOrder.business.vat,
        address1: this.currentOrder.business.location.street + ' ' + this.currentOrder.business.location.number,
        address2: this.currentOrder.business.location.zip + ' ' + this.currentOrder.business.location.city + ' (' + this.currentOrder.business.location.province + ')'
      })

      this.invoice.businessData = this.invoice.clients[0];
      this.invoice.destination = this.invoice.clients[1];
      this.selectedClient = this.invoice.businessData;
      this.selectedDestination = this.invoice.destination;
      this.invoice.documentName = this.invoice.documentNames[0];

    });


    // @ts-ignore
    window.setLocalUser = this.setLocalUser.bind(this);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  approveCurrentOrder() {

  }

  confirmCurrentOrder() {
    this._orderService.statusCompleted(this.currentOrder._id).then((response: any) => {
      this.currentOrder = response;
      // this.currentOrder.status = 'COMPLETED';
      Swal.fire({
        title: 'Conferma',
        text: 'Ordine confermato con successo',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    });
  }

  downloadCSV() {
    this._orderService
      .downloadProductsCSV(this.currentOrder.code)
      .then((response: any) => {
        // const blob = new Blob([response], {type: 'text/csv'});
        // const url = window.URL.createObjectURL(blob);
        // window.open(url);
      });
  }

  rejectCurrentOrder() {

  }

  cancelCurrentOrder() {
    this._orderService.statusCanceled(this.currentOrder._id).then((response: any) => {
      this.currentOrder = response;
      // this.currentOrder.status = 'COMPLETED';
      Swal.fire({
        title: 'Annullato',
        text: 'Ordine annullato con successo',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    });
  }

  modalOpenModal(deliveryModal: any) {
    this.modalService.open(deliveryModal, { size: 'xl', backdrop: 'static', windowClass: 'modal modal-primary' });
  }

  sendDelivery() {
    let products: any[] = [];
    this.currentOrder.products.forEach((product: any) => {
      if (product.quantity) {
        products.push({
          product: product._id,
          quantity: product.quantity
        });
      }
    });
    this._orderService.newDelivery(this.currentOrder._id, this.deliveryForm.value, products).then((response: any) => {
      this.deliveries.push(response);
      this.modalService.dismissAll();
      Swal.fire({
        title: 'Conferma',
        text: 'Consegna creata con successo',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    });
    this._orderService.getOrderDetails(this.currentCode, this.currentYear).then((response: any) => {
      this.currentOrder = response;
      this._orderService.getDeliveries(this.currentOrder._id).then((response: any) => {
        this.deliveries = response.rows;
        this.currentOrder.shippings = this.deliveries;
        this.loaded = Promise.resolve(true);
      });
    });

  }

  editDelivery() {
    let products: any[] = this.selectedDelivery.products;
    this._orderService.editDelivery(this.currentOrder._id, this.selectedDelivery.id, this.deliveryForm2.value, this.selectedDelivery.products).then((response: any) => {
      this.deliveries.push(response);
      this.modalService.dismissAll();
      Swal.fire({
        title: 'Conferma',
        text: 'Consegna modificata con successo',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    });
    this._orderService.getOrderDetails(this.currentCode, this.currentYear).then((response: any) => {
      this.currentOrder = response;
      console.log(this.currentOrder);
      this._orderService.getDeliveries(this.currentOrder._id).then((response: any) => {
        this.deliveries = response.rows;
        this.currentOrder.shippings = this.deliveries;
        this.loaded = Promise.resolve(true);
      });
      console.log(this.currentOrder);
    });

  }

  calculateSumOfProducts(): number {
    let sum = 0;
    this.currentOrder.products.forEach((product: any) => {
      sum = sum + parseFloat(product.price.toFixed(3)) * product.quantity;
    });
    return parseFloat(sum.toFixed(2));
  }

  calculateTaxOfProducts(): number {
    let tax = 0;
    this.currentOrder.products.forEach((product: any) => {
      tax = tax + (parseFloat(product.price.toFixed(3)) * product.quantity * product.vat);
    });
    if (this.currentOrder.delivery?.price) {
      tax = tax + (parseFloat(this.currentOrder.delivery.price.toFixed(3)) * 0.22);
    }
    return tax;
  }

  calculateTotalOfProducts(): number {
    let total = 0;
    total = this.calculateSumOfProducts() + this.calculateTaxOfProducts() + this.currentOrder.delivery.price;
    return total;
  }

  calculateTotalWithDiscount(): number {
    let total = 0;
    total = this.calculateTotalOfProducts() - this.discount;
    return total;
  }


  updateQuantity(product: any, target: EventTarget | null) {
    product.quantity = parseInt((target as HTMLInputElement).value);
    this.currentOrder.products.forEach((currentProduct: any) => {
      if (currentProduct._id === product._id) {
        currentProduct.quantity = product.quantity;
      }
    });
    console.log(product);
    console.log(this.currentOrder);
  }


  updateQuantity2(product: any, target: EventTarget | null) {
    product.quantity = parseInt((target as HTMLInputElement).value);
    this.selectedDelivery.products.forEach((currentProduct: any) => {
      if (currentProduct.product === product._id) {
        currentProduct.quantity = product.quantity;
      }
    });
  }

  editShippingModal(shipping: any, shippingModal: any) {
    this.selectedDelivery = shipping;
    let date = new Date(this.selectedDelivery.date);
    let dateObject = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }
    this.selectedDelivery.date = dateObject;
    this.deliveryForm2 = this._formBuilder.group({
      deliveryCode: [this.selectedDelivery.reference || ''],
      deliveryTrack: [this.selectedDelivery.tracking || ''],
      deliveryDate: [this.selectedDelivery.date || ''],
    });
    this.modalService.open(shippingModal, { size: 'xl', backdrop: 'static', windowClass: 'modal modal-primary' });
  }

  openPdfModal(modalPdf: TemplateRef<any>) {
    this.modalService.open(modalPdf, {
      size: 'xl',
      windowClass: 'modal modal-primary'
    });
  }

  downloadPDF() {
    console.log(this.invoice);
    this._pdfService.setdInvoices(this.currentOrder._id, this.invoice)
      .then((response: any) => {
        console.log(response);
        this._pdfService.getPdf(response.id).then((response2: any) => {
          console.log(response2);
          const blob = new Blob([response2], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
      })
      .catch((error: any) => {
        console.error('Error downloading PDF:', error);
      });
  }


  protected readonly Object = Object;

  removeProduct(i: number) {
    this.invoice.lines.splice(i, 1);
  }

  getLocalUser(): {
    name: string,
    avatar: string
  } {
    const userName = localStorage.getItem('comment-user-name') || "Cap Nemo";
    const userAvatar = localStorage.getItem('comment-user-avatar') || "assets/images/avatars/default-avatar.png";
    return {
      name: userName,
      avatar: userAvatar
    }
  }

  setLocalUser(name: string, avatar: string) {
    localStorage.setItem('comment-user-name', name);
    localStorage.setItem('comment-user-avatar', `assets/images/avatars/${avatar}.jpg`);
  }

  loadComments() {
    this._commentService.getCommentList(this.currentOrder._id)
      .then((response: any) => {
        this.comments = response.rows;
      })
      .catch((error: any) => {
        console.error('Error fetching comments:', error);
      });
  }

  addComment() {
    let user = this.getLocalUser();
    if (this.commentText.trim()) {
      this._commentService.setComment(this.currentOrder._id, this.commentText, user)
        .then((response: any) => {
          this.commentText = '';
          this.loadComments();
          Swal.fire({
            title: 'Conferma',
            text: 'Commento aggiunto con successo',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
        })
        .catch((error: any) => {
          console.error('Error fetching comments:', error);
          Swal.fire({
            title: 'Errore',
            text: 'Errore durante l\'invio del commento',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
    }
  }

  selectedClient: any;
  selectedDestination: any;

  updateBusinessData() {
    if (this.selectedClient) {
      const existingClient = this.invoice.clients.find((client: Client) => client.name === this.selectedClient.name);
      if (existingClient) {
        console.log(existingClient);
        this.invoice.businessData = {
          name: existingClient.name,
          vat: existingClient.vat,
          address1: existingClient.address1,
          address2: existingClient.address2
        };
      } else {
        console.log("not found");
        this.createNew1(this.selectedClient.name);
        this.invoice.businessData = {
          name: this.selectedClient.name,
          vat: '',
          address1: '',
          address2: ''
        };
      }
    } else {
      console.log("not found 2");
      this.createNew1(this.selectedClient.name);
      this.invoice.businessData = {
        name: this.selectedClient.name,
        vat: '',
        address1: '',
        address2: ''
      };
    }
  }

  createNew1(text: string) {
    this.selectedClient = {
      name: text,
      vat: '',
      address1: '',
      address2: ''
    };
    this.invoice.clients.push({
      name: text,
      vat: '',
      address1: '',
      address2: ''
    })
    this.invoice.businessData = {
      name: text,
      vat: '',
      address1: '',
      address2: ''
    };
  }

  createNew2(text: string) {
    this.selectedDestination = {
      name: text,
      vat: '',
      address1: '',
      address2: ''
    };
    this.invoice.clients.push({
      name: text,
      vat: '',
      address1: '',
      address2: ''
    })
    this.invoice.destination = {
      name: text,
      vat: '',
      address1: '',
      address2: ''
    };
    console.log('something');
    console.log(this.invoice);
  }


  updateDestination() {
    if (this.selectedDestination) {
      const existingClient = this.invoice.clients.find((client: Client) => client.name === this.selectedDestination.name);
      if (existingClient) {
        console.log(existingClient);
        this.invoice.destination = {
          name: existingClient.name,
          vat: existingClient.vat,
          address1: existingClient.address1,
          address2: existingClient.address2
        };
      } else {
        console.log("not found");
        this.createNew2(this.selectedDestination.name);
        this.invoice.destination = {
          name: this.selectedDestination.name,
          vat: '',
          address1: '',
          address2: ''
        };
      }
    } else {
      console.log("not found 2");
      this.createNew2(this.selectedDestination.name);
      this.invoice.destination = {
        name: this.selectedDestination.name,
        vat: '',
        address1: '',
        address2: ''
      };
    }
  }

  async getProducts(query?: any): Promise<DynamicData> {
    console.log('getProducts');
    let products: DynamicData = {
      total: this.currentOrder.products.length,
      totalPages: 1,
      page: 1,
      pageSize: 50,
      rows: this.currentOrder.products
    };
    console.log(products);
    products.rows.forEach(product => {
      product.links = product.links || {};
      product.links.code = '/products/' + product.id;
      product.actions = product.actions || [];
      product.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/products/' + product.id
      });
    })
    console.log(products);
    return products
  }


  protected readonly close = close;
}

interface Client {
  name: string;
  vat: string;
  address1: string;
  address2: string;
}
