// Importing necessary modules and components from Angular and other libraries
import {
  Component,
  OnDestroy,
  OnInit,
  Inject,
  PLATFORM_ID,
  NgZone,
  TemplateRef,
  ViewChild
} from "@angular/core";
import {filter, Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {CustomersService} from "@core/services/customers.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {DynamicData} from "../../../../@core/types/dynamic-table/dynamic-data";
import {ShopService} from "../../../../@core/services/shop.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import {CommentsService} from "../../../../@core/services/comments.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-businesses-view',
  templateUrl: './businesses-view.component.html',
  styleUrls: ['./businesses-view.component.scss']
})
export class BusinessesViewComponent implements OnInit, OnDestroy {

  // Private variables
  private _unsubscribeAll: Subject<any>; // Subject to unsubscribe from subscriptions

  @ViewChild("paymentsCell", {static: true})
  private paymentsCellTemplate?: TemplateRef<any>;

  // Public variables
  public code: string = ''; // Public variable to store a string code
  public business: any = {}; // Public variable to store a Group object
  public stats: any = {}; // Public variable to store a Group object
  public loaded: Promise<boolean> = Promise.resolve(false);
  public comments: any[] = [];
  public commentText: string = '';
  public walletHistoryArray: any = [];
  public modalData: any = {};


  public pagePermissions: {
    details: boolean,
    actions: boolean,
    addresses: boolean,
    contacts: boolean,
    stats: boolean,
    orders: boolean,
    wallets: boolean,
    payments: boolean,
    connections: boolean,
    login: boolean,
    edit: boolean,
    delete: boolean,
    comments: boolean,
  } = {
    details: false,
    actions: false,
    addresses: false,
    contacts: false,
    stats: false,
    orders: false,
    wallets: false,
    payments: false,
    connections: false,
    login: false,
    edit: false,
    delete: false,
    comments: false,
  };


  public statuses: any = {
    CREATED: {
      class: 'badge-light-warning',
      label: 'Creato'
    },
    VERIFIED: {
      class: 'badge-light-info',
      label: 'Verificato'
    },
    COMPLETED: {
      class: 'badge-light-primary',
      label: 'Completato'
    },
    SUSPENDED: {
      class: 'badge-light-danger',
      label: 'Sospeso'
    },
    CANCELED: {
      class: 'badge-light-danger',
      label: 'Annullato'
    },
  }
  public users: any[] = [];


  public paymentsConfig: TableConfig = {} as TableConfig;
  public ordersConfig: TableConfig = {} as TableConfig;


  private unsubscribe$ = new Subject<void>();
  private permissions: any[] = []

  constructor(
    // Injecting services and dependencies required for this component
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _customerService: CustomersService,
    private _route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    private modalService: NgbModal,
    private router: Router,
    private _commentService: CommentsService,
    private _dynamicTableService: DynamicTableService,
    private _authService: AuthenticationService
  ) {
    // Initializing private variables and setting defaults in the constructor
    this._unsubscribeAll = new Subject(); // Initializing _unsubscribeAll as a Subject
    this._applicationService.layoutContentWidth(); // Setting layout content width
    this._applicationService.selectMenu('customers-businesses-list'); // Selecting a menu option
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: NavigationEnd) => {
        // Check if the component is re-entered
        if (this.router.url === event.url) {
          // Call a function to reload or fetch new data here
          this.reloadData();
        }
      });
  }


  // Lifecycle hook - On initialization
  ngOnInit() {
    this.loaded = Promise.resolve(false);
    this.pagePermissions = {
      details: this._authService.checkPermission('admin.page.businessView.details'),
      actions: this._authService.checkPermission('admin.page.businessView.actions'),
      addresses: this._authService.checkPermission('admin.page.businessView.addresses'),
      contacts: this._authService.checkPermission('admin.page.businessView.contacts'),
      stats: this._authService.checkPermission('admin.page.businessView.stats'),
      orders: this._authService.checkPermission('admin.page.businessView.orders'),
      wallets: this._authService.checkPermission('admin.page.businessView.wallets'),
      payments: this._authService.checkPermission('admin.page.businessView.payments'),
      connections: this._authService.checkPermission('admin.page.businessView.connections'),
      login: this._authService.checkPermission('admin.page.businessView.login'),
      edit: this._authService.checkPermission('admin.page.businessView.edit'),
      delete: this._authService.checkPermission('admin.page.businessView.delete'),
      comments: this._authService.checkPermission('admin.page.businessView.comments'),
    }
    // Retrieving 'code' from the route or setting an empty string
    this.code = this._route.snapshot.paramMap.get('code') || '';
    this._dynamicTableService.getDynamicTable('orders')
      .then((table) => {
        this.ordersConfig = table;
        console.log('ordersConfig', this.ordersConfig)
        const paymentsColumn = this.ordersConfig.columns.find(column => column.prop === 'payments');
        if (paymentsColumn) {
          paymentsColumn.cellTemplate = this.paymentsCellTemplate;
        }
      })
    this._dynamicTableService.getDynamicTable('businessPayments')
      .then((table) => {
        this.paymentsConfig = table;
      })
    // Fetching a single group from the customer service
    this._customerService.getSingleBusinessForView(this.code, {
      params: {
        populateFields: ['group', 'network']
      }
    })
      .then((response: any) => {
        this.business = response;
        console.log('business', this.business)
        //sort the alerts based on the last click date
        this.business.alerts = this.business.alerts.sort((a: any, b: any) => {
          return new Date(b.dates[b.dates.length - 1]).getTime() - new Date(a.dates[a.dates.length - 1]).getTime();
        });
        console.log("[[========== [BUSINESS] ==================================================]]")
        console.log(response);
        console.log(this.business);

        this._customerService.getSingleBusinessOrderStats(this.code)
          .then((response: any) => {
            this.stats = response;
          });
        this._customerService.getWallets(this.code)
          .then((response: any) => {
            this.business.wallets = response;
          });
        this._customerService.getUsersByBusiness(this.business.id)
          .then((response: any) => {
            this.users = response;
            this.selectedUser = this.users[0];
            this.loaded = Promise.resolve(true);
          });
        (this.business?.alerts || []).forEach((alert: any) => {
          this._shopService.getAlert(alert.alert).then((data: any) => {
            alert.alert = data;
            console.log('business', this.business);
          } );
        } )

        this.loadComments();
      });
  }

  // Lifecycle hook - On component destruction
  ngOnDestroy(): void {
    // Unsubscribing from all subscriptions to prevent memory leaks
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  private identity_id!: string;
  selectedUser: any;

  async getOrders(query?: any) {
    query = query || {};
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    query.filters = query.filters || {};
    query.filters.business = {"$oid": this.business.id}
    query['populateFields'] = ['business', 'network', 'warehouse', 'payments.payment','payments.payment.method'];
    const orders: DynamicData = await this._shopService.getOrderList(query)
    orders.rows.forEach(order => {
      order.links = order.links || {};
      order.links.code = '/orders/' + order.code;
      order.products = order.products.reduce((acc: number, product: any) => {
        acc += product.total
        return acc
      }, 0);
      order.actions = order.actions || [];
      order.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/orders/' + order.code
      });
    })
    return orders
  }

  async getPayments(query?: any) {
    query = query || {};
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    query.filters = query.filters || {};
    query.filters.business = {"$oid": this.business.id}
    query['populateFields'] = ['method'];
      const payments: DynamicData = await this._shopService.getBusinessPaymentList(this.business.id, query)
    payments.rows.forEach(payment => {
      payment.actions = payment.actions || [];
      payment.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/payments/' + payment._id
      });
    } )
    return payments
  }


  loginAs(usersListModal: TemplateRef<any>) {
    return this.modalService.open(usersListModal, {
      size: 'medium',
      windowClass: 'modal modal-primary'
    });
  }

  loginAsUser() {
    const identity = this.selectedUser.identity.id;
    this._customerService.getToken(identity)
      .then((response: any) => {
        const token = response;
        this._customerService.loginByToken(token)
          .then((response: any) => {
            window.open('https://api.tulero.it/api/app/identities/login/token/' + response, '_blank');

          })
      })
  }

  private reloadData() {
    // Retrieving 'code' from the route or setting an empty string
    this.code = this._route.snapshot.paramMap.get('code') || '';

    // Fetching a single group from the customer service
    this._customerService.getSingleBusiness(this.code, {
      params: {
        populateFields: ['group', 'network']
      }
    })
      .then((response: any) => {
        this.business = response; // Setting the fetched response to the 'group' variable

        this._customerService.getSingleBusinessOrderStats(this.code)
          .then((response: any) => {
            this.stats = response;
          });
        this._customerService.getWallets(this.code)
          .then((response: any) => {
            this.business.wallets = response;
            console.log('wallets', response)
          });
        this._customerService.getUsersByBusiness(this.business.id)
          .then((response: any) => {
            this.users = response;
            console.log('users', response)
          });
      });
  }

  addComment() {
    let user = this.getLocalUser();
    console.log(this.business)
    if (this.commentText.trim()) {
      this._commentService.setComment(this.business.id, this.commentText, user)
        .then((response: any) => {
          this.commentText = '';
          this.loadComments();
          Swal.fire({
            title: 'Conferma',
            text: 'Commento aggiunto con successo',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
        })
        .catch((error: any) => {
          console.error('Error fetching comments:', error);
          Swal.fire({
            title: 'Errore',
            text: 'Errore durante l\'invio del commento',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
    }
  }

  setLocalUser(name: string, avatar: string) {
    localStorage.setItem('comment-user-name', name);
    localStorage.setItem('comment-user-avatar', `assets/images/avatars/${avatar}.jpg`);
  }

  loadComments() {
    this._commentService.getCommentList(this.business.id)
      .then((response: any) => {
        this.comments = response.rows;
      })
      .catch((error: any) => {
        console.error('Error fetching comments:', error);
      });
  }

  getLocalUser(): {
    name: string,
    avatar: string
  } {
    const userName = localStorage.getItem('comment-user-name') || "Cap Nemo";
    const userAvatar = localStorage.getItem('comment-user-avatar') || "assets/images/avatars/default-avatar.png";
    return {
      name: userName,
      avatar: userAvatar
    }
  }

  navigateToAlert(id: string) {
    this.router.navigate(['/alerts/' + id]);
  }


  showWalletHistory(walletModal: TemplateRef<any>, wallet: any) {
    console.log(wallet)
    console.log(this.code)
    this._customerService.getBusinessWalletHistory(this.code, wallet._id)
      .then((response: any) => {
        if (response.rows?.length > 0) {
          response.rows.map((row: any) => {
            if (parseInt(row.code) != row.code) {
              return;
            }
            row.code = `T-${row.code.padStart(8, '0')}`
          })
          this.walletHistoryArray = response.rows;
          this.modalService.open(walletModal, {
            size: 'lg',
            windowClass: 'modal modal-primary'
          });
        } else {
          Swal.fire(
            'Errore!',
            'Non ci sono movimenti per questo wallet',
            'warning'
          )
        }
      });

  }



  showHistory(clickHistory: TemplateRef<any>, alert: any) {
    this.modalData = alert;
    this.modalService.open(clickHistory, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }
}
