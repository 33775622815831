import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class CustomUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    // Override the parse method to avoid splitting on '/'
    return super.parse(url.replace(/%2F/g, '/'));
  }

  override serialize(tree: UrlTree): string {
    // Override the serialize method to avoid encoding '/'
    return super.serialize(tree).replace(/\//g, '%2F');
  }
}
