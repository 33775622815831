import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UsersListComponent } from "./users-list/users-list.component";
import { UsersViewComponent } from "./users-view/users-view.component";
// import { UsersEditComponent} from "./users-edit/users-edit.component";
import { CoreModule } from "@core/components/core.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";

const routes: Routes = [
  {
    path: 'users',
    component: UsersListComponent,
    data: { animation: 'UsersListComponent' }
  },
  {
    path: 'users/:code',
    component: UsersViewComponent,
    data: { animation: 'UsersViewComponent' }
  },
  // {
  //   path: 'users/:code/edit',
  //   component: UsersEditComponent,
  //   data: { animation: 'UsersEditComponent' }
  // },
];

@NgModule({
  declarations: [
    UsersListComponent,
    UsersViewComponent,
    // UsersEditComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule
  ],
  providers: []
})
export class UsersModule {
}
