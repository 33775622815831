import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { DynamicData } from "../types/dynamic-table/dynamic-data";
import { ListParams } from "../types/api/list-params";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  // -----------------------------------------------------------------------------------------------------
  // @ public
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ private
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  getProductList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('products', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  searchProducts(text: string) : Promise<any> {
    console.log('searchProducts', text);
    return new Promise((resolve, reject) => {
      this._apiService.make('products', 'get', {}, {params:{search: text}})
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleProduct(id: string, options = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('products', 'view', { id }, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSingleProductOrders(id: string, queryParams = {}): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('products', 'viewOrders', { id }, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getBrandList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('brands', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getCategoryList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('categories', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  downloadCSVCategoryList(): Promise<string> {
    return new Promise((resolve, reject) => {
      this._apiService.make('categories', 'downloadCSV', {}, {responseType: 'blob'})
        .then((response) => {
          console.log("response", response)
          if (!response) {
            return
          }
          const fileName = `categories.csv`;
          saveAs(response, fileName);
          resolve(fileName)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getCategoryByCode(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('categories', 'viewByCode', { code })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  updateCategory(id: string, category: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('categories', 'update', { id }, { body: { category } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  propagatePriceRules(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('categories', 'propagatePriceRules', { id })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getSupplierList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('suppliers', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getWarehouseList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('suppliers', 'getWarehouses', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getShippingRuleList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {}
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('shippingrules', 'get', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  exportShippingRuleList(queryParams?: ListParams): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      const options: any = {
        responseType: 'blob'
      };
      if (queryParams) {
        options['params'] = queryParams
      }
      this._apiService.make('shippingrules', 'export', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getDeliveryPrices(param: { supplier: any }) {
    return new Promise((resolve, reject) => {
      this._apiService.make('suppliers', 'getDeliveryPrices', param)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });

  }

  getSingleBrand(id: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('brands', 'view', { id })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  saveBrand(id: string, brand: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('brands', 'save', {id}, { body: { brand } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  updateProduct(id: string, product: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('products', 'updateProduct', { id }, { body: { product } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deleteProduct(id: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('products', 'delete', { id })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }


  newCategory(category: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('categories', 'newCategory', {}, { body: { category: category } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deleteCategory(id: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('categories', 'delete', { id })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  newProduct(product: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('products', 'newProduct', {}, { body: { product } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  newBrand(brand: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('brands', 'create', {}, { body: { brand } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  addSupplier(newSupplier: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('suppliers', 'create', {}, { body: { supplier: newSupplier } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });

  }

  getSupplier(id: string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('suppliers', 'view', { id })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  saveSupplier(supplier: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('suppliers', 'save', { }, { body: { supplier } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deleteSupplier(id: string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('suppliers', 'delete', { id })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  toggleSupplierWarehouse(id: string, warehouse: string, status: boolean) {
    return new Promise((resolve, reject) => {
      this._apiService.make('suppliers', 'toggleWarehouse', { id, warehouse }, {
        body: {
          status
        }
      })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getWarehouse(currentId: string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('warehouses', 'view', { id: currentId })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  saveWarehouse(warehouse: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('warehouses', 'save', {}, { body: { warehouse } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  deleteWarehouse(currentId: string) {
    return new Promise((resolve, reject) => {
      this._apiService.make('warehouses', 'delete', { id: currentId })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    } );
  }

  newWarehouse(newWarehouse: any) {
    return new Promise((resolve, reject) => {
      this._apiService.make('warehouses', 'create', {}, { body: { warehouse: newWarehouse } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }
}
