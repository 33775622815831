import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { DynamicData } from "../types/dynamic-table/dynamic-data";
import { ListParams } from "../types/api/list-params";
import { Group } from "../types/data/group";
import { array } from "@amcharts/amcharts5";

@Injectable({
  providedIn: 'root'
})
export class DynamicTableService {

  constructor(
    private _apiService: ApiService,
  ) {
  }


  getDynamicTable(name: string): Promise<any> {
    return this._apiService.make('dynamicTable', 'getDynamicTable', {name: name}).then(
      (response: any) => {
        return response;
      }
    );
  }

  async getList(queryParams?: ListParams): Promise<DynamicData> {
    const response = await this._apiService.make('dynamicTable', 'getDynamicTableList', {}, {queryParams});
    return response;
  }
  async getAllList(queryParams?: ListParams): Promise<any> {
    const response = await this._apiService.make('dynamicTable', 'getAllDynamicTableList', {}, {queryParams});
    return response;
  }

  updateDynamicTable(name: string, table: any): Promise<any> {
    console.log("table",table);
    return this._apiService.make('dynamicTable', 'updateDynamicTable', {name}, {body: {table}});
  }
  deleteDynamicTable(name: string): Promise<any> {
    return this._apiService.make('dynamicTable', 'deleteDynamicTable', {name: name});
  }

  getDynamicTablePermissionsList(): Promise<any> {
    return this._apiService.make('dynamicTable', 'getDynamicTablePermissionsList', {});
  }
}
