import {
  AfterViewChecked,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { DynamicData } from "@core/types/dynamic-table/dynamic-data";
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import {CustomersService} from "../../services/customers.service";


@Component({
  selector: 'dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DynamicTableComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  // Private
  private _unsubscribeAll: Subject<any>;


  filterFunctions: { [key: string]: ($event: any, filter: any) => void } = {
    updateBusinessFilter: ($event: any, filter: any) => {
      console.log('here');
      this._customerService.getBusinessList({
        search: $event.target.value,
        pageSize: 25,
      }).then((response: DynamicData) => {
        filter.options = response.rows.map(business => {
          return {
            label: business.name,
            value: business.id
          }
        })
      })
    },
  };

  public totalPages: number = 1;
  public total: number = 0;
  public rows: any[] = [];
  public ColumnMode = ColumnMode;
  public SelectionType = SelectionType;
  public loading: boolean = true;
  public autoReload: number = 0;
  public autoReloadInterval: any = null;

  @Input()
  public sort: {
    prop?: string,
    dir?: string
  } = {
    prop: '',
    dir: ''
  }
  public selected: any[] = [];

  @Input()
  public pageSize: number = 50;

  @Input()
  public page: number = 1;

  @Input()
  public pageSizes: number[] = [10, 25, 50, 100];

  @Input()
  public search: string = '';

  @Input()
  public strictSearch: boolean = true;

  @Input()
  public columns: any[] = [];

  @Input()
  public filterGroups: any[] = [];

  @Input()
  public autoReloadEnable: boolean = false;

  @Input()
  public dataRetriever: Function = async (): Promise<DynamicData> => {
    return {} as DynamicData;
  };

  @Input()
  public messages: {
    emptyMessage: string,
    totalMessage: string,
    selectedMessage: string,
    beforePageSizeMessage: string,
    afterPageSizeMessage: string,
    textSearchMessage: string,
    specificSearchMessage: string,
    beforeAutoReloadMessage: string,
    afterAutoReloadMessage: string,
  } = {
    emptyMessage: 'No data to display',
    totalMessage: 'total',
    selectedMessage: 'selected',
    beforePageSizeMessage: 'See',
    afterPageSizeMessage: 'rows',
    textSearchMessage: 'Search',
    specificSearchMessage: 'Exact match',
    beforeAutoReloadMessage: 'Auto reload every',
    afterAutoReloadMessage: 'seconds',
  };


  @ViewChild("headerSelect", { static: true })
  customHeaderSelect?: TemplateRef<any>;

  @ViewChild("cellSelect", { static: true })
  customCellSelect?: TemplateRef<any>;

  @ViewChild("cellText", { static: true })
  customCellText?: TemplateRef<any>;

  @ViewChild("cellPrice", { static: true })
  customCellPrice?: TemplateRef<any>;

  @ViewChild("cellBadge", { static: true })
  customCellBadge?: TemplateRef<any>;

  @ViewChild("cellPhoto", { static: true })
  customCellPhoto?: TemplateRef<any>;

  @ViewChild("cellDate", { static: true })
  customCellDate?: TemplateRef<any>;

  @ViewChild("cellDateTime", { static: true })
  customCellDateTime?: TemplateRef<any>;

  @ViewChild("cellDateHumanized", { static: true })
  customCellDateHumanized?: TemplateRef<any>;

  @ViewChild("cellLink", { static: true })
  customCellLink?: TemplateRef<any>;

  @ViewChild("cellLinkOut", { static: true })
  customCellLinkOut?: TemplateRef<any>;

  @ViewChild("cellLinkEmail", { static: true })
  customCellLinkEmail?: TemplateRef<any>;

  @ViewChild("cellLinkPhone", { static: true })
  customCellLinkPhone?: TemplateRef<any>;

  @ViewChild("cellPercentage", { static: true })
  customCellPercentage?: TemplateRef<any>;

  @ViewChild("cellActions", { static: true })
  customCellActions?: TemplateRef<any>;

  @ViewChild(DatatableComponent) table?: DatatableComponent;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _customerService: CustomersService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {

    this.calculateColumnFlex(this.columns);
    this.forceColumnRecalculation();
    this.refresh();

    this.columns.forEach((column: any) => {
      if (!column.headerTemplate) {
        switch (column.type) {
          case 'select':
            column.headerTemplate = this.customHeaderSelect;
            break;
          default:
            break;
        }
      }
      if (!column.cellTemplate) {
        switch (column.type) {
          case 'select':
            column.cellTemplate = this.customCellSelect;
            break;
          case 'price':
            column.cellTemplate = this.customCellPrice;
            break;
          case 'badge':
            column.cellTemplate = this.customCellBadge;
            break;
          case 'photo':
            column.cellTemplate = this.customCellPhoto;
            break;
          case 'date':
            column.cellTemplate = this.customCellDate;
            break;
          case 'date-time':
            column.cellTemplate = this.customCellDateTime;
            break;
          case 'date-humanized':
            column.cellTemplate = this.customCellDateHumanized;
            break;
          case 'link':
            column.cellTemplate = this.customCellLink;
            break;
          case 'out-link':
            column.cellTemplate = this.customCellLinkOut;
            break;
          case 'link-email':
            column.cellTemplate = this.customCellLinkEmail;
            break;
          case 'link-phone':
            column.cellTemplate = this.customCellLinkPhone;
            break;
          case 'percentage':
            column.cellTemplate = this.customCellPercentage;
            break;
          case 'actions':
            column.cellTemplate = this.customCellActions;
            break;
          default:
            column.cellTemplate = this.customCellText;
            break;
        }
      }
    })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    if (this.autoReloadInterval) {
      clearInterval(this.autoReloadInterval)
    }
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnChanges() {
  }

  ngAfterViewChecked() {
  }

  ngAfterContentChecked() {
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getQueryOptions() {
    const queryOptions: {
      page?: number,
      pageSize?: number,
      sort?: string,
      sortDirection?: string,
      search?: string,
      strictSearch?: boolean,
      filters?: any
    } = {};
    queryOptions['page'] = this.page;
    queryOptions['pageSize'] = this.pageSize;
    if (this.sort.prop) {
      queryOptions['sort'] = this.sort.prop;
      if (this.sort.dir) {
        queryOptions['sort'] += ':' + this.sort.dir;
      }
    }
    if (this.search) {
      queryOptions['search'] = this.search;
      queryOptions['strictSearch'] = this.strictSearch;
    }
    if (this.filterGroups.length > 0) {
      const filters = this.filterGroups.flatMap(filterGroup => filterGroup.filters);
      queryOptions['filters'] = {};
      filters.forEach(filter => {
        if (filter.value) {
          if (Array.isArray(filter.value)) {
            queryOptions['filters'][filter.field] = filter.value.map((value: any) => this.getQueryFieldValue(value.value, filter.fieldType));
          } else {
            queryOptions['filters'][filter.field] = this.getQueryFieldValue(filter.value.value, filter.fieldType);
          }
        }
      })
    }
    return queryOptions;
  }

  getQueryFieldValue(field: any, type: string) {
    if (!type) {
      return field;
    }
    switch (type) {
      case 'id':
        return {'$oid': field};
      default:
        return field;
    }
  }

  textSearch() {
    this.page = 1;
    this.refresh();
  }

  refresh() {
    this.changeAutoreload();
    this.loading = true;
    this.search = this.search.trim();
    this.dataRetriever(this.getQueryOptions())
      .then((data: DynamicData) => {
        this.loading = false;
        this.rows = data.rows;
        if (this.total != data.total) {
          this.total = data.total;
        }
        if (this.page != data.page) {
          this.page = data.page;
        }
        if (this.totalPages != data.totalPages) {
          this.totalPages = data.totalPages;
        }
        window.dispatchEvent(new Event('resize'));
      });
  }

  onSelect(event: any) {
  }

  onActivate(event: any) {
  }

  pageCallback(pageInfo: any) {
    const newPage = (pageInfo.offset || 0) + 1;
    if (this.page === newPage) {
      return;
    }
    this.pageSize = pageInfo.pageSize || this.pageSize;
    this.page = newPage;
    this.refresh();
  }

  sortCallback(pageInfo: any) {
    this.sort.prop = pageInfo.column.prop;
    this.sort.dir = pageInfo.newValue;
    this.refresh();
  }

  // Calculate column widths or flex
  calculateColumnFlex(columns: any[]) {
    const totalFlex = columns.reduce((total, column) => total + (column.flexGrow || 0), 0);
    columns.forEach(column => {
      column.flexGrow = (column.flexGrow || 1) / totalFlex;
    });
  }

  forceColumnRecalculation() {
    setTimeout(() => {
      // window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  changeFilter(filter: any, event: any) {
    this.page = 1;
    this.refresh();
  }



  keyUpFilter(filter: any, event: any) {
    const functionName = filter.keyUp;
    if (typeof this.filterFunctions[functionName] === 'function') {
      this.filterFunctions[functionName](event, filter);
    } else {
      console.error(`Function ${functionName} does not exist`);
    }
  }

  closeFilter() {
  }

  changeAutoreload() {
    if (this.autoReloadInterval) {
      clearInterval(this.autoReloadInterval);
      this.autoReloadInterval = null;
    }
    if (this.autoReload > 0) {
      this.autoReloadInterval = setInterval(() => {
        this.refresh();
      }, this.autoReload * 1000);
    }
  }

  protected readonly onkeyup = onkeyup;

  externalLink(link: string) {
    if (link.startsWith('http')) {
      window.location.href = link;
    } else {
    }
  }

}

