import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PaymentsListComponent } from "./payments-list/payments-list.component";
import { PaymentsViewComponent } from "./payments-view/payments-view.component";
import { CoreModule } from "@core/components/core.module";
import {NgbInputDatepicker, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import { ClipboardModule } from 'ngx-clipboard';

const routes: Routes = [
  {
    path: 'payments',
    component: PaymentsListComponent,
    data: { animation: 'PaymentsListComponent' }
  },
  {
    path: 'payments/:id',
    component: PaymentsViewComponent,
    data: { animation: 'PaymentsViewComponent' }
  },
];

@NgModule({
  declarations: [
    PaymentsListComponent,
    PaymentsViewComponent
  ],
  imports: [
    ClipboardModule,
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    NgbInputDatepicker,
    ReactiveFormsModule,
    NgSelectModule,
    NgbTooltip
  ],
  providers: []
})
export class PaymentsModule {
}
