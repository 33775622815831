import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AlertsListComponent } from "./alerts-list/alerts-list.component";
import { CoreModule } from "@core/components/core.module";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {AlertsViewComponent} from "./alerts-view/alerts-view.component";
import {NgbAlert} from "@ng-bootstrap/ng-bootstrap";

const routes: Routes = [
  {
    path: 'alerts',
    component: AlertsListComponent,
    data: { animation: 'AlertsListComponent' }
  },
  {
    path: 'alerts/:id',
    component: AlertsViewComponent,
    data: { animation: 'AlertsViewComponent' }
  },
];

@NgModule({
  declarations: [
    AlertsListComponent,
    AlertsViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    FormsModule,
    NgSelectModule,
    NgbAlert
  ],
  providers: []
})
export class AlertsModule {
}
