<div class="row" *ngIf="loaded | async">
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Banner {{ banner.id }}</h5>
        <div class="row">
          <div class="col-6">
            <label class="text-primary">Ordine:</label>
            <input type="number" class="form-control" [(ngModel)]="banner.order"/>
          </div>
          <div class="col-6">
            <label class="text-primary">Dimensione:</label>
            <input type="number" class="form-control" [(ngModel)]="banner.size"/>
          </div>
          <div class="col-12 mt-1">
            <label>Network:</label>
            <ng-select
              [items]="networks"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="banner.network"
            ></ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-7 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <div class="mb-1 d-flex justify-content-between">
          <div>
            <h5 class="card-title">Immagine e Link</h5>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-primary px-1" (click)="addPicture()">
              <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
            </button>
          </div>
        </div>
        <div class="row">
          <ng-container *ngFor="let picture of banner.pictures; let i = index">
            <div class="col-4">
              <div class="mt-1 d-flex justify-content-between">
                <div>
                  <span class="text-primary">Immagine {{ i + 1 }}</span>
                </div>
                <div class="d-flex justify-content-end align-items-center">
                  <button type="button" class="btn btn-danger btn-sm align-middle p-025 font-small-2 mr-05"
                          (click)="deletePicture(i)">
                    <app-svg-icon class="d-inline-block align-middle no-margin" name="delete"></app-svg-icon>
                  </button>
                </div>
              </div>
              <label class="text-primary">src:</label>
              <input type="text" class="form-control" [(ngModel)]="picture.src"/>
              <button class="btn btn-primary full-width mt-05" (click)="uploadImage(i)">
                <app-svg-icon name="upload"></app-svg-icon>
              </button>
              <label class="text-primary mt-1">link:</label>
              <input type="text" class="form-control" [(ngModel)]="picture.link"/>
              <img *ngIf="picture.src" [src]="picture.src" class="img-fluid mt-1 all-borders" alt="Immagine {{ i + 1 }}"/>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Azioni</h5>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-primary btn-block" (click)="saveBanner()">
            <app-svg-icon name="save"></app-svg-icon>
            Salva
          </button>
        </div>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-danger btn-block" (click)="deleteBanner()">
            <app-svg-icon name="delete"></app-svg-icon>
            Elimina
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
