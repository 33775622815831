<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Abbonamenti</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.subscriptionsList.add')" class="btn btn-primary px-1"
            (click)="addNewSubscriptionModal(newSubscriptionModal)">
      <app-svg-icon class="d-inline-block align-middle" name="gift"></app-svg-icon>
      Regalare un abbonamento
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getSubscription.bind(this)"
    [filterGroups]="tableConfig.filterGroups"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
    [autoReloadEnable]="true"
  >
  </dynamic-table>
</div>


<ng-template
  #rewardsCell
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-rowIndex="rowIndex"
  let-rewardBadges="value"
>
  <ng-container *ngIf="rewardBadges.length > 0">
    <div class="flex flex-row items-center">
      <ng-container *ngFor="let badge of rewardBadges">
        <a
          class="badge badge-pill mr-05 p-05 {{badge.class}}"
          ngbTooltip="{{badge.amount}}"
        >
          {{ badge.label }}
        </a>
      </ng-container>
    </div>
  </ng-container>
</ng-template>


<ng-template #newSubscriptionModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="pdf-modal">Regalare un abbonamento</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0">
    <div class="row">
      <div class="col-12">
        <label class="text-primary">Azienda</label>
        <ng-select
          id="customer"
          [items]="businesses"
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="true"
          [placeholder]="'Seleziona una azienda'"
          (change)="selectClient($event)"
          (keyup)="searchBusiness($event)"
          appendTo="body"
          class="z-index-2073"
          bindLabel="name"
          addTagText="Usa "
          [addTag]="true"
        >
        </ng-select>
      </div>

      <div class="col-12 mt-1">
        <label class="text-primary">Piano</label>
        <ng-select
          id="plan"
          [items]="plans"
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="true"
          [placeholder]="'Seleziona una piano'"
          (change)="selectPlan($event)"
          (keyup)="searchPlans($event)"
          appendTo="body"
          class="z-index-2073"
          bindLabel="code"
          addTagText="Usa "
          [addTag]="true"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="gift()">Regala</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Close click')">Chiudi</button>
  </div>
</ng-template>
