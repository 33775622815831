import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from "./login/login.component";
import {BusinessSelectionComponent} from "./business-selection/business-selection.component";
import { AuthGuard } from "./AuthGuard";


const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      animation: 'LoginComponent'
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'business-selection',
    component: BusinessSelectionComponent,
    data: {
      animation: 'BusinessSelectionComponent',
      userLoggedIn: true
    }
  }

];

@NgModule({
  declarations: [
    LoginComponent,
    BusinessSelectionComponent
  ],
  exports: [
    LoginComponent,
    BusinessSelectionComponent
  ],
  imports: [CommonModule, RouterModule.forChild(routes), NgbModule, FormsModule, ReactiveFormsModule]
})
export class AuthenticationModule {}
