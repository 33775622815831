<div class="row" xmlns="http://www.w3.org/1999/html" *ngIf="loaded | async">
  <h4 class="card-title">Menu {{menuItem.name}}</h4>
  <div class="col-4 mt-1">
    <div class="card glassy card-full-height card-short-height overflow-hidden">
      <div class="card-body overflow-hidden">
        <h4 class="card-title">Dettagli</h4>
        <div class="row">
          <div class="col-6">
            <label for="name">Nome</label>
            <input type="text" class="form-control" id="name" [(ngModel)]="menuItem.name">
          </div>
          <div class="col-6">
            <label for="price">Codice</label>
            <input type="text" class="form-control" id="price" [(ngModel)]="menuItem.code">
          </div>
          <div class="col-6 mt-1">
            <label for="order">Ordine</label>
            <input type="number" class="form-control" id="order" [(ngModel)]="menuItem.order">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 mt-1">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-body">
        <h4 class="card-title">Azioni</h4>
        <div class="row">
          <div class="mt-1 col-12 d-flex justify-content-center">
            <button class=" col-12 btn btn-primary btn-block" (click)="saveMenu()">
              <app-svg-icon name="save"></app-svg-icon>
              Salva
            </button>
          </div>
          <div class="mt-1 col-12 d-flex justify-content-center">
            <button class=" col-12 btn btn-danger btn-block" (click)="deleteMenu()">
              <app-svg-icon name="delete"></app-svg-icon>
              Elimina
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <ng-container *ngFor="let children of menuItem.children">
    <div class="col-12">
      <div class="card glassy card-full-height card-short-height">
        <div class="card-body">
        </div>
      </div>
    </div>
  </ng-container>
</div>

