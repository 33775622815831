import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { DynamicData } from "../types/dynamic-table/dynamic-data";
import { ListParams } from "../types/api/list-params";

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  // -----------------------------------------------------------------------------------------------------
  // @ public
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ private
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  getLastOrders(filters?: any, groupBy?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('charts', 'lastOrders', {}, { body: { filters, groupBy }})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getKromedaProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('charts', 'kromedaProfile')
        .then((response) => {
          console.log(response)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }
}
