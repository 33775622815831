import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import { ApplicationService } from "@core/services/application.service";

@Component({
  selector: 'app-order-view',
  templateUrl: './quote-view.component.html',
  styleUrls: ['./quote-view.component.scss']
})

export class QuoteViewComponent implements OnInit, OnDestroy {
  public currentInvoice: any = {};
  public loaded:Promise<boolean> = Promise.resolve(false);
  // Private
  private _unsubscribeAll: Subject<any>;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  public currentCode: string  = '';


  constructor(
    private _applicationService: ApplicationService,
    private _route: ActivatedRoute,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-invoices-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.currentCode = this._route.snapshot.paramMap.get('code') || '';
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
}
