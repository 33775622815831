import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { DynamicData } from "../types/dynamic-table/dynamic-data";
import { ListParams } from "../types/api/list-params";
import { Group } from "../types/data/group";
import { array } from "@amcharts/amcharts5";



@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public setdInvoices(id: string, inputJson: any): Promise<any> {
    return this._apiService.make('pdf', 'setInvoice', {}, { body: { invoice: inputJson }})
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        throw error;
      });
  }


  public async getPdf(id: string): Promise<any> {
    try {
      const response = await this._apiService.make('pdf', 'getPdf', { id: id }, { responseType: 'blob' });
      return response;
    } catch (error) {
      throw error;
    }
  }

}
