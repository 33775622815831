<a class="menu-item"
   [class.active]="item.selected"
   [routerLink]="item.link" *ngIf="!item.children">
  <app-svg-icon *ngIf="item.icon" [name]="item.icon"></app-svg-icon>
  <app-svg-icon *ngIf="!item.icon" name="circle"></app-svg-icon>
  <span class="menu-title ml-05">{{item.name}}</span>
</a>

<div class="menu-item"
     [class.selected]="showChildren"
     *ngIf="item.children" (click)="showChildren = !showChildren">
  <app-svg-icon *ngIf="item.icon" [name]="item.icon"></app-svg-icon>
  <app-svg-icon *ngIf="!item.icon" name="circle"></app-svg-icon>
  <span class="menu-title ml-05">{{item.name}}</span>
  <div class="arrow-container">
    <app-svg-icon name="chevron"></app-svg-icon>
  </div>
</div>
<div class="children-container" *ngIf="item.children">
  <ng-container *ngFor="let item of item.children">
    <app-menu-item class="menu-container children-item"
                   [class.active]="item.selected"
                   [item]="item"></app-menu-item>
  </ng-container>
</div>
