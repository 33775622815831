<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Ordini</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.ordersList.pdf')" class="btn btn-primary mr-1"
            (click)="openPdfModal(invoicePdfModal)">
      <app-svg-icon class="d-inline-block align-middle" name="download"></app-svg-icon>
      PDF
    </button>
    <button *ngIf="_authService.checkPermission('admin.page.ordersList.add')" class="btn btn-primary px-1"
            (click)="showProgressStepsWithHtmlFromTemplate()">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getOrders.bind(this)"
    [columns]="tableConfig.columns"
    [filterGroups]="tableConfig.filterGroups"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
    [autoReloadEnable]="true"
  >
  </dynamic-table>
</div>


<ng-template
  #paymentsCell
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-rowIndex="rowIndex"
  let-payments="value"
>
  <ng-container *ngIf="payments.length > 0">
    <div class="flex flex-row items-center">
      <ng-container *ngFor="let payment of payments">
        <ng-container *ngIf="payment.payment">
          <a *ngIf="column.extra?.status?.[payment.payment.status]"
             class="badge badge-pill {{column.extra.status[payment.payment.status]?.class}}"
             ngbTooltip="{{payment.payment.code}} | {{payment.payment.amount | price}} | {{column.extra.status[payment.payment.status]?.label}}"
             routerLink="/payments/{{payment.payment.code}}"
          >
            {{ column.extra?.method?.[payment.payment.method?.driver] ? column.extra?.method?.[payment.payment.method?.driver].label : payment.payment.method?.driver }}
          </a>
          <a *ngIf="!column.extra?.status?.[payment.payment.status]" class="badge badge-pill badge-light-danger"
             ngbTooltip="{{payment.payment.code}} | {{payment.payment.amount | price}} | {{payment.payment.status}}">
            {{ column.extra?.method?.[payment.payment.method?.driver] ? column.extra?.method?.[payment.payment.method?.driver].label : payment.payment.method?.driver }}
          </a>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>


<ng-template #addNewOrderModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="newOrder-modal">Ordine manuale</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="column">
      <!--      <h6>Intestazione</h6>-->
      <!--      <div class="row mb-1">-->
      <!--        <div class="form-group col-5 d-flex flex-column">-->
      <!--          <label for="customer">Cliente</label>-->
      <!--          <input-->
      <!--            #searchInput-->
      <!--            type="text"-->
      <!--            class="form-control"-->
      <!--            placeholder="{{search}}"-->
      <!--            aria-describedby="basic-addon-search2"-->
      <!--            [(ngModel)]="search"-->
      <!--            (ngModelChange)="searchBusiness(searchInput.value)"-->
      <!--            (keyup.enter)="searchBusiness(searchInput.value)"-->
      <!--            id="customer"-->
      <!--          />-->
      <!--          <div class="card dropdown2" *ngIf="showDropdown && businesses.length > 0">-->
      <!--            <ul class="suggested-products">-->
      <!--              <ng-container *ngFor="let business of businesses">-->
      <!--                <li class="cursor-pointer" (click)="selectBusiness(business)">-->
      <!--                  <span class="font-weight-bold m-1">{{ business.code }} - {{ business.name }}</span>-->
      <!--                </li>-->
      <!--              </ng-container>-->
      <!--            </ul>-->
      <!--          </div>-->
      <!--          <div class="card dropdown2" *ngIf="showDropdown && businesses.length == 0">-->
      <!--            <ul class="suggested-products">-->
      <!--              <li>-->
      <!--                <span>Nessun risultato</span>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <div class="form-group col-5 d-flex flex-column">-->
      <!--          <label for="supplier">Magazzino</label>-->
      <!--          <input-->
      <!--            #searchInput2-->
      <!--            type="text"-->
      <!--            class="form-control"-->
      <!--            placeholder="{{search2}}"-->
      <!--            aria-describedby="basic-addon-search2"-->
      <!--            [(ngModel)]="search2"-->
      <!--            (ngModelChange)="searchSuppliers(searchInput2.value)"-->
      <!--            (keyup.enter)="searchSuppliers(searchInput2.value)"-->
      <!--            id="supplier"-->
      <!--          />-->
      <!--          <div class="card dropdown2" *ngIf="showDropdown2 && suppliers2.length > 0">-->
      <!--            <ul class="suggested-products">-->
      <!--              <ng-container *ngFor="let supplier of suppliers2">-->
      <!--                <li class="cursor-pointer" (click)="selectSupplier(supplier)">-->
      <!--                  <span class="font-weight-bold m-1">{{ supplier.name }}</span>-->
      <!--                </li>-->
      <!--              </ng-container>-->
      <!--            </ul>-->
      <!--          </div>-->
      <!--          <div class="card dropdown2" *ngIf="showDropdown2 && suppliers2.length == 0">-->
      <!--            <ul class="suggested-products">-->
      <!--              <li>-->
      <!--                <span>Nessun risultato</span>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        &lt;!&ndash; same dropdown method but for the delivery prices &ndash;&gt;-->
      <!--        <div class="form-group col-2 d-flex flex-column">-->
      <!--          <label for="delivery">Spedizione</label>-->
      <!--          <input-->
      <!--            #searchInput3-->
      <!--            type="text"-->

      <!--            class="form-control"-->
      <!--            placeholder="{{search3}}"-->
      <!--            aria-describedby="basic-addon-search3"-->
      <!--            [(ngModel)]="search3"-->
      <!--            (ngModelChange)="searchDelivery(searchInput3.value)"-->
      <!--            (keyup.enter)="searchDelivery(searchInput3.value)"-->
      <!--            id="delivery"-->
      <!--          />-->
      <!--          <div class="card dropdown2" *ngIf="showDropdown3 && deliveries.length > 0">-->
      <!--            <ul class="suggested-products">-->
      <!--              <ng-container *ngFor="let delivery of deliveries">-->
      <!--                <li class="cursor-pointer" (click)="selectDelivery(delivery)">-->
      <!--                  <span class="font-weight-bold m-1">{{ delivery.name }}</span>-->
      <!--                </li>-->
      <!--              </ng-container>-->
      <!--            </ul>-->
      <!--          </div>-->
      <!--          <div class="card dropdown2" *ngIf="showDropdown3 && businesses.length == 0">-->
      <!--            <ul class="suggested-products">-->
      <!--              <li>-->
      <!--                <span>Nessun risultato</span>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div></div>-->
      <!--      <h6 class="mt-2">Prodotti</h6>-->
      <!--      <form class="auth-register-form" [formGroup]="productsForm" (ngSubmit)="onSubmit()">-->
      <!--        <div formArrayName="productsArray">-->
      <!--          <div *ngFor="let product of newOrderProducts; let i = index" [formGroupName]="i">-->
      <!--            &lt;!&ndash; Your input fields for each product here &ndash;&gt;-->
      <!--            <div class="row mt-1">-->
      <!--              <div class="class form-group col-2">-->
      <!--                <label for="product-code{{i}}">Codice</label>-->
      <!--                <input-->
      <!--                  #searchInput-->
      <!--                  type="text"-->
      <!--                  class="form-control"-->
      <!--                  id="product-code{{i}}"-->
      <!--                  formControlName="description"-->
      <!--                />-->
      <!--              </div>-->
      <!--              &lt;!&ndash; description &ndash;&gt;-->
      <!--              <div class="form-group col-3">-->
      <!--                <label for="description{{i}}">Descrizione</label>-->
      <!--                <input-->
      <!--                  type="text"-->
      <!--                  class="form-control"-->
      <!--                  id="description{{i}}"-->
      <!--                  formControlName="description"-->
      <!--                />-->
      <!--              </div>-->
      <!--              &lt;!&ndash; brand &ndash;&gt;-->
      <!--              <div class="form-group col-2">-->
      <!--                <label for="brand{{i}}">Marca</label>-->
      <!--                <input-->
      <!--                  type="text"-->
      <!--                  class="form-control"-->
      <!--                  id="brand{{i}}"-->
      <!--                  formControlName="brand"-->
      <!--                />-->
      <!--              </div>-->
      <!--              <div class="form-group col-1">-->
      <!--                <label for="quantity{{i}}">Quantità</label>-->
      <!--                <input-->
      <!--                  type="number"-->
      <!--                  class="form-control"-->
      <!--                  id="quantity{{i}}"-->
      <!--                  formControlName="quantity"-->
      <!--                />-->
      <!--              </div>-->
      <!--              &lt;!&ndash; price &ndash;&gt;-->
      <!--              <div class="form-group col-1">-->
      <!--                <label for="price{{i}}">Prezzo</label>-->
      <!--                <input-->
      <!--                  type="number"-->
      <!--                  class="form-control"-->
      <!--                  id="price{{i}}"-->
      <!--                  formControlName="price"-->
      <!--                />-->
      <!--              </div>-->
      <!--              &lt;!&ndash; cost &ndash;&gt;-->
      <!--              <div class="form-group col-1">-->
      <!--                <label for="cost{{i}}">Costo</label>-->
      <!--                <input-->
      <!--                  type="number"-->
      <!--                  class="form-control"-->
      <!--                  id="cost{{i}}"-->
      <!--                  formControlName="cost"-->
      <!--                />-->
      <!--              </div>-->
      <!--              &lt;!&ndash; total &ndash;&gt;-->
      <!--              <div class="form-group col-1">-->
      <!--                <label for="total{{i}}">Totale</label>-->
      <!--                <input-->
      <!--                  type="number"-->
      <!--                  class="form-control"-->
      <!--                  id="total{{i}}"-->
      <!--                  formControlName="total"-->
      <!--                />-->
      <!--              </div>-->
      <!--              &lt;!&ndash; remove button &ndash;&gt;-->
      <!--              <div class="form-group col-1 align-middle center mt-2">-->
      <!--                <button-->
      <!--                  type="button"-->
      <!--                  class="btn btn-danger remove-button"-->
      <!--                  (click)="removeProductRow(i)"-->
      <!--                >-->
      <!--                  <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>-->
      <!--                </button>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="row mt-1 align-content-start">-->
      <!--          &lt;!&ndash; add button &ndash;&gt;-->
      <!--          <div class="form-group col-3 align-content-start">-->
      <!--            <button type="submit" class="btn btn-success" (click)="addProductRow()">-->
      <!--              <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>-->
      <!--              Aggiungi prodotto-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </form>-->
      <form>
        <h6 class="mt-2">Metodi di Pagamenti</h6>
        <div class="row">
          <div class="form-group col-4">
            <label for="paymentMethod">Metodi di pagamento del cliente selezionato</label>
            <select class="form-control" id="paymentMethod" [(ngModel)]="selectedMethod">
              <option *ngFor="let method of paymentMethods" [value]="method._id">{{ method.name }}
              </option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Invia</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>

<ng-template #invoicePdfModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="pdf-modal">Modifica PDF</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="downloadPDF()">
    <div class="modal-body" tabindex="0">
      <div class="row">
        <h5 class="text-primary mt-1">Dettagli del documento</h5>
        <div class="form-group col-4 mb-1">
          <label for="invoiceType">Nome del documento</label>
          <ng-select [items]="invoice.documentNames"
                     [(ngModel)]="invoice.documentName"
                     name="invoiceType"
                     id="invoiceType">
          </ng-select>
        </div>
        <div class="col-8 mb-1"></div>
        <div class="form-group col-3">
          <label for="returnedNumber">Numero</label>
          <input type="text" class="form-control" id="returnedNumber" [(ngModel)]="invoice.returned.number"
                 name="returnedNumber">
        </div>
        <div class="form-group col-3">
          <label for="returnedDate">Data</label>
          <input type="text" class="form-control" id="returnedDate" ngbDatepicker #basicDP="ngbDatepicker"
                 [(ngModel)]="formattedReturnedDate" (ngModelChange)="onDateChange1($event)"
                 name="returnedDate">
          <div class="input-group-append">
            <button class="feather icon-calendar cursor-pointer pt-05 mt-1 mr-05" (click)="basicDP.toggle()"
                    type="button" rippleEffect>
              <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
            </button>
          </div>
        </div>

        <div class="form-group col-3">
          <label for="returnedReason">Causale</label>
          <input type="text" class="form-control" id="returnedReason" [(ngModel)]="invoice.returned.casual"
                 name="returnedReason">
        </div>
        <div class="form-group col-3">
          <label for="returnedReference">Riferimento</label>
          <input type="text" class="form-control" id="returnedReference" [(ngModel)]="invoice.returned.reference"
                 name="returnedReference">
        </div>
        <h6 class="text-primary mt-1">Dettagli aziendali</h6>
        <div class="form-group col-3">
          <label for="businessName">Nome azienda</label>
          <ng-select
            #clientSelect
            [items]="invoice.clients"
            bindLabel="name"
            [(ngModel)]="selectedClient"
            (ngModelChange)="updateBusinessData()"
            name="businessName"
            id="businessName"
            addTagText="Usa "
            [addTag]="true"
          >
            <ng-template #optionTemplate let-item="item">
              <div *ngIf="!item || item === selectedClient">
                {{ item }}
              </div>
              <div *ngIf="item && item !== selectedClient">
                {{ item.name }}
              </div>
            </ng-template>
          </ng-select>

        </div>

        <div class="form-group col-3">
          <label for="businessVat">P.IVA</label>
          <input type="text" class="form-control" id="businessVat" [(ngModel)]="invoice.businessData.vat"
                 name="businessVat">
        </div>
        <div class="form-group col-3">
          <label for="businessAddress1">Indirizzo (via e numero)</label>
          <input type="text" class="form-control" id="businessAddress1" [(ngModel)]="invoice.businessData.address1"
                 name="businessAddress1">
        </div>
        <div class="form-group col-3">
          <label for="businessAddress2">Indirizzo (cap, città, provincia, regione e paese)</label>
          <input type="text" class="form-control" id="businessAddress2" [(ngModel)]="invoice.businessData.address2"
                 name="businessAddress2">
        </div>

        <h6 class="text-primary mt-1">Destinazione</h6>
        <div class="form-group col-3">
          <label for="destinationName">Nome azienda</label>
          <ng-select
            #destinationSelect
            [items]="invoice.clients2"
            bindLabel="name"
            [(ngModel)]="selectedDestination"
            (ngModelChange)="updateDestination()"
            name="destinationName"
            id="destinationName"
            addTagText="Usa "
            [addTag]="true">
          </ng-select>
        </div>
        <div class="form-group col-3">
          <label for="destinationVat">P.IVA</label>
          <input type="text" class="form-control" id="destinationVat" [(ngModel)]="invoice.destination.vat"
                 name="destinationVat">
        </div>
        <div class="form-group col-3">
          <label for="destinationAddress1">Indirizzo (via e numero)</label>
          <input type="text" class="form-control" id="destinationAddress1" [(ngModel)]="invoice.destination.address1"
                 name="destinationAddress1">
        </div>
        <div class="form-group col-3">
          <label for="destinationAddress2">Indirizzo (cap, città, provincia, regione e paese)</label>
          <input type="text" class="form-control" id="destinationAddress2" [(ngModel)]="invoice.destination.address2"
                 name="destinationAddress2">
        </div>

        <h6 class="text-primary mt-1">Dettagli</h6>
        <div class="form-group col-4">
          <label for="number">Numero</label>
          <input type="text" class="form-control" id="number" [(ngModel)]="invoice.number" name="number">
        </div>
        <div class="form-group col-4">
          <label for="invoiceDate">Data</label>
          <input type="text" class="form-control" id="invoiceDate" ngbDatepicker #basicDP2="ngbDatepicker"
                 [(ngModel)]="formattedInvoiceDate" (ngModelChange)="onDateChange2($event)"
                 name="returnedDate">

          <div class="input-group-append">
            <button class="feather icon-calendar cursor-pointer pt-05 mt-1 mr-05" (click)="basicDP2.toggle()"
                    type="button" rippleEffect>
              <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
            </button>
          </div>
        </div>
        <div class="form-group col-4">
          <label for="productsQuantity">Quantità prodotto</label>
          <input type="text" class="form-control" id="productsQuantity" [(ngModel)]="invoice.productQuantity"
                 name="productsQuantity">
        </div>


        <h6 class="text-primary mt-1">Spedizione</h6>
        <div class="form-group col-3">
          <label for="transportMethod">Trasporto a cura di</label>
          <input type="text" class="form-control" id="transportMethod" [(ngModel)]="invoice.transport.method"
                 name="transportMethod">
        </div>
        <div class="form-group col-3">
          <label for="shippingMethod">Spedizione a</label>
          <input type="text" class="form-control" id="shippingMethod" [(ngModel)]="invoice.shipping.method"
                 name="shippingMethod">
        </div>
        <div class="form-group col-3">
          <label for="shippingPacks">Numero colli</label>
          <input type="text" class="form-control" id="shippingPacks" [(ngModel)]="invoice.shipping.packages"
                 name="shippingPacks">
        </div>
        <div class="form-group col-3">
          <label for="vectorName">Nome Vettore</label>
          <input type="text" class="form-control" id="vectorName" [(ngModel)]="invoice.vectorName"
                 name="vectorName">
        </div>
        <h5 class="text-primary mt-1">Prodotti
          <button class="btn btn-primary" (click)="addProductLine()">
            <app-svg-icon name="add"/>
          </button>
        </h5>
        <ng-container *ngFor="let line of invoice.lines; let i = index">
          <h6 class="text-primary mt-1">Prodotto {{ i + 1 }}</h6>
          <div class="form-group col-3">
            <label for="productCode{{i}}">Codice</label>
            <input type="text" class="form-control" id="productCode{{i}}" [(ngModel)]="line.code"
                   name="productCode{{i}}">
          </div>
          <div class="form-group col-5">
            <label for="productDescription{{i}}">Descrizione</label>
            <input type="text" class="form-control" id="productDescription{{i}}" [(ngModel)]="line.description"
                   name="productDescription{{i}}">
          </div>
          <div class="form-group col-2">
            <label for="productUnit{{i}}">U.m.</label>
            <input type="text" class="form-control" id="productUnit{{i}}" [(ngModel)]="line.unit"
                   name="productUnit{{i}}">
          </div>
          <div class="form-group col-1">
            <label for="productQuantity{{i}}">Q.tà</label>
            <input type="text" class="form-control" id="productQuantity{{i}}" [(ngModel)]="line.quantity"
                   name="productQuantity{{i}}">
          </div>
          <div class="form-group col-1">
            <div class="column d-flex flex-column">
              <label>Rimuovere</label>
              <app-svg-icon (click)="removeProduct(i)" class="d-inline-block align-middle cursor-pointer mt-05 ml-2"
                            name="close"></app-svg-icon>
            </div>
          </div>

        </ng-container>

      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary">Scarica</button>
      <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
    </div>
  </form>
</ng-template>


<div style="display: none;">
  <div #clientStep>
    <div class="row mb-1 overflow-visible">
      <div class="form-group col-4 d-flex flex-column overflow-visible">
        <label for="customer">Cliente</label>
        <ng-select
          id="customer"
          [items]="businesses"
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="true"
          [placeholder]="'Seleziona una azienda'"
          (change)="selectClient($event)"
          (keyup)="searchBusiness($event)"
          appendTo="body"
          class="z-index-2073"
          bindLabel="name"
          addTagText="Usa "
          [addTag]="true"
        >
        </ng-select>
      </div>

      <div class="form-group col-4 d-flex flex-column">
        <label for="supplier">Magazzino</label>
        <ng-select
          id="supplier"
          [items]="suppliers"
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="true"
          [placeholder]="'Seleziona una magazzino'"
          (change)="selectSupplier($event)"
          (keyup)="searchSuppliers($event)"
          [ngModel]="selectedSupplier"
          appendTo="body"
          class="z-index-1061"
          bindLabel="name"
          addTagText="Usa "
          [addTag]="true"
        >
        </ng-select>
      </div>
      <div class="form-group col-4 d-flex flex-column">
        <label for="network">Network</label>
        <ng-select
          id="network"
          [items]="networks"
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="true"
          [placeholder]="'Seleziona un network'"
          (change)="selectNetwork($event)"
          (keyup)="searchNetwork($event)"
          [ngModel]="selectedNetwork"
          appendTo="body"
          class="z-index-1061"
          bindLabel="name"
          bindValue="id"
          addTagText="Usa "
          [addTag]="true"
        >
        </ng-select>
      </div>
    </div>
  </div>
</div>


<div style="display: none;">
  <div #addressStep>
    <div class="row">
      <div class="col-3">
        <label for="locationStreet">Via</label>
        <input type="text" class="form-control" id="locationStreet" [(ngModel)]="selectedAddress.street"
               name="locationStreet">
      </div>
      <div class="col-1">
        <label for="locationNumber">Numero</label>
        <input type="text" class="form-control" id="locationNumber" [(ngModel)]="selectedAddress.number"
               name="locationCity">
      </div>
      <div class="col-1">
        <label for="locationZip">CAP</label>
        <input type="text" class="form-control" id="locationZip" [(ngModel)]="selectedAddress.zip" name="locationZip">
      </div>
      <div class="col-2">
        <label for="locationCity">Città</label>
        <input type="text" class="form-control" id="locationCity" [(ngModel)]="selectedAddress.city"
               name="locationCity">
      </div>
      <div class="col-1">
        <label for="locationProvince">Provincia</label>
        <input type="text" class="form-control" id="locationProvince" [(ngModel)]="selectedAddress.province"
               name="locationProvince">
      </div>
      <div class="col-2">
        <label for="locationRegion">Regione</label>
        <input type="text" class="form-control" id="locationRegion" [(ngModel)]="selectedAddress.region"
               name="locationRegion">
      </div>
      <div class="col-2">
        <label for="locationCountry">Paese</label>
        <input type="text" class="form-control" id="locationCountry" [(ngModel)]="selectedAddress.country"
               name="locationCountry">
      </div>
    </div>
  </div>
</div>


<div style="display: none;">
  <div #deliveryStep>
    <div class="row">
      <div class="col-2">
        <label for="delivery">Spedizione</label>
        <ng-select
          id="delivery"
          [items]="deliveries"
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="true"
          [placeholder]="'Seleziona una spedizione'"
          (change)="selectDelivery($event)"
          (keyup)="searchDelivery($event)"
          [ngModel]="selectedDelivery"
          appendTo="body"
          class="z-index-1061"
          bindLabel="name"
          addTagText="Usa "
          [addTag]="true"
        >
        </ng-select>
      </div>
      <div class="col-3">
        <label for="deliveryPrice">Prezzo</label>
        <input type="text" class="form-control" id="deliveryPrice" [(ngModel)]="selectedDelivery.price">
      </div>
      <div class="col-3">
        <label for="deliveryDate">Data</label>
        <input type="text" class="form-control" id="deliveryDate" ngbDatepicker #basicDP3="ngbDatepicker"
               [(ngModel)]="selectedDelivery.date"
               [disabled]="true"
               name="deliveryDate">
      </div>
      <div class="col-2">
        <label for="cut-off">Cut off</label>
        <input type="text" class="form-control" [disabled]="true" id="cut-off" [(ngModel)]="selectedDelivery.cutoff"
               name="cut-off">
      </div>
      <div class="col-2">
        <label for="free">Gratis</label>
        <input type="text" class="form-control" [disabled]="true" id="free" [(ngModel)]="selectedDelivery.free"
               name="free">
      </div>
    </div>
  </div>
</div>


<div style="display: none;">
  <div #productStep>
    <form class="auth-register-form" [formGroup]="productsForm" (ngSubmit)="onSubmit()">
      <div formArrayName="productsArray">
        <div *ngFor="let product of newOrderProducts; let i = index" [formGroupName]="i">
          <!-- Your input fields for each product here -->
          <div class="row mt-1">
            <div class="class form-group col-2">
              <label for="product-code{{i}}">Codice</label>
              <input
                #searchInput
                type="text"
                class="form-control"
                id="product-code{{i}}"
                formControlName="code"
              />
            </div>
            <!-- description -->
            <div class="form-group col-3">
              <label for="description{{i}}">Descrizione</label>
              <input
                type="text"
                class="form-control"
                id="description{{i}}"
                formControlName="description"
              />
            </div>
            <!-- brand -->
            <div class="form-group col-2">
              <label for="brand{{i}}">Marca</label>
              <ng-select class="form-group col-12" [items]="brands" bindLabel="name"
                         [(ngModel)]="selectedBrand" name="brand" id="brand{{i}}"
                         [multiple]="false"
                         [closeOnSelect]="true"
                         [searchable]="true"
                         [placeholder]="'Seleziona una marca'"
                         (change)="selectBrand($event, i)"
                         (keyup)="searchBrand($event)"
                         [ngModel]="selectedBrand"
                         addTagText="Usa "
                         appendTo="body"
                         class="z-index-1061"
                         [addTag]="true">
              </ng-select>
            </div>
            <div class="form-group col-1">
              <label for="quantity{{i}}">Quantità</label>
              <input
                type="number"
                class="form-control"
                id="quantity{{i}}"
                formControlName="quantity"
                (input)="recalculateTotal(i)"
              />
            </div>
            <!-- price -->
            <div class="form-group col-1">
              <label for="price{{i}}">Prezzo</label>
              <input
                type="number"
                class="form-control"
                id="price{{i}}"
                formControlName="price"
                (input)="recalculateTotal(i)"
              />
            </div>
            <div class="form-group col-1">
              <label for="cost{{i}}">Costo</label>
              <input
                type="number"
                class="form-control"
                id="cost{{i}}"
                formControlName="cost"
              />
            </div>
            <div class="form-group col-1">
              <label for="total{{i}}">Totale</label>
              <input
                [disabled]="true"
                type="number"
                class="form-control"
                id="total{{i}}"
                formControlName="total"
              />
            </div>
            <!-- remove button -->
            <div class="form-group col-1 align-middle center mt-2">
              <button
                type="button"
                class="btn btn-danger remove-button"
                (click)="removeProductRow(i)"
              >
                <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1 align-content-start">
        <div class="form-group col-2 align-content-start">
          <button type="submit" class="btn btn-success col-12" (click)="addProductRow()">
            <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
            Aggiungi prodotto
          </button>
        </div>
      </div>
    </form>
  </div>
</div>


<div style="display: none;">
  <div #paymentStep>
    <form class="payment-register-form" [formGroup]="paymentsForm" (ngSubmit)="onSubmit()">
      <div formArrayName="paymentsArray">
        <div *ngFor="let payment of newOrderPayments; let i = index" [formGroupName]="i">
          <div class="row mt-1">
            <div class="class form-group col-2">
              <label for="payment-code{{i}}">Codice</label>
              <input
                type="text"
                class="form-control"
                id="payment-code{{i}}"
                formControlName="code"
              />
            </div>
            <div class="form-group col-3">
              <label for="reference{{i}}">
                Riferimento
              </label>
              <input
                type="text"
                class="form-control"
                id="reference{{i}}"
                formControlName="reference"
              />
            </div>
            <div class="form-group col-3">
              <label for="paymentMethods{{i}}">
                Metodo di pagamento
              </label>
              <ng-select class="form-group col-12" [items]="paymentMethods" bindLabel="name"
                         [(ngModel)]="selectedMethod" name="paymentMethod" id="paymentMethods{{i}}"
                         [multiple]="false"
                         [closeOnSelect]="true"
                         [searchable]="true"
                         [placeholder]="'Seleziona una metodo di pagamento'"
                         (change)="selectMethod($event)"
                         (keyup)="searchMethod($event)"
                         [ngModel]="selectedMethod"
                         appendTo="body"
                         class="z-index-1061"
                         addTagText="Usa "
                         [addTag]="true">
              </ng-select>
            </div>
            <div class="form-group col-2">
              <label for="value{{i}}">Importo</label>
              <input
                type="text"
                class="form-control"
                id="value{{i}}"
                formControlName="amount"
              />
            </div>
            <div class="form-group col-1">
              <label for="cashback{{i}}">CashBack</label>
              <input
                type="number"
                class="form-control"
                id="cashback{{i}}"
                formControlName="cashback"
              />
            </div>
            <!-- remove button -->
            <div class="form-group col-1 align-middle center mt-2">
              <button
                type="button"
                class="btn btn-danger remove-button"
                (click)="removePaymentRow(i)"
              >
                <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1 align-content-start">
        <div class="form-group col-2 align-content-start">
          <button type="submit" class="btn btn-success col-12" (click)="addPaymentRow()">
            <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
            Aggiungi pagamento
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
