import {Component, OnDestroy, OnInit, TemplateRef} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CustomersService} from "@core/services/customers.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {SystemService} from "@core/services/system.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {any} from "@amcharts/amcharts5/.internal/core/util/Array";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-business-list',
  templateUrl: './businesses-list.component.html',
  styleUrls: ['./businesses-list.component.scss']
})

export class BusinessesListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public tableConfig: TableConfig = {} as TableConfig;


  public businessRegistration: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    vat: ['', Validators.required],
    fiscalCode: ['', Validators.required],
    pec: ['', Validators.required],
    uniqueCode: ['', Validators.required],
    legalStreet: ['', Validators.required],
    legalNumber: ['', Validators.required],
    legalZipCode: ['', Validators.required],
    legalCity: ['', Validators.required],
    legalProvince: ['', Validators.required],
    Street: [''],
    Number: [''],
    ZipCode: [''],
    City: [''],
    Province: ['']
  });
  sameAddress: boolean = true;
  public groups: any[] = []
  public networks: any[] = []
  // newBusiness should have group and network and each should have id and name
  public newBusiness: any = {
    group: {
      id: '',
      name: ''
    },
    network: {
      id: '',
      name: ''
    }
  }
  public network : string = '';
  public group : string = '';

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private modalService: NgbModal,
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _systemService: SystemService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('customers-businesses-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('businesses')
      .then((table) => {
        this.tableConfig = table;
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
          this.tableConfig.pagination.strictSearch = queryParams.strictSearch !== 'false';
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }

        this.tableConfig.filterGroups.forEach(filterGroup => {
          filterGroup.filters.forEach((filter: any) => {
            switch (filter.retriever) {
              case 'system.region.list':
                this._systemService.getRegionList().then((regions) => {
                  filter.options = regions.map(region => {
                    return {
                      label: region,
                      value: region
                    }
                  })
                })
                break;
              case 'businesses.status.list':
                this._customerService.getBusinessStatusList().then((response: DynamicData) => {
                  filter.options = response.rows.map(status => {
                    return {
                      label: status.label,
                      value: status.value
                    }
                  })
                })
                break;
              case 'networks.list':
                this._customerService.getNetworkList({
                  pageSize: 1000,
                }).then((response: DynamicData) => {
                  filter.options = response.rows.map(network => {
                    return {
                      label: network.name,
                      value: network.id
                    }
                  })
                })
                break;
              case 'groups.list':
                this._customerService.getGroupList({
                  pageSize: 1000,
                }).then((response: DynamicData) => {
                  filter.options = response.rows.map(group => {
                    return {
                      label: group.name,
                      value: group.id
                    }
                  })
                })
                break;
            }
          })
        })
        this._customerService.getGroups()
          .then((response: any) => {
            this.groups = response.rows;
          });
        this._customerService.getNetworks()
          .then((response: any) => {
            this.networks = response.rows;
          });
      })
    this.businessRegistration = this._formBuilder.group({
      name: ['', Validators.required],
      vat: ['', Validators.required],
      fiscalCode: [''],
      pec: ['', Validators.required],
      uniqueCode: ['', Validators.required],
      legalStreet: ['', Validators.required],
      legalNumber: ['', Validators.required],
      legalZipCode: ['', Validators.required],
      legalCity: ['', Validators.required],
      legalProvince: ['', Validators.required],
      Street: [''],
      Number: [''],
      ZipCode: [''],
      City: [''],
      Province: ['']
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getBusinesses(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['network', 'group'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const businesses: DynamicData = await this._customerService.getBusinessList(query)
    businesses.rows.forEach(business => {
      business.links = business.links || {};
      business.links.code = '/businesses/' + business.code;
      business.actions = business.actions || [];
      business.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/businesses/' + business.code
      });
      business.actions.push({
        type: 'link',
        icon: 'edit',
        tooltip: 'Modifica',
        link: '/businesses/' + business.code + '/edit'
      });
    })
    return businesses
  }

  addBusiness(addNewBusinessModal: TemplateRef<any>) {
    return this.modalService.open(addNewBusinessModal, {
      size: 'lg',
      windowClass: 'modal modal-primary'
    });
  }

  onSubmit() {
    const formData = this.businessRegistration.value;
    //remove fiscal code from formData if it's empty
    if (!formData.fiscalCode) {
      delete formData.fiscalCode;
    }
    if (!formData.uniqueCode) {
      delete formData.uniqueCode;
    }
    formData.vat = formData.vat.toUpperCase();
    if (formData.vat.length === 11) {
      formData.vat = 'IT' + formData.vat;
    }
    formData.network = this.newBusiness.network;
    formData.group = this.newBusiness.group;
    if (this.sameAddress) {
      formData.location = formData.addresses = {
        number: formData.legalNumber,
        street: formData.legalStreet,
        zip: formData.legalZipCode,
        city: formData.legalCity,
        province: formData.legalProvince,
        country: 'IT'
      }
      formData.addresses = [{
        number: formData.legalNumber,
        street: formData.legalStreet,
        zip: formData.legalZipCode,
        city: formData.legalCity,
        province: formData.legalProvince,
        country: 'IT'
      },
      ]
    } else {
      formData.location = formData.addresses = {
        number: formData.legalNumber,
        street: formData.legalStreet,
        zip: formData.legalZipCode,
        city: formData.legalCity,
        province: formData.legalProvince,
        country: 'IT'
      }
      formData.addresses = [{
        number: formData.legalNumber,
        street: formData.legalStreet,
        zip: formData.legalZipCode,
        city: formData.legalCity,
        province: formData.legalProvince,
        country: 'IT'
      },
    ]
    }
    delete formData.legalNumber;
    delete formData.legalStreet;
    delete formData.legalZipCode;
    delete formData.legalCity;
    delete formData.legalProvince;
    delete formData.Street;
    delete formData.Number;
    delete formData.ZipCode;
    delete formData.City;
    delete formData.Province;

    this._customerService.newBusiness(formData)
      .then((data) => {
        this.modalService.dismissAll();
        this._router.navigate(['/businesses/' + data.code]);
      })
      .catch((error) => {
        console.error('Error creating business:', error);
      });
  }

  onCheckboxChange() {
    this.sameAddress = !this.sameAddress;
  }

  changeGroup($event: any) {
    this.group = $event.id;
    this.newBusiness.group = $event.id;
  }
  changeNetwork($event: any) {
    this.network = $event.id;
    this.newBusiness.network = $event.id;
  }
}
