import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {CustomersService} from "../../../../@core/services/customers.service";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-tables-list',
  templateUrl: './tables-list.component.html',
  styleUrls: ['./tables-list.component.scss']
})

export class TablesListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public tableConfig: TableConfig = {} as TableConfig;

  public applications: any[] = [];
  public users: any[] = [];
  public businesses: any[] = [];
  public selectedBusiness: any = null;
  public selectedUser: any = null;
  public selectedApplication: any = null;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  private networks: any[] = [];

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _customerService: CustomersService,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService,
    private modalService: NgbModal,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('super-dynamic-tables-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('tables')
      .then((table) => {
        console.log("table",table);
        this.tableConfig = table;
      } );

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getTables(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = [];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const tables: DynamicData = await this._dynamicTableService.getAllList(query);
    tables.rows.forEach(table => {
      table.permissionsLength = table.permissions?.length || 0;
      table.actions = table.actions || [];
      table.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/tables/' + table.name
      });      table.columnNumber = table.columns.length;
      if(!table?.filterGroups){
        table.filterNumber = 'Nessun filtro';
      }
      else if(table.filterGroups?.length === 0) {
        table.filterNumber = 'Nessun filtro';
      }
      else if(!table.filterGroups[0]?.filters){
        table.filterNumber = 'Nessun filtro';
      }

      if(table.filterGroups?.length > 0) {
        table.filterNumber = table?.filterGroups[0].filters?.length || 'Nessun filtro';
      }
    })
    return tables
  }

}
