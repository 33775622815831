<div class="row" xmlns="http://www.w3.org/1999/html" *ngIf="loaded | async">
  <h4 class="card-title my-1">Applicazione {{ application.name }}</h4>
  <div class="col-10 mt-1">
    <div class="card glassy card-full-height card-short-height overflow-hidden">
      <div class="card-body overflow-hidden">
        <div class="row">
          <div class="col-4">
            <div class="row">
              <div class="col-6">
                <label class="text-primary">Nome</label>
                <input type="text" class="form-control" [(ngModel)]="application.name">
              </div>
              <div class="col-6">
                <label class="text-primary">Codice</label>
                <input type="text" class="form-control" [(ngModel)]="application.code">
              </div>
              <div class="col-6 mt-1">
                <label class="text-primary">Stato</label>
                <ng-select
                  [items]="statuses"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  [searchable]="false"
                  [clearable]="false"
                  [hideSelected]="true"
                  appendTo="body"
                  [(ngModel)]="application.status"
                ></ng-select>
              </div>
              <div class="col-6 mt-3">
                <input type="checkbox" class="form-check-input" [(ngModel)]="application.customizable">
                <span class="text-primary pl-05">Personalizzabile</span>
              </div>
            </div>
          </div>
          <div class="col-4">
            <label class="text-primary">Descrizione</label>
            <textarea class="form-control descriptions-textarea" [(ngModel)]="application.description"></textarea>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-12">
                <div class="row d-flex justify-content-between">
                  <div class="col-10 d-flex justify-content-between">
                    <label class="font-medium-1 font-weight-bold">Tipi di utente</label>
                  </div>
                  <div class="col-2 d-flex justify-content-between">
                    <button class="btn-sm btn-primary" (click)="addNewUserType(addUserTypeModal)">
                      <app-svg-icon name="add"></app-svg-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <ng-container *ngFor="let type of application.userTypes">
                <div class="col-12">
                  <div class="row d-flex justify-content-between">
                    <div class="col-10 d-flex justify-content-between">
                      <label class="text-primary font-small-3">{{ type }}</label>
                    </div>
                    <div class="col-2 d-flex justify-content-between">
                      <button class="btn-sm btn-danger btn-block" (click)="removeType(type)">
                        <app-svg-icon name="delete"></app-svg-icon>
                      </button>
                    </div>
                  </div>
                  <div class="separator"></div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 mt-1">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-body">
        <h4 class="card-title">Azioni</h4>
        <div class="row">
          <div class="mt-1 col-12 d-flex justify-content-center">
            <button class=" col-12 btn btn-primary btn-block" (click)="saveApplication()">
              <app-svg-icon name="save"></app-svg-icon>
              Salva
            </button>
          </div>
          <div class="mt-1 col-12 d-flex justify-content-center">
            <button class=" col-12 btn btn-danger btn-block" (click)="deleteApplication()">
              <app-svg-icon name="delete"></app-svg-icon>
              Elimina
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col-6">
    <div class="row">
      <h4 class="card-title my-1">Autorizzazioni</h4>
      <div class="col-6 mt-1">
        <div class="card glassy overflow-hidden">
          <div class="card-header overflow-hidden">
            <div class="row d-flex justify-content-between flex-row">
              <h4 class="card-title col-11">Tutte le autorizzazioni disponibili</h4>
              <button class="btn-sm btn-primary col-1" (click)="addNewPermission(addPermissionModal)">
                <app-svg-icon name="add"></app-svg-icon>
              </button>
            </div>
            <div class="row d-flex justify-content-between flex-row">
              <div class="col-12 mt-1">
                <input type="text" class="form-control" [(ngModel)]="searchPermission" (input)="searchPermissionList()"
                       placeholder="Cerca autorizzazione">
              </div>
            </div>
          </div>
          <div class="card-body card-permissions">
            <div class="row">
              <ng-container *ngFor="let permission of tmpPermissions">
                <div class="col-12">
                  <div class="row d-flex justify-content-between">
                    <div class="col-9">
                      <label class="text-primary font-small-3">{{ permission }}</label>
                    </div>
                    <div class="col-3 d-flex justify-content-between">
                      <button *ngIf="isPermissionExist(permission, 'default')" class="btn-sm btn-block btn-danger"
                              (click)="removeDefault(permission)">
                        <span class="text-white">P</span>
                      </button>
                      <button *ngIf="!isPermissionExist(permission, 'default')" class="btn-sm btn-block btn-success"
                              (click)="addDefault(permission)">
                        <span class="text-white">P</span>
                      </button>
                      <button *ngIf="isPermissionExist(permission, 'anonymous')" class="btn-sm btn-block btn-danger"
                              (click)="removeAnonymous(permission)">
                        <span class="text-white">A</span>
                      </button>
                      <button *ngIf="!isPermissionExist(permission, 'anonymous')" class="btn-sm btn-block btn-success"
                              (click)="addAnonymous(permission)">
                        <span class="text-white">A</span>
                      </button>
                    </div>
                  </div>
                  <div class="separator"></div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 mt-1">
        <div class="card glassy overflow-hidden">
          <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Autorizzazioni predefinite</h4>
            <button class="btn-sm btn-primary" (click)="addAllToDefault()">
              <app-svg-icon name="add"></app-svg-icon>
              Tutte
            </button>
          </div>
          <div class="card-body overflow-hidden card-permissions2">
            <div class="row">
              <ng-container *ngFor="let permission of application.defaultPermissions">
                <div class="col-12">
                  <div class="row d-flex justify-content-between">
                    <div class="col-10">
                      <label class="text-primary font-small-3">{{ permission }}</label>
                    </div>
                    <div class="col-2 d-flex justify-content-between">
                      <button class="btn-sm btn-danger btn-block" (click)="removeDefault(permission)">
                        <app-svg-icon name="delete"></app-svg-icon>
                      </button>
                    </div>
                  </div>
                  <div class="separator"></div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="card glassy overflow-hidden">
          <div class="card-header overflow-hidden d-flex justify-content-between">
            <h4 class="card-title">Autorizzazioni Anonime</h4>
            <button class="btn-sm btn-primary" (click)="addAllToAnonymous()">
              <app-svg-icon name="add"></app-svg-icon>
              Tutte
            </button>
          </div>
          <div class="card-body card-permissions2">
            <div class="row">
              <ng-container *ngFor="let permission of application.anonymousPermissions">
                <div class="col-12">
                  <div class="row d-flex justify-content-between">
                    <div class="col-10">
                      <label class="text-primary font-small-3">{{ permission }}</label>
                    </div>
                    <div class="col-2 d-flex justify-content-between">
                      <button class="btn-sm btn-danger btn-block" (click)="removeAnonymous(permission)">
                        <app-svg-icon name="delete"></app-svg-icon>
                      </button>
                    </div>
                  </div>
                  <div class="separator"></div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row mt-1">
      <div class="col-12">
        <h4 class="card-title my-1">Tema</h4>
        <div class="card glassy card-full-height card-short-height overflow-hidden">
          <div class="card-header overflow-hidden">
          </div>
          <div class="card-body overflow-hidden">
            <div class="row">
              <div class="col-12">
            <textarea class="form-control permissions-textarea"
                      [(ngModel)]="application.theme">{{ convertObjectToJSON(application.theme) }}</textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        <h4 class="card-title my-1">Menu</h4>
        <div class="card glassy card-full-height card-short-height overflow-hidden">
          <div class="card-header overflow-hidden">
          </div>
          <div class="card-body overflow-hidden">
            <div class="row">
              <div class="col-12">
            <textarea class="form-control permissions-textarea"
                      [(ngModel)]="application.menu">{{ convertObjectToJSON(application.menu) }}</textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #addPermissionModal let-modal>
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Aggiungi autorizzazione</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <input type="text" class="form-control" [(ngModel)]="newPermission" placeholder="Inserisci il autorizzazione">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addPermission()">Aggiungi</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>


<ng-template #addUserTypeModal let-modal>
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Aggiungi tipo di utente</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <input type="text" class="form-control" [(ngModel)]="newUser" placeholder="Inserisci il tipo di utente">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addUser()">Aggiungi</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>



