<div class="auth-container">
  <div class="row">
    <div class="card col-12 auth-box">
      <div class="card-header justify-content-center">
        <a href="#" target="_self" class="brand-logo mb-0 mt-0">
          <img src="/assets/images/logo/logo-light.png" alt="brand-logo" height="50"/>
        </a>
      </div>
      <div class="card-body pt-2">
        <h4 class="card-title mb-1">accedi al pannello di amministrazione di Tulero! </h4>
        <p class="card-text mb-2">Per favore accedi al tuo account per continuare</p>
        <form (ngSubmit)="login(user.username, user.password)">
          <div class="form-group">
            <label class="form-label" for="username">Username</label>
            <input type="text" id="username" name="username" class="form-control" required autofocus
                   [(ngModel)]="user.username">
          </div>
          <div class="form-group mt-1">
            <label class="form-label" for="password">Password</label>
            <input type="password" id="password" name="password" class="form-control" required
                   [(ngModel)]="user.password">
          </div>
          <div class="form-group my-2">
            <button type="submit" class="btn btn-primary">Accedi</button>
          </div>
        </form>
        <p>Non hai un account? <a>Registrati</a></p>
      </div>
    </div>
  </div>
</div>
