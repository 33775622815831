import { Component, OnInit } from '@angular/core';
import { ChartsService } from "../../../../@core/services/charts.service";


@Component({
  selector: 'widget-kromeda-stats',
  templateUrl: './kromeda-stats.component.html',
  styleUrls: ['./kromeda-stats.component.scss']
})
export class KromedaStatsComponent implements OnInit {
  public loaded: Promise<boolean> = Promise.resolve(false);

  public plates: {
    PlatesAvailable?: number,
    PlatesUsed?: number,
  } = {};

  public vins: {
    VinsAvailable?: number,
    VinsUsed?: number,
  } = {};

  public requests: {
    RequestsAvailable?: number,
    RequestsUsed?: number,
  } = {};

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _chartsService: ChartsService,
  ) {
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this._chartsService.getKromedaProfile()
      .then((response: {plates: any, vins: any, requests: any}) => {
        this.plates = response.plates;
        this.vins = response.vins;
        this.requests = response.requests;

        this.loaded = Promise.resolve(true);
      })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

}
