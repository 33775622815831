<div class="row" *ngIf="loaded | async">
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Avviso {{ alert.title }}</h5>
        <div class="row">
          <div class="col-12">
            <label class="text-primary">Titolo:</label>
            <input type="text" class="form-control" [(ngModel)]="alert.title"/>
          </div>
          <div class="col-12 mt-1">
            <label class="text-primary">Link:</label>
            <input type="text" class="form-control" [(ngModel)]="alert.link"/>
          </div>
          <div class="col-12 mt-1">
            <label class="text-primary">Tipo:</label>
            <ng-select [items]="types" bindLabel="name" bindValue="id" [(ngModel)]="alert.type"></ng-select>
          </div>
          <div class="col-12 mt-1">
            <label class="text-primary">Visualizzazioni Necessaria</label>
            <input type="number" class="form-control" [(ngModel)]="alert.clicks">
          </div>
          <div class="col-6 mt-1">
            <input type="checkbox" class="form-check-input mr-05" id="active" [(ngModel)]="alert.active">
            <span class="card-text-title mt-1" for="active">Attivo</span>
          </div>
          <div class="col-6 mt-1">
            <input type="checkbox" class="form-check-input mr-05" id="expanded" [(ngModel)]="alert.expanded">
            <span class="card-text-title mt-1" for="expanded">Allargata</span>
          </div>
          <div class="col-12 mt-1">
            <label class="text-primary">Descrizione:</label>
            <textarea class="form-control" [(ngModel)]="alert.description"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <div class="mb-1 d-flex justify-content-between">
          <div>
            <h5 class="card-title">Foto</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="text-primary">src:</label>
            <input type="text" class="form-control" [(ngModel)]="alert.image"/>
            <button class="btn btn-primary full-width mt-05" (click)="uploadImage()">
              <app-svg-icon name="upload"></app-svg-icon>
            </button>
            <img *ngIf="alert.image" [src]="alert.image" class="img-fluid mt-1 all-borders" alt="Foto"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <div class="mb-1 d-flex justify-content-between">
          <div>
            <h5 class="card-title">Networks</h5>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-primary px-1" (click)="addNetwork(networkModal)">
              <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
            </button>
          </div>
        </div>
        <div class="row">
          <ng-container *ngFor="let network of networks; let i = index">
            <ng-container *ngIf="network.active">
              <div class="col-12 mt-1 d-flex flex-row align-items-center justify-content-between">
                <div class="card-text-title font-medium-3">
                  <img src="{{ network.logo }}" class="img-fluid" alt="{{ network.name }}" width="40" height="40" *ngIf="network.logo">
                  <img src="assets/images/icons/business.svg" class="img-fluid" alt="{{ network.name }}" width="40" height="40" *ngIf="!network.logo">
                  {{ network.name }}
                </div>
                <div class="d-flex">
                  <button class="btn btn-success p-05 mr-025" (click)="goToNetworkOrigin(i)">
                    <app-svg-icon class="cursor-pointer" name="link"></app-svg-icon>
                  </button>
                  <button class="btn btn-danger p-05" (click)="deleteNetwork(i)">
                    <app-svg-icon class="cursor-pointer" name="delete"></app-svg-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Azioni</h5>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-primary btn-block" (click)="showPreview(alertModal)">
            <app-svg-icon name="show"></app-svg-icon>
            Anteprima
          </button>
        </div>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-primary btn-block" (click)="saveAlert()">
            <app-svg-icon name="save"></app-svg-icon>
            Salva
          </button>
        </div>
        <div class="mt-1 col-12 d-flex justify-content-center">
          <button class=" col-12 btn btn-danger btn-block" (click)="deleteAlert()">
            <app-svg-icon name="delete"></app-svg-icon>
            Elimina
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #alertModal let-modal>
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Avvisi</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngb-alert *ngFor="let alert of alerts" [type]="alert.type" [dismissible]="false">
      <h4 class="alert-heading" (click)="toggleAlert(alert)">
        <span>{{ alert.title }}</span>
        <span class="arrow-icon" [ngClass]="{ 'expanded': alert.expanded }">
          <i class="arrow" data-feather="chevron-right"></i>
        </span>
      </h4>
      <div class="row" [ngClass]="{ 'expanded': alert.expanded }">
        <div class="col-12">
          <p class="description-text" [innerHTML]="alert.description" [hidden]="!alert.expanded"></p>
          <a *ngIf="alert.link" class="description-text" [href]="alert.link" target="_blank"
             [hidden]="!alert.expanded">{{ alert.linkText }}</a>
          <a *ngIf="alert.image && alert.link" [href]="alert.link" target="_blank"
             [hidden]="!alert.expanded">
            <img class="alert-image" [src]="alert.image" alt="Alert Image">
          </a>
          <img *ngIf="alert.image && !alert.link" class="alert-image" [src]="alert.image"
               alt="Alert Image" [hidden]="!alert.expanded">
        </div>
      </div>
    </ngb-alert>
    <!-- Confirmation Button -->
    <div class="modal-footer justify-content-end">
      <button type="button" class="btn btn-primary" (click)="CloseModal()">Letto</button>
    </div>
  </div>
</ng-template>


<ng-template #networkModal let-modal>
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Aggiungi Network</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <ng-select
          [items]="networks"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="newNetwork"
          placeholder="Seleziona un network"
        ></ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-primary" (click)="addNewNetwork()">Aggiungi</button>
  </div>
</ng-template>

