<!-- business-profile.component.html -->
<div class="row" *ngIf="loaded | async">
  <div class="col-3" *ngIf="pagePermissions.details">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-header pb-0">
        <h4 class="card-title mb-0 font-large-1 text-primary">
          <span class="align-middle">{{ business.code }}</span>
          <span class="badge badge-pill font-small-2 ml-1 align-middle {{statuses[business.status].class}}">
            {{ statuses[business.status].label }}
          </span>
        </h4>
      </div>
      <div class="card-body pt-025 pb-0">
        <h5 class="font-medium-4 font-weight-soft-bold mb-05 mt-0">{{ business.name }}</h5>
        <h6 class="font-medium-1 font-weight-normal">
          <span *ngIf="business.vat">P.IVA {{ business.vat }}</span>
          <span *ngIf="business.vat && business.fiscalCode"> / </span>
          <span *ngIf="business.fiscalCode">C.F. {{ business.fiscalCode }}</span>
        </h6>
        <div class="row mt-075">
          <div class="col-4">
            <app-svg-icon class="font-small-1 d-inline-block align-middle" name="share"></app-svg-icon>
            <span class="align-middle">Gruppo</span>
          </div>
          <div class="col-8">{{ business.group.name }}</div>
          <div class="col-4">
            <app-svg-icon class="font-small-1 d-inline-block align-middle" name="share"></app-svg-icon>
            <span class="align-middle">Network</span>
          </div>
          <div class="col-8">{{ business.network.name }}</div>
          <div class="col-4">
            <app-svg-icon class="font-small-1 d-inline-block align-middle" name="location"></app-svg-icon>
            <span class="align-middle">Sede Legale</span>
          </div>
          <div class="col-8">
            <div>{{ business.location.street }} {{ business.location.number }}</div>
            <div>{{ business.location.zip }} - {{ business.location.city }} ({{ business.location.province }})</div>
            <div>{{ business.location.region }}, {{ business.location.country }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="col-4" *ngIf="pagePermissions.stats">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Statistiche</h4>
      </div>
      <div class="card-body pb-025 d-flex align-items-center">
        <div class="row">
          <div class="col-4 d-flex mt-075">
            <div class="stat bg-light-primary text-primary font-medium-3  p-05">
              <app-svg-icon class="no-margin" name="delivery"></app-svg-icon>
            </div>
            <div class="ml-05 d-flex flex-column justify-content-center">
              <div class="font-medium-1 font-weight-bold text-gray">{{ stats.orders }}</div>
              <div class="font-small-2 font-weight-soft-bold text-primary">Ordini</div>
            </div>
          </div>
          <div class="col-4 d-flex mt-075">
            <div class="stat bg-light-success text-success font-medium-3  p-05">
              <app-svg-icon class="no-margin" name="package"></app-svg-icon>
            </div>
            <div class="ml-05 d-flex flex-column justify-content-center">
              <div class="font-medium-1 font-weight-bold text-gray">{{ stats.products }}</div>
              <div class="font-small-2 font-weight-soft-bold text-success">Prodotti</div>
            </div>
          </div>
          <div class="col-4 d-flex mt-075">
            <div class="stat bg-light-info text-info font-medium-3  p-05">
              <app-svg-icon class="no-margin" name="shipping"></app-svg-icon>
            </div>
            <div class="ml-05 d-flex flex-column justify-content-center">
              <div class="font-medium-1 font-weight-bold text-gray">{{ stats.shipping | price }}</div>
              <div class="font-small-2 font-weight-soft-bold text-info">Spedizioni</div>
            </div>
          </div>
          <div class="col-4 d-flex mt-075">
            <div class="stat bg-light-warning text-warning font-medium-3  p-05">
              <app-svg-icon class="no-margin" name="alert"></app-svg-icon>
            </div>
            <div class="ml-05 d-flex flex-column justify-content-center">
              <div class="font-medium-1 font-weight-bold text-gray">{{ stats.shippingCost | price }}</div>
              <div class="font-small-2 font-weight-soft-bold text-warning">Costo Spedizioni</div>
            </div>
          </div>
          <div class="col-4 d-flex mt-075">
            <div class="stat bg-light-primary text-primary font-medium-4  p-05">
              <app-svg-icon class="no-margin" name="money"></app-svg-icon>
            </div>
            <div class="ml-05 d-flex flex-column justify-content-center">
              <div class="font-medium-2 font-weight-bold">{{ stats.total | price }}</div>
              <div class="font-small-2 font-weight-soft-bold text-primary">Totale Prodotti</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="col-3 d-flex" *ngIf="pagePermissions.comments">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title ">Commenti</h5>
        <div class="comment-container">
          <ng-container *ngIf="comments.length > 0">
            <ul class="list-unstyled">
              <li *ngFor="let comment of comments" class="mb-1">
                <div class="">
                  <div class="comment-text">
                    <p class="card-text mb-0">
                      {{ comment.text }}
                    </p>
                  </div>
                  <div class="comment-user mt-05">
                    <span class="avatar mr-1">
                      <img
                        [src]="comment.user.avatar || 'assets/images/avatars/default-avatar.png'"
                        (error)="comment.user.avatar = 'assets/images/avatars/default-avatar.png'"
                        alt="avatar"
                        class="rounded-circle"
                        width="18"
                        height="18"
                      >
                    </span>
                    <span class="font-small-2 font-weight-bold mr-1">{{ comment.user.name }}</span>
                    <span class="font-small-2">{{ comment.createdAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="comments?.length == 0">
            <p>Nessun commento trovato</p>
          </ng-container>
        </div>
      </div>
      <div class="card-footer">
        <div class="form-group">
          <div class="input-group">
            <textarea class="form-control" id="comment" rows="2"
                      [(ngModel)]="commentText" name="comment"
                      (keydown.control.enter)="addComment()"></textarea>
            <button class="btn btn-outline-primary px-1" (click)="addComment()">
              <app-svg-icon class="d-inline-block align-middle" name="send"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-2" *ngIf="pagePermissions.actions">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Azioni</h4>
      </div>
      <div class="card-body pt-05 pb-0 d-flex flex-column justify-content-center overflow-visible">
        <button class="btn btn-primary btn-block mb-1" (click)="loginAs(usersListModal)" *ngIf="pagePermissions.login">
          <app-svg-icon class="d-inline-block align-middle" name="lock"></app-svg-icon>
          <span class="align-middle">Accedi</span>
        </button>
        <button class="btn btn-warning btn-block mb-1" routerLink="./edit" *ngIf="pagePermissions.edit">
          <app-svg-icon class="d-inline-block align-middle" name="edit"></app-svg-icon>
          <span class="align-middle">Modifica</span>
        </button>
        <button class="btn btn-danger btn-block" *ngIf="pagePermissions.delete">
          <app-svg-icon class="d-inline-block align-middle" name="forbidden"></app-svg-icon>
          <span class="align-middle">Sospendi</span>
        </button>
      </div>
    </div>
  </div>

  <div class="col-3" *ngIf="pagePermissions.connections">
    <div class="row">
      <div class="col-12">
        <div class="card glassy card-full-height">
          <div class="card-header pb-0">
            <h4 *ngIf="pagePermissions.addresses" class="card-title">Indirizzi</h4>
          </div>
          <div class="card-body pt-05 d-flex flex-column">
            <div *ngIf="pagePermissions.addresses">
              <ng-container *ngIf="business.addresses.length > 0">
                <div class="mt-05 mb-1" *ngFor="let address of business.addresses">
                  <div class="row">
                    <div class="col-8">
                      <app-svg-icon class="font-small-1 d-inline-block align-middle" name="location"></app-svg-icon>
                      <span class="align-middle mr-05 font-small-3 font-weight-soft-bold">Indirizzo</span>
                      <div class="text-gray">{{ address.street }} {{ address.number }}</div>
                      <div class="text-gray">{{ address.zip }} - {{ address.city }} ({{ address.province }})</div>
                      <div class="text-gray">{{ address.region }}, {{ address.country }}</div>
                    </div>
                    <div class="col-4">
                      <app-svg-icon class="font-small-1 d-inline-block align-middle" name="delivery"></app-svg-icon>
                      <span class="align-middle mr-05 font-small-3 font-weight-soft-bold">Fornitori</span>
                      <div *ngIf="address.extra && address.extra.cati">
                        <div class="text-gray">{{ address.extra.cati.username }}</div>
                        <div class="text-gray">{{ address.extra.cati.password }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="business.addresses.length === 0">
                <div class="row">
                  <div class="col-12 text-center">
                    <span class="align-middle mr-05 font-small-3 font-weight-soft-bold">Nessun indirizzo</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="pagePermissions.contacts">
        <div class="card glassy">
          <div class="card-body pt-05 d-flex flex-column">
            <ul>
              <div>
                <h4 class="card-title"> Contatti</h4>
                <ng-container *ngIf="business.contacts.length > 0">
                  <li class="p-05 pt-0" *ngFor="let contact of business.contacts">
                    <div class="row">
                      <div class="col-4 text-right">
                        <span class="align-middle mr-05 font-small-3 font-weight-soft-bold">Nome</span>
                        <app-svg-icon class="font-small-1 d-inline-block align-middle" name="user"></app-svg-icon>
                      </div>
                      <div class="col-8">{{ contact.name }}</div>
                      <div class="col-4 text-right">
                        <span class="align-middle mr-05 font-small-3 font-weight-soft-bold">Telefono</span>
                        <app-svg-icon class="font-small-1 d-inline-block align-middle" name="phone"></app-svg-icon>
                      </div>
                      <div class="col-8">
                        <a href="tel:{{contact.phone}}">{{ contact.phone }}</a>
                      </div>
                      <div class="col-4 text-right">
                        <span class="align-middle mr-05 font-small-3 font-weight-soft-bold">Email</span>
                        <app-svg-icon class="font-small-1 d-inline-block align-middle" name="mail"></app-svg-icon>
                      </div>
                      <div class="col-8">
                        <a href="mailto:{{contact.email}}">{{ contact.email }}</a>
                      </div>
                    </div>
                  </li>
                </ng-container>
                <ng-container *ngIf="business.contacts.length === 0">
                  <li class="p-05 pt-0">
                    <div class="row">
                      <div class="col-12 text-center">
                        <span class="align-middle mr-05 font-small-3 font-weight-soft-bold">Nessun contatto</span>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card glassy">
          <div class="card-body pt-05 d-flex flex-column">
            <ul>
              <div>
                <h4 class="card-title"> Avvisi</h4>
                <ng-container *ngIf="business?.alerts?.length > 0">
                  <li class="p-05 pt-0" *ngFor="let alert of business.alerts">
                    <div class="row">
                      <div class="col-12 text-primary cursor-pointer mt-05"
                           (click)="navigateToAlert(alert.alert.id)">
                        <app-svg-icon class="font-small-1 d-inline-block align-middle" name="alert"></app-svg-icon>
                        {{ alert.alert.title }}
                      </div>
                      <div class="col-4 text-left mt-05">
                        <span
                          class="align-middle mr-05 font-small-3 font-weight-soft-bold">Clicks </span>
                        <span
                          class="align-middle mr-05 font-small-3 font-weight-soft-bold">{{ alert.dates.length }}
                          / {{ alert.alert.clicks }}
                          </span>
                      </div>
                      <div class="col-6 mt-05 d-flex flex-column" *ngIf="alert.dates.length > 0">
                        <div class="d-flex flex-row" *ngIf="alert.dates.length > 0">
                          <span class="align-middle mr-05 font-small-2 font-weight-soft-bold">Primo</span>
                          <span class="align-middle font-small-2">{{ alert.dates[0] | date: 'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                        <div class="d-flex flex-row" *ngIf="alert.dates.length > 0">
                          <span class="align-middle mr-05 font-small-2 font-weight-soft-bold">Ultimo</span>
                          <span
                            class="align-middle font-small-2">{{ alert.dates[alert.dates.length - 1] | date: 'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                      </div>
                      <div class="col-6" *ngIf="alert.dates.length === 0">
                        -
                      </div>
                      <div class="col-2">
                        <button class="btn btn-sm btn-outline-primary" (click)="showHistory(clickHistory, alert)">
                          <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                        </button>
                      </div>
                    </div>
                  </li>
                </ng-container>
                <ng-container *ngIf="!business?.alerts?.length">
                  <li class="p-05 pt-0">
                    <div class="row">
                      <div class="col-12 text-center">
                        <span class="align-middle mr-05 font-small-3 font-weight-soft-bold">Nessun avviso</span>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-9">
    <div class="row">
      <div class="col-12" *ngIf="pagePermissions.orders">
        <div class="card glassy card-full-height">
          <div class="card-header pb-0">
            <h4 class="card-title">Ordini</h4>
          </div>
          <dynamic-table
            [dataRetriever]="getOrders.bind(this)"
            [columns]="ordersConfig.columns"
            [page]="1"
            [pageSize]="10"
            [sort]="{
          prop: 'createdAt',
          dir: 'desc'
        }"
            [strictSearch]="true"
            [messages]="ordersConfig.messages"
          >
          </dynamic-table>
        </div>
      </div>
      <div class="col-6" *ngIf="pagePermissions.wallets">
        <div class="card glassy ">
          <div class="card-body pt-05 d-flex flex-column">
            <div class="row pt-05">
              <h4 class="card-title"> Wallets</h4>
              <ng-container *ngIf="business.wallets.length > 0">
                <ng-container *ngFor="let wallet of business.wallets">
                  <div class="col-4 d-flex flex-row mt-1">
                    <div class="stat bg-light-primary text-primary font-medium-3  p-05 mr-05">
                      <app-svg-icon class="no-margin" name="wallet"></app-svg-icon>
                    </div>
                    <div class="d-flex flex-column">
                      <div class="font-weight-bold cursor-pointer wallet-title"
                           (click)="showWalletHistory(walletHistory, wallet)">
                        <span>{{ wallet.type.label }}
                          <app-svg-icon class="d-inline-block align-middle" name="bill"></app-svg-icon></span>

                      </div>
                      <div class="text-gray">{{ wallet.balance.toFixed(2) }}</div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="business.wallets.length === 0">
                <div class="col-12 text-center">
                  <span class="align-middle mr-05 font-small-3 font-weight-soft-bold">Nessun wallet</span>
                </div>
              </ng-container>
            </div>
            <div class="row">
              <h4 class="card-title mt-1 pt-05"> Metodi di pagamento</h4>
              <ng-container *ngIf="business.payments.length > 0">
                <ng-container *ngFor="let payment of business.payments">
                  <div class="col-6 d-flex flex-row mt-1">
                    <div *ngIf="payment.active" class="stat bg-light-success text-success font-medium-3  p-05 mr-05">
                      <app-svg-icon class="no-margin" name="money"></app-svg-icon>
                    </div>
                    <div *ngIf="!payment.active" class="stat bg-light-danger text-danger font-medium-3  p-05 mr-05">
                      <app-svg-icon class="no-margin" name="money"></app-svg-icon>
                    </div>
                    <div class="d-flex flex-column">
                      <div *ngIf="payment.label" class="text-gray font-weight-bold">{{ payment.label }}</div>
                      <div *ngIf="!payment.label" class="text-gray font-weight-bold"> -</div>
                      <div class="text-gray">{{ payment.method.label }}</div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="business.payments.length === 0">
                <div class="col-12 text-center">
                  <span class="align-middle mr-05 font-small-3 font-weight-soft-bold">Nessun metodo di pagamento</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="pagePermissions.payments">
        <div class="card glassy card-full-height overflow-hidden">
          <div class="card-header pb-0">
            <h4 class="card-title">Pagamenti</h4>
          </div>
          <dynamic-table
            [dataRetriever]="getPayments.bind(this)"
            [columns]="paymentsConfig.columns"
            [page]="1"
            [pageSize]="10"
            [sort]="{
          prop: 'createdAt',
          dir: 'desc'
        }"
            [strictSearch]="true"
            [messages]="paymentsConfig.messages"
          >
          </dynamic-table>
        </div>
      </div>
    </div>
  </div>


</div>

<ng-template #usersListModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="usersList-modal">Lista Utenti</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pl-2 pr-2">
    <!-- list of users to make the user select which user they want to login as -->
    <div class="row">
      <div class="col-12">
        <div class="form-group mb-2 mt-2">
          <label for="user">Utente:</label>
          <ng-select
            id="user"
            [items]="users"
            bindLabel="username"
            placeholder="Seleziona utente"
            [(ngModel)]="selectedUser"
          >
          </ng-select>
        </div>
        <!-- button to make the login by that user -->
        <button class="btn btn-success" (click)="loginAsUser()">Accedi</button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>
<ng-template
  #paymentsCell
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-rowIndex="rowIndex"
  let-payments="value"
>
  <ng-container *ngIf="payments.length > 0">
    <div class="flex flex-row items-center">
      <ng-container *ngFor="let payment of payments">
        <ng-container *ngIf="payment.payment">
          <a *ngIf="column.extra?.status?.[payment.payment.status]"
             class="badge badge-pill {{column.extra.status[payment.payment.status]?.class}}"
             ngbTooltip="{{payment.payment.code}} | {{payment.payment.amount | price}} | {{column.extra.status[payment.payment.status]?.label}}"
             routerLink="/payments/{{payment.payment.code}}"
          >
            {{ column.extra?.method?.[payment.payment.method?.driver] ? column.extra?.method?.[payment.payment.method?.driver].label : payment.payment.method?.driver }}
          </a>
          <a *ngIf="!column.extra?.status?.[payment.payment.status]" class="badge badge-pill badge-light-danger"
             ngbTooltip="{{payment.payment.code}} | {{payment.payment.amount | price}} | {{payment.payment.status}}">
            {{ column.extra?.method?.[payment.payment.method?.driver] ? column.extra?.method?.[payment.payment.method?.driver].label : payment.payment.method?.driver }}
          </a>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>


<ng-template #walletHistory let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="show-wallet-history-modal">Storico</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-2">
        <div class="text-gray font-weight-bold">Codice</div>
      </div>
      <div class="col-4">
        <div class="text-gray font-weight-bold">Causale</div>
      </div>
      <div class="col-1 d-flex justify-content-end">
        <div class="text-gray font-weight-bold">Quantità</div>
      </div>
      <div class="col-1 d-flex justify-content-end">
        <div class="text-gray font-weight-bold">Da</div>
      </div>
      <div class="col-1 d-flex justify-content-end">
        <div class="text-gray font-weight-bold">A</div>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <div class="text-gray font-weight-bold">Data</div>
      </div>
      <div class="col-1 d-flex justify-content-end">
        <div class="text-gray font-weight-bold">Ora</div>
      </div>
      <ng-container *ngFor="let history of walletHistoryArray">
        <div class="col-2">
          <div class="text-gray">{{ history.code }}</div>
        </div>
        <div class="col-4">
          <div class="text-gray">{{ history.reason }}</div>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <div class="text-gray">{{ history.amount | price:' ' }}</div>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <div class="text-gray">{{ history.from | price:' ' }}</div>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <div class="text-gray">{{ history.to | price:' ' }}</div>
        </div>
        <div class="col-2 d-flex justify-content-end">
          <div class="text-gray">{{ history.createdAt | date:'dd/MM/yyyy' }}</div>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <div class="text-gray">{{ history.createdAt | date:'HH:mm' }}</div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-error" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>


<ng-template #clickHistory let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="show-click-history-modal">Storico</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <ng-container *ngFor="let date of modalData.dates">
        <div class="col-12">
          <div class="text-gray my-1">{{ date | date:'dd/MM/yyyy HH:mm' }}</div>
        </div>
        <div class="separator" *ngIf="modalData.dates.length > 1"></div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-error" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>
