import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";

import { Error500Component } from './error500/error500.component';
import { Error404Component } from './error404/error404.component';


// routing
const routes: Routes = [
  {
    path: '500',
    component: Error500Component,
    data: { animation: 'Error500Component' }
  },
  {
    path: '**',
    component: Error404Component,
    data: { animation: 'Error404Component' }
  },
];

@NgModule({
  declarations: [
    Error500Component,
    Error404Component
  ],
  imports: [
    RouterModule.forChild(routes)
  ],
  providers: [],
  bootstrap: []
})
export class ErrorsModule {}
