
<h1 class="font-medium-5 px-025 my-1">Fatture</h1>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getQuotes.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>


<ng-template
  #sentDateCell
  let-column="column"
  let-allRowsSelected="allRowsSelected"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-rowIndex="rowIndex"
  let-value="value"
>
  <ng-container *ngIf="value">
    <span>{{value | date:"dd/MM/yyyy"}}</span>
  </ng-container>
  <ng-container *ngIf="!value">
    <span class="text-danger">Non inviato</span>
  </ng-container>
</ng-template>
