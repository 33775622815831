<div class="row" xmlns="http://www.w3.org/1999/html" *ngIf="loaded | async">
  <div class="row">
    <div class="col-4">
      <div class="card glassy card-full-height card-short-height">
        <div class="card-header pb-0">
          <h5 class="card-title mb-0 text-primary">
            {{ identity.application.code }} | {{ identity.business.code }} | {{ identity.user.code }}
          </h5>
        </div>
        <div class="card-body pt-1 pb-0">
          <h6 class="card-title mb-0 font-medium-1">
            Nome Utente:
          </h6>
          <p class="card-text">
            {{ identity.user.username }}
          </p>
          <h6 class="card-title mb-0 font-medium-1">
            Nome Azienda:
          </h6>
          <p class="card-text">
            {{ identity.business.name }}
          </p>
          <h6 class="card-title mb-0 font-medium-1">
            Applicazione:
          </h6>
          <p class="card-text">
            {{ identity.application.name }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="card glassy card-full-height card-short-height">
        <div class="card-header pb-0">
          <h4 class="card-title mb-0 text-primary">
            <span class="align-middle">Azioni:</span>
          </h4>
        </div>
        <div class="card-body pt-025 pb-0">
          <div class="row">
            <div class="col-12 mt-05">
              <button class="btn btn-success btn-block col-12 text-nowrap" (click)="save()">
                <div class="row">
                  <app-svg-icon [name]="'save'" class="mr-1 col-2"></app-svg-icon>
                  <span class="align-middle col-7">Salva</span>
                </div>
              </button>
            </div>
            <div class="col-12 mt-05">
              <button class="btn btn-danger btn-block col-12 text-nowrap" (click)="suspend()">
                <div class="row">
                  <app-svg-icon [name]="'forbidden'" class="mr-1 col-2"></app-svg-icon>
                  <span class="align-middle col-7">Sospendi</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
    <div>
      <h3 class="card-title mb-1 mt-2 text-primary">
        Autorizzazioni
      </h3>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <button class="btn btn-primary px-1" (click)="addPermission(addNewPermissionModal)">
        <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <div class="card glassy card-full-height overflow-y-scroll">
        <div class="card-header pb-0 d-flex justify-content-between">
          <h4 class="card-title mb-0 text-primary">
            Voci del menu
          </h4>
          <button class="btn-sm btn-primary" (click)="activateAllMenu()">
            Tutti
          </button>
        </div>
        <div class="card-body pb-1">
          <div class="d-flex flex-column align-items-start pb-1">
            <ng-container *ngFor="let item of menu">
              <div>
                <div class="d-flex flex-row text-primary mt-1">
                  <input type="checkbox" class="form-check-input mr-05 mt-05" [(ngModel)]="item.active"
                         (input)="changeChildrens(menu, item)"/>
                  <span class="card-text-title font-medium-3">{{ item.name || item.code | titlecase }} </span>
                </div>

                <div class="pl-1">
                  <ng-container *ngFor="let subItem of item.children">
                    <div>
                      <div class="d-flex flex-row text-success mt-05">
                        <input type="checkbox" class="form-check-input mr-05 mt-05" [(ngModel)]="subItem.active"
                               (input)="changeChildrens(menu, subItem)"/>
                        <span
                          class="card-text-title font-medium-2">{{ subItem.name || subItem.code | titlecase }} </span>
                      </div>

                      <div class="pl-1 pt-05">
                        <ng-container *ngFor="let subsubItem of subItem.children">
                          <div class="d-flex flex-row text-warning">
                            <input type="checkbox" class="form-check-input mr-05 ml-2" [(ngModel)]="subsubItem.active"
                                   (input)="changeChildrens(menu, subsubItem)"/>
                            <span
                              class="card-text-title font-medium-1">{{ subsubItem.name || subsubItem.code | titlecase }} </span>
                          </div>
                          <div>
                            <div class="pl-1">
                              <ng-container *ngFor="let subsubsubItem of subsubItem.children">
                                <div class="d-flex flex-row text-danger mt-05">
                                  <input type="checkbox" class="form-check-input mr-05 ml-2"
                                         [(ngModel)]="subsubsubItem.active"
                                         (input)="changeChildrens(menu,subsubsubItem)"/>
                                  <span
                                    class="card-text-title font-medium-1">{{ subsubsubItem.name || subsubsubItem.code | titlecase }} </span>
                                </div>
                                <div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card glassy card-full-height overflow-y-scroll">
        <div class="card-header pb-0 d-flex justify-content-between">
          <h4 class="card-title mb-0 text-primary">
            Tabelle
          </h4>
          <button class="btn-sm btn-primary" (click)="activateAllTable()">
            Tutti
          </button>
        </div>
        <div class="card-body pb-1">
          <div class="d-flex flex-column align-items-start pb-1">
            <ng-container *ngFor="let item of table">
              <div class="d-flex flex-row text-primary mt-1">
                <input type="checkbox" class="form-check-input mr-05 mt-05" [(ngModel)]="item.active"
                       (input)="changeChildrens(table,item)"/>
                <span class="card-text-title font-medium-3">{{ item.name }} </span>
              </div>
              <div class="d-flex flex-column align-items-start">
                <div class="d-flex flex-row text-warning">
                  <input type="checkbox" class="form-check-input mr-05 mt-05 ml-1" [(ngModel)]="item.columns.active"
                         (input)="changeChildrens(item.columns,item.columns)"/>
                  <span class="card-text-title font-medium-2 text-success mt-05">Colonne</span>
                </div>
                <ng-container *ngFor="let column of item.columns">
                  <div class="d-flex flex-row text-warning">
                    <input type="checkbox" class="form-check-input mr-05 mt-05 ml-2" [(ngModel)]="column.active"
                           (input)="changeChildrens(item.columns,column)"/>
                    <span class="card-text-title font-medium-1 pt-025">{{ column.name }} </span>
                  </div>
                </ng-container>
                <div *ngIf="item.filterGroups">
                  <div class="d-flex flex-row text-warning">
                    <input type="checkbox" class="form-check-input mr-05 mt-05 ml-1"
                           [(ngModel)]="item.filterGroups.active"/>
                    <span class="card-text-title font-medium-2 text-success mt-05">Filtri</span>
                  </div>
                </div>
                <ng-container *ngFor="let filterGroup of item.filterGroups">
                  <ng-container *ngFor="let filter of filterGroup.filters">
                    <div class="d-flex flex-row text-warning">
                      <input type="checkbox" class="form-check-input mr-05 mt-05 ml-1" [(ngModel)]="filter.active"
                             (input)="changeChildrens(filterGroup.filters,filter)"/>
                      <span class="card-text-title font-medium-1">{{ filter.label }} </span>

                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card glassy card-full-height overflow-y-scroll">
        <div class="card-header pb-0 d-flex justify-content-between">
          <h4 class="card-title mb-0 text-primary">
            Pagine
          </h4>
          <button class="btn-sm btn-primary" (click)="activateAllPages()">
            Tutti
          </button>
        </div>
        <div class="card-body pb-1">
          <div class="d-flex flex-column align-items-start pb-1">
            <ng-container *ngFor="let item of AllPagePermissions">
              <div class="d-flex flex-row">
                <input type="checkbox" class="form-check-input mr-05 mt-05" [(ngModel)]="item.active"
                       (input)="changeChildrens(AllPagePermissions,item)"/>
                <span class="card-text-title font-medium-4">{{ item.name }} </span>
              </div>
              <div class="d-flex flex-column align-items-start">
                <ng-container *ngFor="let subItem of item.children">
                  <div class="d-flex flex-row text-primary mt-2">
                    <input type="checkbox" class="form-check-input mr-05 mt-05 ml-1" [(ngModel)]="subItem.active"
                           (input)="changeChildrens(AllPagePermissions,subItem)"/>
                    <span class="card-text-title font-medium-3">{{ subItem.name }} </span>
                  </div>
                  <div class="d-flex flex-column align-items-start">
                    <ng-container *ngFor="let subsubItem of subItem.children">
                      <div class="d-flex flex-row text-success mt-1">
                        <input type="checkbox" class="form-check-input mr-05 ml-2" [(ngModel)]="subsubItem.active"
                               (input)="changeChildrens(AllPagePermissions,subsubItem)"/>
                        <span class="card-text-title font-medium-2">{{ subsubItem.name }} </span>
                      </div>
                      <div class="d-flex flex-column align-items-start">
                        <ng-container *ngFor="let subsubsubItem of subsubItem.children">
                          <div class="d-flex flex-row text-warning mt-05">
                            <input type="checkbox" class="form-check-input mr-05 ml-3"
                                   [(ngModel)]="subsubsubItem.active"
                                   (input)="changeChildrens(AllPagePermissions,subsubsubItem)"/>
                            <span class="card-text-title font-medium-1">{{ subsubsubItem.name }} </span>
                          </div>
                          <div class="d-flex flex-column align-items-start">
                            <ng-container *ngFor="let subsubsubsubItem of subsubsubItem.children">
                              <div class="d-flex flex-row text-warning mt-05 pl-1">
                                <input type="checkbox" class="form-check-input mr-05 ml-3"
                                       [(ngModel)]="subsubsubsubItem.active"
                                       (input)="changeChildrens(AllPagePermissions,subsubsubsubItem)"/>
                                <span class="card-text-title font-medium-1">{{ subsubsubsubItem.name }} </span>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card glassy card-full-height overflow-y-scroll">
        <div class="card-header pb-0 d-flex justify-content-between">
          <h4 class="card-title mb-0 text-primary">
            Dati
          </h4>
          <button class="btn-sm btn-primary" (click)="activateAllDatas()">
            Tutti
          </button>
        </div>
        <div class="card-body pb-1">
          <div class="d-flex flex-column align-items-start pb-1">
            <ng-container *ngFor="let item of data">
              <div class="d-flex flex-row mt-1">
                <span class="card-text-title font-medium-4 text-primary">{{ item.category }} </span>
              </div>
              <div class="d-flex flex-column align-items-start">
                <ng-container *ngFor="let id of item.ids">
                  <div class="d-flex flex-row text-primary mt-05">
                    <input type="checkbox" class="form-check-input mr-05 mt-05 ml-1" [(ngModel)]="id.active"/>
                    <span class="card-text-title font-medium-2 text-success">{{ id.label }} </span>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addNewPermissionModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="newOrder-modal">Aggiungi Nuova Autorizzazione</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  </div>
</ng-template>
