<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Piani Prime</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.primesList.add')" class="btn btn-primary px-1" (click)="addNewPlanModal(newPlanModal)">
      <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getPrimes.bind(this)"
    [filterGroups]="tableConfig.filterGroups"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [strictSearch]="tableConfig.pagination.strictSearch"
    [messages]="tableConfig.messages"
    [autoReloadEnable]="true"
  >
  </dynamic-table>
</div>



<ng-template #newPlanModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="pdf-modal">Aggiungi Piani Prime</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
    <div class="modal-body" tabindex="0">
      <div class="row">
        <div class="col-12">
          <label>Codice:</label>
          <input type="text" class="form-control" [(ngModel)]="newPrime.code">
        </div>
        <div class="col-6 mt-1">
          <label>Punto Minimo:</label>
          <input type="number" class="form-control" [(ngModel)]="newPrime.minPoint">
        </div>
        <div class="col-6 mt-1">
          <label>Punto Massimo:</label>
          <input type="number" class="form-control" [(ngModel)]="newPrime.maxPoint">
        </div>
        <ng-container *ngFor = "let step of newPrime.steps; let i = index">
          <label class="mt-2 text-primary font-weight-bold">Passo {{i+1}}:</label>
          <div class="col-6">
            <label>Etichetta:</label>
            <input type="text" class="form-control" [(ngModel)]="step.label">
          </div>
          <div class="col-6">
            <label>La Targa:</label>
            <input type="number" class="form-control" [(ngModel)]="step.target">
          </div>
        </ng-container>
        <div class="col-6 mt-2">
          <button class="btn btn-primary" (click)="addStep()">Aggiungi Passo</button>
        </div>
      </div>
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addNewPlan()">Aggiungi</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>
