<div class="row" xmlns="http://www.w3.org/1999/html">
  <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-header pb-0">
        <h4 class="card-title mb-0 font-large-1 text-primary">
          <span class="align-middle">{{ user.code }}</span>
          <span class="badge badge-pill font-small-2 ml-1 align-middle {{statuses[user.status].class}}">
            {{ statuses[user.status].label }}
          </span>
        </h4>
      </div>
      <div class="card-body pt-025 pb-0">
        <h5
          class="font-medium-4 font-weight-soft-bold mb-05 mt-1 text-uppercase">{{ user.name }} {{ user.surname }}</h5>
        <div class="row mt-075">
          <div class="col-5">
            <h6>Username:</h6>
            <h6>Email:</h6>
            <h6>Telefono:</h6>
            <h6>Creato a:</h6>
            <h6>Ultima modifica:</h6>
          </div>
          <div class="col-7">
            <h6 class="font-weight-normal ">{{ user.username || '-' }}</h6>
            <h6 class="font-weight-normal">{{ user.email || '-' }}</h6>
            <h6 class="font-weight-normal">{{ user.phone || '-' }}</h6>
            <h6 class="font-weight-normal">{{ user.createdAt | date: 'dd/MM/yyyy' || '-' }}</h6>
            <h6 class="font-weight-normal">{{ user.updatedAt | date: 'dd/MM/yyyy' || '-' }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-header pb-0">
        <h4 class="card-title mb-0 font-large-1 text-primary">
          <span class="align-middle">Azienda</span>
        </h4>
      </div>
      <div *ngIf="businessItems.length > 0" class="business-list col-12">
        <div *ngFor="let item of businessItems; index as i" class="col-12">
          <div class="row">
            <div class="business-item col-md-6">
              <div [routerLink]="'/businesses/' + item.code" class="d-flex align-items-center">
                <app-svg-icon class="d-inline-block align-middle ml-2" name="link"></app-svg-icon>
                <div class="business-info">
                  <div class="business-name">{{ item.name }}</div>
                  <div class="partita-iva">IVA {{ item.vat }}</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 align-self-center d-flex justify-content-end">
              <button class="btn btn-info btn-block mr-1" (click)="loginAs(identities[i].id)">
                <app-svg-icon class="d-inline-block align-middle" name="user"></app-svg-icon>
                <span class="align-middle buttons-text2">Accedi</span>
              </button>
              <button class="btn btn-danger btn-block" (click)="removeBusiness(identities[i].id)">
                <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
                <span class="align-middle buttons-text2">Rimuovere</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="businessItems.length === 0" class="business-list col-12">
        <div class="business-item">
          <div class="business-info">
            <div class="business-name">Nessuna azienda trovata</div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
    <div class="card glassy card-full-height card-short-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Azioni</h4>
      </div>
      <div class="card-body pt-05 pb-0 d-flex flex-column justify-content-center overflow-visible">
        <button class="btn btn-primary btn-block mb-1 password-button" (click)="changePassword()">
          <app-svg-icon class="d-inline-block align-left" name="lock"></app-svg-icon>
          <span class="align-left buttons-text">Cambiare la password</span>
        </button>

        <button class="btn btn-warning btn-block mb-1" (click)="modifyUser()">
          <app-svg-icon class="d-inline-block align-middle" name="edit"></app-svg-icon>
          <span class="align-middle buttons-text">Modifica</span>
        </button>
        <button class="btn btn-success btn-block mb-1" (click)="addBusiness(addBusinessModal)">
          <app-svg-icon class="d-inline-block align-middle" name="addBusiness"></app-svg-icon>
          <span class="align-middle buttons-text">Aggiungi azienda</span>
        </button>
        <button class="btn btn-danger btn-block" (click)="suspendUser()">
          <app-svg-icon class="d-inline-block align-middle" name="forbidden"></app-svg-icon>
          <span class="align-middle buttons-text">Sospendi</span>
        </button>
      </div>
    </div>
  </div>
</div>


<ng-template #changePasswordModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="changePassword-modal">Cambiare la password</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="auth-register-form mt-2" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <div class="form-group mb-2">
        <label for="new-password" class="form-label text-primary">Nuova Password</label>
        <div class="input-group input-group-merge form-password-toggle">
          <input
            type="password"
            formControlName="password"
            class="form-control form-control-merge"
            placeholder="Password"
            aria-describedby="new-password"
            tabindex="3"
            id="new-password"
          />
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block" tabindex="5">Cambiare</button>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>

<ng-template #modifyModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="modify-modal">Modificare l'utente</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="auth-register-form mt-2" [formGroup]="modifyForm" (ngSubmit)="onModify()">
      <div class="form-group">
        <label for="register-username" class="form-label"
               [ngClass]="{ 'is-invalid-label':  userExist}">Username</label>
        <input
          formControlName="username"
          class="form-control"
          placeholder="johndoe"
          aria-describedby="register-username"
          tabindex="1"
          id="register-username"
          [ngClass]="{ 'is-invalid': userExist}"

        />

        <div *ngIf="userExist" class="invalid-feedback">
          <div>Username già in uso</div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <label for="register-name" class="form-label">Nome</label>
          <input
            type="text"
            formControlName="name"
            class="form-control"
            placeholder=""
            aria-describedby="register-name"
            tabindex="1"
            autofocus
            id="register-name"
          />
        </div>
        <div class="form-group col-6">
          <label for="register-surname" class="form-label">Cognome</label>
          <input
            type="text"
            formControlName="surname"
            class="form-control"
            placeholder=""
            aria-describedby="register-surname"
            tabindex="1"
            id="register-surname"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="register-email" class="form-label">Email</label>
        <input
          type="text"
          formControlName="email"
          class="form-control"
          placeholder="john@example.com"
          aria-describedby="register-email"
          tabindex="1"
          id="register-email"
        />
      </div>
      <div class="form-group">
        <label for="register-phone" class="form-label">Numero di telefono</label>
        <input
          type="text"
          formControlName="phone"
          class="form-control"
          placeholder="Numero di telefono"
          aria-describedby="register-phone"
          tabindex="1"
          id="register-phone"
        />
      </div>
      <div class="form-group mb-2">
        <label for="status-box" class="form-label">Stato</label>
        <ng-select
          [items]="statuses2"
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="true"
          [(ngModel)]="status"
          (change)="changeStatus($event)"
          placeholder="{{status}}"
          id="status-box"
        >

        </ng-select>

      </div>

      <button type="submit" class="btn btn-primary btn-block" tabindex="5">Modificare</button>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>

<ng-template #addBusinessModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="addBusiness-modal">Aggiungi azienda</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pl-2 pr-2">
    <!--a modal body with a search box to search the businesses and show the result-->
    <div class="form-group">
      <label for="search-business" class="form-label">Cerca azienda</label>
      <input
        type="text"
        class="form-control"
        placeholder="Cerca azienda"
        aria-describedby="search-business"
        tabindex="1"
        id="search-business"
        [(ngModel)]="search"
        (ngModelChange)="searchBusiness()"
      />


    </div>
    <div *ngIf="businessList.length > 0  && search != ''" class="business-list col-12">
      <div *ngFor="let item of businessList; index as i" class="business-item" (click)="addBusinessToUser(item.id)">
        <div class="row-number">{{ i + 1 }}</div>
        <div class="business-info">
          <div class="business-name">{{ item.name }}</div>
          <div class="partita-iva">IVA {{ item.vat }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="businessItems.length === 0 && search != ''" class="business-list col-12">
      <div class="business-item">
        <div class="business-info">
          <div class="business-name"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>

