import {Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {NgbDate, NgbDateStruct, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-discounts-list',
  templateUrl: './discounts-list.component.html',
  styleUrls: ['./discounts-list.component.scss']
})

export class DiscountsListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  fromDate!: NgbDateStruct;
  toDate!: NgbDateStruct;
  fromDateString!: string;
  toDateString!: string;
  selectFromDate: boolean = false;
  selectToDate: boolean = false;


  public tableConfig : TableConfig ={} as TableConfig;

  public newDiscount: any = {
    code: '',
    type: '',
    amount: 0,
    from: null,
    to: null,
    restricted: true,
    businesses: [],
  }
  public hoveredDate: NgbDate | null = null;
  public selectTime: boolean = false;
  @ViewChild('timeSelector')
  timeSelector!: ElementRef;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-discount-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('discounts')
      .then((table) => {
        this.tableConfig = table;
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    if (this.timeSelector?.nativeElement?.contains(event.target)) {
    } else {
      this.selectTime = false;
    }
  }

  async getDiscounts(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge',
      }
    );
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const discounts: DynamicData = await this._shopService.getDiscountList(query)
    discounts.rows.forEach(discount => {
      discount.type = discount.type === 'PERCENTAGE' ? 'Percentuale' : 'Fisso';
      discount.restricted = discount.restricted ? 'Si' : 'No';
      discount.actions = discount.actions || [];
      discount.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/discounts/' + discount.id
      });
    })
    return discounts;
  }

  addDiscount(addNewdiscountModal: TemplateRef<any>) {
    return this.modalService.open(addNewdiscountModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  addNewDiscount() {
    this._shopService.createDiscount(this.newDiscount)
      .then((response: any) => {
        this.modalService.dismissAll();
        this._router.navigate(['/discounts/' + response.id]);
      })
  }


  updateRestricted(object: any) {
    object.restricted = !object.restricted;
  }


  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }


  openFromDateSelector(event: MouseEvent) {
    this.selectFromDate = !this.selectFromDate;
    this.selectToDate = false;
    event.stopPropagation();
  }

  openToDateSelector(event: MouseEvent) {
    this.selectToDate = !this.selectToDate;
    this.selectFromDate = false;
    event.stopPropagation();
  }

  onFromDateSelection(date: any) {
    this.fromDate = date;
    this.newDiscount.from = new Date(date.year, date.month - 1, date.day);
    this.fromDateString = this.formatDateToString(date);
    this.selectFromDate = false;
  }

  onToDateSelection(date: any) {
    this.toDate = date;
    this.toDateString = this.formatDateToString(date);
    this.newDiscount.to = new Date(date.year, date.month - 1, date.day);
    this.selectToDate = false;
  }

  formatDateToString(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    return `${date.year}-${date.month}-${date.day}`;
  }
}
