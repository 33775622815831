import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../../app/authentication/user";
import { Business } from "../../app/authentication/business";
import { ApplicationService } from "./application.service";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // -----------------------------------------------------------------------------------------------------
  // @ private
  // -----------------------------------------------------------------------------------------------------

  private _user: BehaviorSubject<User> = new BehaviorSubject({} as User);

  private _business: BehaviorSubject<Business> = new BehaviorSubject({} as Business);

  private _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _isUserLogged: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _permissions: BehaviorSubject<string[]> = new BehaviorSubject([] as string[]);

  private _firstCheck: boolean = true;


  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Get the config
  get user(): Observable<User> {
    return this._user.asObservable()
  }

  // Get the config
  get business(): Observable<Business> {
    return this._business.asObservable()
  }

  get isAuthenticated(): Observable<boolean> {
    return this._isAuthenticated.asObservable()
  }

  get isUserLogged(): Observable<boolean> {
    return this._isUserLogged.asObservable()
  }

  get permissions(): Observable<string[]> {
    return this._permissions.asObservable()
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _apiService: ApiService,
    private _applicationService: ApplicationService
  ) {
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  checkPermission(permissions: string): boolean {
    return this._permissions.value.includes(permissions);
  }

  checkAuthenticated(): boolean {
    return this._isAuthenticated.value;
  }

  checkUserLogged(): boolean {
    return this._isUserLogged.value;
  }

  async checkLogin(force: boolean = false): Promise<void> {
    if (!this._firstCheck && !force) {
      return;
    }

    this._firstCheck = false;

    const processLoginResponse = (response: any) => {
      if (response.user) {
        this._user.next(response.user);
        this._isUserLogged.next(true);
      }
      if (response.business) {
        this._business.next(response.business);
        this._isAuthenticated.next(true);
      }
      if (response.identity) {
        this._permissions.next(response.identity?.permissions as string[]);
      }
    }

    await new Promise<boolean>((resolve, reject) => {
      this._apiService.make('auth', 'account')
        .then((response: any) => {
          processLoginResponse(response);
          resolve(true);
        })
        .catch((error: any) => {
          processLoginResponse(error.error);
          resolve(true);
        });
    });
    this._applicationService.getMenu();
    this._applicationService.getTheme();
  }

  signin(username: string, password: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._apiService.make('auth', 'signin', {}, { body: { username, password } })
        .then((response: any) => {
          if (response) {
            this._user.next(response);
            this._isUserLogged.next(true);
            resolve(true);
          }
          resolve(false);
        })
        .catch((error: any) => {
          reject(error);
        });
    })
  }

  getIdentities(): Promise<any> {
    return this._apiService.make('auth', 'getIdentities')
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching brands:', error);
        return [];
      });
  }

  setIdentity(identity: string): Promise<any> {
    return this._apiService.make('auth', 'setIdentity', {}, { body: { id: identity } })
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching identities:', error);
        return [];
      });
  }
  getLoggedInUser(): Promise<any> {
    return this._apiService.make('auth', 'account')
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching brands:', error);
        return [];
      });
  }
}
