import { Component, Input, OnChanges } from '@angular/core';
import { IconsService } from "@core/services/icons.service";

@Component({
  selector: 'app-svg-icon',
  template: `<span class="icon" [innerHTML]="svgIcon"></span>`
})
export class SvgIconComponent implements OnChanges {

  @Input()
  public name?: string;

  public svgIcon: any;

  constructor(
    private _iconService: IconsService
  ) {
  }

  public ngOnChanges(): void {
    if (!this.name) {
      this.svgIcon = '';
      return;
    }
    this._iconService.getIcon(this.name).subscribe(value => {
      this.svgIcon = value;
    });
  }
}
