import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal2 from "sweetalert2";
import {TableConfig} from "../../../../../@core/components/dynamic-table/dynamic-table-interface";
import {CustomersService} from "../../../../../@core/services/customers.service";
import {DynamicTableService} from "../../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../../@core/services/authentication.service";

@Component({
  selector: 'app-walletTypes-list',
  templateUrl: './walletTypes-list.component.html',
  styleUrls: ['./walletTypes-list.component.scss']
})

export class WalletTypesListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public tableConfig: TableConfig = {} as TableConfig;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------


  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _customerService: CustomersService,
    private _dynamicTableService: DynamicTableService,
    public _authService: AuthenticationService,
    private modalService: NgbModal
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-walletTypes-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('walletTypes')
      .then((table) => {
        console.log("table",table);
        this.tableConfig = table;
      } );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getWalletTypes(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = [];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const walletTypes: DynamicData = await this._shopService.getWalletTypes(query)
    walletTypes.rows.forEach(walletType => {
      walletType.actions = walletType.actions || [];
      walletType.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/wallets/types/' + walletType.id
      });
    })
    return walletTypes
  }


}
