import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthenticationService } from "../../../@core/services/authentication.service";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from "../../../@core/services/config.service";
import { ApplicationService } from "../../../@core/services/application.service";
import { signin } from "../../../@fake-db/account";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('wrongPasswordModal') wrongPasswordModal!: TemplateRef<any>;
  //  Public
  public loginForm!: FormGroup;
  public submitted = false;

  email: string = '';
  password: string = '';
  username: string = '';
  name: string = '';
  phone: string = '';
  validationErrorMessage = 'Per favore, completa tutti i campi obbligatori.';
  showLoginFailureModal = false;

  public user = {
    username: '',
    password: ''
  };


  // Private
  private _unsubscribeAll: Subject<any>;


  /**
   * Constructor
   *
   * @param modalService
   * @param {FormBuilder} _formBuilder
   * @param _authenticationService
   * @param _applicationService
   * @param router
   */
  constructor(private modalService: NgbModal,
              private _formBuilder: FormBuilder,
              private _authenticationService: AuthenticationService,
              private _applicationService: ApplicationService,
              private router: Router) {
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutFullWidth();
  }


  login(username: string, password: string) {
    if (username && password) {
      this._authenticationService.signin(username, password)
        .then((user) => {
          this.router.navigate(['/auth/business-selection']);
        })
        .catch((error) => {
          console.error(error);
          // Handle the case where the authentication promise is rejected
          this.validationErrorMessage = 'Errore durante l\'autenticazione.';
          this.wrongCredentials(this.wrongPasswordModal);
        });
    } else {
      // Username or password is empty
      this.validationErrorMessage = 'Per favore, completa tutti i campi obbligatori.';
      this.wrongCredentials(this.wrongPasswordModal);
    }
  }

  wrongCredentials(modalWrong: TemplateRef<any>): NgbModalRef {
    return this.modalService.open(modalWrong, {
      centered: true,
      size: 'sm',
      windowClass: 'modal modal-primary'
    });
  }

  openLoginFailureModal() {
    this.showLoginFailureModal = true;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._authenticationService.user.subscribe(
      (user) => {
        if (!user) {
          // Handle case where user is null (invalid credentials)
          this.validationErrorMessage = 'Credenziali non valide. Riprova.';
          this.openLoginFailureModal();
        }
      },
      (error) => {
        console.error(error);
        // Handle other errors if needed
      }
    );

    this.loginForm = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  protected readonly signin = signin;
}
