import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@core/components/core.module";
import {NgbDatepicker, NgbInputDatepicker, NgbProgressbar, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import { DiscountsListComponent } from "./discounts-list/discounts-list.component";
import { DiscountsViewComponent } from "./discounts-view/discounts-view.component";
const routes: Routes = [
  {
    path: 'discounts',
    component: DiscountsListComponent,
    data: { animation: 'DiscountsListComponent' }
  },
  {
    path: 'discounts/:id',
    component: DiscountsViewComponent,
    data: { animation: 'DiscountsViewComponent' }
  },
];

@NgModule({
  declarations: [
    DiscountsListComponent,
    DiscountsViewComponent,
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        CoreModule,
        NgbProgressbar,
        FormsModule,
        NgSelectModule,
        NgbInputDatepicker,
        NgbDatepicker,
        NgbTooltip
    ],
  providers: []
})
export class DiscountsModule {
}
