import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {CatalogService} from "@core/services/catalog.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {CommentsService} from "../../../../@core/services/comments.service";
import Swal2 from "sweetalert2";

@Component({
  selector: 'app-suppliers-view',
  templateUrl: './suppliers-view.component.html',
  styleUrls: ['./suppliers-view.component.scss']
})

export class SuppliersViewComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  @ViewChild("activeCell", {static: true})
  private activeCell?: TemplateRef<any>;

  public loaded: Promise<boolean> = Promise.resolve(false);
  public supplier: any;
  public currentId: string = '';

  public comments: any[] = [];
  public commentText: string = '';

  public newWarehouse: any = {};


  public tableConfig: TableConfig = {} as TableConfig;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _catalogService: CatalogService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _commentService: CommentsService,
    private modalService: NgbModal,
    private _dynamicTableService: DynamicTableService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('catalog-suppliers-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loaded = Promise.resolve(false);
    this.currentId = this._route.snapshot.paramMap.get('id') || '';
    this._catalogService.getSupplier(this.currentId)
      .then((response: any) => {
        this.supplier = response;
        this.loaded = Promise.resolve(true);
      });
    this._dynamicTableService.getDynamicTable('warehouses')
      .then((table) => {
        this.tableConfig = table;
        const activeColumn = this.tableConfig.columns.find(column => column.prop === 'active');
        if (activeColumn) {
          activeColumn.cellTemplate = this.activeCell;
        }
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getWarehouses(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._route,
        queryParams,
      }
    );
    query.filters = query.filters || {};
    query.filters.supplier = {$oid: this.currentId};
    query['populateFields'] = ['category', 'supplier'];
    const warehouses: DynamicData = await this._catalogService.getWarehouseList(query)
    warehouses.rows.forEach(warehouse => {
      warehouse.actions = warehouse.actions || [];
      warehouse.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/warehouses/' + warehouse.id
      });
    })

    return warehouses;
  }

  saveSupplier() {
    this._catalogService.saveSupplier(this.supplier)
      .then((response: any) => {
        this.supplier = response;
        Swal.fire('Fornitore salvato', 'Il fornitore è stato salvato con successo', 'success');
      })
      .catch((error: any) => {
        Swal.fire('Errore', 'Si è verificato un errore durante il salvataggio del fornitore', 'error');
      });
  }

  deleteSupplier() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Questa operazione non può essere annullata',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, elimina',
      cancelButtonText: 'Annulla'
    })
      .then((result) => {
        if (result.isConfirmed) {
          this._catalogService.deleteSupplier(this.supplier.id)
            .then(() => {
              Swal.fire('Fornitore eliminato', 'Il fornitore è stato eliminato con successo', 'success');
              this._router.navigate(['/suppliers']);
            })
            .catch((error: any) => {
              Swal.fire('Errore', 'Si è verificato un errore durante l\'eliminazione del fornitore', 'error');
            });
        }
      });
  }

  getLocalUser(): {
    name: string,
    avatar: string
  } {
    const userName = localStorage.getItem('comment-user-name') || "Cap Nemo";
    const userAvatar = localStorage.getItem('comment-user-avatar') || "assets/images/avatars/default-avatar.png";
    return {
      name: userName,
      avatar: userAvatar
    }
  }

  setLocalUser(name: string, avatar: string) {
    localStorage.setItem('comment-user-name', name);
    localStorage.setItem('comment-user-avatar', `assets/images/avatars/${avatar}.jpg`);
  }

  loadComments() {
    this._commentService.getCommentList(this.currentId)
      .then((response: any) => {
        this.comments = response.rows;
      })
      .catch((error: any) => {
        console.error('Error fetching comments:', error);
      });
  }

  addComment() {
    let user = this.getLocalUser();
    if (this.commentText.trim()) {
      this._commentService.setComment(this.currentId, this.commentText, user)
        .then((response: any) => {
          this.commentText = '';
          this.loadComments();
          Swal.fire({
            title: 'Conferma',
            text: 'Commento aggiunto con successo',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
        })
        .catch((error: any) => {
          console.error('Error fetching comments:', error);
          Swal.fire({
            title: 'Errore',
            text: 'Errore durante l\'invio del commento',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
    }
  }

  changeWarehouse(input: any, row: any) {
    Swal2.fire(
      {
        title: 'Attenzione',
        text: `Sei sicuro di voler ${input.checked ? 'attivare' : 'disattivare'} il magazzino ${row.code}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }
    ).then((result) => {
        if (result.isConfirmed) {
          console.log(`Making the request to ${input.checked ? 'enable' : 'disable'} the warehouse ${row.code} (${row.id})`);
          this._catalogService.toggleSupplierWarehouse(this.currentId, row.id, input.checked)
            .then((response: any) => {
              row.active = response.active;
              Swal.fire({
                title: 'Conferma',
                text: `Magazzino ${input.checked ? 'attivato' : 'disattivato'} correttamente`,
                icon: 'success',
                confirmButtonText: 'Ok'
              });
            })
            .catch((error: any) => {
              console.error('Error fetching comments:', error);
              Swal.fire({
                title: 'Errore',
                icon: 'error',
                confirmButtonText: 'Ok'
              });
            })
        }
      }
    )
  }

  addWarehouse(addNewWarehouseModal: TemplateRef<any>) {
    this.newWarehouse = {
      supplier: this.currentId,
      name: '',
      code: '',
      active: true,
      label: '',
      warnings: [],
    };
    this.modalService.open(addNewWarehouseModal, {size: 'md'});
  }

  addnewWarehouse() {
    this._catalogService.newWarehouse(this.newWarehouse)
      .then((response: any) => {
        this.modalService.dismissAll();
        this.loadComments();
        Swal.fire({
          title: 'Conferma',
          text: 'Magazzino aggiunto con successo',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      })
      .catch((error: any) => {
        console.error('Error fetching comments:', error);
        Swal.fire({
          title: 'Errore',
          text: 'Errore durante l\'aggiunta del magazzino',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      });
  }
}
