import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "@core/services/authentication.service";

@Injectable({
  providedIn: 'root'
})
class PermissionsService {

  constructor(
    private _router: Router,
    private _authService: AuthenticationService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await this._authService.checkLogin();
    if (next.data?.['userLoggedIn']) {
      if (!this._authService.checkUserLogged()) {
        this._router.navigate(['/auth/login']);
        return false;
      }
      return true;
    }
    if (!this._authService.checkAuthenticated()) {
      this._router.navigate(['/auth/login']);
      return false;
    }
    if (next.data?.['permission']) {
      const flag = this._authService.checkPermission(next.data['permission']);
      if (!flag && next.data?.['fallback']) {
        this._router.navigate([next.data?.['fallback']]);
      }
      return flag;
    }
    return true;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(PermissionsService).canActivate(next, state);
}
