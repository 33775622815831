<div class="row">
  <div class="col-lg-4 col-md-6 col-sm-12">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Logo</h4>
      </div>
      <div class="card-body p-1">
        <div class="row">
          <div class="col-12 px-4 py-2 text-center">
            <img class="img-fluid main-picture" *ngIf="brand.logo" src="{{brand.logo}}" alt="brand image">
            <img class="img-fluid main-picture" *ngIf="!brand.logo"
                 src="assets/images/catalog/default-product-picture.png" alt="Product image">
          </div>
          <div class="col-1 position-relative">
            <label for="logo-source" class="pt-05">URL</label>
          </div>
          <div class="col-9">
            <input type="text" id="logo-source" class="form-control" [(ngModel)]="brand.logo">
          </div>
          <div class="col-2 position-relative">
            <button type="button" class="btn btn-primary">
              <app-svg-icon name="upload"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Dati</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h5 class="font-small-3 text-primary">Nome</h5>
            <input type="text" class="form-control mb-1" [(ngModel)]="brand.name">
          </div>
          <div class="col-12">
            <h5 class="font-small-3 text-primary">Codice</h5>
            <input type="text" class="form-control mb-1" [(ngModel)]="brand.code">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Kromeda</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h5 class="font-small-3 text-primary">Codice</h5>
            <input type="text" class="form-control mb-1" [(ngModel)]="brand.info.kromeda.code">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-2 col-md-6 col-sm-12">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title">َAzioni</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <button type="button" class="btn btn-primary"
                  (click)="saveBrand()">
            <app-svg-icon class="mr-1" name="save"></app-svg-icon>
            Salva
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<h1 class="font-medium-5 px-025 my-1">Prodotti</h1>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getProducts.bind(this)"
    [columns]="tableConfig.columns"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>
