import { Injectable } from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { mergeMap, Observable, of, throwError } from "rxjs";
import { environment } from "environments/environment";
import fakeDb from "@fake-db";
import { type } from "@amcharts/amcharts5";

/**
 * This is a fake backend interceptor for development purposes.
 * It intercepts all the requests and returns fake data from the fake-db.ts file.
 */
@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

  /**
   * This function intercepts the request and returns fake data from the fake-db.ts file.
   *
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(handleRoute));
    // .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
    // .pipe(delay(500))
    // .pipe(dematerialize());


    /**
     * This function handles the route and returns the fake data from the fake-db folder.
     */
    function handleRoute(): Observable<HttpEvent<any>> {
      if (url.startsWith('/assets') || url.startsWith('assets')) {
        return next.handle(request);
      }
      if(url != "https://api.tulero.it/api/app/ping") {
        console.log('[FDB | req]', {url, method, headers, body})
      }
      const parsedUrl = new URL(url);

      // check for fake auth api urls
      let apiKey = Object.keys(environment.apis).find((key: string): boolean => {
        const api = environment.apis[key];

        // get the correct url based on the environment
        const requestURL = api.isDev ? api.devUrl : api.baseUrl;
        const newUrl = new URL(requestURL);

        // match only when the api is in Fake mode and the hostname is the same
        return api.isFake && parsedUrl.href.startsWith(newUrl.href);
      })

      // if the api is not found, handle the request normally
      if (!apiKey) {
        return next.handle(request);
      }

      let api = environment.apis[apiKey];

      // if the api is found try to find the endpoint
      const apiUrl = api.isDev ? api.devUrl : api.baseUrl;
      const endpointPath = url.replace(apiUrl, '');
      const argumentRegex = new RegExp("\{[a-zA-Z0-9]+\}", 'g');

      // initialize the fake source to work on it later
      let fakeSource = "";

      // find the endpoint
      const endpointName = Object.keys(api.endpoints).find((endpointName: string): boolean => {
        const endpoint = api.endpoints[endpointName];

        // abort if the method is not the same
        if (method.toLowerCase() !== endpoint.method.toLowerCase()) {
          return false
        }

        // find any path arguments with the syntax {argument}
        const pathArguments = endpoint.url.match(argumentRegex);
        let endpointRegexString = endpoint.url;

        if (pathArguments) {
          // create the regex that match the arguments and name the groups with the argument name
          pathArguments.forEach((argument: string): void => {
            endpointRegexString = endpointRegexString.replace(argument, `(?<${argument.substring(1, argument.length - 1)}>[^/]+)`);
          })
        }

        // create the regex that match the endpoint
        const endpointRegex = new RegExp("^" + endpointRegexString + "$");

        // match the endpoint
        const match = endpointRegex.exec(endpointPath);

        if (match && endpoint.fakeSource) {
          // if the endpoint is found, replace the arguments with the corresponding value in the fake source string
          fakeSource = endpoint.fakeSource;
          Object.keys(match.groups || {}).forEach((key: string): void => {
            fakeSource = fakeSource.replace(`{${key}}`, (match.groups || {})[key]);
          })
        }

        // return the match to find the correct endpoint
        return !!match;
      })

      // if the endpoint is not found, handle the request normally
      if (!endpointName) {
        return ok({});
      }

      // if the endpoint is found, find the correct data in the fake-db
      let data: any = fakeDb
      const keys = fakeSource.split('.');
      keys.some((key: string): boolean => {
        data = data[key];
        return (typeof data === 'function');
      })

      if (typeof data === 'function') {
        data = data(body, keys);
      }

      console.log('[FB | res]', { endpointName, data })
      // return the data
      return ok({ data });
    }


    // -----------------------------------------------------------------------------------------------------
    // @ helper functions
    // -----------------------------------------------------------------------------------------------------

    /**
     * This function returns an Observable of HttpResponse with the given body.
     * @param body
     */
    function ok(body: any) {
      return of(new HttpResponse({ status: 200, body }));
    }

    /**
     * Throws an error of unauthorized.
     */
    function unauthorized() {
      return throwError({ status: 401, error: { message: 'unauthorized' } });
    }

    /**
     * Throws an error of error.
     * @param message
     */
    function error(message: any) {
      return throwError({ status: 400, error: { message } });
    }
  }
}

export const fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};

