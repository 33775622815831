import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {CustomersService} from "../../../../@core/services/customers.service";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal2 from "sweetalert2";

@Component({
  selector: 'app-alert-view',
  templateUrl: './alerts-view.component.html',
  styleUrls: ['./alerts-view.component.scss']
})

export class AlertsViewComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;
  public loaded: Promise<boolean> = Promise.resolve(false);
  public currentId: any;
  public alert: any ;
  public alerts: any[] = [];
  protected networks: any[] = [];
  public newNetwork: any;


  public types = [
    {id: 'info', name: 'Info'},
    {id: 'warning', name: 'Avvertimento'},
    {id: 'danger', name: 'Pericolo'},
    {id: 'success', name: 'Successo'},
    {id: 'primary', name: 'Primario'},
    {id: 'secondary', name: 'Secondario'},
    {id: 'light', name: 'Chiaro'},
    {id: 'dark', name: 'Scuro'},
    ];


  public tableConfig: TableConfig = {} as TableConfig;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------


  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _customerService: CustomersService,
    private _dynamicTableService: DynamicTableService,
    public _authService: AuthenticationService,
    private modalService: NgbModal
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('cms-alerts-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loaded = Promise.resolve(false);
    this.currentId = this._activatedRoute.snapshot.paramMap.get('id') || '';
    this._shopService.getAlert(this.currentId).then((data: any) => {
      this.alert = data;
      this.alerts.push(this.alert);
      this.loaded = Promise.resolve(true);
      this._customerService.getNetworks()
        .then((response: any) => {
          this.networks = response.rows;
          this.networks.forEach((network: any) => {
            network.active = network.active || false;
          });
          this.networks.forEach((network: any) => {
            this.alert.networks.forEach((alertNetwork: any) => {
              console.log(network.id, alertNetwork);
              if (network.id === alertNetwork) {
                network.active = true;
              }
            });
          });
        });
    } );

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  saveAlert() {
    this.alert.networks = [];
    this.networks.forEach((network: any) => {
      if (network.active) {
        this.alert.networks.push(network.id);
      }
    });
    this._shopService.updateAlert(this.alert).then((data: any) => {
      Swal2.fire('Successo', 'Avviso salvato con successo', 'success');
    });
  }

  deleteAlert() {
    Swal2.fire({
      title: 'Sei sicuro?',
      text: 'Non sarai in grado di recuperare questo avviso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, elimina',
      cancelButtonText: 'No, annulla',
    }).then((result) => {
      if (result.isConfirmed) {
        this._shopService.deleteAlert(this.alert.id).then((data: any) => {
          Swal2.fire('Eliminato!', 'Il avviso è stato eliminato.', 'success');
          this._router.navigate(['/alerts']);
        });
      }
    });
  }

  uploadImage() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e: any) => {
      const file = e.target.files[0];
      this._shopService.uploadAlertImage(file).then((data: any) => {
        this.alert.image = data.url;
      });
    };
    input.click();
  }


  deletePicture(i: number) {
    this.alert.pictures.splice(i, 1);
  }


  toggleAlert(alert: any) {
    alert.alert.expanded = !alert.alert.expanded;
  }

  showPreview(alertModal: TemplateRef<any>) {
    this.modalService.open(alertModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  CloseModal() {
    this.modalService.dismissAll();
  }

  deleteNetwork(i: number) {
    this.networks[i].active = false;
    const index = this.alert.networks.indexOf(this.networks[i].id);
    this.alert.networks.splice(index, 1);
  }

  addNetwork(networkModal: TemplateRef<any>) {
    this.modalService.open(networkModal, {
      size: 'sm',
      windowClass: 'modal modal-primary'
    });
  }

  addNewNetwork() {
    this.alert.networks.push(this.newNetwork);
    this.newNetwork = null;
    this.networks.forEach((network: any) => {
      network.active = false;
      if (this.alert.networks.includes(network.id)) {
        network.active = true;
      }
    } );
    this.modalService.dismissAll();
  }

  goToNetworkOrigin(i: number) {
    window.open(this.networks[i].origin);
  }
}
