import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService} from "../../../@core/services/authentication.service";
import { ConfigService} from "../../../@core/services/config.service";
import { FormBuilder } from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import { ApplicationService } from "../../../@core/services/application.service";

@Component({
  selector: 'app-business-selection',
  templateUrl: './business-selection.component.html',
  styleUrls: ['./business-selection.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class BusinessSelectionComponent {

  identities: any[] = [];
  permenantIdentities: any[] = [];
  searchQuery: string = '';
  placeholderText: string = 'Cerca...';
  private currentUser: any;
  public coreConfig: any;

  private _unsubscribeAll: Subject<any>;


  constructor(
    private _authenticationService: AuthenticationService,
    private _applicationService: ApplicationService,
    private router: Router
  ) {
    this._unsubscribeAll = new Subject();
    // Configure the layout
    this._applicationService.layoutFullWidth();
  }

  ngOnInit() {
    this._authenticationService.getIdentities().then((identities) => {
      this.identities = identities as any[];
      this.permenantIdentities = identities as any[];
    });
    // this._authenticationService.business.subscribe((business) => {
    //   if (business) {
    //     this.router.navigate(['/home']);
    //   }
    // })

    // this.businessesSubscription = this._authenticationService.businesses$.subscribe(
    //   (businesses) => {
    //     this.businessItems = businesses;
    //   },
    //   (error) => {
    //     console.error('Error fetching businesses:', error);
    //   }
    // );
    // this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
    //   this.coreConfig = config;
    // });
  }

  signOut() {
    // this._authenticationService.signout();
  }


  identifyUserWithBusinessCode(code: string) {
    console.log('Identifying user with business code:', code)
    this._authenticationService.setIdentity(code)
      .then(async () => {
        console.log('User identified with business code:', code)
        await this._authenticationService.checkLogin(true);
        console.log('User logged in with business code:', code)
        this.router.navigate(['/']);
      })
  }


  onSearch(event: any) {
    const inputValue = event.target.value as string;
    this.searchQuery = inputValue;
    if (inputValue) {
      console.log('Filtering business items by:', inputValue);
      this.identities = this.identities.filter((item) => {
        return item.name.toLowerCase().includes(inputValue.toLowerCase());
      });
    } else {
      this.identities = this.permenantIdentities;
    }
  }

  clearSearch() {
    this.searchQuery = '';
    this.placeholderText = 'Cerca...';
  }

  businessRegister() {
    this.router.navigate(['/authentication/business-registration']);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
