import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, Subscription, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CustomersService} from "@core/services/customers.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {NgModule} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: 'app-user-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.scss']
})

export class UsersViewComponent implements OnInit, OnDestroy {

  @ViewChild('changePasswordModal') changePasswordModal!: TemplateRef<any>;
  @ViewChild('modifyModal') modifyModal!: TemplateRef<any>;
  @ViewChild('addBusinessModal') addBusinessModal!: TemplateRef<any>;

  public userExist: boolean = false;

  public businessItems: any[] = [];
  public businessList: any[] = [];
  public code: string = '';
  public user: any = {};
  public passwordForm!: UntypedFormGroup;
  public modifyForm!: UntypedFormGroup;
  public status: string = '';
  public statuses: any = {
    CREATED: {
      class: 'badge-light-warning',
      label: 'Creato'
    },
    VERIFIED: {
      class: 'badge-light-info',
      label: 'Verificato'
    },
    COMPLETED: {
      class: 'badge-light-primary',
      label: 'Completato'
    },
    SUSPENDED: {
      class: 'badge-light-danger',
      label: 'Sospeso'
    },
    CANCELED: {
      class: 'badge-light-danger',
      label: 'Annullato'
    },
  }
  public statuses2 = [
    {
      class: 'badge-light-warning',
      label: 'Creato',
      value: 'CREATED'
    },
    {
      class: 'badge-light-info',
      label: 'Verificato',
      value: 'VERIFIED'
    },
    {
      class: 'badge-light-primary',
      label: 'Completato',
      value: 'COMPLETED'
    },
    {
      class: 'badge-light-danger',
      label: 'Sospeso',
      value: 'SUSPENDED'
    },
    {
      class: 'badge-light-danger',
      label: 'Annullato',
      value: 'CANCELED'
    },
  ]
  // Private
  private _unsubscribeAll: Subject<any>;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  public identities: any;

  constructor(
    private modalService: NgbModal,
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('customers-users-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.code = this._route.snapshot.paramMap.get('code') || '';
    this._customerService.getSingleUser(this.code)
      .then((response) => {
        this.user = response;
        this._customerService.getIdentities(this.user._id)
          .then((response) => {
           this.identities = response;
            this.identities.forEach((identity: any) => {
              this.businessItems.push(identity.business);
          })
          } )
          .catch((error) => {
            console.log('Businesses error', error);
          })
      })
      .catch((error) => {
        console.log('User error', error);
      })

    this.passwordForm = this._formBuilder.group({
      password: ['', Validators.required]
    });
    this.modifyForm = this._formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      status: ['', Validators.required]
    });
    //set the users value inside the mmodify form default values
    this.modifyForm.patchValue({
      username: this.user.username,
      name: this.user.name,
      surname: this.user.surname,
      email: this.user.email,
      phone: this.user.phone,
      status: this.user.status
    });
    this.status = this.user.status;
  }


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  onSubmit() {
    console.log('submitted')
    const formData = this.passwordForm.value;

    this._customerService.updateUserPassword(this.user._id, formData.password)
      .then((response) => {
        console.log('Password updated', response);
        this.modalService.dismissAll();
        Swal.fire({
          title: 'Password aggiornata!',
          text: 'La password è stata aggiornata con successo.',
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        });
      })
      .catch((error) => {
        console.log('Password update error', error);
      })
  }

  onModify() {
    const formData = this.modifyForm.value;
    formData.status = this.status;
    this._customerService.userEdit(this.user._id, formData)
      .then((response) => {
        console.log('User updated', response);
        this.modalService.dismissAll();
        Swal.fire({
          title: 'Utente aggiornato!',
          text: 'L\'utente è stato aggiornato con successo.',
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        });
      })
      .catch((error) => {
        console.log('User update error', error);
      })
    this._customerService.getSingleUser(this.code)
      .then((response) => {
        console.log('User', response);
        this.user = response;
        this._customerService.getBusinesses(this.user._id)
          .then((response) => {
            console.log('Businesses', response);
            this.businessItems = response;
          })
          .catch((error) => {
            console.log('Businesses error', error);
          })
      })
      .catch((error) => {
        console.log('User error', error);
      })
  }

  changePassword() {
    this.openPasswordModal(this.changePasswordModal);
  }

  modifyUser() {
    this.modifyForm.patchValue({
      name: this.user.name,
      surname: this.user.surname,
      username: this.user.username,
      email: this.user.email,
      phone: this.user.phone,
      status: this.user.status
    });
    console.log('modify user', this.user, this.modifyForm.value)
    this.openModifyModal(this.modifyModal);
  }

  openPasswordModal(modalPassword: TemplateRef<any>): NgbModalRef {
    return this.modalService.open(modalPassword, {
      size: 'sm',
      windowClass: 'modal modal-primary'
    });
  }

  openModifyModal(modalModify: TemplateRef<any>): NgbModalRef {
    return this.modalService.open(modalModify, {
      size: 'normal',
      windowClass: 'modal modal-primary'
    });
  }

  suspendUser() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Vuoi sospendere l\'utente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sì, sospendilo!',
      cancelButtonText: 'No, annulla!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1'
      },
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        const suspendedUser = {
          name: this.user.name,
          surname: this.user.surname,
          username: this.user.username,
          email: this.user.email,
          phone: this.user.phone,
          status: 'SUSPENDED'
        }
        this.user.status = 'SUSPENDED';
        this._customerService.userEdit(this.user._id, suspendedUser)
          .then((response) => {
            console.log('User suspended', response);
            this.modalService.dismissAll();
            Swal.fire({
              title: 'Utente sospeso!',
              text: 'L\'utente è stato sospeso con successo.',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary'
              }
            });
          })
          .catch((error) => {
            console.log('User update error', error);
          })
        this._customerService.getSingleUser(this.code)
          .then((response) => {
            console.log('User', response);
            this.user = response;
            this._customerService.getBusinesses(this.user._id)
              .then((response) => {
                console.log('Businesses', response);
                this.businessItems = response;
              })
              .catch((error) => {
                console.log('Businesses error', error);
              })
          })
          .catch((error) => {
            console.log('User error', error);
          })
      }
    });
  }


  onStatusChange(event: any) {
    const selectedStatus = event.target.value;
    this.modifyForm.patchValue({
      status: selectedStatus,
    });
    // Optionally, update the user's status immediately upon selection
    this.user.status = selectedStatus;
  }

  protected readonly Object = Object;
  search: any = '';

  changeStatus($event: any) {
    this.status = $event.value;
  }

  addBusiness(addBusinessModal: TemplateRef<any>) {
    return this.modalService.open(addBusinessModal, {
      size: 'normal',
      windowClass: 'modal modal-primary'
    });
  }

  searchBusiness() {
    this._customerService.getBusinessList({search: this.search})
      .then((response) => {
        console.log('Businesses', response);
        this.businessList = response.rows;
      })
      .catch((error) => {
        console.log('Businesses error', error);
      })
  }

  addBusinessToUser(id: string) {
    console.log('add business to user', id);
    this._customerService.setBusinessToUser(this.user._id, id)
      .then((response) => {
        console.log('Business added', response);
        this.modalService.dismissAll();
        Swal.fire({
          title: 'Business aggiunto!',
          text: 'Il business è stato aggiunto con successo.',
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        });
        this._customerService.getIdentities(this.user._id)
          .then((response) => {
            this.identities = response;
            this.identities.forEach((identity: any) => {
              console.log('Business', identity);
              this.businessItems.push(identity.business);
            } )
          })
          .catch((error) => {
            console.log('Businesses error', error);
          })
      })
      .catch((error) => {
        console.log('Business add error', error);
      })

  }

  removeBusiness(id: string) {
    console.log('remove business', id);
    this._customerService.revokeBusiness(id, this.user._id)
      .then((response) => {
        console.log('Business removed', response);
        this.modalService.dismissAll();
        Swal.fire({
          title: 'Business rimosso!',
          text: 'Il business è stato rimosso con successo.',
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        });
        this._customerService.getIdentities(this.user._id)
          .then((response) => {
            console.log('Businesses', response);
            this.businessItems = response;
          })
          .catch((error) => {
            console.log('Businesses error', error);
          })
      })
      .catch((error) => {
        console.log('Business remove error', error);
      })
  }

  loginAs(id: string) {
    console.log('login as', id);
    this._customerService.switchIdentity(id)
      .then((response) => {
        console.log('Login as', response);
        window.open('https://business.tulero.it', '_blank');
      })
      .catch((error) => {
        console.log('Login as error', error);
      })
  }
}
