import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {ApplicationService} from "../../../../@core/services/application.service";
import {ShopService} from "../../../../@core/services/shop.service";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {CustomersService} from "../../../../@core/services/customers.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: 'app-order-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.scss']
})

export class InvoiceViewComponent implements OnInit, OnDestroy {
  public currentInvoice: any = {};
  public loaded: Promise<boolean> = Promise.resolve(false);
  // Private
  private _unsubscribeAll: Subject<any>;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  public invoiceForm!: FormGroup;
  public currentCode: string = '';
  public paymentMethods: any[] = []
  public newPaymentMethod: any;
  public total: any = {
    invoice: 0,
    tax: 0,
    walletDiscount: 0,
    collectionExpenses: 0,
  }

  public statuses = [
    {
      class: 'badge-light-success',
      label: 'Inviata',
      value: 'SENT'
    },
    {
      class: 'badge-light-danger',
      label: 'Annulata',
      value: 'CANCELED'
    },
    {
      class: 'badge-light-info',
      label: 'Pronta',
      value: 'READY'
    },
  ]
  public status: any;
  public showDropdown: boolean = false;
  public selectedBusiness: any = null;
  public businesses!: any[];
  public search: any;
  public prevoiusStatus: any;

  constructor(
    private _applicationService: ApplicationService,
    private _route: ActivatedRoute,
    private _shopService: ShopService,
    private _formBuilder: FormBuilder,
    private _customerService: CustomersService,
    private modalService: NgbModal,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-invoices-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    //merge route params and data
    this._route.params.pipe(takeUntil(this._unsubscribeAll)).subscribe((params: any) => {
      this.currentCode = params.code;
    });
    this._shopService.getInvoiceFromCode(this.currentCode).then((response) => {
      this.currentInvoice = response;
      this.selectedBusiness = this.currentInvoice.businessData;
      this.search = this.currentInvoice.businessData.name;
      this.status = this.statuses.find((status: any) => status.value === this.currentInvoice.status);
      this.prevoiusStatus = this.status;
      this.total.invoice = 0;
      this.total.tax = 0;
      this.total.walletDiscount = 0;
      this.total.collectionExpenses = 0;
      this.currentInvoice.lines.forEach((line: any) => {
        console.log(line)
        if (line.price) {
          this.total.invoice += line.price * line.quantity;
          this.total.tax += line.price * line.quantity * line.vat
        }
      });
      console.log(this.currentInvoice.businessData)
      this.initForm();
      this.loaded = Promise.resolve(true);
      this.showDropdown = false;
    }).catch((error) => {
      this.loaded = Promise.resolve(true);
    });

    this._customerService.getBusinessesList()
      .then((businesses) => {
        this.businesses = businesses.rows;
        console.log('businesses', businesses)
        console.log('this.businesses', this.businesses)
      })
      .catch((error: any) => {
        console.log(error);
      });

    console.log(this.currentInvoice)
  }

  initForm() {
    this.invoiceForm = this._formBuilder.group({
      statusInput: [this.currentInvoice.status || ''],
      invoiceDate: [this.currentInvoice.invoiceDate || ''],
      creationDate: [this.currentInvoice.createdAt || ''],
      updateDate: [this.currentInvoice.updatedAt || ''],
      paymentMethodInput: [this.currentInvoice.payments.paymentMethod || ''],
      businessInput: [this.currentInvoice.businessData.name || ''],
      ivaInput: [this.selectedBusiness.vat || ''],
      fiscalInput: [this.selectedBusiness.fiscalCode || ''],
      pecInput: [this.selectedBusiness.pec || ''],
      uniqueInput: [this.selectedBusiness.uniqueCode || ''],
      streetInput: [this.selectedBusiness.location.street || ''],
      numberInput: [this.selectedBusiness.location.number || ''],
      zipInput: [this.selectedBusiness.location.zip || ''],
      cityInput: [this.selectedBusiness.location.city || ''],
      provinceInput: [this.selectedBusiness.location.province || ''],
      regionInput: [this.selectedBusiness.location.region || ''],
      countryInput: [this.selectedBusiness.location.country || ''],
    });

  }


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  rowTypes: any[] = [
    {
      label: 'Prodotto',
      value: 'PRODUCT'
    },
    {
      label: 'Testo',
      value: 'TEXT'
    },
  ];
  rowType: any =
    {
      label: 'Prodotto',
      value: 'PRODUCT'
    }

  newTextCode: string = '';
  newTextDescription: string = '';
  newProductDescription: string = '';
  newProductCode: string = '';
  newProductQuantity: number = 1;
  newProductPrice: string = '';
  newProductUnit: string = 'NR';
  newProductDiscount: string = '';
  newProductVat: number = 0.22;

  changePaymentMethod(paymentMethod: any) {
    this.newPaymentMethod = paymentMethod;
  }

  downloadInvoice() {

  }

  changeStatus($event: any) {
    this.currentInvoice.status = $event.value;
    this.prevoiusStatus = this.status;
    this.status = this.statuses.find((status: any) => status.value === this.currentInvoice.status);
    console.log(this.status);
    console.log(this.prevoiusStatus);
  }

  searchBusiness(search: string) {
    this.search = search;
    this.showDropdown = true;
    if (search.length == 0) {
      this.showDropdown = false;
      this.selectedBusiness = this.currentInvoice.businessData;
      this.businesses = [];
      return;
    } else {
      this._customerService.getBusinessList({search: search})
        .then((response) => {
          this.businesses = response.rows;
          console.log('Businesses', this.businesses);
        })
        .catch((error) => {
          console.log('Businesses error', error);
        })
    }
  }


  selectBusiness(business: any) {
    this.selectedBusiness = business;
    this.paymentMethods = business.payments;
    this.search = business.name;
    this.showDropdown = false;

  }

  changeVatNumber(value: string) {
    this.currentInvoice.businessData.vat = value;

  }

  changeAddress(value: string) {

  }

  openAddProductModal(addNewRowModal: TemplateRef<any>) {
    console.log(this.rowType)
    return this.modalService.open(addNewRowModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  changeShipping() {

  }

  removeOrder() {
  }

  changeQuantity(order: any, product: any, number: number) {
    const line = this.currentInvoice.lines.find((line: any) => line.code === product.code);
    if (line) {
      if (line.quantity + number >= 0) {
        line.quantity += number;
      }
    }
    this.total.invoice = 0;
    this.total.tax = 0;
    this.total.walletDiscount = 0;
    this.total.collectionExpenses = 0;
    this.currentInvoice.lines.forEach((line: any) => {
      if (line.price) {
        this.total.invoice += line.price * line.quantity;
        console.log(line.price * line.quantity * line.vat)
        this.total.tax += line.price * line.quantity * line.vat;
      }
    });
  }

  removeProduct(order: any, product: any) {
    this.currentInvoice.lines = this.currentInvoice.lines.filter((line: any) => line.code !== product.code);
    console.log(this.currentInvoice.lines);
    this.total.invoice = 0;
    this.total.tax = 0;
    this.total.walletDiscount = 0;
    this.total.collectionExpenses = 0;
    this.currentInvoice.lines.forEach((line: any) => {
      if (line.price) {
        this.total.invoice += line.price * line.quantity;
        console.log(line.price * line.quantity * line.vat)
        this.total.tax += line.price * line.quantity * line.vat;
      }
    });
  }

  changeCode(order: any, value: string) {

  }

  changeDescription(order: any, value: string) {

  }

  changePrice(order: any, value: string) {
    let valueNumber = parseFloat(value);
    order.price = valueNumber;
    //find that order in the invoice lines and update it
    const line = this.currentInvoice.lines.find((line: any) => line.code === order.code);
    if (line) {
      line.price = valueNumber;
    }

    this.total.invoice = 0;
    this.total.tax = 0;
    this.total.walletDiscount = 0;
    this.total.collectionExpenses = 0;
    this.currentInvoice.lines.forEach((line: any) => {
      if (line.price) {
        this.total.invoice += line.price * line.quantity;
        console.log(line.price * line.quantity * line.vat)
        this.total.tax += line.price * line.quantity * line.vat;
      }
    });
  }

  changeVat(order: any, value: string) {
    let valueNumber = parseFloat(value);
    if (valueNumber > 1) {
      valueNumber = valueNumber / 100;
    }
    order.vat = valueNumber;
    //find that order in the invoice lines and update it
    const line = this.currentInvoice.lines.find((line: any) => line.code === order.code);
    if (line) {
      line.vat = valueNumber;
    }

    this.total.invoice = 0;
    this.total.tax = 0;
    this.total.walletDiscount = 0;
    this.total.collectionExpenses = 0;
    this.currentInvoice.lines.forEach((line: any) => {
      if (line.price) {
        this.total.invoice += line.price * line.quantity;
        console.log(line.price * line.quantity * line.vat)
        this.total.tax += line.price * line.quantity * line.vat;
      }
    });
  }

  changeUniqueNumber(value: string) {
    this.currentInvoice.businessData.uniqueCode = value;
  }

  changePecNumber(value: string) {
    this.currentInvoice.businessData.pec = value;
  }

  changeFiscal(value: string) {
    this.currentInvoice.businessData.fiscalCode = value;
  }

  saveInvoice() {
    console.log(this.currentInvoice);
    this._shopService.updateInvoice(this.currentInvoice.id, this.currentInvoice)
      .then((response) => {
        console.log(response)
        Swal.fire({
          title: 'Fattura aggiornata',
          text: 'La fattura è stata aggiornata correttamente',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          cancelButtonText: 'Annulla'
        }).then((result) => {
          this._shopService.getInvoiceFromCode(this.currentCode).then((response) => {
            this.currentInvoice = response;
            console.log(this.currentInvoice)
            this.selectedBusiness = this.currentInvoice.businessData;
            this.search = this.currentInvoice.businessData.name;
            this.status = this.statuses.find((status: any) => status.value === this.currentInvoice.status);
            this.prevoiusStatus = this.status;
            console.log(this, this.prevoiusStatus);
            console.log(this.status);
            console.log(this.currentInvoice)
            console.log(this.currentInvoice.updatedAt)
            this.total.invoice = 0;
            this.total.tax = 0;
            this.total.walletDiscount = 0;
            this.total.collectionExpenses = 0;
            this.currentInvoice.lines.forEach((line: any) => {
              console.log(line)
              if (line.price) {
                this.total.invoice += line.price * line.quantity;
                this.total.tax += line.price * line.quantity * line.vat
              }
            });
            console.log(this.currentInvoice.businessData)
            this.initForm();
            this.loaded = Promise.resolve(true);
            this.showDropdown = false;
          }).catch((error) => {
            this.loaded = Promise.resolve(true);
          })

            .catch((error) => {
              console.log(error)
            })
        });
      }).catch((error) => {
      console.log(error)
    });
  }

  changeClient(value: string) {
    this.currentInvoice.businessData.name = value;
  }

  changeOrderCode(line: any, value: string) {
    const lineToUpdate = this.currentInvoice.lines.find((line: any) => line.code === line.code);

  }

  changeOrderDescription(line: any, value: string) {
    const lineToUpdate = this.currentInvoice.lines.find((line: any) => line.code === line.code);
  }

  changeRowType($event: any) {
    this.rowType = $event;
    console.log(this.rowType);
  }

  changeTextCode(value: string) {
    this.newTextCode = value;
  }

  changeTextDescription(value: string) {
    this.newTextDescription = value;
  }

  changeProductCodeModal(value: string) {
    this.newProductCode = value;
  }

  changeProductDescriptionModal(value: string) {
    this.newProductDescription = value;
  }

  changeProductQuantity(value: string) {
    this.newProductQuantity = parseInt(value);
  }

  changeProductPrice(value: string) {
    this.newProductPrice = value;
  }

  changeProductUnit(value: string) {
    this.newProductUnit = value;
  }

  changeProductDiscount(value: string) {
    this.newProductDiscount = value;
  }

  changeProductVat(value: string) {
    this.newProductVat = parseFloat(value);
  }

  addRow() {
    console.log(this.rowType.value)
    if (this.rowType.value === 'PRODUCT') {
      this.addProduct();
    } else {
      this.addText();
    }
  }

  private addProduct() {
    const newProduct = {
      code: this.newProductCode,
      description: this.newProductDescription,
      quantity: this.newProductQuantity,
      price: parseFloat(this.newProductPrice),
      unit: this.newProductUnit,
      discount: this.newProductDiscount,
      vat: this.newProductVat,
      type: 'PRODUCT'
    }
    this.currentInvoice.lines.push(newProduct);
    this.total.invoice = 0;
    this.total.tax = 0;
    this.total.walletDiscount = 0;
    this.total.collectionExpenses = 0;
    this.currentInvoice.lines.forEach((line: any) => {
      if (line.price) {
        this.total.invoice += line.price * line.quantity;
        console.log(line.price * line.quantity * line.vat)
        this.total.tax += line.price * line.quantity * line.vat;
      }
    });
    this.modalService.dismissAll();
    console.log(this.currentInvoice.lines);
  }

  private addText() {
    const newText = {
      code: this.newTextCode,
      description: this.newTextDescription,
      type: 'TEXT'
    }
    this.currentInvoice.lines.push(newText);
    this.modalService.dismissAll();
    console.log(this.currentInvoice.lines);
  }


}
