<!-- business-profile.component.html -->
<div class="row" *ngIf="!loading">
  <div class="col-lg-2 col-md-4 col-sm-6" *ngIf="pagePermissions.image">
    <div class="card glassy card-full-height product-card">
      <div class="card-header pb-0">
        <h4 class="card-title">Immagini</h4>
      </div>
      <div class="card-body p-1">
        <div class="row">
          <div class="col-12 px-4 py-2 text-center">
            <img class="img-fluid main-picture" *ngIf="product.pictures[pictureIndex]"
                 src="{{product.pictures[pictureIndex]}}" alt="Product image">
            <img class="img-fluid main-picture" *ngIf="!product.pictures[pictureIndex]"
                 src="assets/images/catalog/default-product-picture.png" alt="Product image">
          </div>
          <div class="col-12 px-1 position-relative">
            <app-svg-icon class="arrow-icon arrow-icon-left no-margin" name="arrow-left"
                          *ngIf="pictureIndex > 0"
                          (click)="pictureIndex = pictureIndex - 1"></app-svg-icon>

            <app-svg-icon class="arrow-icon arrow-icon-right no-margin" name="arrow-right"
                          *ngIf="pictureIndex < product.pictures.length - 1"
                          (click)="pictureIndex = pictureIndex + 1"></app-svg-icon>
            <ul class="pictures-selector">
              <li class="picture" *ngFor="let picture of product.pictures; let index = index"
                  [ngClass]="{'selected': index === pictureIndex}"
                  (click)="pictureIndex = index">
                <img class="img-fluid" src="{{picture}}" alt="Card image cap">
              </li>
              <li class="picture font-medium-5">
                <label for="file-upload"
                       class="no-margin d-flex full-width full-height align-items-center justify-content-center">
                  <app-svg-icon [name]="'plus'"></app-svg-icon>
                </label>
                <input type="file" id="file-upload" style="display: none;" (change)="onFileSelected($event)">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="pagePermissions.details">
    <div class="card glassy card-full-height product-card">
      <div class="card-header pb-0">
        <h4 class="card-title">Dati</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h5 class="font-small-3 text-primary">Codice</h5>
            <input type="text" class="form-control font-small-3 mb-1" [(ngModel)]="product.code">
          </div>
          <div class="col-12">
            <h5 class="font-small-3 text-primary">Nome</h5>
            <input type="text" class="form-control font-small-3 mb-1" [(ngModel)]="product.name">
          </div>
          <div class="col-12">
            <h5 class="font-small-3 text-primary">Brand</h5>
            <ng-select id="warehouse" [items]="brands" bindLabel="name"
                       placeholder="Seleziona un marchio"
                       [(ngModel)]="product.brand"
                       (input)="onBrandInput($event)"
                       appendTo="body"
                       class="mb-1">
            </ng-select>
          </div>
          <div class="col-12" *ngIf="product.listPrice">
            <h5 class="font-small-3 text-primary">Prezzo di listino</h5>
            <input type="text" class="form-control font-small-3 mb-1" [(ngModel)]="product.listPrice">
          </div>
          <div class="col-12">
            <h5 class="font-small-3 text-primary">Categoria</h5>
            <ng-select id="category" [items]="categories" bindLabel="name"
                       placeholder="Seleziona un categoria"
                       [(ngModel)]="product.category"
                       (input)="onCategoryInput($event)"
                       appendTo="body"
                       class="mb-1">
            </ng-select>
          </div>
          <div class="col-12">
            <h5 class="font-small-3 text-primary">Familia (descrittivo interno)</h5>
            <input type="text" class="form-control font-small-3 mb-1" placeholder="-" [(ngModel)]="product.family">

          </div>
          <div class="col-12">
            <h5 class="font-small-3 text-primary">Descrizione</h5>
            <input type="text" class="form-control font-small-3 mb-1" placeholder="Nessuna descrizione"
                   [(ngModel)]="product.description">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="pagePermissions.info">
    <div class="card glassy card-full-height product-card">
      <div class="card-header pb-0">
        <h4 class="card-title">Informazioni Tecniche</h4>
      </div>
      <div class="card-body">
        <div class="row" *ngIf="product.meta.length">
          <div class="col-12" *ngFor="let meta of product.meta">
            <div class="col-12">
              <h5 class="font-small-3 text-primary">{{ meta.name }}</h5>
              <input type="text" class="form-control font-small-3 mb-1 cross-input d-inline-block"
                     [(ngModel)]="meta.value">
              <button class="btn btn-danger d-inline-block p-05 pr-025 ml-05" (click)="removeMeta(meta)">
                <app-svg-icon name="delete"></app-svg-icon>
              </button>

            </div>
          </div>
        </div>
        <div class="row" *ngIf="!product.meta.length">
          <div class="col-12">
            <p class="font-medium-1"><i>Nessuna informazione tecnica</i></p>
          </div>
        </div>

      </div>
      <div class="card-footer">
        <div class="row">
          <button class="btn btn-primary btn-block col-12" (click)="addMeta(addNewMetaModal)">
            <app-svg-icon name="plus"></app-svg-icon>
            Aggiungi informazione tecnica
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="col-lg-2 col-md-4 col-sm-6" *ngIf="pagePermissions.database">
    <div class="card glassy card-full-height product-card">
      <div class="card-header pb-0">
        <h4 class="card-title">Database</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <ng-container *ngIf="product.info && product.info?.[selectedDatabase]">
            <ng-container *ngFor="let item of product.info?.[selectedDatabase] | keyvalue">
              <div class="col-12" *ngIf="item.value">
                <h5 class="font-small-3 text-primary">{{ item.key }}</h5>
                <input type="text" class="form-control font-small-3 mb-1 cross-input d-inline-block"
                       [(ngModel)]="item.value">
                <button class="btn btn-danger d-inline-block p-05 pr-025 ml-05" (click)="removeInfo(item)">
                  <app-svg-icon name="delete"></app-svg-icon>
                </button>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <button class="btn btn-primary btn-block col-12" (click)="addInfo()">
            <app-svg-icon name="plus"></app-svg-icon>
            Aggiungi campo
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-2 col-md-4 col-sm-6" *ngIf="pagePermissions.actions">
    <div class="row">
      <div class="row-12">
        <div class="card glassy">
          <div class="card-header pb-0">
            <h4 class="card-title">Azioni</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <button *ngIf="pagePermissions.edit" class="btn btn-primary btn-block col-12 mb-1" (click)="save()">
                <app-svg-icon name="save"></app-svg-icon>
                Salva
              </button>
              <button *ngIf="pagePermissions.delete" class="btn btn-danger btn-block col-12" (click)="delete()">
                <app-svg-icon name="delete"></app-svg-icon>
                Elimina
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row-12" *ngIf="pagePermissions.cross">
        <div class="card glassy">
          <div class="card-header pb-0">
            <h4 class="card-title">Cross</h4>
          </div>
          <div class="card-body">
            <div class="row" *ngIf="product.cross?.length">
              <div class="col-12" *ngFor="let cross of product.cross;let index = index;trackBy:trackByCrossIndex;">
                <input type="text" class="form-control font-small-3 mb-1 cross-input d-inline-block"
                       [(ngModel)]="product.cross[index]">
                <button class="btn btn-danger d-inline-block p-05 pr-025 ml-05" (click)="removeCross(index)">
                  <app-svg-icon name="delete"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <button class="btn btn-primary btn-block col-12" (click)="addCross()">
                <app-svg-icon name="plus"></app-svg-icon>
                Aggiungi cross
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-8 col-md-12" *ngIf="pagePermissions.suppliers">
    <div class="card glassy">
      <div class="card-header pb-0">
        <h4 class="card-title">
          Fornitori
          <button class="btn btn-primary d-inline-block p-05 pr-025 ml-025" (click)="addSupplier(addNewSupplierModal)">
            <app-svg-icon name="add"></app-svg-icon>
          </button>
        </h4>
      </div>
      <div class="card-body border-top" *ngFor="let supplier of product.suppliers; let i = index">
        <div class="row">
          <div class="col-lg-2 col-md-3 col-sm-6">
            <h5 class="font-medium-1 text-primary mb-1">
              {{ supplier.supplier.name | titlecase }}
              <button class="btn btn-primary d-inline-block p-025 pr-01 mr-025" (click)="saveSupplier(supplier)">
                <app-svg-icon name="save"></app-svg-icon>
              </button>
              <button class="btn btn-danger d-inline-block p-025 pr-01 mr-025" (click)="deleteSupplier(i)">
                <app-svg-icon name="delete"></app-svg-icon>
              </button>
            </h5>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <h5 class="font-small-3">Codice Brand</h5>
                <input type="text" class="form-control font-small-3 mb-1" placeholder=""
                       [(ngModel)]="supplier.brand_code">
              </div>
              <div class="col-lg-6 col-md-6">
                <h5 class="font-small-3">Codice Prodotto</h5>
                <input type="text" class="form-control font-small-3 mb-1" placeholder=""
                       [(ngModel)]="supplier.article_code">
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-6">
            <div class="row">
              <div class="col-lg-3 col-md-3">
                <h5 class="font-small-3">Minimo</h5>
                <input type="number" class="form-control font-small-3 mb-1" placeholder="1"
                       [(ngModel)]="supplier.min">
              </div>
              <div class="col-lg-3 col-md-3">
                <h5 class="font-small-3">Massimo</h5>
                <input type="number" class="form-control font-small-3 mb-1" placeholder="999999"
                       [(ngModel)]="supplier.max">
              </div>
              <div class="col-lg-3 col-md-3">
                <h5 class="font-small-3">Multipli</h5>
                <input type="number" class="form-control font-small-3 mb-1" placeholder="999999"
                       [(ngModel)]="supplier.multiplier">
              </div>
              <div class="col-lg-3 col-md-3">
                <h5 class="font-small-3">Unità</h5>
                <input type="text" class="form-control font-small-3 mb-1" placeholder="PZ"
                       [(ngModel)]="supplier.unit">
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
                <h5 class="font-small-3">Prezzo</h5>
                <input type="number" class="form-control font-small-3 mb-1" placeholder=""
                       [(ngModel)]="supplier.price">
          </div>
          <div class="col-lg-1 col-md-1 pt-1">
            <button class="btn btn-primary d-inline-block p-05 font-small-1" (click)="addWarehouse(addNewWarehouseModal, i, supplier)">
              Aggiungi magazzino
            </button>
          </div>
          <div class="col-lg-11 col-md-11 border-top pt-1">
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let warehouse of supplier.warehouses; let j = index">
                <h5 class="mb-1 font-small-4 d-flex flex-row">
                  <div class="d-flex flex-column">
                    {{ warehouse.warehouse.label | titlecase }}
                    <span class="font-small-2"> {{ warehouse.warehouse.name | titlecase }}</span>
                  </div>
                  <button class="btn btn-primary d-inline-block p-025 pr-01 mr-025 ml-025" (click)="saveWarehouse(supplier, warehouse)">
                    <app-svg-icon name="save"></app-svg-icon>
                  </button>
                  <button class="btn btn-danger d-inline-block p-025 pr-01 mr-025"  (click)="deleteWarehouse(j)">
                    <app-svg-icon name="delete"></app-svg-icon>
                  </button>
                </h5>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <h5 class="font-small-3">Prezzo</h5>
                        <input type="number" class="form-control font-small-3 mb-1" placeholder=""
                               value="{{warehouse.price || supplier.price}}" disabled>
                      </div>
                      <div class="col-6">
                        <h5 class="font-small-3">Disp.</h5>
                        <input type="number" class="form-control font-small-3 mb-1" placeholder=""
                               [(ngModel)]="warehouse.stock">
                      </div>
                    </div>
                  </div>

<!--                  <div class="col-3 d-flex align-items-end">-->
<!--                    <button class="btn btn-primary d-inline-block p-05 pr-025 mb-1" (click)="saveWarehouse(supplier, warehouse)">-->
<!--                      <app-svg-icon name="save"></app-svg-icon>-->
<!--                    </button>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div  class="col-lg-4 col-md-12" *ngIf="pagePermissions.comments">
    <div class="card glassy">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title ">Commenti</h5>
        <div class="comment-container">
          <ng-container *ngIf="comments.length > 0">
            <ul class="list-unstyled">
              <li *ngFor="let comment of comments" class="mb-1">
                <div class="">
                  <div class="comment-text">
                    <p class="card-text mb-0">
                      {{ comment.text }}
                    </p>
                  </div>
                  <div class="comment-user mt-05">
                    <span class="avatar mr-1">
                      <img
                        [src]="comment.user.avatar || 'assets/images/avatars/default-avatar.png'"
                        (error)="comment.user.avatar = 'assets/images/avatars/default-avatar.png'"
                        alt="avatar"
                        class="rounded-circle"
                        width="18"
                        height="18"
                      >
                    </span>
                    <span class="font-small-2 font-weight-bold mr-1">{{ comment.user.name }}</span>
                    <span class="font-small-2">{{ comment.createdAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="comments?.length == 0">
            <p>Nessun commento trovato</p>
          </ng-container>
        </div>
      </div>
      <div class="card-footer">
        <div class="form-group">
          <div class="input-group">
            <textarea class="form-control" id="comment" rows="2"
                      [(ngModel)]="commentText" name="comment"
                      (keydown.control.enter)="addComment()"></textarea>
            <button class="btn btn-outline-primary px-1" (click)="addComment()">
              <app-svg-icon class="d-inline-block align-middle" name="send"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-7 col-md-12" *ngIf="pagePermissions.orders">
    <div class="card glassy card-full-height" *ngIf="!loading">
      <div class="card-header pb-0">
        <h4 class="card-title">Ordini</h4>
      </div>
      <dynamic-table
        [dataRetriever]="getOrders.bind(this)"
        [columns]="ordersColumns"
        [page]="1"
        [pageSize]="10"
        [sort]="{
          prop: 'createdAt',
          dir: 'desc'
        }"
        [strictSearch]="true"
        [messages]="ordersMessages"
      >
      </dynamic-table>
    </div>
  </div>


  <div class="col-lg-5 col-md-12" *ngIf="pagePermissions.research">
    <div class="card glassy card-full-height" *ngIf="!loading">
      <div class="card-header pb-0">
        <h4 class="card-title">Ricerche</h4>
      </div>
    </div>
  </div>
</div>

<ng-template #addNewMetaModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="newOrder-modal">Aggiungi informazione tecnica</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Nome</h5>
        <input type="text" class="form-control font-small-3 mb-1" [(ngModel)]="newMeta.name">
      </div>
      <div class="col-12">
        <h5 class="font-small-3 text-primary">Valore</h5>
        <textarea class="form-control font-small-3 mb-1" [(ngModel)]="newMeta.value"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addMetaToProduct()">Aggiungi</button>
  </div>
</ng-template>


<ng-template #addNewSupplierModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="newSupplier-modal">Aggiungi fornitore</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <ng-select id="supplier" [items]="suppliers" bindLabel="name"
                   placeholder="Seleziona un fornitore"
                   [(ngModel)]="selectedSupplier.supplier"
                   (input)="onSupplierInput($event)"
                   appendTo="body"
                   class="mb-1">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addSupplierToProduct()">Aggiungi</button>
  </div>
</ng-template>


<ng-template #addNewWarehouseModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white " id="newWarehouse-modal">Aggiungi magazzino</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <ng-select id="warehouseList" [items]="warehouses" bindLabel="label"
                   placeholder="Seleziona un magazzino"
                   [(ngModel)]="selectedWarehouse"
                   (input)="onWarehouseInput($event)"
                   appendTo="body"
                   class="mb-1">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addWarehouseToSupplier()">Aggiungi</button>
  </div>
</ng-template>
