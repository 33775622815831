import {
  Component,
  Inject,
  PLATFORM_ID,
  NgZone,
  OnDestroy,
  AfterViewInit,
  Input,
  OnInit,
  HostListener, ElementRef, ViewChild
} from '@angular/core';
import { isPlatformBrowser } from "@angular/common";

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { ChartsService } from "@core/services/charts.service";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { CatalogService } from "../../../../@core/services/catalog.service";
import { CustomersService } from "../../../../@core/services/customers.service";
import { AuthenticationService } from "../../../../@core/services/authentication.service";

@Component({
  selector: 'widget-last-orders',
  templateUrl: './last-orders.component.html',
  styleUrls: ['./last-orders.component.scss']
})
export class LastOrdersComponent implements OnDestroy, AfterViewInit, OnInit {
  private _refresh: any;

  private root!: am5.Root;

  public maxDate: NgbDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  public toDate: NgbDate | null = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());

  public fromDateString: string = this.getStringNgDate(this.fromDate);
  public toDateString: string = this.getStringNgDate(this.toDate);
  public isLoading: boolean = true;

  public selectTime: boolean = false;

  public stats: {
    total_products?: number,
    total_delivery_prices?: number,
    total_delivery_costs?: number,
    total_orders?: number,
    total_businesses?: number,
    total_warehouses?: number,
    total_networks?: number
  } = {}

  public warehouses: any[] = [];

  public networks: any[] = [];

  public statuses: any[] = [
    {
      label: "Creato",
      value: "CREATED"
    },
    {
      label: "Approvato",
      value: "APPROVED"
    },
    {
      label: "Confermato",
      value: "CONFIRMED"
    },
    {
      label: "Completato",
      value: "COMPLETED"
    },
    {
      label: "Spedito",
      value: "SHIPPED"
    },
    {
      label: "Consegnato",
      value: "DELIVERED"
    },
    {
      label: "Fallito",
      value: "FAILED"
    },
    {
      label: "Rifiutato",
      value: "REJECTED"
    },
    {
      label: "Annullato",
      value: "CANCELED"
    },
  ];

  public selectedWarehouses: any = [];

  public selectedNetworks: any = [];

  public selectedStatuses: any = [
    {
      label: "Approvato",
      value: "APPROVED"
    },
    {
      label: "Confermato",
      value: "CONFIRMED"
    },
    {
      label: "Spedito",
      value: "SHIPPED"
    },
    {
      label: "Consegnato",
      value: "DELIVERED"
    },
    {
      label: "Completato",
      value: "COMPLETED"
    },
  ];

  public groupByOptions: string[] = [
    'Ora',
    'Giorno',
    'Mese',
    'Anno'
  ];
  public selectedGroupBy: string = this.groupByOptions[0];


  public pagePermissions: {
    group_by: boolean,
    states: boolean,
    warehouses: boolean,
    networks: boolean,
  } = {
    group_by: false,
    states: false,
    warehouses: false,
    networks: false,
  }

  // @ts-ignore
  @ViewChild('timeSelector') timeSelector: ElementRef;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    private _chartsService: ChartsService,
    private _catalogService: CatalogService,
    private _customersService: CustomersService,
    private _authService: AuthenticationService,
  ) {
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngAfterViewInit() {
    // this.startRefreshing();
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  ngOnInit() {
    this.pagePermissions = {
      group_by: this._authService.checkPermission('admin.page.dashboard.last_orders.group_by'),
      states: this._authService.checkPermission('admin.page.dashboard.last_orders.states'),
      warehouses: this._authService.checkPermission('admin.page.dashboard.last_orders.warehouses'),
      networks: this._authService.checkPermission('admin.page.dashboard.last_orders.networks'),
    }
    this._catalogService.getWarehouseList({
      pageSize: 1000,
    }).then((response) => {
      if (response?.rows && Array.isArray(response.rows)) {
        this.warehouses = response.rows.map((warehouse: any) => {
          return {
            id: warehouse.id,
            name: warehouse.name,
            value: warehouse.name,
            label: warehouse.name
          }
        });
        this.selectedWarehouses = this.warehouses.filter((warehouse: any) => !warehouse.name.toUpperCase().includes('AUTO180'));
        this.startRefreshing();
      }
    });

    this._customersService.getNetworkList({
      pageSize: 1000,
    }).then((response) => {
      if (response?.rows && Array.isArray(response.rows)) {
        this.networks = response.rows.map((network: any) => {
          return {
            id: network.id,
            name: network.name,
            value: network.name,
            label: network.name
          }
        });
        this.startRefreshing();
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    if (this.timeSelector.nativeElement.contains(event.target)) {
      console.log('clicked inside')
    } else {
      console.log('clicked outside')
      this.selectTime = false;
    }
  }

  startRefreshing() {
    this.getChartData();
    this._refresh = setTimeout(() => {
      this.getChartData();
    }, 3 * 60 * 1000);
  }

  openTimeSelector(event: any) {
    event.stopPropagation();
    this.selectTime = !this.selectTime;
  }

  getMissingYears(data: any[], uniqueYears: string[]): string[] {
    const minYear = Math.min(...uniqueYears.map((year: string) => parseInt(year)));
    const maxYear = Math.max(...uniqueYears.map((year: string) => parseInt(year)));
    const missingYears = [];
    for (let i = minYear; i <= maxYear; i++) {
      if (!uniqueYears.includes(i.toString())) {
        missingYears.push(`${i}`);
      }
    }
    return missingYears;
  }

  getMissingMonths(year: string, data: any[], uniqueMonths: string[], fromStart: boolean = false, toEnd: boolean = false) {
    const minMonth = fromStart ? 1 : Math.min(...uniqueMonths.filter(month => month.startsWith(`${year}-`)).map((month: string) => parseInt(month.split('-')[1])));
    const maxMonth = toEnd ? 12 : Math.max(...uniqueMonths.filter(month => month.startsWith(`${year}-`)).map((month: string) => parseInt(month.split('-')[1])));
    const missingMonths = [];
    for (let i = minMonth; i <= maxMonth; i++) {
      if (!uniqueMonths.includes(`${year}-${i}`)) {
        missingMonths.push(`${year}-${i}`);
      }
    }
    return missingMonths;
  }

  getMissingDays(year: string, month: string, data: any[], uniqueDays: string[], fromStart: boolean = false, toEnd: boolean = false) {
    const maxMonthDays = new Date(parseInt(year), parseInt(month), 0).getDate();
    const minDay = fromStart ? 1 : Math.min(...uniqueDays.filter(day => day.startsWith(`${year}-${month}-`)).map((day: string) => parseInt(day.split('-')[2])));
    const maxDay = toEnd ? maxMonthDays : Math.max(...uniqueDays.filter(day => day.startsWith(`${year}-${month}-`)).map((day: string) => parseInt(day.split('-')[2])));
    const missingDays = [];
    for (let i = minDay; i <= maxDay; i++) {
      if (!uniqueDays.includes(`${year}-${month}-${i}`)) {
        missingDays.push(`${year}-${month}-${i}`);
      }
    }
    return missingDays;
  }

  getMissingHours(year: string, month: string, day: string, data: any[], uniqueHours: string[], fromStart: boolean = false, toEnd: boolean = false) {
    const minHour = fromStart ? 0 : Math.min(...uniqueHours.filter(hour => hour.startsWith(`${year}-${month}-${day}-`)).map((hour: string) => parseInt(hour.split('-')[3])));
    const maxHour = toEnd ? 23 : Math.max(...uniqueHours.filter(hour => hour.startsWith(`${year}-${month}-${day}-`)).map((hour: string) => parseInt(hour.split('-')[3])));
    const missingHours = [];
    for (let i = minHour; i <= maxHour; i++) {
      if (!uniqueHours.includes(`${year}-${month}-${day}-${i}`)) {
        missingHours.push(`${year}-${month}-${day}-${i}`);
      }
    }
    return missingHours;
  }


  getChartData() {
    this.fromDateString = this.getStringNgDate(this.fromDate);
    this.toDateString = this.getStringNgDate(this.toDate);
    clearTimeout(this._refresh);
    this._refresh = setTimeout(() => {
      this.getChartData();
    }, 3 * 60 * 1000);

    const filters: {
      status?: string[],
      warehouse?: { $oid: string }[],
      network?: { $oid: string }[],
      createdAt?: {
        $between?: {
          from: {
            $date: Date
          },
          to?: {
            $date: Date
          }
        }
      }
    } = {
      status: this.selectedStatuses.map((status: any) => status.value)
    };

    if (this.selectedWarehouses.length > 0) {
      filters['warehouse'] = this.selectedWarehouses.map((warehouse: any) => {
        return { $oid: warehouse.id }
      });
    }

    if (this.selectedNetworks.length > 0) {
      filters['network'] = this.selectedNetworks.map((network: any) => {
        return { $oid: network.id }
      });
    }


    if (!this.fromDate || !this.toDate) {
      return;
    }

    const from = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0, 0);
    const to = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, 23, 59, 59, 999);

    filters.createdAt = {
      $between: {
        from: {
          $date: from
        },
        to: {
          $date: to
        }
      }
    };

    this.isLoading = true;

    this._chartsService.getLastOrders(filters, this.selectedGroupBy).then((response) => {
      const { stats, orders } = response;
      if (stats) {
        this.stats = stats;
      }
      if (orders && orders.length) {
        const uniqueYears: Set<string> = new Set();
        const uniqueMonths: Set<string> = new Set();
        const uniqueDays: Set<string> = new Set();
        const uniqueHours: Set<string> = new Set();

        orders.forEach((order: any) => {
          if (order._id.createdYear) {
            uniqueYears.add(`${order._id.createdYear}`);
          }
          if (order._id.createdMonth) {
            uniqueMonths.add(`${order._id.createdYear}-${order._id.createdMonth}`);
          }
          if (order._id.createdDay) {
            uniqueDays.add(`${order._id.createdYear}-${order._id.createdMonth}-${order._id.createdDay}`);
          }
          if (order._id.createdHour) {
            uniqueHours.add(`${order._id.createdYear}-${order._id.createdMonth}-${order._id.createdDay}-${order._id.createdHour}`);
          }
        });

        const deepness = {
          year: uniqueYears.size > 1,
          month: uniqueMonths.size > 1,
          day: uniqueDays.size > 1,
          hour: uniqueHours.size > 1,
        };

        const getTimeKey = ({ year, month, day, hour }: any) => {
          let key = '';
          if (deepness.year) {
            key += `${year}`.padStart(4, '0');
          }
          if (deepness.month) {
            if (key) {
              key += '/';
            }
            key += `${month}`.padStart(2, '0');
          }
          if (deepness.day) {
            if (key) {
              key += '/';
            }
            key += `${day}`.padStart(2, '0');
          }
          if (deepness.hour) {
            if (key) {
              key += ' ';
            }
            key += `${hour}`.padStart(2, '0') + ':00';
          }

          return key;
        }


        const chartData = orders.map((order: any) => {
          const orderChart = {
            time: getTimeKey({
              year: order._id.createdYear,
              month: order._id.createdMonth,
              day: order._id.createdDay,
              hour: order._id.createdHour
            }),
            id: order._id,
            // time: order._id + 1,
            cost: 0,
            sales: order.total_products + order.total_delivery_prices,
          }
          return orderChart
        })

        const missingYears: string[] = this.getMissingYears(chartData, Array.from(uniqueYears));
        const minYear = Math.min(...Array.from(uniqueYears).map((year: string) => parseInt(year))).toString();
        const maxYear = Math.max(...Array.from(uniqueYears).map((year: string) => parseInt(year))).toString();
        const missingMonths =
          [...missingYears, ...uniqueYears]
            .map((year: string) => {
              return this.getMissingMonths(year, chartData, Array.from(uniqueMonths), year !== minYear, year !== maxYear)
            })
            .flat();

        const minMonth = Math.min(...Array.from([...missingMonths, ...uniqueMonths]).filter((month: string) => month.split('-')[0] === minYear).map((month: string) => parseInt(month.split('-')[1]))).toString();
        const maxMonth = Math.max(...Array.from([...missingMonths, ...uniqueMonths]).filter((month: string) => month.split('-')[0] === maxYear).map((month: string) => parseInt(month.split('-')[1]))).toString();
        const missingDays =
          [...missingMonths, ...uniqueMonths]
            .map((month: string) => {
              return this.getMissingDays(month.split('-')[0], month.split('-')[1], chartData, Array.from(uniqueDays), month !== `${minYear}-${minMonth}`, month !== `${maxYear}-${maxMonth}`)
            })
            .flat();

        const minDay = Math.min(...Array.from([...missingDays, ...uniqueDays]).filter((day: string) => day.split('-')[0] === minYear && day.split('-')[1] === minMonth).map((day: string) => parseInt(day.split('-')[2]))).toString();
        const maxDay = Math.max(...Array.from([...missingDays, ...uniqueDays]).filter((day: string) => day.split('-')[0] === maxYear && day.split('-')[1] === maxMonth).map((day: string) => parseInt(day.split('-')[2]))).toString();
        const missingHours =
          [...missingDays, ...uniqueDays]
            .map((day: string) => {
              return this.getMissingHours(day.split('-')[0], day.split('-')[1], day.split('-')[2], chartData, Array.from(uniqueHours), day !== `${minYear}-${minMonth}-${minDay}`, day !== `${maxYear}-${maxMonth}-${maxDay}`)
            })
            .flat();

        if (deepness.hour) {
          chartData.push(...missingHours.map((hour: string) => {
            return {
              time: getTimeKey({
                year: parseInt(hour.split('-')[0]),
                month: parseInt(hour.split('-')[1]),
                day: parseInt(hour.split('-')[2]),
                hour: parseInt(hour.split('-')[3])
              }),
              id: {
                createdYear: parseInt(hour.split('-')[0]),
                createdMonth: parseInt(hour.split('-')[1]),
                createdDay: parseInt(hour.split('-')[2]),
                createdHour: parseInt(hour.split('-')[3])
              },
              cost: 0,
              sales: 0,
            }
          }));
        } else if (deepness.day) {
          chartData.push(...missingDays.map((day: string) => {
            return {
              time: getTimeKey({
                year: parseInt(day.split('-')[0]),
                month: parseInt(day.split('-')[1]),
                day: parseInt(day.split('-')[2]),
                hour: 0
              }),
              id: {
                createdYear: parseInt(day.split('-')[0]),
                createdMonth: parseInt(day.split('-')[1]),
                createdDay: parseInt(day.split('-')[2]),
                createdHour: 0
              },
              cost: 0,
              sales: 0,
            }
          }));
        } else if (deepness.month) {
          chartData.push(...missingMonths.map((month: string) => {
            return {
              time: getTimeKey({
                year: parseInt(month.split('-')[0]),
                month: parseInt(month.split('-')[1]),
                day: 1,
                hour: 0
              }),
              id: {
                createdYear: parseInt(month.split('-')[0]),
                createdMonth: parseInt(month.split('-')[1]),
                createdDay: 1,
                createdHour: 0
              },
              cost: 0,
              sales: 0,
            }
          }));
        } else if (deepness.year) {
          chartData.push(...missingYears.map((year: string) => {
            return {
              time: getTimeKey({
                year: parseInt(year),
                month: 1,
                day: 1,
                hour: 0
              }),
              id: {
                createdYear: parseInt(year),
                createdMonth: 1,
                createdDay: 1,
                createdHour: 0
              },
              cost: 0,
              sales: 0,
            }
          }));
        }


        chartData.sort((a: any, b: any) => {
          if (a.id.createdYear != b.id.createdYear) {
            return a.id.createdYear - b.id.createdYear;
          }
          if (a.id.createdMonth != b.id.createdMonth) {
            return a.id.createdMonth - b.id.createdMonth;
          }
          if (a.id.createdDay != b.id.createdDay) {
            return a.id.createdDay - b.id.createdDay;
          }
          if (a.id.createdHour != b.id.createdHour) {
            return a.id.createdHour - b.id.createdHour;
          }
          return 0;
        })
        console.log(chartData)
        this.refreshChart(
          "last-orders",
          {
            data: chartData,
            field: "time",
            chartType: "line",
            series: [
              {
                "name": "Acquisto",
                "field": "cost",
                "color": "#490E67"
              },
              {
                "name": "Vendita",
                "field": "sales",
                "color": "#ff8300"
              }
            ]
          }
        );
        console.log('done')
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  getStringNgDate(date: NgbDate | null) {
    if (!date) {
      return '--/--/----';
    }
    return [
      date.day,
      date.month,
      date.year
    ].map(d => `${d}`.padStart(2, '0')).join('/');
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  changeWarehouses(event: any) {
    this.getChartData();
  }

  changeNetworks(event: any) {
    this.getChartData();
  }

  changeStatuses(event: any) {
    this.getChartData();
  }

  refreshChart(
    target: string,
    chartData: {
      data: any[],
      field: string,
      chartType: string,
      series: any[]
    }) {
    if (!chartData || !chartData.data || !chartData.data.length) {
      return;
    }
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      this.root = am5.Root.new(target);
      const root = this.root;

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {})
      );

      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "none"
      }));

      cursor.lineY.set("visible", false);
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: chartData.field,
        startLocation: 0.5,
        endLocation: 0.5,
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      }));

      xAxis.data.setAll(chartData.data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      }));

      function createSeries(name: any, field: any, color: string) {
        let series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: chartData.field,
          stroke: am5.color(color),
          fill: am5.color(color),
          stacked: true,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
          })
        }));

        series.strokes.template.setAll({
          strokeWidth: 2,
          strokeOpacity: 2,
          shadowBlur: 1,
          shadowOffsetX: 2,
          shadowOffsetY: 2,
          shadowColor: am5.color(0x000000),
          shadowOpacity: 0.1
        })

        series.fills.template.setAll({
          fillOpacity: .7,
          visible: true,
        });

        series.data.setAll(chartData.data);
        series.appear(1000);
      }

      chartData.series.forEach((serie) => {
        createSeries(serie.name, serie.field, serie.color);
      })

      chart.appear(300, 200);
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
      this.getChartData();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

}
