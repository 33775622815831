import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@core/components/core.module";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {TablesListComponent} from "./tables-list/tables-list.component";
import {TablesViewComponent} from "./tables-view/tables-view.component";
const routes: Routes = [
  {
    path: 'tables',
    component: TablesListComponent,
    data: { animation: 'TablesListComponent' }
  },
  {
    path: 'tables/:name',
    component: TablesViewComponent,
    data: { animation: 'TablesViewComponent' }
  }

];

@NgModule({
  declarations: [
    TablesListComponent,
    TablesViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    FormsModule,
    NgSelectModule
  ],
  providers: []
})
export class TablesModule {
}
