import { Component, HostListener, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApplicationService } from "@core/services/application.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DynamicData } from "@core/types/dynamic-table/dynamic-data";
import { CatalogService } from "@core/services/catalog.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { HttpClient } from "@angular/common/http";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {AuthenticationService} from "../../../../@core/services/authentication.service";

@Component({
  selector: 'app-shipping-rules-list',
  templateUrl: './shipping-rules-list.component.html',
  styleUrls: ['./shipping-rules-list.component.scss']
})

export class ShippingRulesListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  public tableConfig: TableConfig = {} as TableConfig;

  public warehouseList: any[] = [];

  public actionWarehouse: any;

  public dragover = false;
  public currentFile:any = null;

  @HostListener('document:dragover', ['$event']) onDragOver(event: any) {
    const elements = [
      event.target,
      event.target.parentElement,
      event.target.parentElement.parentElement,
      event.target.parentElement.parentElement.parentElement,
    ]
    if (elements.some(element => element?.classList?.contains('dropzone'))) {
      event.preventDefault();
      event.stopPropagation();
      this.dragover = true;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('document:dragleave', ['$event']) onDragLeave(event: any) {
    const elements = [
      event.target,
      event.target.parentElement,
      event.target.parentElement.parentElement,
      event.target.parentElement.parentElement.parentElement,
    ]
    if (elements.some(element => element?.classList?.contains('dropzone'))) {
      event.preventDefault();
      event.stopPropagation();
      if (event.target.classList.contains('dropzone')) {
        this.dragover = false;
      }
    }
  }

  @HostListener('document:drop', ['$event']) onDrop(event: any) {
    const elements = [
      event.target,
      event.target.parentElement,
      event.target.parentElement.parentElement,
      event.target.parentElement.parentElement.parentElement,
    ]
    if (elements.some(element => element.classList.contains('dropzone'))) {
      event.preventDefault();
      event.stopPropagation();
      this.dragover = false;

      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.currentFile = files[0];
      }
    }
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _catalogService: CatalogService,
    private _router: Router,
    private modalService: NgbModal,
    private _activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('catalog-shipping-rules-list');
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  updateWarehouseFilter($event: any, filter: any) {
    const query: {
      pageSize: number,
      search?: string,
    } = {
      pageSize: 25,
    };
    if ($event.target.value) {
      query['search'] = $event.target.value;
    }
    this._catalogService.getWarehouseList(query).then((response: DynamicData) => {
      filter.options = response.rows.map(group => {
        return {
          label: group.name,
          value: group.id
        }
      })
    })
  }

  removeFile() {
    this.currentFile = null;
  }

  fileSelected(event: any) {
    this.currentFile = event.target.files[0];
  }

  importShippingRules() {
    if (!this.currentFile) {
      Swal.fire('Attenzione', 'Seleziona un file', 'warning');
      return;
    }

    Swal.fire({
      title: `Caricando le regole per ${this.actionWarehouse.label}...`,
      html: "Questa operazione potrebbe richiedere alcuni minuti",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = new FormData();
    formData.append('warehouse', this.actionWarehouse.value);
    formData.append(this.currentFile.name, this.currentFile);
    this.httpClient.post('https://api.tulero.it/api/admin/shippingrules/import' , formData, {})
    // this.httpClient.post('http://localhost:3000/api/admin/shippingrules/import' , formData, {})
      .toPromise()
      .then((response: any) => {
        Swal.close();
        if (response.error) {
          Swal.fire('Errore', response.error, 'error');
          return;
        }
        if (response.result === 0) {
          Swal.fire('Attenzione', 'Nessuna regola di spedizione è stata caricata', 'warning');
          return;
        }
        Swal.fire('Completato', `Sono state caricate ${response.result} regole`, 'success');
      })
      .catch((e) => {
        Swal.close();
        Swal.fire('Errore', 'Si è verificato un errore', 'error');
        // handle me
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('shipping-rules')
      .then((table) => {
        this.tableConfig = table;
        this.generateFilterOptions();
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getShippingRules(query?: any) {
    query = query || {};
    const queryParams: Params = { ...query };
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
      }
    );
    query['populateFields'] = ['warehouse'];
    const shippingRules: DynamicData = await this._catalogService.getShippingRuleList(query)
    shippingRules.rows.forEach(shippingRule => {
      const cutoffs = new Set();
      shippingRule.cutoffs.forEach((cutoff: any) => {
        cutoffs.add(`${cutoff.hh}:${cutoff.mm}`);
      })
      shippingRule.cutoffs = [...cutoffs].join(' | ');
      shippingRule.actions = shippingRule.actions || [];
      shippingRule.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/shipping-rules/' + shippingRule.code
      });
    })
    return shippingRules
  }

  generateFilterOptions() {
    this.tableConfig.filterGroups.forEach(filterGroup => {
      filterGroup.filters.forEach((filter: any) => {
        switch (filter.retriever) {
          case 'warehouses.list':
            this._catalogService.getWarehouseList({
              pageSize: 100,
            }).then((response: DynamicData) => {
              this.warehouseList = filter.options = response.rows.map(warehouse => {
                return {
                  label: warehouse.name,
                  value: warehouse.id
                }
              })
            })
            break;
        }
      })
    })
  }

  exportShippingRules() {
    if (!this.actionWarehouse.value) {
      Swal.fire('Attenzione', 'Seleziona un magazzino', 'warning');
      return;
    }
    const query: any = {};
    query.filters = {
      warehouse: {
        $oid: this.actionWarehouse.value
      }
    };
    query['populateFields'] = ['warehouse'];
    this._catalogService.exportShippingRuleList(query)
      .then((response: any) => {
        const fileName = `${this.actionWarehouse.label.toLowerCase()}_shipping rules.csv`;
        saveAs(response, fileName);
      })
  }

  openImportModal(modalPdf: TemplateRef<any>) {
    this.modalService.open(modalPdf, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  openExportModal(modalPdf: TemplateRef<any>) {
    this.modalService.open(modalPdf, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }
}
