import { RouterModule, Routes } from "@angular/router";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import { NgModule } from "@angular/core";
import { GroupsListComponent } from "./groups-list/groups-list.component";
import { GroupsViewComponent } from "./groups-view/groups-view.component";
import { CoreModule } from "@core/components/core.module";
import { PricingChartComponent } from "@core/components/pricing-chart/pricing-chart.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import Swal from 'sweetalert2'

const routes: Routes = [
  {
    path: 'groups',
    component: GroupsListComponent,
    data: { animation: 'GroupsListComponent' }
  },
  {
    path: 'groups/:code',
    component: GroupsViewComponent,
    data: { animation: 'GroupsViewComponent' }
  },
];

@NgModule({
  declarations: [
    GroupsListComponent,
    GroupsViewComponent,
    PricingChartComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    FormsModule,
    NgOptimizedImage,
    ReactiveFormsModule,
  ],
  providers: []
})
export class GroupsModule {
}
