import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Observable, Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CustomersService} from "@core/services/customers.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {Menu, MenuGroup} from "../../../../@core/types/app/menu";
import Swal2 from "sweetalert2";
import {CatalogService} from "../../../../@core/services/catalog.service";


@Component({
  selector: 'app-permissions-view',
  templateUrl: './permissions-view.component.html',
  styleUrls: ['./permissions-view.component.scss']
})

export class PermissionsViewComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public currentId: string = '';
  public loaded: Promise<boolean> = Promise.resolve(false);

  public identity: any = {};


  public menuPermissionsArray: any[] = [];
  public menuPermissions: any[] = [];
  public tablePermissionsArray: any[] = [];
  public tablePermissions: any[] = [];
  public pagePermissionsArray: any[] = [];
  public pagePermissions: any[] = [];


  public permissionsList: any[] = [];

  public AllPermissions: any[] = [];
  public AllPagePermissions: any[] = [];

  public AllAdditionalPermissions: any[] = [];
  public additionalPermissions: any[] = [];
  public data: DATAS[] = [
    {
      category: "networks",
      ids: []
    },
    {
      category: "warehouses",
      ids: []
    }
  ]
  public dataPermissions: any[] = [
    {
      label: "Ordini",
      key: "admin.orders",
      children: [
        {
          label: "Network",
          key: "admin.orders.network",
          children: [
            {
              label: "tutti",
              key: "admin.orders.network.*",
            },
            {
              "label": "CDG One (CDGONE)",
              "key": "admin.orders.network.6559647be7fe6fe874bb6bb2"
            },
            {
              "label": "Petronas (PETRONAS)",
              "key": "admin.orders.network.65596470e7fe6fe874bb6bb1"
            },
            {
              "label": "Ponzo (PONZO)",
              "key": "admin.orders.network.653289a197bdfbb6ec2b610d"
            },
            {
              "label": "EK Parts (EKPARTS)",
              "key": "admin.orders.network.650b0ea572f3409632912122"
            },
            {
              "label": "Auto 180 (AUTO180)",
              "key": "admin.orders.network.6490225c00ebf661cc9d8094"
            },
            {
              "label": "Non Gomme&Service (EXTERNAL-GS)",
              "key": "admin.orders.network.643e4df2102fb702aa79d031"
            },
            {
              "label": "Super Admin (SUPERADMIN)",
              "key": "admin.orders.network.6414774561c5f1dbf8871e68"
            },
            {
              "label": "SOS Car Service (SOSCARSERVICE)",
              "key": "admin.orders.network.6414774561c5f1dbf8871e67"
            },
            {
              "label": "Kwikfit (KWIKFIT)",
              "key": "admin.orders.network.6414774561c5f1dbf8871e66"
            },
            {
              "label": "SuperService (SUPERSERVICE)",
              "key": "admin.orders.network.640f14d1be00258d9f666f37"
            },
            {
              "label": "Vamos (VAMOS)",
              "key": "admin.orders.network.640f1497be00258d9f666f36"
            },
            {
              "label": "FirstStop (FIRSTSTOP)",
              "key": "admin.orders.network.640768fcc8698bba3cd62457"
            },
            {
              "label": "Gomme&Service (GOMME-SERVICE)",
              "key": "admin.orders.network.63e9bad29cd3b6142d83572f"
            },
            {
              "label": "Euromaster (EUROMASTER)",
              "key": "admin.orders.network.63e9bac19cd3b6142d83572e"
            },
            {
              "label": "Tulero (TULERO)",
              "key": "admin.orders.network.63e9bab69cd3b6142d83572d"
            }
          ]
        },
        {
          label: "Magazzini",
          key: "admin.orders.warehouse",
          children: [
            {
              label: "tutti",
              key: "admin.orders.warehouse.*",
            },
            {
              "label": "poliplast (Delos)",
              "key": "admin.orders.warehouse.65e9b01473bde42eee82faed"
            },
            {
              "label": "topdon (A32)",
              "key": "admin.orders.warehouse.65e5cd384d7e7abb8cedcbcc"
            },
            {
              "label": "nuovarl (Limnos)",
              "key": "admin.orders.warehouse.65ddc521d3754f00f69569de"
            },
            {
              "label": "coran (chios)",
              "key": "admin.orders.warehouse.65cc9a12b97ffc5267690af5"
            },
            {
              "label": "TULERO PLATE (tulero-plate)",
              "key": "admin.orders.warehouse.65c1cf53800e8756f36afd88"
            },
            {
              "label": "torinoautoricambi (tilos)",
              "key": "admin.orders.warehouse.65a8f66687ec617f89360151"
            },
            {
              "label": "max (milos)",
              "key": "admin.orders.warehouse.65a7bc58de41032b9551f787"
            },
            {
              "label": "outlet (outlet)",
              "key": "admin.orders.warehouse.65a6652a8792730338a4cd8b"
            },
            {
              "label": "TULERO WALLET (tulero-wallet)",
              "key": "admin.orders.warehouse.65a2a5ab97e080bfac98f174"
            },
            {
              "label": "TULERO VIN (tulero-vin)",
              "key": "admin.orders.warehouse.65a2a15597e080bfac98f173"
            },
            {
              "label": "as-pl (anafi)",
              "key": "admin.orders.warehouse.65a1536be29d179e31472fc5"
            },
            {
              "label": "iap (symi)",
              "key": "admin.orders.warehouse.658d8511a9f866303f62f25e"
            },
            {
              "label": "cati (scirocco)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad76"
            },
            {
              "label": "cati-stellare (marino)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad75"
            },
            {
              "label": "idir (maestrale)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad74"
            },
            {
              "label": "cida (libeccio)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad73"
            },
            {
              "label": "fuchs (tulero-oil)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad72"
            },
            {
              "label": "mobil (mobil)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad71"
            },
            {
              "label": "dipa (alisei)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad70"
            },
            {
              "label": "midac (solano)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad6f"
            },
            {
              "label": "eneos (cauro)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad6e"
            },
            {
              "label": "serbatoigpl (eolo)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad6d"
            },
            {
              "label": "idir-gomme (noto)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad6c"
            },
            {
              "label": "bori (levante)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad6b"
            },
            {
              "label": "sidat (bora)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad6a"
            },
            {
              "label": "tulero (tulero)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad69"
            },
            {
              "label": "ovam (grecale)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad68"
            },
            {
              "label": "franceschini (ponente)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad67"
            },
            {
              "label": "felappi (A17)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad66"
            },
            {
              "label": "felappi-stellare (A18)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad65"
            },
            {
              "label": "sidat-stellare (A19)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad64"
            },
            {
              "label": "prasco (A20)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad63"
            },
            {
              "label": "prasco2 (A21)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad62"
            },
            {
              "label": "cida-prasco (A22)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad61"
            },
            {
              "label": "idir-stellare (A23)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad60"
            },
            {
              "label": "euroglass (A24)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad5f"
            },
            {
              "label": "action (A25)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad5e"
            },
            {
              "label": "abs (A26)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad5d"
            },
            {
              "label": "viskoil (A27)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad5c"
            },
            {
              "label": "idir-milano (A28)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad5b"
            },
            {
              "label": "idir-auto180 (idir-caserta)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad5a"
            },
            {
              "label": "commercialericambi (A29)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad59"
            },
            {
              "label": "cida-extra (A30)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad58"
            },
            {
              "label": "gammaplast (A31)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad57"
            },
            {
              "label": "idir-milano-auto180 (idir-milano)",
              "key": "admin.orders.warehouse.65798bbd3128245708efad56"
            }

          ]
        }
      ]
    },
    {
      label: "Aziende",
      key: "admin.businesses",
      children: [
        {
          label: "Network",
          key: "admin.businesses.network",
          children: [
            {
              label: "tutti",
              key: "admin.businesses.network.*",
            },
            {
              "label": "CDG One (CDGONE)",
              "key": "admin.businesses.network.6559647be7fe6fe874bb6bb2"
            },
            {
              "label": "Petronas (PETRONAS)",
              "key": "admin.businesses.network.65596470e7fe6fe874bb6bb1"
            },
            {
              "label": "Ponzo (PONZO)",
              "key": "admin.businesses.network.653289a197bdfbb6ec2b610d"
            },
            {
              "label": "EK Parts (EKPARTS)",
              "key": "admin.businesses.network.650b0ea572f3409632912122"
            },
            {
              "label": "Auto 180 (AUTO180)",
              "key": "admin.businesses.network.6490225c00ebf661cc9d8094"
            },
            {
              "label": "Non Gomme&Service (EXTERNAL-GS)",
              "key": "admin.businesses.network.643e4df2102fb702aa79d031"
            },
            {
              "label": "Super Admin (SUPERADMIN)",
              "key": "admin.businesses.network.6414774561c5f1dbf8871e68"
            },
            {
              "label": "SOS Car Service (SOSCARSERVICE)",
              "key": "admin.businesses.network.6414774561c5f1dbf8871e67"
            },
            {
              "label": "Kwikfit (KWIKFIT)",
              "key": "admin.businesses.network.6414774561c5f1dbf8871e66"
            },
            {
              "label": "SuperService (SUPERSERVICE)",
              "key": "admin.businesses.network.640f14d1be00258d9f666f37"
            },
            {
              "label": "Vamos (VAMOS)",
              "key": "admin.businesses.network.640f1497be00258d9f666f36"
            },
            {
              "label": "FirstStop (FIRSTSTOP)",
              "key": "admin.businesses.network.640768fcc8698bba3cd62457"
            },
            {
              "label": "Gomme&Service (GOMME-SERVICE)",
              "key": "admin.businesses.network.63e9bad29cd3b6142d83572f"
            },
            {
              "label": "Euromaster (EUROMASTER)",
              "key": "admin.businesses.network.63e9bac19cd3b6142d83572e"
            },
            {
              "label": "Tulero (TULERO)",
              "key": "admin.businesses.network.63e9bab69cd3b6142d83572d"
            }
          ]
        }
      ]
    },
    {
      label: "Grafico ordini",
      key: "admin.charts.orders",
      children: [
        {
          label: "Network",
          key: "admin.charts.orders.network",
          children: [
            {
              label: "tutti",
              key: "admin.charts.orders.network.*",
            },
            {
              "label": "CDG One (CDGONE)",
              "key": "admin.charts.orders.network.6559647be7fe6fe874bb6bb2"
            },
            {
              "label": "Petronas (PETRONAS)",
              "key": "admin.charts.orders.network.65596470e7fe6fe874bb6bb1"
            },
            {
              "label": "Ponzo (PONZO)",
              "key": "admin.charts.orders.network.653289a197bdfbb6ec2b610d"
            },
            {
              "label": "EK Parts (EKPARTS)",
              "key": "admin.charts.orders.network.650b0ea572f3409632912122"
            },
            {
              "label": "Auto 180 (AUTO180)",
              "key": "admin.charts.orders.network.6490225c00ebf661cc9d8094"
            },
            {
              "label": "Non Gomme&Service (EXTERNAL-GS)",
              "key": "admin.charts.orders.network.643e4df2102fb702aa79d031"
            },
            {
              "label": "Super Admin (SUPERADMIN)",
              "key": "admin.charts.orders.network.6414774561c5f1dbf8871e68"
            },
            {
              "label": "SOS Car Service (SOSCARSERVICE)",
              "key": "admin.charts.orders.network.6414774561c5f1dbf8871e67"
            },
            {
              "label": "Kwikfit (KWIKFIT)",
              "key": "admin.charts.orders.network.6414774561c5f1dbf8871e66"
            },
            {
              "label": "SuperService (SUPERSERVICE)",
              "key": "admin.charts.orders.network.640f14d1be00258d9f666f37"
            },
            {
              "label": "Vamos (VAMOS)",
              "key": "admin.charts.orders.network.640f1497be00258d9f666f36"
            },
            {
              "label": "FirstStop (FIRSTSTOP)",
              "key": "admin.charts.orders.network.640768fcc8698bba3cd62457"
            },
            {
              "label": "Gomme&Service (GOMME-SERVICE)",
              "key": "admin.charts.orders.network.63e9bad29cd3b6142d83572f"
            },
            {
              "label": "Euromaster (EUROMASTER)",
              "key": "admin.charts.orders.network.63e9bac19cd3b6142d83572e"
            },
            {
              "label": "Tulero (TULERO)",
              "key": "admin.charts.orders.network.63e9bab69cd3b6142d83572d"
            }
          ]
        },
        {
          label: "Magazzini",
          key: "admin.charts.orders.warehouse",
          children: [
            {
              label: "tutti",
              key: "admin.charts.orders.warehouse.*",
            },
            {
              "label": "poliplast (Delos)",
              "key": "admin.charts.orders.warehouse.65e9b01473bde42eee82faed"
            },
            {
              "label": "topdon (A32)",
              "key": "admin.charts.orders.warehouse.65e5cd384d7e7abb8cedcbcc"
            },
            {
              "label": "nuovarl (Limnos)",
              "key": "admin.charts.orders.warehouse.65ddc521d3754f00f69569de"
            },
            {
              "label": "coran (chios)",
              "key": "admin.charts.orders.warehouse.65cc9a12b97ffc5267690af5"
            },
            {
              "label": "TULERO PLATE (tulero-plate)",
              "key": "admin.charts.orders.warehouse.65c1cf53800e8756f36afd88"
            },
            {
              "label": "torinoautoricambi (tilos)",
              "key": "admin.charts.orders.warehouse.65a8f66687ec617f89360151"
            },
            {
              "label": "max (milos)",
              "key": "admin.charts.orders.warehouse.65a7bc58de41032b9551f787"
            },
            {
              "label": "outlet (outlet)",
              "key": "admin.charts.orders.warehouse.65a6652a8792730338a4cd8b"
            },
            {
              "label": "TULERO WALLET (tulero-wallet)",
              "key": "admin.charts.orders.warehouse.65a2a5ab97e080bfac98f174"
            },
            {
              "label": "TULERO VIN (tulero-vin)",
              "key": "admin.charts.orders.warehouse.65a2a15597e080bfac98f173"
            },
            {
              "label": "as-pl (anafi)",
              "key": "admin.charts.orders.warehouse.65a1536be29d179e31472fc5"
            },
            {
              "label": "iap (symi)",
              "key": "admin.charts.orders.warehouse.658d8511a9f866303f62f25e"
            },
            {
              "label": "cati (scirocco)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad76"
            },
            {
              "label": "cati-stellare (marino)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad75"
            },
            {
              "label": "idir (maestrale)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad74"
            },
            {
              "label": "cida (libeccio)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad73"
            },
            {
              "label": "fuchs (tulero-oil)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad72"
            },
            {
              "label": "mobil (mobil)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad71"
            },
            {
              "label": "dipa (alisei)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad70"
            },
            {
              "label": "midac (solano)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad6f"
            },
            {
              "label": "eneos (cauro)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad6e"
            },
            {
              "label": "serbatoigpl (eolo)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad6d"
            },
            {
              "label": "idir-gomme (noto)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad6c"
            },
            {
              "label": "bori (levante)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad6b"
            },
            {
              "label": "sidat (bora)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad6a"
            },
            {
              "label": "tulero (tulero)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad69"
            },
            {
              "label": "ovam (grecale)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad68"
            },
            {
              "label": "franceschini (ponente)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad67"
            },
            {
              "label": "felappi (A17)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad66"
            },
            {
              "label": "felappi-stellare (A18)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad65"
            },
            {
              "label": "sidat-stellare (A19)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad64"
            },
            {
              "label": "prasco (A20)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad63"
            },
            {
              "label": "prasco2 (A21)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad62"
            },
            {
              "label": "cida-prasco (A22)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad61"
            },
            {
              "label": "idir-stellare (A23)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad60"
            },
            {
              "label": "euroglass (A24)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad5f"
            },
            {
              "label": "action (A25)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad5e"
            },
            {
              "label": "abs (A26)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad5d"
            },
            {
              "label": "viskoil (A27)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad5c"
            },
            {
              "label": "idir-milano (A28)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad5b"
            },
            {
              "label": "idir-auto180 (idir-caserta)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad5a"
            },
            {
              "label": "commercialericambi (A29)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad59"
            },
            {
              "label": "cida-extra (A30)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad58"
            },
            {
              "label": "gammaplast (A31)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad57"
            },
            {
              "label": "idir-milano-auto180 (idir-milano)",
              "key": "admin.charts.orders.warehouse.65798bbd3128245708efad56"
            }

          ]
        }
      ]
    },
  ];


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  protected menu!: any[];
  protected table!: any[];
  public tablesList !: DynamicData;

  constructor(
    private modalService: NgbModal,
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private _dynamicTableService: DynamicTableService,
    private _catalogService: CatalogService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('super-permissions-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loaded = Promise.resolve(false);
    this.currentId = this._activatedRoute.snapshot.paramMap.get('id') || '';
    this.permissionsList = this.generatePermission();
    const query: any = {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['network', 'group'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    let networkList: DynamicData;
    this._customerService.getNetworkList()
      .then((networks) => {
        networkList = networks;
        console.log('Networks', networkList);
        console.log('Data', this.data);
        networkList.rows.forEach((network) => {
          console.log('Network', network);
          this.data[0].ids.push({
            label: network.name,
            key: network.id,
            active: false
          });
        });
        console.log('Data', this.data);
      });

    this._catalogService.getWarehouseList()
      .then((warehouses) => {
        warehouses.rows.forEach((warehouse) => {
          this.data[1].ids.push({
            label: warehouse.name,
            key: warehouse.id,
            active: false
          });
        });
      });


    this._customerService.getIdentityById(this.currentId)
      .then((identity) => {
        this.identity = identity;
        console.log('Identity', this.identity);
        this._customerService.getPermissions()
          .then((response) => {
            console.log('Permissions', response);
            this.AllPermissions = response as unknown as any[]
            this.AllPermissions.forEach((permission: any) => {
              if (permission.startsWith('admin.page')) {
                this.AllPagePermissions.push(permission);
              } else if (permission.startsWith('admin')) {
                this.AllAdditionalPermissions.push(permission);
              }
              if (permission.startsWith('data.networks') && this.identity.permissions.includes(permission)) {
                console.log('Permission networks found', permission)
                const networkId = permission.split('.')[2];
                console.log('Network id', networkId);
                const network = this.data[0].ids.find((network) => network.key === networkId);
                console.log('Network', network);
                if (network) {
                  network.active = true;
                  console.log('Network active', network);
                }
              }
              console.log('Permission', permission);
              if (permission.startsWith('data.warehouses') && this.identity.permissions.includes(permission)) {
                console.log('Permission warehouses found', permission)
                const warehouseId = permission.split('.')[2];
                console.log('Warehouse id', warehouseId);
                const warehouse = this.data[1].ids.find((warehouse) => warehouse.key === warehouseId);
                console.log('Warehouse', warehouse);
                if (warehouse) {
                  warehouse.active = true;
                  console.log('Warehouse active', warehouse);
                }
              }
            });
            this.AllPagePermissions = this.convertArrayToArray(this.AllPagePermissions);
            this.AllAdditionalPermissions = this.convertArrayToArray(this.AllAdditionalPermissions);
            this.setDataPermissions(this.dataPermissions, this.identity.permissions);
            this.identity.permissions.forEach((permission: string) => {
              if (permission.startsWith('menu')) {
                this.menuPermissionsArray.push(permission);
              } else if (permission.startsWith('dynamic-table')) {
                this.tablePermissionsArray.push(permission);
              } else if (permission.startsWith('admin.page')) {
                this.pagePermissionsArray.push(permission);
              } else if (permission.startsWith('admin')) {
                this.additionalPermissions.push(permission);
              }
            });
            this.additionalPermissions = this.convertArrayToArray(this.additionalPermissions);
            this.pagePermissions = this.convertArrayToArray(this.pagePermissionsArray);
            this.makeAllInactive(this.AllPagePermissions);
            this.makeAllInactive(this.AllAdditionalPermissions);
            this.comparePermissions(this.AllPagePermissions, this.pagePermissions);
            this.comparePermissions(this.AllAdditionalPermissions, this.additionalPermissions);

            this._applicationService.menu.pipe(takeUntil(this._unsubscribeAll))
              .subscribe((menu: MenuGroup[]) => {
                this.menu = menu as any[];
                this.setMenuPermissions(this.menuPermissionsArray, this.menu);
              });
            this._dynamicTableService.getDynamicTablePermissionsList()
              .then((tables) => {
                this.table = tables;
                this.table = this.setTablePermissions2(this.tablePermissionsArray, this.table);
                this._dynamicTableService.getList()
                  .then((data) => {
                    this.tablesList = data;
                    this.table = this.setTablePermissions(this.table, this.tablesList as any);
                    this.loaded = Promise.resolve(true);
                  });
              });

            if (this.pagePermissions.length > 0) {
              this.pagePermissions[0] = this.pagePermissions[0].children[0];
            }

          })
          .catch((error) => {
            console.log('permissions error', error);
          })

      });
    this.getIdentities();
  }

  updateCurrentIdentity() {

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getDataPermissions(dataPermissions: any): string[] {
    const result: string[] = []
    if (!dataPermissions) {
      return [];
    }
    dataPermissions.forEach((permission: any) => {
      if (permission.active) {
        result.push(permission.key);
        result.push(...this.getDataPermissions(permission.children));
      }
    })
    return result;
  }

  setDataPermissions(dataPermissions: any, permissions: string[]) {
    if (!dataPermissions) {
      return;
    }
    dataPermissions.forEach((permission: any) => {
      if (permissions.includes(permission.key)) {
        permission.active = true;
      }

      this.setDataPermissions(permission.children, permissions);
    })
  }

  save() {
    Swal2.fire({
      title: 'Sei sicuro?',
      text: 'Stai per aggiornare i permessi di questo utente!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, aggiorna!',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const menuPermissions = this.getMenuPermissions(this.menu, 'menu.');
        const tablePermissions = this.getTablePermissions(this.table, 'dynamic-table.');
        const pagePermissions = this.convertArrayToStringArray(this.AllPagePermissions);
        const additionalPermissions = this.convertArrayToStringArray(this.AllAdditionalPermissions);
        let dPermissions: string[] = [];
        this.data.forEach((data) => {
          data.ids.forEach((id) => {
            if (id.active) {
              dPermissions.push('data.' + data.category + '.' + id.key);
            }
          });
        });
        this.getDataPermissions(this.dataPermissions);
        this.identity.permissions = [
          ...menuPermissions,
          ...tablePermissions,
          ...pagePermissions,
          ...dPermissions,
          // ...additionalPermissions,
          ...this.getDataPermissions(this.dataPermissions)
        ];
        console.log('Permissions', this.identity.permissions);
        this._customerService.updateIdentity(this.identity)
          .then((response) => {
            this.identity = response;
            this.identity.permissions.forEach((permission: string) => {
              if (permission.startsWith('menu')) {
                this.menuPermissionsArray.push(permission);
              } else if (permission.startsWith('dynamic-table')) {
                this.tablePermissionsArray.push(permission);
              } else if (permission.startsWith('admin.page')) {
                this.pagePermissionsArray.push(permission);
              }
            });
            this._applicationService.menu.pipe(takeUntil(this._unsubscribeAll))
              .subscribe((menu: MenuGroup[]) => {
                this.menu = menu as any[];
                this.setMenuPermissions(this.menuPermissionsArray, this.menu);
              });
            this._dynamicTableService.getDynamicTablePermissionsList()
              .then((tables) => {
                this.table = tables;
                this.table = this.setTablePermissions2(this.tablePermissionsArray, this.table);
                this._dynamicTableService.getList()
                  .then((data) => {
                    this.tablesList = data;
                    this.table = this.setTablePermissions(this.table, this.tablesList as any);
                  });
              });
            this.loaded = Promise.resolve(true);
            if (this.pagePermissions.length > 0) {
              this.pagePermissions[0] = this.pagePermissions[0].children[0];
            }
            this.loaded = Promise.resolve(true);
          });
        Swal2.fire('Aggiornato!', 'I permessi sono stati aggiornati con successo!', 'success');
      }
    });


  }

  suspend() {
    Swal2.fire({
      title: 'Sei sicuro?',
      text: 'Stai per sospendere questo utente! questo rimuoverà tutte le autorizzazioni!',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Si, sospendi!',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.identity.permissions = [];
        this._customerService.updateIdentity(this.identity)
          .then((response) => {
            this.identity = response;
          });
        Swal2.fire('Sospeso!', 'L\'utente è stato sospeso con successo!', 'success');
      }
    });
  }

  convertArrayToArray(array1: string[]): any[] {
    const result: any[] = [];

    for (const item of array1) {
      if (item !== '*') {
        const keys = item.split('.');
        let tempArray: any[] = result;

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (key !== '*') {
            const existingIndex = tempArray.findIndex(obj => obj.name === key);
            if (existingIndex === -1) {
              const newItem = {
                name: key,
                active: true,
                children: []
              };
              tempArray.push(newItem);
              tempArray = newItem.children;
            } else {
              tempArray = tempArray[existingIndex].children;
            }
          }
        }
      }
    }
    return result;
  }


  updateMenuActiveStatus(menuItems: PermissionsItem[], permissions: PermissionsItem) {

    // Flatten the permissions object into an array
    const permissionArray: PermissionsItem[] = this.flattenPermissions(permissions);

    menuItems.forEach(item => {

      // Find the corresponding permission item from the flattened array
      const permissionItem = permissionArray.find(permission => permission.name === item.name);

      if (permissionItem) {
        if (permissionItem.active || permissionItem.children.some(child => child.active)) {
          item.active = true;
          this.setParentsActive(menuItems, permissionItem);
        } else {
          item.active = false;
        }

        if (item.children.length > 0 && permissionItem.children.length > 0) {
          // Recursively call updateMenuActiveStatus with the children of the current item
          this.updateMenuActiveStatus(item.children, permissionItem);
        }
      } else {
        item.active = false;
        console.error('Permission item is undefined for:', item);
      }
    });
    this.checkParentsActive(menuItems);
    return menuItems;
  }

  checkParentsActive(menuItems: PermissionsItem[]) {
    //if all children are active, set the parent as active
    menuItems.forEach(item => {
      if (item.children.length > 0) {
        if (item.children.every(child => child.active)) {
          item.active = true;
        }
        this.checkParentsActive(item.children);
      }
    });
  }

// Helper function to set all parent items as active
  setParentsActive(menuItems: PermissionsItem[], item: PermissionsItem) {
    const parent = menuItems.find(menuItem => menuItem.children.includes(item));
    if (parent) {
      parent.active = true;
      this.setParentsActive(menuItems, parent);
    }
  }


  flattenPermissions(permissions: PermissionsItem): PermissionsItem[] {
    const result: PermissionsItem[] = [];
    const stack: PermissionsItem[] = [permissions];

    while (stack.length > 0) {
      const node = stack.pop();
      if (node) {
        result.push(node);
        if (node.children) {
          stack.push(...node.children);
        }
      }
    }
    return result;
  }


  changeChildrens(menu: any, item: any) {
    if (item.active) {
      this.changeChildrensActive(item, false);
    } else {
      this.changeParentsActive(menu, item, true);
      this.changeChildrensActive(item, true);
    }
  }

  private changeChildrensActive(item: any, b: boolean) {
    if (item.children && item.children.length > 0) {
      item.children.forEach((child: any) => {
        child.active = b;
        this.changeChildrensActive(child, b);
      });
    }
  }

  private changeParentsActive(menu: any, item: any, b: boolean) {
    let parent = this.findParent(menu, item);
    if (parent) {
      parent.active = b;
      this.changeParentsActive(menu, parent, b);
    }
  }

  findParent(menu: any, item: any): any {
    for (let i = 0; i < menu.length; i++) {
      let parent = menu[i];
      if (parent.children && parent.children.length > 0) {
        if (parent.children.includes(item)) {
          return parent;
        }
      }
      if (parent.children && parent.children.length > 0) {
        let result = this.findParent(parent.children, item);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  convertArrayToStringArray(permissions: any[]): string[] {
    const result: string[] = [];

    function convertPermissionsToStringArray(permissions: any[], prefix: string = '') {
      for (const permission of permissions) {
        if (permission.active) {
          let name = prefix ? `${prefix}.${permission.name}` : permission.name;
          if (name.startsWith('page')) {
            name = `admin.${name}`;
          }
          if (name.endsWith('columns') || name.endsWith('filterGroups')) {
            name += '.*';
          }
          result.push(name);
          if (permission.children && permission.children.length > 0) {
            convertPermissionsToStringArray(permission.children, name);
          }
        }
      }
    }

    convertPermissionsToStringArray(permissions);
    return result;
  }

  async getIdentities(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['application', 'user', 'business'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    query['id'] = this.currentId;
    const identity: DynamicData = await this._customerService.getIdentityById(this.currentId)

    return identity
  }

  addPermission(addNewPermissionModal: TemplateRef<any>) {
    return this.modalService.open(addNewPermissionModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  generatePermission(): any {
    this._customerService.getPermissions()
      .then((response) => {
        const permissions = response;
        return permissions;
      })
      .catch((error) => {
        console.log('permissions error', error);
      })
  }


  setMenuPermissions(permissions: string[], menu: any[], parent: string = "menu.") {
    for (let i = 0; i < menu.length; i++) {
      let item = menu[i];
      let name = parent + item.code;
      if (permissions.includes(name)) {
        item.active = true;
      }
      if (item.children && item.children.length > 0) {
        this.setMenuPermissions(permissions, item.children, name + ".")
      }
    }
  }

  getMenuPermissions(menu: any[], parent: string = ""): string[] {
    let result: string[] = [];
    for (let i = 0; i < menu.length; i++) {
      let item = menu[i];
      let name = parent + item.code;
      if (item.active)
        result.push(name);
      if (item.children && item.children.length > 0) {
        result = result.concat(this.getMenuPermissions(item.children, name + "."));
      }
    }
    return result;
  }


  setTablePermissions(permissions: any[], tables: any[], parent: string = "dynamic-table.") {
    for (let i = 0; i < tables.length; i++) {
      let item = tables[i];
      let name = parent + item.name;
      let name2 = parent + item.name + '*';
      permissions.forEach((permission: any) => {
        if (permission.name === name && permission.active) {
          item.active = true;
        }
        if (permission.name === name2 && permission.active) {
          item.active = true;
        }
      })
      if (item.columns && item.columns.length > 0) {
        for (let j = 0; j < item.columns.length; j++) {
          let column = item.columns[j];
          let columnName = name + '.columns.' + j;
          permissions.forEach((permission: any) => {
            if (permission.name === columnName && permission.active) {
              column.active = true;
            }
          });
        }
        let columnName = name + '.columns.*';
        permissions.forEach((permission: any) => {
          if (permission.name === columnName && permission.active) {
            item.columns.active = true;
            item.columns.forEach((column: any) => {
              column.active = true;
            });
          } else {
            item.columns.active = false;
          }
        });
      }
      if (item.filterGroups && item.filterGroups.length > 0) {
        item.filterGroups.forEach((filterGroup: any, index: number) => {
          if (filterGroup.filters) {
            filterGroup.filters.forEach((filter: any, filterIndex: number) => {
              let filterName = name + '.filterGroups.' + index + '.filters.' + filterIndex;
              permissions.forEach((permission: any) => {
                if (permission.name === filterName && permission.active) {
                  filter.active = true;
                }
              });
            });
          }
        })
      }
    }
    return tables;
  }

  setTablePermissions2(permissions: string[], tables: string[]) {
    let result: any[] = [];
    for (let i = 0; i < tables.length; i++) {
      let item = {
        name: tables[i],
        active: false,
      };
      if (permissions.includes(item.name)) {
        item.active = true;
      } else {
        item.active = false;
      }
      result.push(item);
    }
    return result;

  }

  getTablePermissions(tables: any[], parent: string = ""):
    string[] {
    let result: string[] = [];
    for (let i = 0; i < tables.length; i++) {
      let item = tables[i];
      let name = parent + item.name;
      if (item.active)
        result.push(name);
      if (item.columns && item.columns.length > 0) {
        for (let j = 0; j < item.columns.length; j++) {
          let column = item.columns[j];
          let columnName = name + '.columns.' + j;
          if (column.active) {
            result.push(columnName);
          }
        }
      }
      if (item.filterGroups) {
        item.filterGroups.forEach((filterGroup: any, index: number) => {
          if (filterGroup.filters) {
            filterGroup.filters.forEach((filter: any, filterIndex: number) => {
              let filterName = name + '.filterGroups.' + index + '.filters.' + filterIndex;
              if (filter.active) {
                if (!result.includes(name + '.filterGroups.' + index)) {
                  result.push(name + '.filterGroups.' + index);
                }
                result.push(filterName);
              }
            });
          }
        })
      }
      if (item.children && item.children.length > 0) {
        result = result.concat(this.getTablePermissions(item.children, name + "."))
      }
    }
    return result;
  }

  private comparePermissions(AllPagePermissions: any[], pagePermissions: any[]) {
    for (let i = 0; i < AllPagePermissions.length; i++) {
      let item = AllPagePermissions[i];
      let name = item.name;

      // Check if the current item exists in pagePermissions
      let matchingPermission = pagePermissions.find((permission: any) => permission.name === name);
      if (matchingPermission) {
        item.active = true; // Set active to true if found

        // Set active property of parent items up to the root to true
        let parent = item;
        while (parent = this.findParent2(parent, AllPagePermissions)) {
          parent.active = true;
        }
      }

      // Recursively call comparePermissions for children
      if (item.children && item.children.length > 0) {
        this.comparePermissions(item.children, matchingPermission ? matchingPermission.children : []);
      }
    }
  }

  private findParent2(child: any, array: any[]): any {
    for (let i = 0; i < array.length; i++) {
      let item = array[i];
      if (item.children && item.children.includes(child)) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        let parent = this.findParent2(child, item.children);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  }


  private makeAllInactive(AllPagePermissions: any[]) {
    for (let i = 0; i < AllPagePermissions.length; i++) {
      let item = AllPagePermissions[i];
      item.active = false;
      if (item.children && item.children.length > 0) {
        this.makeAllInactive(item.children);
      }
    }
  }

  activateAllMenu() {
    console.log('Menu', this.menu);
    this.menu.forEach((menu) => {
      console.log('Menu', menu);
      menu.active = true;
      if (menu.children) {
        menu.children.forEach((menu2: { active: boolean; children: any[] }) => {
          console.log('Menu2', menu2);
          menu2.active = true;
          if (menu2.children) {
            menu2?.children.forEach((menu3: { active: boolean; children: any[] }) => {
              menu3.active = true;
              if (menu3.children) {
                menu3?.children.forEach((menu4: { active: boolean; children: any[] }) => {
                  menu4.active = true;
                  if (menu4.children) {
                    menu4?.children.forEach((menu5: { active: boolean; children: any[] }) => {
                      menu5.active = true;
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  activateAllTable() {
    this.table.forEach((table) => {
      table.active = true;
      table.columns.active = true;
      table.filterGroups.active = true;
      table.columns.forEach((column: { active: boolean }) => {
        column.active = true;
      } );
      if(table.filterGroups) {
        table.filterGroups.forEach((filterGroup: { filters: any[] }) => {
          if(filterGroup.filters) {
          filterGroup.filters.forEach((filter: { active: boolean }) => {
            filter.active = true;
          });
          }
        });
      }
    });
  }

  activateAllPages() {
    this.AllPagePermissions.forEach((page) => {
      page.active = true;
      if (page.children) {
        page.children.forEach((page2: { active: boolean; children: any[] }) => {
          page2.active = true;
          if (page2.children) {
            page2?.children.forEach((page3: { active: boolean; children: any[] }) => {
              page3.active = true;
              if (page3.children) {
                page3?.children.forEach((page4: { active: boolean; children: any[] }) => {
                  page4.active = true;
                  if (page4.children) {
                    page4?.children.forEach((page5: { active: boolean; children: any[] }) => {
                      page5.active = true;
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  activateAllDatas() {
    this.data.forEach((data) => {
      data.ids.forEach((id) => {
        id.active = true;
      });
    });
  }
}


export interface PermissionsItem {
  name: string;
  active: boolean;
  children: PermissionsItem[];
}


export interface IDS {
  label: string;
  key: string;
  active: boolean;
}

export interface DATAS {
  category: string;
  ids: IDS[];
}
