//service file for the orders detail component

import { ApiService } from "./api.service";
import { DynamicData } from "../types/dynamic-table/dynamic-data";
import { ListParams } from "../types/api/list-params";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";


@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(
    private _apiService: ApiService,
  ) {
  }

  getOrderDetails(code: string, year: string): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      this._apiService.make('orders', 'getDetails', { code: code, year: year })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getDeliveries(id: string): Promise<DynamicData> {
    return new Promise((resolve, reject) => {
      this._apiService.make('orders', 'getDeliveries', { id: id })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as DynamicData)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  downloadProductsCSV(orderCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const [code, year] = orderCode.split('/');
      this._apiService.make('orders', 'downloadProductsCSV', { code, year }, {responseType: 'blob'})
        .then((response) => {
          if (!response) {
            return
          }
          const fileName = `${code}_${year}.csv`;
          saveAs(response, fileName);
          resolve(fileName)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  statusCompleted(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('orders', 'statusCompleted', { id })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  statusCanceled(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('orders', 'statusCanceled', { id })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  newDelivery(id: string, data: any, products: any): Promise<any> {
    const delivery = {
      reference: data.deliveryCode ? data.deliveryCode : null,
      tracking: data.deliveryTrack ? data.deliveryTrack : null,
      courier: data.courier ? data.courier : null,
      date: data.deliveryDate ? data.deliveryDate : null,
      products: products
    }
    return new Promise((resolve, reject) => {
      this._apiService.make('orders', 'newDelivery', { id }, { body: { delivery } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  getStats(filters?: any): Promise<any> {
    const options: any = {};
    if (filters) {
      options['params'] = filters;
    }
    return new Promise((resolve, reject) => {
      this._apiService.make('orders', 'stats', {}, options)
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  editDelivery(id: string, deliveryId: string, data: any, products: any): Promise<any> {
    const delivery = {
      reference: data.deliveryCode ? data.deliveryCode : null,
      tracking: data.deliveryTrack ? data.deliveryTrack : null,
      courier: data.courier ? data.courier : null,
      date: data.deliveryDate ? data.deliveryDate : null,
      products: products
    }
    return new Promise((resolve, reject) => {
      this._apiService.make('orders', 'editDelivery', { id, deliveryId }, { body: { delivery } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response as any)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

}
