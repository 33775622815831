<div class="row" *ngIf="loaded | async">
  <form [formGroup]="invoiceForm" class="mb-0">
    <div class="row">
      <div class="col-7 d-flex">
        <div class="card full-width">
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">DETTAGLI</h5>
            <div class="row">
              <div class="form-group col-3">
                <fieldset class="form-group">
                  <!-- Basic Multi select -->
                  <div class="form-group">
                    <span>Stato</span>
                  </div>
                  <ng-select
                    *ngIf="currentInvoice.status != 'SENT'"
                    [items]="statuses"
                    [multiple]="false"
                    [closeOnSelect]="true"
                    [searchable]="true"
                    [(ngModel)]="status"
                    placeholder="{{status.label}}"
                    (change)="changeStatus($event)"
                    formControlName="statusInput"
                  >
                  </ng-select>
                  <div *ngIf="currentInvoice.status === 'SENT'">
                    <span
                      [ngClass]="{
                        'badge-light-primary': currentInvoice.status == 'CONFIRMED',
                        'badge-light-success': currentInvoice.status == 'SENT',
                        'badge-light-danger': currentInvoice.status == 'CREATED' || currentInvoice.status == 'CANCELLED' || currentInvoice.status == 'REJECTED' || currentInvoice.status == 'FAILED',
                        'badge-light-warning': currentInvoice.status == 'OPENED',
                        'badge-light-info': currentInvoice.status == 'CLOSED' || currentInvoice.status == 'VERIFIED'
                      }"
                      class="badge badge-pill font-medium-1"
                    >
                      {{ status.label }}
                    </span>
                  </div>
                  <!--/ Basic Multi select -->
                </fieldset>
              </div>

              <div class="form-group col-3">
                <label>Data Fattura</label>
                <div>
                  <div *ngIf="currentInvoice.status != 'SENT'" class="input-group">
                    <input class="form-control" placeholder="{{currentInvoice.invoiceDate | date}}"
                           formControlName="invoiceDate"
                           id="invoiceDate" ngbDatepicker #basicDP="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="feather icon-calendar cursor-pointer" (click)="basicDP.toggle()"
                              type="button" rippleEffect>
                        <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="currentInvoice.status === 'SENT'">
                    <b>{{ currentInvoice.invoiceDate | dateHumanized:"Y-m-d" }}</b>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <label>Data Creazione</label>
                <div>
                  <div *ngIf="currentInvoice.status != 'SENT'" class="input-group">
                    <input class="form-control" placeholder="{{currentInvoice.createdAt | date}}"
                           formControlName="creationDate"
                           id="creationDate" ngbDatepicker #basicDP2="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="feather icon-calendar cursor-pointer" (click)="basicDP2.toggle()"
                              type="button" rippleEffect>
                        <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="currentInvoice.status === 'SENT'">
                    <b>{{ currentInvoice.createdAt | dateHumanized:"Y-m-d H:i" }}</b>
                  </div>
                </div>
              </div>

              <div class="form-group col-3" *ngIf="currentInvoice.status == 'SENT'">
                <label>Data Invio</label>
                <div>
                  <div *ngIf="currentInvoice.status != 'SENT'" class="input-group">
                    <input class="form-control" placeholder="{{currentInvoice.updatedAt | date}}"
                           formControlName="updateDate"
                           id="updateDate" ngbDatepicker #basicDP3="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="feather icon-calendar cursor-pointer" (click)="basicDP3.toggle()"
                              type="button" rippleEffect>
                        <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="currentInvoice.status === 'SENT'">
                    <b>{{ currentInvoice.updatedAt | dateHumanized:"Y-m-d H:i" }}</b>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group mt-1">
              <label>Metodo di pagamento</label>
              <ng-select
                *ngIf="currentInvoice.status != 'SENT'"
                [items]="paymentMethods"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                (change)="changePaymentMethod($event)"
                placeholder="{{currentInvoice.payments.method ? currentInvoice.payments.method.code + ' | ' + currentInvoice.payments.method.label : 'Seleziona metodo di pagamento'}}"
                formControlName="paymentMethodInput"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.label }}</span>
                </ng-template>
              </ng-select>
              <div *ngIf="currentInvoice.status === 'SENT'">
                <b>{{ currentInvoice.payments.method ? currentInvoice.payments.method.code + ' | ' + currentInvoice.payments.method.label : 'Nessun metodo di pagamento' }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3 d-flex">
        <div class="card full-width">
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">
              TOTALE
              <span
                class="badge badge-pill font-medium-1 badge-light-primary ml-1">{{ total.invoice + total.tax | price }}</span>
            </h5>
            <div class="row my-50">
              <div class="form-group col-6">
                <label>Imponibile</label>
                <div>
                  <span class="font-weight-bold">{{ total.invoice | price }}</span>
                </div>
              </div>

              <div class="form-group col-6">
                <label>IVA</label>
                <div>
                  <span class="font-weight-bold">{{ total.tax | price }}</span>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="col-2 d-flex">
        <div class="card full-width">
          <div class="card-header pb-0">
            <h4 class="card-title">Azioni</h4>
          </div>
          <div class="card-body pt-05 pb-0 d-flex flex-column justify-content-center overflow-visible">
            <button *ngIf="currentInvoice.status === 'SENT'" class="btn btn-primary btn-block mb-1 text-white"
                    (click)="downloadInvoice()">
              <app-svg-icon class="d-inline-block align-left" name="download"></app-svg-icon>
              <span class="align-left">Scarica XML</span>
            </button>
            <button *ngIf="currentInvoice.status != 'SENT'" class="btn btn-success btn-block mb-1"
                    (click)="saveInvoice()">
              <app-svg-icon class="d-inline-block align-middle" name="save"></app-svg-icon>
              <span class="align-middle">Salva</span>
            </button>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card full-width">
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">AZIENDA</h5>
            <div class="row">
              <div *ngIf="currentInvoice.status != 'SENT'" class="form-group col-4">
                <label>Cliente</label>
                <input

                  #businessInput
                  type="text"
                  class="form-control"
                  placeholder="{{selectedBusiness.name}}"
                  aria-describedby="basic-addon-search2"
                  (keyup.enter)="searchBusiness(businessInput.value)"
                  [(ngModel)]="selectedBusiness.name"
                  (ngModelChange)="changeClient(businessInput.value)"
                  id="customer"
                  formControlName="businessInput"
                />
                <div class="card dropdown2" *ngIf="showDropdown && businesses.length > 0">
                  <ul class="suggested-products">
                    <ng-container *ngFor="let business of businesses">
                      <li class="cursor-pointer" (click)="selectBusiness(business)">
                        <span class="font-weight-bold m-1">{{ business.code }} - {{ business.name }}</span>
                      </li>
                    </ng-container>
                  </ul>
                </div>
                <div class="card dropdown2" *ngIf="showDropdown && businesses.length == 0">
                  <ul class="suggested-products">
                    <li>
                      <span>Nessun risultato</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="currentInvoice.status === 'SENT'" class="form-group col-4">
                <label>Cliente</label>
                <b>{{ selectedBusiness.name || '-'}}</b>
              </div>

              <div *ngIf="currentInvoice.status != 'SENT'" class="form-group col-2">
                <label>P.IVA</label>
                <div>
                  <input
                    #ivaInput
                    type="text"
                    class="form-control"
                    placeholder="{{selectedBusiness.vat}}"
                    aria-describedby="basic-addon-search2"
                    [(ngModel)]="selectedBusiness.vat"
                    (ngModelChange)="changeVatNumber(ivaInput.value)"
                    id="ivaInput"
                    formControlName="ivaInput"
                  />
                </div>
              </div>
              <div *ngIf="currentInvoice.status === 'SENT'" class="form-group col-2">
                <label>P.IVA</label>
                <b>{{ selectedBusiness.businessData.vat || '-' }}</b>
              </div>

              <div *ngIf="currentInvoice.status != 'SENT'" class="form-group col-2">
                <label>Codice Fiscale</label>
                <div>
                  <input
                    #fiscalInput
                    type="text"
                    class="form-control"
                    placeholder="{{selectedBusiness.fiscalCode}}"
                    aria-describedby="basic-addon-search2"
                    [(ngModel)]="selectedBusiness.fiscalCode"
                    (ngModelChange)="changeFiscal(fiscalInput.value)"
                    id="fiscalCode"
                    formControlName="fiscalInput"
                  />
                </div>
              </div>
              <div *ngIf="currentInvoice.status === 'SENT'" class="form-group col-2">
                <label>Codice Fiscale</label>
                <b>{{ selectedBusiness.fiscalCode || '-' }}</b>
              </div>

              <div *ngIf="currentInvoice.status != 'SENT'" class="form-group col-2">
                <label>PEC</label>
                <div>
                  <input
                    #pecInput
                    type="text"
                    class="form-control"
                    placeholder="{{selectedBusiness.pec}}"
                    aria-describedby="basic-addon-search2"
                    [(ngModel)]="selectedBusiness.pec"
                    (ngModelChange)="changePecNumber(pecInput.value)"
                    id="pec"
                    formControlName="pecInput"
                  />
                </div>
              </div>
              <div *ngIf="currentInvoice.status === 'SENT'" class="form-group col-2">
                <label>PEC</label>
                <b>{{ selectedBusiness.pec || '-' }}</b>
              </div>

              <div *ngIf="currentInvoice.status != 'SENT'" class="form-group col-2">
                <label>Codice Unico</label>
                <div>
                  <input
                    #uniqueInput
                    type="text"
                    class="form-control"
                    placeholder="{{selectedBusiness.uniqueCode}}"
                    aria-describedby="basic-addon-search2"
                    [(ngModel)]="selectedBusiness.uniqueCode"
                    (ngModelChange)="changeUniqueNumber(uniqueInput.value)"
                    id="uniqueCode"
                    formControlName="uniqueInput"
                  />
                </div>
              </div>
              <div *ngIf="currentInvoice.status === 'SENT'" class="form-group col-2">
                <label>Codice Unico</label>
                <b>{{ selectedBusiness.uniqueCode || '-' }}</b>
              </div>
            </div>
            <div class="form-group col-12 mt-1">
              <label>Indirizzo Fatturazione</label>
              <div>
                <div class="row mt-1">
                  <div *ngIf="currentInvoice.status != 'SENT'" class="col-4">
                    <label>Via</label>
                    <input
                      #streetInput
                      type="text"
                      class="form-control"
                      placeholder="{{selectedBusiness.location.street}}"
                      aria-describedby="basic-addon-search2"
                      [(ngModel)]="selectedBusiness.location.street"
                      (ngModelChange)="changeAddress(streetInput.value)"
                      id="street"
                      formControlName="streetInput"
                    />
                  </div>
                  <div *ngIf="currentInvoice.status === 'SENT'" class="col-4">
                    <label>Via</label>
                    <b>{{ selectedBusiness.location.street || '-' }}</b>
                  </div>
                  <div *ngIf="currentInvoice.status != 'SENT'" class="col-1">
                    <label>Numero</label>
                    <input
                      #numberInput
                      type="text"
                      class="form-control"
                      placeholder="{{selectedBusiness.location.number}}"
                      aria-describedby="basic-addon-search2"
                      [(ngModel)]="selectedBusiness.location.number"
                      (ngModelChange)="changeAddress(numberInput.value)"
                      id="number"
                      formControlName="numberInput"
                    />
                  </div>
                  <div *ngIf="currentInvoice.status === 'SENT'" class="col-1">
                    <label>Numero</label>
                    <b>{{ selectedBusiness.location.number || '-' }}</b>
                  </div>
                  <div *ngIf="currentInvoice.status != 'SENT'" class="col-1">
                    <label>CAP</label>
                    <input
                      #zipInput
                      type="text"
                      class="form-control"
                      placeholder="{{selectedBusiness.location.zip}}"
                      aria-describedby="basic-addon-search2"
                      [(ngModel)]="selectedBusiness.location.zip"
                      (ngModelChange)="changeAddress(zipInput.value)"
                      id="zip"
                      formControlName="zipInput"
                    />
                  </div>
                  <div *ngIf="currentInvoice.status === 'SENT'" class="col-1">
                    <label>CAP</label>
                    <b>{{ selectedBusiness.location.zip || '-' }}</b>
                  </div>
                  <div *ngIf="currentInvoice.status != 'SENT'" class="col-3">
                    <label>Citta</label>
                    <input
                      #cityInput
                      type="text"
                      class="form-control"
                      placeholder="{{selectedBusiness.location.city}}"
                      aria-describedby="basic-addon-search2"
                      [(ngModel)]="selectedBusiness.location.city"
                      (ngModelChange)="changeAddress(cityInput.value)"
                      id="city"
                      formControlName="cityInput"
                    />
                  </div>
                  <div *ngIf="currentInvoice.status === 'SENT'" class="col-3">
                    <label>Citta</label>
                    <b>{{ selectedBusiness.location.city || '-' }}</b>
                  </div>
                  <div *ngIf="currentInvoice.status != 'SENT'" class="col-1">
                    <label>Provincia</label>
                    <input
                      #provinceInput
                      type="text"
                      class="form-control"
                      placeholder="{{selectedBusiness.location.province}}"
                      aria-describedby="basic-addon-search2"
                      [(ngModel)]="selectedBusiness.location.province"
                      (ngModelChange)="changeAddress(provinceInput.value)"
                      id="province"
                      formControlName="provinceInput"
                    />
                  </div>
                  <div *ngIf="currentInvoice.status === 'SENT'" class="col-1">
                    <label>Provincia</label>
                    <b>{{ selectedBusiness.location.province || '-' }}</b>
                  </div>
                  <div *ngIf="currentInvoice.status != 'SENT'" class="col-1">
                    <label>Regione</label>
                    <input
                      #regionInput
                      type="text"
                      class="form-control"
                      placeholder="{{selectedBusiness.location.region}}"
                      aria-describedby="basic-addon-search2"
                      [(ngModel)]="selectedBusiness.location.region"
                      (ngModelChange)="changeAddress(regionInput.value)"
                      id="region"
                      formControlName="regionInput"
                    />
                  </div>
                  <div *ngIf="currentInvoice.status === 'SENT'" class="col-1">
                    <label>Regione</label>
                    <b>{{ selectedBusiness.location.region || '-' }}</b>
                  </div>
                  <div *ngIf="currentInvoice.status != 'SENT'" class="col-1">
                    <label>Paese</label>
                    <input
                      #countryInput
                      type="text"
                      class="form-control"
                      placeholder="{{selectedBusiness.location.country}}"
                      aria-describedby="basic-addon-search2"
                      [(ngModel)]="selectedBusiness.location.country"
                      (ngModelChange)="changeAddress(countryInput.value)"
                      id="country"
                      formControlName="countryInput"
                    />
                  </div>
                  <div *ngIf="currentInvoice.status === 'SENT'" class="col-1">
                    <label>Paese</label>
                    <b>{{ selectedBusiness.location.country || '-' }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card invoice-card mt-1">
          <div class="card-body">
            <ng-container *ngIf="currentInvoice.status != 'SENT'">
              <button type="button" class="btn btn-icon btn-primary mb-1"
                      (click)="openAddProductModal(addNewRowModal)" rippleEffect>
                <app-svg-icon class="d-inline-block align-middle" name="add"></app-svg-icon>
                Aggiungi riga
              </button>
            </ng-container>
            <div class="table-responsive">
              <h4 class="mb-1 text-primary">PRODOTTI</h4>
              <table class="table">
                <thead class="thead-light">
                <tr>
                  <th>Cod.articolo</th>
                  <th>Descrizione</th>
                  <th class="text-center">Quantità (spedita)</th>
                  <th>Prezzo unitario</th>
                  <th>UM</th>
                  <th>Sconto o magg.</th>
                  <th>%IVA</th>
                  <th>Prezzo Totale</th>
                  <th *ngIf="currentInvoice.status != 'SENT'"></th>
                </tr>
                </thead>
                <tbody formArrayName="lines">
                <ng-container *ngFor="let line of currentInvoice.lines;  let i = index">
                  <tr *ngIf="line.type === 'TEXT'" class="order-row">
                    <td *ngIf="currentInvoice.status === 'SENT'">
                        <a
                          class="badge badge-pill badge-primary text-primary"
                          href="/tulero-b2b/order/detail/{{line.id}}">{{ line.code }}</a>
                    </td>
                    <td *ngIf="currentInvoice.status != 'SENT'">
                      <div class="d-inline-flex align-items-center">
                        <input
                          #orderCodeInput
                          type="text"
                          class="form-control"
                          placeholder="{{line.code}}"
                          aria-describedby="basic-addon-search2"
                          [value]="line.code"
                          [(ngModel)]="line.code"
                          (ngModelChange)="changeOrderCode(line, orderCodeInput.value)"
                          [ngModelOptions]="{standalone: true}"
                          id="orderCodeInput"
                        />
                      </div>
                    </td>
                    <td *ngIf="currentInvoice.status === 'SENT'" colspan="3">
                      <span class="ml-1"> {{ line.description }}</span>
                    </td>
                    <td *ngIf="currentInvoice.status != 'SENT'" colspan="3">
                      <input
                        #orderDescriptionInput
                        type="text"
                        class="form-control"
                        placeholder="{{line.description}}"
                        aria-describedby="basic-addon-search2"
                        [value]="line.description"
                        [(ngModel)]="line.description"
                        (ngModelChange)="changeOrderDescription(line, orderDescriptionInput.value)"
                        [ngModelOptions]="{standalone: true}"
                        id="orderDescriptionInput"
                      />
                    </td>
                    <td colspan="4">

                    </td>
                    <td *ngIf="currentInvoice.status != 'SENT'" class="text-center overflow-hidden actions">
                      <button type="button" class="btn btn-icon btn-danger"
                              (click)="removeProduct(line, line)" rippleEffect>
                        <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="line.type === 'PRODUCT'" class="product-row">
                    <td *ngIf="currentInvoice.status === 'SENT'">{{ line.code }}</td>
                    <td *ngIf="currentInvoice.status != 'SENT'">
                      <input
                        #codeInput
                        type="text"
                        class="form-control"
                        placeholder="{{line.code}}"
                        aria-describedby="basic-addon-search2"
                        [value]="line.code"
                        [(ngModel)]="line.code"
                        (ngModelChange)="changeCode(line, codeInput.value)"
                        [ngModelOptions]="{standalone: true}"
                        id="code"
                      />
                    </td>
                    <td *ngIf="currentInvoice.status === 'SENT'">{{ line.description }}</td>
                    <td *ngIf="currentInvoice.status != 'SENT'">
                      <input
                        #descriptionInput
                        type="text"
                        class="form-control"
                        placeholder="{{line.description}}"
                        aria-describedby="basic-addon-search2"
                        [value]="line.description"
                        [(ngModel)]="line.description"
                        (ngModelChange)="changeDescription(line, descriptionInput.value)"
                        [ngModelOptions]="{standalone: true}"
                        id="description"
                      />
                    </td>
                    <td class="text-center">
                      <div class="quantity-controls d-flex align-items-center justify-content-center">
                        <button *ngIf="currentInvoice.status != 'SENT'" class="quantity-control-button"
                                (click)="changeQuantity(line, line, -1)">-
                        </button>
                        <span>{{ line.quantity }}</span>
                        <button *ngIf="currentInvoice.status != 'SENT'" class="quantity-control-button"
                                (click)="changeQuantity(line, line, 1)">+
                        </button>
                      </div>
                    </td>
                    <td *ngIf="currentInvoice.status === 'SENT'"
                        class="product-row-price">{{ line.price | price:'€ |.|,|3' }}
                    </td>
                    <td *ngIf="currentInvoice.status != 'SENT'" class="product-row-price col-1">
                      <input
                        #priceInput
                        type="text"
                        class="form-control"
                        placeholder="{{line.price | price:'€ |.|,|3'}}"
                        aria-describedby="basic-addon-search2"
                        [value]="line.price"
                        [(ngModel)]="line.price"
                        (ngModelChange)="changePrice(line, priceInput.value)"
                        [ngModelOptions]="{standalone: true}"
                        id="price"
                      />
                    <td>{{ line.unit }}</td>
                    <td>{{ line?.discount }}</td>
                    <td *ngIf="currentInvoice.status === 'SENT'">%{{ line.vat * 100 }}</td>
                    <td *ngIf="currentInvoice.status != 'SENT'" class="col-1">
                      <input
                        #vatInput
                        type="text"
                        class="form-control"
                        placeholder="%{{line.vat * 100}}"
                        aria-describedby="basic-addon-search2"
                        [value]="line.vat"
                        [(ngModel)]="line.vat"
                        (ngModelChange)="changeVat(line, vatInput.value)"
                        [ngModelOptions]="{standalone: true}"
                        id="vat"
                      />
                    <td class="product-row-price">{{ (line.price * line.quantity)| price }}</td>
                    <td *ngIf="currentInvoice.status != 'SENT'" class="text-center overflow-hidden actions">
                      <button type="button" class="btn btn-icon btn-danger"
                              (click)="removeProduct(line, line)" rippleEffect>
                        <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
                      </button>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>


<ng-template #addNewRowModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="changePassword-modal">Aggiungi riga</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Tipo di riga</label>
      <ng-select
        class="mb-2"
        [items]="rowTypes"
        [multiple]="false"
        [closeOnSelect]="true"
        [searchable]="true"
        [(ngModel)]="rowType"
        placeholder="Seleziona tipo di riga"
        (change)="changeRowType($event)"
      >
      </ng-select>
      <div *ngIf="rowType.value === 'PRODUCT'">
        <div class="form-group">
          <label>Codice prodotto</label>
          <input
            #newProductCodeInput
            type="text"
            class="form-control"
            placeholder="Codice prodotto"
            aria-describedby="basic-addon-search2"
            [(ngModel)]="newProductCode"
            (ngModelChange)="changeProductCodeModal(newProductCodeInput.value)"
            id="newProductCode"
          />
        </div>
        <div class="form-group">
          <label>Descrizione</label>
          <input
            #newProductDescriptionInput
            type="text"
            class="form-control"
            placeholder="Descrizione"
            aria-describedby="basic-addon-search2"
            [(ngModel)]="newProductDescription"
            (ngModelChange)="changeProductDescriptionModal(newProductDescriptionInput.value)"
            id="newProductDescription"
          />
        </div>
        <div class="form-group">
          <label>Quantità</label>
          <input
            #newProductQuantityInput
            type="number"
            class="form-control"
            placeholder="Quantità"
            aria-describedby="basic-addon-search2"
            [(ngModel)]="newProductQuantity"
            (ngModelChange)="changeProductQuantity(newProductQuantityInput.value)"
            id="newProductQuantity"
          />
        </div>
        <div class="form-group">
          <label>Prezzo</label>
          <input
            #newProductPriceInput
            type="text"
            class="form-control"
            placeholder="Prezzo"
            aria-describedby="basic-addon-search2"
            [(ngModel)]="newProductPrice"
            (ngModelChange)="changeProductPrice(newProductPriceInput.value)"
            id="newProductPrice"
          />
        </div>
        <div class="form-group">
          <label>Unità di misura</label>
          <input
            #newProductUnitInput
            type="text"
            class="form-control"
            placeholder="Unità di misura"
            aria-describedby="basic-addon-search2"
            [(ngModel)]="newProductUnit"
            (ngModelChange)="changeProductUnit(newProductUnitInput.value)"
            id="newProductUnit"
          />
        </div>
        <div class="form-group">
          <label>Sconto o maggiorazione</label>
          <input
            #newProductDiscountInput
            type="text"
            class="form-control"
            placeholder="Sconto o maggiorazione"
            aria-describedby="basic-addon-search2"
            [(ngModel)]="newProductDiscount"
            (ngModelChange)="changeProductDiscount(newProductDiscountInput.value)"
            id="newProductDiscount"
          />
        </div>
        <div class="form-group">
          <label>%IVA</label>
          <input
            #newProductVatInput
            type="text"
            class="form-control"
            placeholder="%IVA"
            aria-describedby="basic-addon-search2"
            [(ngModel)]="newProductVat"
            (ngModelChange)="changeProductVat(newProductVatInput.value)"
            id="newProductVat"
          />
        </div>
      </div>
      <div *ngIf="rowType.value === 'TEXT'">
        <div class="form-group">
          <label>Codice</label>
          <input
            #newTextCodeInput
            type="text"
            class="form-control"
            placeholder="Codice"
            aria-describedby="basic-addon-search2"
            [(ngModel)]="newTextCode"
            (ngModelChange)="changeTextCode(newTextCodeInput.value)"
            id="newTextCode"
          />
        </div>
        <div class="form-group">
          <label>Descrizione</label>
          <input
            #newTextDescriptionInput
            type="text"
            class="form-control"
            placeholder="Descrizione"
            aria-describedby="basic-addon-search2"
            [(ngModel)]="newTextDescription"
            (ngModelChange)="changeTextDescription(newTextDescriptionInput.value)"
            id="newTextDescription"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="addRow()">Aggiungi</button>
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>


