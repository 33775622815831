// Importing necessary modules and components from Angular and other libraries
import {
  Component,
  OnDestroy,
  OnInit,
  Inject,
  PLATFORM_ID,
  NgZone,
  TemplateRef, ElementRef
} from "@angular/core";
import {Subject} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {CustomersService} from "@core/services/customers.service";
import {ActivatedRoute, NavigationExtras, Params, Router} from "@angular/router";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {ShopService} from "@core/services/shop.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import Swal2 from "sweetalert2";
import * as IBAN from 'iban';
import notify from 'devextreme/ui/notify';



@Component({
  selector: 'app-businesses-edit',
  templateUrl: './businesses-edit.component.html',
  styleUrls: ['./businesses-edit.component.scss']
})
export class BusinessesEditComponent implements OnInit, OnDestroy {

  // Private variables
  private _unsubscribeAll: Subject<any>; // Subject to unsubscribe from subscriptions

  // Public variables
  public code: string = '';
  public business: any = {};
  public loaded: Promise<boolean> = Promise.resolve(false);
  public section: string = 'details';
  public status: string = '';
  public network: string = '';
  public paymentMethods: any = [];
  public walletTypes: any = [];
  public newWallet: any = {
    type: {
      label: "",
      min: 0,
      max: -1,
      currency: ""
    },
    balance: 0,
    total: 0,
    reason: '',
    business: this.business.id
  }
  public group: string = '';
  public walletHistoryArray: any = [];
  public permissions: any[] = [];
  public pagePermissions: {
    addresses: boolean;
    detailSection: boolean;
    paymentsSection: boolean;
    payments: boolean;
    wallets: boolean;
    personal: boolean;
    connectionsSection: boolean;
    contacts: boolean;
    users: boolean
  } = {
    detailSection: false,
    connectionsSection: false,
    paymentsSection: false,
    personal: false,
    users: false,
    contacts: false,
    addresses: false,
    payments: false,
    wallets: false,
  }
  public statuses = [
    {
      class: 'badge-light-warning',
      label: 'Creato',
      value: 'CREATED'
    },
    {
      class: 'badge-light-info',
      label: 'Verificato',
      value: 'VERIFIED'
    },
    {
      class: 'badge-light-primary',
      label: 'Completato',
      value: 'COMPLETED'
    },
    {
      class: 'badge-light-danger',
      label: 'Sospeso',
      value: 'SUSPENDED'
    },
    {
      class: 'badge-light-danger',
      label: 'Annullato',
      value: 'CANCELED'
    },
  ]
  public paymentColumns = [
    {
      width: 150,
      name: 'Codice',
      prop: 'code',
      type: 'text',
      sortable: false,
      resizable: true,
    },
    {
      width: 150,
      name: 'Metodo',
      prop: 'method',
      type: 'text',
      sortable: false,
      resizable: true,
    },
    {
      width: 150,
      name: 'Data',
      prop: 'createdAt',
      type: 'date-time',
      sortable: false,
      resizable: true,
    },
    {
      width: 100,
      name: 'Totale',
      prop: 'total',
      type: 'price',
      sortable: false,
      resizable: true,
    },
    {
      width: 100,
      name: 'Cashback',
      prop: 'cashback',
      type: 'price',
      sortable: false,
      resizable: true,
    },
  ]
  public paymentsMessages = {
    emptyMessage: 'Nessun pagamenti da visualizzare',
    totalMessage: 'totali',
    selectedMessage: 'selezionati',
    beforePageSizeMessage: 'Mostra',
    afterPageSizeMessage: 'pagamenti',
    textSearchMessage: 'Cerca',
    specificSearchMessage: 'Corrispondenza esatta',
    beforeAutoReloadMessage: 'Ricarica ogni',
    afterAutoReloadMessage: 'secondi',
  }

  public walletEdit: FormGroup = this._formBuilder.group({
    current: ['', Validators.required],
    total: ['', Validators.required],
    charge: ['', Validators.required]
  });


  public groups: any[] = []

  public networks = []
  public selectedWallet: any;

  constructor(
    // Injecting services and dependencies required for this component
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _customerService: CustomersService,
    private _route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _router: Router,
    private _authService: AuthenticationService,
    private elRef: ElementRef
  ) {
    // Initializing private variables and setting defaults in the constructor
    this._unsubscribeAll = new Subject(); // Initializing _unsubscribeAll as a Subject
    this._applicationService.layoutContentWidth(); // Setting layout content width
    this._applicationService.selectMenu('customers-businesses-list'); // Selecting a menu option

  }


  // Lifecycle hook - On initialization
  ngOnInit() {
    this.loaded = Promise.resolve(false);
    // Fetching the user's permissions
    this.pagePermissions = {
      detailSection: this._authService.checkPermission('admin.page.businessEdit.detailSection'),
      addresses: this._authService.checkPermission('admin.page.businessEdit.addresses'),
      contacts: this._authService.checkPermission('admin.page.businessEdit.contacts'),
      wallets: this._authService.checkPermission('admin.page.businessEdit.wallets'),
      payments: this._authService.checkPermission('admin.page.businessEdit.payments'),
      connectionsSection: this._authService.checkPermission('admin.page.businessEdit.connectionsSection'),
      personal: this._authService.checkPermission('admin.page.businessEdit.personal'),
      users: this._authService.checkPermission('admin.page.businessEdit.users'),
      paymentsSection: this._authService.checkPermission('admin.page.businessEdit.paymentsSection'),
    }

    this.walletEdit = this._formBuilder.group({
      charge: [0, Validators.required]
    });
    // Retrieving 'code' from the route or setting an empty string
    this.code = this._route.snapshot.paramMap.get('code') || '';

    // Fetching a single group from the customer service
    this._customerService.getSingleBusiness(this.code, {
      params: {
        populateFields: ['group', 'network']
      }
    })
      .then((response: any) => {
        this.business = response;
        console.log('business 208', this.business);
        this.business.addresses.forEach((address: any) => {
          if (!address.extra) {
            address.extra = {
              ovam: '',
              cati: {
                username: '',
                password: ''
              }
            }
          }
          if (!address.extra.ovam) {
            address.extra.ovam = '';
          }
          if (!address.extra.cati) {
            address.extra.cati = {
              username: '',
              password: ''
            }
          }
        })
        this._customerService.getUsersByBusiness(this.business.id)
          .then((response: any) => {
            this.business.users = response;
          });
        this._customerService.getWallets(this.code)
          .then((response: any) => {
            this.business.wallets = response;
            this.status = this.business.status;
            this.group = this.business.group.id;
            this.network = this.business.network.id;
          });
        this._customerService.getGroups()
          .then((response: any) => {
            this.groups = response.rows;
          });
        this._customerService.getNetworks()
          .then((response: any) => {
            this.networks = response.rows;
            console.log('networks', this.networks);
          });
        this._shopService.getPaymentMethodList()
          .then((paymentMethods) => {
            this.paymentMethods = paymentMethods.rows;
            console.log('paymentMethods', this.paymentMethods);
          })
          .catch((error: any) => {
            console.error('error', error);
          });
        this._shopService.getWalletTypes()
          .then((walletTypes) => {
            this.walletTypes = walletTypes.rows;
            console.log('walletTypes', this.walletTypes);
          })
          .catch((error: any) => {
            console.error('error', error);
          });
        this.loaded = Promise.resolve(true);
      });
  }

  // Lifecycle hook - On component destruction
  ngOnDestroy(): void {
    // Unsubscribing from all subscriptions to prevent memory leaks
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  dismiss() {
    window.history.back();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  selectedGroup: any;

  async getOrders(query?: any) {
    query = query || {};
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    query.filters = query.filters || {};
    query.filters.business = {"$oid": this.business.id}
    query['populateFields'] = ['supplier'];
    const orders: DynamicData = await this._customerService.getSingleBusinessOrders(this.code, query)
    orders.rows.forEach(order => {
      order.links = order.links || {};
      order.links.code = '/orders/' + order.code;
      order.products = order.products.reduce((acc: number, product: any) => {
        acc += product.total
        return acc
      }, 0);
      order.actions = order.actions || [];
      order.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/orders/' + order.code
      });
    })
    return orders
  }

  changeSection(section: string) {
    this.section = section;
  }


  saveDetails() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Questa operazione non è reversibile',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, modifica',
      cancelButtonText: 'No, annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('business 308', this.business);
        this.business.status = this.status;
        this.business.group = this.group;
        this.business.network = this.network;
        console.log('business 312', this.business);
        const body = {
          business: this.business,
        }
        this.editBusiness('changeAnagrafica', this.business.id, body);
        Swal.fire(
          'Modificato!',
          'Il business è stato modificato.',
          'success'
        )
        this._customerService.getSingleBusiness(this.code, {
          params: {
            populateFields: ['group', 'network']
          }
        })
          .then((response: any) => {
            this.business = response; // Setting the fetched response to the 'group' variable
          });
        const navigationExtras: NavigationExtras = {
          queryParams: {timestamp: new Date().getTime()}, // Add a unique query param
          skipLocationChange: true // Avoid updating the browser URL
        };
        this._customerService.getSingleBusiness(this.code, {
          params: {
            populateFields: ['group', 'network']
          }
        })
          .then((response: any) => {
            this.business = response;
          });
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Annullato',
          'Il business non è stato modificato',
          'error'
        )
      }
    })

  }


  editBusiness(type: string, id: string, body: any) {
    let allowedFields: string[] = [];
    if (type === 'newWallet') {
      allowedFields = ['wallets'];
    }
    if (type === 'deleteWallet') {
      allowedFields = ['wallets'];
    }
    if (type === 'newContact') {
      allowedFields = ['contacts'];
    }
    if (type === 'newPayment') {
      allowedFields = ['payments'];
    }
    if (type === 'deletePayment') {
      allowedFields = ['payments'];
    }
    if (type === 'deleteContact') {
      allowedFields = ['contacts'];
    }
    if (type === 'newAddress') {
      allowedFields = ['addresses'];
    }
    if (type === 'deleteAddress') {
      allowedFields = ['addresses'];
    }
    if (type === 'changeAnagrafica') {
      allowedFields = ['name', 'vat', 'status', 'pec', 'group', 'network', 'uniqueCode', 'fiscalCode', 'location'];
    }
    const filteredBusiness = Object.keys(body.business)
      .filter(key => allowedFields.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = body.business[key];
        return obj;
      }, {});

    this._customerService.editBusiness(id, filteredBusiness)
      .then((response: any) => {
        this.business = response;
        this._customerService.getSingleBusiness(this.code, {
          params: {
            populateFields: ['group', 'network']
          }
        })
          .then((response: any) => {
            this.business = response;
          });
      });
  }

  changeStatus($event: any) {
    this.status = $event.value;
  }

  changeGroup($event: any) {
    this.group = $event.id;
    console.log('group', this.group);
  }

  changeNetwork($event: any) {
    this.network = $event.id;
    console.log('network', this.network);
  }

  async getPayments(query?: any) {
    query = query || {};
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    query.filters = query.filters || {};
    query.filters.business = {"$oid": this.business.id}
    query['populateFields'] = ['method'];
    const payments: DynamicData = {
      rows: [],
      total: this.business.payments.length,
      totalPages: this.business.payments.length / 10,
      page: 1,
      pageSize: 10,
    };
    payments.rows = this.business.payments || [];
    payments.rows.forEach(payment => {
      payment.method = null;
    })
    payments.rows.forEach(payment => {
      this._customerService.getItemById('payment', payment._id)
        .then((response: any) => {
          payment.code = response.code;
        })
      this._customerService.getItemById('paymentmethod', payment.method)
        .then((response: any) => {
          payment.method = response.name;
        })
      payment.links = payment.links || {};
      payment.links.code = '/payments/' + payment.code;
      payment.actions = payment.actions || [];
      payment.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/payments/' + payment.code
      });
    })
    console.log('payments', payments)
    return payments
  }

  public changeTimeout: any;

  onChangeWallet(type: string, $event: any) {
    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }
    this.changeTimeout = setTimeout(() => {
      this.updateWallet(type, $event);
    }, 150);
  }

  updateWallet(type: string, $event: any) {
    if (type === 'charge') {
      this.selectedWallet.charge = $event.target.value;
      this.selectedWallet.total = parseFloat(this.selectedWallet.balance) + parseFloat($event.target.value || 0);
    } else if (type === 'total') {
      this.selectedWallet.total = $event.target.value;
      this.selectedWallet.charge = parseFloat(this.selectedWallet.total || 0) - parseFloat(this.selectedWallet.balance);
    }
  }

  editWallet(walletModal: TemplateRef<any>, wallet: any) {
    wallet.balance = parseFloat(wallet.balance.toFixed(2));
    wallet.total = parseFloat(wallet.balance.toFixed(2));
    wallet.charge = 0;
    this.selectedWallet = wallet;

    return this.modalService.open(walletModal, {
      size: 'm',
      windowClass: 'modal modal-primary'
    });
  }

  showWalletHistory(walletModal: TemplateRef<any>, wallet: any) {
    console.log(wallet)
    console.log(this.code)
    this._customerService.getBusinessWalletHistory(this.code, wallet._id)
      .then((response: any) => {
        if (response.rows?.length > 0) {
          response.rows.map((row: any) => {
            if (parseInt(row.code) != row.code) {
              return;
            }
            row.code = `T-${row.code.padStart(8, '0')}`
          })
          this.walletHistoryArray = response.rows;
          this.modalService.open(walletModal, {
            size: 'lg',
            windowClass: 'modal modal-primary'
          });
        } else {
          Swal.fire(
            'Errore!',
            'Non ci sono movimenti per questo wallet',
            'warning'
          )
        }
      });

  }

  onSubmit() {
    if (!this.selectedWallet.reason) {
      Swal.fire(
        'Errore!',
        'Inserisci una motivazione',
        'error'
      )
      return;
    }
    this._customerService
      .setBusinessWallet(this.code, this.selectedWallet._id, parseFloat(this.selectedWallet.charge), this.selectedWallet.reason)
      .then((response: any) => {
        this._customerService.getWallets(this.code)
          .then((response: any) => {
            this.business.wallets = response;
            this.status = this.business.status;
            this.group = this.business.group.id;
            this.network = this.business.network.id;
          });

        Swal.fire(
          'Modificato!',
          'Il wallet è stato modificato.',
          'success'
        )
      });
  }

  addContact() {
    const contact = {
      name: '',
      email: '',
      phone: '',
    }
    this.business.contacts.push(contact);
    this.editBusiness('newContact', this.business.id, {business: this.business});
    Swal2.fire(
      'Aggiunto!',
      'Il contatto è stato aggiunto.',
      'success'
    )
    this._customerService.getSingleBusiness(this.code, {
      params: {
        populateFields: ['group', 'network']
      }
    })
      .then((response: any) => {
        this.business = response;
        console.log('business', this.business);
        this._customerService.getWallets(this.code)
          .then((response: any) => {
            this.business.wallets = response;
            console.log('wallets', response)
            this.status = this.business.status;
            console.log('status', this.status);
            this.group = this.business.group.id;
            console.log('group', this.group)
            this.network = this.business.network.id;
            console.log('network', this.network);
          });
        this._customerService.getGroups()
          .then((response: any) => {
            this.groups = response.rows;
            console.log('groups', this.groups);
          });
        this._customerService.getNetworks()
          .then((response: any) => {
            this.networks = response.rows;
            console.log('networks', this.networks);
          });
      });
  }

  addAddress() {
    const address = {
      street: '',
      number: '',
      city: '',
      zip: '',
      region: '',
      province: '',
      country: '',
      extra: {
        cati: {
          username: '',
          password: ''
        },
        ovam: ''
      }
    }
    this.business.addresses.push(address);
    this.editBusiness('newAddress', this.business.id, {business: this.business});
    Swal2.fire(
      'Aggiunto!',
      'L\'indirizzo è stato aggiunto.',
      'success'
    )
    this._customerService.getSingleBusiness(this.code, {
      params: {
        populateFields: ['group', 'network']
      }
    })
      .then((response: any) => {
        this.business = response;
        console.log('business', this.business);
        this._customerService.getWallets(this.code)
          .then((response: any) => {
            this.business.wallets = response;
            console.log('wallets', response)
            this.status = this.business.status;
            console.log('status', this.status);
            this.group = this.business.group.id;
            console.log('group', this.group)
            this.network = this.business.network.id;
            console.log('network', this.network);
          });
        this._customerService.getGroups()
          .then((response: any) => {
            this.groups = response.rows;
            console.log('groups', this.groups);
          });
        this._customerService.getNetworks()
          .then((response: any) => {
            this.networks = response.rows;
            console.log('networks', this.networks);
          });

      });
  }

  deleteAddress(i: number) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Questa operazione non è reversibile',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, elimina',
      cancelButtonText: 'No, annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.business.addresses.splice(i, 1);
        this.editBusiness('deleteAddress', this.business.id, {business: this.business});
        Swal.fire(
          'Eliminato!',
          'L\'indirizzo è stato eliminato.',
          'success'
        )
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Annullato',
          'L\'indirizzo non è stato eliminato',
          'error'
        )
      }
    })
  }

  deleteContact(i: number) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Questa operazione non è reversibile',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, elimina',
      cancelButtonText: 'No, annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.business.contacts.splice(i, 1);
        this.editBusiness('deleteContact', this.business.id, {business: this.business});
        Swal.fire(
          'Eliminato!',
          'Il contatto è stato eliminato.',
          'success'
        )
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Annullato',
          'Il contatto non è stato eliminato',
          'error'
        )
      }
    })
  }

  saveContact(i: number) {
    Swal2.fire({
      title: 'Sei sicuro?',
      text: 'Questa operazione non è reversibile',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, modifica',
      cancelButtonText: 'No, annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.editBusiness('newContact', this.business.id, {business: this.business});
        Swal2.fire(
          'Modificato!',
          'Il contatto è stato modificato.',
          'success'
        )
      } else if (
        result.dismiss === Swal2.DismissReason.cancel
      ) {
        Swal2.fire(
          'Annullato',
          'Il contatto non è stato modificato',
          'error'
        )
      }
    })
  }

  searchMethod($event: KeyboardEvent) {
    const value = ($event.target as HTMLInputElement).value;
    this.paymentMethods = this.paymentMethods.filter((method: {
      name: string;
    }) => method.name.toLowerCase().includes(value.toLowerCase()));
  }


  addPayment() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Questa operazione non è reversibile',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, aggiungi',
      cancelButtonText: 'No, annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const payment = {
          method: "",
          iban: "",
          abi: "",
          cab: "",
          bic: "",
          deleted: false,
          active: false,
          expiration: false,
          label: "",
          total: 0,
          cashback: 0
        }
        this.business.payments.push(payment);
        this.editBusiness('newPayment', this.business.id, {business: this.business});
        Swal.fire(
          'Aggiunto!',
          'Il pagamento è stato aggiunto.',
          'success'
        )
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Annullato',
          'Il pagamento non è stato aggiunto',
          'error'
        )
      }
    })
  }


  savePayment(i: number) {
    this.editBusiness('newPayment', this.business.id, {business: this.business});
    Swal2.fire(
      'Modificato!',
      'Il pagamento è stato modificato.',
      'success'
    )
  }


  saveAddress(i: number) {
    this.editBusiness('newAddress', this.business.id, {business: this.business});
    Swal2.fire(
      'Modificato!',
      'L\'indirizzo è stato modificato.',
      'success'
    )
    this._customerService.getSingleBusiness(this.code, {
      params: {
        populateFields: ['group', 'network']
      }
    })
      .then((response: any) => {
        this.business = response;
        console.log('business', this.business);
        this._customerService.getWallets(this.code)
          .then((response: any) => {
            this.business.wallets = response;
            console.log('wallets', response)
            this.status = this.business.status;
            console.log('status', this.status);
            this.group = this.business.group.id;
            console.log('group', this.group)
            this.network = this.business.network.id;
            console.log('network', this.network);
          });
        this._customerService.getGroups()
          .then((response: any) => {
            this.groups = response.rows;
            console.log('groups', this.groups);
          });
        this._customerService.getNetworks()
          .then((response: any) => {
            this.networks = response.rows;
            console.log('networks', this.networks);
          });

      });
  }

  deletePayment(i: number) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Questa operazione non è reversibile',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, elimina',
      cancelButtonText: 'No, annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.business.payments.splice(i, 1);
        this.editBusiness('deletePayment', this.business.id, {business: this.business});
        Swal.fire(
          'Eliminato!',
          'Il pagamento è stato eliminato.',
          'success'
        )
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Annullato',
          'Il pagamento non è stato eliminato',
          'error'
        )
      }
    })
  }

  deleteWallet(i: number) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Questa operazione non è reversibile',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, elimina',
      cancelButtonText: 'No, annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.business.wallets.splice(i, 1);
        this.editBusiness('deleteWallet', this.business.id, {business: this.business});
        Swal.fire(
          'Eliminato!',
          'Il wallet è stato eliminato.',
          'success'
        )
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Annullato',
          'Il wallet non è stato eliminato',
          'error'
        )
      }
    })
  }

  searchWalletType($event: KeyboardEvent) {
    const value = ($event.target as HTMLInputElement).value;
    this.walletTypes = this.walletTypes.filter((type: {
      label: string;
    }) => type.label.toLowerCase().includes(value.toLowerCase()));
  }

  addWallet(newWalletModal: TemplateRef<any>) {
    this.newWallet = {
      type: {
        label: "",
        min: 0,
        max: -1,
        currency: ""
      },
      balance: 0,
      total: 0,
      reason: '',
      business: this.business.id
    }
    return this.modalService.open(newWalletModal, {
      size: 'm',
      windowClass: 'modal modal-primary'
    });
  }

  onSubmitWallet() {
    if (!this.newWallet.type.label) {
      Swal.fire(
        'Errore!',
        'Seleziona un tipo di wallet',
        'error'
      )
      return;
    } else {
      this.business.wallets.push(this.newWallet);
      this.editBusiness('newWallet', this.business.id, {business: this.business});
      this.modalService.dismissAll();
    }
  }

  scrollToElement(targetId: string) {
    const targetElement = this.elRef.nativeElement.querySelector(`#${targetId}`);
    if (targetElement) {
      targetElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
  }

  backToList() {
    this._router.navigate(['/businesses']);
  }

  backToViewPage() {
    this._router.navigate(['/businesses/', this.code]);
  }

  isValidIBAN(iban: string, payment: any) {
    if (IBAN.isValid(iban)) {
      this.getCABAndABIFromIBAN(iban, payment);
    }
    else {
      payment.cab = '';
      payment.abi = '';
    }
    return IBAN.isValid(iban);
  }

  getCABAndABIFromIBAN(iban: string, payment: any) {
    const countryToPositions: CountryPositions = {
      'AL': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 4},
      'AD': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 4},
      'AT': {CABStartPosition: 4, CABLength: 5, ABIStartPosition: 9, ABILength: 11},
      'AZ': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 20},
      'BH': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 14},
      'BY': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 4},
      'BE': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 4},
      'BA': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 3},
      'BR': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 8},
      'BG': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 4},
      'HR': {CABStartPosition: 4, CABLength: 7, ABIStartPosition: 11, ABILength: 10},
      'CY': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 5},
      'CZ': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 16},
      'DK': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 10},
      'EE': {CABStartPosition: 4, CABLength: 2, ABIStartPosition: 6, ABILength: 16},
      'FO': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 9},
      'FI': {CABStartPosition: 4, CABLength: 6, ABIStartPosition: 10, ABILength: 8},
      'FR': {CABStartPosition: 4, CABLength: 5, ABIStartPosition: 9, ABILength: 11},
      'GE': {CABStartPosition: 4, CABLength: 2, ABIStartPosition: 6, ABILength: 16},
      'DE': {CABStartPosition: 4, CABLength: 8, ABIStartPosition: 12, ABILength: 10},
      'GI': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 15},
      'GR': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 16},
      'GL': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 9},
      'GT': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 4},
      'HU': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 4},
      'IS': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 18},
      'IE': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 6},
      'IL': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 13},
      'IT': {CABStartPosition: 5, CABLength: 5, ABIStartPosition: 10, ABILength: 5},
      'KZ': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 13},
      'KW': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 22},
      'LV': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 13},
      'LB': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 20},
      'LI': {CABStartPosition: 4, CABLength: 5, ABIStartPosition: 9, ABILength: 12},
      'LT': {CABStartPosition: 4, CABLength: 5, ABIStartPosition: 9, ABILength: 11},
      'LU': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 13},
      'MK': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 10},
      'MT': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 5},
      'MR': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 13},
      'MU': {CABStartPosition: 4, CABLength: 6, ABIStartPosition: 10, ABILength: 2},
      'MD': {CABStartPosition: 4, CABLength: 2, ABIStartPosition: 6, ABILength: 18},
      'MC': {CABStartPosition: 4, CABLength: 5, ABIStartPosition: 9, ABILength: 5},
      'ME': {CABStartPosition: 4, CABLength: 3, ABIStartPosition: 7, ABILength: 13},
      'NL': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 10},
      'NO': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 7},
      'PK': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 16},
      'PS': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 10},
      'PL': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 16},
      'PT': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 4},
      'QA': {CABStartPosition: 4, CABLength: 4, ABIStartPosition: 8, ABILength: 21}
    };

    const countryCode = iban.substring(0, 2);
    if (countryCode in countryToPositions) {
      const {CABStartPosition, CABLength, ABIStartPosition, ABILength} = countryToPositions[countryCode];

      const CAB = iban.substring(CABStartPosition, CABStartPosition + CABLength);
      const ABI = iban.substring(ABIStartPosition, ABIStartPosition + ABILength);
      payment.cab = CAB;
      payment.abi = ABI;
    }
  }

  copyToClipboard(value: any) {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    notify('Copiato negli appunti', 'success', 3000);
  }
}

interface CountryPositions {
  [key: string]: {
    CABStartPosition: number;
    CABLength: number;
    ABIStartPosition: number;
    ABILength: number;
  };
}
