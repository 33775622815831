import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {CustomersService} from "../../../../@core/services/customers.service";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-menuItems-list',
  templateUrl: './menuItems-list.component.html',
  styleUrls: ['./menuItems-list.component.scss']
})

export class MenuItemsListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  public tableConfig: TableConfig = {} as TableConfig;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _customerService: CustomersService,
    private _dynamicTableService: DynamicTableService,
    protected _authService: AuthenticationService,
    private modalService: NgbModal,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('super-menu-items-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('menuItems')
      .then((table) => {
        console.log("table",table);
        this.tableConfig = table;
      } );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  async getMenus(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge',
      }
    );
    query['populateFields'] = [];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const menus: DynamicData = await this._applicationService.getMenuItemsList(query);
    menus.rows.forEach(menu => {
      menu.actions = menu.actions || [];
      menu.childrenLength = menu.childrenLength || menu.children.length;
      menu.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/menus/' + menu.code
      });
    })

    console.log("menus",menus, menus.rows.length);
    return menus;
  }
}
