import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CustomersService} from "@core/services/customers.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {FormBuilder, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableConfig} from "../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../@core/services/dynamicTable.service";

@Component({
  selector: 'app-mail-list',
  templateUrl: './mails-list.component.html',
  styleUrls: ['./mails-list.component.scss']
})

export class MailsListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  public loaded: Promise<boolean> = Promise.resolve(false);


  public tableConfig : TableConfig ={} as TableConfig;


  public newEmail: {
    data: {
      name: string,
      email: string,
    },
    subject: string,
    template: string,
    recipients : [
      {
        name: string,
        email: string,
      }
    ];
    status: string,
    createdAt: string,
  } = {
    data: {
      name: '',
      email: '',
    },
    subject: '',
    template: '',
    recipients : [
      {
        name: '',
        email: '',
      }
    ],
    status: '',
    createdAt: '',
  }



  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private modalService: NgbModal,
    private _applicationService: ApplicationService,
    private _customerService: CustomersService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _dynamicTableService: DynamicTableService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('mail');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loaded = Promise.resolve(false);
    this._dynamicTableService.getDynamicTable('mails')
      .then((table) => {
        this.tableConfig = table;
        console.log("config", this.tableConfig)
        this.loaded = Promise.resolve(true);
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  async getMails(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['network', 'group'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const mails: DynamicData = await this._customerService.getMailList(query)
    mails.rows.forEach(mail => {
      mail.links = mail.links || {};
      mail.links.code = '/mails/' + mail.id;
      mail.actions = mail.actions || [];
      mail.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/mails/' + mail.id
      });
      mail.mail = mail.template.substring(0, 45) + '...';
      mail.recipient = mail.recipients.map((recipient: { email: any; }) => recipient.email).join('; ').substring(0, 45) + '...';
    })
    return mails
  }

  addMail(addNewMailModal: TemplateRef<any>) {
    this.modalService.open(addNewMailModal, {size: 'lg'});
  }

  addRecipient() {
    this.newEmail.recipients.push({
      name: '',
      email: ''
    })
  }

  sendMail() {
    this._customerService.sendMail(this.newEmail)
      .then((mail) => {
        this.modalService.dismissAll();
      })
  }
}
