import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { OrdersListComponent } from "./orders-list/orders-list.component";
import { CoreModule } from "@core/components/core.module";
import { OrdersViewComponent } from "./order-view/order-view.component";
import { NgbInputDatepicker, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
const routes: Routes = [
  {
    path: 'orders',
    component: OrdersListComponent,
    data: { animation: 'OrdersListComponent' }
  },
  {
    path: 'orders/:code/:year',
    component: OrdersViewComponent,
    data: { animation: 'OrdersViewComponent' }
  },
];

@NgModule({
  declarations: [
    OrdersListComponent,
    OrdersViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    NgbInputDatepicker,
    ReactiveFormsModule,
    NgbTooltip,
    FormsModule,
    NgSelectModule
  ],
  providers: []
})
export class OrdersModule {
}
