import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BusinessesListComponent } from "./businesses-list/businesses-list.component";
import { BusinessesViewComponent } from "./businesses-view/businesses-view.component";
import { CoreModule } from "@core/components/core.module";
import { BusinessesEditComponent } from "./businesses-edit/businesses-edit.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

const routes: Routes = [
  {
    path: 'businesses',
    component: BusinessesListComponent,
    data: { animation: 'BusinessesListComponent' }
  },
  {
    path: 'businesses/:code',
    component: BusinessesViewComponent,
    data: { animation: 'BusinessesViewComponent' }
  },
  {
    path: 'businesses/:code/edit',
    component: BusinessesEditComponent,
    data: { animation: 'BusinessesEditComponent' }
  },
];

@NgModule({
  declarations: [
    BusinessesListComponent,
    BusinessesViewComponent,
    BusinessesEditComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbTooltip
  ],
  providers: []
})
export class BusinessesModule {
}
