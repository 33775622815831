<div class="col-12 mb-1 d-flex justify-content-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <h1 class="font-medium-5 px-025 my-1">Regole di Spedizione</h1>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button *ngIf="_authService.checkPermission('admin.page.shippingRulesList.upload')" class="btn btn-primary mr-1 px-075 pr-05" (click)="openImportModal(importModal)">
      <app-svg-icon class="d-inline-block align-middle" name="upload"></app-svg-icon>
    </button>
    <button *ngIf="_authService.checkPermission('admin.page.shippingRulesList.download')" class="btn btn-primary px-075 pr-05" (click)="openExportModal(exportModal)">
      <app-svg-icon class="d-inline-block align-middle" name="download"></app-svg-icon>
    </button>
  </div>
</div>
<div class="glassy">
  <dynamic-table
    [dataRetriever]="getShippingRules.bind(this)"
    [columns]="tableConfig.columns"
    [filterGroups]="tableConfig.filterGroups"
    [page]="tableConfig.pagination.page"
    [pageSize]="tableConfig.pagination.pageSize"
    [sort]="tableConfig.pagination.sort"
    [search]="tableConfig.pagination.search"
    [messages]="tableConfig.messages"
  >
  </dynamic-table>
</div>


<ng-template #importModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="import-modal">Esporta Regole di Spedizione</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group mb-1">
      <label for="warehouse-import">Magazzino</label>
      <ng-select [items]="warehouseList"
                 [(ngModel)]="actionWarehouse"
                 name="warehouse"
                 id="warehouse-import">
      </ng-select>
    </div>
    <div class="form-group">
      <label for="warehouse-import">File</label>
      <div class="custom-file">
        <div id="drop-area" class="dropzone border rounded d-flex justify-content-center align-items-center"
             (click)="fileInput.click()"
             [class.dragover]="dragover"
             style="height: 200px; cursor: pointer">
          <div class="text-center" *ngIf="currentFile == null">
            <app-svg-icon class="d-inline-block align-middle font-large-2" name="upload-cloud"></app-svg-icon>
            <p class="mt-1">
              Trascina qui il file o clicca per selezionare
            </p>
          </div>
          <div class="text-center" *ngIf="currentFile != null">
            <app-svg-icon class="d-inline-block align-middle font-large-2 text-success" name="check"></app-svg-icon>
            <p class="mt-1 text-success">
              {{currentFile.name}}
            </p>
            <p class="mt-1">
              <a href="javascript:void(0)" class="text-danger" (click)="removeFile();$event.stopPropagation()">
                <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
                Rimuovi
              </a>
            </p>
          </div>
        </div>
        <input type="file" id="fileElem" class="d-none" #fileInput (change)="fileSelected($event)"/>
        <div id="gallery"></div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-success" (click)="importShippingRules();modal.dismiss()">Scarica</button>
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>

<ng-template #exportModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="export-modal">Esporta Regole di Spedizione</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="warehouse-export">Magazzino</label>
      <ng-select [items]="warehouseList"
                 [(ngModel)]="actionWarehouse"
                 name="warehouse"
                 id="warehouse-export">
      </ng-select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-success" (click)="exportShippingRules();modal.dismiss()">Scarica</button>
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Chiudi</button>
  </div>
</ng-template>
