<!-- business-profile.component.html -->
<script src="invoice-new.component.ts"></script>
<div class="row">
  <div class="col-3 d-flex">
    <div class="card glassy full-width card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title text-primary">
          <button type="button" class="btn btn-primary btn-sm align-middle p-025 font-small-2 mr-05"
                  (click)="openTrasnittenteModal(trasmittenteModal)">
            <app-svg-icon class="d-inline-block align-middle no-margin" name="edit"></app-svg-icon>
          </button>
          <span class="align-middle">{{ newInvoice.body.invoiceData.number || '-' }}
            / {{ newInvoice.body.invoiceData.date | date: 'yyyy' }}</span>
        </h4>
      </div>
      <div class="card-body pt-025 pb-0">
        <label class="text-primary">Tipo documento</label>
        <p class="card-text mb-05">{{ newInvoice.header.transmitter.transmissionFormat }}</p>
        <ng-container *ngFor="let documentType of TipoDocumentoOptions">
          <p class="card-text mb-0" *ngIf="documentType.value === newInvoice.body.invoiceData.documentType">
            {{ documentType.label }}
          </p>
        </ng-container>
        <label class="text-primary">Trasmittente</label>
        <p
          class="card-text mb-05">{{ newInvoice.header.transmitter.idTransmitter.country }}  {{ newInvoice.header.transmitter.idTransmitter.fiscal }}</p>
        <label class="text-primary">Progressivo</label>
        <p class="card-text mb-05">{{ newInvoice.header.transmitter.sendingProgress }}</p>
        <label class="text-primary">Destinatario</label>
        <p class="card-text mb-05"> {{ newInvoice.header.transmitter.recipientCode || '-' }}
          / {{ newInvoice.header.transmitter.recipientPEC.value || '-' }}</p>
      </div>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="card glassy full-width card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title text-primary">
          <span class="align-middle">Totale</span>
        </h4>
      </div>
      <div class="card-body pt-025 pb-0">
      </div>
    </div>
  </div>

  <div class="col-3 d-flex">
    <div class="card full-width">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title text-primary">Commenti</h5>
        <div class="comment-container">
          <ng-container *ngIf="comments.length > 0">
            <ul class="list-unstyled">
              <li *ngFor="let comment of comments" class="mb-1">
                <div class="">
                  <div class="comment-text">
                    <p class="card-text mb-0">
                      {{ comment.text }}
                    </p>
                  </div>
                  <div class="comment-user mt-05">
                    <span class="avatar mr-1">
                      <img
                        [src]="comment.user.avatar || 'assets/images/avatars/default-avatar.png'"
                        (error)="comment.user.avatar = 'assets/images/avatars/default-avatar.png'"
                        alt="avatar"
                        class="rounded-circle"
                        width="18"
                        height="18"
                      >
                    </span>
                    <span class="font-small-2 font-weight-bold mr-1">{{ comment.user.name }}</span>
                    <span class="font-small-2">{{ comment.createdAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="comments?.length == 0">
            <p>Nessun commento trovato</p>
          </ng-container>
        </div>
      </div>
      <div class="card-footer">
        <div class="form-group">
          <div class="input-group">
            <textarea class="form-control" id="comment" rows="2"
                      [(ngModel)]="commentText" name="comment"
                      (keydown.control.enter)="addComment()"></textarea>
            <button class="btn btn-outline-primary px-1" (click)="addComment()">
              <app-svg-icon class="d-inline-block align-middle" name="send"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-2">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title text-primary">Azioni</h4>
      </div>
      <div class="card-body pt-05 pb-0 d-flex flex-column justify-content-center overflow-visible">
        <button class="btn btn-warning btn-block mb-1" routerLink="./edit">
          <app-svg-icon class="d-inline-block align-middle" name="edit"></app-svg-icon>
          <span class="align-middle">Modifica</span>
        </button>
        <button class="btn btn-primary btn-block mb-1" routerLink="./edit">
          <app-svg-icon class="d-inline-block align-middle" name="file-xml"></app-svg-icon>
          <span class="align-middle">Scarica</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-5">
    <div class="card glassy card-full-height">
      <div
        *ngIf="newInvoice.header.sellerProvider.providerId.fiscal !== '' || newInvoice.header.sellerProvider.taxCode.active"
        class="card-header pb-0">
        <h4 class="card-title text-primary">
          <button type="button" class="btn btn-primary btn-sm align-middle p-025 font-small-2 mr-05"
                  (click)="openSellerproviderModal(cedentePrestatoreModal)">
            <app-svg-icon class=" d-inline-block align-middle no-margin
          " name="edit"></app-svg-icon>
          </button>
          <span class="align-middle">Cedente / Prestatore</span>
        </h4>
      </div>
      <div
        *ngIf="newInvoice.header.sellerProvider.providerId.fiscal === '' && !newInvoice.header.sellerProvider.taxCode.active"
        class="card-header pb-0">
        <h4 class="card-title text-primary">
          <span class="align-middle">Cedente / Prestatore</span>
        </h4>
      </div>
      <div
        *ngIf="newInvoice.header.sellerProvider.providerId.fiscal !== '' || newInvoice.header.sellerProvider.taxCode.active"
        class="card-body pt-025 pb-0">
        <ng-container
          *ngIf="newInvoice.header.sellerProvider.personalData.denominazione.active &&
          newInvoice.header.sellerProvider.personalData.denominazione.value !== '' &&
          newInvoice.header.sellerProvider.personalData.name.active &&
          newInvoice.header.sellerProvider.personalData.name.value !== '' &&
          newInvoice.header.sellerProvider.personalData.surname.active &&
          newInvoice.header.sellerProvider.personalData.surname.value !== ''">
          <h5 class="mb-0">{{ newInvoice.header.sellerProvider.personalData.denominazione.value }}
            / {{ newInvoice.header.sellerProvider.personalData.name.value }} {{ newInvoice.header.sellerProvider.personalData.surname.value }}</h5>
        </ng-container>
        <ng-container
          *ngIf="newInvoice.header.sellerProvider.personalData.denominazione.active &&
          newInvoice.header.sellerProvider.personalData.denominazione.value !== '' &&
          !newInvoice.header.sellerProvider.personalData.name.active &&
          newInvoice.header.sellerProvider.personalData.name.value === '' &&
          !newInvoice.header.sellerProvider.personalData.surname.active &&
          newInvoice.header.sellerProvider.personalData.surname.value === ''">
          <h5 class="mb-0">{{ newInvoice.header.sellerProvider.personalData.denominazione.value }}</h5>
        </ng-container>
        <ng-container
          *ngIf="!newInvoice.header.sellerProvider.personalData.denominazione.active &&
          newInvoice.header.sellerProvider.personalData.denominazione.value === '' &&
          newInvoice.header.sellerProvider.personalData.name.active &&
          newInvoice.header.sellerProvider.personalData.name.value !== '' &&
          newInvoice.header.sellerProvider.personalData.surname.active &&
          newInvoice.header.sellerProvider.personalData.surname.value !== ''">
          <h5
            class="mb-0">{{ newInvoice.header.sellerProvider.personalData.name.value }} {{ newInvoice.header.sellerProvider.personalData.surname.value }}</h5>
        </ng-container>
        <ng-container *ngIf="newInvoice.header.sellerProvider.providerId.fiscal !== '' &&
        newInvoice.header.sellerProvider.taxCode.active &&
        newInvoice.header.sellerProvider.taxCode.value !==''">
          <span
            class="mb-05">{{ newInvoice.header.sellerProvider.providerId.country }} {{ newInvoice.header.sellerProvider.providerId.fiscal }}
            / {{ newInvoice.header.sellerProvider.taxCode.value }}</span>
        </ng-container>
        <div class="row">
          <div class="col-2 text-primary"><label class="text-primary">Sede</label></div>
          <div
            class="col-10">{{ newInvoice.header.sellerProvider.address.street }} {{ newInvoice.header.sellerProvider.address.streetNumber }}
            , {{ newInvoice.header.sellerProvider.address.postalCode }} {{ newInvoice.header.sellerProvider.address.city }} {{ newInvoice.header.sellerProvider.address.province.value }} {{ newInvoice.header.sellerProvider.address.country }}
          </div>
          <div class="col-2 text-primary"><label class="text-primary">Stabile</label></div>
          <div
            class="col-10">{{ newInvoice.header.sellerProvider.permanentEstablishment.street }} {{ newInvoice.header.sellerProvider.permanentEstablishment.streetNumber.value }}
            , {{ newInvoice.header.sellerProvider.permanentEstablishment.postalCode }} {{ newInvoice.header.sellerProvider.permanentEstablishment.city }} {{ newInvoice.header.sellerProvider.permanentEstablishment.province.value }} {{ newInvoice.header.sellerProvider.permanentEstablishment.country }}
          </div>
          <div class="col-2 text-primary"><label class="text-primary">Regime</label></div>
          <ng-container *ngFor="let fiscalRegime of RegimeFiscaleOptions">
            <div class="col-10"
                 *ngIf="fiscalRegime.value === newInvoice.header.sellerProvider.personalData.fiscalRegime">
              {{ fiscalRegime.label }}
            </div>
          </ng-container>
          <div class="col-2 text-primary"><label class="text-primary">Telefono</label></div>
          <div class="col-3">{{ newInvoice.header.sellerProvider.providerContacts.phone.value || '-' }}</div>
          <div class="col-2 text-primary"><label class="text-primary">Email</label></div>
          <div class="col-5">{{ newInvoice.header.sellerProvider.providerContacts.email.value || '-' }}</div>
        </div>
      </div>
      <div
        *ngIf="newInvoice.header.sellerProvider.providerId.fiscal === '' && !newInvoice.header.sellerProvider.taxCode.active"
        class="card-body pt-025 pb-0">
        <button class="btn btn-primary btn-block" (click)="openSellerproviderModal(cedentePrestatoreModal)">
          <span class="align-middle">Aggiungi</span>
          <app-svg-icon class="d-inline-block align-middle ml-05" name="add"></app-svg-icon>
        </button>
        <button class="btn btn-primary btn-block ml-1" (click)="openSellerproviderModal(customerSelectionCedenteModal, 'md')">
          <span class="align-middle">Scegli azienda</span>
          <app-svg-icon class="d-inline-block align-middle ml-05" name="add"></app-svg-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-5">
    <div class="card glassy card-full-height">
      <div
        *ngIf="newInvoice.header.assigneeClient.assigneeId.fiscal !== '' ||  newInvoice.header.assigneeClient.taxCode.active"
        class="card-header pb-0">
        <h4 class="card-title text-primary">
          <button type="button" class="btn btn-primary btn-sm align-middle p-025 font-small-2 mr-05"
                  (click)="openAssigneeClientModal(cessionarioCommittenteModal)">
            <app-svg-icon class="d-inline-block align-middle no-margin" name="edit"></app-svg-icon>
          </button>
          <span class="align-middle">Cesionario / Committente</span>
        </h4>
      </div>
      <div
        *ngIf="newInvoice.header.assigneeClient.assigneeId.fiscal === '' &&  !newInvoice.header.assigneeClient.taxCode.active"
        class="card-header pb-0">
        <h4 class="card-title text-primary">
          <span class="align-middle">Cesionario / Committente</span>
        </h4>
      </div>
      <div *ngIf="newInvoice.header.assigneeClient.assigneeId.fiscal !== ''" class="card-body pt-025 pb-0">
        <ng-container
          *ngIf="newInvoice.header.assigneeClient.personalData.denominazione.active &&
          newInvoice.header.assigneeClient.personalData.denominazione.value !== '' &&
          newInvoice.header.assigneeClient.personalData.name.active &&
          newInvoice.header.assigneeClient.personalData.name.value !== '' &&
          newInvoice.header.assigneeClient.personalData.surname.active &&
          newInvoice.header.assigneeClient.personalData.surname.value !== ''">
          <h5 class="mb-0">{{ newInvoice.header.assigneeClient.personalData.denominazione.value }}
            / {{ newInvoice.header.assigneeClient.personalData.name.value }} {{ newInvoice.header.assigneeClient.personalData.surname.value }}</h5>
        </ng-container>
        <ng-container
          *ngIf="newInvoice.header.assigneeClient.personalData.denominazione.active &&
          newInvoice.header.assigneeClient.personalData.denominazione.value !== '' &&
          !newInvoice.header.assigneeClient.personalData.name.active &&
          newInvoice.header.assigneeClient.personalData.name.value === '' &&
          !newInvoice.header.assigneeClient.personalData.surname.active &&
          newInvoice.header.assigneeClient.personalData.surname.value === ''">
          <h5 class="mb-0">{{ newInvoice.header.assigneeClient.personalData.denominazione.value }}</h5>
        </ng-container>
        <ng-container
          *ngIf="!newInvoice.header.assigneeClient.personalData.denominazione.active &&
          newInvoice.header.assigneeClient.personalData.denominazione.value === '' &&
          newInvoice.header.assigneeClient.personalData.name.active &&
          newInvoice.header.assigneeClient.personalData.name.value !== '' &&
          newInvoice.header.assigneeClient.personalData.surname.active &&
          newInvoice.header.assigneeClient.personalData.surname.value !== ''">
          <h5
            class="mb-0">{{ newInvoice.header.assigneeClient.personalData.name.value }} {{ newInvoice.header.assigneeClient.personalData.surname.value }}</h5>
        </ng-container>
        <ng-container *ngIf="newInvoice.header.assigneeClient.assigneeId.fiscal !== '' &&
        newInvoice.header.assigneeClient.taxCode.active &&
        newInvoice.header.assigneeClient.taxCode.value !==''">
          <span
            class="mb-05">{{ newInvoice.header.assigneeClient.assigneeId.country }} {{ newInvoice.header.assigneeClient.assigneeId.fiscal }}
            / {{ newInvoice.header.assigneeClient.taxCode.value }}</span>
        </ng-container>
        <div class="row">
          <div class="col-2 text-primary"><label class="text-primary">Sede</label></div>
          <div
            class="col-10">{{ newInvoice.header.assigneeClient.address.street }} {{ newInvoice.header.assigneeClient.address.streetNumber.value }}
            , {{ newInvoice.header.assigneeClient.address.postalCode }} {{ newInvoice.header.assigneeClient.address.city }} {{ newInvoice.header.assigneeClient.address.province.value }} {{ newInvoice.header.assigneeClient.address.country }}
          </div>
          <div class="col-2 text-primary"><label class="text-primary">Stabile</label></div>
          <div
            class="col-10">{{ newInvoice.header.assigneeClient.permanentEstablishment.street }} {{ newInvoice.header.assigneeClient.permanentEstablishment.streetNumber.value }}
            , {{ newInvoice.header.assigneeClient.permanentEstablishment.postalCode }} {{ newInvoice.header.assigneeClient.permanentEstablishment.city }} {{ newInvoice.header.assigneeClient.permanentEstablishment.province.value }} {{ newInvoice.header.assigneeClient.permanentEstablishment.country }}
          </div>
        </div>
      </div>
      <div *ngIf="newInvoice.header.assigneeClient.assigneeId.fiscal === ''" class="card-body pt-025 pb-0">
        <button class="btn btn-primary btn-block" (click)="openAssigneeClientModal(cessionarioCommittenteModal)">
          <span class="align-middle">Aggiungi</span>
          <app-svg-icon class="d-inline-block align-middle ml-05" name="add"></app-svg-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <ng-container
          *ngIf="(newInvoice.header.thirdIntermediary.active) && (newInvoice.header.thirdIntermediary.intermediaryId.fiscal !=='' || newInvoice.header.thirdIntermediary.taxCode.active)">
          <h4 class="card-title text-primary">
            <button type="button" class="btn btn-primary btn-sm align-middle p-025 font-small-2 mr-05"
                    (click)="openThirdIntermediaryModal(terzoIntermediarioModal)">
              <app-svg-icon class="d-inline-block align-middle no-margin" name="edit"></app-svg-icon>
            </button>
            <span class="align-middle">Terzo Intermediario</span>
          </h4>
        </ng-container>
        <ng-container
          *ngIf="(!newInvoice.header.thirdIntermediary.active)  || (newInvoice.header.thirdIntermediary.intermediaryId.fiscal ==='' && !newInvoice.header.thirdIntermediary.taxCode.active)">
          <h4 class="card-title text-primary">
            <span class="align-middle">Terzo Intermediario</span>
          </h4>
        </ng-container>
      </div>
      <ng-container
        *ngIf="(newInvoice.header.thirdIntermediary.active) && (newInvoice.header.thirdIntermediary.intermediaryId.fiscal !=='' || newInvoice.header.thirdIntermediary.taxCode.active)">
        <div class="card-body pt-025 pb-0">
          <h5 class="mb-0">{{ newInvoice.header.thirdIntermediary.personalData.denominazione.value || '-' }}
            / {{ newInvoice.header.thirdIntermediary.personalData.name.value || '-' }} {{ newInvoice.header.thirdIntermediary.personalData.surname.value || '-' }}</h5>
          <p
            class="mb-05">{{ newInvoice.header.thirdIntermediary.intermediaryId.country }} {{ newInvoice.header.thirdIntermediary.intermediaryId.fiscal || '-' }}
            / {{ newInvoice.header.thirdIntermediary.taxCode.value || '-' }}</p>
          <div class="row">
            <div class="col-12 text-primary"><label class="text-primary">Sogetto Emittente</label></div>
            <div
              class="col-10">{{ newInvoice.header.issuingSubject.subjectType }}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="(!newInvoice.header.thirdIntermediary.active)  || (newInvoice.header.thirdIntermediary.intermediaryId.fiscal ==='' && !newInvoice.header.thirdIntermediary.taxCode.active)">
        <button class="btn btn-primary btn-block m-1" (click)="openThirdIntermediaryModal(terzoIntermediarioModal)">
          <span class="align-middle">Aggiungi</span>
          <app-svg-icon class="d-inline-block align-middle ml-05" name="add"></app-svg-icon>
        </button>
      </ng-container>

    </div>
  </div>
  <div class="col-9">
    <div class="card glassy full-width">
      <div class="card-header pb-0">
        <h4 class="card-title text-primary">
          <span class="align-middle">Corpo</span>
        </h4>
      </div>
      <div class="card-body pt-025 pb-0">
        <div>
          <div>
            <button type="button" class="btn btn-primary" (click)="newLineModal(datiBeniServiziModal)">
              Aggiungi
              <app-svg-icon class="d-inline-block align-middle no-margin" name="add"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <table class="table mt-1">
              <thead>
              <tr>
                <th><span class="font-small-3">Nº</span></th>
                <th><span class="font-small-3">Codice</span></th>
                <th><span class="font-small-3">Descrizione</span></th>
                <th><span class="font-small-3">Quantità</span></th>
                <th><span class="font-small-3">UM</span></th>
                <th><span class="font-small-3">Prezzo Unitario</span></th>
                <th><span class="font-small-3">Sconto</span></th>
                <th><span class="font-small-3">Totale</span></th>
                <th><span class="font-small-3">IVA</span></th>
                <th><span class="font-small-3">Azioni</span></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let product of newInvoice.body.goodsServicesData.lineDetails; index as i">
                <td>{{ product.lineNumber }}</td>
                <td>{{ product.articleCode.value }}</td>
                <td>{{ product.description }}</td>
                <td>{{ product.quantity.value }}</td>
                <td>{{ product.unitMeasure.value }}</td>
                <td>{{ Number(product.unitPrice) | price }}</td>
                <td>
                  <span>{{ product.discountIncrease.discountType === 'DISCOUNT' ? '-' : '' }}</span>
                  <span
                    *ngIf="product.discountIncrease.discountAmount.value != 0">{{ product.discountIncrease.discountAmount.value }}
                    %</span>
                  <span
                    *ngIf="product.discountIncrease.discountPercentage.value != 0">{{ product.discountIncrease.discountPercentage.value }}
                    %</span>
                </td>
                <td>{{ product.totalPrice | price }}</td>
                <td>{{ product.VATRate }} %</td>
                <td>
                  <button type="button" class="btn btn-primary btn-sm align-middle p-025 font-small-2 mr-05"
                          (click)="openEditLineModal(i, datiBeniServiziModal)">
                    <app-svg-icon class="d-inline-block align-middle no-margin" name="edit"></app-svg-icon>
                  </button>
                  <button type="button" class="btn btn-danger btn-sm align-middle p-025 font-small-2 mr-05"
                          (click)="removeLineFromLines(i)">
                    <app-svg-icon class="d-inline-block align-middle no-margin" name="delete"></app-svg-icon>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="card glassy full-width card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title text-primary">
          <span class="align-middle">Condizione di Pagamento</span>
        </h4>
      </div>
      <div class="card-body pt-025 pb-0">
        <button type="button" class="btn btn-primary" (click)="addNewPaymenCondition(datiPagamentoModal)">
          Aggiungi Condizione
          <app-svg-icon class="d-inline-block align-middle no-margin" name="add"></app-svg-icon>
        </button>
      </div>
      <ng-container *ngFor="let paymentCondition of newInvoice.body.paymentData.payment; index as i">
        <div class="card-body pt-025 pb-0">
          <p class="font-small-4 mt-2 font-weight-bold">Condizione {{ i + 1 }}</p>
        </div>
        <ng-container *ngFor="let condition of paymentConditionItems">
          <ng-container *ngIf="paymentCondition.paymentCondition === condition.value">
            <div class="card-body pt-025 pb-0">
              <span class="font-medium-1 text-primary">{{ condition.label }}</span>
              <div>
                <p class="font-small-4 mt-2 font-weight-bold">Pagamenti
                  <button type="button" class="btn btn-primary btn-sm align-middle p-025 font-small-2 mr-05"
                          (click)="openNewPaymentModal(paymentCondition, newPaymentModal)">
                    <app-svg-icon class="d-inline-block align-middle no-margin" name="add"></app-svg-icon>
                  </button>
                </p>
              </div>
            </div>
            <div>
              <table class="table mt-1 mb-2">
                <thead>
                <tr>
                  <th><span class="font-small-3">Modalita</span></th>
                  <th><span class="font-small-3">Importo</span></th>
                  <th><span class="font-small-3">Azioni</span></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let payment of paymentCondition.payments; index as i">
                  <td>
                    <ng-container *ngFor="let paymentMethod of modalitaPagamentoOptions">
                      <span *ngIf="paymentMethod.value === payment.paymentMethod">{{ paymentMethod.label }}</span>
                    </ng-container>
                  </td>
                  <td class="no-wrap">{{ payment.paymentAmount | price }}</td>
                  <td>
                    <button type="button" class="btn btn-primary btn-sm align-middle p-025 font-small-2 mr-05"
                            (click)="openEditPaymentModal(paymentCondition, i, editpaymentmodal)">
                      <app-svg-icon class="d-inline-block align-middle no-margin" name="edit"></app-svg-icon>
                    </button>
                    <button type="button" class="btn btn-danger btn-sm align-middle p-025 font-small-2 mr-05"
                            (click)="removePaymentFromPayments(i)">
                      <app-svg-icon class="d-inline-block align-middle no-margin" name="delete"></app-svg-icon>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>


<ng-template #trasmittenteModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Trasmittente</h5>
    <button type="button" class="close" (click)="closeTransmitterModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12">
      <div class="row">
        <div class="col-4">
          <label class="form-label mt-05 text-primary">Tipo Documento <span class="font-small-2">*</span></label>
        </div>
        <div class="col-4">
          <label class="form-label mt-05 text-primary">Numero <span class="font-small-2">*</span></label>
        </div>
        <div class="col-4">
          <label class="form-label  mt-05 text-primary">Data <span class="font-small-2">*</span></label>
        </div>
        <div class="col-4">
          <ng-select
            class="full-width col-12"
            [items]="TipoDocumentoOptions"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [multiple]="false"
            placeholder="Seleziona"
            [(ngModel)]="newInvoice.body.invoiceData.documentType">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{item.label}}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-4">
          <input type="text" class="form-control" [(ngModel)]="newInvoice.body.invoiceData.number">
        </div>
        <div class="col-4">
          <div class="input-group">
            <input class="form-control" placeholder="{{newInvoice.body.invoiceData.date | date}}"
                   [(ngModel)]="newInvoice.body.invoiceData.date" ngbDatepicker #basicDP1="ngbDatepicker">
            <div class="input-group-append">
              <button class="feather icon-calendar cursor-pointer" (click)="basicDP1.toggle()"
                      type="button" rippleEffect>
                <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
              </button>
            </div>
          </div>
        </div>
        <h5 class="card-title col-12 mt-2">Trasmittente <span class="font-small-3">*</span></h5>
        <label class="form-label mt-1 font-weight-bold">ID Trasmittente</label>
        <div class="col-4">
          <label class="form-label text-primary">Paese <span class="font-small-2">*</span></label>
        </div>
        <div class="col-8">
          <label class="form-label text-primary">P.IVA <span class="font-small-2">*</span></label>
        </div>
        <div class="col-4">
          <ng-select
            class="full-width no-empty"
            [items]="CountryFiscalOptions"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [multiple]="false"
            placeholder="Seleziona"
            [(ngModel)]="newInvoice.header.transmitter.idTransmitter.country">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{item.label}}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-8">
          <input type="text" class="form-control" [(ngModel)]="newInvoice.header.transmitter.idTransmitter.fiscal">
        </div>
        <div class="col-12 mt-1">
          <label class="form-label text-primary">Progressivo Invio <span class="font-small-2">*</span></label>
        </div>
        <div class="col-12">
          <input type="text" class="form-control" [(ngModel)]="newInvoice.header.transmitter.sendingProgress">
        </div>
        <div class="col-12 mt-1">
          <label class="form-label text-primary">Formato Trasmissione <span class="font-small-2">*</span></label>
        </div>
        <div class="col-12">
          <ng-select
            class="full-width no-empty"
            [items]="[{value: 'FPA12', label: 'FPA12 - fattura verso PA'}, {value: 'FPR12', label: 'FPR12 - fattura verso privati'}]"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [multiple]="false"
            placeholder="Seleziona"
            [(ngModel)]="newInvoice.header.transmitter.transmissionFormat">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{item.label}}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12">
          <label class="form-label mt-1 text-primary">PEC Destinatario <span class="font-small-2">*</span></label>
        </div>
        <div class="col-12">
          <input type="text" class="form-control"
                 [(ngModel)]="newInvoice.header.transmitter.recipientPEC.value">
        </div>
        <div class="col-12">
          <label class="form-label mt-1 text-primary">Codice Destinatario <span
            class="font-small-2">*</span></label>
        </div>
        <div class="col-12">
          <input type="text" class="form-control" [(ngModel)]="newInvoice.header.transmitter.recipientCode">
        </div>
        <div class="col-12 d-flex flex-row">
          <input type="checkbox" class="form-check-input mr-05 mt-1"
                 [(ngModel)]="newInvoice.header.transmitter.transmitterContacts.active">
          <label class="form-label mt-1 font-weight-bold">Contatti Trasmittente</label>
        </div>
        <ng-container *ngIf="newInvoice.header.transmitter.transmitterContacts.active">
          <div class="col-6">
            <label class="form-label text-primary">Telefono</label>
          </div>
          <div class="col-6">
            <label class="form-label text-primary">Email</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newInvoice.header.transmitter.transmitterContacts.phone">
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newInvoice.header.transmitter.transmitterContacts.email">
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeTransmitterModal()">Cancello</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Salva</button>
  </div>
</ng-template>

<ng-template #customerSelectionCedenteModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">CEDENTE PRESTATORE</h5>
    <button type="button" class="close" (click)="closeSellerProviderModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group overflow-visible">
      <label for="customer">Cliente</label>
      <ng-select
        id="customer"
        [items]="businesses"
        [multiple]="false"
        [closeOnSelect]="true"
        [searchable]="true"
        [placeholder]="'Seleziona una azienda'"
        (keyup)="searchBusiness($event.target)"
        appendTo="body"
        class="z-index-2073"
        bindLabel="name"
        addTagText="Usa "
        [addTag]="false"
      >
        <ng-template ng-option-tmp let-item="item">
          <div title="{{item.name}} - {{item.vat}}">{{ item.name }} - {{item.vat}}</div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeSellerProviderModal()">Cancello</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Salva</button>
  </div>
</ng-template>


<ng-template #cedentePrestatoreModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">CEDENTE PRESTATORE</h5>
    <button type="button" class="close" (click)="closeSellerProviderModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="card-title">CEDENTE PRESTATORE <span class="font-small-3">*</span></h5>
    <div class="row">
      <div class="col-6">
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <label class="form-label text-primary">Paese <span class="font-small-2">*</span></label>
            </div>
            <div class="col-8">
              <label class="form-label text-primary">P.IVA <span class="font-small-2">*</span></label>
            </div>
            <div class="col-4">
              <ng-select
                class="full-width"
                [items]="CountryFiscalOptions"
                [bindLabel]="'label'"
                [bindValue]="'value'"
                [multiple]="false"
                placeholder="Seleziona"
                [(ngModel)]="newInvoice.header.sellerProvider.providerId.country">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{item.label}}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-8">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.providerId.fiscal">
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label text-primary mt-1">Codice Fiscale</label>
            </div>
            <div class="col-12">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.taxCode.value">
            </div>
            <label class="form-label mt-1 font-weight-bold">Dati Anagrafici</label>
            <div class="col-12">
              <label class="form-label text-primary mt-1">Denominazione</label>
            </div>
            <div class="col-12">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.personalData.denominazione.value">
            </div>
            <div class="col-6">
              <label class=" mt-1 form-label text-primary">Nome</label>
            </div>
            <div class="col-6">
              <label class="mt-1 form-label text-primary">Cognome</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.personalData.name.value">
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.personalData.surname.value">
            </div>
            <div class="col-6">
              <label class="mt-1 form-label text-primary">Titolo</label>
            </div>
            <div class="col-6">
              <label class="mt-1 form-label text-primary">CodEORI</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.personalData.title.value">
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.personalData.codEORI.value">
            </div>
            <div class="col-6">
              <label class=" mt-1 form-label text-primary">Albo Professionale</label>
            </div>
            <div class="col-6">
              <label class=" mt-1 form-label text-primary">Provincia Albo</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.personalData.professionalRegistry.value">
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.personalData.registryProvince.value">
            </div>
            <div class="col-12">
              <label class="mt-1 form-label text-primary">Numero Iscrizione Albo</label>
            </div>
            <div class="col-12">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.personalData.registryNumber.value">
            </div>
            <div class="col-12">
              <label class="mt-1 form-label text-primary">Data Iscrizione Albo</label>
            </div>
            <div class="col-12">
              <div class="input-group">
                <input class="form-control"
                       placeholder="{{newInvoice.header.sellerProvider.personalData.registryDate.value | date}}"
                       [(ngModel)]="newInvoice.header.sellerProvider.personalData.registryDate.value" ngbDatepicker
                       #basicDP2="ngbDatepicker">
                <div class="input-group-append">
                  <button class="feather icon-calendar cursor-pointer" (click)="basicDP2.toggle()"
                          type="button" rippleEffect>
                    <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 mt-1">
              <label class="form-label text-primary">Regime Fiscale</label>
            </div>
            <div class="col-12">
              <ng-select
                [items]="RegimeFiscaleOptions"
                [bindLabel]="'label'"
                [bindValue]="'value'"
                [multiple]="false"
                placeholder="Seleziona"
                [(ngModel)]="newInvoice.header.sellerProvider.personalData.fiscalRegime">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{item.label}}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="col-12">
          <div class="row">
            <label class="form-label mt-1 font-weight-bold">Sede</label>
            <div class="col-8">
              <label class="form-label text-primary">Indirizzo <span class="font-small-2">*</span></label>
            </div>
            <div class="col-4">
              <label class="form-label text-primary">Numero Civico <span class="font-small-2">*</span></label>
            </div>
            <div class="col-8">
              <input type="text" class="form-control" [(ngModel)]="newInvoice.header.sellerProvider.address.street">
            </div>
            <div class="col-4">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.address.streetNumber">
            </div>
            <div class="col-3 mt-1">
              <label class="form-label text-primary">CAP <span class="font-small-2">*</span></label>
            </div>
            <div class="col-3 mt-1">
              <label class="form-label text-primary">Comune <span class="font-small-2">*</span></label>
            </div>
            <div class="col-3 d-flex flex-row">
              <label class="mt-1 form-label text-primary">Provincia</label>
            </div>
            <div class="col-3 mt-1">
              <label class="form-label text-primary">Nazione <span class="font-small-2">*</span></label>
            </div>
            <div class="col-3">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.address.postalCode">
            </div>
            <div class="col-3">
              <input type="text" class="form-control" [(ngModel)]="newInvoice.header.sellerProvider.address.city">
            </div>
            <div class="col-3">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.address.province.value">
            </div>
            <div class="col-3">
              <ng-select
                class="full-width"
                [items]="CountryFiscalOptions"
                [bindLabel]="'label'"
                [bindValue]="'value'"
                [multiple]="false"
                placeholder="Seleziona"
                [(ngModel)]="newInvoice.header.sellerProvider.address.country">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{item.label}}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-12 d-flex flex-row">
              <input type="checkbox" class="form-check-input mt-1 mr-05"
                     [(ngModel)]="newInvoice.header.sellerProvider.permanentEstablishment.active">
              <label class="form-label mt-1 font-weight-bold">Stabile Organizzazione</label>
            </div>
            <ng-container *ngIf="newInvoice.header.sellerProvider.permanentEstablishment.active">
              <div class="col-7">
                <label class="form-label text-primary">Indirizzo <span class="font-small-2">*</span></label>
              </div>
              <div class="col-5 d-flex flex-row">
                <label class=" form-label text-primary">Numero Civico</label>
              </div>
              <div class="col-7">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.permanentEstablishment.street">
              </div>
              <div class="col-5">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.permanentEstablishment.streetNumber.value">
              </div>
              <div class="col-3 mt-1">
                <label class="form-label text-primary">CAP <span class="font-small-2">*</span></label>
              </div>
              <div class="col-3 mt-1">
                <label class="form-label text-primary">Comune <span class="font-small-2">*</span></label>
              </div>
              <div class="col-3 d-flex flex-row">
                <label class="mt-1 form-label text-primary">Provincia</label>
              </div>
              <div class="col-3 mt-1">
                <label class="form-label text-primary">Nazione <span class="font-small-2">*</span></label>
              </div>
              <div class="col-3">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.permanentEstablishment.postalCode">
              </div>
              <div class="col-3">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.permanentEstablishment.city">
              </div>
              <div class="col-3">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.permanentEstablishment.province.value">
              </div>
              <div class="col-3">
                <ng-select
                  class="full-width"
                  [items]="CountryFiscalOptions"
                  [bindLabel]="'label'"
                  [bindValue]="'value'"
                  [multiple]="false"
                  placeholder="Seleziona"
                  [(ngModel)]="newInvoice.header.sellerProvider.permanentEstablishment.country">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.label}}">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
            </ng-container>
            <div class="col-12 d-flex flex-row">
              <input type="checkbox" class="form-check-input mt-1 mr-05"
                     [(ngModel)]="newInvoice.header.sellerProvider.REARegistration.active">
              <label class="form-label mt-1 font-weight-bold">Iscrizione REA</label>
            </div>
            <ng-container *ngIf="newInvoice.header.sellerProvider.REARegistration.active">
              <div class="col-4">
                <label class="form-label text-primary">Ufficio Provinciale <span
                  class="font-small-2">*</span></label>
              </div>
              <div class="col-8">
                <label class="form-label text-primary">Numero REA <span class="font-small-2">*</span></label>
              </div>
              <div class="col-4">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.REARegistration.office">
              </div>
              <div class="col-8">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.REARegistration.REANumber">
              </div>
              <div class="col-4 d-flex flex-row">
                <label class="mt-1 form-label text-primary">Capitale Sociale</label>
              </div>
              <div class="col-4 d-flex flex-row">
                <label class="mt-1 form-label text-primary">Socio Unico</label>
              </div>
              <div class="col-4 ">
                <label class="mt-1 form-label text-primary">Stato Liquidazione <span
                  class="font-small-2">*</span></label>
              </div>
              <div class="col-4">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.REARegistration.shareCapital.value">
              </div>
              <div class="col-4">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.REARegistration.singlePartner.value">
              </div>
              <div class="col-4">
                <ng-select
                  class="full-width"
                  [items]="[{label: 'In Liquidazione', value: 'LS'}, {label: 'Non In Liquidazione', value: 'LN'}]"
                  [bindLabel]="'label'"
                  [bindValue]="'value'"
                  [multiple]="false"
                  placeholder="Seleziona"
                  [(ngModel)]="newInvoice.header.sellerProvider.REARegistration.liquidationStatus">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.label}}">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
            </ng-container>
            <div class="col-12 d-flex flex-row">
              <input type="checkbox" class="form-check-input mt-1 mr-05"
                     [(ngModel)]="newInvoice.header.sellerProvider.providerContacts.active">
              <label class="form-label mt-1 font-weight-bold">Contatti Cedente Prestatore</label>
            </div>
            <ng-container *ngIf="newInvoice.header.sellerProvider.providerContacts.active">
              <div class="col-6 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Telefono</label>
              </div>
              <div class="col-6 d-flex flex-row">
                <label class="form-label text-primary">Fax</label>
              </div>
              <div class="col-6">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.providerContacts.phone.value">
              </div>
              <div class="col-6">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.providerContacts.fax.value">
              </div>
              <div class="col-12 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Email</label>
              </div>
              <div class="col-12">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.sellerProvider.providerContacts.email.value">
              </div>
            </ng-container>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-2 font-weight-bold">Riferimento Amministrazione</label>
            </div>
            <div class="col-12 d-flex flex-row">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.sellerProvider.administrationReference.value">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeSellerProviderModal()">Cancello</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Salva</button>
  </div>
</ng-template>

<ng-template #cessionarioCommittenteModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Cessionario / Committente</h5>
    <button type="button" class="close" (click)="closeAssigneeClientModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="card-title">CESIONARIO / COMMITTENTE</h5>
    <div class="row">
      <div class="col-6">
        <div class="col-12">
          <div class="row">
            <label class="form-label mt-1 font-weight-bold">Dati Anagrafici</label>
            <div class="col-4">
              <label class="form-label text-primary">Paese</label>
            </div>
            <div class="col-8">
              <label class="form-label text-primary">Fiscale</label>
            </div>
            <div class="col-4">
              <ng-select
                class="full-width"
                [items]="CountryFiscalOptions"
                [bindLabel]="'label'"
                [bindValue]="'value'"
                [multiple]="false"
                placeholder="Seleziona"
                [(ngModel)]="newInvoice.header.assigneeClient.assigneeId.country">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{item.label}}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-8">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.header.assigneeClient.assigneeId.fiscal">
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice Fiscale</label>
            </div>
            <div class="col-12">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.header.assigneeClient.taxCode.value">
            </div>
            <div class="col-12">
              <label class="form-label mt-1  text-primary">Denominazione</label>
            </div>
            <div class="col-12">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.assigneeClient.personalData.denominazione.value">
            </div>
            <div class="col-6 mt-1">
              <label class="form-label mt-1  text-primary">Nome</label>
            </div>
            <div class="col-6 mt-1">
              <label class="form-label mt-1  text-primary">Cognome</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.assigneeClient.personalData.name.value">
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.assigneeClient.personalData.surname.value">
            </div>
            <div class="col-6 mt-1">
              <label class="form-label mt-1  text-primary">Titolo</label>
            </div>
            <div class="col-6 mt-1">
              <label class="form-label mt-1  text-primary">CodEORI</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.assigneeClient.personalData.title.value">
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.assigneeClient.personalData.codEORI.value">
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="col-12">
          <div class="row">
            <label class="form-label mt-1 font-weight-bold">Sede</label>
            <div class="col-7">
              <label class="form-label mt-1 text-primary">Indirizzo <span class="font-small-2">*</span></label>
            </div>
            <div class="col-5 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Numero Civico</label>
            </div>
            <div class="col-7">
              <input type="text" class="form-control" [(ngModel)]="newInvoice.header.assigneeClient.address.street">
            </div>
            <div class="col-5">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.assigneeClient.address.streetNumber.value">
            </div>
            <div class="col-3 mt-1">
              <label class="form-label text-primary">CAP <span class="font-small-2">*</span></label>
            </div>
            <div class="col-3 mt-1">
              <label class="form-label text-primary">Comune <span class="font-small-2">*</span></label>
            </div>
            <div class="col-3 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Provincia</label>
            </div>
            <div class="col-3 mt-1">
              <label class="form-label text-primary">Nazione <span class="font-small-2">*</span></label>
            </div>
            <div class="col-3">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.header.assigneeClient.address.postalCode">
            </div>
            <div class="col-3">
              <input type="text" class="form-control" [(ngModel)]="newInvoice.header.assigneeClient.address.city">
            </div>
            <div class="col-3">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.header.assigneeClient.address.province.value">
            </div>
            <div class="col-3">
              <ng-select
                class="full-width"
                [items]="CountryFiscalOptions"
                [bindLabel]="'label'"
                [bindValue]="'value'"
                [multiple]="false"
                placeholder="Seleziona"
                [(ngModel)]="newInvoice.header.assigneeClient.address.country">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{item.label}}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-12 d-flex flex-row">
              <input type="checkbox" class="form-check-input mt-1 mr-05"
                     [(ngModel)]="newInvoice.header.assigneeClient.permanentEstablishment.active">

              <label class="form-label mt-1 font-weight-bold">Stabile Organizzazione</label>
            </div>
            <ng-container *ngIf="newInvoice.header.assigneeClient.permanentEstablishment.active">
              <div class="col-7">
                <label class="form-label text-primary">Indirizzo <span class="font-small-2">*</span></label>
              </div>
              <div class="col-5 d-flex flex-row">
                <label class="form-label text-primary">Numero Civico</label>
              </div>
              <div class="col-7">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.header.assigneeClient.permanentEstablishment.street">
              </div>
              <div class="col-5">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.assigneeClient.permanentEstablishment.streetNumber.value">
              </div>
              <div class="col-3 mt-1">
                <label class="form-label text-primary">CAP <span class="font-small-2">*</span></label>
              </div>
              <div class="col-3 mt-1">
                <label class="form-label text-primary">Comune <span class="font-small-2">*</span></label>
              </div>
              <div class="col-3 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Provincia</label>
              </div>
              <div class="col-3 mt-1">
                <label class="form-label text-primary">Nazione <span class="font-small-2">*</span></label>
              </div>
              <div class="col-3">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.header.assigneeClient.permanentEstablishment.postalCode">
              </div>
              <div class="col-3">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.header.assigneeClient.permanentEstablishment.city">
              </div>
              <div class="col-3">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.assigneeClient.permanentEstablishment.province.value">
              </div>
              <div class="col-3">
                <ng-select
                  class="full-width"
                  [items]="CountryFiscalOptions"
                  [bindLabel]="'label'"
                  [bindValue]="'value'"
                  [multiple]="false"
                  placeholder="Seleziona"
                  [(ngModel)]="newInvoice.header.assigneeClient.permanentEstablishment.country">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.label}}">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
            </ng-container>
            <div class="col-12 d-flex flex-row">
              <input type="checkbox" class="form-check-input mt-1 mr-05"
                     [(ngModel)]="newInvoice.header.assigneeClient.fiscalRepresentative.active">
              <label class="form-label mt-1 font-weight-bold">Rappresentante Fiscale</label>
            </div>
            <ng-container *ngIf="newInvoice.header.assigneeClient.fiscalRepresentative.active">
              <div class="col-4">
                <label class="form-label text-primary">Paese <span class="font-small-2">*</span></label>
              </div>
              <div class="col-8">
                <label class="form-label text-primary">P.IVA <span class="font-small-2">*</span></label>
              </div>
              <div class="col-4">
                <ng-select
                  class="full-width"
                  [items]="CountryFiscalOptions"
                  [bindLabel]="'label'"
                  [bindValue]="'value'"
                  [multiple]="false"
                  placeholder="Seleziona"
                  [(ngModel)]="tmpFiscalRepresentative.fiscalRepresentativeId.country"
                  (ngModelChange)="updateFiscalRepresentative()">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.label}}">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-8">
                <input type="text" class="form-control"
                       [(ngModel)]="tmpFiscalRepresentative.fiscalRepresentativeId.fiscal"
                       (ngModelChange)="updateFiscalRepresentative()">
              </div>
              <div class="col-12 d-flex flex-row">
                <label class="form-label text-primary mt-1">Codice Fiscale</label>
              </div>
              <div class="col-12">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.fiscalRepresentative.taxCode.value">
              </div>
              <div class="col-12 mt-1 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Denominazione</label>
              </div>
              <div class="col-12">
                <input
                  type="text" class="form-control"
                  [(ngModel)]="tmpFiscalRepresentative.personalData.denominazione.value"
                  (ngModelChange)="updateFiscalRepresentative()">
              </div>
              <div class="col-6 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Nome</label>
              </div>
              <div class="col-6 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Cognome</label>
              </div>
              <div class="col-6">
                <input type="text" class="form-control"
                       [(ngModel)]="tmpFiscalRepresentative.personalData.name.value"
                       (ngModelChange)="updateFiscalRepresentative()">
              </div>
              <div class="col-6">
                <input type="text" class="form-control"
                       [(ngModel)]="tmpFiscalRepresentative.personalData.surname.value"
                       (ngModelChange)="updateFiscalRepresentative()">
              </div>
              <div class="col-6">
                <label class="form-label mt-1 text-primary">Titolo</label>
              </div>
              <div class="col-6">
                <label class="form-label mt-1 text-primary">CodEORI</label>
              </div>
              <div class="col-6">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.fiscalRepresentative.personalData.title.value">
              </div>
              <div class="col-6">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.header.fiscalRepresentative.personalData.codEORI.value">
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeAssigneeClientModal()">Cancello</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Salva</button>
  </div>
</ng-template>


<ng-template #terzoIntermediarioModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Terzo Intermediario O Soggetto Incaricato</h5>
    <button type="button" class="close" (click)="closeThirdIntermediaryModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 d-flex flex-row">
      <h5 class="card-title text-primary">Terzo Intermediario O Soggetto Incaricato</h5>
    </div>
    <ng-container>
      <div class="row">
        <div class="col-4">
          <label class="form-label text-primary">Paese</label>
        </div>
        <div class="col-8">
          <label class="form-label text-primary">Fiscale</label>
        </div>
        <div class="col-4">
          <ng-select
            class="full-width col-12"
            [items]="CountryFiscalOptions"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [multiple]="false"
            placeholder="Seleziona"
            [(ngModel)]="newInvoice.header.thirdIntermediary.intermediaryId.country">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{item.label}}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-8">
          <input type="text" class="form-control"
                 [(ngModel)]="newInvoice.header.thirdIntermediary.intermediaryId.fiscal">
        </div>
        <div class="col-12 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Codice Fiscale</label>
        </div>
        <div class="col-12">
          <input type="text" class="form-control"
                 [(ngModel)]="newInvoice.header.thirdIntermediary.taxCode.value">
        </div>
        <div class="col-12 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Denominazione</label>
        </div>
        <div class="col-12">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="newInvoice.header.thirdIntermediary.personalData.denominazione.value">
        </div>
        <div class="col-6 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Nome</label>
        </div>
        <div class="col-6 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Cognome</label>
        </div>
        <div class="col-6">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="newInvoice.header.thirdIntermediary.personalData.name.value">
        </div>
        <div class="col-6">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="newInvoice.header.thirdIntermediary.personalData.surname.value">
        </div>
        <div class="col-6 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Titolo</label>
        </div>
        <div class="col-6 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Codice EORI</label>
        </div>
        <div class="col-6">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="newInvoice.header.thirdIntermediary.personalData.title.value">
        </div>
        <div class="col-6">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="newInvoice.header.thirdIntermediary.personalData.codEORI.value">
        </div>
        <div class="col-12 mt-1">
          <label class="form-label text-primary">Soggetto Emittente</label>
        </div>
        <div class="col-12">
          <ng-select
            class="full-width mt-1 col-12"
            [items]="[{value: 'CC', name: 'Cessionario Committente [CC]'}, {value: 'TZ', name: 'Terzo [TZ]'}]"
            [bindLabel]="'name'"
            [bindValue]="'value'"
            [multiple]="false"
            placeholder="Seleziona"
            [(ngModel)]="newInvoice.header.issuingSubject.subjectType">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{item.name}}">{{ item.name }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeThirdIntermediaryModal()">Cancello</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Salva</button>
  </div>
</ng-template>


<ng-template #datiGeneraliModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Dati Generali</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="card-title text-primary">Dati Generali</h5>
    <div class="row">
      <div class="col-6">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <label class="form-label mt-1 text-primary">Divisa <span class="font-small-2">*</span></label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Bollo</label>
            </div>
            <div class="col-6">
              <ng-select
                class="full-width col-12"
                [items]="divise"
                [bindLabel]="'label'"
                [bindValue]="'value'"
                [multiple]="false"
                placeholder="Seleziona"
                [(ngModel)]="newInvoice.body.invoiceData.currency">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{item.label}}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.invoiceData.stampData.stampAmount.value">
            </div>
            <div class="col-12 d-flex flex-row">
              <input type="checkbox" class="form-check-input mt-1 mr-05"
                     [(ngModel)]="newInvoice.body.invoiceData.withholdingData.active">
              <label class="form-label mt-1 font-weight-bold">Dati Ritenuta</label>
            </div>
            <ng-container *ngIf="newInvoice.body.invoiceData.withholdingData.active">
              <div class="col-6">
                <label class="form-label text-primary">Tipo Ritenuta <span class="font-small-2">*</span></label>
              </div>
              <div class="col-6">
                <label class="form-label text-primary">Importo Ritenuta <span class="font-small-2">*</span></label>
              </div>
              <div class="col-6">
                <ng-select
                  class="full-width col-12"
                  [items]="tipoRitenutaOptions"
                  [bindLabel]="'label'"
                  [bindValue]="'value'"
                  [multiple]="false"
                  placeholder="Seleziona"
                  [(ngModel)]="newInvoice.body.invoiceData.withholdingData.withholdingType">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.label}}">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-6">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.withholdingData.withholdingAmount">
              </div>
              <div class="col-6 mt-1">
                <label class="form-label text-primary">Aliquota Ritenuta <span class="font-small-2">*</span></label>
              </div>
              <div class="col-6 mt-1">
                <label class="form-label text-primary">Causale Pagamento <span class="font-small-2">*</span></label>
              </div>
              <div class="col-6">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.withholdingData.withholdingRate">
              </div>
              <div class="col-6">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.withholdingData.paymentReason">
              </div>
            </ng-container>
            <div class="col-12 d-flex flex-row">
              <input type="checkbox" class="form-check-input mt-1 mr-05"
                     [(ngModel)]="newInvoice.body.invoiceData.pensionData.active">
              <label class="form-label mt-1 font-weight-bold">Dati Cassa Previdenziale</label>
            </div>
            <ng-container *ngIf="newInvoice.body.invoiceData.pensionData.active">
              <div class="col-6">
                <label class="form-label text-primary">Tipo Cassa <span class="font-small-2">*</span></label>
              </div>
              <div class="col-6">
                <label class="form-label text-primary">Al Cassa <span class="font-small-2">*</span></label>
              </div>
              <div class="col-6">
                <ng-select class="full-width"
                           [items]="TipoCassaOptions"
                           [bindLabel]="'label'"
                           [bindValue]="'value'"
                           [multiple]="false"
                           placeholder="Seleziona"
                           [(ngModel)]="newInvoice.body.invoiceData.pensionData.pensionType">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.label}}">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-6">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.pensionData.pensionAmount">
              </div>
              <div class="col-6 mt-1">
                <label class="form-label text-primary">Importo Contributo Cassa <span
                  class="font-small-2">*</span></label>
              </div>
              <div class="col-6 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Imponibile Cassa</label>
              </div>
              <div class="col-6">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.pensionData.pensionContributionAmount">
              </div>
              <div class="col-6">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.pensionData.pensionTaxableAmount.value">
              </div>
              <div class="col-6 mt-1">
                <label class="form-label text-primary">Aliquota IVA <span class="font-small-2">*</span></label>
              </div>
              <div class="col-6 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Ritenuta</label>
              </div>

              <div class="col-6">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.pensionData.pensionVATRate">
              </div>
              <div class="col-6">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.pensionData.pensionWithholding.value">
              </div>
              <div class="col-6 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Natura</label>
              </div>
              <div class="col-6 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Riferimento Amministrativo</label>
              </div>
              <div class="col-6">
                <ng-select class="full-width"
                           [items]="naturaOptions"
                           [bindLabel]="'label'"
                           [bindValue]="'value'"
                           [multiple]="false"
                           placeholder="Seleziona"
                           [(ngModel)]="newInvoice.body.invoiceData.pensionData.pensionNature.value">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.label}}">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-6">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.pensionData.administrativeReference.value">
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="col-12">
          <div class="row">
            <div class="col-12 d-flex flex-row">
              <input type="checkbox" class="form-check-input mt-1 mr-05"
                     [(ngModel)]="newInvoice.body.invoiceData.discountIncreaseData.active">
              <label class="form-label mt-1 font-weight-bold">Sconto Maggiorazione</label>
            </div>
            <ng-container *ngIf="newInvoice.body.invoiceData.discountIncreaseData.active">

              <div class="col-4">
                <label class="form-label mt-1 text-primary">Tipo</label>
              </div>
              <div class="col-4 d-flex flex-row">
                <label class="form-label mt-1 text-primary">Percentuale</label>
              </div>
              <div class="col-4">
                <label class="form-label mt-1 text-primary">Importo</label>
              </div>
              <div class="col-4">
                <ng-select
                  class="full-width"
                  [items]="[{value: 'SC', name: 'Sconto(SC)'}, {value: 'MG', name: 'Maggiorazione(MG)'}]"
                  [bindLabel]="'name'"
                  [bindValue]="'value'"
                  [multiple]="false"
                  placeholder="Seleziona"
                  [(ngModel)]="newInvoice.body.invoiceData.discountIncreaseData.discountType">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.name}}">{{ item.name }}</div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-4">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.discountIncreaseData.discountPercentage.value">
              </div>
              <div class="col-4">
                <input type="text" class="form-control"
                       [(ngModel)]="newInvoice.body.invoiceData.discountIncreaseData.discountAmount.value">
              </div>
            </ng-container>
            <div class="col-12 mt-2">
              <label class="form-label mt-1 font-weight-bold">Altri Dettagli</label>
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Importo Totale Documento</label>
            </div>
            <div class="col-12">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.invoiceData.totalDocumentAmount.value">
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Arrotondamento</label>
            </div>
            <div class="col-12">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.invoiceData.rounding.value">
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Causale</label>
            </div>
            <div class="col-12">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.invoiceData.reason.value">
            </div>
            <div class="col-12 mt-1">
              <label class="form-label mt-1 text-primary">Art73</label>
            </div>
            <div class="col-12">
              <ng-select class="full-width"
                         [items]="[{value: 'SI', name: 'SI'}, {value: 'NO', name: 'NO'}]"
                         [bindLabel]="'name'"
                         [bindValue]="'value'"
                         [multiple]="false"
                         placeholder="Seleziona"
                         [(ngModel)]="newInvoice.body.invoiceData.art73.value">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{item.name}}">{{ item.name }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Close click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<ng-template #orderDetailsModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Dati Ordine Acquisto e... </h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 d-flex flex-row">
      <h5 class="card-title mt-1 text-primary">Dati Ordine Acquisto</h5>
    </div>
    <ng-container>
      <div class="row">
        <div class="col-6 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Riferimento Numero Linea</label>
        </div>
        <div class="col-6">
          <label class="form-label mt-1 text-primary">Id Documento <span class="font-small-2">*</span></label>
        </div>
        <div class="col-6">
          <input type="text" class="form-control"
                 [(ngModel)]="newInvoice.body.purchaseOrderData.lineNumber.value">
        </div>
        <div class="col-6">
          <input type="text" class="form-control" [(ngModel)]="newInvoice.body.purchaseOrderData.documentId">
        </div>
        <div class="col-6 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Data</label>
        </div>
        <div class="col-6 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Numero Item</label>
        </div>
        <div class="col-6">
          <div class="input-group">
            <input class="form-control" placeholder="{{newInvoice.body.purchaseOrderData.date.value | date}}"
                   [(ngModel)]="newInvoice.body.purchaseOrderData.date.value" ngbDatepicker #basicDP3="ngbDatepicker">
            <div class="input-group-append">
              <button class="feather icon-calendar cursor-pointer" (click)="basicDP3.toggle()"
                      type="button" rippleEffect>
                <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <input type="text" class="form-control"
                 [(ngModel)]="newInvoice.body.purchaseOrderData.itemNumber.value">
        </div>
        <div class="col-6 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Codice Commessa Convenzione</label>
        </div>
        <div class="col-6 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Codice CUP</label>
        </div>
        <div class="col-6">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="newInvoice.body.purchaseOrderData.conventionCode.value">
        </div>
        <div class="col-6">
          <input type="text" class="form-control"
                 [(ngModel)]="newInvoice.body.purchaseOrderData.CUPCode.value">
        </div>
        <div class="col-12 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Codice CIG</label>
        </div>
        <div class="col-12">
          <input type="text" class="form-control"
                 [(ngModel)]="newInvoice.body.purchaseOrderData.CIGCode.value">
        </div>
      </div>
    </ng-container>

    <div class="row mt-2">
      <div class="col-12 d-flex flex-row">
        <label class="form-label font-weight-bold">Dati Contratto</label>
      </div>
      <div class="col-12">
        <ng-container>
          <div class="row">
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Riferimento Numero Linea</label>
            </div>
            <div class="col-6">
              <label class="form-label mt-1 text-primary">Id Documento <span class="font-small-2">*</span></label>
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.contractData.lineNumber.value">
            </div>
            <div class="col-6">
              <input type="text" class="form-control" [(ngModel)]="newInvoice.body.contractData.documentId">
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Data</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Numero Item</label>
            </div>
            <div class="col-6">
              <div class="input-group">
                <input class="form-control" placeholder="{{newInvoice.body.contractData.date.value | date}}"
                       [(ngModel)]="newInvoice.body.contractData.date.value" ngbDatepicker #basicDP4="ngbDatepicker">
                <div class="input-group-append">
                  <button class="feather icon-calendar cursor-pointer" (click)="basicDP4.toggle()"
                          type="button" rippleEffect>
                    <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.contractData.itemNumber.value">
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice Commessa Convenzione</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice CUP</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.contractData.conventionCode.value">
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.contractData.CUPCode.value">
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice CIG</label>
            </div>
            <div class="col-12">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.contractData.CIGCode.value">
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-12 d-flex flex-row">
        <label class="form-label mt-1 font-weight-bold">Dati Convenzione</label>
      </div>
      <div class="col-12">
        <ng-container>
          <div class="row">
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Riferimento Numero Linea</label>
            </div>
            <div class="col-6">
              <label class="form-label mt-1 text-primary">Id Documento <span class="font-small-2">*</span></label>
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.conventionData.lineNumber.value">
            </div>
            <div class="col-6">
              <input type="text" class="form-control" [(ngModel)]="newInvoice.body.conventionData.documentId">
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Data</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Numero Item</label>
            </div>
            <div class="col-6">
              <div class="input-group">
                <input class="form-control" placeholder="{{newInvoice.body.conventionData.date.value | date}}"
                       [(ngModel)]="newInvoice.body.conventionData.date.value" ngbDatepicker
                       #basicDP5="ngbDatepicker">
                <div class="input-group-append">
                  <button class="feather icon-calendar cursor-pointer" (click)="basicDP5.toggle()"
                          type="button" rippleEffect>
                    <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.conventionData.itemNumber.value">
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice Commessa Convenzione</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice CUP</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.conventionData.conventionCode.value">
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.conventionData.CUPCode.value">
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice CIG</label>
            </div>
            <div class="col-12">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.conventionData.CIGCode.value">
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-12 d-flex flex-row">
        <label class="form-label mt-1 font-weight-bold">Dati Ricezione</label>
      </div>
      <div class="col-12">
        <ng-container>
          <div class="row">
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Riferimento Numero Linea</label>
            </div>
            <div class="col-6">
              <label class="form-label mt-1 text-primary">Id Documento <span class="font-small-2">*</span></label>
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.receptionData.lineNumber.value">
            </div>
            <div class="col-6">
              <input type="text" class="form-control" [(ngModel)]="newInvoice.body.receptionData.documentId">
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Data</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Numero Item</label>
            </div>
            <div class="col-6">
              <div class="input-group">
                <input class="form-control" placeholder="{{newInvoice.body.receptionData.date.value | date}}"
                       [(ngModel)]="newInvoice.body.receptionData.date.value" ngbDatepicker #basicDP6="ngbDatepicker">
                <div class="input-group-append">
                  <button class="feather icon-calendar cursor-pointer" (click)="basicDP6.toggle()"
                          type="button" rippleEffect>
                    <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.receptionData.itemNumber.value">
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice Commessa Convenzione</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice CUP</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.receptionData.conventionCode.value">
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.receptionData.CUPCode.value">
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice CIG</label>
            </div>
            <div class="col-12">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.receptionData.CIGCode.value">
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-12 d-flex flex-row">
        <label class="form-label mt-1 font-weight-bold">Dati Fatture Collegate</label>
      </div>
      <div class="col-12">
        <ng-container>
          <div class="row">
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Riferimento Numero Linea</label>
            </div>
            <div class="col-6">
              <label class="form-label mt-1 text-primary">Id Documento <span class="font-small-2">*</span></label>
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.linkedInvoicesData.lineNumber.value">
            </div>
            <div class="col-6">
              <input type="text" class="form-control" [(ngModel)]="newInvoice.body.linkedInvoicesData.documentId">
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Data</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Numero Item</label>
            </div>
            <div class="col-6">
              <div class="input-group">
                <input class="form-control" placeholder="{{newInvoice.body.linkedInvoicesData.date.value | date}}"
                       [(ngModel)]="newInvoice.body.linkedInvoicesData.date.value" ngbDatepicker
                       #basicDP7="ngbDatepicker">
                <div class="input-group-append">
                  <button class="feather icon-calendar cursor-pointer" (click)="basicDP7.toggle()"
                          type="button" rippleEffect>
                    <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.linkedInvoicesData.itemNumber.value">
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice Commessa Convenzione</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice CUP</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.linkedInvoicesData.conventionCode.value">
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.linkedInvoicesData.CUPCode.value">
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice CIG</label>
            </div>
            <div class="col-12">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.linkedInvoicesData.CIGCode.value">
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-12 d-flex flex-row">
        <label class="form-label mt-1 font-weight-bold">Dati SAL </label>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="form-label text-primary">Riferimento Fase</label>
        </div>
        <div class="col-12">
          <input type="text" class="form-control" [(ngModel)]="newInvoice.body.SALData.phaseReference">
        </div>
      </div>
      <div class="col-12 d-flex flex-row">
        <input type="checkbox" class="form-check-input mt-1 mr-05"
               [(ngModel)]="newInvoice.body.DDTData.active">
        <label class="form-label mt-1 font-weight-bold">Dati DDT</label>
      </div>
      <div *ngIf="newInvoice.body.DDTData.active" class="row">
        <div class="col-6">
          <label class="form-label text-primary">Numero DDT</label>
        </div>
        <div class="col-6">
          <label class="form-label text-primary">Data DDT</label>
        </div>
        <div class="col-6">
          <input type="text" class="form-control" [(ngModel)]="newInvoice.body.DDTData.DDTNumber">
        </div>
        <div class="col-6">
          <div class="input-group">
            <input class="form-control" placeholder="{{newInvoice.body.DDTData.DDTDate | date}}"
                   [(ngModel)]="newInvoice.body.DDTData.DDTDate" ngbDatepicker #basicDP8="ngbDatepicker">
            <div class="input-group-append">
              <button class="feather icon-calendar cursor-pointer" (click)="basicDP8.toggle()"
                      type="button" rippleEffect>
                <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex flex-row">
          <label class="form-label mt-1 text-primary">Riferimento Numero Linea</label>
        </div>
        <div class="col-12">
          <input type="text" class="form-control"
                 [(ngModel)]="newInvoice.body.DDTData.lineNumber.value">
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Close click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<ng-template #datiTrasportoModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Dati Trasporto</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 d-flex flex-row">
      <h5 class="card-title text-primary">Dati Trasporto</h5>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-12 d-flex flex-row">
            <input type="checkbox" class="form-check-input mt-1 mr-05"
                   [(ngModel)]=newInvoice.body.transportData.carrier.active>
            <label class="form-label mt-1 font-weight-bold">Dati Anagrafici Vettore</label>
          </div>
          <ng-container *ngIf="newInvoice.body.transportData.carrier.active">
            <div class="col-12">
              <label class="font-small-4 form-label text-primary">ID Fiscale IVA<span
                class="font-small-3">*</span></label>
            </div>
            <div class="col-4">
              <label class="form-label text-primary">Paese<span class="font-small-2">*</span></label>
            </div>
            <div class="col-8">
              <label class="form-label text-primary">Fiscale<span class="font-small-2">*</span></label>
            </div>
            <div class="col-4">
              <ng-select
                class="full-width"
                [items]="CountryFiscalOptions"
                [bindLabel]="'label'"
                [bindValue]="'value'"
                [multiple]="false"
                placeholder="Seleziona"
                [(ngModel)]="newInvoice.body.transportData.carrier.id.country">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{item.label}}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-8">
              <input type="text" class="form-control" [(ngModel)]="newInvoice.body.transportData.carrier.id.fiscal">
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Codice Fiscale</label>
            </div>
            <div class="col-12">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.carrier.taxCode.value">
            </div>
            <div class="col-12">
              <label class=" font-small-4 form-label mt-2 text-primary">Anagrafica<span
                class="font-small-3">*</span></label>
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label text-primary">Denominazione</label>
            </div>
            <div class="col-12">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.carrier.personalData.denominazione.value">
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Nome</label>
            </div>
            <div class="col-6  d-flex flex-row">
              <label class="form-label mt-1 text-primary">Cognome</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.carrier.personalData.name.value">
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.carrier.personalData.surname.value">
            </div>
            <div class="col-6  d-flex flex-row">
              <label class="form-label mt-1 text-primary">Titolo</label>
            </div>
            <div class="col-6  d-flex flex-row">
              <label class="form-label mt-1 text-primary">CodEORI</label>
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.carrier.personalData.title.value">
            </div>
            <div class="col-6">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.carrier.personalData.codEORI.value">
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Numero Licenza Guida</label>
            </div>
            <div class="col-12">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.carrier.driverLicenseNumber.value">
            </div>
          </ng-container>
          <div class="col-12 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Mezzo Trasporto</label>
          </div>
          <div class="col-12">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newInvoice.body.transportData.transportVehicle.value">
          </div>
          <div class="col-12 d-flex flex-row">

            <label class="form-label mt-1 text-primary">Causale Trasporto</label>
          </div>
          <div class="col-12">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newInvoice.body.transportData.transportReason.value">
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-3 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Numero Colli</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Descrizione</label>
          </div>
          <div class="col-3 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Unita Misura Peso</label>
          </div>
          <div class="col-3">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newInvoice.body.transportData.numberOfPackages.value">
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newInvoice.body.transportData.description.value">
          </div>
          <div class="col-3">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newInvoice.body.transportData.weightUnitMeasure.value">
          </div>
          <div class="col-4 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Peso Lordo</label>
          </div>
          <div class="col-4 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Peso Netto</label>
          </div>
          <div class="col-4 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Ora Ritiro</label>
          </div>
          <div class="col-4">
            <input type="text" class="form-control"
                   [(ngModel)]="newInvoice.body.transportData.grossWeight.value">
          </div>
          <div class="col-4">
            <input type="text" class="form-control"
                   [(ngModel)]="newInvoice.body.transportData.netWeight.value">
          </div>
          <div class="col-4">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newInvoice.body.transportData.pickupDateTime.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Inizio Trasporto</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Tipo Resa</label>
          </div>
          <div class="col-6">
            <div class="input-group">
              <input class="form-control"
                     placeholder="{{newInvoice.body.transportData.transportStartDate.value | date}}"
                     [(ngModel)]="newInvoice.body.transportData.transportStartDate.value" ngbDatepicker
                     #basicDP9="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP9.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newInvoice.body.transportData.deliveryType.value">
          </div>
          <div class="col-12 d-flex flex-row">
            <input type="checkbox" class="form-check-input mt-1 mr-05"
                   [(ngModel)]="newInvoice.body.transportData.deliveryAddress.active">
            <label class="form-label mt-1 font-weight-bold">Indirizzo Resa</label>
          </div>
          <ng-container *ngIf="newInvoice.body.transportData.deliveryAddress.active">
            <div class="col-7">
              <label class="form-label mt-1 text-primary">Indirizzo<span class="font-small-2">*</span></label>
            </div>
            <div class="col-5 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Numero Civico</label>
            </div>
            <div class="col-7">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.deliveryAddress.street">
            </div>
            <div class="col-5">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.deliveryAddress.streetNumber.value">
            </div>
            <div class="col-3 mt-1">
              <label class="form-label text-primary">CAP<span class="font-small-2">*</span></label>
            </div>
            <div class="col-3 mt-1">
              <label class="form-label text-primary">Comune<span class="font-small-2">*</span></label>
            </div>
            <div class="col-3 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Provincia</label>
            </div>
            <div class="col-3 mt-1">
              <label class="form-label text-primary">Nazione<span class="font-small-2">*</span></label>
            </div>
            <div class="col-3">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.deliveryAddress.postalCode">
            </div>
            <div class="col-3">
              <input type="text" class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.deliveryAddress.city">
            </div>
            <div class="col-3">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="newInvoice.body.transportData.deliveryAddress.province.value">
            </div>
            <div class="col-3">
              <ng-select
                class="full-width"
                [items]="CountryFiscalOptions"
                [bindLabel]="'label'"
                [bindValue]="'value'"
                [multiple]="false"
                placeholder="Seleziona"
                [(ngModel)]="newInvoice.body.transportData.deliveryAddress.country">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{item.label}}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
          </ng-container>
          <div class="col-12 d-flex flex-row">
            <label class="form-label mt-2 text-primary">Data Ora Consegna</label>
          </div>
          <div class="col-12">
            <div class="input-group">
              <input class="form-control"
                     placeholder="{{newInvoice.body.transportData.deliveryDateTime.value | date}}"
                     [(ngModel)]="newInvoice.body.transportData.deliveryDateTime.value" ngbDatepicker
                     #basicDP10="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP10.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex flex-row">
            <input type="checkbox" class="form-check-input mt-1 mr-05"
                   [(ngModel)]="newInvoice.body.mainInvoiceData.active">
            <label class="form-label mt-1 font-weight-bold">Fattura Principale</label>
          </div>
          <ng-container *ngIf="newInvoice.body.mainInvoiceData.active">
            <div class="col-6">
              <label class="form-label text-primary">Numero Fattura Principale</label>
            </div>
            <div class="col-6">
              <label class="form-label text-primary">Data Fattura Principale</label>
            </div>
            <div class="col-6">
              <input type="text" class="form-control" [(ngModel)]="newInvoice.body.mainInvoiceData.number">
            </div>
            <div class="col-6">
              <div class="input-group">
                <input class="form-control"
                       placeholder="{{newInvoice.body.mainInvoiceData.date | date}}"
                       [(ngModel)]="newInvoice.body.mainInvoiceData.date" ngbDatepicker
                       #basicDP11="ngbDatepicker">
                <div class="input-group-append">
                  <button class="feather icon-calendar cursor-pointer" (click)="basicDP11.toggle()"
                          type="button" rippleEffect>
                    <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Close click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<ng-template #datiBeniServiziModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Dati Beni Servizi</h5>
    <button type="button" class="close" (click)="closeLineModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class=" row">
      <div class="col-12">
        <h6 class="text-primary mt-2">Dettaglio Linee {{ selectedLineIndex }}</h6>
        <div class="row">
          <div class="col-6">
            <label class="form-label text-primary">Numero Linea<span class="font-small-2">*</span></label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label text-primary">Tipo Cessione Prestazione</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control" [(ngModel)]="selectedLine.lineNumber">
          </div>
          <div class="col-6">
            <ng-select
              class="full-width"
              [items]="[{value: '', name: 'Prodotto/Servizio'}, {value: 'SC', name: 'Sconto(SC)'}, {value: 'PR', name: 'Premio(PR)'}, {value: 'AB', name: 'Abbuono(AB)'}, {value: 'AC', name: 'Spesa Accessoria(AC)'}]"
              [bindLabel]="'name'"
              [bindValue]="'value'"
              [multiple]="false"
              placeholder="Seleziona"
              [(ngModel)]="selectedLine.transferType.value">
              <ng-template ng-option-tmp let-item="item">
                <div title="{{item.name}}">{{ item.name }}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-12 d-flex flex-row">
            <input type="checkbox" class="form-check-input mt-1 mr-05"
                   [(ngModel)]="selectedLine.articleCode.active">
            <label class="form-label mt-1 font-weight-bold">Codice Articolo</label>
          </div>
          <ng-container *ngIf="selectedLine.articleCode.active">
            <div class="col-6">
              <label class="form-label text-primary">Codice Tipo</label>
            </div>
            <div class="col-6">
              <label class="form-label text-primary">Codice Valore</label>
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="selectedLine.articleCode.type">
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="selectedLine.articleCode.value">
            </div>
          </ng-container>
          <div class="col-12 mt-1">
            <label class="form-label mt-1 font-weight-bold">Descrizione<span
              class="font-small-2">*</span></label>
          </div>
          <div class="col-12">
            <input type="text" class="form-control" [(ngModel)]="selectedLine.description">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Quantita</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Unita Misura</label>
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="selectedLine.quantity.value">
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="selectedLine.unitMeasure.value">
          </div>

          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Inizio Periodo</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Fine Periodo</label>
          </div>
          <div class="col-6">
            <div class="input-group">
              <input class="form-control"
                     placeholder="{{selectedLine.periodStartDate.value | date}}"
                     [(ngModel)]="selectedLine.periodStartDate.value" ngbDatepicker
                     [maxDate]="selectedLine.periodEndDate.value"
                     #basicDP12="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP12.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
                <button class="feather icon-x cursor-pointer" (click)="clearStartDate()" type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="close"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group">
              <input class="form-control"
                     placeholder="{{selectedLine.periodEndDate.value | date}}"
                     [(ngModel)]="selectedLine.periodEndDate.value" ngbDatepicker
                     [minDate]="selectedLine.periodStartDate.value"
              #basicDP13="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP13.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
                <button class="feather icon-x cursor-pointer" (click)="clearEndDate()" type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="close"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="col-12 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Prezzo Unitario<span
              class="font-small-2">*</span></label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="selectedLine.unitPrice">
          </div>

          <div class="col-12 d-flex flex-row">
            <input type="checkbox" class="form-check-input mt-1 mr-05"
                   [(ngModel)]="selectedLine.discountIncrease.active">
            <label class="form-label mt-1 font-weight-bold">Sconto Maggiorazione</label>
          </div>
          <ng-container *ngIf="selectedLine.discountIncrease.active">
            <div class="col-4">
              <label class="form-label mt-1 text-primary">Tipo<span class="font-small-2">*</span></label>
            </div>
            <div class="col-4 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Percentuale</label>
            </div>
            <div class="col-4 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Importo</label>
            </div>
            <div class="col-4">
              <ng-select
                class="full-width"
                [items]="[{value: 'SC', name: 'Sconto(SC)'}, {value: 'MG', name: 'Maggiorazione(MG)'}]"
                [bindLabel]="'name'"
                [bindValue]="'value'"
                [multiple]="false"
                placeholder="Seleziona"
                [(ngModel)]="selectedLine.discountIncrease.discountType">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{item.name}}">{{ item.name }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-4">
              <input
                type="text" class="form-control"
                [(ngModel)]="selectedLine.discountIncrease.discountPercentage.value">
            </div>
            <div class="col-4">
              <input
                type="text" class="form-control"
                [(ngModel)]="selectedLine.discountIncrease.discountAmount.value">
            </div>
          </ng-container>
          <div class="col-6 mt-1">
            <label class="form-label text-primary">Prezzo Totale<span class="font-small-2">*</span></label>
          </div>
          <div class="col-6 mt-1">
            <label class="form-label text-primary">Aliquota IVA<span class="font-small-2">*</span></label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control" [(ngModel)]="selectedLine.totalPrice">
          </div>
          <div class="col-6">
            <input type="text" class="form-control" [(ngModel)]="selectedLine.VATRate"
                   (ngModelChange)="updateSummaryDetails()">
          </div>
          <div class="col-3 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Ritenuta</label>
          </div>
          <div class="col-4 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Natura</label>
          </div>
          <div class="col-5 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Riferimento Amministrativo</label>
          </div>
          <div class="col-3">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="selectedLine.withholding.value">
          </div>
          <div class="col-4">
            <ng-select class="full-width"
                       [items]="naturaOptions"
                       [bindLabel]="'label'"
                       [bindValue]="'value'"
                       [multiple]="false"
                       placeholder="Seleziona"
                       [(ngModel)]="selectedLine.nature.value"
                       (ngModelChange)="updateSummaryDetails()">
              <ng-template ng-option-tmp let-item="item">
                <div title="{{item.label}}">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-5">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="selectedLine.administrativeReference.value">
          </div>
          <div class="col-12 d-flex flex-row">
            <input type="checkbox" class="form-check-input mt-1 mr-05"
                   [(ngModel)]=selectedLine.managementData.active>
            <label class="form-label mt-1 font-weight-bold">Altri Dati Gestionali</label>
          </div>
          <ng-container *ngIf="selectedLine.managementData.active">
            <div class="col-6">
              <label class="form-label mt-1 text-primary">Tipo Dato<span class="font-small-2">*</span></label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Riferimento Testo</label>
            </div>
            <div class="col-6">
              <input type="text" class="form-control"
                     [(ngModel)]="selectedLine.managementData.type">
            </div>
            <div class="col-6">
              <input
                type="text" class="form-control"
                [(ngModel)]="selectedLine.managementData.textReference.value">
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Riferimento Numero </label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary">Riferimento Data</label>
            </div>
            <div class="col-6">
              <input
                type="text" class="form-control"
                [(ngModel)]="selectedLine.managementData.numberReference.value">
            </div>
            <div class="col-6">
              <div class="input-group">
                <input class="form-control"
                       placeholder="{{selectedLine.managementData.dateReference.value | date}}"
                       [(ngModel)]="selectedLine.managementData.dateReference.value" ngbDatepicker
                       #basicDP14="ngbDatepicker">
                <div class="input-group-append">
                  <button class="feather icon-calendar cursor-pointer" (click)="basicDP14.toggle()"
                          type="button" rippleEffect>
                    <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!--    <div class="row">-->
    <!--      <ng-container *ngFor="let line of newInvoice.body.goodsServicesData.summaryDetails; let i = index">-->
    <!--        <div class="col-6">-->
    <!--          <div class="row  mt-2">-->
    <!--            <h6 class="text-primary">Dati Riepilogo {{ i + 1 }}</h6>-->
    <!--            <div class="col-6">-->
    <!--              <label class="form-label mt-1 text-primary font-weight-bold">Aliquota IVA</label>-->
    <!--            </div>-->
    <!--            <div class="col-6 d-flex flex-row">-->
    <!--              <label class="form-label mt-1 text-primary font-weight-bold">Natura</label>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <span class="text-primary">{{ line.VATRate }}</span>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <span class="text-primary">{{ line.nature.value }}</span>-->
    <!--            </div>-->

    <!--            <div class="col-6 d-flex flex-row">-->
    <!--              <label class="form-label mt-1 text-primary font-weight-bold">Spese Accessorie</label>-->
    <!--            </div>-->
    <!--            <div class="col-6 d-flex flex-row">-->
    <!--              <label class="form-label mt-1 text-primary font-weight-bold">Arrotondamento</label>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <span class="text-primary">{{ line.additionalExpenses.value }}</span>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <span class="text-primary">{{ line.rounding.value }}</span>-->
    <!--            </div>-->
    <!--            <div class="col-6 mt-1">-->
    <!--              <label class="form-label text-primary font-weight-bold">Imponibile Importo</label>-->
    <!--            </div>-->
    <!--            <div class="col-6 mt-1">-->
    <!--              <label class="form-label text-primary font-weight-bold">Imposta</label>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <span class="text-primary">{{ line.taxableAmount }}</span>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <span class="text-primary">{{ line.taxAmount }}</span>-->
    <!--            </div>-->
    <!--            <div class="col-6 d-flex flex-row">-->
    <!--              <label class="form-label mt-1 text-primary font-weight-bold">Esigibilita IVA</label>-->
    <!--            </div>-->
    <!--            <div class="col-6 d-flex flex-row">-->
    <!--              <label class="form-label mt-1 text-primary font-weight-bold">Riferimento Normativo</label>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <span class="text-primary">{{ line.VATExigibility.value }}</span>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <span class="text-primary">{{ line.regulatoryReference.value }}</span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </ng-container>-->
    <!--    </div>-->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeLineModal()">Cancello</button>
    <button type="button" class="btn btn-primary" (click)="saveLineModal()">Salva</button>
  </div>
</ng-template>


<ng-template #datiVeicoliModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Dati Veicoli</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 d-flex flex-row">
      <h5 class="card-title mt-1 text-primary">Dati Veicoli</h5>
    </div>
    <div class="row">
      <div class="col-6">
        <label class="form-label text-primary">Data<span class="font-small-2">*</span></label>
      </div>
      <div class="col-6">
        <label class="form-label text-primary">Totale Percorso<span class="font-small-2">*</span></label>
      </div>
      <div class="col-6">
        <div class="input-group">
          <input class="form-control"
                 placeholder="{{newInvoice.body.vehicleData.date | date}}"
                 [(ngModel)]="newInvoice.body.vehicleData.date" ngbDatepicker
                 #basicDP15="ngbDatepicker">
          <div class="input-group-append">
            <button class="feather icon-calendar cursor-pointer" (click)="basicDP15.toggle()"
                    type="button" rippleEffect>
              <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-6">
        <input type="text" class="form-control" [(ngModel)]="newInvoice.body.vehicleData.totalDistance">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Close click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<ng-template #newPaymentModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Nuovo Pagamento</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <label class="form-label mt-1 text-primary">Modalita Pagamento<span
              class="font-small-2">*</span></label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Importo Pagamento<span
              class="font-small-2">*</span></label>
          </div>
          <div class="col-6">
            <ng-select class="full-width"
                       [items]="modalitaPagamentoOptions"
                       [bindLabel]="'label'"
                       [bindValue]="'value'"
                       [multiple]="false"
                       placeholder="Seleziona"
                       [(ngModel)]="newPayment.paymentMethod">
              <ng-template ng-option-tmp let-item="item">
                <div title="{{item.label}}">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.paymentAmount">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Beneficiario</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Giorni Termini Pagamento</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.beneficiary.value">
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.paymentTermsDays.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Scadenza Pagamento</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Riferimento Termini Pagamento</label>
          </div>
          <div class="col-6">
            <div class="input-group">
              <input class="form-control"
                     placeholder="{{newPayment.paymentDueDate.value | date}}"
                     [(ngModel)]="newPayment.paymentDueDate.value" ngbDatepicker
                     #basicDP17="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP17.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group">
              <input class="form-control"
                     placeholder="{{newPayment.paymentTermsReferenceDate.value | date}}"
                     [(ngModel)]="newPayment.paymentTermsReferenceDate.value" ngbDatepicker
                     #basicDP16="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP16.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Cod Ufficio Postale</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Cognome Quietanzante</label>
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.postOfficeCode.value">
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.payerSurname.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Nome Quietanzante</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">CF Quietanzante</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.payerName.value">
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.payerCF.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Titolo Quietanzante</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Istituto Finanziario</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.payerTitle.value">
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.financialInstitution.value">
          </div>
          <div class="col-12 d-flex flex-row">
            <label class="form-label mt-1 text-primary">IBAN</label>
          </div>
          <div class="col-12">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.IBAN.value">
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">ABI</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">CAB</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.ABI.value">
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.CAB.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">BIC</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Sconto Pagamento Anticipato</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.BIC.value">
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.earlyPaymentDiscount.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Limite Pagamento Anticipato</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Penalita Pagamenti Ritardati</label>
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.earlyPaymentDeadline.value">
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.latePaymentPenalty.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Decorrenza Penale</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Codice Pagamento</label>
          </div>
          <div class="col-6">
            <div class="input-group">
              <input class="form-control"
                     placeholder="{{newPayment.penaltyStartDate.value | date}}"
                     [(ngModel)]="newPayment.penaltyStartDate.value" ngbDatepicker
                     #basicDP18="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP18.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.paymentCode.value">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="cancelAddPayment()">Annulla</button>
    <button type="button" class="btn btn-primary" (click)=addNewPayment()>Salva</button>
  </div>
</ng-template>


<ng-template #editpaymentmodal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Nuovo Pagamento</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <label class="form-label mt-1 text-primary">Modalita Pagamento<span
              class="font-small-2">*</span></label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Importo Pagamento<span
              class="font-small-2">*</span></label>
          </div>
          <div class="col-6">
            <ng-select class="full-width"
                       [items]="modalitaPagamentoOptions"
                       [bindLabel]="'label'"
                       [bindValue]="'value'"
                       [multiple]="false"
                       placeholder="Seleziona"
                       [(ngModel)]="newPayment.paymentMethod">
              <ng-template ng-option-tmp let-item="item">
                <div title="{{item.label}}">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.paymentAmount">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Beneficiario</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Giorni Termini Pagamento</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.beneficiary.value">
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.paymentTermsDays.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Scadenza Pagamento</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Riferimento Termini Pagamento</label>
          </div>
          <div class="col-6">
            <div class="input-group">
              <input class="form-control"
                     placeholder="{{newPayment.paymentDueDate.value | date}}"
                     [(ngModel)]="newPayment.paymentDueDate.value" ngbDatepicker
                     #basicDP17="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP17.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group">
              <input class="form-control"
                     placeholder="{{newPayment.paymentTermsReferenceDate.value | date}}"
                     [(ngModel)]="newPayment.paymentTermsReferenceDate.value" ngbDatepicker
                     #basicDP16="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP16.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Cod Ufficio Postale</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Cognome Quietanzante</label>
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.postOfficeCode.value">
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.payerSurname.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Nome Quietanzante</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">CF Quietanzante</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.payerName.value">
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.payerCF.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Titolo Quietanzante</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Istituto Finanziario</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.payerTitle.value">
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.financialInstitution.value">
          </div>
          <div class="col-12 d-flex flex-row">
            <label class="form-label mt-1 text-primary">IBAN</label>
          </div>
          <div class="col-12">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.IBAN.value">
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">ABI</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">CAB</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.ABI.value">
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.CAB.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">BIC</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Sconto Pagamento Anticipato</label>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.BIC.value">
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.earlyPaymentDiscount.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Limite Pagamento Anticipato</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Penalita Pagamenti Ritardati</label>
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.earlyPaymentDeadline.value">
          </div>
          <div class="col-6">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="newPayment.latePaymentPenalty.value">
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Data Decorrenza Penale</label>
          </div>
          <div class="col-6 d-flex flex-row">
            <label class="form-label mt-1 text-primary">Codice Pagamento</label>
          </div>
          <div class="col-6">
            <div class="input-group">
              <input class="form-control"
                     placeholder="{{newPayment.penaltyStartDate.value | date}}"
                     [(ngModel)]="newPayment.penaltyStartDate.value" ngbDatepicker
                     #basicDP18="ngbDatepicker">
              <div class="input-group-append">
                <button class="feather icon-calendar cursor-pointer" (click)="basicDP18.toggle()"
                        type="button" rippleEffect>
                  <app-svg-icon class="d-inline-block align-middle" name="calendar"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <input type="text" class="form-control"
                   [(ngModel)]="newPayment.paymentCode.value">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeEditPaymentModal()">Cancello</button>
    <button type="button" class="btn btn-primary" (click)="saveEditPaymentModal()">Salva</button>
  </div>
</ng-template>


<ng-template #datiPagamentoModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Dati Pagamento</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 d-flex flex-row">
      <h5 class="card-title mt-1 text-primary">Condizioni Pagamento <span
        class="font-small-2">*</span></h5>
    </div>
    <div class="row">
      <div class="col-12">
        <ng-select class="full-width mt-05"
                   [items]="paymentConditionItems"
                   [bindLabel]="'label'"
                   [bindValue]="'value'"
                   [multiple]="false"
                   placeholder="Seleziona"
                   [(ngModel)]="selectedCondition.paymentCondition">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{item.label}}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div *ngIf=" selectedCondition.payments.length > 0" class="col-12 d-flex flex-row">
      <h5 class="card-title mt-1 text-primary">Dati Pagamento</h5>
    </div>
    <ng-container *ngFor="let payment of selectedCondition.payments; let i = index">
      <label class="form-label mt-1 text-primary font-weight-bold font-small-4">Pagamento {{ i + 1 }}</label>
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Beneficiario</label>
            </div>
            <div class="col-6">
              <label class="form-label mt-1 text-primary font-weight-bold">Modalita Pagamento<span
                class="font-small-2">*</span></label>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.beneficiary.value }}</span>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.paymentMethod }}</span>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Data Riferimento Termini
                Pagamento</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Giorni Termini Pagamento</label>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.paymentTermsReferenceDate.value | date }}</span>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.paymentTermsDays.value }}</span>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Data Scadenza Pagamento</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Importo Pagamento<span
                class="font-small-2">*</span></label>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.paymentDueDate.value | date }}</span>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.paymentAmount }}</span>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Cod Ufficio Postale</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Cognome Quietanzante</label>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.postOfficeCode.value }}</span>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.payerSurname.value }}</span>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Nome Quietanzante</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">CF Quietanzante</label>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.payerName.value }}</span>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.payerCF.value }}</span>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Titolo Quietanzante</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Istituto Finanziario</label>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.payerTitle.value }}</span>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.financialInstitution.value }}</span>
            </div>
            <div class="col-12 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">IBAN</label>
            </div>
            <div class="col-12">
              <span class="text-primary">{{ payment.IBAN.value }}</span>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">ABI</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">CAB</label>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.ABI.value }}</span>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.CAB.value }}</span>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">BIC</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Sconto Pagamento Anticipato</label>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.BIC.value }}</span>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.earlyPaymentDiscount.value }}</span>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Data Limite Pagamento Anticipato</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Penalita Pagamenti Ritardati</label>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.earlyPaymentDeadline.value }}</span>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.latePaymentPenalty.value }}</span>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Data Decorrenza Penale</label>
            </div>
            <div class="col-6 d-flex flex-row">
              <label class="form-label mt-1 text-primary font-weight-bold">Codice Pagamento</label>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.penaltyStartDate.value | date }}</span>
            </div>
            <div class="col-6">
              <span class="text-primary">{{ payment.paymentCode.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="cancelAddpaymentCondition()">Annulla</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Salva</button>
  </div>
</ng-template>


<ng-template #allegatiModal let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white">Allegati</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 d-flex flex-row">
      <h5 class="card-title mt-1 text-primary">Allegati</h5>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="form-label text-primary">Nome Attachment<span class="font-small-2">*</span></label>
      </div>
      <div class="col-12">
        <input type="text" class="form-control" [(ngModel)]="newInvoice.body.attachments.name">
      </div>
      <div class="col-12 d-flex flex-row">
        <label class="form-label mt-1 text-primary">Algoritmo Compressione</label>
      </div>
      <div class="col-12">
        <input type="text"
               class="form-control"
               [(ngModel)]="newInvoice.body.attachments.compressionAlgorithm.value">
      </div>
      <div class="col-12 d-flex flex-row">
        <label class="form-label mt-1 text-primary">Formato Attachment</label>
      </div>
      <div class="col-12">
        <input type="text" class="form-control"
               [(ngModel)]="newInvoice.body.attachments.attachmentFormat.value">
      </div>
      <div class="col-12 d-flex flex-row">
        <label class="form-label mt-1 text-primary">Descrizione Attachment</label>
      </div>
      <div class="col-12">
        <input type="text"
               class="form-control"
               [(ngModel)]="newInvoice.body.attachments.attachmentDescription.value">
      </div>
      <div class="col-12 mt-2">
        <label class="form-label text-primary">Attachment<span class="font-small-2">*</span></label>
      </div>
      <div class="col-12">
        <div class="file-upload-container">
          <label for="fileInput">Choose File</label>
          <input type="file" id="fileInput" (change)="onChange($event)"/>
          <button (click)="upload()">Upload</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Close click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>

