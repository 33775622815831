<div class="card glassy">
  <div class="card-header d-flex justify-content-between">
    <h3 class="card-title mb-1">Kromeda</h3>
  </div>

  <div class="card-body pt-0">
    <div class="row">
      <div class="col-4">
        <div class="stats-widget" *ngIf="loaded | async">
          <div class="stats-widget-title font-medium-2 font-weight-soft-bold">Targhe</div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold">
            <span *ngIf="plates.PlatesAvailable !== undefined && plates.PlatesUsed !== undefined">{{plates.PlatesAvailable - plates.PlatesUsed}}</span>
            <span *ngIf="plates.PlatesAvailable === undefined || plates.PlatesUsed === undefined">--</span>
          </div>
          <div class="stats-widget-value font-small-3 font-weight-soft-bold">
            <span *ngIf="plates.PlatesUsed !== undefined">{{plates.PlatesUsed}}</span>
            <span *ngIf="plates.PlatesUsed === undefined">--</span>
            /
            <span *ngIf="plates.PlatesAvailable !== undefined">{{plates.PlatesAvailable}}</span>
            <span *ngIf="plates.PlatesAvailable === undefined">--</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="stats-widget" *ngIf="loaded | async">
          <div class="stats-widget-title font-medium-2 font-weight-soft-bold">Vin</div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold">
            <span *ngIf="vins.VinsAvailable !== undefined && vins.VinsUsed !== undefined">{{vins.VinsAvailable - vins.VinsUsed}}</span>
            <span *ngIf="vins.VinsAvailable === undefined || vins.VinsUsed === undefined">--</span>
          </div>
          <div class="stats-widget-value font-small-3 font-weight-soft-bold">
            <span *ngIf="vins.VinsUsed !== undefined">{{vins.VinsUsed}}</span>
            <span *ngIf="vins.VinsUsed === undefined">--</span>
            /
            <span *ngIf="vins.VinsAvailable !== undefined">{{vins.VinsAvailable}}</span>
            <span *ngIf="vins.VinsAvailable === undefined">--</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="stats-widget" *ngIf="loaded | async">
          <div class="stats-widget-title font-medium-2 font-weight-soft-bold">Richieste</div>
          <div class="stats-widget-value font-medium-5 font-weight-soft-bold">
            <span *ngIf="requests.RequestsAvailable !== undefined && requests.RequestsUsed !== undefined">{{requests.RequestsAvailable - requests.RequestsUsed}}</span>
            <span *ngIf="requests.RequestsAvailable === undefined || requests.RequestsUsed === undefined">--</span>
          </div>
          <div class="stats-widget-value font-small-3 font-weight-soft-bold">
            <span *ngIf="requests.RequestsUsed !== undefined">{{requests.RequestsUsed}}</span>
            <span *ngIf="requests.RequestsUsed === undefined">--</span>
            /
            <span *ngIf="requests.RequestsAvailable !== undefined">{{requests.RequestsAvailable}}</span>
            <span *ngIf="requests.RequestsAvailable === undefined">--</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
