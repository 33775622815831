import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { InvoicesListComponent } from "./invoices-list/invoices-list.component";
import { InvoiceViewComponent } from "./invoice-view/invoice-view.component";
import { CoreModule } from "@core/components/core.module";
import {NgbInputDatepicker, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import { CustomUrlSerializer } from "@core/helpers/UrlSerializer";
import {UrlSerializer } from '@angular/router';
import {NgSelectModule} from "@ng-select/ng-select";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InvoiceNewComponent} from "./invoice-new/invoice-new.component";

const routes: Routes = [
  {
    path: 'invoices',
    component: InvoicesListComponent,
    data: { animation: 'InvoicesListComponent' }
  },
  {
    path: 'invoices/new',
    component: InvoiceNewComponent,
    data: { animation: 'InvoiceNewComponent' }
  },
  {
    path: 'invoices/:code',
    component: InvoiceViewComponent,
    data: { animation: 'OrdersViewComponent' }
  },
];

@NgModule({
  declarations: [
    InvoicesListComponent,
    InvoiceViewComponent,
    InvoiceNewComponent,
    InvoiceNewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    NgbTooltip,
    NgSelectModule,
    TranslateModule,
    FormsModule,
    NgbInputDatepicker,
    ReactiveFormsModule
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
  ]
})
export class InvoicesModule {
}
