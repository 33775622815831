import { Component, OnInit } from '@angular/core';
import { ApplicationService } from "@core/services/application.service";
import {Subject, takeUntil} from "rxjs";
import {MenuGroup} from "../../../@core/types/app/menu";

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {
  public logo: string = '';
  private _unsubscribeAll: Subject<any>;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _applicationService: ApplicationService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutFullWidth();
    this._applicationService.logo.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((logo: string) => {
        this.logo = logo;
      });

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._applicationService.setTitle("404 Not Found");
  }
}
