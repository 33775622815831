import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateHumanized'
})
export class DateHumanizedPipe implements PipeTransform {
  transform(date: any, format?: string): string {
    if (!(date instanceof Date)) {
      //new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0, 0)
      if (date.year && date.month && date.day) {
        date = new Date(date.year, date.month - 1, date.day);
      } else {
        date = new Date(date);
      }
    }
    if (format) {
      let dateString = format;
      const formatOptions = format.split(/[^a-zA-Z0-9]/gmi)
      formatOptions.forEach(option => {
        switch (option) {
          case 'd':
            dateString = dateString.replace('d', date.getDate().toString().padStart(2, '0'));
            break;
          case 'm':
            dateString = dateString.replace('m', (date.getMonth() + 1).toString().padStart(2, '0'));
            break;
          case 'Y':
            dateString = dateString.replace('Y', date.getFullYear().toString());
            break;
          case 'H':
            dateString = dateString.replace('H', date.getHours().toString().padStart(2, '0'));
            break;
          case 'i':
            dateString = dateString.replace('i', date.getMinutes().toString().padStart(2, '0'));
            break;
          case 's':
            dateString = dateString.replace('s', date.getSeconds().toString().padStart(2, '0'));
            break;
        }
      })
      return dateString;
    }
    const now = new Date();
    if (now.getFullYear() !== date.getFullYear()) {
      return date.toLocaleDateString();
    } else if (now.getMonth() !== date.getMonth()) {
      return getDayMonth(date);
    } else if (now.getDate() !== date.getDate()) {
      const days = Math.floor((date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
      if (days === 1) {
        return 'Domani';
      } else if (days < 7) {
        const dayNames = ['Domenica', 'Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato'];
        return dayNames[date.getDay()];
      } else {
        return getDayMonth(date);
      }
    } else {
      return 'Oggi';
    }
  }
}

export const getDayMonth = (date: Date) => {
  return date.toLocaleDateString().split('/').slice(0, 2).join('/');
}
