import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApplicationService } from "@core/services/application.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ShopService } from "@core/services/shop.service";
import { DynamicData } from "@core/types/dynamic-table/dynamic-data";
import Swal from "sweetalert2";


@Component({
  selector: 'app-payment-methods-view',
  templateUrl: './payment-methods-view.component.html',
  styleUrls: ['./payment-methods-view.component.scss']
})

export class PaymentMethodsViewComponent implements OnInit, OnDestroy {

  public paymentMethod: any = null;
  public currentId: any;
  public loaded: Promise<boolean> = Promise.resolve(false);
  public feeSum = 0;
  public endOfMonthCheckbox: boolean = false;
  public cumulativeDaysCheckbox: boolean = false;
  public invoiceCodes:  any[] = [
    {
      label: 'Contanti (MP01)',
      value: 'MP01'
    },
    {
      label: 'Assegno (MP02)',
      value: 'MP02'
    },
    {
      label: 'Assegno circolare (MP03)',
      value: 'MP03'
    },
    {
      label: 'Contanti presso Tesoreria (MP04)',
      value: 'MP04'
    },
    {
      label: 'Bonifico (MP05)',
      value: 'MP05'
    },
    {
      label: 'Vaglia cambiario (MP06)',
      value: 'MP06'
    },
    {
      label: 'Bollettino bancario (MP07)',
      value: 'MP07'
    },
    {
      label: 'Carta di credito (MP08)',
      value: 'MP08'
    },
    {
      label: 'RID (MP09)',
      value: 'MP09'
    },
    {
      label: 'RID utenze (MP10)',
      value: 'MP10'
    },
    {
      label: 'RID veloce (MP11)',
      value: 'MP11'
    },
    {
      label: 'Bonifico (MP12)',
      value: 'MP12'
    },
    {
      label: 'Riba (MP13)',
      value: 'MP13'
    },
    {
      label: 'Quietanza erario stato (MP14)',
      value: 'MP14'
    },
    {
      label: 'Giroconto su conti di contabilità speciale (MP15)',
      value: 'MP15'
    },
    {
      label: 'Domiciliazione bancaria (MP16)',
      value: 'MP16'
    },
    {
      label: 'Domiciliazione postale (MP17)',
      value: 'MP17'
    },
  ];

  public drivers:  any[] = [
    'CASHBACK',
    'DEBT',
    'KLARNA',
    'MYBANK',
    'PAUSEPAY',
    'RIBAAUTO180',
    'STRIPE',
    ];

  // Private
  private _unsubscribeAll: Subject<any>;



  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _route: ActivatedRoute,

  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-payment-methods-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loaded = Promise.resolve(false);
    this.currentId = this._route.snapshot.paramMap.get('id') || '';
    this._shopService.viewPaymentMethod(this.currentId)
      .then((response: any) => {
        this.paymentMethod = response;
        for (var i = 0; i < this.paymentMethod.installments.length; i++) {
          this.feeSum += this.paymentMethod.installments[i].fee;
        }
        if(this.paymentMethod.endOfMonth === -1){
          this.endOfMonthCheckbox = false;
        }
        else {
          this.endOfMonthCheckbox = true;
        }
        if(this.paymentMethod.cumulativeDays === -1){
          this.cumulativeDaysCheckbox = false;
        }
        else {
          this.paymentMethod.cumulativeDays++;
          this.cumulativeDaysCheckbox = true;
        }
        this.loaded = Promise.resolve(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  savePaymentMethod() {
    if(this.feeSum !== 100){
      Swal.fire({
        title: 'Errore!',
        text: "La somma delle rate deve essere uguale a 100!",
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      return;
    }
    else{
      if(this.paymentMethod.cumulativeDays > -1){
        this.paymentMethod.cumulativeDays--;
      }
      this._shopService.savePaymentMethod(this.currentId,this.paymentMethod)
        .then((response) => {
          this._router.navigate(['/payment-methods']);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  deletePaymentMethod() {
    //check if the user is sure with Swal
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Non sarà possibile recuperare i dati eliminati!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#790af8',
      cancelButtonColor: '#ff740f',
      confirmButtonText: 'Elimina'
    }).then((result) => {
      if (result.isConfirmed) {
        this._shopService.deletePaymentMethod(this.currentId)
          .then((response) => {
            this._router.navigate(['/payment-methods']);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });

  }

  toggleEndOfMonth() {
    if(this.endOfMonthCheckbox){
      this.paymentMethod.endOfMonth = 0;
    }
    else {
      this.paymentMethod.endOfMonth = -1;
    }
  }

  toggleCumulativeDays() {
    if(this.cumulativeDaysCheckbox){
      this.paymentMethod.cumulativeDays = 0;
    }
    else {
      this.paymentMethod.cumulativeDays = -1;
    }
  }

  addInstallment() {
    this.paymentMethod.installments.push({fee: null, days: null});
  }

  recalculateFee() {
    this.feeSum = 0;
    for (var i = 0; i < this.paymentMethod.installments.length; i++) {
      this.feeSum += this.paymentMethod.installments[i].fee;
    }
  }

  removeInstallment(i: number) {
    this.paymentMethod.installments.splice(i, 1);
    this.recalculateFee();
  }
}
