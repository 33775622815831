import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ApplicationService} from "@core/services/application.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShopService} from "@core/services/shop.service";
import {DynamicData} from "@core/types/dynamic-table/dynamic-data";
import {CustomersService} from "../../../../@core/services/customers.service";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {AuthenticationService} from "../../../../@core/services/authentication.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal2 from "sweetalert2";

@Component({
  selector: 'app-banner-list',
  templateUrl: './banners-list.component.html',
  styleUrls: ['./banners-list.component.scss']
})

export class BannersListComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;


  @ViewChild("bannersCell", {static: true})
  private bannersCell?: TemplateRef<any>;

  public tableConfig: TableConfig = {} as TableConfig;


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  protected networks: any[] = [];
  public newBanner: any = {
    order: null,
    size:null,
    network: '',
    pictures: [],
  };

  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _customerService: CustomersService,
    private _dynamicTableService: DynamicTableService,
    public _authService: AuthenticationService,
    private modalService: NgbModal
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('cms-banners-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('banners')
      .then((table) => {
        this.tableConfig = table;
        this.tableConfig.columns.forEach(column => {
          if (column.cellTemplate === 'bannersCell') {
            column.cellTemplate = this.bannersCell;
          }
        });
        const queryParams: any = this._activatedRoute.snapshot.queryParams;
        if (queryParams.page) {
          this.tableConfig.pagination.page = queryParams.page;
        }
        if (queryParams.pageSize) {
          this.tableConfig.pagination.pageSize = queryParams.pageSize;
        }
        if (queryParams.search) {
          this.tableConfig.pagination.search = queryParams.search;
          this.tableConfig.pagination.strictSearch = queryParams.strictSearch !== 'false';
        }
        if (queryParams.sort) {
          const [sortProp, sortDir] = queryParams.sort.split(':');
          this.tableConfig.pagination.sort = {
            prop: sortProp,
            dir: sortDir?.toLowerCase() === 'desc' ? 'desc' : 'asc'
          };
        }
        this.tableConfig.filterGroups.forEach(filterGroup => {
          filterGroup.filters.forEach((filter: any) => {
            switch (filter.retriever) {
              case 'businesses.status.list':
                this._customerService.getBusinessStatusList().then((response: DynamicData) => {
                  filter.options = response.rows.map(status => {
                    return {
                      label: status.label,
                      value: status.value
                    }
                  })
                })
                break;
              case 'networks.list':
                this._customerService.getNetworkList({
                  pageSize: 1000,
                }).then((response: DynamicData) => {
                  filter.options = response.rows.map(network => {
                    return {
                      label: network.name,
                      value: network.id
                    }
                  })
                })
                break;
              case 'groups.list':
                this._customerService.getGroupList({
                  pageSize: 1000,
                }).then((response: DynamicData) => {
                  filter.options = response.rows.map(group => {
                    return {
                      label: group.name,
                      value: group.id
                    }
                  })
                })
                break;
            }
          })
        })
        this.tableConfig.columns.forEach(column => {
          if (column.prop === 'pictures') {
            column
          }
        });

        this._customerService.getNetworks()
          .then((response: any) => {
            this.networks = response.rows;
          });
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  async getBanners(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['network'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }
    const banners: DynamicData = await this._shopService.getBannerList(query)
    banners.rows.forEach(banner => {
      banner.actions = banner.actions || [];
      banner.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/banners/' + banner.id
      });

      banner.links = {
        picture: banner.pictures[0].link
      }
      banner['custom-value-link'] = banner.pictures[0].link ? 'Link' : ' - ';
    })
    return banners
  }

  externalLink(link: string) {
    window.open(link, '_blank');

  }

  addBanner(addNewBannerModal: TemplateRef<any>) {
    return this.modalService.open(addNewBannerModal, {
      size: 'md',
      windowClass: 'modal modal-primary'
    });
  }

  addPicture() {
    this.newBanner.pictures.push({
      src: '',
      link: ''
    });
  }

  addNewBanner() {
    this._shopService.newBanner(this.newBanner).then(() => {
      this.modalService.dismissAll();
      Swal2.fire({
        icon: 'success',
        title: 'Banner aggiunto con successo',
        showConfirmButton: false,
        timer: 1500
      })
    })
  }
}
