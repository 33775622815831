import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AgreementsListComponent } from "./agreements-list/agreements-list.component";
// import { AgreementsViewComponent } from "./agreements-view/agreements-view.component";
import { CoreModule } from "@core/components/core.module";

const routes: Routes = [
  {
    path: 'agreements',
    component: AgreementsListComponent,
    data: { animation: 'AgreementsListComponent' }
  },
  // {
  //   path: 'agreements/:code',
  //   component: AgreementsViewComponent,
  //   data: { animation: 'AgreementsViewComponent' }
  // },
];

@NgModule({
  declarations: [
    AgreementsListComponent,
    // AgreementsViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule
  ],
  providers: []
})
export class AgreementsModule {
}
