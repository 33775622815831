import { Component, OnInit } from '@angular/core';
import { ApplicationService } from "../../../@core/services/application.service";

@Component({
  selector: 'app-error500',
  templateUrl: './error500.component.html',
  styleUrls: ['./error500.component.scss']
})
export class Error500Component implements OnInit {


  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------


  constructor(
    private _applicationService: ApplicationService,
  ) {
    this._applicationService.layoutFullWidth();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._applicationService.setTitle("500 Internal Server Error");
  }
}
