import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApplicationService } from "@core/services/application.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ShopService } from "@core/services/shop.service";
import { DynamicData } from "@core/types/dynamic-table/dynamic-data";
import Swal from "sweetalert2";
import {CustomersService} from "../../../../@core/services/customers.service";
import {SystemService} from "../../../../@core/services/system.service";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {CatalogService} from "../../../../@core/services/catalog.service";
import {TableConfig} from "../../../../@core/components/dynamic-table/dynamic-table-interface";
import {DynamicTableService} from "../../../../@core/services/dynamicTable.service";


@Component({
  selector: 'app-discounts-view',
  templateUrl: './discounts-view.component.html',
  styleUrls: ['./discounts-view.component.scss']
})

export class DiscountsViewComponent implements OnInit, OnDestroy {

  public discount: any = null;
  public currentId: any;
  private clientFilter!: any[];
  private groups: any[] = [];
  public businesses: any[] = [];
  public paymentMethods: any[] = [];
  public suppliers: any[] = [];
  private networks: any[] = []
  public loaded: Promise<boolean> = Promise.resolve(false);


  @ViewChild("paymentsCell", {static: true})
  private paymentsCellTemplate?: TemplateRef<any>;


  public tableConfig1: TableConfig = {} as TableConfig;
  public tableConfig2: TableConfig = {} as TableConfig;




  fromDateString!: string;
  toDateString!: string;
  selectFromDate: boolean = false;
  selectToDate: boolean = false;
  public hoveredDate: NgbDate | null = null;
  public selectTime: boolean = false;
  @ViewChild('timeSelector')
  timeSelector!: ElementRef;


  // Private
  private _unsubscribeAll: Subject<any>;



  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  constructor(
    private _applicationService: ApplicationService,
    private _shopService: ShopService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _customerService: CustomersService,
    private _systemService: SystemService,
    private _catalogService: CatalogService,
    private _dynamicTableService: DynamicTableService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._applicationService.layoutContentWidth();
    this._applicationService.selectMenu('orders-discount-list');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._dynamicTableService.getDynamicTable('businesses')
      .then((table) => {
        this.tableConfig1 = table;
        this.generateFilterOptions();
        this._customerService.getBusinessesList()
          .then((businesses) => {
            this.businesses = businesses.rows;
            this.businesses.forEach(business => {
              this.tableConfig1.filterGroups[0].filters[0].options.push({
                label: business.name,
                value: business._id
              })
            })
          })
          .catch((error: any) => {
          });
        this._catalogService.getSupplierList()
          .then((suppliers) => {
            this.suppliers = suppliers.rows;
            this.suppliers.forEach(supplier => {
              this.tableConfig1.filterGroups[0].filters[1].options.push({
                label: supplier.name,
                value: supplier._id
              })
            })
          })
          .catch((error: any) => {
            console.log(error);
          });

        this.tableConfig1.filterGroups.forEach(filterGroup => {
          filterGroup.filters.forEach((filter: any) => {
            switch (filter.retriever) {
              case 'system.region.list':
                this._systemService.getRegionList().then((regions) => {
                  filter.options = regions.map(region => {
                    return {
                      label: region,
                      value: region
                    }
                  })
                })
                break;
              case 'businesses.status.list':
                this._customerService.getBusinessStatusList().then((response: DynamicData) => {
                  filter.options = response.rows.map(status => {
                    return {
                      label: status.label,
                      value: status.value
                    }
                  })
                })
                break;
              case 'networks.list':
                this._customerService.getNetworkList({
                  pageSize: 1000,
                }).then((response: DynamicData) => {
                  filter.options = response.rows.map(network => {
                    return {
                      label: network.name,
                      value: network.id
                    }
                  })
                })
                break;
              case 'groups.list':
                this._customerService.getGroupList({
                  pageSize: 1000,
                }).then((response: DynamicData) => {
                  filter.options = response.rows.map(group => {
                    return {
                      label: group.name,
                      value: group.id
                    }
                  })
                })
                break;
            }
          })
        })
      })
    this._dynamicTableService.getDynamicTable('orders')
      .then((table) => {
        this.tableConfig2 = table;
        const paymentsColumn = this.tableConfig2.columns.find(column => column.prop === 'payments');
        if (paymentsColumn) {
          paymentsColumn.cellTemplate = this.paymentsCellTemplate;
        }
      })
    this.currentId = this._route.snapshot.paramMap.get('id') || '';
    this._shopService.getSingleDiscountFromId(this.currentId)
      .then((response: any) => {
        this.discount = response;
        this.loaded = Promise.resolve(true);
      })
      .catch((error) => {
        console.error(error);
      });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  async getBusinesses(query?: any) {
    if (!this.discount.businesses.length) {
      return {
        rows: [],
        "page": 1,
        "pageSize": 1000000,
        "total": 1,
        "totalPages": 1
      }
    }
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._route,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['network', 'group'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }

    query.filters = query.filters || {};
    query.filters._id = this.discount.businesses.map((business: any) => ({$oid: business}));

    const businesses = await this._customerService.getBusinessList(query)
    businesses.rows.forEach(business => {
      business.links = business.links || {};
      business.links.code = '/businesses/' + business.code;
      business.actions = business.actions || [];
      business.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/businesses/' + business.code
      });
    });
    return businesses;
  }

  async getOrders(query?: any) {
    query = query || {};
    const queryParams: Params = {...query};
    this._router.navigate(
      [],
      {
        relativeTo: this._route,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
    query['populateFields'] = ['business', 'network', 'warehouse', 'payments'];
    if (query.strictSearch) {
      query.search = `"${query.search}"`
    }

    query.filters = query.filters || {};
    query.filters["discount.id"] = this.currentId;

    const orders = await this._shopService.getOrderList(query)
    orders.rows.forEach(order => {
      order.links = order.links || {};
      order.links.code = '/orders/' + order.code;
      order.actions = order.actions || [];
      order.actions.push({
        type: 'link',
        icon: 'eye-outline',
        tooltip: 'Visualizza',
        link: '/orders/' + order.code
      });
    });
    return orders;
  }

  saveDiscount() {
    this._shopService.saveDiscount(this.currentId, this.discount)
      .then((response: any) => {
        Swal.fire('Salvato!', 'Lo sconto è stato salvato con successo', 'success');
        this._router.navigate(['/discounts']);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteDiscount() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Non sarà possibile recuperare lo sconto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, elimina!',
      cancelButtonText: 'No, annulla!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this._shopService.deleteDiscount(this.currentId)
          .then((response: any) => {
            Swal.fire('Eliminato!', 'Lo sconto è stato eliminato con successo', 'success');
            this._router.navigate(['/discounts']);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }





  isHovered(date: NgbDate) {
    return (
      this.discount.from && !this.discount.to && this.hoveredDate && date.after(this.discount.from) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.discount.to && date.after(this.discount.from) && date.before(this.discount.to);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.discount.from) ||
      (this.discount.to && date.equals(this.discount.to)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }


  openFromDateSelector(event: MouseEvent) {
    this.selectFromDate = !this.selectFromDate;
    this.selectToDate = false;
    event.stopPropagation();
  }

  openToDateSelector(event: MouseEvent) {
    this.selectToDate = !this.selectToDate;
    this.selectFromDate = false;
    event.stopPropagation();
  }

  onFromDateSelection(date: any) {
    this.discount.from = date;
    this.discount.from = new Date(date.year, date.month - 1, date.day);
    console.log('from', this.discount.from);
    this.fromDateString = this.formatDateToString(date);
    this.selectFromDate = false;
  }

  onToDateSelection(date: any) {
    this.discount.to = date;
    this.discount.to = new Date(date.year, date.month - 1, date.day);
    console.log('to', this.discount.to);
    this.toDateString = this.formatDateToString(date);
    this.selectToDate = false;
  }

  formatDateToString(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    return `${date.year}-${date.month}-${date.day}`;
  }

  updateBusinessFilter($event: any, filter: any) {
    this._customerService.getBusinessList({
      search: $event.target.value,
      pageSize: 25,
    }).then((response: DynamicData) => {
      filter.options = response.rows.map(business => {
        return {
          label: business.name,
          value: business.id
        }
      })
    })
  }
  generateFilterOptions() {
    this.tableConfig1.filterGroups.forEach(filterGroup => {
      filterGroup.filters.forEach((filter: any) => {
        switch (filter.retriever) {
          case 'networks.list':
            this._customerService.getNetworkList({
              pageSize: 50,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(network => {
                return {
                  label: network.name,
                  value: network.id
                }
              })
            })
            break;
          case 'warehouses.list':
            this._catalogService.getWarehouseList({
              pageSize: 100,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(group => {
                return {
                  label: group.name,
                  value: group.id
                }
              })
            })
            break;
          case 'businesses.list':
            this._customerService.getBusinessList({
              pageSize: 50,
            }).then((response: DynamicData) => {
              filter.options = response.rows.map(business => {
                return {
                  label: business.name,
                  value: business.id
                }
              })
            })
            break;
        }
      })
    })
    this._customerService.getGroups()
      .then((response: any) => {
        this.groups = response.rows;
        console.log('groups', this.groups);
      });
    this._customerService.getNetworks()
      .then((response: any) => {
        this.networks = response.rows;
        console.log('networks', this.networks);
      });

  }
}
