<div class="row" *ngIf="!loading">
  <div class="col-12 col-sm-6 col-md-4">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Dettagli</h4>
      </div>
      <div class="card-body pt-1 pb-0">
        <div class="row">
          <div class="col-md-12">
            <label for="category-name" class="form-label">Nome</label>
            <div class="input-group mb-1">
              <input type="text" class="form-control" id="category-name" aria-describedby="basic-addon3"
                     [(ngModel)]="category.name">
            </div>
          </div>
          <div class="col-md-12">
            <label for="category-code" class="form-label">Codice</label>
            <div class="input-group mb-1">
              <input type="text" class="form-control" id="category-code" aria-describedby="basic-addon3"
                     [(ngModel)]="category.code">
            </div>
          </div>
          <div class="col-md-12">
            <label for="category-code" class="form-label">Categoria Padre</label>
            <div class="mb-1">
              <ng-select
                [items]="categories"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                [placeholder]="category?.parent?.name || 'Seleziona una categoria'"
                (change)="selectParent($event)"
                (keyup)="searchCategory($event)"
                appendTo="body"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <ng-container *ngIf="item.label && item.id !== category.id">
                    <span class="ng-value-label font-small-4"> {{ item.label }}</span>
                    <span class="ng-value-icon right" (click)="clear(item);" aria-hidden="true">×</span>
                  </ng-container>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-6">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Listini</h4>
      </div>
      <div class="card-body pt-1 pb-0">
        <div class="row">
          <ng-container *ngFor="let group of groups">
            <div class="col-12 col-md-6">
              <label for="category-name" class="form-label">
                            <span ngbTooltip="{{group.name}}" class="group-code ml-025">
                                <app-svg-icon class="d-inline-block align-middle" name="info"></app-svg-icon>
                            </span>
                {{ group.code }}
              </label>
              <div class="row mb-1">
                <div class="col-12 col-md-5">
                  <div class="input-group">
                    <input type="text" class="form-control" aria-describedby="basic-addon3"
                           [(ngModel)]="group.markup">
                  </div>
                </div>
                <div class="col-12 col-md-7">
                  <input type="checkbox" class="btn-check" id="btn-check-outlined-{{group.code}}"
                         autocomplete="off"
                         (change)="toggleGroupMarkup(group, $event)" [checked]="group.enabled">
                  <label class="btn btn-outline-primary btn-checkbox full-width"
                         for="btn-check-outlined-{{group.code}}">
                    <span *ngIf="group.enabled" class="text-nowrap enable-text">Abilitato</span>
                    <span *ngIf="!group.enabled" class="text-nowrap enable-text">Disabilitato</span>
                  </label>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-sm-12 col-md-2">
    <div class="card glassy card-full-height">
      <div class="card-header pb-0">
        <h4 class="card-title">Azioni</h4>
      </div>
      <div class="card-body pt-05 pb-0 d-flex flex-column justify-content-center overflow-visible">
        <button class="btn btn-success btn-block mb-1" (click)="saveCategory()">
          <app-svg-icon class="d-inline-block align-middle" name="save"></app-svg-icon>
          <span class="align-middle buttons-text">Salva</span>
        </button>
        <button class="btn btn-warning btn-block mb-1" (click)="propagatePriceRules()">
          <app-svg-icon class="d-inline-block align-middle" name="alert"></app-svg-icon>
          <span class="align-middle buttons-text">Propaga Listini</span>
        </button>
        <button class="btn btn-danger btn-block mb-1" (click)="cancelModifications()">
          <app-svg-icon class="d-inline-block align-middle" name="forbidden"></app-svg-icon>
          <span class="align-middle buttons-text">Annulla Modifiche</span>
        </button>
        <button class="btn btn-danger btn-block mb-1" (click)="deleteCategory()">
          <app-svg-icon class="d-inline-block align-middle" name="delete"></app-svg-icon>
          <span class="align-middle buttons-text">Elimina Categoria</span>
        </button>
      </div>
    </div>
  </div>
</div>
