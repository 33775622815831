import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApplicationService, Device } from "@core/services/application.service";
import { Title } from "@angular/platform-browser";
import { Subject, takeUntil } from "rxjs";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('zoomInOut', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate('1000ms ease-in', style({ transform: 'scale(1)', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('1000ms ease-out', style({ transform: 'scale(0.5)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  getAnimationState(outlet: any) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  constructor(
    private _applicationService: ApplicationService,
    private _title: Title,
  ) {
    this._unsubscribeAll = new Subject();
    this._applicationService.title.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((title: string) => {
        this._title.setTitle(title)
      });

    this._applicationService.icon.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((icon: string) => {
        let favIcon: HTMLLinkElement = document.querySelector('#appIcon') as HTMLLinkElement;
        favIcon.href = icon;
      });

    this._applicationService.getMenu();
    this._applicationService.getTheme();
  }

  checkSize(width: number, height: number) {
    if (width <= 1280) {
      console.log('mobile');
      this._applicationService.setDevice(Device.mobile);
    } else {
      console.log('desktop');
      this._applicationService.setDevice(Device.desktop);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkSize(event.target.outerWidth, event.target.outerHeight);
  }


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    this.checkSize(window.outerWidth, window.outerHeight);
    setTimeout(() => {
      this.checkSize(window.outerWidth, window.outerHeight);
    }, 100);
  }
}
