import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { DynamicData } from "../types/dynamic-table/dynamic-data";
import { ListParams } from "../types/api/list-params";
import { Group } from "../types/data/group";

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  // -----------------------------------------------------------------------------------------------------
  // @ public
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ private
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getCommentList(target: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const options = {
        params: {
          sort: "createdAt:desc",
          pageSize: 100
        }
      }
      this._apiService.make('comments', 'get', { target })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  setComment(target: string, comment: string, user: any): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this._apiService.make('comments', 'set', { target }, { body: { text: comment, user } })
        .then((response) => {
          if (!response) {
            return
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  }
}
